import React from 'react';
import "../styles.css";
import googleImage from '../assets/google.png'; // Correctly imported image file

// Explains App
const MapExplainer = ({ setExplainer }) => {
  const handleClose = () => {
    setExplainer(false);
  };

  // Patch notes data structured by months
  const patchNotes = {
     May: {
      notes: [
        "Re-did entire backend architecture after influx of users",
        "Form updates",
        "Marker loading icon",
      ]
    },
    April: {
      notes: [
        "Updated Algorithm",
        "Added recommendations based off of ratings",
        "Added reviews",
        "UI Updates"
      ]
    },
    March: {
      notes: [
        "Updated restaurant locations",
        "Bug fixes",
        "Added filtering between restaurants",
        "Filter reviews"
      ]
    },
    February: {
      notes: [
        "Security Rules",
        "Added Qdoba, CAVA, Sweetgreens"
      ]
    }
  };

  return (
    <div>
      <div>

      </div>
      <div className="explainer-containerPatchNotes">
        <button className="explainer-close-button" onClick={handleClose}>
          X
        </button>
        <h1> What is Stop the Skimp? </h1>       
        <p>I got tired of skimpflation at my favorite restaurant.</p>
        <p>- Stop the Skimp is an effort to maximize fast casual restaurants, by ranking them according to how much they skimp.</p>
        <p>- The rating's use a formula based off of the ratings and mistakes, on a scale from 1 - 5. 5 being best, 1 being worst.</p>
        <p>- If a restaurant has not been reviewed yet, Google's <img src={googleImage} alt="Google Icon" style={{ width: '20px', height: '20px', margin: '2px 0' }}/> ratings are shown. (50 mile radius)</p>
        {/* Patch notes section */}
        <div className="patch-notes">
          <h2>Patch Notes</h2>
          {Object.entries(patchNotes).map(([month, data]) => (
            <div key={month}>
              <h3>{month}</h3>
              <ul>
                {data.notes.map((note, index) => (
                  <li key={index}>{note}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapExplainer;
