// App.js
import React, { useState, useEffect, useRef, Component } from "react";
import "./styles.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import L, { Icon, divIcon, point } from "leaflet";
import Form from "./components/Form"; // Import the Form component
import markersData from './components/cleanChipotlesimple.js';
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";
import { getAuth, onAuthStateChanged, signInAnonymously, GoogleAuthProvider, signInWithPopup, signInWithCredential } from "firebase/auth";
import MapExplainer from "./components/MapExplainer.js"; // Import the Form component
import MarkerClusterGroup from "react-leaflet-cluster";
import FilterControl, { restaurantTypes } from "./components/FilterControl.js";
import burritoImage from './assets/burrito2.ico';
import googleImage from './assets/google.png'
import { findMaxRatingLocationWithinRadius, findMinRatingLocationWithinRadius } from './components/MaxRatingFinder';
import PopupModal from './components/popupRecommendation.js';
import { getAppCheckToken } from './components/firebase.js';



export default function App() {

  // app check:

  const [filters, setFilters] = useState({
    restaurantTypes: ["Chipotle"],
  });

  const restaurants = markersData || [];

  // State Management

  const [isFormVisible, setFormVisibility] = useState(false);
  const [userLocation, setUserLocation] = useState(null); // Replace with default coordinates
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [restaurantName, setRestaurantName] = useState(null);
  const [PlaceID, setPlaceID] = useState(null);
  const [geocode, setgeocode] = useState(null);
  const [TotalGoogleRatings, setTotalGoogleRatings] = useState(null);
  const [AverageGoogleRating, setAverageGoogleRatings] = useState(null);
  const [restaurantAddress, setRestaurantAddress] = useState(null);
  const [onlineRating, setOnlineRating] = useState(null); //State to save online rating per click
  const [restaurantType, setRestaurantType] = useState(null);
  const [restID, setrestID] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false); // New state for map loaded status
  const [showZoomControl, setShowZoomControl] = useState(false); // set zoom control to false for time being
  const [map, setMap] = useState(null);
  const [averageRatings, setAverageRatings] = useState([]); // State to store restaurant names and average ratings
  const [usertoken, setToken] = useState(null);
  const [showExplainer, setExplainer] = useState(false);
  const [areCalculationsComplete, setCalculationsComplete] = useState(false); // New state for calculation completion
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [recommendedLocation, setRecommendedLocation] = useState(null);
  const [nonrecommendedLocation, setnonRecommendedLocation] = useState(null);
  const [locationSetByUser, setLocationSetByUser] = useState(false); // New state to track if the location is set by the user
  const [isDefaultLocation, setIsDefaultLocation] = useState(false);
  const [isNewData, setNewData] = useState(null);
  const [FirestoreData, setFirestoreData] = useState([]);
  const [isReccomenderReady, setReccomender] = useState(false);
  const [appCheckToken, setAppCheckToken] = useState(null);
  const [markerisLoading, markersetIsLoading] = useState(false); 
  const [submittedRestID, setSubmittedRestID] = useState(null);

  useEffect(() => {
    let geolocationWatcher = null;
    if (navigator.geolocation) {
        geolocationWatcher = navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserLocation([position.coords.latitude, position.coords.longitude]);
                setDefaultLocation([position.coords.latitude, position.coords.longitude]);
                setLocationSetByUser(true); // Set the flag when location is obtained
                clearTimeout(timeoutId); // Clear the timeout as location is successfully obtained
            },
            (error) => {
                console.error("Error getting location, using default: New York City", error);
                if (!locationSetByUser) { // Only use default if user location isn't already set
                    // setUserLocation([55.7558, 37.6173]); // Latitude and longitude of Moscow 
                    setUserLocation([40.7128, -74.0060]); // Latitude and longitude of Moscow
                    setDefaultLocation([40.7128, -74.0060]);
                    setIsDefaultLocation(true);
                }
            },
            { enableHighAccuracy: true }
        );
    } else {
        console.error("Geolocation is not supported by this browser.");
    }

    // Load the New York location after 8 seconds if location has not been set by user
    const timeoutId = setTimeout(() => {
        if (!locationSetByUser) { // Check if location was not set by user
            setUserLocation([40.7128, -74.0060]); // Latitude and longitude of New York City
            setIsDefaultLocation(true);
            if (geolocationWatcher) {
                navigator.geolocation.clearWatch(geolocationWatcher);
            }
        }
    }, 8000);

    return () => {
        clearTimeout(timeoutId); // Cleanup the timeout when component unmounts
        if (geolocationWatcher || setIsDefaultLocation) {
            navigator.geolocation.clearWatch(geolocationWatcher);
        }
    };

}, []);

useEffect(() => {
  const fetchToken = async () => {
    const tokenResponse = await getAppCheckToken();
    setAppCheckToken(tokenResponse.token);
  };

  fetchToken();
}, []);

useEffect(() => {
  if (defaultLocation && appCheckToken) {  // Ensure appCheckToken is available
    fetchData(appCheckToken);  // Pass the token to fetchData
  }
}, [defaultLocation, appCheckToken]);  
  
  // load map
  useEffect(() => {
    if (userLocation && areCalculationsComplete === true) {
      // Hide loader when map is loaded and calculations are complete
      const loader = document.getElementById('loader');
      if (loader) {
        loader.style.display = 'none';
      }
    }
  }, [isMapLoaded, areCalculationsComplete]);
  
  // set up firebase
  //Signs in anon to work with firebase
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        setToken(idToken);
      } else {
        signInAnonymously(auth).catch((error) => {
          console.error('Error signing in anonymously:', error);
        });
      }
    })
  }, []);

  async function fetchData(appCheckToken) {
    try {
      if (userLocation) {
        const myHeaders = new Headers();
        myHeaders.append("X-Firebase-AppCheck", appCheckToken); // Pass the token string itself
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        const response = await fetch(`https://querygeohashv2-qc3vtkednq-uc.a.run.app?lat=${userLocation[0]}&lng=${userLocation[1]}`, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const FirestoreData = await response.json();
          setCalculationsComplete(true);
          setFirestoreData(FirestoreData);
        } else {
          const text = await response.text();
          console.error('Response was not JSON:', text);
        }
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  }
   



  useEffect(() => {
    if (isNewData){
      markersetIsLoading(true); // Market Loading flag
    const fetchDataWithDelay = () => {
      setTimeout(() => {
        fetchData(appCheckToken);
        markersetIsLoading(false); // Marker Loading Flag
        setSubmittedRestID(null);
      }, 5000);
    };

    fetchDataWithDelay();
  }
  }, [isNewData]);

  const joinedArray = leftJoinArrays(markersData, FirestoreData);
  
  
  

  //if Marker on map is clicked, shows form and disables controls
  const handleMarkerClick = () => {
    // changes long and lat when user clicks a new location
    if (map) {
      const currentCenter = map.getCenter();
      setUserLocation([currentCenter.lat, currentCenter.lng]);
    }
    setFormVisibility(true);
    setNewData(false);
    setShowZoomControl(false);
  };

  //handles submits of map
  const handleSubmit = (reviewData) => {
    setSubmittedRestID(reviewData.restID);
    setFormVisibility(false);
    setShowZoomControl(true);
    setNewData(true);
  };
  
  // Adding a useEffect to log the updated submittedRestID when it changes
  useEffect(() => {
  }, [submittedRestID]);

  const confirmRestaurant = (marker) => {
    setRestaurantName(marker.Rest_Name);
    setRestaurantType(marker.type);
    setrestID(marker.id);
    setOnlineRating(marker.averageOnlineRating)
    setRestaurantAddress(marker.address)
    setPlaceID(marker.place_id || ''); // If marker.place_id is undefined, it sets an empty string
    setgeocode(marker.geocode);
    setTotalGoogleRatings(marker.user_ratings_total || '');
    setAverageGoogleRatings(marker.rating || '');
  };

  //shows Explainer
  const handleExplainerClick = () => {
    setExplainer(true);
  };

  //Creates Cluster
  const createClusterCustomIcon = function (cluster) {
    return new divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: "custom-marker-cluster",
      iconSize: point(33, 33, true)
    });
  };




function leftJoinArrays(markersData, FirestoreData) {   
    const result = [];
  
    markersData.forEach(obj1 => {
      const matchingObj2 = FirestoreData.find(obj2 => obj2.restID == obj1.id);
      if (matchingObj2) {
        // Merge obj1 and matchingObj2 and add SkimpReview: true
        const joinedObject = { ...obj1, ...matchingObj2, SkimpReview: true };
        result.push(joinedObject);
        // console.log(joinedObject.place_id)
      } else {
        // If there is no match, include obj1 with SkimpReview set to false
        const joinedObject = { ...obj1, SkimpReview: false };
        result.push(joinedObject);
      }
    });

    return result;
  }
  

  
// Function to determine the appropriate icon based on the restaurant type and rating
const getCustomDivIcon = (marker) => {
  let ratingDisplay, imageUrl;

  if (marker.average_rating > 0) {
    ratingDisplay = parseFloat(marker.average_rating.toFixed(2));
    imageUrl = burritoImage; // Assuming burritoImage is already imported at the top
  } else if (marker.rating) { // Fallback to using the default Google rating if present
    ratingDisplay = marker.rating;
    imageUrl = googleImage; // Assuming googleImage is already imported at the top
  } else {
    ratingDisplay = "N/A";
    imageUrl = burritoImage; // Assuming this is the default image when no specific rating is available
  }

  let backgroundColor;
  switch (marker.type) {
    case "Chipotle":
      backgroundColor = "#441500"; // Dark brown background for Chipotle
      break;
    case "CAVA":
      backgroundColor = "#f3d03e"; // Yellow background for CAVA
      break;
    case "Sweetgreen":
      backgroundColor = "#00473c"; // Green background for Sweetgreen
      break;
    case "Qdoba":
      backgroundColor = "#ef9600"; // Orange background for Qdoba
      break;
    default:
      backgroundColor = "#fff"; // Default background color if type is not recognized
  }

  let iconSize = marker.id === recommendedLocation?.id ? [80, 80] : [60, 60]; // Larger icon size for recommended locations
  let recommendedText = marker.id === recommendedLocation?.id ? "<div style='background-color: white; padding: 5px; border-radius: 5px; margin-bottom: 5px;'><div style='color: rgb(76, 175, 80); font-size: 10px; font-weight: bold;'>RECOMMENDED</div></div>" : "";

  let imageHtml = marker.id === submittedRestID ? "<div class='loading-icon'></div>" : `<img src="${imageUrl}" style="width: 20px; height: 20px; margin: 2px 0;"/>`;

  let loadingIconHtml = `
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: ${backgroundColor}; border-radius: 10px; box-shadow: 0 2px 6px rgba(0,0,0,0.3); padding: 5px; width: ${iconSize[0]}px; height: ${iconSize[1]}px; color: #fff;">
      ${recommendedText}
      <div style="height: 15px; width: 100%; display: flex; align-items: center; justify-content: center; overflow: hidden;">
        <span style="font-size: 9px; font-weight: bold; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          ${marker.Rest_Name}
        </span>
      </div>
      ${imageHtml}
      <div style="display: flex; align-items: center;">
        <span style="font-size: 12px; font-weight: bold; color: white;">
          ${ratingDisplay}
        </span>
        ${
          ratingDisplay !== "N/A"
            ? '<span style="font-size: 12px; font-weight: bold; color: #FFD700; margin-left: 4px;">★</span>'
            : ""
        }
      </div>
    </div>
  `;

  return L.divIcon({
    className: "my-custom-pin",
    html: loadingIconHtml,
    iconSize: iconSize,
    iconAnchor: [iconSize[0] / 2, iconSize[1]] // Adjust anchor to new size
  });
};




useEffect(() => {
  // Check if userLocation and averageRatings are loaded and operations haven't been done yet
  if (userLocation && FirestoreData && FirestoreData.length > 0) {
    const chipotleLocations = joinedArray.filter(location => location.type === 'Chipotle');


    const maxLocation = findMaxRatingLocationWithinRadius(chipotleLocations, userLocation, 5);
    // const badLocation = findMinRatingLocationWithinRadius(chipotleLocations, userLocation, 5);
    if (maxLocation) {
      setRecommendedLocation(maxLocation);
      setPopupVisible(true);
    } else {
      // Show popup if no maximum rating location is found
      setPopupVisible(true);
    }

    // Optionally, uncomment and modify this to handle 'badLocation' if needed
    // if (badLocation) {
    //   setnonRecommendedLocation(badLocation);
    //   setPopupVisible(true);
    // } else if (!maxLocation) {
    //   setPopupVisible(true);
    // }
  } else {
    // Show popup if userLocation or averageRatings are not properly loaded
    setPopupVisible(true);
  }

  setReccomender(true); // Assuming you want to set this regardless of the above conditions
}, [areCalculationsComplete]); // Corrected the dependency array to monitor changes correctly
// }, [userLocation, averageRatings]); // Corrected the dependency array to monitor changes correctly


  //buttons on map

  //   useEffect(() => {
  //     if (map) {
  //         if (showZoomControl) {
  //             if (!map.zoomControl) {
  //                 map.addControl(L.control.zoom());
  //             }
  //         } else {
  //             if (map.zoomControl) {
  //                 map.zoomControl.remove();
  //             }
  //         }
  //     }
  // }, [map, isPopupVisible]);

//   const [zoomControlAdded, setZoomControlAdded] = useState(true);

//   useEffect(() => {
//     console.log('map', map); // Check if map is defined and not null
//     if (map) {
//         console.log('map.zoomControl', map.zoomControl); // Check if zoomControl is defined
//         if (!isFormVisible && !isPopupVisible && map.zoomControl) {
//             console.log('attempting removal');
//             map.zoomControl.remove();
//             setZoomControlAdded(false);
//         } else if (!map.zoomControl) {
//             console.log('zoom control not added yet');
//         } else {
//             map.addControl(L.control.zoom());
//             setZoomControlAdded(true);
//         }
//     }
// }, [map, isFormVisible, isPopupVisible]);



  
  useEffect(() => {
    if (map && !isFormVisible && !isPopupVisible) {  // Add !isPopupVisible to the condition
      const easyButton = L.easyButton({
        id: 'toggleExplainer',
        states: [{
          stateName: 'show-explainer',
          onClick: function() {
            handleExplainerClick(); // Call the showExplainer function when the button is clicked
          },
          title: 'Show Explainer', // Tooltip text when the button is in 'show-explainer' state
          icon: 'fa-question' // Icon for the button
        }]
      }).addTo(map);
    return () => {
      // Remove the button when the component unmounts or when isPopupVisible changes to true
      easyButton.remove();
    };
  };
  }, [map, isPopupVisible, isFormVisible]);  // Add isPopupVisible to the dependency array

  useEffect(() => {
    if (map && !isFormVisible && !isPopupVisible) {  // Add !isPopupVisible to the condition
      const easyButton = L.easyButton({
        id: 'crosshairs',
        states: [{
          stateName: 'default',
          icon: 'fa-crosshairs fa-lg',
          title: 'Zoom to Your Location',
          onClick: function () {
            map.locate().on("locationfound", function (e) {
              map.flyTo(e.latlng, map.getZoom());
            });
          }
        }]
      }).addTo(map);

      return () => {
        // Remove the button when the component unmounts or when isPopupVisible changes to true
        easyButton.remove();
      };
    }
  }, [map, isPopupVisible, isFormVisible]);  // Add isPopupVisible to the dependency array


  return (
  <>
    <header>
      {!isFormVisible && !showExplainer && <h1 className="Title">Stop the Skimp!</h1>}
    </header>
    {isPopupVisible && (
      <PopupModal
        location={recommendedLocation}
        onClose={() => {
          setPopupVisible(false);
        }}
        isReccomenderReady = {isReccomenderReady}
      />
    )}
    {isFormVisible && (
      <Form
        submitForm={handleSubmit}
        restaurantName={restaurantName}
        restID={restID}
        setFormVisibility={setFormVisibility}
        setShowZoomControl={setShowZoomControl}
        restaurantType={restaurantType}
        onlineRating={onlineRating}
        setPopupVisible = {setPopupVisible}
        restaurantAddress ={restaurantAddress}
        geocode = {geocode}
        PlaceID = {PlaceID}
        TotalGoogleRatings = {TotalGoogleRatings}
        AverageGoogleRating = {AverageGoogleRating}
        userLocation = {userLocation}
        FirestoreData = {FirestoreData}
        appCheckToken = {appCheckToken}
      />
    )}
    {showExplainer ? (
      <MapExplainer setExplainer={setExplainer} />
    ) : userLocation ? (
        <MapContainer center={userLocation} ref={setMap} zoomControl ={false} zoom={13} whenReady={() => { setIsMapLoaded(true) }} tap={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {!isFormVisible && <FilterControl filters={filters} setFilters={setFilters} />}
          <MarkerClusterGroup
            key={JSON.stringify(filters.restaurantTypes)}
            chunkedLoading
            iconCreateFunction={createClusterCustomIcon}
          >
            {joinedArray
              .filter((marker) => filters.restaurantTypes.includes(marker.type))
              .map((marker, index) => (
                <Marker
                  position={marker.geocode}
                  icon={getCustomDivIcon(marker)}
                  key={index}
                  eventHandlers={{
                    click: () => {
                      handleMarkerClick();
                      confirmRestaurant(marker);
                    },
                  }}
                />
              ))}
          </MarkerClusterGroup>
        </MapContainer>
    ) : (
      <p>Loading map...</p>
    )}
    <footer className="footer">
      Operated by Ben Shifrin
    </footer>
  </>
);

  
}  
