//Form.js
import React, { useRef, useState, useEffect } from 'react';
import {database} from './firebase';
import { ref, push, set } from 'firebase/database';
import { getAuth } from "firebase/auth";
import Reviews from './Reviews'; // Adjust the path as necessary
// import OnlineRatingDisplay from './OnlineRatings';
import { CookiesProvider, useCookies } from 'react-cookie'

const useHoverSupport = () => {
  const [hoverSupported, setHoverSupported] = useState(false);

  useEffect(() => {
    const query = window.matchMedia('(hover: hover)');
    setHoverSupported(query.matches);
  }, []);

  return hoverSupported;
};



const Form = ({ submitForm, restaurantName, restID, setFormVisibility, setShowZoomControl, restaurantType, onlineRating, restaurantAddress, geocode, PlaceID, TotalGoogleRatings, AverageGoogleRating, userLocation, FirestoreData, appCheckToken }) => {
  // cookies
  const [cookies, setCookie, removeCookie] = useCookies(['user', 'expiration']);


  const hoverSupported = useHoverSupport();
  //mobile button hover


  const time = new Date().toLocaleString();
  // button hover
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);

  const [formData, setFormData] = useState({
    rating: '',
    review: '',
    foodSpeed: '',
    isOnline: false,
    PositiveOrder: false,
    NegativeOrder: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleState = (field) => {
    setFormData({
      ...formData,
      [field]: !formData[field],
    });
  };

  const handleClose = () => {
    setFormVisibility(false);
    setShowZoomControl(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.rating === '') {
        alert('Please select a rating before submitting.');
        return; // Stop the form submission if rating is not selected
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
        console.log("User is not authenticated");
        return;
    }

    const reviewRef = ref(database, 'data');
    const newReviewId = push(reviewRef).key;
    const reviewData = {
        ...formData,
        restaurantName,
        restaurantType,
        restID,
        time,
        restaurantAddress,
        PlaceID,
        geocode,
        TotalGoogleRatings,
        AverageGoogleRating
    };

    try {
        reviewData.newReviewId = newReviewId;
        console.log('cookies.user', cookies.user)
        console.log('cookies.expiration', cookies.expiration )
        console.log('datenow', Date.now())
        if ((typeof(cookies.expiration) === 'undefined') || (Date.now() > cookies.expiration)) { // Check if cookie is not expired
            const url = "https://adddatav2-qc3vtkednq-uc.a.run.app";
            fetch(url, {
                method: 'POST',
                headers: {
                    'X-Firebase-AppCheck': appCheckToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reviewData)
            })
            submitForm(reviewData);
            // sets cookies if there isnt any or if the previous ones were expired
            if (!cookies.expiration || (Date.now() > cookies.expiration)) {
              const expirationTime = Date.now() + 14400000; // 4 hours from now
              setCookie('expiration', expirationTime, { path: '/' });
            }
        } else {
            alert('Please wait before submitting a new review');
        }
    } catch (error) {
        console.error('Error submitting data to Firebase:', error);
    }
};


  
  return (
    <div className="Formmodal-backdrop">
      <div className="Formmodal-content">
        <button onClick={handleClose} style={{ float: 'right', border: 'none', background: 'transparent', cursor: 'pointer', fontSize: '26px' }}>
          <i className="fa fa-times"></i> {/* Close button */}
        </button>
        <h1>{restaurantName} - {restaurantType} </h1>
        <h3>Address: {restaurantAddress}</h3>
        <form onSubmit={handleFormSubmit}>
          <div>
          <button 
            type="button" 
            onMouseEnter={() => hoverSupported && setHover1(true)}
            onMouseLeave={() => hoverSupported && setHover1(false)}
            onClick={() => toggleState('isOnline')}
            style={{
              backgroundColor: formData.isOnline ? '#ccc' : (hover1 ? '#ccc' : 'white'), // Example hover color change
              color: 'black',
              border: 'none',
              fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif',
              borderRadius: '20px',
              padding: '10px 20px',
              outline: 'none',
              boxShadow: hover1 ? '0px 2px 10px rgba(0,0,0,0.1)' : 'none', // Add shadow on hover
              cursor: hover1 ? 'pointer' : 'default' // Change cursor on hover
            }}
          >
            Online Order?
          </button>
            </div>
            <br />
            <div>
            <button 
            type="button" 
            onMouseEnter={() => hoverSupported && setHover2(true)}
            onMouseLeave={() => hoverSupported && setHover2(false)}
            onClick={() => toggleState('PositiveOrder')}
            style={{
              backgroundColor: formData.PositiveOrder ? 'green' : (hover2 ? 'green' : 'white'), // Example hover color change
              color: 'black',
              border: 'none',
              fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif',
              borderRadius: '20px',
              padding: '10px 20px',
              outline: 'none',
              boxShadow: hover2 ? '0px 2px 10px rgba(0,0,0,0.1)' : 'none', // Add shadow on hover
              cursor: hover2 ? 'pointer' : 'default' // Change cursor on hover
            }}
          >              Positive Mistake: Did their mistake save you money?
              </button>
            </div>
            <br />
            <div>
            <button 
            type="button" 
            onMouseEnter={() => hoverSupported && setHover3(true)}
            onMouseLeave={() => hoverSupported && setHover3(false)}
            onClick={() => toggleState('NegativeOrder')}
            style={{
              backgroundColor: formData.NegativeOrder ? '#8D2C23' : (hover3 ? '#8D2C23' : 'white'), // Example hover color change
              color: 'black',
              border: 'none',
              fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif',
              borderRadius: '20px',
              padding: '10px 20px',
              outline: 'none',
              boxShadow: hover3 ? '0px 2px 10px rgba(0,0,0,0.1)' : 'none', // Add shadow on hover
              cursor: hover3 ? 'pointer' : 'default' // Change cursor on hover
            }}
          >              Negative Mistake: Did their mistake cost you money?
            </button>
          </div>

          <br />
          <div className="centeredRatinglabel" style={{ display: "flex", alignItems: "center" }}>
  <span style={{ marginRight: "10px" }}>
    Rating: {formData.rating === '' ? <span style={{color: 'red'}}>*</span> : null}
  </span>
  <div className="star-rating">
      {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;
          return (
              <label key={ratingValue} style={{ position: 'relative', cursor: "pointer", margin: 0 }}>
                  <input 
                      type="radio" 
                      name="rating"
                      value={ratingValue} 
                      onChange={() => setFormData(prev => ({...prev, rating: ratingValue}))}
                      style={{ position: 'absolute', opacity: 0, width: 0, height: 0 }}
                  />
                  <div 
                      className={`burritoSeparatorForm ${ratingValue <= formData.rating ? 'active' : ''}`}
                      onClick={() => setFormData(prev => ({...prev, rating: ratingValue}))}
                      style={{ filter: ratingValue <= formData.rating ? 'none' : 'grayscale(100%)'}}
                  ></div> {/* Conditional styling and onClick to update rating */}
              </label>
          );
      })}
  </div>
</div>


          <br />
          <div className="centered-section">
            <label className="reviewRatingLabel">
              Review:
              <textarea name="review" onChange={handleInputChange}></textarea>
            </label>
          </div>
          <div>
          <button type="submit" style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif', borderRadius: '20px', padding: '5px 5px', cursor: 'pointer' }}>
              Submit Form
          </button>
          </div>        
        </form>
      </div>
      <div className="Formmodal-content2">
          <h2>What people are saying about {restaurantName}</h2>
          <Reviews restID={restID} userLocation = {userLocation} FirestoreData = {FirestoreData} /> {/* Pass the restaurantName prop */}
      </div>
    </div>

  );
};

export default Form;
