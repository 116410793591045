import React from 'react';
import "../styles.css";
import googleImage from '../assets/google.png'; // Correctly imported image file

// Explains App
const Explainer = ({ setExplainer }) => {
  const handleClose = () => {
    setExplainer(false);
  };

  return (
    <div>
      <div>
        {/* <button className="explainer-close-button" onClick={() => handleClose()}>
          X
        </button> */}
      </div>
      <div className="explainer-container">
        - Stop the Skimp is an effort to maximize fast casual restaurants, by ranking them according to how much they skimp.<br /> 
        - The rating's use a formula based off of the ratings and mistakes, on a scale from 1 - 5.<br />
        - If you want to fight skimpflation, leave a review :)<br />
      </div>
    </div>
  );
};

export default Explainer;
