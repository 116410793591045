function haversineDistance(coords1, coords2) {
  const [lat1, lon1] = coords1;
  const [lat2, lon2] = coords2;

  if (!lat1 || !lon1 || !lat2 || !lon2) {
    console.error('Invalid coordinates provided.');
    return null; // Return null instead of 0 when coordinates are invalid
  }

  const R = 6371; // Radius of the earth in kilometers
  const dLat = (lat2 - lat1) * Math.PI / 180;  // Convert degrees to radians
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const distance = R * c; // Distance in kilometers
  return distance;
}

const findMaxRatingLocationWithinRadius = (locations, centerPoint, radiusInMiles) => {
  if (!locations || locations.length === 0 || !centerPoint) {
    return null;
  }

  const radiusInKm = radiusInMiles * 1.60934; // Convert miles to kilometers
  let maxRatingLocation = null;

  locations.forEach(location => {
    if (location.SkimpReview === false) {
      return; // Skip this iteration of the loop
    }
  const distance = haversineDistance(centerPoint, location.geocode);
    if (distance !== null && distance <= radiusInKm) {
      if (!maxRatingLocation || location.average_rating > maxRatingLocation.average_rating) {
        maxRatingLocation = location;
      }
    }
  });
  return maxRatingLocation;
};

export { findMaxRatingLocationWithinRadius };

// const findMinRatingLocationWithinRadius = (locations, centerPoint, radiusInMiles) => {
//   if (!locations || locations.length === 0 || !centerPoint) {
//     console.error('No locations provided or center point is missing');
//     return null;
//   }

//   const radiusInKm = radiusInMiles * 1.60934; // Convert miles to kilometers
//   let minRatingLocation = null;

//   locations.forEach(location => {
//         // Want only locations on stop the skimp
//     if (location.SkimpReview == false) {
//         return; // Skip this iteration of the loop
//     }

//     const distance = haversineDistance(centerPoint, location.geocode);
//     if (distance <= radiusInKm) {
//       if (!minRatingLocation || location.averageRating < minRatingLocation.averageRating) {
//         minRatingLocation = location;
//       }

//     }
//   });

//   if (!minRatingLocation) {
//     // console.log('No locations within the specified radius');
//   } else {
//   }

//   return minRatingLocation;
// };


// export { findMaxRatingLocationWithinRadius, findMinRatingLocationWithinRadius };
