import React, { useState, useEffect } from "react";
import Explainer from "./Explainer"; // Import the Explainer component

const PopupModal = ({ location, onClose, isReccomenderReady, }) => {
  const [showExplainer, setExplainer] = useState(false);

  // Function to handle click events that sets showExplainer to true
  const handleExplainerClick = () => {
    setExplainer(true);
  };

  // useEffect hook to trigger handleExplainerClick when the component mounts
  useEffect(() => {
    if (!location) { // Immediately show the explainer if location is null
      handleExplainerClick();
    }
  }, [location, isReccomenderReady]); // Dependency on location ensures explainer shows when it's null
// Render the Explainer directly if no location is provided
  if (!location) {
    return (
      <div className="modal-backdrop">
        <div className="modal-content" style={{ position: 'relative', padding: '20px' }}>
          <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px', border: 'none', background: 'transparent', cursor: 'pointer', fontSize: '26px' }}>
            <i className="fa fa-times"></i> {/* Close button */}
          </button>
          {showExplainer && <Explainer setExplainer={setExplainer} />}
        </div>
      </div>
    );
  }
  // Otherwise, render the usual modal content if a location is provided
  if (isReccomenderReady == true) {

    return (
      showExplainer && (
        <div className="modal-backdrop">
          <div className="modal-content" style={{ position: 'relative', padding: '20px' }}>
            <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px', border: 'none', background: 'transparent', cursor: 'pointer', fontSize: '26px' }}>
              <i className="fa fa-times"></i> {/* Close button */}
            </button>
            <h2>Recommended Location</h2>
            <p><strong>Name:</strong> {location.type} - {location.Rest_Name}</p>
            <p><strong>Address: </strong><a href={`https://www.google.com/search?q=${encodeURIComponent(location.address + ' ' + location.Rest_Name + ' Chipotle')}`} target="_blank" rel="noopener noreferrer">{location.address}</a></p>
            <p><span className="burritoSeparator"></span> <strong>Rating:</strong> {location.average_rating.toFixed(2)}</p>
            <Explainer setExplainer={setExplainer} />
          </div>
        </div>
      )
    );
    
  }
};

export default PopupModal;
