import React from "react";
//Creates restaurant filters
export const restaurantTypes = ["Chipotle", "CAVA","Sweetgreen","Qdoba",];

const FilterControl = ({ filters, setFilters }) => {
  const handleButtonClick = (type) => {
    if (filters.restaurantTypes.includes(type)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        restaurantTypes: prevFilters.restaurantTypes.filter((f) => f !== type),
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        restaurantTypes: [...prevFilters.restaurantTypes, type],
      }));
    }
  };
  
  return (
    <div className="filter-control">
      <h3 style={{ marginTop: "0px", marginBottom: "0px" }}>Select a Chain</h3>
      {restaurantTypes.map((type) => (
        <button
          key={type}
          className={`selector ${
            filters.restaurantTypes.includes(type) ? "selected" : ""
          }`}
          onClick={() => handleButtonClick(type)}
        >
          {type}
        </button>
      ))}
    </div>
  );
};

export default FilterControl;
