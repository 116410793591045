import { createRoot } from "react-dom/client";
import React, { useState, useEffect } from "react";
import App from "./App";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
//Creates app in production
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <App />,
);
