// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from 'firebase/app-check';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBMM2T543IfwVwi6FerVYNM_Euvd83SIv0",
  authDomain: "newreviewsv2.firebaseapp.com",
  projectId: "newreviewsv2",
  storageBucket: "newreviewsv2.appspot.com",
  messagingSenderId: "938424775117",
  appId: "1:938424775117:web:bbc75ff4a984551a21d484"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize App Check
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfeREApAAAAACjRQM3y0BGfMyjyF0bQ8GO6Gkfk'),
  isTokenAutoRefreshEnabled: true
});

// Function to get App Check token
const getAppCheckToken = async () => {
  try {
    const appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ true);
    return appCheckTokenResponse;
  } catch (err) {
    console.error("Error getting App Check token:", err);
    return null;
  }
};

// Initialize Database
const database = getDatabase(app);

export { getAppCheckToken, database };
