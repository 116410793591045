const markers = [
    {
      "geocode": [38.92936206, -77.03246887],
      "Rest_Name": "Columbia Heights",
      "address": "3105 14th Street NW",
      "id": 10003,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJF484eR_It4kRhAchIntoTI4",
      "user_ratings_total": 457.0
    },
    {
      "geocode": [38.94434142, -77.07741868],
      "Rest_Name": "Tenleytown",
      "address": "4237 Wisconsin Avenue NW",
      "id": 10004,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ3UEDWMLJt4kRStdTuevVzY8",
      "user_ratings_total": 570.0
    },
    {
      "geocode": [34.06111619, -118.4462877],
      "Rest_Name": "Westwood",
      "address": "1073 Broxton Avenue",
      "id": 10012,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJrVwAzoO8woAR4BS5v8M_-FI",
      "user_ratings_total": 744.0
    },
    {
      "geocode": [34.18425554, -118.6046264],
      "Rest_Name": "Topanga",
      "address": "6256 Topanga Canyon Boulevard",
      "id": 10013,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJT_axsiGcwoARBVLaLcfKqQc",
      "user_ratings_total": 609.0
    },
    {
      "geocode": [34.02513705, -118.3939411],
      "Rest_Name": "Culver City",
      "address": "9343 Culver Boulevard",
      "id": 10025,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ3Z9z9Cm6woARsuDO-ZzwQhs",
      "user_ratings_total": 575.0
    },
    {
      "geocode": [34.01564586, -118.498184],
      "Rest_Name": "Santa Monica",
      "address": "1318 Second Street",
      "id": 10026,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJl3UOXsWkwoAROuxk5Ln4nK8",
      "user_ratings_total": 525.0
    },
    {
      "geocode": [34.02511903, -118.28411],
      "Rest_Name": "USC Village",
      "address": "3201 S Hoover Street",
      "id": 10032,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ1xoiveXHwoARC-qY17itdWk",
      "user_ratings_total": 1179.0
    },
    {
      "geocode": [38.90006913, -76.99864016],
      "Rest_Name": "H Street",
      "address": "523 H Street NE",
      "id": 10039,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJpUqzLT24t4kRnjNyY794n6g",
      "user_ratings_total": 507.0
    },
    {
      "geocode": [38.87662555, -77.00703049],
      "Rest_Name": "Navy Yard",
      "address": "52 M Street SE",
      "id": 10042,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJVVVFj9a5t4kRa_Wney9ocEc",
      "user_ratings_total": 490.0
    },
    {
      "geocode": [33.55938359, -117.6716005],
      "Rest_Name": "Mission Viejo",
      "address": "27750 Crown Valley Parkway",
      "id": 10051,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ7T_sEvLu3IARj5eE6aDyChE",
      "user_ratings_total": 744.0
    },
    {
      "geocode": [35.30991925, -80.74779141],
      "Rest_Name": "University Place",
      "address": "8936 J M Keynes Drive",
      "id": 10054,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJd7psTCMcVIgRNoc8CV7yw-U",
      "user_ratings_total": 1761.0
    },
    {
      "geocode": [33.63219975, -117.9136436],
      "Rest_Name": "Costa Mesa",
      "address": "350 E 17th Street",
      "id": 10058,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJpweBogDf3IAReWdowgzcsag",
      "user_ratings_total": 548.0
    },
    {
      "geocode": [42.34401837, -71.09922998],
      "Rest_Name": "Fenway",
      "address": "1346 Boylston Street",
      "id": 10060,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJm0LFxvV544kR6CAUr4kduZU",
      "user_ratings_total": 662.0
    },
    {
      "geocode": [42.34997125, -71.0788315],
      "Rest_Name": "Back Bay",
      "address": "669 Boylston Street",
      "id": 10061,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJXdQ1GeJ744kRlEv_a8e4Nz0",
      "user_ratings_total": 634.0
    },
    {
      "geocode": [35.05611703, -80.76910924],
      "Rest_Name": "Waverly",
      "address": "6307 Providence Farm Lane",
      "id": 10064,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJEVgCmssnVIgRzKYKOKi-KB8",
      "user_ratings_total": 1119.0
    },
    {
      "geocode": [42.17737669, -70.90893832],
      "Rest_Name": "Hingham",
      "address": "94 Derby Street",
      "id": 10066,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJX7vFaEJj44kRkVG9glVBWqM",
      "user_ratings_total": 448.0
    },
    {
      "geocode": [42.23117677, -71.17743412],
      "Rest_Name": "Dedham",
      "address": "724 Legacy Place",
      "id": 10068,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ_59Bde9_44kRVTmAasopogs",
      "user_ratings_total": 597.0
    },
    {
      "geocode": [35.44587225, -80.87994826],
      "Rest_Name": "Huntersville",
      "address": "16845 Birkdale Commons Parkway",
      "id": 10069,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJI0_PmmepVogRgbzPHjcG_Zw",
      "user_ratings_total": 1000.0
    },
    {
      "geocode": [35.9350043, -79.02822104],
      "Rest_Name": "Chapel Hill",
      "address": "79 S Elliot Road",
      "id": 10072,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJpYRQ_ardrIkRatYPeI_Vbew",
      "user_ratings_total": 854.0
    },
    {
      "geocode": [34.14005072, -118.1325228],
      "Rest_Name": "Pasadena",
      "address": "345 S Lake Avenue",
      "id": 10075,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJbxqeVHPDwoAR6SBBOUZdLBc",
      "user_ratings_total": 595.0
    },
    {
      "geocode": [33.06610907, -117.2656313],
      "Rest_Name": "Encinitas",
      "address": "1042 N El Camino Real",
      "id": 10076,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ0XIk9PcL3IARC9VezSUUg5c",
      "user_ratings_total": 472.0
    },
    {
      "geocode": [35.17376571, -80.85003564],
      "Rest_Name": "Park Road",
      "address": "4267 Park Road",
      "id": 10077,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJLc_KYHSfVogRFyHC92WS6C4",
      "user_ratings_total": 1104.0
    },
    {
      "geocode": [42.36312705, -71.08786847],
      "Rest_Name": "Kendall Square",
      "address": "82 Ames Street",
      "id": 10078,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJOxJ7RKRx44kRMSVvrqnnwpo",
      "user_ratings_total": 667.0
    },
    {
      "geocode": [35.78934895, -78.6616592],
      "Rest_Name": "Cameron Village",
      "address": "403 Daniels Street",
      "id": 10084,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ4ye9uqr1rIkROqQHPzNnYPc",
      "user_ratings_total": 890.0
    },
    {
      "geocode": [33.86759876, -117.7493747],
      "Rest_Name": "Anaheim Hills",
      "address": "8162 E Santa Ana Canyon Road",
      "id": 10085,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJWylRSofR3IARw4i3yQF4frY",
      "user_ratings_total": 58.0
    },
    {
      "geocode": [35.75895, -78.74160059],
      "Rest_Name": "Cary",
      "address": "2001 Walnut Street",
      "id": 10087,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ-73XynH1rIkR8bfF6gT3RpY",
      "user_ratings_total": 1444.0
    },
    {
      "geocode": [32.95124406, -117.2359221],
      "Rest_Name": "One Paseo",
      "address": "3722 Paseo Place",
      "id": 10090,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJPaanN5wI3IARpiaGObsE794",
      "user_ratings_total": 395.0
    },
    {
      "geocode": [35.20731353, -80.86105774],
      "Rest_Name": "Southend",
      "address": "2126 South Boulevard",
      "id": 10093,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJf7KI1EGfVogRAGMxDQR1Ink",
      "user_ratings_total": 694.0
    },
    {
      "geocode": [35.8217322, -78.62229725],
      "Rest_Name": "Midtown East",
      "address": "1100 Mercantile Drive",
      "id": 10094,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJJdoc8eJYwokR5uIqF2WmdzQ",
      "user_ratings_total": 358.0
    },
    {
      "geocode": [32.76994276, -117.1470828],
      "Rest_Name": "Mission Valley",
      "address": "1652 Camino Del Rio N",
      "id": 10097,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJH7jDqq9V2YARQj1zJFofvhI",
      "user_ratings_total": 627.0
    },
    {
      "geocode": [32.86952315, -117.2316374],
      "Rest_Name": "La Jolla",
      "address": "8849 Villa La Jolla Drive",
      "id": 10102,
      "type": "CAVA",
      "rating": 4.0,
      "place_id": "ChIJu1fHlnsH3IARIW5JbGQVkuc",
      "user_ratings_total": 308.0
    },
    {
      "geocode": [42.50497004, -71.13392337],
      "Rest_Name": "Woburn",
      "address": "300 Mishawum Road",
      "id": 10112,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJbSSrt5l144kRVfSTbiL_z4I",
      "user_ratings_total": 394.0
    },
    {
      "geocode": [36.09247662, -80.28015829],
      "Rest_Name": "Winston-Salem",
      "address": "205 S Stratford Road",
      "id": 10115,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJSe_8V1mxU4gRcx4xZn4ppXM",
      "user_ratings_total": 457.0
    },
    {
      "geocode": [35.0722671, -78.96082546],
      "Rest_Name": "Fayetteville",
      "address": "565 Cross Creek Mall",
      "id": 10116,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJa9PFgiNrq4kRund8_it4N24",
      "user_ratings_total": 591.0
    },
    {
      "geocode": [35.80755766, -78.8146412],
      "Rest_Name": "Park West",
      "address": "1017 Market Center Drive",
      "id": 10117,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJHb0wn6XzrIkRheWwUxtkV14",
      "user_ratings_total": 436.0
    },
    {
      "geocode": [35.86544497, -78.62000851],
      "Rest_Name": "North Ridge",
      "address": "6004 Falls of Neuse Road",
      "id": 10128,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJh2R3osRZrIkRhVdfQ_K0N0s",
      "user_ratings_total": 379.0
    },
    {
      "geocode": [33.85574234, -84.3822648],
      "Rest_Name": "North Buckhead",
      "address": "3655 Roswell Road",
      "id": 10129,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJIdOpXhEP9YgRSfcR4c3PS5E",
      "user_ratings_total": 806.0
    },
    {
      "geocode": [33.92926769, -84.347673],
      "Rest_Name": "Perimeter",
      "address": "1165 Perimeter Center W",
      "id": 10130,
      "type": "CAVA",
      "rating": 3.8,
      "place_id": "ChIJVY1FPdYJ9YgRh1s6krAMZTY",
      "user_ratings_total": 819.0
    },
    {
      "geocode": [33.94847248, -83.40909345],
      "Rest_Name": "Athens",
      "address": "145 Alps Road",
      "id": 10134,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJ21Nf8z1t9ogRw8REhLg8DxI",
      "user_ratings_total": 1317.0
    },
    {
      "geocode": [33.82734802, -84.33179213],
      "Rest_Name": "Druid Hills",
      "address": "2480 Briarcliff Road NE",
      "id": 10136,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJ-YbtVDAH9YgRuzo3-EtDy7M",
      "user_ratings_total": 602.0
    },
    {
      "geocode": [33.98063497, -84.21463202],
      "Rest_Name": "Peachtree Corners",
      "address": "5150 Peachtree Parkway",
      "id": 10139,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJCcqlp3ih9YgR6bepcnyMMz4",
      "user_ratings_total": 736.0
    },
    {
      "geocode": [33.88480579, -84.3156683],
      "Rest_Name": "Chamblee",
      "address": "5001 Peachtree Boulevard",
      "id": 10141,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJExEBt0wJ9YgRvKIWz07cpb4",
      "user_ratings_total": 536.0
    },
    {
      "geocode": [33.99896153, -84.5905544],
      "Rest_Name": "Kennesaw",
      "address": "1555 Crater Lake Drive",
      "id": 10145,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJw14EP2EV9YgRBe76gewzYtQ",
      "user_ratings_total": 567.0
    },
    {
      "geocode": [33.79477449, -84.28765465],
      "Rest_Name": "Decatur",
      "address": "2501 Blackmon Dr",
      "id": 10148,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJfUTkXukH9YgR_skWWToJEUY",
      "user_ratings_total": 1063.0
    },
    {
      "geocode": [34.06951751, -83.98432989],
      "Rest_Name": "Mall of Georgia",
      "address": "3385 Woodward Crossing Boulevard",
      "id": 10150,
      "type": "CAVA",
      "rating": 4.0,
      "place_id": "ChIJEamIf_WV9YgRwtN2YqObrro",
      "user_ratings_total": 794.0
    },
    {
      "geocode": [33.81882368, -84.38786798],
      "Rest_Name": "Peachtree",
      "address": "2333-A Peachtree Street NE",
      "id": 10152,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ7dCDMiMF9YgRlS7BmEOjni4",
      "user_ratings_total": 841.0
    },
    {
      "geocode": [33.902548, -118.3943433],
      "Rest_Name": "El Segundo",
      "address": "860 S Pacific Coast Highway",
      "id": 10154,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ2aNmZ9KzwoAR2KI5JMlphiw",
      "user_ratings_total": 269.0
    },
    {
      "geocode": [34.06308682, -84.39618945],
      "Rest_Name": "Roswell Corners",
      "address": "1105 Woodstock Road",
      "id": 10155,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJQWQkSa9z9YgRvIkwFtkyeiI",
      "user_ratings_total": 346.0
    },
    {
      "geocode": [33.88126927, -84.46319355],
      "Rest_Name": "Cumberland",
      "address": "2935 Cobb Parkway SE",
      "id": 10157,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ446fYp8R9YgRUQSHhyCJ4eY",
      "user_ratings_total": 1459.0
    },
    {
      "geocode": [34.05217426, -84.28611257],
      "Rest_Name": "Alpharetta",
      "address": "10920 Haynes Bridge Road",
      "id": 10159,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJCzuVs8519YgR39qiZxqewLM",
      "user_ratings_total": 542.0
    },
    {
      "geocode": [34.15296449, -84.17612856],
      "Rest_Name": "Cumming",
      "address": "410 Peachtree Parkway",
      "id": 10161,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJQ1ZkcNab9YgRUX351JI4UPc",
      "user_ratings_total": 589.0
    },
    {
      "geocode": [34.24190342, -77.82840232],
      "Rest_Name": "Wilmington",
      "address": "1011 International Drive",
      "id": 10164,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJTbqp3R_zqYkRXgu05k4qrUw",
      "user_ratings_total": 317.0
    },
    {
      "geocode": [35.21290363, -80.83484486],
      "Rest_Name": "Midtown Charlotte",
      "address": "1055 Metropolitan Ave",
      "id": 10166,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJ74uwysCfVogRPj8xtxd76TY",
      "user_ratings_total": 655.0
    },
    {
      "geocode": [36.05359072, -79.90876324],
      "Rest_Name": "Wendover",
      "address": "4524 W Wendover Avenue",
      "id": 10168,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJTWtPbmAbU4gR5SQ0fUJKQqw",
      "user_ratings_total": 730.0
    },
    {
      "geocode": [34.27524714, -118.5731871],
      "Rest_Name": "Porter Ranch",
      "address": "20165 Rinaldi Street",
      "id": 10174,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJz75AQr2FwoARFkXD_Bqordo",
      "user_ratings_total": 282.0
    },
    {
      "geocode": [30.18953061, -81.62796479],
      "Rest_Name": "Mandarin",
      "address": "10422 San Jose Blvd",
      "id": 10178,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJmd9b2uPJ5YgRzE94b0V8zmY",
      "user_ratings_total": 143.0
    },
    {
      "geocode": [30.14187, -81.54665],
      "Rest_Name": "Bartram Village",
      "address": "13920 Old St. Augustine Rd",
      "id": 10183,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJNdYVKHbN5YgR2eaIUDwemFQ",
      "user_ratings_total": 180.0
    },
    {
      "geocode": [30.3239102, -81.6718449],
      "Rest_Name": "Riverside",
      "address": "90 Riverside Avenue",
      "id": 10185,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJO6QG2jeYwokRsK4LIvUtGgE",
      "user_ratings_total": 271.0
    },
    {
      "geocode": [30.25978, -81.52759],
      "Rest_Name": "St. Johns",
      "address": "4624 Town Crossing Dr.",
      "id": 10189,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJewwiCLy15YgRZWjVOdoKZUM",
      "user_ratings_total": 358.0
    },
    {
      "geocode": [29.62401, -82.27837],
      "Rest_Name": "Butler Plaza",
      "address": "3730 SW Archer Rd",
      "id": 10190,
      "type": "CAVA",
      "rating": 3.8,
      "place_id": "ChIJQ3omvRGj6IgRmkOdvJB8x1Q",
      "user_ratings_total": 242.0
    },
    {
      "geocode": [32.04402011, -81.07244716],
      "Rest_Name": "Savannah",
      "address": "1821 East Victory Drive",
      "id": 10191,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJU9p7SbKd-4gRZuA_RZnwuw8",
      "user_ratings_total": 254.0
    },
    {
      "geocode": [33.91487575, -84.37961643],
      "Rest_Name": "Sandy Springs",
      "address": "5840 Roswell Road",
      "id": 10192,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJV6vVWVAP9YgRo68lzgVcs9Q",
      "user_ratings_total": 275.0
    },
    {
      "geocode": [30.46385, -84.27037],
      "Rest_Name": "Tallahassee",
      "address": "1817 Thomasville Rd",
      "id": 10197,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ8-_YmQf17IgRcezo8i3GUt4",
      "user_ratings_total": 200.0
    },
    {
      "geocode": [30.22211, -85.86665],
      "Rest_Name": "Panama City Beach",
      "address": "15620 Panama City Beach Pkwy",
      "id": 10203,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJ40ppkt6Nk4gRXaPG8QNtmPI",
      "user_ratings_total": 189.0
    },
    {
      "geocode": [36.08838061, -79.83946],
      "Rest_Name": "Greensboro",
      "address": "3352 West Friendly Avenue",
      "id": 10205,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJU9jBu64bU4gRvs8vcAKm8aA",
      "user_ratings_total": 345.0
    },
    {
      "geocode": [35.03461188, -80.808466],
      "Rest_Name": "Blakeney",
      "address": "9848 Rea Road",
      "id": 10209,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJSVvUYCspVIgRO58tv9YiEfE",
      "user_ratings_total": 339.0
    },
    {
      "geocode": [30.67707, -88.1515],
      "Rest_Name": "Mobile",
      "address": "3980 Airport Blvd.",
      "id": 10213,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJdVK9HwBNmogRsNa_L8EQazc",
      "user_ratings_total": 134.0
    },
    {
      "geocode": [28.60555219, -81.36681832],
      "Rest_Name": "Winter Park",
      "address": "501 North Orlando Avenue",
      "id": 10216,
      "type": "CAVA",
      "rating": 4.0,
      "place_id": "ChIJH_mDG2Vx54gRkt0keTg32a8",
      "user_ratings_total": 255.0
    },
    {
      "geocode": [30.47484, -87.2113],
      "Rest_Name": "Pensacola",
      "address": "5100 N. 9th Ave.",
      "id": 10219,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJc4WDh5HBkIgROCRPPx8gU9M",
      "user_ratings_total": 288.0
    },
    {
      "geocode": [34.10552336, -84.5388282],
      "Rest_Name": "Woodstock",
      "address": "1426 Towne Lake Parkway",
      "id": 10225,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJRb4h3mRr9YgRNQ4l-MoClJI",
      "user_ratings_total": 114.0
    },
    {
      "geocode": [28.64806193, -81.26646863],
      "Rest_Name": "Winter Springs",
      "address": "5930 Red Bug Lake Road",
      "id": 10227,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJjXFAYsRv54gReKXAt337w4Q",
      "user_ratings_total": 357.0
    },
    {
      "geocode": [36.11279285, -94.14595281],
      "Rest_Name": "College Marketplace",
      "address": "3379 North College Ave",
      "id": 10228,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJmbFfFlJryYcRxtxBJHqUxBQ",
      "user_ratings_total": 198.0
    },
    {
      "geocode": [33.39757047, -84.6026325],
      "Rest_Name": "Peachtree City",
      "address": "2777 SR-54 W",
      "id": 10229,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJKyJTob7r9IgRyPL_LYtbojo",
      "user_ratings_total": 87.0
    },
    {
      "geocode": [28.51250618, -81.37510808],
      "Rest_Name": "Sodo",
      "address": "236 E Michigan Street",
      "id": 10231,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJQVcNEcp754gRgOviLoGW-4E",
      "user_ratings_total": 281.0
    },
    {
      "geocode": [36.33534665, -94.176645],
      "Rest_Name": "Rogers",
      "address": "4007 West Walnut Street",
      "id": 10232,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJt8bCPkIRyYcRAG4nc_pmS2Q",
      "user_ratings_total": 414.0
    },
    {
      "geocode": [34.02131485, -84.1210048],
      "Rest_Name": "Duluth",
      "address": "2200 Peachtree Industrial Blvd.",
      "id": 10234,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJYSEIjTCZ9YgRBb3tKc4F3ZE",
      "user_ratings_total": 248.0
    },
    {
      "geocode": [38.88346842, -94.66018082],
      "Rest_Name": "Corbin Park",
      "address": "6405 West 135th Street",
      "id": 10236,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJRzV6sQHBwIcRYDSqvtgID80",
      "user_ratings_total": 256.0
    },
    {
      "geocode": [32.54267445, -84.94973965],
      "Rest_Name": "Columbus",
      "address": "5413 Whittlesey Blvd.",
      "id": 10238,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ_SSHCMTLjIgRCVenTfP1OGE",
      "user_ratings_total": 224.0
    },
    {
      "geocode": [27.38768129, -82.46552467],
      "Rest_Name": "Sarasota",
      "address": "5248 University Parkway",
      "id": 10240,
      "type": "CAVA",
      "rating": 3.8,
      "place_id": "ChIJHa9Gqv45w4gRdQBdWF_tWqs",
      "user_ratings_total": 261.0
    },
    {
      "geocode": [38.95621146, -94.7227465],
      "Rest_Name": "Oak Park",
      "address": "11895 West 95th Street",
      "id": 10241,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJySRquj_rwIcRlD9WpxZOqFg",
      "user_ratings_total": 237.0
    },
    {
      "geocode": [33.78900812, -84.32700435],
      "Rest_Name": "Emory",
      "address": "1385 Oxford Rd. NE",
      "id": 10242,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJ3THER94H9YgRbMtXYb4XejE",
      "user_ratings_total": 143.0
    },
    {
      "geocode": [33.88355883, -84.00911319],
      "Rest_Name": "Snellville",
      "address": "1630 Scenic Highway 12 North",
      "id": 10243,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJC8dJhqW59YgRw9JoB59_ZZE",
      "user_ratings_total": 504.0
    },
    {
      "geocode": [27.93403162, -82.48283334],
      "Rest_Name": "South Howard",
      "address": "936 S Howard Avenue",
      "id": 10245,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ_fzQZz7DwogRQmwhdlHCfp8",
      "user_ratings_total": 264.0
    },
    {
      "geocode": [32.35652221, -86.26529409],
      "Rest_Name": "Zelda",
      "address": "2960 C Zelda Road",
      "id": 10249,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJy9-UIpSBjogRU9lhpPgIJho",
      "user_ratings_total": 356.0
    },
    {
      "geocode": [28.08713, -82.50491],
      "Rest_Name": "Carrollwood",
      "address": "15028 N Dale Mabry Hwy",
      "id": 10252,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJXZAoPGjBwogR9xHtb7S8nxo",
      "user_ratings_total": 261.0
    },
    {
      "geocode": [33.41988582, -86.68228661],
      "Rest_Name": "Brook Highlands",
      "address": "5247 Highway 280",
      "id": 10254,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJSRaKVaQXiYgRlf6D25Sj-ls",
      "user_ratings_total": 363.0
    },
    {
      "geocode": [33.47982, -86.79001],
      "Rest_Name": "Soho",
      "address": "1830 29th Ave S",
      "id": 10259,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJhdpHGjwZiYgRa98Nz7bqE0s",
      "user_ratings_total": 460.0
    },
    {
      "geocode": [35.08630796, -80.84899722],
      "Rest_Name": "Carmel Commons",
      "address": "7631 Pineville Matthews Road",
      "id": 10262,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJD2zu-G2dVogR9_wAlD9a-_Y",
      "user_ratings_total": 198.0
    },
    {
      "geocode": [34.69392, -86.56872],
      "Rest_Name": "Whitesburg",
      "address": "4800 Whitesburg Drive",
      "id": 10267,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJsay2sPUTYogR3BtBQxsDgBg",
      "user_ratings_total": 351.0
    },
    {
      "geocode": [26.54734, -81.84122],
      "Rest_Name": "Ft. Myers",
      "address": "6881 Daniels Pkwy",
      "id": 10270,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJIxZTjz0V24gRvQHFk1H10vI",
      "user_ratings_total": 173.0
    },
    {
      "geocode": [27.78550408, -82.63876406],
      "Rest_Name": "St Petersburg",
      "address": "1320 4th Street N",
      "id": 10271,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJRY634bnhwogR5Lfli4L8f28",
      "user_ratings_total": 288.0
    },
    {
      "geocode": [33.9857899, -84.42215484],
      "Rest_Name": "East Cobb",
      "address": "4400 Roswell Road",
      "id": 10273,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ99_XRYYN9YgRt2L8o6DrMt8",
      "user_ratings_total": 429.0
    },
    {
      "geocode": [42.37325141, -71.12033736],
      "Rest_Name": "Harvard Square",
      "address": "22 Brattle Street",
      "id": 10275,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ9wRb_lF344kRi-311jFhdnE",
      "user_ratings_total": 116.0
    },
    {
      "geocode": [42.34755055, -71.08261607],
      "Rest_Name": "Prudential Center",
      "address": "800 Boylston Street",
      "id": 10280,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJK4YA20V744kRHkLnJHZ3V8A",
      "user_ratings_total": 119.0
    },
    {
      "geocode": [34.21498, -77.90601],
      "Rest_Name": "Oleander Drive",
      "address": "3520 Oleander Dr",
      "id": 10282,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJW9GfgQv1qYkRHuxkPK5L1Kg",
      "user_ratings_total": 325.0
    },
    {
      "geocode": [42.30189609, -71.39507223],
      "Rest_Name": "Framingham",
      "address": "1 Worcester Road",
      "id": 10285,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJT94N4AyJ44kRrOzpSvL3PtI",
      "user_ratings_total": 248.0
    },
    {
      "geocode": [33.510135, -86.802524],
      "Rest_Name": "20 Midtown",
      "address": "241 20TH ST S",
      "id": 10288,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJPzMY3wMbiYgRG6zRsEHTOO0",
      "user_ratings_total": 239.0
    },
    {
      "geocode": [33.55530966, -117.7134017],
      "Rest_Name": "Laguna Niguel",
      "address": "23870 Aliso Creek Rd",
      "id": 10289,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJrfdn5Orv3IARLNxiKo5hhho",
      "user_ratings_total": 25.0
    },
    {
      "geocode": [34.75064758, -86.76117084],
      "Rest_Name": "Huntsville AL - Clift Farm",
      "address": "8254 Highway 72 West Building 250",
      "id": 10304,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJV-19a19nYogRDxRwy6fPea4",
      "user_ratings_total": 181.0
    },
    {
      "geocode": [29.65238228, -82.34082588],
      "Rest_Name": "Gainesville FL - W. University",
      "address": "1404 W University Avenue",
      "id": 10306,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJKTk5SJqj6IgRqXalehN0gps",
      "user_ratings_total": 105.0
    },
    {
      "geocode": [27.94455276, -82.45051479],
      "Rest_Name": "Tampa FL - Water Street",
      "address": "904 E Eunice Ave",
      "id": 10312,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJYyy7AnLFwogR5qi7CFPUeh4",
      "user_ratings_total": 138.0
    },
    {
      "geocode": [42.35311855, -71.05750657],
      "Rest_Name": "Downtown Crossing",
      "address": "125 Summer Street",
      "id": 10317,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ8b2yNa5x44kRXPouJ33PLHE",
      "user_ratings_total": 102.0
    },
    {
      "geocode": [28.75693472, -81.35846738],
      "Rest_Name": "Lake Mary",
      "address": "901 Currency Circle",
      "id": 10318,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJd6gJHkAl54gRgLZwv1te-zU",
      "user_ratings_total": 203.0
    },
    {
      "geocode": [35.56947045, -82.54397784],
      "Rest_Name": "Asheville",
      "address": "14 Swannanoa River Road",
      "id": 10325,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ__dX4M_zWYgRQ1qMfVqGFvo",
      "user_ratings_total": 350.0
    },
    {
      "geocode": [35.57477995, -77.38305886],
      "Rest_Name": "Greenville NC",
      "address": "3120 Evans St",
      "id": 10326,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJZfngp1_DrokRvivutLTI-v4",
      "user_ratings_total": 111.0
    },
    {
      "geocode": [33.37687764, -86.80523523],
      "Rest_Name": "Hoover AL - Riverchase",
      "address": "3076 John Hawkins Parkway",
      "id": 10329,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJBZUpAYkjiYgRaVAH5QsmFIU",
      "user_ratings_total": 118.0
    },
    {
      "geocode": [33.97811439, -118.0493359],
      "Rest_Name": "Whittier",
      "address": "11840 Whittier Blvd",
      "id": 10332,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJDYc12vDTwoARblmrZ6AsYrE",
      "user_ratings_total": 162.0
    },
    {
      "geocode": [28.4932181, -81.42864633],
      "Rest_Name": "Millenia",
      "address": "4724 Millenia Plaza Way",
      "id": 10333,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJUSgY66h_54gRgNpVosYLhUA",
      "user_ratings_total": 285.0
    },
    {
      "geocode": [34.10708374, -117.5640498],
      "Rest_Name": "Rancho Cucamonga CA - Foothill",
      "address": "11010 Foothill Blvd",
      "id": 10335,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJf37AW9w1w4ARurRCcPbxaF4",
      "user_ratings_total": 124.0
    },
    {
      "geocode": [33.69841253, -117.7412597],
      "Rest_Name": "Irvine",
      "address": "6416 Irvine Blvd",
      "id": 10029,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ13gkNTTc3IAR-C-h8vR8ASA",
      "user_ratings_total": 1916.0
    },
    {
      "geocode": [34.68450492, -86.71774034],
      "Rest_Name": "Huntsville AL - Town Madison",
      "address": "101 Outfield Drive",
      "id": 10339,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJQcQIwclvYogRM8qLVTjqUA8",
      "user_ratings_total": 428.0
    },
    {
      "geocode": [26.49373813, -81.78555355],
      "Rest_Name": "Fort Myers - Alico Road",
      "address": "16441 Corporate Commerce Way #100",
      "id": 10372,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [35.375137, -80.72877529],
      "Rest_Name": "Charlotte NC 2 - Concord Mills",
      "address": "8680 Concord Mills Blvd.",
      "id": 10375,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJ6-bTpGsdVIgRmuC_7EHEXZA",
      "user_ratings_total": 128.0
    },
    {
      "geocode": [42.01370081, -71.23015249],
      "Rest_Name": "Mansfield MA - Mansfield Crossing",
      "address": "280 School St D135",
      "id": 10377,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJb3Hhq4Vj5IkROfXDYmtaQYM",
      "user_ratings_total": 55.0
    },
    {
      "geocode": [27.94004436, -82.32222265],
      "Rest_Name": "Brandon FL - Regency Square",
      "address": "2338 W Brandon Blvd",
      "id": 10386,
      "type": "CAVA",
      "rating": 3.9,
      "place_id": "ChIJWWBFG0zPwogRcVVdJoY_hrE",
      "user_ratings_total": 47.0
    },
    {
      "geocode": [28.65703964, -81.2087204],
      "Rest_Name": "Orlando FL - Oviedo",
      "address": "45 W Mitchell Hammock Rd",
      "id": 10388,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJ1egy6d1p54gRAHSuUmyl4a4",
      "user_ratings_total": 64.0
    },
    {
      "geocode": [33.73689827, -84.38780293],
      "Rest_Name": "Atlanta GA - Summerhill Station",
      "address": "572 Hank Aaron Dr SE",
      "id": 10392,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJw7kfHFsD9YgRkU4v-2HwWzU",
      "user_ratings_total": 150.0
    },
    {
      "geocode": [30.44783836, -84.31334706],
      "Rest_Name": "Tallahassee FL - W Tennessee",
      "address": "1919 W Tennessee Street",
      "id": 10409,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJYQ4nDiL17IgRlWFnKwEujCI",
      "user_ratings_total": 40.0
    },
    {
      "geocode": [35.96616339, -78.53341923],
      "Rest_Name": "Wake Forest",
      "address": "515 Dr. Calvin Jones Highway",
      "id": 10468,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJ-y94HsZRrIkRxh60clqdPyY",
      "user_ratings_total": 54.0
    },
    {
      "geocode": [33.49466543, -111.9881442],
      "Rest_Name": "Arcadia",
      "address": "4355 East Indian School Road",
      "id": 10175,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJofsXEA4NK4cRKIGZ9ooUjSY",
      "user_ratings_total": 347.0
    },
    {
      "geocode": [33.61870653, -111.925202],
      "Rest_Name": "North Scottsdale",
      "address": "14601 North Scottsdale Road",
      "id": 10176,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJddrmsIJ1K4cRn3Ksl4Tqh78",
      "user_ratings_total": 253.0
    },
    {
      "geocode": [33.51019214, -112.0461967],
      "Rest_Name": "Camelback",
      "address": "1641 East Camelback Rd",
      "id": 10177,
      "type": "CAVA",
      "rating": 3.8,
      "place_id": "ChIJEap078gNK4cRbo_r4-TMPE8",
      "user_ratings_total": 285.0
    },
    {
      "geocode": [33.42243688, -111.9254246],
      "Rest_Name": "Tempe",
      "address": "920 E University Dr",
      "id": 10298,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJ4bhASB0JK4cRAYa0hc3w0x0",
      "user_ratings_total": 90.0
    },
    {
      "geocode": [34.05881, -118.41873],
      "Rest_Name": "Century City Mall 2.0",
      "address": "10250 Santa Monica Blvd",
      "id": 10500,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.98125077, -117.0816877],
      "Rest_Name": "San Diego",
      "address": "11640 Carmel Mountain Rd.",
      "id": 10355,
      "type": "CAVA",
      "rating": 3.9,
      "place_id": "ChIJ272biOj524AR2L6PUK6KMQg",
      "user_ratings_total": 55.0
    },
    {
      "geocode": [34.15161343, -118.4535591],
      "Rest_Name": "Sherman Oaks CA - Sherman Oaks",
      "address": "14708 Ventura Blvd",
      "id": 10357,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJx0JsHx2XwoARe8gjnaDJn3E",
      "user_ratings_total": 10.0
    },
    {
      "geocode": [34.00158526, -117.7299366],
      "Rest_Name": "Chino Hills CA - Chino Hills",
      "address": "13855 City Center Dr.",
      "id": 10362,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJ0_FuWRYzw4ARnbjJUqSXXwg",
      "user_ratings_total": 137.0
    },
    {
      "geocode": [34.09765711, -118.323999],
      "Rest_Name": "Los Angeles CA - Hollywood",
      "address": "6200 Sunset Blvd.",
      "id": 10501,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [33.79173183, -118.1396141],
      "Rest_Name": "Long Beach CA - Traffic Circle",
      "address": "4680 E Los Coyotes Diagonal",
      "id": 10400,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJa0PsYwAx3YARLSQF-U33r-s",
      "user_ratings_total": 54.0
    },
    {
      "geocode": [39.73157551, -104.9397119],
      "Rest_Name": "9th and Colorado",
      "address": "4084 E 8th Place",
      "id": 10105,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJC9R316t_bIcR4ruVm2RW5Pg",
      "user_ratings_total": 410.0
    },
    {
      "geocode": [40.01700417, -105.2571661],
      "Rest_Name": "Boulder",
      "address": "1695 29th Street",
      "id": 10106,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJl4D2YKbta4cRFAN8KJeW4Zw",
      "user_ratings_total": 522.0
    },
    {
      "geocode": [39.67978562, -104.9412471],
      "Rest_Name": "Colorado Blvd South",
      "address": "2023 S Colorado Boulevard",
      "id": 10108,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJryAtZhN_bIcRANOJnxmDDh0",
      "user_ratings_total": 655.0
    },
    {
      "geocode": [39.91242985, -105.0459993],
      "Rest_Name": "Westminster",
      "address": "4550 Main Street",
      "id": 10109,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJDVE_U1aLa4cR7poUa0wC6kA",
      "user_ratings_total": 666.0
    },
    {
      "geocode": [39.55402373, -105.007633],
      "Rest_Name": "Highlands Ranch",
      "address": "1515 Park Central Drive",
      "id": 10110,
      "type": "CAVA",
      "rating": 4.0,
      "place_id": "ChIJI2SJiTGDbIcRzFOGyANPozw",
      "user_ratings_total": 429.0
    },
    {
      "geocode": [40.54426994, -105.0763889],
      "Rest_Name": "Fort Collins",
      "address": "3100 S College Avenue",
      "id": 10111,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJY2W0BzJLaYcRarp9e3brcwY",
      "user_ratings_total": 380.0
    },
    {
      "geocode": [39.41043475, -104.8734811],
      "Rest_Name": "Castle Rock",
      "address": "4987 Factory Shops Boulevard",
      "id": 10167,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ2QtXeraZbIcRzhmpo_db1rw",
      "user_ratings_total": 374.0
    },
    {
      "geocode": [39.75024, -104.99901],
      "Rest_Name": "Downtown Denver",
      "address": "1460 16th Street",
      "id": 10200,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJS5Q7ha15bIcRN41JfxT6fqI",
      "user_ratings_total": 169.0
    },
    {
      "geocode": [39.79799479, -105.0776121],
      "Rest_Name": "Arvada",
      "address": "7220 W 56th Ave",
      "id": 10279,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJG1Bc5ISHa4cR70z8kE9uREU",
      "user_ratings_total": 137.0
    },
    {
      "geocode": [39.59562561, -104.8912464],
      "Rest_Name": "Denver CO - DTC South",
      "address": "8507 E Arapahoe Rd",
      "id": 10352,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [41.0276203, -73.63042367],
      "Rest_Name": "Greenwich",
      "address": "129 West Putnam Avenue",
      "id": 10049,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJO6QG2jeYwokRsK4LIvUtGgE",
      "user_ratings_total": 271.0
    },
    {
      "geocode": [41.13832119, -73.34450169],
      "Rest_Name": "Westport CT - Westport Row",
      "address": "620 Post Road East",
      "id": 10376,
      "type": "CAVA",
      "rating": 4.8,
      "place_id": "ChIJN8Tp9BQb6IkR34ZAQ-nIlZY",
      "user_ratings_total": 21.0
    },
    {
      "geocode": [38.90667031, -77.04198468],
      "Rest_Name": "Dupont",
      "address": "1222 Connecticut Avenue NW",
      "id": 10015,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJLQEDgbi3t4kR5mQ1nW5fN4E",
      "user_ratings_total": 667.0
    },
    {
      "geocode": [38.91639148, -77.02243279],
      "Rest_Name": "Shaw",
      "address": "1921 8th Street NW",
      "id": 10022,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJBTeBR-W3t4kRFHZwI_lzpUg",
      "user_ratings_total": 454.0
    },
    {
      "geocode": [38.89747819, -77.00694166],
      "Rest_Name": "Union Station",
      "address": "50 Massachusetts Ave NE",
      "id": 10053,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ-3xSyCa4t4kR0f6-_5R_Sfo",
      "user_ratings_total": 196.0
    },
    {
      "geocode": [39.67208109, -75.64888201],
      "Rest_Name": "Christiana",
      "address": "2150 Fashion Center Blvd",
      "id": 10163,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJh2YN740Bx4kRoDWDhwVMKIE",
      "user_ratings_total": 315.0
    },
    {
      "geocode": [27.84120068, -82.79378855],
      "Rest_Name": "Seminole FL - Seminole City Center",
      "address": "11165 Park Blvd",
      "id": 10502,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [41.90890092, -87.67515709],
      "Rest_Name": "Chicago IL - Wicker Park",
      "address": "1484 North Milwaukee Ave",
      "id": 10503,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.34934658, -91.02523881],
      "Rest_Name": "Highland Marketplace",
      "address": "18107 Highland Market Dr.",
      "id": 10504,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.38034238, -91.09586686],
      "Rest_Name": "Perkins Rowe",
      "address": "7707 Bluebonnet Blvd.",
      "id": 10505,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.43156401, -91.11279805],
      "Rest_Name": "Baton Rouge",
      "address": "7415 Corporate Blvd. Bldg. H",
      "id": 10506,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.96021588, -90.18443559],
      "Rest_Name": "Elmwood",
      "address": "1208 S. Clearview Pkwy",
      "id": 10507,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.0056626, -90.17965157],
      "Rest_Name": "Veterans",
      "address": "4243 Veterans Memorial Blvd.",
      "id": 10508,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.39459485, -91.15962447],
      "Rest_Name": "Baton Rouge LA - LSU",
      "address": "5295 Highland Road",
      "id": 10509,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [38.98080923, -77.09622816],
      "Rest_Name": "Bethesda",
      "address": "4832 Bethesda Avenue",
      "id": 10001,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ-ybqC3zJt4kRr78j35LipV8",
      "user_ratings_total": 516.0
    },
    {
      "geocode": [39.1259491, -77.23765768],
      "Rest_Name": "Kentlands",
      "address": "213 Kentlands Boulevard",
      "id": 10007,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ7SAaIp0ttokR_JpgTHJEFkQ",
      "user_ratings_total": 1020.0
    },
    {
      "geocode": [39.02487638, -77.14706611],
      "Rest_Name": "Montgomery Mall",
      "address": "7101 Democracy Boulevard",
      "id": 10008,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJCRGoOH7Mt4kRpGRtoC_dOp4",
      "user_ratings_total": 278.0
    },
    {
      "geocode": [38.99663169, -77.02480309],
      "Rest_Name": "Silver Spring",
      "address": "8515 Fenton Street",
      "id": 10014,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJOwqIYrrIt4kR_3_nTx06zxc",
      "user_ratings_total": 825.0
    },
    {
      "geocode": [39.20068663, -76.81268623],
      "Rest_Name": "Dobbin Station",
      "address": "6181 Old Dobbin Lane",
      "id": 10017,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJYQFTrm_gt4kRJrHgUYKcwQU",
      "user_ratings_total": 2252.0
    },
    {
      "geocode": [39.11339197, -77.1859083],
      "Rest_Name": "Shady Grove",
      "address": "28 Upper Rock Circle",
      "id": 10031,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJr8KHxqjSt4kRyO2lxPg-Y2Q",
      "user_ratings_total": 1017.0
    },
    {
      "geocode": [39.05497369, -77.1174158],
      "Rest_Name": "Rockville Pike",
      "address": "12037 Rockville Pike",
      "id": 10040,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJtWKKwzPMt4kRtaEPSTgRKW8",
      "user_ratings_total": 823.0
    },
    {
      "geocode": [39.04098141, -77.15849414],
      "Rest_Name": "Cabin John",
      "address": "7991 Tuckerman Lane",
      "id": 10055,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJe34-Cc3Nt4kRikzmjTIZMlE",
      "user_ratings_total": 196.0
    },
    {
      "geocode": [39.15317236, -76.72819466],
      "Rest_Name": "Arundel",
      "address": "7680 Arundel Mills Boulevard",
      "id": 10071,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJxZdLND_jt4kR9fJ4RzeecWg",
      "user_ratings_total": 957.0
    },
    {
      "geocode": [38.98075288, -76.93899743],
      "Rest_Name": "College Park",
      "address": "7350 Baltimore Avenue",
      "id": 10073,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJ5RckNVXHt4kR7Qliwel7DKg",
      "user_ratings_total": 391.0
    },
    {
      "geocode": [39.38385611, -77.40226026],
      "Rest_Name": "Frederick",
      "address": "5615 Spectrum Drive",
      "id": 10092,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ49fzeIjbyYkRHs3exZUF2c4",
      "user_ratings_total": 1038.0
    },
    {
      "geocode": [38.9232905, -76.84815085],
      "Rest_Name": "Woodmore",
      "address": "9140 Woodmore Centre Drive",
      "id": 10095,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJw4y2Bx_Bt4kROuUdqGb5Q7U",
      "user_ratings_total": 666.0
    },
    {
      "geocode": [39.36941478, -76.45469912],
      "Rest_Name": "White Marsh",
      "address": "5201A Campbell Boulevard",
      "id": 10104,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJD18Vw6rjx4kRIpNjqKy6bRU",
      "user_ratings_total": 496.0
    },
    {
      "geocode": [39.41290163, -76.77273577],
      "Rest_Name": "Owings Mills",
      "address": "10130 Reisterstown Road",
      "id": 10113,
      "type": "CAVA",
      "rating": 4.0,
      "place_id": "ChIJJaOF-4oXyIkRvToeaqtk1OI",
      "user_ratings_total": 230.0
    },
    {
      "geocode": [38.9816714, -76.5432761],
      "Rest_Name": "Annapolis",
      "address": "1901 Towne Centre Boulevard",
      "id": 10127,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJD8jDi-z3t4kRHfVo3q2DXM8",
      "user_ratings_total": 202.0
    },
    {
      "geocode": [39.44358775, -76.62708735],
      "Rest_Name": "Timonium",
      "address": "2129-A York Road",
      "id": 10173,
      "type": "CAVA",
      "rating": 3.2,
      "place_id": "ChIJo9BXdOIPyIkRlbgFMVhPF90",
      "user_ratings_total": 277.0
    },
    {
      "geocode": [39.27846422, -76.56329185],
      "Rest_Name": "Canton",
      "address": "3900 Boston Street",
      "id": 10201,
      "type": "CAVA",
      "rating": 3.2,
      "place_id": "ChIJDaqYZeYDyIkRTOFK_mXRZDA",
      "user_ratings_total": 309.0
    },
    {
      "geocode": [39.18252, -77.26417],
      "Rest_Name": "Germantown",
      "address": "19865 Century Blvd.",
      "id": 10255,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJSW3kSOMttokRxi3fnsFsLzs",
      "user_ratings_total": 270.0
    },
    {
      "geocode": [38.98035255, -76.73444235],
      "Rest_Name": "Bowie MD - Annapolis Rd",
      "address": "15480 Annapolis Road",
      "id": 10300,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ551Ur4jpt4kRnLy_lTHG24k",
      "user_ratings_total": 16.0
    },
    {
      "geocode": [39.08615246, -76.86278671],
      "Rest_Name": "Laurel MD - Laurel Lakes",
      "address": "14398 Baltimore Ave",
      "id": 10311,
      "type": "CAVA",
      "rating": 3.0,
      "place_id": "ChIJwaeU-Q3dt4kR8-OCRD5zB2I",
      "user_ratings_total": 4.0
    },
    {
      "geocode": [38.62960072, -76.90815356],
      "Rest_Name": "Waldorf MD - Shops at Waldorf",
      "address": "3005 Crain Hwy",
      "id": 10315,
      "type": "CAVA",
      "rating": 3.9,
      "place_id": "ChIJy76QXTaht4kRsb9Z8GKfSWY",
      "user_ratings_total": 428.0
    },
    {
      "geocode": [39.49900509, -76.65435879],
      "Rest_Name": "Hunt Valley MD - York Road",
      "address": "11495 McCormick Rd",
      "id": 10349,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJexwJhf4TyIkRn2_O5TTlcfI",
      "user_ratings_total": 16.0
    },
    {
      "geocode": [39.02944879, -76.68790273],
      "Rest_Name": "Crofton MD - Waugh Chapel Towne Centre",
      "address": "2515 Evergreen Rd",
      "id": 10510,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [38.929032, -94.414151],
      "Rest_Name": "Lee's Summit MO - Pryor Road",
      "address": "904 NW Pryor Road",
      "id": 10334,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJJ1l_kBThwIcRk70zpeVlXDs",
      "user_ratings_total": 142.0
    },
    {
      "geocode": [40.71679785, -74.0355073],
      "Rest_Name": "Jersey City",
      "address": "30 Montgomery Street",
      "id": 10033,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJk6H9NalQwokRi5f08Ety2rQ",
      "user_ratings_total": 1290.0
    },
    {
      "geocode": [40.91513883, -74.0596614],
      "Rest_Name": "Paramus",
      "address": "600 Bergen Town Center",
      "id": 10044,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJGxkFnVz6wokR5f8hnP3MxsE",
      "user_ratings_total": 1837.0
    },
    {
      "geocode": [40.58253369, -74.6150185],
      "Rest_Name": "Bridgewater",
      "address": "620 Commons Way",
      "id": 10050,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJfWAEuPe_w4kRMEIU5cX28-k",
      "user_ratings_total": 1252.0
    },
    {
      "geocode": [40.88783814, -74.25420631],
      "Rest_Name": "Wayne",
      "address": "84 Willowbrook Boulevard",
      "id": 10052,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJidCCKlABw4kR2ngb6NP3Boc",
      "user_ratings_total": 687.0
    },
    {
      "geocode": [39.94079809, -74.96212165],
      "Rest_Name": "Moorestown",
      "address": "1205 Nixon Drive",
      "id": 10158,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJyXgHgKY1wYkRcUqbjEvL3EM",
      "user_ratings_total": 298.0
    },
    {
      "geocode": [40.29513844, -74.68450459],
      "Rest_Name": "Lawrenceville",
      "address": "3371 Brunswick Pike",
      "id": 10162,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJiaA3197hw4kR_slErzcYauc",
      "user_ratings_total": 177.0
    },
    {
      "geocode": [41.06662818, -74.13146453],
      "Rest_Name": "Ramsey",
      "address": "984 Route 17 N",
      "id": 10171,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJIWJol93hwokRGIEOM9PLPLY",
      "user_ratings_total": 327.0
    },
    {
      "geocode": [40.81326865, -73.98423033],
      "Rest_Name": "Edgewater NJ - Edgewater Commons",
      "address": "443 River Road",
      "id": 10336,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJs39QtTD3wokRO2ogqW3qRK8",
      "user_ratings_total": 152.0
    },
    {
      "geocode": [40.73378978, -73.98961327],
      "Rest_Name": "Union Square",
      "address": "143 4th Avenue",
      "id": 10023,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJsZ78J5lZwokRDr-qC9eQpFc",
      "user_ratings_total": 943.0
    },
    {
      "geocode": [41.00341638, -73.68321634],
      "Rest_Name": "Rye Ridge",
      "address": "3 Rye Ridge Plaza",
      "id": 10511,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [40.72189122, -73.99652724],
      "Rest_Name": "Nolita",
      "address": "50 Spring Street",
      "id": 10047,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJc08MyYhZwokR4qW5U71ANJ0",
      "user_ratings_total": 529.0
    },
    {
      "geocode": [40.74092144, -73.98563647],
      "Rest_Name": "Park Ave South",
      "address": "325 Park Avenue South",
      "id": 10048,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ2d8VLKdZwokRuFhVXsVySjU",
      "user_ratings_total": 868.0
    },
    {
      "geocode": [40.75285857, -73.98754192],
      "Rest_Name": "38th and Broadway",
      "address": "1385 Broadway",
      "id": 10059,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ8XrcxbtZwokRAQuQ4xXGtWY",
      "user_ratings_total": 843.0
    },
    {
      "geocode": [40.7540169, -73.98171796],
      "Rest_Name": "Bryant Park",
      "address": "11 W 42nd Street",
      "id": 10070,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJW_t7BaRZwokRyrTzAb7rudo",
      "user_ratings_total": 463.0
    },
    {
      "geocode": [40.69252908, -73.98855704],
      "Rest_Name": "Downtown Brooklyn",
      "address": "345 Adams Street",
      "id": 10079,
      "type": "CAVA",
      "rating": 4.0,
      "place_id": "ChIJK9S2cxVbwokREN9iU_Q1Q1Q",
      "user_ratings_total": 715.0
    },
    {
      "geocode": [40.75156506, -73.98055972],
      "Rest_Name": "40th and Madison",
      "address": "280 Madison Ave",
      "id": 10080,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJOy-1wl5ZwokRwwPX7mgPAtM",
      "user_ratings_total": 331.0
    },
    {
      "geocode": [40.74166632, -73.9933709],
      "Rest_Name": "21st and 6th",
      "address": "678 6th Avenue",
      "id": 10082,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJXYkNQ-dZwokRfRXmkttK2_Q",
      "user_ratings_total": 603.0
    },
    {
      "geocode": [40.70590611, -74.00878888],
      "Rest_Name": "Wall Street",
      "address": "63 Wall Street",
      "id": 10086,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ7RY99xFbwokR9wyK49Ud9LE",
      "user_ratings_total": 577.0
    },
    {
      "geocode": [40.79983778, -73.41199653],
      "Rest_Name": "Melville",
      "address": "880 Walt Whitman Road",
      "id": 10512,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [40.7456, -73.59861],
      "Rest_Name": "Westbury Plaza",
      "address": "1256 Old Country Road",
      "id": 10513,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [40.7467866, -73.99326858],
      "Rest_Name": "307 7th Ave",
      "address": "307 7th Avenue",
      "id": 10291,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJofhOzbxZwokRQf1ixFGebYE",
      "user_ratings_total": 227.0
    },
    {
      "geocode": [40.7520555, -73.9756075],
      "Rest_Name": "708 3rd Ave",
      "address": "708 3rd Ave",
      "id": 10299,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJWxRsUtZZwokRGk7CVNzOAIY",
      "user_ratings_total": 249.0
    },
    {
      "geocode": [40.71533, -73.95858],
      "Rest_Name": "Williamsburg - Brooklyn NY",
      "address": "174 N 4th Street",
      "id": 10303,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJa-0Zf1xZwokRNP_xJequ0Lg",
      "user_ratings_total": 223.0
    },
    {
      "geocode": [35.61389482, -97.54763965],
      "Rest_Name": "Quail Springs",
      "address": "14110 N Pennsylvania Avenue",
      "id": 10194,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJpX09Al8dsocRXuZC9Hh1u_4",
      "user_ratings_total": 218.0
    },
    {
      "geocode": [35.53506038, -97.53218718],
      "Rest_Name": "Classen Curve",
      "address": "6150C NW Grand Boulevard",
      "id": 10196,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ-2IatoQbsocR17FGxV_-rBY",
      "user_ratings_total": 279.0
    },
    {
      "geocode": [35.65217515, -97.45812104],
      "Rest_Name": "Edmond",
      "address": "1700 E Second",
      "id": 10206,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJQ898e0IfsocRO-U9YToQ7ys",
      "user_ratings_total": 134.0
    },
    {
      "geocode": [35.23915552, -97.48150687],
      "Rest_Name": "Norman",
      "address": "1651 24th Avenue NW",
      "id": 10208,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJq0aFPsppsocRUmciHTKx9b0",
      "user_ratings_total": 246.0
    },
    {
      "geocode": [36.01926719, -95.88577093],
      "Rest_Name": "Southtown",
      "address": "10005 S Memorial Road",
      "id": 10217,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJB9k0mI6PtocRjAuSbRzr6vE",
      "user_ratings_total": 370.0
    },
    {
      "geocode": [36.0559034, -96.00286374],
      "Rest_Name": "Tulsa Hills",
      "address": "7427 South Olympia",
      "id": 10220,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJt5b7UCeVtocR1R2PzO1l6lw",
      "user_ratings_total": 319.0
    },
    {
      "geocode": [36.07608513, -95.92321862],
      "Rest_Name": "Kingspointe",
      "address": "6044 South Yale Street",
      "id": 10221,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJSceo1iWTtocRRcgwS7FJo2o",
      "user_ratings_total": 217.0
    },
    {
      "geocode": [36.06711916, -95.78015444],
      "Rest_Name": "Broken Arrow",
      "address": "1556 North 9th Street",
      "id": 10224,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJCw6lZgqLtocRfvERiYVIh7Y",
      "user_ratings_total": 296.0
    },
    {
      "geocode": [36.1106359, -95.97540769],
      "Rest_Name": "Tulsa Brookside",
      "address": "3629 S. Peoria Avenue",
      "id": 10324,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJWTlAwkeTtocRsUn1S7FxMys",
      "user_ratings_total": 157.0
    },
    {
      "geocode": [36.29356988, -95.84608273],
      "Rest_Name": "Owasso OK - 96th Street",
      "address": "11340 E 96th St N",
      "id": 10345,
      "type": "CAVA",
      "rating": 3.9,
      "place_id": "ChIJ5y3oh6XxtocRunhCY_P6yTU",
      "user_ratings_total": 22.0
    },
    {
      "geocode": [39.95166321, -75.16939227],
      "Rest_Name": "Rittenhouse",
      "address": "1713 Chestnut Street",
      "id": 10081,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJff6Ga0XHxokRG8zpw3uiouU",
      "user_ratings_total": 602.0
    },
    {
      "geocode": [40.00786878, -75.28848096],
      "Rest_Name": "Suburban Square",
      "address": "40 Coulter Ave",
      "id": 10098,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ7VP9OBvBxokRGx4PjEfC06I",
      "user_ratings_total": 352.0
    },
    {
      "geocode": [40.06638553, -76.31444048],
      "Rest_Name": "Lancaster",
      "address": "1569 Fruitville Pike",
      "id": 10143,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJWUy3zsIjxokRUaKHrYOxbzA",
      "user_ratings_total": 312.0
    },
    {
      "geocode": [39.98721598, -75.40544727],
      "Rest_Name": "Ellis Preserve",
      "address": "3741 West Chester Pike",
      "id": 10144,
      "type": "CAVA",
      "rating": 4.8,
      "place_id": "ChIJ6Ut5COHrxokRul1Jpz3pObk",
      "user_ratings_total": 590.0
    },
    {
      "geocode": [40.11773063, -75.28307897],
      "Rest_Name": "Plymouth Meeting",
      "address": "520 West Germantown Pike",
      "id": 10147,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJFd6UHaS9xokRQKx69COASLQ",
      "user_ratings_total": 272.0
    },
    {
      "geocode": [39.87511057, -75.5438223],
      "Rest_Name": "Glen Mills",
      "address": "1751 Wilmington Pike",
      "id": 10160,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJKTXqn1r7xokRVtV5v5c0dKo",
      "user_ratings_total": 172.0
    },
    {
      "geocode": [40.23892828, -75.24157818],
      "Rest_Name": "Montgomeryville",
      "address": "32 Airport Square",
      "id": 10179,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJZyg8XPylxokRGYBBEQIqoBo",
      "user_ratings_total": 169.0
    },
    {
      "geocode": [40.16599675, -75.47999106],
      "Rest_Name": "Collegeville",
      "address": "161 Market St.",
      "id": 10327,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJVYMiWKeRxokRHDRL_M6y86E",
      "user_ratings_total": 139.0
    },
    {
      "geocode": [41.87100464, -71.53247842],
      "Rest_Name": "Smithfield",
      "address": "473 Putnam Pike",
      "id": 10367,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJgZNZfOdH5IkRZg1ahMmgguQ",
      "user_ratings_total": 228.0
    },
    {
      "geocode": [32.83072049, -79.831052],
      "Rest_Name": "Mt Pleasant",
      "address": "1242 Belk Drive",
      "id": 10165,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJJXiVnSFx_ogR9Q5eOXxANVM",
      "user_ratings_total": 310.0
    },
    {
      "geocode": [34.82608703, -82.39304587],
      "Rest_Name": "Greenville",
      "address": "2123 Augusta Street",
      "id": 10184,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJ3dFofIcxWIgRRKktQzNauc8",
      "user_ratings_total": 321.0
    },
    {
      "geocode": [32.2471967, -80.8347376],
      "Rest_Name": "Bluffton",
      "address": "1250B Fording Island Rd",
      "id": 10188,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJVbJAsSCJ-4gRfBMvjpQtI_U",
      "user_ratings_total": 181.0
    },
    {
      "geocode": [34.12401, -80.87293],
      "Rest_Name": "Two Notch",
      "address": "10296 Two Notch Rd",
      "id": 10266,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJcfyKd_mr-IgRVhImiBB0WnA",
      "user_ratings_total": 213.0
    },
    {
      "geocode": [34.00631, -81.2002],
      "Rest_Name": "Lexington",
      "address": "5166 Sunset Blvd",
      "id": 10269,
      "type": "CAVA",
      "rating": 4.8,
      "place_id": "ChIJH-lkRVGX-IgRoPPKfjKv0Ho",
      "user_ratings_total": 380.0
    },
    {
      "geocode": [34.0192301, -80.9632927],
      "Rest_Name": "Forest Drive",
      "address": "4855 Forest Drive",
      "id": 10272,
      "type": "CAVA",
      "rating": 4.8,
      "place_id": "ChIJjdos9tev-IgRJJEFwdOfxJc",
      "user_ratings_total": 685.0
    },
    {
      "geocode": [34.09078678, -81.17298504],
      "Rest_Name": "Irmo",
      "address": "952 Lake Murray Blvd",
      "id": 10323,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJKbPdzIGZ-IgRAqmqD7HErWg",
      "user_ratings_total": 149.0
    },
    {
      "geocode": [34.84742349, -82.33906436],
      "Rest_Name": "Greenville SC - Haywood Rd",
      "address": "586 Haywood Road",
      "id": 10338,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJe4SXAmUvWIgRELauW-QuW4s",
      "user_ratings_total": 291.0
    },
    {
      "geocode": [33.84187296, -78.66174785],
      "Rest_Name": "N Myrtle Beach SC - Coastal N Town Center",
      "address": "1400 Hwy 17 N",
      "id": 10373,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJp0iXD6p9AIkRxn5XtnpiklI",
      "user_ratings_total": 113.0
    },
    {
      "geocode": [34.82558268, -82.29858753],
      "Rest_Name": "Greenville SC - Woodruff Road",
      "address": "1156 Woodruff Road",
      "id": 10514,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [34.00000121, -81.03875558],
      "Rest_Name": "Columbia SC - Vista",
      "address": "831 Gervais St",
      "id": 10383,
      "type": "CAVA",
      "rating": 4.8,
      "place_id": "ChIJlXv0BzW7-IgRZSIY6m2goM0",
      "user_ratings_total": 159.0
    },
    {
      "geocode": [36.10781713, -86.81594411],
      "Rest_Name": "Green Hills",
      "address": "2171 Green Hills Village Dr",
      "id": 10096,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJJ6P6wTBlZIgRt_Dp0MOP9dw",
      "user_ratings_total": 713.0
    },
    {
      "geocode": [36.1605858, -86.77974086],
      "Rest_Name": "5th and Broadway",
      "address": "5010 Broadway Pl",
      "id": 10099,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJLQk5qVFnZIgR7hUYy5WKdmI",
      "user_ratings_total": 435.0
    },
    {
      "geocode": [35.94448284, -86.81880309],
      "Rest_Name": "Franklin",
      "address": "3058 Mallory Lane",
      "id": 10170,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJPxmHu6l5ZIgRTrDw5SW2-F0",
      "user_ratings_total": 392.0
    },
    {
      "geocode": [36.14595598, -86.79934237],
      "Rest_Name": "21st Avenue",
      "address": "422 21st Avenue South",
      "id": 10199,
      "type": "CAVA",
      "rating": 4.8,
      "place_id": "ChIJecYFwy5nZIgRhTPSzADvKCY",
      "user_ratings_total": 1089.0
    },
    {
      "geocode": [35.93103013, -84.01324159],
      "Rest_Name": "Bearden Station",
      "address": "6638 Kingston Pike",
      "id": 10212,
      "type": "CAVA",
      "rating": 4.7,
      "place_id": "ChIJf7o3CTUjXIgRXzRl8ZmMN04",
      "user_ratings_total": 485.0
    },
    {
      "geocode": [35.89930915, -84.16057362],
      "Rest_Name": "Turkey Creek",
      "address": "11378 Parkside Drive",
      "id": 10215,
      "type": "CAVA",
      "rating": 4.8,
      "place_id": "ChIJM8ysdjIvXIgRPDAiTi6KBJs",
      "user_ratings_total": 375.0
    },
    {
      "geocode": [36.09768207, -86.87210659],
      "Rest_Name": "Belle Meade",
      "address": "6025 Highway 100",
      "id": 10250,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJdwUGBidjZIgRsn_HzY26-l4",
      "user_ratings_total": 145.0
    },
    {
      "geocode": [30.28856623, -97.74168507],
      "Rest_Name": "The Drag",
      "address": "2426 Guadalupe Street",
      "id": 10056,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJffRKRca1RIYR-Iz3ROxDKeA",
      "user_ratings_total": 735.0
    },
    {
      "geocode": [30.26781794, -97.74261939],
      "Rest_Name": "6th and Congress",
      "address": "515 Congress Avenue",
      "id": 10057,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJfd05H2e1RIYRQHGIfo6yVDc",
      "user_ratings_total": 1382.0
    },
    {
      "geocode": [30.27310664, -97.79930525],
      "Rest_Name": "Westlake",
      "address": "3201 Bee Caves Road",
      "id": 10065,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJ73qFyS5LW4YRlkc4fxtKiQY",
      "user_ratings_total": 686.0
    },
    {
      "geocode": [33.08045184, -96.82644094],
      "Rest_Name": "Legacy West",
      "address": "7501 Windrose Avenue",
      "id": 10083,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJzyXidu49TIYRK4RC8qa_-hU",
      "user_ratings_total": 864.0
    },
    {
      "geocode": [32.78975558, -96.80403939],
      "Rest_Name": "Uptown",
      "address": "1919 McKinney Avenue",
      "id": 10088,
      "type": "CAVA",
      "rating": 4.2,
      "place_id": "ChIJi3UbfR2ZToYRZJFAgILZjsY",
      "user_ratings_total": 588.0
    },
    {
      "geocode": [30.3934421, -97.72678256],
      "Rest_Name": "The Domain",
      "address": "10808 Domain Drive",
      "id": 10089,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJR6i24x7NRIYRpi83Gih24BU",
      "user_ratings_total": 2054.0
    },
    {
      "geocode": [32.95373924, -96.82084214],
      "Rest_Name": "Addison",
      "address": "5100 Belt Line Road",
      "id": 10119,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJfbjVsuAhTIYRn9GFKaq-0Nc",
      "user_ratings_total": 547.0
    },
    {
      "geocode": [32.87058789, -96.76896091],
      "Rest_Name": "Park Lane",
      "address": "8018 Park Lane",
      "id": 10120,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJlxYD56yfToYRYAEjEVxRXH8",
      "user_ratings_total": 296.0
    },
    {
      "geocode": [32.90108098, -96.95955955],
      "Rest_Name": "Las Colinas",
      "address": "6731 N MacArthur Boulevard",
      "id": 10122,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJk7DHFewpTIYRurmaEJB47DA",
      "user_ratings_total": 723.0
    },
    {
      "geocode": [33.15320967, -96.84128364],
      "Rest_Name": "Frisco Market Center",
      "address": "5252 Main Street",
      "id": 10123,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJFRCJGPY7TIYRadZYiIIGWVs",
      "user_ratings_total": 597.0
    },
    {
      "geocode": [32.94038228, -97.11898286],
      "Rest_Name": "Southlake",
      "address": "2175 E Southlake Boulevard",
      "id": 10125,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJr505M2nVTYYRBjTw2GGmghM",
      "user_ratings_total": 445.0
    },
    {
      "geocode": [32.81538786, -96.80072687],
      "Rest_Name": "Turtle Creek",
      "address": "3878 Oak Lawn Avenue",
      "id": 10131,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJt4yesnefToYRGKJ8dS-q5BE",
      "user_ratings_total": 404.0
    },
    {
      "geocode": [33.06601523, -97.08136537],
      "Rest_Name": "Flower Mound",
      "address": "5871 Long Prairie Road",
      "id": 10132,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ7Ueimu8zTIYRLqwjHe3flrU",
      "user_ratings_total": 448.0
    },
    {
      "geocode": [33.17494323, -96.73446483],
      "Rest_Name": "Custer Star",
      "address": "16120 W Eldorado Parkway",
      "id": 10133,
      "type": "CAVA",
      "rating": 3.9,
      "place_id": "ChIJTd7eRK8VTIYRs5tGP486qGc",
      "user_ratings_total": 667.0
    },
    {
      "geocode": [32.69944589, -97.4127955],
      "Rest_Name": "Waterside",
      "address": "5917 Convair Drive",
      "id": 10135,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJlSZ4gLBzToYR25_O6_3edXE",
      "user_ratings_total": 662.0
    },
    {
      "geocode": [32.97589896, -96.72038729],
      "Rest_Name": "Richardson",
      "address": "222 W Campbell Road",
      "id": 10137,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJpfpXbUIfTIYR9HuJUnGFU9g",
      "user_ratings_total": 483.0
    },
    {
      "geocode": [32.81428861, -96.75321217],
      "Rest_Name": "Lakewood",
      "address": "2009 Abrams Parkway",
      "id": 10138,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJiV3GttafToYRQyQ8wCZQJwU",
      "user_ratings_total": 236.0
    },
    {
      "geocode": [30.52540212, -97.82003676],
      "Rest_Name": "Cedar Park",
      "address": "905 E Whitestone Boulevard",
      "id": 10142,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ11-wDQ0tW4YRh6RDQRUpmBo",
      "user_ratings_total": 591.0
    },
    {
      "geocode": [30.22794912, -97.82081652],
      "Rest_Name": "Sunset Valley",
      "address": "5601 Brodie Lane",
      "id": 10146,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJEV7NSkBLW4YRrn8ezFTUDB0",
      "user_ratings_total": 457.0
    },
    {
      "geocode": [29.49876929, -98.46708828],
      "Rest_Name": "Lincoln Heights",
      "address": "999 E Basse Road",
      "id": 10149,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ8zwcNOL1XIYR8qhr4Y10eXQ",
      "user_ratings_total": 402.0
    },
    {
      "geocode": [29.54644008, -98.57767286],
      "Rest_Name": "The Strand",
      "address": "11225 Huebner Road",
      "id": 10151,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJByMhxYlnXIYRDvJawPXsSVA",
      "user_ratings_total": 290.0
    },
    {
      "geocode": [29.60922269, -98.4998004],
      "Rest_Name": "Sonterra Village",
      "address": "427 N Loop 1604 W Acc Road",
      "id": 10153,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJ1f8t6uxjXIYR4EIgKvSnJx0",
      "user_ratings_total": 352.0
    },
    {
      "geocode": [29.60910381, -98.59708069],
      "Rest_Name": "The Rim",
      "address": "17603 La Cantera Parkway",
      "id": 10156,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ9cntJaRlXIYRmsBF-V3i29I",
      "user_ratings_total": 479.0
    },
    {
      "geocode": [32.96823666, -96.99230611],
      "Rest_Name": "Coppell",
      "address": "120 S Denton Tap Road",
      "id": 10172,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJw5QIvcEpTIYR6-TvB9DX2TA",
      "user_ratings_total": 565.0
    },
    {
      "geocode": [29.74063896, -95.77552977],
      "Rest_Name": "Cinco Ranch",
      "address": "2643 Commercial Center Boulevard",
      "id": 10180,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJnUFq0cghQYYRl5oJx9fDAnU",
      "user_ratings_total": 298.0
    },
    {
      "geocode": [30.18239207, -95.53489496],
      "Rest_Name": "The Woodlands",
      "address": "6700 Woodlands Parkway",
      "id": 10181,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJH_r--WYxR4YRo4pJFb5xdZ0",
      "user_ratings_total": 248.0
    },
    {
      "geocode": [29.80388723, -95.40120862],
      "Rest_Name": "Houston Heights",
      "address": "250 W 20th",
      "id": 10182,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ-_40ZOzHQIYRpOXE3r9O2vk",
      "user_ratings_total": 377.0
    },
    {
      "geocode": [30.05092711, -95.18409246],
      "Rest_Name": "Kingwood",
      "address": "4505 Kingwood Drive",
      "id": 10186,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJp8AmRQKtQIYRYg0k4lZXXak",
      "user_ratings_total": 360.0
    },
    {
      "geocode": [29.59433502, -95.62309086],
      "Rest_Name": "Sugarland",
      "address": "2260 Lone Star Drive",
      "id": 10187,
      "type": "CAVA",
      "rating": 4.1,
      "place_id": "ChIJFRtUi73nQIYRCVQ-pxBcQnQ",
      "user_ratings_total": 307.0
    },
    {
      "geocode": [29.99325199, -95.75150995],
      "Rest_Name": "Cypress",
      "address": "28920 Highway 290",
      "id": 10195,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJCdAWQ04rQYYRVSbRmlphwx8",
      "user_ratings_total": 276.0
    },
    {
      "geocode": [29.55511594, -95.39746305],
      "Rest_Name": "Pearland",
      "address": "11470 Broadway Street",
      "id": 10515,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.73752077, -95.56123714],
      "Rest_Name": "Westchase",
      "address": "2550 Citywest Boulevard",
      "id": 10516,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.53815128, -95.14717654],
      "Rest_Name": "Baybrook",
      "address": "1501 C Bay Area Boulevard",
      "id": 10517,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.16491168, -95.46643457],
      "Rest_Name": "Market Street",
      "address": "9595 Six Pines Drive",
      "id": 10518,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.77364836, -95.55999917],
      "Rest_Name": "Town and Country",
      "address": "12850 Memorial Drive",
      "id": 10519,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.5646, -98.48166],
      "Rest_Name": "Bitters",
      "address": "13610 San Pedro Avenue",
      "id": 10520,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.77044368, -95.40125125],
      "Rest_Name": "Houston Washington",
      "address": "4000 Washington Avenue",
      "id": 10521,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.08435249, -95.44992117],
      "Rest_Name": "Springwood",
      "address": "2162 Spring Stuebner Road",
      "id": 10522,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.99773775, -95.57516461],
      "Rest_Name": "Vintage",
      "address": "10850 Louetta Road",
      "id": 10523,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.84868048, -96.78751386],
      "Rest_Name": "Snider Plaza",
      "address": "6800 Snider Plaza Boulevard",
      "id": 10524,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.63238595, -97.69919996],
      "Rest_Name": "Georgetown",
      "address": "1201 W University Avenue",
      "id": 10525,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.39037, -97.74789],
      "Rest_Name": "Arboretum",
      "address": "10000 Research Blvd",
      "id": 10526,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.62449569, -96.33929307],
      "Rest_Name": "College Station",
      "address": "1099 University Drive",
      "id": 10527,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [33.22932526, -97.16545646],
      "Rest_Name": "Rayzor Ranch",
      "address": "2515 W University",
      "id": 10528,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.29045371, -97.82617597],
      "Rest_Name": "West Lake Hills",
      "address": "701 S Capital of Texas Highway",
      "id": 10529,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.85083746, -96.81469119],
      "Rest_Name": "Lovers Lane",
      "address": "5710 West Lover's Lane",
      "id": 10530,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [33.02508938, -96.79594758],
      "Rest_Name": "Plano",
      "address": "1901 Preston Rd.",
      "id": 10531,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.25065969, -95.30594656],
      "Rest_Name": "Tyler",
      "address": "8926 S. Broadway Ave.",
      "id": 10532,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.91555709, -97.30958746],
      "Rest_Name": "Alliance Town Center",
      "address": "3330 Tracewood Way",
      "id": 10533,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.75148, -97.35622],
      "Rest_Name": "Fort Worth TX - W 7th",
      "address": "2736 W 7th Street",
      "id": 10534,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.83196, -96.70537],
      "Rest_Name": "Dallas TX - Casa Linda",
      "address": "9323 Garland Road",
      "id": 10535,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [33.10620236, -96.8050606],
      "Rest_Name": "Stonebriar",
      "address": "3246 Preston Rd",
      "id": 10536,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.87915674, -97.10268133],
      "Rest_Name": "Euless TX - Glade Road",
      "address": "2900 Rio Grande Blvd",
      "id": 10537,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.6614478, -95.71855429],
      "Rest_Name": "Aliana TX - Waterview Town Center",
      "address": "19215 West Belfort Blvd",
      "id": 10538,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.53195488, -95.50774768],
      "Rest_Name": "Sienna TX - Fort Bend Town Center",
      "address": "10407 Highway 6",
      "id": 10539,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.64257983, -98.45484436],
      "Rest_Name": "San Antonio TX - Stone Ridge",
      "address": "21025 U.S. Hwy 281 N",
      "id": 10540,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.7453418, -96.45178128],
      "Rest_Name": "Forney TX - Hwy 80",
      "address": "630 E US HWY 80",
      "id": 10541,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [29.70981582, -95.40125546],
      "Rest_Name": "Houston TX - Texas Medical Center",
      "address": "6618 Fannin Street",
      "id": 10542,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [31.55061072, -97.12568016],
      "Rest_Name": "Waco TX - Baylor University",
      "address": "812 S 6th St",
      "id": 10543,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [32.76124841, -97.09677771],
      "Rest_Name": "Arlington TX - Champions Park 2",
      "address": "1707 N Collins St",
      "id": 10544,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [30.0156, -97.8668],
      "Rest_Name": "Kyle TX - Kyle Crossing",
      "address": "5120 Farm to Market 1626",
      "id": 10545,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [33.0116, -96.6037],
      "Rest_Name": "Murphy TX - Murphy",
      "address": "305 E FM 544",
      "id": 10546,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [38.87251059, -77.22914159],
      "Rest_Name": "Mosaic",
      "address": "2905 District Avenue",
      "id": 10005,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJtY5ISXtLtokRp3qX4SY8r3w",
      "user_ratings_total": 646.0
    },
    {
      "geocode": [39.06747646, -77.4852854],
      "Rest_Name": "Belmont (Ashburn)",
      "address": "19825 Belmont Chase Drive",
      "id": 10010,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJxTYpYRo8tokRvAvUEbWaXuQ",
      "user_ratings_total": 845.0
    },
    {
      "geocode": [38.85374412, -77.32728166],
      "Rest_Name": "Kamp Washington",
      "address": "11046 Main Street",
      "id": 10011,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJMefXxeZOtokR6-p7A2yY2AE",
      "user_ratings_total": 877.0
    },
    {
      "geocode": [38.81267997, -77.11001157],
      "Rest_Name": "Foxchase",
      "address": "4513 Duke Street",
      "id": 10016,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJn1PBPtOzt4kRYDYSSo-ph7I",
      "user_ratings_total": 1067.0
    },
    {
      "geocode": [38.93156664, -77.17855222],
      "Rest_Name": "McLean",
      "address": "1445 Chain Bridge Road",
      "id": 10019,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ59O3vkS1t4kRhr8JwraOMFc",
      "user_ratings_total": 328.0
    },
    {
      "geocode": [38.78907135, -77.60683174],
      "Rest_Name": "Gainesville",
      "address": "13959 Promenade Commons Street",
      "id": 10020,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJ-wYGWKtntokRsVMyITjP7rE",
      "user_ratings_total": 1068.0
    },
    {
      "geocode": [39.02421687, -77.39424966],
      "Rest_Name": "Sterling",
      "address": "21475 Epicerie Plaza",
      "id": 10024,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJl5dFlcM5tokRFrkW69qELpg",
      "user_ratings_total": 855.0
    },
    {
      "geocode": [38.79979175, -77.3191825],
      "Rest_Name": "Burke",
      "address": "5765A Burke Centre Parkway",
      "id": 10028,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJVQW-ziVOtokRbxX5ylXti6Y",
      "user_ratings_total": 365.0
    },
    {
      "geocode": [37.5496267, -77.4499212],
      "Rest_Name": "VCU",
      "address": "810 W Grace Street",
      "id": 10030,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJI0470kcRsYkRd5RL7Ucm5d4",
      "user_ratings_total": 875.0
    },
    {
      "geocode": [38.96132401, -77.3565666],
      "Rest_Name": "Reston",
      "address": "1815 Fountain Drive",
      "id": 10034,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJV8Ge6x9ItokRk-E2lUYJSjI",
      "user_ratings_total": 998.0
    },
    {
      "geocode": [37.65563411, -77.62474427],
      "Rest_Name": "Short Pump",
      "address": "11780 W Broad Street",
      "id": 10035,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJfRUOfHFqsYkRs4zmewIwOmA",
      "user_ratings_total": 1034.0
    },
    {
      "geocode": [38.76950333, -77.13991304],
      "Rest_Name": "Kingstowne",
      "address": "5965 Kingstowne Center",
      "id": 10036,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJDwY0iIatt4kRgqkGPUci2XU",
      "user_ratings_total": 1078.0
    },
    {
      "geocode": [37.51195848, -77.61368304],
      "Rest_Name": "Midlothian",
      "address": "1403 Huguenot Road",
      "id": 10037,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJhx8TKZltsYkRixAdhG1tm40",
      "user_ratings_total": 993.0
    },
    {
      "geocode": [38.8954415, -77.07095396],
      "Rest_Name": "Rosslyn",
      "address": "1201 Wilson Boulevard",
      "id": 10038,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJsQglT1y3t4kRhgl3UMdXJqs",
      "user_ratings_total": 292.0
    },
    {
      "geocode": [38.05291869, -78.49859262],
      "Rest_Name": "Barracks Road",
      "address": "1200 Emmet Street N",
      "id": 10041,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJS9QRr8KHs4kR6hTwrZ-xP2s",
      "user_ratings_total": 616.0
    },
    {
      "geocode": [38.87706824, -77.44217004],
      "Rest_Name": "Chantilly",
      "address": "14337 Newbrook Drive",
      "id": 10045,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJS33N6HVFtokRZvI2JL2rLP4",
      "user_ratings_total": 786.0
    },
    {
      "geocode": [38.92336008, -77.2368971],
      "Rest_Name": "Pike 7 Plaza",
      "address": "8409 Leesburg Pike",
      "id": 10046,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJXy97p5VLtokRgZIbFkcxBLQ",
      "user_ratings_total": 471.0
    },
    {
      "geocode": [37.41139301, -77.64656654],
      "Rest_Name": "Swift Creek",
      "address": "13421 Hull Street Road",
      "id": 10062,
      "type": "CAVA",
      "rating": 4.6,
      "place_id": "ChIJhwHQSPBzsYkRrxVjcsOgVOs",
      "user_ratings_total": 418.0
    },
    {
      "geocode": [38.88016804, -77.1102558],
      "Rest_Name": "Ballston",
      "address": "4121 Wilson Boulevard",
      "id": 10067,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJq6pqRCS0t4kRVgRGJe3uJh0",
      "user_ratings_total": 606.0
    },
    {
      "geocode": [38.83675152, -77.05114981],
      "Rest_Name": "Potomac Yard",
      "address": "3525 Richmond Hwy",
      "id": 10074,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJhzCIKLCxt4kRsw4qCbaGiPE",
      "user_ratings_total": 501.0
    },
    {
      "geocode": [38.29566107, -77.51634685],
      "Rest_Name": "Fredericksburg",
      "address": "3205 Plank Rd",
      "id": 10091,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJ-1769FvBtokRNG2Edtk_rP4",
      "user_ratings_total": 822.0
    },
    {
      "geocode": [36.8429761, -76.12998937],
      "Rest_Name": "Pembroke",
      "address": "4485 Virginia Beach Boulevard",
      "id": 10103,
      "type": "CAVA",
      "rating": 4.4,
      "place_id": "ChIJOXorD9yVuokRAQIAhzFjG68",
      "user_ratings_total": 1240.0
    },
    {
      "geocode": [36.77288438, -76.2345894],
      "Rest_Name": "Chesapeake",
      "address": "801 Eden Way N",
      "id": 10107,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJkxGYP869uokRqgZlyW06yDM",
      "user_ratings_total": 665.0
    },
    {
      "geocode": [37.5817033, -77.49858443],
      "Rest_Name": "Willow Lawn",
      "address": "1601 Willow Lawn Drive",
      "id": 10114,
      "type": "CAVA",
      "rating": 4.0,
      "place_id": "ChIJYZiRvyAVsYkRh8bR2_qxrWU",
      "user_ratings_total": 277.0
    },
    {
      "geocode": [37.27682672, -76.74825119],
      "Rest_Name": "Williamsburg",
      "address": "5025 Settlers Market Boulevard",
      "id": 10121,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJERGiwXOLsIkRzwERE4MNyaQ",
      "user_ratings_total": 273.0
    },
    {
      "geocode": [37.10332694, -76.49099216],
      "Rest_Name": "Newport News",
      "address": "12090 Jefferson Avenue",
      "id": 10124,
      "type": "CAVA",
      "rating": 4.5,
      "place_id": "ChIJC4XGSkh5sIkRZZXLoYpre3Y",
      "user_ratings_total": 432.0
    },
    {
      "geocode": [36.77222086, -76.08764551],
      "Rest_Name": "Landstown",
      "address": "1912 Landstown Centre Way",
      "id": 10126,
      "type": "CAVA",
      "rating": 4.3,
      "place_id": "ChIJ93agp9O_uokRWDCkzyTrO20",
      "user_ratings_total": 229.0
    },
    {
      "geocode": [37.25610235, -79.95572967],
      "Rest_Name": "Roanoke",
      "address": "2031 Colonial Ave SW",
      "id": 10547,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [37.39860195, -79.22379082],
      "Rest_Name": "Lynchburg",
      "address": "3901 Old Forest Road",
      "id": 10548,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [38.90869872, -77.25701537],
      "Rest_Name": "Vienna VA - 418 Maple",
      "address": "418 Maple Street East",
      "id": 10549,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [38.8280119, -77.0937766],
      "Rest_Name": "Alexandria",
      "address": "3644 King St",
      "id": 10258,
      "type": "CAVA",
      "rating": 3.0,
      "place_id": "ChIJB-VXbJaxt4kRbz1hH8cXPHQ",
      "user_ratings_total": 85.0
    },
    {
      "geocode": [37.04568313, -76.39075489],
      "Rest_Name": "Hampton Coliseum VA - Peninsula Town Center",
      "address": "2033 Coliseum Drive",
      "id": 10550,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
    {
      "geocode": [38.6305588, -77.2881366],
      "Rest_Name": "Woodbridge",
      "address": "14901 Potomac Town Place",
      "id": 10551,
      "type": "CAVA",
      "rating": null,
      "place_id": null,
      "user_ratings_total": null
    },
      {
          "geocode": [
              39.101973,
              -84.512958
          ],
          "Rest_Name": "Fountain Square OH",
          "id": 1,
          "address": "1 Fountain Square Plz",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.086238,
              -84.51897
          ],
          "Rest_Name": "Covington - KY",
          "id": 2,
          "address": "501 W Fourth St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.128127,
              -84.519368
          ],
          "Rest_Name": "U of Cincinnati",
          "id": 3,
          "address": "2507 W Clifton Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.087037,
              -84.477918
          ],
          "Rest_Name": "Newport Pavilion 2",
          "id": 4,
          "address": "93 Carothers Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.135092,
              -84.502779
          ],
          "Rest_Name": "Stetson Square",
          "id": 5,
          "address": "258 Stetson St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.157164,
              -84.457622
          ],
          "Rest_Name": "Norwood",
          "id": 6,
          "address": "4402 Montgomery Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.034226,
              -84.533712
          ],
          "Rest_Name": "Ft Wright",
          "id": 7,
          "address": "3459 Valley Plaza Pkwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.047485,
              -84.576219
          ],
          "Rest_Name": "Buttermilk",
          "id": 8,
          "address": "525 Buttermilk Pike",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.145323,
              -84.431855
          ],
          "Rest_Name": "Hyde Park",
          "id": 9,
          "address": "3725 Paxton Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.037629,
              -84.454721
          ],
          "Rest_Name": "NKU",
          "id": 10,
          "address": "2527 Wilson Rd",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.131107,
              -84.610291
          ],
          "Rest_Name": "Western Hills",
          "id": 11,
          "address": "5555 Glenway Ave Ste 700",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.093965,
              -84.616116
          ],
          "Rest_Name": "Delhi",
          "id": 12,
          "address": "5201 Delhi Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.161847,
              -84.4203
          ],
          "Rest_Name": "Oakley Station",
          "id": 13,
          "address": "4820 Ridge Ave",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.095882,
              -84.386883
          ],
          "Rest_Name": "Mt Washington",
          "id": 14,
          "address": "2244 Beechmont Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.021412,
              -84.589667
          ],
          "Rest_Name": "Erlanger",
          "id": 15,
          "address": "3100 Dixie Hwy",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.151516,
              -84.628302
          ],
          "Rest_Name": "Bridgetown",
          "id": 16,
          "address": "6455 Glenway Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.143092,
              -84.387301
          ],
          "Rest_Name": "Fairfax",
          "id": 17,
          "address": "6213 Wooster Pike",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.18794,
              -84.60392
          ],
          "Rest_Name": "Monfort Heights",
          "id": 18,
          "address": "3639 West Fork Road",
          "rating": 1.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.219475,
              -84.518652
          ],
          "Rest_Name": "Finneytown",
          "id": 19,
          "address": "8375 Winton Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.011697,
              -84.630136
          ],
          "Rest_Name": "Turfway Road",
          "id": 20,
          "address": "7313 Turfway Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.073042,
              -84.346289
          ],
          "Rest_Name": "Anderson Towne Center",
          "id": 21,
          "address": "7630 Beechmont Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.190949,
              -84.656508
          ],
          "Rest_Name": "Dent",
          "id": 22,
          "address": "6440 Harrison Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.202023,
              -84.377149
          ],
          "Rest_Name": "Kenwood Towne Centre",
          "id": 23,
          "address": "7875 Montgomery Rd Spc F4",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.983726,
              -84.399519
          ],
          "Rest_Name": "Alexandria KY",
          "id": 24,
          "address": "7105 Alexandria Pike",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.001518,
              -84.652732
          ],
          "Rest_Name": "Florence",
          "id": 25,
          "address": "7516 Mall Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.204106,
              -84.368105
          ],
          "Rest_Name": "Kenwood",
          "id": 26,
          "address": "8154 Montgomery Rd Unit 4",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.988771,
              -84.641847
          ],
          "Rest_Name": "Dream Street",
          "id": 27,
          "address": "7915 Dream St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.079756,
              -84.706757
          ],
          "Rest_Name": "Hebron",
          "id": 28,
          "address": "2100 Medical Arts Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.2431,
              -84.594132
          ],
          "Rest_Name": "Colerain",
          "id": 29,
          "address": "9430C Colerain Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.072193,
              -84.312245
          ],
          "Rest_Name": "Beechmont",
          "id": 30,
          "address": "444 Ohio Pike # 26",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.253254,
              -84.426266
          ],
          "Rest_Name": "Evendale",
          "id": 31,
          "address": "2552 Cunningham Rd Ste E",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.019039,
              -84.693862
          ],
          "Rest_Name": "Shoppes of Burlington",
          "id": 32,
          "address": "1803 Patrick Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.93078,
              -84.54572
          ],
          "Rest_Name": "Independence - KY",
          "id": 33,
          "address": "2020 Declaration Drive",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.289776,
              -84.466155
          ],
          "Rest_Name": "Tri County Relo",
          "id": 34,
          "address": "11700 Princeton Pike",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.961637,
              -84.678584
          ],
          "Rest_Name": "Union",
          "id": 35,
          "address": "9039 US Highway 42 Ste A",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.092887,
              -84.263778
          ],
          "Rest_Name": "Glen Este",
          "id": 36,
          "address": "4397 Glen Este Withamsville Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.27182,
              -84.376281
          ],
          "Rest_Name": "Cornell",
          "id": 37,
          "address": "11257 Reed Hartman Hwy Unit B",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.303063,
              -84.522788
          ],
          "Rest_Name": "Forest Park",
          "id": 38,
          "address": "350 Cincinnati Mills Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.292668,
              -84.392782
          ],
          "Rest_Name": "Sharonville",
          "id": 39,
          "address": "11974 Lebanon Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.18742,
              -84.260014
          ],
          "Rest_Name": "Milford",
          "id": 40,
          "address": "5697 Romar Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.047266,
              -84.24678
          ],
          "Rest_Name": "Amelia",
          "id": 41,
          "address": "1227 W Ohio Pike",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.25131,
              -84.297108
          ],
          "Rest_Name": "Loveland",
          "id": 42,
          "address": "10567 Loveland Madeira Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.32501,
              -84.430265
          ],
          "Rest_Name": "Union Centre #2",
          "id": 43,
          "address": "9324 Union Centre Blvd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.335861,
              -84.526835
          ],
          "Rest_Name": "Jungle Jim",
          "id": 44,
          "address": "5410 Dixie Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.299562,
              -84.314295
          ],
          "Rest_Name": "Fields Ertl",
          "id": 45,
          "address": "9540 S Mason Montgomery Rd Ste A",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.291297,
              -84.3012
          ],
          "Rest_Name": "Montgomery Crossing",
          "id": 46,
          "address": "9430 Fields Ertel Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.360205,
              -84.459634
          ],
          "Rest_Name": "Beckett Ridge",
          "id": 47,
          "address": "7903 Crossings of Beckett Dr Ste 101",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.260609,
              -84.793431
          ],
          "Rest_Name": "Harrison",
          "id": 48,
          "address": "589 Ring Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.35327,
              -84.361763
          ],
          "Rest_Name": "Tylersville",
          "id": 49,
          "address": "7717 Voice of America Centre Dr",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.38097,
              -84.549157
          ],
          "Rest_Name": "South Hamilton",
          "id": 50,
          "address": "1510 S Erie Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.081983,
              -84.148605
          ],
          "Rest_Name": "Batavia - OH",
          "id": 51,
          "address": "2029 Hospital Dr",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.389981,
              -84.504327
          ],
          "Rest_Name": "Indian Springs",
          "id": 52,
          "address": "3335 Princeton Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.377833,
              -84.377698
          ],
          "Rest_Name": "Liberty Township",
          "id": 53,
          "address": "6876 Cincinnati Dayton Rd Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.078143,
              -84.88931
          ],
          "Rest_Name": "Aurora Indiana",
          "id": 54,
          "address": "1040 Green Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.356517,
              -84.259052
          ],
          "Rest_Name": "Kings Island",
          "id": 55,
          "address": "2364 Kings Center Ct Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.424126,
              -84.594984
          ],
          "Rest_Name": "Hamilton #2",
          "id": 56,
          "address": "1455 Main St",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.37847,
              -84.222102
          ],
          "Rest_Name": "South Lebanon #2",
          "id": 57,
          "address": "674 Corwin Nixon Blvd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.440045,
              -84.330696
          ],
          "Rest_Name": "Cincinnati Premium Outlets",
          "id": 58,
          "address": "175 Senate Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.495964,
              -84.3287
          ],
          "Rest_Name": "Middletown-OH",
          "id": 59,
          "address": "3479 Towne Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.451866,
              -84.183044
          ],
          "Rest_Name": "Lebanon OH",
          "id": 60,
          "address": "1205 Columbus Ave",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.510312,
              -84.742634
          ],
          "Rest_Name": "Miami-Oxford",
          "id": 61,
          "address": "1 W High St Ste 101",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.563291,
              -84.265251
          ],
          "Rest_Name": "Springboro",
          "id": 62,
          "address": "5 Greenwood Ln",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.597056,
              -84.23142
          ],
          "Rest_Name": "Austin Landing",
          "id": 63,
          "address": "10671 Innovation Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.628827,
              -84.189247
          ],
          "Rest_Name": "Centerville",
          "id": 64,
          "address": "1051 Miamisburg Centerville Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.652227,
              -84.109395
          ],
          "Rest_Name": "Bellbrook",
          "id": 65,
          "address": "6000 Wilmington Pike",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.684779,
              -84.168211
          ],
          "Rest_Name": "Far Hills",
          "id": 66,
          "address": "4329 Far Hills Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.710034,
              -84.206025
          ],
          "Rest_Name": "Moraine",
          "id": 67,
          "address": "1925 W Dorothy Ln",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.45401,
              -83.78094
          ],
          "Rest_Name": "Wilmington - OH",
          "id": 68,
          "address": "2816 Rombach Avenue",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.694858,
              -84.103369
          ],
          "Rest_Name": "Indian Ripple",
          "id": 69,
          "address": "4473 Walnut St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.741625,
              -84.182484
          ],
          "Rest_Name": "U of Dayton",
          "id": 70,
          "address": "1211 Brown St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.744497,
              -84.118881
          ],
          "Rest_Name": "Linden Avenue",
          "id": 71,
          "address": "4215 Linden Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.689679,
              -83.962089
          ],
          "Rest_Name": "Xenia",
          "id": 72,
          "address": "1620 W Park Sq",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.776151,
              -84.075405
          ],
          "Rest_Name": "Colonel Glenn",
          "id": 73,
          "address": "2544 Zink Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.770859,
              -84.053009
          ],
          "Rest_Name": "Beaver Creek",
          "id": 74,
          "address": "3286 Pentagon Blvd Ste D",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.831467,
              -84.849463
          ],
          "Rest_Name": "Richmond Village",
          "id": 75,
          "address": "3726 National Rd E",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.858352,
              -84.277631
          ],
          "Rest_Name": "Englewood OH",
          "id": 76,
          "address": "9208 N Main St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.845602,
              -84.1928
          ],
          "Rest_Name": "Little York",
          "id": 77,
          "address": "6759 Miller Ln",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.78517,
              -83.99743
          ],
          "Rest_Name": "Fairborn",
          "id": 78,
          "address": "2012 Commerce Center Blvd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.865701,
              -84.13854
          ],
          "Rest_Name": "Huber Heights #2",
          "id": 79,
          "address": "7767 Old Troy Pike",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.891878,
              -84.19549
          ],
          "Rest_Name": "Vandalia",
          "id": 80,
          "address": "275 E National Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.618218,
              -83.603905
          ],
          "Rest_Name": "Jeffersonville",
          "id": 81,
          "address": "12478 Old U.S. 35",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.89532,
              -83.811094
          ],
          "Rest_Name": "Limestone St",
          "id": 82,
          "address": "2242 S Limestone St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.056496,
              -84.243259
          ],
          "Rest_Name": "Troy Pavilion",
          "id": 83,
          "address": "1934 W Main St",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.951231,
              -83.83
          ],
          "Rest_Name": "Bechtle Ave",
          "id": 84,
          "address": "1930 N Bechtle Ave Ste B",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.124425,
              -84.620744
          ],
          "Rest_Name": "Greenville - OH",
          "id": 85,
          "address": "1437 Wagner Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.066775,
              -84.525327
          ],
          "Rest_Name": "Leestown",
          "id": 86,
          "address": "1584 Leestown Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.042723,
              -84.502723
          ],
          "Rest_Name": "U of Kentucky",
          "id": 87,
          "address": "345 S Limestone",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.22101,
              -85.889226
          ],
          "Rest_Name": "Columbus Indiana",
          "id": 88,
          "address": "2260 N National Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.017243,
              -84.418305
          ],
          "Rest_Name": "Hamburg Place",
          "id": 89,
          "address": "1869 Plaudit Pl Ste 140",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.007286,
              -84.517454
          ],
          "Rest_Name": "Nicholasville Rd",
          "id": 90,
          "address": "2270 Nicholasville Rd # 110",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.005289,
              -84.449374
          ],
          "Rest_Name": "Mount Tabor",
          "id": 91,
          "address": "2905 Richmond Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.987156,
              -84.525733
          ],
          "Rest_Name": "Fayette Mall",
          "id": 92,
          "address": "3565 Nicholasville Rd Ste 935",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.29398,
              -85.560009
          ],
          "Rest_Name": "Springhurst",
          "id": 93,
          "address": "10333 Westport Rd Ste 100",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.24303,
              -85.50888
          ],
          "Rest_Name": "Middletown Commons",
          "id": 94,
          "address": "13303 Shelbyville Rd Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.240621,
              -85.577978
          ],
          "Rest_Name": "Hurstbourne",
          "id": 95,
          "address": "420 S Hurstbourne Pkwy",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.81605,
              -85.7696
          ],
          "Rest_Name": "Greenfield",
          "id": 96,
          "address": "2165 N State St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.287106,
              -84.18447
          ],
          "Rest_Name": "Sidney",
          "id": 97,
          "address": "1975 Michigan St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.361621,
              -82.976921
          ],
          "Rest_Name": "Chillicothe",
          "id": 98,
          "address": "1290 N Bridge St Ste A",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.134955,
              -85.580565
          ],
          "Rest_Name": "Fern Creek",
          "id": 99,
          "address": "7710 Bardstown Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.238987,
              -85.721278
          ],
          "Rest_Name": "Highlands",
          "id": 100,
          "address": "1075 Bardstown Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.253164,
              -85.756956
          ],
          "Rest_Name": "Downtown Louisville",
          "id": 101,
          "address": "315 S 4th St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.581716,
              -82.952244
          ],
          "Rest_Name": "Circleville",
          "id": 102,
          "address": "1469 S Court St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.219258,
              -85.394826
          ],
          "Rest_Name": "Muncie",
          "id": 103,
          "address": "720 W McGalliard Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.304651,
              -85.83976
          ],
          "Rest_Name": "New Albany - IN",
          "id": 104,
          "address": "2133 State St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.840741,
              -83.085835
          ],
          "Rest_Name": "Grove City South",
          "id": 105,
          "address": "3019 Meadow Pond Ct",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.641152,
              -86.08267
          ],
          "Rest_Name": "Emerson Ave & County Line",
          "id": 106,
          "address": "8705 S Emerson Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.919867,
              -83.126363
          ],
          "Rest_Name": "Georgesville",
          "id": 107,
          "address": "1528 Georgesville Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.699737,
              -86.081785
          ],
          "Rest_Name": "Beech Grove",
          "id": 108,
          "address": "4645 S Emerson Ave",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.337433,
              -83.761348
          ],
          "Rest_Name": "Bellefontaine",
          "id": 109,
          "address": "1551 S Main St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.638347,
              -86.129134
          ],
          "Rest_Name": "Greenwood",
          "id": 110,
          "address": "8812 US 31 Ste D",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.952327,
              -83.129431
          ],
          "Rest_Name": "Lincoln Village",
          "id": 111,
          "address": "4782 W Broad St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.989194,
              -83.152182
          ],
          "Rest_Name": "Hilliard Rome",
          "id": 112,
          "address": "1835 Hilliard Rome Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.878272,
              -83.042323
          ],
          "Rest_Name": "Grove City",
          "id": 113,
          "address": "1671 Stringtown Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.888081,
              -82.997347
          ],
          "Rest_Name": "South High",
          "id": 114,
          "address": "3566 S High St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.995766,
              -85.937149
          ],
          "Rest_Name": "Hamilton Town Center",
          "id": 115,
          "address": "12831 Campus Pkwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.029622,
              -83.116149
          ],
          "Rest_Name": "Mill Run",
          "id": 116,
          "address": "3670 Fishinger Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.957561,
              -85.997292
          ],
          "Rest_Name": "Fishers 116th",
          "id": 117,
          "address": "9760 E 116th St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.880894,
              -86.06902
          ],
          "Rest_Name": "Binford Blvd",
          "id": 118,
          "address": "6830 Lake Plaza Dr",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.767405,
              -86.157774
          ],
          "Rest_Name": "Downtown Indy",
          "id": 119,
          "address": "6 E Washington St Ste 100",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.867936,
              -82.936637
          ],
          "Rest_Name": "Obetz",
          "id": 120,
          "address": "5051 Groveport Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.988935,
              -83.041219
          ],
          "Rest_Name": "Grandview Heights",
          "id": 121,
          "address": "1298 W 5th Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.076899,
              -83.131379
          ],
          "Rest_Name": "Tuttle Crossing",
          "id": 122,
          "address": "6079 Parkcenter Circle",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.236781,
              -83.350109
          ],
          "Rest_Name": "Marysville",
          "id": 123,
          "address": "1089 Delaware Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.952049,
              -82.998464
          ],
          "Rest_Name": "German Village",
          "id": 124,
          "address": "488 S High St # 100A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.103998,
              -83.157364
          ],
          "Rest_Name": "Perimeter",
          "id": 125,
          "address": "6776 Perimeter Loop Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.020483,
              -83.060454
          ],
          "Rest_Name": "Kingsdale",
          "id": 126,
          "address": "3090 Kingsdale Ctr",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.98444,
              -83.022995
          ],
          "Rest_Name": "3rd & Olentangy",
          "id": 127,
          "address": "1195 Olentangy River Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.90604,
              -86.074509
          ],
          "Rest_Name": "Castleton",
          "id": 128,
          "address": "5320 E 82nd St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.7818,
              -86.1696
          ],
          "Rest_Name": "IUPUI #3",
          "id": 129,
          "address": "601 Oscar Robertson Blvd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.969545,
              -83.004066
          ],
          "Rest_Name": "Arena District",
          "id": 130,
          "address": "401 N Front St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.982811,
              -83.004472
          ],
          "Rest_Name": "Short North",
          "id": 131,
          "address": "1000 N High St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.997527,
              -83.007382
          ],
          "Rest_Name": "Ohio State University",
          "id": 132,
          "address": "1726 N High St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.005756,
              -83.009007
          ],
          "Rest_Name": "Lane and High",
          "id": 133,
          "address": "2130 North High Street ",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.926457,
              -86.090599
          ],
          "Rest_Name": "96th Street",
          "id": 134,
          "address": "4625 E 96th St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.87009,
              -86.141552
          ],
          "Rest_Name": "Broad Ripple",
          "id": 135,
          "address": "1002 Broad Ripple Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.914441,
              -86.111029
          ],
          "Rest_Name": "Keystone Fashion Mall Fd Ct",
          "id": 136,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.102112,
              -83.090516
          ],
          "Rest_Name": "Sawmill & 161",
          "id": 137,
          "address": "6590 Sawmill Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.062661,
              -83.041207
          ],
          "Rest_Name": "Olentangy Plaza",
          "id": 138,
          "address": "771 Bethel Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.896011,
              -82.882435
          ],
          "Rest_Name": "Groveport",
          "id": 139,
          "address": "3870 S Hamilton Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.957379,
              -82.932422
          ],
          "Rest_Name": "Bexley",
          "id": 140,
          "address": "2484 E Main St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.912852,
              -86.134278
          ],
          "Rest_Name": "Nora",
          "id": 141,
          "address": "1560 E 86th St Ste C",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.053901,
              -83.020202
          ],
          "Rest_Name": "Clintonville",
          "id": 142,
          "address": "4489 N High St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.854858,
              -82.825092
          ],
          "Rest_Name": "Canal Winchester",
          "id": 143,
          "address": "695 W Waterloo St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.156557,
              -83.090472
          ],
          "Rest_Name": "Powell",
          "id": 144,
          "address": "9733 Sawmill Pkwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.974223,
              -82.898717
          ],
          "Rest_Name": "Whitehall Town & Country",
          "id": 145,
          "address": "3822 E Broad St Unit 41A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.059775,
              -82.968979
          ],
          "Rest_Name": "Morse Rd",
          "id": 146,
          "address": "1851 Morse Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.800922,
              -86.269989
          ],
          "Rest_Name": "Speedway",
          "id": 147,
          "address": "6321 Crawfordsville Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.844931,
              -82.781619
          ],
          "Rest_Name": "Diley & 33",
          "id": 148,
          "address": "7730 Diley Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.000786,
              -86.118932
          ],
          "Rest_Name": "Westfield",
          "id": 149,
          "address": "2420 E 146th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.972546,
              -86.154509
          ],
          "Rest_Name": "Old Meridian",
          "id": 150,
          "address": "12697 Pennsylvania St Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.927975,
              -82.829851
          ],
          "Rest_Name": "Brice & Tussing",
          "id": 151,
          "address": "6316 Tussing Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.117859,
              -83.010696
          ],
          "Rest_Name": "Crosswoods Relo",
          "id": 152,
          "address": "191 E Campus View Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.164209,
              -86.495069
          ],
          "Rest_Name": "College Mall",
          "id": 153,
          "address": "2894 E 3rd St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.08733,
              -82.965509
          ],
          "Rest_Name": "Dublin Granville",
          "id": 154,
          "address": "1960 E Dublin Granville Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.912143,
              -86.218409
          ],
          "Rest_Name": "College Plaza",
          "id": 155,
          "address": "3340 W 86th St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.714638,
              -86.356385
          ],
          "Rest_Name": "Plainfield IN",
          "id": 156,
          "address": "350 S Perry Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.04916,
              -82.913825
          ],
          "Rest_Name": "Easton",
          "id": 157,
          "address": "4034 Townsfair Way # J-102",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.762985,
              -86.338379
          ],
          "Rest_Name": "Rockville Road",
          "id": 158,
          "address": "10403 E US Highway 36",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.165003,
              -83.020761
          ],
          "Rest_Name": "Rt. 23 North",
          "id": 159,
          "address": "8695 Owenfield Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.915645,
              -82.780962
          ],
          "Rest_Name": "Pickerington",
          "id": 160,
          "address": "1291 Hill Rd N",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.019662,
              -82.866796
          ],
          "Rest_Name": "Gahanna",
          "id": 161,
          "address": "95 N Hamilton Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.143923,
              -82.989408
          ],
          "Rest_Name": "Polaris",
          "id": 162,
          "address": "1140 Polaris Pkwy",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.166302,
              -86.529029
          ],
          "Rest_Name": "Indiana University",
          "id": 163,
          "address": "420 E Kirkwood Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.94068,
              -82.79108
          ],
          "Rest_Name": "Taylor Square",
          "id": 164,
          "address": "7611 Farmsbury Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.938992,
              -86.23526
          ],
          "Rest_Name": "Carmel West",
          "id": 165,
          "address": "10575 N Michigan Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.980664,
              -82.82176
          ],
          "Rest_Name": "Blacklick Crossing",
          "id": 166,
          "address": "6815 E. Broad Street",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.108926,
              -82.925771
          ],
          "Rest_Name": "Westerville",
          "id": 167,
          "address": "641 S State St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.055933,
              -82.866233
          ],
          "Rest_Name": "Chestnut Hill",
          "id": 168,
          "address": "4750 Morse Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.042351,
              -86.173304
          ],
          "Rest_Name": "Monon Crossing",
          "id": 169,
          "address": "829 E State Road 32 Ste 835",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.731452,
              -82.61394
          ],
          "Rest_Name": "Lancaster",
          "id": 170,
          "address": "1608 N Memorial Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.163956,
              -86.57451
          ],
          "Rest_Name": "Bloomington West",
          "id": 171,
          "address": "3151 W 3rd St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.268145,
              -83.071025
          ],
          "Rest_Name": "Delaware Community Plaza",
          "id": 172,
          "address": "1710 Columbus Pike Ste 220",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.146288,
              -82.922796
          ],
          "Rest_Name": "Maxtown",
          "id": 173,
          "address": "63 Maxtown Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.713557,
              -82.577375
          ],
          "Rest_Name": "Lancaster East",
          "id": 174,
          "address": "1248 E Main St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.093564,
              -82.823459
          ],
          "Rest_Name": "New Albany",
          "id": 175,
          "address": "5375 New Albany Rd W",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.856222,
              -86.392553
          ],
          "Rest_Name": "Brownsburg",
          "id": 176,
          "address": "937 N Green St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.95218,
              -82.683327
          ],
          "Rest_Name": "Etna Township",
          "id": 177,
          "address": "98 Etna Crest Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.267355,
              -82.934174
          ],
          "Rest_Name": "Sunbury#2",
          "id": 178,
          "address": "7162 Longhorn Dr Ste A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.770759,
              -84.164452
          ],
          "Rest_Name": "Lima Elida Road",
          "id": 179,
          "address": "3292 Elida Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.423389,
              -82.419346
          ],
          "Rest_Name": "Marshall University",
          "id": 180,
          "address": "2151 5th Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.473291,
              -86.107957
          ],
          "Rest_Name": "Kokomo",
          "id": 181,
          "address": "1201 S Reed Rd Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.580848,
              -83.083062
          ],
          "Rest_Name": "Marion",
          "id": 182,
          "address": "135 Edgefield Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.038378,
              -82.429925
          ],
          "Rest_Name": "Heath",
          "id": 183,
          "address": "515 Hebron Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.083489,
              -82.427203
          ],
          "Rest_Name": "21st Street Newark",
          "id": 184,
          "address": "1292 N 21st St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.327956,
              -82.101542
          ],
          "Rest_Name": "Ohio University",
          "id": 185,
          "address": "41 S Court St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.419605,
              -82.261201
          ],
          "Rest_Name": "Huntington Mall (Barboursville)",
          "id": 186,
          "address": "18 Mall Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.07226,
              -85.200543
          ],
          "Rest_Name": "Jefferson Pointe Relo",
          "id": 187,
          "address": "1715 Apple Glen Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.399648,
              -82.459244
          ],
          "Rest_Name": "Mt Vernon",
          "id": 188,
          "address": "857 Coshocton Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.058023,
              -83.670918
          ],
          "Rest_Name": "Findlay & I-75",
          "id": 189,
          "address": "1107 Trenton Ave Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.117502,
              -85.12843
          ],
          "Rest_Name": "Coliseum Blvd",
          "id": 190,
          "address": "910 E Coliseum Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.05554,
              -83.591011
          ],
          "Rest_Name": "Findlay",
          "id": 191,
          "address": "15067 E US Route 224",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.135604,
              -85.16216
          ],
          "Rest_Name": "Lima Road",
          "id": 192,
          "address": "6175 Lima Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.947648,
              -82.0032
          ],
          "Rest_Name": "Zanesville I-70",
          "id": 193,
          "address": "695 Zane Street",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.989205,
              -82.023678
          ],
          "Rest_Name": "Zanesville",
          "id": 194,
          "address": "3581 Maple Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.304614,
              -84.36127
          ],
          "Rest_Name": "Defiance",
          "id": 195,
          "address": "1796 N Clinton St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.36776,
              -86.85812
          ],
          "Rest_Name": "Lafayette Concord",
          "id": 196,
          "address": "3235 Builder Drive",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.691468,
              -82.51254
          ],
          "Rest_Name": "I-71 & Mansfield",
          "id": 197,
          "address": "2470 Possum Run Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.417906,
              -86.831375
          ],
          "Rest_Name": "Lafayette",
          "id": 198,
          "address": "4030 South St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.773204,
              -82.589849
          ],
          "Rest_Name": "Mansfield OH",
          "id": 199,
          "address": "764 N Lexington Springmill Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.112488,
              -83.201608
          ],
          "Rest_Name": "Tiffin",
          "id": 200,
          "address": "596 W Market St Ste C",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.423632,
              -86.907163
          ],
          "Rest_Name": "Purdue University",
          "id": 201,
          "address": "200 W State St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.45192,
              -86.915553
          ],
          "Rest_Name": "West Lafayette",
          "id": 202,
          "address": "2400 N Salisbury St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.30518,
              -81.551264
          ],
          "Rest_Name": "Vienna Parkersburg",
          "id": 203,
          "address": "615 Grand Central Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.318562,
              -81.718723
          ],
          "Rest_Name": "Corridor G",
          "id": 204,
          "address": "2811 Mountaineer Blvd",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.373966,
              -83.624628
          ],
          "Rest_Name": "BGSU",
          "id": 205,
          "address": "1558 E Wooster St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.994693,
              -81.575198
          ],
          "Rest_Name": "Cambridge & I-70",
          "id": 206,
          "address": "61690 Southgate Rd Ste 4",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.8608,
              -82.2708
          ],
          "Rest_Name": "Ashland",
          "id": 207,
          "address": "1988 E Main St # US250",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.721952,
              -87.123223
          ],
          "Rest_Name": "Owensboro",
          "id": 208,
          "address": "4911 Frederica St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.37782,
              -83.115892
          ],
          "Rest_Name": "Fremont OH",
          "id": 209,
          "address": "851 Sean Dr Ste 1",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.548701,
              -83.600682
          ],
          "Rest_Name": "Perrysburg",
          "id": 210,
          "address": "10711 Fremont Pike",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.578844,
              -83.66412
          ],
          "Rest_Name": "Maumee",
          "id": 211,
          "address": "1385 Conant St Ste A",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.988466,
              -87.473141
          ],
          "Rest_Name": "Evansville Burkhardt",
          "id": 212,
          "address": "1310 Hirschland Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.612578,
              -83.701442
          ],
          "Rest_Name": "Holland",
          "id": 213,
          "address": "6658 Airport Hwy Ste C",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.002913,
              -87.493061
          ],
          "Rest_Name": "Green River North",
          "id": 214,
          "address": "2800 N Green River Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.980819,
              -87.492085
          ],
          "Rest_Name": "Evansville Green River Road",
          "id": 215,
          "address": "499 N Green River Rd Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.26842,
              -82.609269
          ],
          "Rest_Name": "Norwalk Ohio",
          "id": 216,
          "address": "346 Milan Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.942751,
              -86.421114
          ],
          "Rest_Name": "Bowling Green KY",
          "id": 217,
          "address": "3040 Scottsville Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.84967,
              -81.947159
          ],
          "Rest_Name": "Wooster",
          "id": 218,
          "address": "4124 Burbank Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.673053,
              -83.716357
          ],
          "Rest_Name": "Sylvania",
          "id": 219,
          "address": "7229 W Central Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.636388,
              -83.478429
          ],
          "Rest_Name": "Oregon",
          "id": 220,
          "address": "2924 Navarre Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.676601,
              -83.623213
          ],
          "Rest_Name": "Westgate",
          "id": 221,
          "address": "3305 W Central Ave Unit A2-60",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.693315,
              -83.645571
          ],
          "Rest_Name": "Franklin Park # 2",
          "id": 222,
          "address": "4109 Talmadge Rd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.975948,
              -87.632676
          ],
          "Rest_Name": "West Evansville",
          "id": 223,
          "address": "4710 W Lloyd Expy",
          "rating": 4.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.416369,
              -82.671562
          ],
          "Rest_Name": "Sandusky Milan Road",
          "id": 224,
          "address": "4318 Milan Rd Unit 2",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.493079,
              -81.472435
          ],
          "Rest_Name": "New Philadelphia",
          "id": 225,
          "address": "135 Bluebell Dr SW",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.722341,
              -83.517128
          ],
          "Rest_Name": "Alexis Road",
          "id": 226,
          "address": "1206 E Alexis Rd Ste 100",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.618,
              -85.894001
          ],
          "Rest_Name": "Goshen",
          "id": 227,
          "address": "2225 Rieth Blvd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.875702,
              -84.042525
          ],
          "Rest_Name": "Adrian",
          "id": 228,
          "address": "1421 S Main St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.66306,
              -86.11066
          ],
          "Rest_Name": "Mishawaka South",
          "id": 229,
          "address": "4411 Lincolnway East Hwy",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.722552,
              -85.976624
          ],
          "Rest_Name": "Elkhart",
          "id": 230,
          "address": "135 County Road 6 E",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.795738,
              -81.527338
          ],
          "Rest_Name": "Massillon",
          "id": 231,
          "address": "13 Tommy Henrich Dr NW",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.628508,
              -86.252677
          ],
          "Rest_Name": "Ireland and 31 South Bend",
          "id": 232,
          "address": "202 W Ireland Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.797693,
              -81.486157
          ],
          "Rest_Name": "Lincoln Way",
          "id": 233,
          "address": "2548 Lincoln Way E",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.15056,
              -81.863833
          ],
          "Rest_Name": "Medina",
          "id": 234,
          "address": "899 N Court St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.049712,
              -81.729649
          ],
          "Rest_Name": "Wadsworth",
          "id": 235,
          "address": "990 High St Ste C",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.710279,
              -86.180722
          ],
          "Rest_Name": "Mishawaka",
          "id": 236,
          "address": "5545 N Main St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.692037,
              -86.235757
          ],
          "Rest_Name": "Notre Dame",
          "id": 237,
          "address": "1251 N Eddy St Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.416945,
              -82.208387
          ],
          "Rest_Name": "Amherst Lorain",
          "id": 238,
          "address": "901 N Leavitt Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.135712,
              -81.797782
          ],
          "Rest_Name": "Medina & I-71",
          "id": 239,
          "address": "3070 Medina Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.795677,
              -81.424735
          ],
          "Rest_Name": "Canton Centre",
          "id": 240,
          "address": "4130 Tuscarawas St W",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.025054,
              -81.637934
          ],
          "Rest_Name": "Norton",
          "id": 241,
          "address": "4169 S Cleveland Massillon Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.79963,
              -81.1771
          ],
          "Rest_Name": "Beckley",
          "id": 242,
          "address": "1080 N Eisenhower Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.726463,
              -86.250046
          ],
          "Rest_Name": "IN-933",
          "id": 243,
          "address": "52770 IN-933",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.397005,
              -82.106523
          ],
          "Rest_Name": "Elyria-Midway Crossing",
          "id": 244,
          "address": "1615 W River Rd N",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.010155,
              -81.589504
          ],
          "Rest_Name": "Barberton",
          "id": 245,
          "address": "446 Robinson Ave Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.855239,
              -81.432933
          ],
          "Rest_Name": "Belden Village # 2",
          "id": 246,
          "address": "5097 Dressler Rd NW",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.940334,
              -83.405395
          ],
          "Rest_Name": "Monroe - MI",
          "id": 247,
          "address": "1316 N Telegraph Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.834292,
              -81.388086
          ],
          "Rest_Name": "Cleveland Ave",
          "id": 248,
          "address": "3232 Cleveland Ave NW",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.237845,
              -81.806825
          ],
          "Rest_Name": "Brunswick",
          "id": 249,
          "address": "3362 Center Rd Ste E20",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.424517,
              -82.078073
          ],
          "Rest_Name": "Sheffield",
          "id": 250,
          "address": "5252 Detroit Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.071796,
              -80.866701
          ],
          "Rest_Name": "St Clairsville",
          "id": 251,
          "address": "68041 Mall Ring Rd",
          "rating": 4.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.892436,
              -81.405208
          ],
          "Rest_Name": "Main & Applegrove",
          "id": 252,
          "address": "1472 N Main St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.982711,
              -81.493884
          ],
          "Rest_Name": "Arlington Ridge",
          "id": 253,
          "address": "790 Arlington Rdg Unit 209",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.958534,
              -81.462868
          ],
          "Rest_Name": "Green Township",
          "id": 254,
          "address": "1840 Franks Pkwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.396667,
              -82.002747
          ],
          "Rest_Name": "Center Ridge Rd",
          "id": 255,
          "address": "34712 Center Ridge Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.386286,
              -81.974648
          ],
          "Rest_Name": "North Ridgeville",
          "id": 256,
          "address": "32223 Lorain Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.54969,
              -82.525271
          ],
          "Rest_Name": "Kingsport",
          "id": 257,
          "address": "1401 E Stone Dr",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.135409,
              -81.637787
          ],
          "Rest_Name": "Fairlawn",
          "id": 258,
          "address": "3890 Medina Rd Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.87462,
              -81.36367
          ],
          "Rest_Name": "North Canton",
          "id": 259,
          "address": "6410 Market Ave N",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.028602,
              -81.512372
          ],
          "Rest_Name": "Waterloo Road",
          "id": 260,
          "address": "454 E Waterloo Rd Ste 103",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.306246,
              -81.83457
          ],
          "Rest_Name": "Strongsville",
          "id": 261,
          "address": "15029 Pearl Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.313537,
              -81.818606
          ],
          "Rest_Name": "SouthPark - R",
          "id": 262,
          "address": "17090 Royalton Rd Ste A",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.097023,
              -81.543809
          ],
          "Rest_Name": "Highland Square",
          "id": 263,
          "address": "825 W Market St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.072904,
              -81.513441
          ],
          "Rest_Name": "University of Akron",
          "id": 264,
          "address": "272 E Exchange St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.458786,
              -82.01828
          ],
          "Rest_Name": "Avon City Center",
          "id": 265,
          "address": "35925 Detroit Rd Ste 115",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.028263,
              -81.460997
          ],
          "Rest_Name": "Springfield Township",
          "id": 266,
          "address": "1289 George Washington Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.110393,
              -88.230635
          ],
          "Rest_Name": "Champaign",
          "id": 267,
          "address": "528 E Green St Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.418205,
              -81.916335
          ],
          "Rest_Name": "N Olmsted",
          "id": 268,
          "address": "26420 Great Northern Shop Ctr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.469024,
              -87.030069
          ],
          "Rest_Name": "Valpo",
          "id": 269,
          "address": "2505 Laporte Ave Ste 121",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.371515,
              -81.826474
          ],
          "Rest_Name": "Middleburg Hts.",
          "id": 270,
          "address": "17999 Bagley Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.138863,
              -85.507637
          ],
          "Rest_Name": "Cookeville TN",
          "id": 271,
          "address": "1255 Interstate Dr Ste D109",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.463702,
              -81.952375
          ],
          "Rest_Name": "West Bay Plaza",
          "id": 272,
          "address": "29990 Detroit Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.314031,
              -81.725841
          ],
          "Rest_Name": "Royalton Road",
          "id": 273,
          "address": "5702 Royalton Rd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.137485,
              -88.258603
          ],
          "Rest_Name": "Champaign Prospect",
          "id": 274,
          "address": "903 W Anthony Dr # A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.11958,
              -81.479412
          ],
          "Rest_Name": "Cuyahoga Falls",
          "id": 275,
          "address": "371 Howe Ave Ste C",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.591682,
              -82.260116
          ],
          "Rest_Name": "Bristol TN",
          "id": 276,
          "address": "413 Pinnacle Pkwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.148172,
              -81.507624
          ],
          "Rest_Name": "Portage Crossing",
          "id": 277,
          "address": "2042 Portage Trl",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.380021,
              -81.788187
          ],
          "Rest_Name": "130th & Pearl",
          "id": 278,
          "address": "6860 Pearl Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.163344,
              -81.477624
          ],
          "Rest_Name": "Graham & Hudson",
          "id": 279,
          "address": "994 Graham Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.026345,
              -83.927835
          ],
          "Rest_Name": "Fountain City",
          "id": 280,
          "address": "4829 N Broadway St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.314096,
              -81.654757
          ],
          "Rest_Name": "Broadview Heights",
          "id": 281,
          "address": "4416 Royalton Rd Ste A",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.461396,
              -81.852967
          ],
          "Rest_Name": "Rocky River",
          "id": 282,
          "address": "21029 Center Ridge Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.384207,
              -81.73832
          ],
          "Rest_Name": "Parma 2",
          "id": 283,
          "address": "7683 W Ridgewood Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.099646,
              -81.385935
          ],
          "Rest_Name": "Brimfield",
          "id": 284,
          "address": "4050 Cascades Blvd Ste F",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.450292,
              -81.814071
          ],
          "Rest_Name": "Kamms Corner",
          "id": 285,
          "address": "16729 Lorain Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.361922,
              -81.685409
          ],
          "Rest_Name": "Pleasant Valley - OH",
          "id": 286,
          "address": "1042 W Pleasant Valley Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.423149,
              -81.759351
          ],
          "Rest_Name": "Brooklyn (Tiedeman)",
          "id": 287,
          "address": "10325 Cascade Xing",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.57839,
              -87.04423
          ],
          "Rest_Name": "Chesterton IN",
          "id": 288,
          "address": "2700 Matson Drive",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.156113,
              -81.405807
          ],
          "Rest_Name": "Stow",
          "id": 289,
          "address": "4338 Kent Rd Unit 400",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.673404,
              -86.89336
          ],
          "Rest_Name": "Michigan City",
          "id": 290,
          "address": "5218 Franklin St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.138116,
              -88.56602
          ],
          "Rest_Name": "Effingham",
          "id": 291,
          "address": "1207 N Keller Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.484839,
              -81.801136
          ],
          "Rest_Name": "Lakewood",
          "id": 292,
          "address": "14881 Detroit Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.901415,
              -81.129039
          ],
          "Rest_Name": "Mt Union Alliance",
          "id": 293,
          "address": "1630 W State St Ste A",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.42293,
              -87.3348
          ],
          "Rest_Name": "Crown Point IN",
          "id": 294,
          "address": "10715 Broadway",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.059494,
              -80.599991
          ],
          "Rest_Name": "Triadelphia",
          "id": 295,
          "address": "225 Cabela Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.396235,
              -81.641873
          ],
          "Rest_Name": "Rockside Corners",
          "id": 296,
          "address": "6901 Rockside Rd Ste 13",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.154165,
              -81.349592
          ],
          "Rest_Name": "Kent State - R",
          "id": 297,
          "address": "605 E Main St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.487498,
              -81.767906
          ],
          "Rest_Name": "W 117th",
          "id": 298,
          "address": "11638 Clifton Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.240241,
              -81.445389
          ],
          "Rest_Name": "Hudson Station",
          "id": 299,
          "address": "5 Atterbury Blvd Ste 1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.956584,
              -83.932753
          ],
          "Rest_Name": "Knoxville UT Campus",
          "id": 300,
          "address": "1701 Cumberland Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.312515,
              -81.523093
          ],
          "Rest_Name": "Macedonia",
          "id": 301,
          "address": "8195 Golden Link Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.551197,
              -87.180853
          ],
          "Rest_Name": "Portage IN",
          "id": 302,
          "address": "6040 US Highway 6",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.471713,
              -87.300389
          ],
          "Rest_Name": "Hobart R",
          "id": 303,
          "address": "2831 E 80th Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.240706,
              -83.74529
          ],
          "Rest_Name": "Briarwood",
          "id": 304,
          "address": "858 Briarwood Cir Ste E",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.140423,
              -83.225054
          ],
          "Rest_Name": "Woodhaven",
          "id": 305,
          "address": "23061 Allen Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.460039,
              -81.690368
          ],
          "Rest_Name": "Steelyard",
          "id": 306,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.924314,
              -84.03697
          ],
          "Rest_Name": "West Town Mall Food Court",
          "id": 307,
          "address": "7600 Kingston Pike",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.409464,
              -81.616331
          ],
          "Rest_Name": "Garfield Heights",
          "id": 308,
          "address": "9761 Vista Way",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.915322,
              -84.089824
          ],
          "Rest_Name": "Knoxville Cedar Bluff",
          "id": 309,
          "address": "204 Advantage Pl",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.255368,
              -83.693385
          ],
          "Rest_Name": "Ann Arbor Washtenaw",
          "id": 310,
          "address": "3354 Washtenaw Ave Ste A",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.899326,
              -84.160592
          ],
          "Rest_Name": "Turkey Creek",
          "id": 311,
          "address": "11380 Parkside Dr",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.2758,
              -83.77831
          ],
          "Rest_Name": "West Ann Arbor",
          "id": 312,
          "address": "2295 West Stadium Boulevard",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.499411,
              -81.691688
          ],
          "Rest_Name": "Downtown Cleveland",
          "id": 313,
          "address": "224 Euclid Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.308069,
              -81.438997
          ],
          "Rest_Name": "Twinsburg",
          "id": 314,
          "address": "2640 Creekside Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.279329,
              -83.740607
          ],
          "Rest_Name": "Ann Arbor Go Blue",
          "id": 315,
          "address": "235 S State St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.251016,
              -81.363459
          ],
          "Rest_Name": "Streetsboro",
          "id": 316,
          "address": "9754 State Route 14",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.230429,
              -85.589686
          ],
          "Rest_Name": "Portage",
          "id": 317,
          "address": "5964 S Westnedge Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.19139,
              -86.2954
          ],
          "Rest_Name": "Lebanon",
          "id": 318,
          "address": "620 S Cumberland St",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.319596,
              -86.600238
          ],
          "Rest_Name": "Hendersonville",
          "id": 319,
          "address": "214 Indian Lake Blvd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.197655,
              -83.257766
          ],
          "Rest_Name": "Taylor",
          "id": 320,
          "address": "23111 Eureka Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.340525,
              -82.375067
          ],
          "Rest_Name": "Johnson City",
          "id": 321,
          "address": "2013 N Roan St Ste 10",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.412378,
              -81.536786
          ],
          "Rest_Name": "Maple Heights",
          "id": 322,
          "address": "5501 Warrensville Center Rd",
          "rating": 1.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.157616,
              -81.228214
          ],
          "Rest_Name": "Ravenna",
          "id": 323,
          "address": "787 E Main St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.836165,
              -87.473652
          ],
          "Rest_Name": "Hopkinsville",
          "id": 324,
          "address": "3100 Fort Campbell Blvd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.271432,
              -83.487776
          ],
          "Rest_Name": "South Canton",
          "id": 325,
          "address": "45541 Michigan Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.501274,
              -81.59449
          ],
          "Rest_Name": "Cleveland Heights",
          "id": 326,
          "address": "12401 Cedar Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.385365,
              -81.440518
          ],
          "Rest_Name": "Solon",
          "id": 327,
          "address": "33599 Aurora Rd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.509752,
              -81.603895
          ],
          "Rest_Name": "Uptown CWRU",
          "id": 328,
          "address": "11452 Euclid Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.463484,
              -81.536585
          ],
          "Rest_Name": "Downtown Shaker",
          "id": 329,
          "address": "3530 Warrensville Center Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.356843,
              -81.389561
          ],
          "Rest_Name": "Aurora OH",
          "id": 330,
          "address": "7020 Aurora Rd",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.448202,
              -81.495887
          ],
          "Rest_Name": "Chagrin Highlands",
          "id": 331,
          "address": "4015 Richmond Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.173868,
              -87.85215
          ],
          "Rest_Name": "Bradley",
          "id": 332,
          "address": "1601 N State Route 50",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.2989,
              -86.6977
          ],
          "Rest_Name": "Rivergate",
          "id": 333,
          "address": "1806 Gallatin Pike N",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.501692,
              -81.53834
          ],
          "Rest_Name": "Cedar Center",
          "id": 334,
          "address": "13937 Cedar Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.295394,
              -85.655284
          ],
          "Rest_Name": "Kalamazoo",
          "id": 335,
          "address": "5363B W Main St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.462859,
              -81.480645
          ],
          "Rest_Name": "Eton",
          "id": 336,
          "address": "28077 Chagrin Blvd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.49225,
              -87.50578
          ],
          "Rest_Name": "Dyer IN",
          "id": 337,
          "address": "935 Richard Road",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.525502,
              -87.470687
          ],
          "Rest_Name": "Highland IN",
          "id": 338,
          "address": "10343 Indianapolis Blvd Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.32299,
              -83.459483
          ],
          "Rest_Name": "Canton",
          "id": 339,
          "address": "42404 Ford Road",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.39876,
              -80.55141
          ],
          "Rest_Name": "Weirton",
          "id": 340,
          "address": "261 Saint Thomas Dr",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.501434,
              -81.503974
          ],
          "Rest_Name": "Legacy Village Relo",
          "id": 341,
          "address": "24651 Cedar Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.174831,
              -86.514323
          ],
          "Rest_Name": "Mt. Juliet",
          "id": 342,
          "address": "30 Old Pleasant Grove Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.598166,
              -87.286101
          ],
          "Rest_Name": "Clarksville TN",
          "id": 343,
          "address": "625 Kennedy Ln",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.282955,
              -83.203583
          ],
          "Rest_Name": "Allen Park",
          "id": 344,
          "address": "3125 Fairlane Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.326861,
              -83.302504
          ],
          "Rest_Name": "Dearborn Heights",
          "id": 345,
          "address": "26670 Ford Road",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.900679,
              -80.829772
          ],
          "Rest_Name": "Salem - OH",
          "id": 346,
          "address": "2203 E State St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.194749,
              -86.618089
          ],
          "Rest_Name": "Hermitage 1",
          "id": 347,
          "address": "5000B Old Hickory Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.830762,
              -83.573012
          ],
          "Rest_Name": "Sevierville",
          "id": 348,
          "address": "1713 Parkway",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.358894,
              -83.443966
          ],
          "Rest_Name": "Plymouth MI",
          "id": 349,
          "address": "40815 Ann Arbor Road",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.563999,
              -87.508464
          ],
          "Rest_Name": "Munster",
          "id": 350,
          "address": "8201 Calumet Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.76393,
              -83.98878
          ],
          "Rest_Name": "Alcoa",
          "id": 351,
          "address": "909 Vintage Alcoa Way",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.42818,
              -80.1881
          ],
          "Rest_Name": "Fairmont",
          "id": 352,
          "address": "246 MIDDLETOWN ROAD",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.524944,
              -81.4372
          ],
          "Rest_Name": "Eastgate",
          "id": 353,
          "address": "6717 Eastgate Dr",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.539326,
              -81.453421
          ],
          "Rest_Name": "Highland Heights",
          "id": 354,
          "address": "6267 Wilson Mills Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.609862,
              -81.525876
          ],
          "Rest_Name": "Lakeshore Blvd.",
          "id": 355,
          "address": "22250 Lake Shore Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.507551,
              -87.272863
          ],
          "Rest_Name": "Clarksville Commons",
          "id": 356,
          "address": "2296 Madison St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.664223,
              -80.588819
          ],
          "Rest_Name": "East Liverpool",
          "id": 357,
          "address": "16300 Dresden Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.384284,
              -83.333142
          ],
          "Rest_Name": "Livonia",
          "id": 358,
          "address": "13900 Middlebelt Road ",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.424021,
              -83.433792
          ],
          "Rest_Name": "Northville Haggerty",
          "id": 359,
          "address": "18885 Traditions Dr",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.189243,
              -86.798418
          ],
          "Rest_Name": "MetroCenter Nashville",
          "id": 360,
          "address": "2032 Rosa L Parks Blvd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.568079,
              -87.636924
          ],
          "Rest_Name": "Homewood - Flossmoor",
          "id": 361,
          "address": "17700 Halsted St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.63805,
              -81.474738
          ],
          "Rest_Name": "Willowick",
          "id": 362,
          "address": "30482 Lakeshore Blvd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.600453,
              -87.602416
          ],
          "Rest_Name": "South Holland",
          "id": 363,
          "address": "559 E 162nd St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.505648,
              -87.733312
          ],
          "Rest_Name": "Matteson Lincoln Hwy.",
          "id": 364,
          "address": "4815 211th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.626806,
              -81.429633
          ],
          "Rest_Name": "Euclid Avenue",
          "id": 365,
          "address": "36200 Euclid Ave Ste 12",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.147259,
              -86.798827
          ],
          "Rest_Name": "Vanderbilt",
          "id": 366,
          "address": "400 21st Ave S",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.483718,
              -83.47416
          ],
          "Rest_Name": "Novi",
          "id": 367,
          "address": "43345 Crescent Blvd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.144099,
              -86.813277
          ],
          "Rest_Name": "Nashville West End",
          "id": 368,
          "address": "2825 W End Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.50189,
              -83.53699
          ],
          "Rest_Name": "Wixom",
          "id": 369,
          "address": "28703 SOUTH WIXOM ROAD",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.025144,
              -80.735312
          ],
          "Rest_Name": "Canfield",
          "id": 370,
          "address": "4542 Boardman Canfield Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.541001,
              -83.785801
          ],
          "Rest_Name": "Brighton MI",
          "id": 371,
          "address": "8599 W Grand River Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.111374,
              -86.766713
          ],
          "Rest_Name": "100 Oaks",
          "id": 372,
          "address": "731 Thompson Ln",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.098545,
              -80.766099
          ],
          "Rest_Name": "Austintown",
          "id": 373,
          "address": "5553 Mahoning Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.546443,
              -87.79326
          ],
          "Rest_Name": "191st & Harlem",
          "id": 374,
          "address": "7226 W 191st Street",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.659139,
              -81.376991
          ],
          "Rest_Name": "Mentor Relo",
          "id": 375,
          "address": "7787 Reynolds Rd Ste B",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.129387,
              -86.853176
          ],
          "Rest_Name": "Belle Meade",
          "id": 376,
          "address": "96 White Bridge Pike Ste 103",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.033683,
              -86.650823
          ],
          "Rest_Name": "Century Farms",
          "id": 377,
          "address": "4216 Century Farms Ter Ste 101",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.105401,
              -86.812737
          ],
          "Rest_Name": "Green Hills",
          "id": 378,
          "address": "3900 Hillsboro Pike Ste 28",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.12007,
              -80.76694
          ],
          "Rest_Name": "I-80 & SR 46",
          "id": 379,
          "address": "835 N Canfield Niles Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.481599,
              -83.241589
          ],
          "Rest_Name": "Southfield Evergreen",
          "id": 380,
          "address": "26147 Evergreen Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.40649,
              -82.915806
          ],
          "Rest_Name": "Detroit / Grosse Pointe",
          "id": 381,
          "address": "4444 Radnor St",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.463939,
              -83.137454
          ],
          "Rest_Name": "Ferndale",
          "id": 382,
          "address": "23123 Woodward Ave",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.497849,
              -83.28418
          ],
          "Rest_Name": "Telegraph & 12 Mile",
          "id": 383,
          "address": "28644 Telegraph Rd Spc C-9",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.132694,
              -86.894666
          ],
          "Rest_Name": "Nashville West",
          "id": 384,
          "address": "6838 Charlotte Pike",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.52954,
              -83.43854
          ],
          "Rest_Name": "Commerce Twp",
          "id": 385,
          "address": "273 Haggerty Road",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.978651,
              -86.564851
          ],
          "Rest_Name": "Smyrna TN",
          "id": 386,
          "address": "715 Team Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.502393,
              -87.886747
          ],
          "Rest_Name": "Frankfort",
          "id": 387,
          "address": "11129 W Lincoln Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.54178,
              -87.851328
          ],
          "Rest_Name": "Mokena",
          "id": 388,
          "address": "19130 S La Grange Rd Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.897961,
              -88.955963
          ],
          "Rest_Name": "Decatur IL",
          "id": 389,
          "address": "4165 N Water St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.023823,
              -80.643508
          ],
          "Rest_Name": "Boardman",
          "id": 390,
          "address": "525 Boardman Poland Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.600804,
              -87.794607
          ],
          "Rest_Name": "Tinley Park",
          "id": 391,
          "address": "15980 Harlem Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.489379,
              -83.147059
          ],
          "Rest_Name": "Downtown Royal Oak",
          "id": 392,
          "address": "305 W 11 Mile Rd",
          "rating": 1.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.657422,
              -80.002337
          ],
          "Rest_Name": "University Town Center",
          "id": 393,
          "address": "17 Chaplin Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.649439,
              -87.738744
          ],
          "Rest_Name": "Crestwood",
          "id": 394,
          "address": "13340 Cicero Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.539073,
              -83.359947
          ],
          "Rest_Name": "Orchard Lake 2",
          "id": 395,
          "address": "6753 Orchard Lake Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.735695,
              -87.625984
          ],
          "Rest_Name": "Chatham",
          "id": 396,
          "address": "41 W 87th St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.880857,
              -86.383934
          ],
          "Rest_Name": "North Murfreesboro",
          "id": 397,
          "address": "2113 Memorial Blvd Ste A",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.676628,
              -81.310013
          ],
          "Rest_Name": "Mentor Heisley",
          "id": 398,
          "address": "9188 Mentor Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.721433,
              -87.679919
          ],
          "Rest_Name": "Beverly",
          "id": 399,
          "address": "2302 W 95th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.029744,
              -86.791491
          ],
          "Rest_Name": "Brentwood Nashville",
          "id": 400,
          "address": "269 Franklin Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.512894,
              -87.950729
          ],
          "Rest_Name": "New Lenox",
          "id": 401,
          "address": "641 E Lincoln Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.530744,
              -83.198054
          ],
          "Rest_Name": "Royal Oak Woodward",
          "id": 402,
          "address": "32824 Woodward Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.631733,
              -79.954639
          ],
          "Rest_Name": "WVU",
          "id": 403,
          "address": "461 High St Ste 103",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.691864,
              -87.740233
          ],
          "Rest_Name": "111th and Cicero Oak Lawn",
          "id": 404,
          "address": "11018 S Cicero Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.799695,
              -87.588031
          ],
          "Rest_Name": "Hyde Park Chicago",
          "id": 405,
          "address": "1522 E 53rd St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.661348,
              -81.240686
          ],
          "Rest_Name": "Concord Township",
          "id": 406,
          "address": "7689 Crile Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.720392,
              -87.703901
          ],
          "Rest_Name": "Evergreen Park",
          "id": 407,
          "address": "3259 W 95th St",
          "rating": 1.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.103181,
              -80.647111
          ],
          "Rest_Name": "YSU",
          "id": 408,
          "address": "320 Wick Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.217559,
              -80.74124
          ],
          "Rest_Name": "Niles",
          "id": 409,
          "address": "1922 Niles Cortland Rd SE",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.274053,
              -80.127251
          ],
          "Rest_Name": "Peters Township",
          "id": 410,
          "address": "3840 Washington Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.61661,
              -87.852817
          ],
          "Rest_Name": "Orland Park Relo",
          "id": 411,
          "address": "15071 S La Grange Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.855643,
              -86.430928
          ],
          "Rest_Name": "Murfreesboro",
          "id": 412,
          "address": "479 N Thompson Ln",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.514302,
              -83.047697
          ],
          "Rest_Name": "Warren - MI",
          "id": 413,
          "address": "30229 Mound Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.779975,
              -87.645145
          ],
          "Rest_Name": "Englewood 63rd & Halsted",
          "id": 414,
          "address": "806 W 63rd St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.533882,
              -83.105992
          ],
          "Rest_Name": "14 Mile & John R",
          "id": 415,
          "address": "32 E 14 Mile Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.156854,
              -80.665454
          ],
          "Rest_Name": "Belmont Ave",
          "id": 416,
          "address": "4100 Belmont Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.517208,
              -80.222332
          ],
          "Rest_Name": "Moon Township",
          "id": 417,
          "address": "927 Brodhead Rd",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.276864,
              -80.773925
          ],
          "Rest_Name": "Cortland OH",
          "id": 418,
          "address": "2160 Millennium Blvd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.448909,
              -80.174599
          ],
          "Rest_Name": "North Fayette",
          "id": 419,
          "address": "310 McHolme Dr",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.563563,
              -83.168234
          ],
          "Rest_Name": "Troy",
          "id": 420,
          "address": "3129 Crooks Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.720023,
              -87.776558
          ],
          "Rest_Name": "Oak Lawn 95th",
          "id": 421,
          "address": "6230 W 95th St Ste B",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.354118,
              -80.114269
          ],
          "Rest_Name": "Bridgeville",
          "id": 422,
          "address": "3009 Washington Pike",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.725022,
              -81.248413
          ],
          "Rest_Name": "Painesville",
          "id": 423,
          "address": "7 W Jackson St",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.6495,
              -79.90153
          ],
          "Rest_Name": "Cheat Lake",
          "id": 424,
          "address": "117 FOXTOWN ROAD",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.721455,
              -84.426928
          ],
          "Rest_Name": "Okemos",
          "id": 425,
          "address": "2085 W Grand River Ave Ste D",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.754832,
              -87.740607
          ],
          "Rest_Name": "Chicago-Ford City",
          "id": 426,
          "address": "7561 S. Cicero Avenue",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.607501,
              -83.300305
          ],
          "Rest_Name": "Bloomfield - MI",
          "id": 427,
          "address": "2100 S Telegraph Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.554147,
              -83.028798
          ],
          "Rest_Name": "15 & Van Dyke",
          "id": 428,
          "address": "35394 Van Dyke Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.733636,
              -84.4773
          ],
          "Rest_Name": "East Lansing MSU",
          "id": 429,
          "address": "539 E Grand River Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.731263,
              -87.79849
          ],
          "Rest_Name": "Bridgeview 87th & Harlem",
          "id": 430,
          "address": "8813 S. Harlem Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.632041,
              -87.931793
          ],
          "Rest_Name": "Homer Glen",
          "id": 431,
          "address": "14116 S Bell Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.595803,
              -83.168644
          ],
          "Rest_Name": "Crooks & I-75",
          "id": 432,
          "address": "5322 Crooks Rd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.740544,
              -84.633073
          ],
          "Rest_Name": "Lansing Mall Relo",
          "id": 433,
          "address": "5805 W Saginaw Hwy",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.535861,
              -82.912491
          ],
          "Rest_Name": "Macomb Mall",
          "id": 434,
          "address": "32463 Gratiot Ave",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.796156,
              -87.723641
          ],
          "Rest_Name": "53rd and Pulaski",
          "id": 435,
          "address": "5330 S Pulaski Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.868114,
              -87.626084
          ],
          "Rest_Name": "South Wabash",
          "id": 436,
          "address": "1142 S Wabash Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.868037,
              -87.641059
          ],
          "Rest_Name": "South Loop",
          "id": 437,
          "address": "1132 S Clinton St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.945246,
              -86.825125
          ],
          "Rest_Name": "Cool Springs",
          "id": 438,
          "address": "430 Cool Springs Blvd Ste 100",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.878341,
              -87.627259
          ],
          "Rest_Name": "Jackson",
          "id": 439,
          "address": "10 E Jackson Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.882222,
              -87.627114
          ],
          "Rest_Name": "Madison & State",
          "id": 440,
          "address": "8 E Madison St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.760691,
              -84.506112
          ],
          "Rest_Name": "Lake Lansing Road",
          "id": 441,
          "address": "1603 W Lake Lansing Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.877852,
              -87.635373
          ],
          "Rest_Name": "311 S. Wacker",
          "id": 442,
          "address": "311 S Wacker Dr Lowr 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.347907,
              -80.054165
          ],
          "Rest_Name": "South Hills",
          "id": 443,
          "address": "1720 Washington Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.881865,
              -87.63277
          ],
          "Rest_Name": "Madison LaSalle",
          "id": 444,
          "address": "10 S La Salle St Ste 106",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.887284,
              -87.624809
          ],
          "Rest_Name": "Michigan & Wacker",
          "id": 445,
          "address": "316 N Michigan Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.881215,
              -87.635085
          ],
          "Rest_Name": "230 W. Monroe",
          "id": 446,
          "address": "230 West Monroe Street",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.597196,
              -88.017189
          ],
          "Rest_Name": "Lockport IL",
          "id": 447,
          "address": "16433 W 159th St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.893262,
              -87.620664
          ],
          "Rest_Name": "Ontario",
          "id": 448,
          "address": "291 E Ontario St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.88046,
              -87.640922
          ],
          "Rest_Name": "Presidential Towers",
          "id": 449,
          "address": "525 W Monroe St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.885602,
              -87.635243
          ],
          "Rest_Name": "Franklin",
          "id": 450,
          "address": "233 W Lake St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.895218,
              -87.623167
          ],
          "Rest_Name": "Huron & St. Clair",
          "id": 451,
          "address": "160 E Huron St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.891812,
              -87.628493
          ],
          "Rest_Name": "River North",
          "id": 452,
          "address": "6 W Grand Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.897417,
              -87.625165
          ],
          "Rest_Name": "Loyola Pearson",
          "id": 453,
          "address": "109 E Pearson St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.925546,
              -86.817302
          ],
          "Rest_Name": "Cool Springs East",
          "id": 454,
          "address": "5041 Carothers Pkwy",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.144151,
              -80.582739
          ],
          "Rest_Name": "Radford",
          "id": 455,
          "address": "7406 Lee Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.391647,
              -80.059893
          ],
          "Rest_Name": "Cochran Road",
          "id": 456,
          "address": "1614 Cochran Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.903758,
              -87.628874
          ],
          "Rest_Name": "Division",
          "id": 457,
          "address": "1166 N State St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.872392,
              -87.677268
          ],
          "Rest_Name": "IMD Damen Ogden",
          "id": 458,
          "address": "725 S Seeley St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.790968,
              -87.80108
          ],
          "Rest_Name": "Harlem & Archer",
          "id": 459,
          "address": "5533 S Harlem Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.911327,
              -87.635126
          ],
          "Rest_Name": "Pipers Alley",
          "id": 460,
          "address": "210 W North Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.199188,
              -81.657847
          ],
          "Rest_Name": "Boone",
          "id": 461,
          "address": "1787 Blowing Rock Rd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.522654,
              -88.147525
          ],
          "Rest_Name": "South Joliet",
          "id": 462,
          "address": "2609 W Jefferson St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.642004,
              -83.131413
          ],
          "Rest_Name": "Rochester Hills",
          "id": 463,
          "address": "2611 S Rochester Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.482568,
              -88.952214
          ],
          "Rest_Name": "Bloomington Veterans Prkwy",
          "id": 464,
          "address": "305 N Veterans Pkwy Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.850979,
              -87.743851
          ],
          "Rest_Name": "Cicero",
          "id": 465,
          "address": "2201 S Cicero Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.594245,
              -82.901123
          ],
          "Rest_Name": "Clinton Township",
          "id": 466,
          "address": "40287 S Groesbeck Hwy",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.917751,
              -87.660048
          ],
          "Rest_Name": "Clybourn Commons",
          "id": 467,
          "address": "2000 N Clybourn Ave Ste H",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.925684,
              -87.649527
          ],
          "Rest_Name": "Fullerton Lincoln Relo",
          "id": 468,
          "address": "2400 N Lincoln Ave",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.510087,
              -88.954744
          ],
          "Rest_Name": "Veterans & College Normal",
          "id": 469,
          "address": "1601 E College Ave Ste B",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.932968,
              -87.644199
          ],
          "Rest_Name": "Diversey",
          "id": 470,
          "address": "610 W Diversey Pkwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.913331,
              -87.67732
          ],
          "Rest_Name": "Damen Avenue",
          "id": 471,
          "address": "1733 N Damen Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.91058,
              -87.68696
          ],
          "Rest_Name": "Western & North",
          "id": 472,
          "address": "1611 N Western Ave",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.626416,
              -82.98776
          ],
          "Rest_Name": "Hall & Schoenherr",
          "id": 473,
          "address": "13975 Lakeside Cir",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.78479,
              -87.868119
          ],
          "Rest_Name": "Countryside LaGrange Road",
          "id": 474,
          "address": "5801 S La Grange Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.939757,
              -87.655144
          ],
          "Rest_Name": "Kenmore",
          "id": 475,
          "address": "1025 W Belmont Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.932846,
              -87.668178
          ],
          "Rest_Name": "Ashland Diversey",
          "id": 476,
          "address": "2809 N Ashland Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.681617,
              -83.192438
          ],
          "Rest_Name": "Village at Rochester Hills",
          "id": 477,
          "address": "84 N Adams Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.368159,
              -79.983092
          ],
          "Rest_Name": "Brentwood - PA",
          "id": 478,
          "address": "50 Towne Square Way",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.946918,
              -87.655487
          ],
          "Rest_Name": "Wrigleyville",
          "id": 479,
          "address": "1027 W Addison St Ste A108",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.709054,
              -83.306591
          ],
          "Rest_Name": "Baldwin Road",
          "id": 480,
          "address": "4921 S Baldwin Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.850672,
              -87.802814
          ],
          "Rest_Name": "Berwyn",
          "id": 481,
          "address": "7140 Cermak Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.931623,
              -87.686359
          ],
          "Rest_Name": "Elston Logan",
          "id": 482,
          "address": "2743 N Elston Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.231879,
              -80.431261
          ],
          "Rest_Name": "Blacksburg University Crossroads",
          "id": 483,
          "address": "868 Prices Fork Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.44061,
              -80.00241
          ],
          "Rest_Name": "Market Square",
          "id": 484,
          "address": "211 Forbes Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.576883,
              -88.158892
          ],
          "Rest_Name": "Joliet The Pointe",
          "id": 485,
          "address": "2848 Plainfield Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.504275,
              -88.99489
          ],
          "Rest_Name": "IL State",
          "id": 486,
          "address": "701 S Main St Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.758678,
              -87.944339
          ],
          "Rest_Name": "Willowbrook 83",
          "id": 487,
          "address": "7173 S Kingery Hwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.941367,
              -87.688757
          ],
          "Rest_Name": "Western and Roscoe",
          "id": 488,
          "address": "3312 N Western Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.333354,
              -79.945873
          ],
          "Rest_Name": "Century III",
          "id": 489,
          "address": "509 Clairton Blvd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.616328,
              -88.122773
          ],
          "Rest_Name": "Romeoville",
          "id": 490,
          "address": "253 S Weber Road",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.817789,
              -87.870029
          ],
          "Rest_Name": "LaGrange Relo",
          "id": 491,
          "address": "40 N La Grange Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.231039,
              -80.41524
          ],
          "Rest_Name": "Virginia Tech",
          "id": 492,
          "address": "314 N Main St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.450476,
              -79.987088
          ],
          "Rest_Name": "Strip District",
          "id": 493,
          "address": "1685 Smallman St",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.977139,
              -87.66042
          ],
          "Rest_Name": "Broadway and Foster",
          "id": 494,
          "address": "5224 N Broadway St Ste 102",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.675762,
              -80.098332
          ],
          "Rest_Name": "Cranberry Township",
          "id": 495,
          "address": "20024 Route 19",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.879946,
              -87.805122
          ],
          "Rest_Name": "Harlem & Madison",
          "id": 496,
          "address": "7201 Madison St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.945862,
              -87.709098
          ],
          "Rest_Name": "Addison Elston",
          "id": 497,
          "address": "3227 W Addison St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.66965,
              -82.995681
          ],
          "Rest_Name": "Shelby Township",
          "id": 498,
          "address": "13488 23 Mile Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.888777,
              -87.804045
          ],
          "Rest_Name": "Oak Park",
          "id": 499,
          "address": "1128 Lake St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.529063,
              -80.008719
          ],
          "Rest_Name": "McKnight",
          "id": 500,
          "address": "4861 McKnight Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.716647,
              -88.037908
          ],
          "Rest_Name": "Boughton Road Bolingbrook",
          "id": 501,
          "address": "750 Janes Ave Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.6864,
              -80.0904
          ],
          "Rest_Name": "Cranberry Springs",
          "id": 502,
          "address": "2080 MacKenzie Way Ste B100",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.622987,
              -80.054301
          ],
          "Rest_Name": "Wexford",
          "id": 503,
          "address": "11359 Perry Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.568784,
              -80.023275
          ],
          "Rest_Name": "McCandless Towne Center",
          "id": 504,
          "address": "8870 Covenant Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.439969,
              -79.958694
          ],
          "Rest_Name": "Univ of Pittsburgh R",
          "id": 505,
          "address": "3510 Forbes Ave",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.743279,
              -88.975686
          ],
          "Rest_Name": "Marion IL",
          "id": 506,
          "address": "2804 Joseph Cannon Way",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.002719,
              -87.661209
          ],
          "Rest_Name": "Rogers Park",
          "id": 507,
          "address": "6600 N Sheridan Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.164597,
              -80.421046
          ],
          "Rest_Name": "Christiansburg",
          "id": 508,
          "address": "2545 Market St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.444635,
              -79.948109
          ],
          "Rest_Name": "Carnegie Mellon",
          "id": 509,
          "address": "4611 Forbes Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.953245,
              -87.744923
          ],
          "Rest_Name": "Market Place 6 Corners",
          "id": 510,
          "address": "4657 W Irving Park Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.904913,
              -79.753997
          ],
          "Rest_Name": "Uniontown",
          "id": 511,
          "address": "65 Matthew Dr",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.454233,
              -79.947419
          ],
          "Rest_Name": "Baum & Millvale",
          "id": 512,
          "address": "4800 Baum Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.928521,
              -87.787213
          ],
          "Rest_Name": "Brickyard Mall",
          "id": 513,
          "address": "2640 N Narragansett Ave Ste D1",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.234823,
              -80.450275
          ],
          "Rest_Name": "Hermitage Sharon PA",
          "id": 514,
          "address": "125 N Hermitage Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.989583,
              -87.705598
          ],
          "Rest_Name": "Lincoln Peterson",
          "id": 515,
          "address": "5961 N Lincoln Ave Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.810574,
              -87.968129
          ],
          "Rest_Name": "Westmont",
          "id": 516,
          "address": "300 E Ogden Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.691534,
              -88.123838
          ],
          "Rest_Name": "Bolingbrook R",
          "id": 517,
          "address": "256 S Weber Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.908186,
              -87.840955
          ],
          "Rest_Name": "Melrose Park Relo",
          "id": 518,
          "address": "500 W North Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.975234,
              -87.744321
          ],
          "Rest_Name": "Edens Collection",
          "id": 519,
          "address": "4701 W Foster Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.459291,
              -79.925873
          ],
          "Rest_Name": "Eastside",
          "id": 520,
          "address": "5986 Centre Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.848419,
              -87.932257
          ],
          "Rest_Name": "Oak Brook East",
          "id": 521,
          "address": "2103 Clearwater Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.413529,
              -79.904522
          ],
          "Rest_Name": "The Waterfront",
          "id": 522,
          "address": "650 E Waterfront Dr",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.674083,
              -82.829662
          ],
          "Rest_Name": "Chesterfield",
          "id": 523,
          "address": "27810 23 Mile Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.011785,
              -87.720832
          ],
          "Rest_Name": "Touhy Market Place",
          "id": 524,
          "address": "3654 Touhy Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.651227,
              -88.204231
          ],
          "Rest_Name": "Plainfield 127th",
          "id": 525,
          "address": "12720 S Route 59 Unit 100",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.957549,
              -87.807513
          ],
          "Rest_Name": "HIP - Harlem & Irving",
          "id": 526,
          "address": "4234 N Harlem Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.808292,
              -88.014447
          ],
          "Rest_Name": "Saratoga and Ogden",
          "id": 527,
          "address": "1203 Ogden Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.048587,
              -87.681225
          ],
          "Rest_Name": "Evanston",
          "id": 528,
          "address": "711 Church St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.846534,
              -87.978608
          ],
          "Rest_Name": "Oakbrook",
          "id": 529,
          "address": "18W050 22nd St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.750135,
              -88.11521
          ],
          "Rest_Name": "75th & Naper",
          "id": 530,
          "address": "1315 S Naper Blvd Unit 101",
          "rating": 1.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.0115,
              -87.762874
          ],
          "Rest_Name": "Touhy",
          "id": 531,
          "address": "5373 Touhy Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.931922,
              -87.885508
          ],
          "Rest_Name": "Grand and Mannheim",
          "id": 532,
          "address": "2926 Mannheim Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.602023,
              -79.94546
          ],
          "Rest_Name": "Allison Park",
          "id": 533,
          "address": "4960 William Flynn Hwy",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.487999,
              -79.885476
          ],
          "Rest_Name": "Fox Chapel",
          "id": 534,
          "address": "1027 Freeport Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.835192,
              -88.025263
          ],
          "Rest_Name": "Downers Grove Finley Square",
          "id": 535,
          "address": "1556 Butterfield Rd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.901242,
              -87.939531
          ],
          "Rest_Name": "Elmhurst",
          "id": 536,
          "address": "139 N York St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.8906,
              -87.961391
          ],
          "Rest_Name": "IL 83 and St Charles",
          "id": 537,
          "address": "353 S Il Route 83",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.586721,
              -82.587429
          ],
          "Rest_Name": "Patton Avenue",
          "id": 538,
          "address": "946 Patton Ave Ste 30",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.849426,
              -85.625954
          ],
          "Rest_Name": "South Kentwood",
          "id": 539,
          "address": "6333 Kalamazoo Ave SE Ste 450",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.711822,
              -88.205151
          ],
          "Rest_Name": "Naperville 95th",
          "id": 540,
          "address": "2856 S Route 59 Ste 108",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.052363,
              -87.747295
          ],
          "Rest_Name": "Old Orchard",
          "id": 541,
          "address": "9408 Skokie Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.768814,
              -86.917722
          ],
          "Rest_Name": "Spring Hill 2",
          "id": 542,
          "address": "2098 Wall St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.771193,
              -88.147656
          ],
          "Rest_Name": "Naperville Riverwalk",
          "id": 543,
          "address": "22 E Chicago Ave Ste 103",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.009964,
              -87.827101
          ],
          "Rest_Name": "Park Ridge",
          "id": 544,
          "address": "119 S Northwest Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.797449,
              -88.118673
          ],
          "Rest_Name": "Naperville Tower Crossing",
          "id": 545,
          "address": "1516 N Naper Blvd Ste 104",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.90583,
              -87.985485
          ],
          "Rest_Name": "Villa Park",
          "id": 546,
          "address": "298 W North Ave Unit 1",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.040714,
              -87.796447
          ],
          "Rest_Name": "Morton Grove",
          "id": 547,
          "address": "6861 Dempster St Ste E107",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.859022,
              -88.057634
          ],
          "Rest_Name": "Glen Ellyn",
          "id": 548,
          "address": "695 Roosevelt Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.833803,
              -88.102427
          ],
          "Rest_Name": "Wheaton Relo",
          "id": 549,
          "address": "1980 S Naperville Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.00757,
              -87.885469
          ],
          "Rest_Name": "Rosemont",
          "id": 550,
          "address": "7020 Mannheim Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.768496,
              -88.206342
          ],
          "Rest_Name": "Fox Valley",
          "id": 551,
          "address": "848 N Route 59 Ste 106",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.528736,
              -82.606817
          ],
          "Rest_Name": "Brevard Road",
          "id": 552,
          "address": "879 Brevard Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.801708,
              -88.172313
          ],
          "Rest_Name": "Warrenville",
          "id": 553,
          "address": "28251 Diehl Rd Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.055052,
              -87.835604
          ],
          "Rest_Name": "Golf Mill",
          "id": 554,
          "address": "8488 W Golf Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.911962,
              -85.578299
          ],
          "Rest_Name": "Grand Rapids Woodland",
          "id": 555,
          "address": "3610 28th St SE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.884279,
              -85.739756
          ],
          "Rest_Name": "Grandville Rivertown",
          "id": 556,
          "address": "3070 44th St SW",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.70389,
              -88.30703
          ],
          "Rest_Name": "Oswego",
          "id": 557,
          "address": "2432 US Highway 34",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.439153,
              -79.766929
          ],
          "Rest_Name": "Monroeville",
          "id": 558,
          "address": "4137 William Penn Hwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.81239,
              -86.087795
          ],
          "Rest_Name": "Holland MI",
          "id": 559,
          "address": "12365 James St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.211303,
              -84.863588
          ],
          "Rest_Name": "Cleveland TN",
          "id": 560,
          "address": "4482 Ellis Cir NW",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.85824,
              -88.1399
          ],
          "Rest_Name": "Wheaton West",
          "id": 561,
          "address": "2119 W Roosevelt Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.780454,
              -88.240191
          ],
          "Rest_Name": "Aurora Eola",
          "id": 562,
          "address": "1167 N Eola Rd Ste 105",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.949713,
              -88.032293
          ],
          "Rest_Name": "Addison - Lake 53",
          "id": 563,
          "address": "1078 N Rohlwing Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.569162,
              -79.802922
          ],
          "Rest_Name": "Pittsburgh Mills",
          "id": 564,
          "address": "1003 Village Center Dr",
          "rating": 1.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.976711,
              -83.768421
          ],
          "Rest_Name": "Flint",
          "id": 565,
          "address": "4485 Miller Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.104781,
              -87.814037
          ],
          "Rest_Name": "Glenview",
          "id": 566,
          "address": "2341 Willow Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.504615,
              -82.523931
          ],
          "Rest_Name": "Asheville Hendersonville Road",
          "id": 567,
          "address": "1828 Hendersonville Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.139017,
              -87.793098
          ],
          "Rest_Name": "Northbrook Lake Cook",
          "id": 568,
          "address": "786 Skokie Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.876316,
              -79.946144
          ],
          "Rest_Name": "Butler",
          "id": 569,
          "address": "200 Benevan Sq Ste 4",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.003149,
              -88.004911
          ],
          "Rest_Name": "Elk Grove Village",
          "id": 570,
          "address": "910 Elk Grove Town Ctr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.65683,
              -88.437035
          ],
          "Rest_Name": "Yorkville IL",
          "id": 571,
          "address": "444 E Veterans Pkwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.957178,
              -88.073648
          ],
          "Rest_Name": "Fairfield and Lake Street",
          "id": 572,
          "address": "170 E Lake St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.289909,
              -80.075636
          ],
          "Rest_Name": "Salem VA",
          "id": 573,
          "address": "1122 W Main St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.939191,
              -88.114053
          ],
          "Rest_Name": "Bloomingdale Relo",
          "id": 574,
          "address": "394 W Army Trail Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.109629,
              -87.874887
          ],
          "Rest_Name": "Willow Sanders",
          "id": 575,
          "address": "3846 Willow Road ",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.813732,
              -88.280378
          ],
          "Rest_Name": "Aurora Kirk & Butterfield",
          "id": 576,
          "address": "2902 Kirk Rd Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.081136,
              -87.935653
          ],
          "Rest_Name": "Randhurst",
          "id": 577,
          "address": "102 E Kensington Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.050492,
              -87.994134
          ],
          "Rest_Name": "Rolling Meadows",
          "id": 578,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.422276,
              -79.662292
          ],
          "Rest_Name": "Murrysville-Walnut Hollow",
          "id": 579,
          "address": "4899 William Penn Hwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.915784,
              -81.525122
          ],
          "Rest_Name": "Lenoir",
          "id": 580,
          "address": "139 Blowing Rock Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.167033,
              -87.8441
          ],
          "Rest_Name": "Deerfield",
          "id": 581,
          "address": "675 Deerfield Rd Ste 120",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.040268,
              -88.033668
          ],
          "Rest_Name": "Streets of Woodfield",
          "id": 582,
          "address": "601 N Martingale Rd Ste 330",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.046001,
              -88.038216
          ],
          "Rest_Name": "Woodfield Mall",
          "id": 583,
          "address": "5 Woodfield Mall # VC01",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.877443,
              -80.75807
          ],
          "Rest_Name": "Ashtabula",
          "id": 584,
          "address": "2911 N Ridge Rd E Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.786282,
              -88.376228
          ],
          "Rest_Name": "Aurora Orchard",
          "id": 585,
          "address": "1480 N Orchard Rd Ste 114",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.304414,
              -79.579652
          ],
          "Rest_Name": "Lincoln Place",
          "id": 586,
          "address": "2014 Lincoln Pl",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.1095,
              -87.974
          ],
          "Rest_Name": "Arlington Heights R-2",
          "id": 587,
          "address": "711 E Palatine Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.048967,
              -88.078899
          ],
          "Rest_Name": "Hoffman Estates Golf & Roselle",
          "id": 588,
          "address": "15 E Golf Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.853017,
              -88.339451
          ],
          "Rest_Name": "Batavia Randall Rd.",
          "id": 589,
          "address": "1990 McKee Street ",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.833996,
              -89.605478
          ],
          "Rest_Name": "Springfield North Dirksen",
          "id": 590,
          "address": "2325 N Dirksen Pkwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.920626,
              -88.265868
          ],
          "Rest_Name": "St. Charles",
          "id": 591,
          "address": "3821 E Main St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.125707,
              -85.240269
          ],
          "Rest_Name": "Hixson",
          "id": 592,
          "address": "5248 Highway 153",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.30071,
              -79.956885
          ],
          "Rest_Name": "Valley View Blvd NW",
          "id": 593,
          "address": "4753 Valley View Blvd NW",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.023285,
              -85.688553
          ],
          "Rest_Name": "Grand Rapids Alpine",
          "id": 594,
          "address": "3300 Alpine Ave NW",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.155058,
              -87.954098
          ],
          "Rest_Name": "Wheeling",
          "id": 595,
          "address": "1572 E Lake Cook Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.025247,
              -88.143711
          ],
          "Rest_Name": "Barrington Road",
          "id": 596,
          "address": "2558 W Schaumburg Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.182149,
              -87.929058
          ],
          "Rest_Name": "Lincolnshire",
          "id": 597,
          "address": "950 Milwaukee Ave Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.230484,
              -79.975792
          ],
          "Rest_Name": "Tanglewood",
          "id": 598,
          "address": "4464 Electric Rd Ste A",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.255664,
              -79.955007
          ],
          "Rest_Name": "South Roanoke",
          "id": 599,
          "address": "2011 Colonial Ave SW",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.900848,
              -88.340203
          ],
          "Rest_Name": "Randall & 38",
          "id": 600,
          "address": "815 S Randall Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.303071,
              -79.507396
          ],
          "Rest_Name": "East Greensburg",
          "id": 601,
          "address": "5250 State Route 30",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.138714,
              -88.025533
          ],
          "Rest_Name": "Palatine",
          "id": 602,
          "address": "781 E Dundee Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.593541,
              -87.052038
          ],
          "Rest_Name": "Columbia TN",
          "id": 603,
          "address": "610 S James M Campbell Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.021784,
              -88.203724
          ],
          "Rest_Name": "Sutton Rd. & Irving Park",
          "id": 604,
          "address": "510 S Sutton Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.308965,
              -79.887801
          ],
          "Rest_Name": "Roanoke Orange Ave",
          "id": 605,
          "address": "3661 Blue Hills Vlg Dr NE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.232517,
              -87.942181
          ],
          "Rest_Name": "Vernon Hills",
          "id": 606,
          "address": "375 N Milwaukee Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.161338,
              -88.049837
          ],
          "Rest_Name": "Kildeer",
          "id": 607,
          "address": "20505 Rand Rd Ste 400",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.279038,
              -87.871489
          ],
          "Rest_Name": "Lake Bluff",
          "id": 608,
          "address": "945 Rockland Rd Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.764431,
              -89.704348
          ],
          "Rest_Name": "Springfield White Oaks IL",
          "id": 609,
          "address": "2579 Wabash Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.070549,
              -88.191161
          ],
          "Rest_Name": "Prairie Stone",
          "id": 610,
          "address": "4600 Hoffman Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.063086,
              -85.309764
          ],
          "Rest_Name": "Northshore",
          "id": 611,
          "address": "20 Cherokee Blvd Unit 112",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.034467,
              -85.152592
          ],
          "Rest_Name": "Hamilton Place",
          "id": 612,
          "address": "2122 Gunbarrel Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.985655,
              -88.341586
          ],
          "Rest_Name": "South Elgin",
          "id": 613,
          "address": "348 Randall Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.346185,
              -82.434631
          ],
          "Rest_Name": "Hendersonville NC",
          "id": 614,
          "address": "59 Highlands Square Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.240787,
              -87.992757
          ],
          "Rest_Name": "Mundelein",
          "id": 615,
          "address": "520 Townline Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.283981,
              -87.953805
          ],
          "Rest_Name": "Libertyville",
          "id": 616,
          "address": "139 N Milwaukee Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.342315,
              -87.895796
          ],
          "Rest_Name": "Waukegan",
          "id": 617,
          "address": "940 S Waukegan Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.099744,
              -88.28657
          ],
          "Rest_Name": "W Dundee Springhill",
          "id": 618,
          "address": "201 N 8th St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.070495,
              -88.335675
          ],
          "Rest_Name": "Elgin Randall",
          "id": 619,
          "address": "2500 N Randall Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.384224,
              -87.95446
          ],
          "Rest_Name": "Gurnee Mills",
          "id": 620,
          "address": "6040 Gurnee Mills Cir E",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.363335,
              -89.128676
          ],
          "Rest_Name": "Peru",
          "id": 621,
          "address": "4349 Venture Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.940925,
              -85.217636
          ],
          "Rest_Name": "Fort Oglethorpe",
          "id": 622,
          "address": "2989 Battlefield Pkwy",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.669464,
              -89.583838
          ],
          "Rest_Name": "East Peoria",
          "id": 623,
          "address": "300 W Washington St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.171588,
              -88.335889
          ],
          "Rest_Name": "Algonquin",
          "id": 624,
          "address": "412 S Randall Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.3798,
              -88.0627
          ],
          "Rest_Name": "Round Lake Beach",
          "id": 625,
          "address": "1936 Il Route 83",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.707334,
              -81.308264
          ],
          "Rest_Name": "Hickory",
          "id": 626,
          "address": "1770 US Highway 70 SE",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.226642,
              -88.294975
          ],
          "Rest_Name": "Crystal Lake",
          "id": 627,
          "address": "5006 Northwest Hwy Ste A",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.322283,
              -88.166609
          ],
          "Rest_Name": "Lakemoor",
          "id": 628,
          "address": "27400 IL RTE 120",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.78639,
              -89.950357
          ],
          "Rest_Name": "Edwardsville",
          "id": 629,
          "address": "2200 Troy Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.743073,
              -89.631157
          ],
          "Rest_Name": "Peoria Northwoods",
          "id": 630,
          "address": "4512 N Sterling Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.953346,
              -88.725361
          ],
          "Rest_Name": "Dekalb Barber Greene",
          "id": 631,
          "address": "2383 Sycamore Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.194033,
              -86.262967
          ],
          "Rest_Name": "Muskegon",
          "id": 632,
          "address": "3194 Henry St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.931419,
              -88.771632
          ],
          "Rest_Name": "Dekalb NIU",
          "id": 633,
          "address": "1013 W Lincoln Hwy Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.591908,
              -89.985458
          ],
          "Rest_Name": "Fairview Heights",
          "id": 634,
          "address": "6415 N Illinois St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.565674,
              -87.92086
          ],
          "Rest_Name": "Kenosha",
          "id": 635,
          "address": "9370 76th St Ste B",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.359946,
              -88.26651
          ],
          "Rest_Name": "McHenry",
          "id": 636,
          "address": "2304 N Richmond Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.142147,
              -79.122379
          ],
          "Rest_Name": "Staunton",
          "id": 637,
          "address": "102 Crossing Way",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.604072,
              -87.879425
          ],
          "Rest_Name": "Somers",
          "id": 638,
          "address": "3671 Market Lane",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.815342,
              -80.876032
          ],
          "Rest_Name": "statesville",
          "id": 639,
          "address": "210 Turnersburg Hwy # C",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.613064,
              -79.174538
          ],
          "Rest_Name": "Indiana PA",
          "id": 640,
          "address": "1781 Oakland Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.314119,
              -88.435076
          ],
          "Rest_Name": "Woodstock IL",
          "id": 641,
          "address": "161 South Eastwood Dr",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.47945,
              -83.970125
          ],
          "Rest_Name": "Saginaw",
          "id": 642,
          "address": "2721 Tittabawassee Rd Ste 1",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.719538,
              -87.848882
          ],
          "Rest_Name": "Racine",
          "id": 643,
          "address": "5720 Washington Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.168654,
              -80.277207
          ],
          "Rest_Name": "University Pkwy",
          "id": 644,
          "address": "5414 University Pkwy",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.431892,
              -78.85461
          ],
          "Rest_Name": "JMU",
          "id": 645,
          "address": "1615 Reservoir St Ste 101",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.058019,
              -80.09017
          ],
          "Rest_Name": "Erie Peach Street",
          "id": 646,
          "address": "6611 Peach St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.58492,
              -84.768104
          ],
          "Rest_Name": "CMU",
          "id": 647,
          "address": "1720 S Mission St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.105396,
              -80.131654
          ],
          "Rest_Name": "West Erie",
          "id": 648,
          "address": "2204 W 12th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.632632,
              -90.233931
          ],
          "Rest_Name": "SLU",
          "id": 649,
          "address": "212 S Grand Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.064207,
              -78.934775
          ],
          "Rest_Name": "Waynesboro",
          "id": 650,
          "address": "1960 Rosser Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.350009,
              -79.228666
          ],
          "Rest_Name": "Timberlake",
          "id": 651,
          "address": "7920 Timberlake Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.069426,
              -80.29796
          ],
          "Rest_Name": "Hanes Mall",
          "id": 652,
          "address": "128 Hanes Mall Cir Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.271707,
              -78.857576
          ],
          "Rest_Name": "Johnstown PA",
          "id": 653,
          "address": "1431 Scalp Avenue",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.404326,
              -79.187426
          ],
          "Rest_Name": "Lakeside Lynchburg",
          "id": 654,
          "address": "3901 Old Forest Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.943764,
              -82.255956
          ],
          "Rest_Name": "Greer",
          "id": 655,
          "address": "1126 W Wade Hampton Blvd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.804511,
              -90.307734
          ],
          "Rest_Name": "Florissant",
          "id": 656,
          "address": "2391 N Highway 67",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.889874,
              -82.37533
          ],
          "Rest_Name": "Cherrydale",
          "id": 657,
          "address": "3116 N Pleasantburg Dr Ste 100",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.597105,
              -90.293105
          ],
          "Rest_Name": "Hampton Village",
          "id": 658,
          "address": "3547 Hampton Ave",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.823592,
              -82.578272
          ],
          "Rest_Name": "Easley",
          "id": 659,
          "address": "6099 Calhoun Memorial Hwy Ste A",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.345485,
              -79.188818
          ],
          "Rest_Name": "Lynchburg",
          "id": 660,
          "address": "4010 Wards Rd Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.595424,
              -80.875673
          ],
          "Rest_Name": "Mooresville NC",
          "id": 661,
          "address": "643 River Hwy",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.045426,
              -80.256405
          ],
          "Rest_Name": "Peters Creek Pkwy",
          "id": 662,
          "address": "1051 Southpark Blvd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.650555,
              -90.338435
          ],
          "Rest_Name": "DT Clayton",
          "id": 663,
          "address": "1 N Central Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.626347,
              -90.334343
          ],
          "Rest_Name": "Hanley Road",
          "id": 664,
          "address": "1500 S Hanley Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.144103,
              -80.006192
          ],
          "Rest_Name": "East Erie",
          "id": 665,
          "address": "4165 Buffalo Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.672933,
              -90.360415
          ],
          "Rest_Name": "U City",
          "id": 666,
          "address": "8650 Olive Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.848209,
              -82.337894
          ],
          "Rest_Name": "Haywood Road",
          "id": 667,
          "address": "640 Haywood Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.609044,
              -90.366985
          ],
          "Rest_Name": "Rock Hill",
          "id": 668,
          "address": "9621 Manchester Rd",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.860578,
              -82.269226
          ],
          "Rest_Name": "Greenville Pelham",
          "id": 669,
          "address": "3645 Pelham Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.108443,
              -80.097688
          ],
          "Rest_Name": "Kernersville",
          "id": 670,
          "address": "1020 S Main St Ste G",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.68461,
              -82.837358
          ],
          "Rest_Name": "Clemson",
          "id": 671,
          "address": "393 College Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.027585,
              -81.280763
          ],
          "Rest_Name": "London North",
          "id": 672,
          "address": "60 Fanshawe Park Road East Unit 3 Building B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.825873,
              -82.299647
          ],
          "Rest_Name": "Woodruff",
          "id": 673,
          "address": "4 Market Point Dr Ste C",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.937605,
              -81.984639
          ],
          "Rest_Name": "Spartanburg West",
          "id": 674,
          "address": "1490 Wo Ezell Blvd Ste A",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.750698,
              -90.427814
          ],
          "Rest_Name": "Bridgeton",
          "id": 675,
          "address": "3533 McKelvey Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.967113,
              -81.892263
          ],
          "Rest_Name": "Spartanburg East",
          "id": 676,
          "address": "1855 E Main St Ste 26",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.474058,
              -84.925
          ],
          "Rest_Name": "Calhoun GA",
          "id": 677,
          "address": "530 Highway 53 E",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.563706,
              -90.408141
          ],
          "Rest_Name": "Kirkwood",
          "id": 678,
          "address": "1255 S Kirkwood Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.26449,
              -88.980615
          ],
          "Rest_Name": "Rockford",
          "id": 679,
          "address": "751 S Perryville Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.671993,
              -90.436127
          ],
          "Rest_Name": "Creve Coeur",
          "id": 680,
          "address": "11485 Olive Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.82623,
              -89.47571
          ],
          "Rest_Name": "Dixon",
          "id": 681,
          "address": "1681 S. Galena Ave.",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.599121,
              -90.448845
          ],
          "Rest_Name": "West County Mall FC",
          "id": 682,
          "address": "80 W County Ctr Ste 10",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.44367,
              -80.878983
          ],
          "Rest_Name": "Birkdale Village",
          "id": 683,
          "address": "8830 Lindholm Dr Ste 120",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.77734,
              -82.309436
          ],
          "Rest_Name": "Mauldin",
          "id": 684,
          "address": "108 S Main St",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.986088,
              -87.947824
          ],
          "Rest_Name": "South 27th Street",
          "id": 685,
          "address": "3232 S 27th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.938708,
              -88.047734
          ],
          "Rest_Name": "Hales Corners",
          "id": 686,
          "address": "5794 S 108th St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.319429,
              -88.977673
          ],
          "Rest_Name": "Loves Park",
          "id": 687,
          "address": "7190 E Riverside Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.60174,
              -90.466729
          ],
          "Rest_Name": "Manchester",
          "id": 688,
          "address": "13307 Manchester Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.742178,
              -86.665714
          ],
          "Rest_Name": "Huntsville University Drive",
          "id": 689,
          "address": "5900 University Dr NW Ste D2",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.260192,
              -81.13312
          ],
          "Rest_Name": "Gastonia",
          "id": 690,
          "address": "2501 E Franklin Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.048482,
              -87.904246
          ],
          "Rest_Name": "Ogden Avenue",
          "id": 691,
          "address": "600 E Ogden Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.75046,
              -86.759471
          ],
          "Rest_Name": "Clift Farms",
          "id": 692,
          "address": "8294 Highway 72 W Ste B",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.784563,
              -90.531464
          ],
          "Rest_Name": "St Charles MO",
          "id": 693,
          "address": "2014 Zumbehl Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.652342,
              -80.456753
          ],
          "Rest_Name": "Salisbury NC",
          "id": 694,
          "address": "1527 E Innes Street",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.366051,
              -89.023489
          ],
          "Rest_Name": "Rockford Machesney",
          "id": 695,
          "address": "1570 W Lane Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.785227,
              -86.945742
          ],
          "Rest_Name": "Athens AL",
          "id": 696,
          "address": "1289 US Highway 72 E",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.985403,
              -80.025325
          ],
          "Rest_Name": "High Point Main St.",
          "id": 697,
          "address": "2313 N Main St Ste 101",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.032705,
              -79.958377
          ],
          "Rest_Name": "High Point NC",
          "id": 698,
          "address": "4008 Brian Jordan Pl",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.6221,
              -79.3996
          ],
          "Rest_Name": "Danville VA",
          "id": 699,
          "address": "450 Mall Dr Ste 130",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.643302,
              -90.564922
          ],
          "Rest_Name": "Drew Station",
          "id": 700,
          "address": "1676 Clarkson Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.348791,
              -80.857763
          ],
          "Rest_Name": "Charlotte Northlake",
          "id": 701,
          "address": "9821 Northlake Centre Pkwy",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.684509,
              -86.717969
          ],
          "Rest_Name": "Town Madison",
          "id": 702,
          "address": "101 Outfield Dr Ste A",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.120082,
              -79.842742
          ],
          "Rest_Name": "Battleground North",
          "id": 703,
          "address": "3227 Battleground Ave",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.0558,
              -79.904135
          ],
          "Rest_Name": "Wendover",
          "id": 704,
          "address": "5402 Sapp Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.348681,
              -84.050008
          ],
          "Rest_Name": "Dawsonville",
          "id": 705,
          "address": "285 Marketplace Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.12429,
              -87.913063
          ],
          "Rest_Name": "Bayshore-Glendale",
          "id": 706,
          "address": "5930 N Port Washington Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.592211,
              -90.584408
          ],
          "Rest_Name": "Ellisville",
          "id": 707,
          "address": "15836 Manchester Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.035385,
              -88.104546
          ],
          "Rest_Name": "Bluemound Road",
          "id": 708,
          "address": "15375 W Bluemound Rd Ste 170",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.552493,
              -82.678134
          ],
          "Rest_Name": "Anderson SC",
          "id": 709,
          "address": "3556 Clemson Blvd Ste 300",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.068244,
              -88.048692
          ],
          "Rest_Name": "Tosa Mayfair",
          "id": 710,
          "address": "2711 N Mayfair Rd Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.42271,
              -80.676294
          ],
          "Rest_Name": "Kannapolis",
          "id": 711,
          "address": "580 Kannapolis Parkway",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.09148,
              -79.814204
          ],
          "Rest_Name": "Battleground",
          "id": 712,
          "address": "1420 Westover Ter Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.0028,
              -79.905418
          ],
          "Rest_Name": "Greensboro Gate City Blvd South",
          "id": 713,
          "address": "6021 W Gate City Blvd Ste A",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.92581,
              -79.986307
          ],
          "Rest_Name": "High Point South Main St",
          "id": 714,
          "address": "2650 S Main St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.371145,
              -80.722987
          ],
          "Rest_Name": "Concord Mills",
          "id": 715,
          "address": "8509 Concord Mills Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.085529,
              -88.067712
          ],
          "Rest_Name": "Brookfield Capitol",
          "id": 716,
          "address": "3705 N 124th St Ste 200",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.437973,
              -80.605539
          ],
          "Rest_Name": "Concord Parkway",
          "id": 717,
          "address": "1463 Concord Pkwy N",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.067443,
              -79.80518
          ],
          "Rest_Name": "UNC Greensboro",
          "id": 718,
          "address": "435 Tate St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.316284,
              -83.793518
          ],
          "Rest_Name": "Gainesville East",
          "id": 719,
          "address": "604 White Sulphur Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.303285,
              -83.858625
          ],
          "Rest_Name": "Gainesville GA",
          "id": 720,
          "address": "1134 Dawsonville Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.04916,
              -78.503662
          ],
          "Rest_Name": "Barracks Road",
          "id": 721,
          "address": "953 Emmet St N",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.035397,
              -78.500155
          ],
          "Rest_Name": "UVA The Corner",
          "id": 722,
          "address": "1511 University Ave",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.31327,
              -80.743226
          ],
          "Rest_Name": "Charlotte University",
          "id": 723,
          "address": "9335 N Tryon St Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.780679,
              -90.699027
          ],
          "Rest_Name": "OFallon",
          "id": 724,
          "address": "112 Laura K Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.290516,
              -80.765999
          ],
          "Rest_Name": "Charlotte Belgate Center",
          "id": 725,
          "address": "6925 University City Blvd Ste 440",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.225498,
              -80.846283
          ],
          "Rest_Name": "Uptown on Tryon",
          "id": 726,
          "address": "316 S Tryon St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.18161,
              -80.915386
          ],
          "Rest_Name": "City Park Tyvola",
          "id": 727,
          "address": "3320 Potomac River Pkwy Ste D",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.236698,
              -84.456328
          ],
          "Rest_Name": "Canton Marketplace",
          "id": 728,
          "address": "2026 Cumming Hwy Ste 110",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.207437,
              -80.860255
          ],
          "Rest_Name": "South End",
          "id": 729,
          "address": "2109 South Blvd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.256732,
              -85.164946
          ],
          "Rest_Name": "Rome",
          "id": 730,
          "address": "1415 Turner McCall Blvd SE",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.670273,
              -88.852476
          ],
          "Rest_Name": "Jackson TN",
          "id": 731,
          "address": "883 Vann Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.213717,
              -80.833475
          ],
          "Rest_Name": "Charlotte Midtown",
          "id": 732,
          "address": "239 S Kings Dr Ste B",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.02905,
              -78.444022
          ],
          "Rest_Name": "Pantops",
          "id": 733,
          "address": "2040 Abbey Rd Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.139242,
              -80.935595
          ],
          "Rest_Name": "Whitehall",
          "id": 734,
          "address": "2312 Whitehall Park Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.188393,
              -88.128082
          ],
          "Rest_Name": "Menomonee Falls",
          "id": 735,
          "address": "W176N9360 River Crest Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.170955,
              -80.807433
          ],
          "Rest_Name": "Cotswold",
          "id": 736,
          "address": "2921 Providence Rd Ste 100",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.488753,
              -78.386655
          ],
          "Rest_Name": "Logan Town Center Altoona",
          "id": 737,
          "address": "1600 Osgood Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.24966,
              -83.46104
          ],
          "Rest_Name": "Commerce",
          "id": 738,
          "address": "30874 US-441 S",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.152858,
              -80.832689
          ],
          "Rest_Name": "Southpark Mall Food Court",
          "id": 739,
          "address": "4400 Sharon Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.181199,
              -84.133121
          ],
          "Rest_Name": "Cumming Market Place Highway 20",
          "id": 740,
          "address": "905 Market Place Blvd Ste C",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.961768,
              -78.189205
          ],
          "Rest_Name": "Front Royal",
          "id": 741,
          "address": "145 Riverton Commons Dr Ste 1",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.185347,
              -80.760802
          ],
          "Rest_Name": "Independence Idlewild",
          "id": 742,
          "address": "4022 Connection Point Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.166423,
              -84.787322
          ],
          "Rest_Name": "Cartersville",
          "id": 743,
          "address": "405 E Main St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.161875,
              -78.168029
          ],
          "Rest_Name": "Winchester",
          "id": 744,
          "address": "2012 S Pleasant Valley Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.211225,
              -80.690015
          ],
          "Rest_Name": "Albemarle Rd",
          "id": 745,
          "address": "9030 Albemarle Rd Ste D",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.561446,
              -86.975655
          ],
          "Rest_Name": "Decatur AL",
          "id": 746,
          "address": "1109 Beltline Rd SE",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.035278,
              -80.974106
          ],
          "Rest_Name": "Fort Mill Hwy 160",
          "id": 747,
          "address": "1852 Highway 160 W Ste 107",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.082322,
              -80.877287
          ],
          "Rest_Name": "Carolina Place Mall Food Court",
          "id": 748,
          "address": "11025 Carolina Pl Pkwy Ste FC-12",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.220729,
              -78.133019
          ],
          "Rest_Name": "North Winchester",
          "id": 749,
          "address": "120 Merchant St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.723734,
              -88.995163
          ],
          "Rest_Name": "Janesville",
          "id": 750,
          "address": "3515 Milton Ave Ste 105",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.088065,
              -80.850922
          ],
          "Rest_Name": "Hwy 51 @ Carmel",
          "id": 751,
          "address": "7724 Pineville Matthews Rd Unit B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.95831,
              -81.04278
          ],
          "Rest_Name": "Rock Hill Herlong",
          "id": 752,
          "address": "330 Herlong Ave",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.109071,
              -84.552788
          ],
          "Rest_Name": "Towne Lake",
          "id": 753,
          "address": "2075 Towne Lake Pkwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.967133,
              -80.995356
          ],
          "Rest_Name": "Rock Hill Cherry Rd",
          "id": 754,
          "address": "2316 Cherry Road",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.124711,
              -80.708134
          ],
          "Rest_Name": "Matthews",
          "id": 755,
          "address": "1909 Matthews Township Pkwy",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.061239,
              -80.814391
          ],
          "Rest_Name": "Charlotte StoneCrest",
          "id": 756,
          "address": "7800 Rea Rd Ste F",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.073603,
              -79.492327
          ],
          "Rest_Name": "Burlington NC",
          "id": 757,
          "address": "649 Huffman Mill Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.09084,
              -84.27492
          ],
          "Rest_Name": "Windward",
          "id": 758,
          "address": "5250 Windward Pkwy Ste 120",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.085367,
              -84.531122
          ],
          "Rest_Name": "Woodstock",
          "id": 759,
          "address": "9480 Hwy 92",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.012021,
              -80.851522
          ],
          "Rest_Name": "Indian Land 521",
          "id": 760,
          "address": "10092 Charlotte Hwy Ste 108",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.052562,
              -80.768411
          ],
          "Rest_Name": "Waverly",
          "id": 761,
          "address": "11619 Waverly Center Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.067433,
              -84.169294
          ],
          "Rest_Name": "John's Creek",
          "id": 762,
          "address": "11720 Medlock Bridge Rd Ste 135",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.061468,
              -84.246385
          ],
          "Rest_Name": "Old Milton Parkway",
          "id": 763,
          "address": "3900 Brookside Pkwy",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.073164,
              -83.984773
          ],
          "Rest_Name": "Mall of Georgia",
          "id": 764,
          "address": "3350 Buford Dr Ste B260",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.051774,
              -84.286286
          ],
          "Rest_Name": "Alpharetta North Point",
          "id": 765,
          "address": "10910 Haynes Bridge Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.0436,
              -84.341502
          ],
          "Rest_Name": "Roswell",
          "id": 766,
          "address": "10800 Alpharetta Hwy Ste 288",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.037266,
              -84.461123
          ],
          "Rest_Name": "Sandy Plains 2",
          "id": 767,
          "address": "3606 Sandy Plains Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.05428,
              -83.993618
          ],
          "Rest_Name": "Buford",
          "id": 768,
          "address": "2925 Buford Dr Ste 540",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.035452,
              -84.573604
          ],
          "Rest_Name": "Kennesaw State",
          "id": 769,
          "address": "777 Townpark Ln NW",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.03591,
              -84.684746
          ],
          "Rest_Name": "Acworth",
          "id": 770,
          "address": "3359 Cobb Pkwy NW",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.034396,
              -84.052234
          ],
          "Rest_Name": "Suwanee",
          "id": 771,
          "address": "3186 Lawrenceville Suwanee Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.445938,
              -77.987837
          ],
          "Rest_Name": "Martinsburg",
          "id": 772,
          "address": "937 Foxcroft Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.021459,
              -84.120335
          ],
          "Rest_Name": "Duluth Sugarloaf Parkway",
          "id": 773,
          "address": "2190 Peachtree Industrial Blvd # 110",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.003846,
              -84.579238
          ],
          "Rest_Name": "Ernest Barrett Parkway",
          "id": 774,
          "address": "954 Ernest W Barrett Pkwy NW Ste 120",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.933609,
              -80.834683
          ],
          "Rest_Name": "Indian Land South",
          "id": 775,
          "address": "7680 Charlotte Hwy",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.995691,
              -84.350461
          ],
          "Rest_Name": "Roswell Road North",
          "id": 776,
          "address": "8753 Roswell Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.397641,
              -88.18726
          ],
          "Rest_Name": "West Bend",
          "id": 777,
          "address": "855 W Paradise Dr",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.988177,
              -84.272338
          ],
          "Rest_Name": "Rivermont Station",
          "id": 778,
          "address": "8483 Holcomb Bridge Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.980164,
              -84.424608
          ],
          "Rest_Name": "Marietta East Cobb",
          "id": 779,
          "address": "1281 Johnson Ferry Rd Ste 104",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.980139,
              -84.21714
          ],
          "Rest_Name": "Norcross",
          "id": 780,
          "address": "5175 Peachtree Pkwy Ste 1300",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.470701,
              -90.479684
          ],
          "Rest_Name": "Moline",
          "id": 781,
          "address": "3941 41st Avenue Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.95205,
              -84.521123
          ],
          "Rest_Name": "Marietta BC",
          "id": 782,
          "address": "54 Cobb Pkwy N",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.967151,
              -84.02244
          ],
          "Rest_Name": "Lawrenceville",
          "id": 783,
          "address": "860 Duluth Hwy Ste 320",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.955267,
              -84.131162
          ],
          "Rest_Name": "Gwinnett",
          "id": 784,
          "address": "2040 Pleasant Hill Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.958736,
              -84.055084
          ],
          "Rest_Name": "Sugarloaf Crossing",
          "id": 785,
          "address": "4885 Sugarloaf Pkwy",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.94685,
              -84.334045
          ],
          "Rest_Name": "Dunwoody Village",
          "id": 786,
          "address": "1614 Mount Vernon Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.485197,
              -77.967244
          ],
          "Rest_Name": "Culpeper",
          "id": 787,
          "address": "15335 Creativity Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.940735,
              -84.122714
          ],
          "Rest_Name": "Pleasant Hill - GA",
          "id": 788,
          "address": "1475 Pleasant Hill Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.009415,
              -80.56415
          ],
          "Rest_Name": "Monroe NC",
          "id": 789,
          "address": "2335 W Roosevelt Blvd Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.927932,
              -84.344195
          ],
          "Rest_Name": "Perimeter Mall",
          "id": 790,
          "address": "123 Perimeter Ctr W Ste 500",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.923804,
              -84.341154
          ],
          "Rest_Name": "Perimeter Mall Food Court",
          "id": 791,
          "address": "4400 Ashford Dunwoody Rd NE Ste 1385",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.916322,
              -84.379567
          ],
          "Rest_Name": "Sandy Springs",
          "id": 792,
          "address": "5920 Roswell Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.908363,
              -84.205757
          ],
          "Rest_Name": "Jimmy Carter Blvd",
          "id": 793,
          "address": "5485 Jimmy Carter Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.901473,
              -84.477394
          ],
          "Rest_Name": "Windy Hill",
          "id": 794,
          "address": "2675 Windy Hill Rd SE",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.89056,
              -84.753678
          ],
          "Rest_Name": "Hiram",
          "id": 795,
          "address": "4500 Jimmy Lee Smith Pkwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.884534,
              -84.31582
          ],
          "Rest_Name": "Chamblee",
          "id": 796,
          "address": "5001 Peachtree Blvd Ste 1010",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.900014,
              -83.961003
          ],
          "Rest_Name": "Grayson",
          "id": 797,
          "address": "1860 Grayson Hwy",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.880957,
              -84.461281
          ],
          "Rest_Name": "Cumberland",
          "id": 798,
          "address": "2973 Cobb Pkwy SE # B",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.881,
              -84.380146
          ],
          "Rest_Name": "Chastain Park",
          "id": 799,
          "address": "4600 Roswell Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.573157,
              -90.529315
          ],
          "Rest_Name": "Davenport 53rd and Elmore",
          "id": 800,
          "address": "5270 Elmore Ave Ste 3",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.947371,
              -83.408834
          ],
          "Rest_Name": "Athens Alps",
          "id": 801,
          "address": "165 Alps Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.882375,
              -84.010552
          ],
          "Rest_Name": "Snellville President Commons",
          "id": 802,
          "address": "1650 Scenic Highway S",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.865267,
              -84.469901
          ],
          "Rest_Name": "Vinings",
          "id": 803,
          "address": "2810 Paces Ferry Rd SE Ste 102",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.856229,
              -84.601193
          ],
          "Rest_Name": "Austell",
          "id": 804,
          "address": "3999 Austell Rd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.917383,
              -83.454219
          ],
          "Rest_Name": "Oconee Connector",
          "id": 805,
          "address": "1797 Oconee Connector",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.848164,
              -84.374189
          ],
          "Rest_Name": "Buckhead North",
          "id": 806,
          "address": "3424 Piedmont Rd NE",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.851625,
              -84.210044
          ],
          "Rest_Name": "Tucker",
          "id": 807,
          "address": "4347 Hugh Howell Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.846674,
              -84.361939
          ],
          "Rest_Name": "Lenox Mall Food Court",
          "id": 808,
          "address": "3393 Peachtree Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.729048,
              -77.799506
          ],
          "Rest_Name": "Warrenton",
          "id": 809,
          "address": "251 W Lee Hwy Ste 243",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.560916,
              -90.586689
          ],
          "Rest_Name": "Davenport West Kimberly",
          "id": 810,
          "address": "1020 W Kimberly Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.845894,
              -84.256033
          ],
          "Rest_Name": "Lavista Northlake",
          "id": 811,
          "address": "2078 Henderson Mill Rd NE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.829876,
              -84.491645
          ],
          "Rest_Name": "South Cobb Drive",
          "id": 812,
          "address": "5131 S Cobb Dr SE",
          "rating": 1.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.82559,
              -84.36444
          ],
          "Rest_Name": "Atlanta Lindbergh",
          "id": 813,
          "address": "2571 Sidney Marcus Blvd NE ",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.846189,
              -83.908273
          ],
          "Rest_Name": "Loganville",
          "id": 814,
          "address": "4112 Atlanta Hwy Ste 300",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.659689,
              -77.744704
          ],
          "Rest_Name": "Hagerstown",
          "id": 815,
          "address": "18003 Garland Groh Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.814719,
              -84.310156
          ],
          "Rest_Name": "Toco Hills",
          "id": 816,
          "address": "2963 N Druid Hills Rd NE",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.154151,
              -86.84121
          ],
          "Rest_Name": "Cullman",
          "id": 817,
          "address": "1821 Cherokee Ave SW",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.810169,
              -84.41306
          ],
          "Rest_Name": "Howell Mill Rd",
          "id": 818,
          "address": "2020 Howell Mill Rd NW",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.80134,
              -84.392548
          ],
          "Rest_Name": "South Buckhead",
          "id": 819,
          "address": "1745 Peachtree St NE Ste H",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.135705,
              -77.695844
          ],
          "Rest_Name": "Purcellville",
          "id": 820,
          "address": "1200 Wolf Rock Dr Ste 130",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.791034,
              -84.407041
          ],
          "Rest_Name": "West Midtown Atlanta",
          "id": 821,
          "address": "540 17th St NW Ste 420",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.788468,
              -84.619337
          ],
          "Rest_Name": "Thornton Road",
          "id": 822,
          "address": "642 Thornton Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.788626,
              -84.326656
          ],
          "Rest_Name": "Emory Village",
          "id": 823,
          "address": "1401 Oxford Rd NE",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.783862,
              -84.383951
          ],
          "Rest_Name": "1010 Midtown",
          "id": 824,
          "address": "1080 Peachtree St NE Ste 13C",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.61369,
              -77.6916
          ],
          "Rest_Name": "Hagerstown Dual Highway",
          "id": 825,
          "address": "1739 Dual Highway",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.773906,
              -84.363588
          ],
          "Rest_Name": "Ponce de Leon",
          "id": 826,
          "address": "718 Ponce De Leon Ave NE",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.7753,
              -84.298718
          ],
          "Rest_Name": "Downtown Decatur",
          "id": 827,
          "address": "198 W Ponce De Leon Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.808584,
              -77.903768
          ],
          "Rest_Name": "North Atherton PA",
          "id": 828,
          "address": "1850 N Atherton St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.047195,
              -89.325087
          ],
          "Rest_Name": "Monona",
          "id": 829,
          "address": "6501 Monona Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.727961,
              -84.737702
          ],
          "Rest_Name": "Douglasville",
          "id": 830,
          "address": "9380 the Landing Dr",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.22939,
              -79.94343
          ],
          "Rest_Name": "Ancaster",
          "id": 831,
          "address": "110 Martindale Crescent",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.911412,
              -79.059583
          ],
          "Rest_Name": "Chapel Hill",
          "id": 832,
          "address": "301 W Franklin St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.715336,
              -84.214988
          ],
          "Rest_Name": "Wesley Chapel Road",
          "id": 833,
          "address": "2474 Wesley Chapel Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.796718,
              -77.857276
          ],
          "Rest_Name": "Penn State Univ",
          "id": 834,
          "address": "116 Hiester St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.936111,
              -79.02404
          ],
          "Rest_Name": "Chapel Hill Eastgate",
          "id": 835,
          "address": "1490 Fordham Blvd Ste 110",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.940763,
              -77.628605
          ],
          "Rest_Name": "Chambersburg",
          "id": 836,
          "address": "975 Norland Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.791386,
              -77.606985
          ],
          "Rest_Name": "Virginia Gateway",
          "id": 837,
          "address": "5025 Wellington Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.70088,
              -84.58437
          ],
          "Rest_Name": "Sandtown",
          "id": 838,
          "address": "5823 CAMPBELLTON ROAD",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.009121,
              -78.944674
          ],
          "Rest_Name": "Duke Medical",
          "id": 839,
          "address": "2608 Erwin Rd Ste 144",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.953071,
              -78.992277
          ],
          "Rest_Name": "Gateway Terrace",
          "id": 840,
          "address": "3219 Watkins Rd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.04078,
              -78.898845
          ],
          "Rest_Name": "North Durham",
          "id": 841,
          "address": "3800 N Roxboro St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.08382,
              -77.56261
          ],
          "Rest_Name": "Leesburg Compass Creek",
          "id": 842,
          "address": "510 Compass Point Plaza ",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.13052,
              -89.305693
          ],
          "Rest_Name": "East Towne",
          "id": 843,
          "address": "4628 E Washington Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.075013,
              -89.396766
          ],
          "Rest_Name": "U of Wisconsin",
          "id": 844,
          "address": "658 State St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.113193,
              -77.536941
          ],
          "Rest_Name": "Leesburg VA",
          "id": 845,
          "address": "1001 Edwards Ferry Rd NE",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.657308,
              -84.499326
          ],
          "Rest_Name": "Camp Creek",
          "id": 846,
          "address": "3330 Camp Creek Pkwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.732744,
              -77.54755
          ],
          "Rest_Name": "Bristow",
          "id": 847,
          "address": "10303 Bristow Center Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.075823,
              -89.453834
          ],
          "Rest_Name": "Shorewood Hills",
          "id": 848,
          "address": "4000 University Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.64219,
              -84.429247
          ],
          "Rest_Name": "ATL Concourse D",
          "id": 849,
          "address": "11700 Spine Rd. Suite D28 Gate D30",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.65119,
              -84.00914
          ],
          "Rest_Name": "Conyers",
          "id": 850,
          "address": "1467 Highway 138 SE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.90347,
              -78.941542
          ],
          "Rest_Name": "Durham Southpoint",
          "id": 851,
          "address": "6910 Fayetteville Rd Ste 187",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.797009,
              -77.517141
          ],
          "Rest_Name": "Manassas",
          "id": 852,
          "address": "7311 Sudley Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.061432,
              -89.504906
          ],
          "Rest_Name": "West Towne",
          "id": 853,
          "address": "7066 Sligo Dr",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.010768,
              -77.490978
          ],
          "Rest_Name": "Shoppes at Ryan Park",
          "id": 854,
          "address": "43660 Yukon Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.067771,
              -77.485246
          ],
          "Rest_Name": "Shops at Belmont",
          "id": 855,
          "address": "19825 Belmont Chase Dr Ste 130",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.076748,
              -78.461659
          ],
          "Rest_Name": "Olean",
          "id": 856,
          "address": "2727 W State St",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.075327,
              -89.528676
          ],
          "Rest_Name": "Middleton",
          "id": 857,
          "address": "8422 Old Sauk Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.940629,
              -78.854043
          ],
          "Rest_Name": "Durham Ellis",
          "id": 858,
          "address": "1034 Yunus Rd Ste 110",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.29152,
              -77.56476
          ],
          "Rest_Name": "West Fredericksburg",
          "id": 859,
          "address": "5621 Plank Road",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.416472,
              -77.437766
          ],
          "Rest_Name": "Golden Mile",
          "id": 860,
          "address": "997 W Patrick St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.006281,
              -77.437572
          ],
          "Rest_Name": "Dulles 28",
          "id": 861,
          "address": "22000 Dulles Retail Plz Ste 156",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.897516,
              -77.443503
          ],
          "Rest_Name": "Chantilly",
          "id": 862,
          "address": "14416 Chantilly Crossing Ln",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.876205,
              -77.442463
          ],
          "Rest_Name": "Commonwealth Centre",
          "id": 863,
          "address": "14383 Newbrook Dr Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.603346,
              -83.858775
          ],
          "Rest_Name": "Covington GA",
          "id": 864,
          "address": "3147 US-278 NW",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.374362,
              -77.412111
          ],
          "Rest_Name": "Westview",
          "id": 865,
          "address": "5223 Buckeystown Pike",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.749204,
              -77.4489
          ],
          "Rest_Name": "Liberia Ave",
          "id": 866,
          "address": "9511 Liberia Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.828222,
              -77.438218
          ],
          "Rest_Name": "Centre Ridge",
          "id": 867,
          "address": "6317 Multiplex Dr Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.034621,
              -77.407092
          ],
          "Rest_Name": "Sterling",
          "id": 868,
          "address": "21031 Tripleseven Rd Ste 190",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.562096,
              -84.583331
          ],
          "Rest_Name": "Fairburn",
          "id": 869,
          "address": "8045 Senoia Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.44538,
              -77.397286
          ],
          "Rest_Name": "Frederick",
          "id": 870,
          "address": "7820 Wormans Mill Rd Ste Z",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.56158,
              -84.41311
          ],
          "Rest_Name": "Riverdale Atlanta",
          "id": 871,
          "address": "7086 GA 85",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.562708,
              -84.324649
          ],
          "Rest_Name": "Morrow Mt. Zion",
          "id": 872,
          "address": "2011 Mount Zion Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.296485,
              -77.513437
          ],
          "Rest_Name": "Fredericksburg",
          "id": 873,
          "address": "3051 Plank Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.876531,
              -78.850474
          ],
          "Rest_Name": "RTP Page Rd & Miami",
          "id": 874,
          "address": "5431 Page Rd Ste 150",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.907889,
              -77.40874
          ],
          "Rest_Name": "Franklin Farm",
          "id": 875,
          "address": "13354 Franklin Farm Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.963637,
              -77.399038
          ],
          "Rest_Name": "Herndon",
          "id": 876,
          "address": "1144 Elden St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.343099,
              -77.494231
          ],
          "Rest_Name": "Carter's Corner",
          "id": 877,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.565767,
              -85.076599
          ],
          "Rest_Name": "Carrollton",
          "id": 878,
          "address": "814 S Park St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.857243,
              -77.394431
          ],
          "Rest_Name": "Fair Lakes Center",
          "id": 879,
          "address": "13042 Fair Lakes Shopping Ctr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.231852,
              -77.502378
          ],
          "Rest_Name": "Massaponax",
          "id": 880,
          "address": "5420 Southpoint Plaza Way",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.015539,
              -77.374525
          ],
          "Rest_Name": "Sugarland Crossing",
          "id": 881,
          "address": "47010 Community Plz Ste 100",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.299265,
              -77.483694
          ],
          "Rest_Name": "Cowans Crossing",
          "id": 882,
          "address": "1601 Emancipation Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.323323,
              -77.35226
          ],
          "Rest_Name": "Urbana",
          "id": 883,
          "address": "3278 Bennett Creek Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.954884,
              -77.366303
          ],
          "Rest_Name": "Reston",
          "id": 884,
          "address": "12152 Sunset Hills Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.959348,
              -77.356704
          ],
          "Rest_Name": "Reston Town Center",
          "id": 885,
          "address": "11913 Freedom Dr Ste 35",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.42476,
              -77.43089
          ],
          "Rest_Name": "Embrey Mill Town Center",
          "id": 886,
          "address": "810 Wonder Rd ",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.6083,
              -85.7873
          ],
          "Rest_Name": "Oxford",
          "id": 887,
          "address": "30 Commons Way",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.857663,
              -77.357521
          ],
          "Rest_Name": "Fairlakes",
          "id": 888,
          "address": "11939 Grand Commons Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.469389,
              -77.411947
          ],
          "Rest_Name": "Stafford",
          "id": 889,
          "address": "100 Prosperity Ln Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.504292,
              -84.238686
          ],
          "Rest_Name": "Eagles Landing",
          "id": 890,
          "address": "1580 Hudson Bridge Rd Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.938568,
              -77.330351
          ],
          "Rest_Name": "South Lakes Village",
          "id": 891,
          "address": "11160 S Lakes Dr # G2",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.807005,
              -78.81519
          ],
          "Rest_Name": "Park West Village",
          "id": 892,
          "address": "1516 Village Market Pl",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.852589,
              -77.327248
          ],
          "Rest_Name": "Kamp Washington",
          "id": 893,
          "address": "11062 Main St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.65373,
              -77.615732
          ],
          "Rest_Name": "Short Pump",
          "id": 894,
          "address": "11728 W Broad St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.799654,
              -77.3201
          ],
          "Rest_Name": "Burke Centre",
          "id": 895,
          "address": "5747 Burke Centre Pkwy",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.673386,
              -77.335048
          ],
          "Rest_Name": "The Glen",
          "id": 896,
          "address": "4269 Merchant Plz",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.50536,
              -77.66366
          ],
          "Rest_Name": "Ivymont",
          "id": 897,
          "address": "14171 Midlothian Tpke",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.685202,
              -77.588592
          ],
          "Rest_Name": "Nuckols Place",
          "id": 898,
          "address": "5300 Wyndham Forest Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.472488,
              -84.443367
          ],
          "Rest_Name": "FAYETTEVILLE",
          "id": 899,
          "address": "1135 Highway 85 N Ste E",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.40047,
              -77.70467
          ],
          "Rest_Name": "Cosby Village Square",
          "id": 900,
          "address": "15668 Hull Street Road",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.829909,
              -77.307703
          ],
          "Rest_Name": "George Mason",
          "id": 901,
          "address": "4477 Aquia Creek Lane",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.881167,
              -77.30187
          ],
          "Rest_Name": "Oakton VA",
          "id": 902,
          "address": "2946 Chain Bridge Rd Ste I",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.746414,
              -78.82956
          ],
          "Rest_Name": "Apex",
          "id": 903,
          "address": "1081 Pine Plaza Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.182602,
              -77.263864
          ],
          "Rest_Name": "Germantown MD",
          "id": 904,
          "address": "19881 Century Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.860384,
              -78.707695
          ],
          "Rest_Name": "Pleasant Valley",
          "id": 905,
          "address": "6602 Glenwood Ave Ste 3",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.645852,
              -77.579687
          ],
          "Rest_Name": "Summit",
          "id": 906,
          "address": "10501 W Broad St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.765223,
              -78.783861
          ],
          "Rest_Name": "Cary Kildaire",
          "id": 907,
          "address": "100 Wrenn Dr Ste 103",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.203586,
              -77.247937
          ],
          "Rest_Name": "Germantown East",
          "id": 908,
          "address": "20940 Frederick Rd Ste A",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.901908,
              -78.654884
          ],
          "Rest_Name": "Strickland Six Forks",
          "id": 909,
          "address": "9504 Strickland Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.842967,
              -77.272162
          ],
          "Rest_Name": "Pickett",
          "id": 910,
          "address": "9506 Main St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.903597,
              -77.262059
          ],
          "Rest_Name": "Vienna",
          "id": 911,
          "address": "213 Maple Ave E",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.164904,
              -79.42146
          ],
          "Rest_Name": "Southern Pines",
          "id": 912,
          "address": "10771 US 15-501 HWY",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.65275,
              -77.28605
          ],
          "Rest_Name": "Potomac Mills",
          "id": 913,
          "address": "2457 Prince William Pkwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.124667,
              -77.236987
          ],
          "Rest_Name": "Kentlands",
          "id": 914,
          "address": "96 Main St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.506143,
              -77.6071
          ],
          "Rest_Name": "Midlothian TPK",
          "id": 915,
          "address": "11440 Midlothian Tpke Ste F",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.603027,
              -77.56671
          ],
          "Rest_Name": "Regency Square Mall",
          "id": 916,
          "address": "8675 Quioccasin Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.415539,
              -77.64121
          ],
          "Rest_Name": "Swift Creek",
          "id": 917,
          "address": "13300 Rittenhouse Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.924963,
              -77.239781
          ],
          "Rest_Name": "Tysons Rt. 7",
          "id": 918,
          "address": "8461 Leesburg Pike Ste A",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.632058,
              -77.274651
          ],
          "Rest_Name": "Woodbridge VA",
          "id": 919,
          "address": "1926 Daniel Stuart Sq",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.789439,
              -78.815
          ],
          "Rest_Name": "Hamburg Buffalo",
          "id": 920,
          "address": "4405 Milestrip Rd Ste 5",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.422916,
              -84.187152
          ],
          "Rest_Name": "McDonough",
          "id": 921,
          "address": "1442 Highway 20 W",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.760404,
              -78.740893
          ],
          "Rest_Name": "Cary Crossroads",
          "id": 922,
          "address": "204 Crossroads Blvd Ste 212",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.148525,
              -77.210253
          ],
          "Rest_Name": "Gaithersburg Square",
          "id": 923,
          "address": "564 N Frederick Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.87209,
              -77.228353
          ],
          "Rest_Name": "Merrifield Town Ctr",
          "id": 924,
          "address": "8191 Strawberry Ln",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.288766,
              -77.202452
          ],
          "Rest_Name": "Damascus",
          "id": 925,
          "address": "9815 Main St Ste 104",
          "rating": 4.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.916504,
              -77.221193
          ],
          "Rest_Name": "Tysons Corner Relo",
          "id": 926,
          "address": "7923 L Tysons Corner Center",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.780937,
              -77.234999
          ],
          "Rest_Name": "West Springfield Center",
          "id": 927,
          "address": "6230 Rolling Rd Ste X",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.966301,
              -78.543495
          ],
          "Rest_Name": "Wake Forest",
          "id": 928,
          "address": "11700 Retail Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.016651,
              -77.208966
          ],
          "Rest_Name": "Potomac Village",
          "id": 929,
          "address": "10116 River Rd Unit B",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.116712,
              -77.198399
          ],
          "Rest_Name": "Washingtonian",
          "id": 930,
          "address": "205 Boardwalk Pl",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.75776,
              -77.470318
          ],
          "Rest_Name": "Ashland VA ",
          "id": 931,
          "address": "703A England St",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.639836,
              -78.835931
          ],
          "Rest_Name": "Holly Springs 55",
          "id": 932,
          "address": "601 Village Walk Dr",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.359919,
              -89.892458
          ],
          "Rest_Name": "Millington",
          "id": 933,
          "address": "8599 US Highway 51 N",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.866116,
              -78.620049
          ],
          "Rest_Name": "Falls of Neuse",
          "id": 934,
          "address": "6102 Falls of Neuse Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.840181,
              -78.643501
          ],
          "Rest_Name": "Raleigh North Hills",
          "id": 935,
          "address": "4421 Six Forks Rd Ste 120",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.637803,
              -77.511687
          ],
          "Rest_Name": "Staples Mill",
          "id": 936,
          "address": "8955 Staples Mill Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.603428,
              -77.524983
          ],
          "Rest_Name": "Reynolds Crossing",
          "id": 937,
          "address": "7000 Forest Ave Ste 900",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.096508,
              -77.192648
          ],
          "Rest_Name": "Falls Grove",
          "id": 938,
          "address": "14925 Shady Grove Rd Unit L",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.396355,
              -84.591743
          ],
          "Rest_Name": "Peachtree City",
          "id": 939,
          "address": "101 City Cir",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.39831,
              -84.739745
          ],
          "Rest_Name": "Newnan",
          "id": 940,
          "address": "1200 Highway 34 E # B",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.072489,
              -81.157815
          ],
          "Rest_Name": "Harbison Blvd",
          "id": 941,
          "address": "276 Harbison Blvd Ste B",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.701711,
              -77.220685
          ],
          "Rest_Name": "Lorton",
          "id": 942,
          "address": "9447 Lorton Market St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.740262,
              -77.212982
          ],
          "Rest_Name": "Newington VA",
          "id": 943,
          "address": "8066 Rolling Rd # 9",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.787647,
              -78.66684
          ],
          "Rest_Name": "NC State",
          "id": 944,
          "address": "2316 Hillsborough St Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.516894,
              -79.687164
          ],
          "Rest_Name": "Winston Churchill",
          "id": 945,
          "address": "2530 Winston Park Drive Unit B1",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.868126,
              -78.583484
          ],
          "Rest_Name": "Triangle Town Center",
          "id": 946,
          "address": "6081 Capital Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.36455,
              -77.160105
          ],
          "Rest_Name": "Mount Airy",
          "id": 947,
          "address": "439 E Ridgeville Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.933178,
              -77.176182
          ],
          "Rest_Name": "McLean",
          "id": 948,
          "address": "6707 Old Dominion Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.779124,
              -77.189774
          ],
          "Rest_Name": "Springfield",
          "id": 949,
          "address": "7108 Old Keene Mill Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.584576,
              -77.496572
          ],
          "Rest_Name": "Willow Lawn",
          "id": 950,
          "address": "4930 W Broad St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.885013,
              -77.174616
          ],
          "Rest_Name": "Broaddale Center",
          "id": 951,
          "address": "358 W Broad St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.501405,
              -77.528411
          ],
          "Rest_Name": "Chippenham",
          "id": 952,
          "address": "7106 Midlothian Tpke Ste A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.137164,
              -80.955709
          ],
          "Rest_Name": "Killian Rd",
          "id": 953,
          "address": "451 Killian Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.666725,
              -77.458474
          ],
          "Rest_Name": "North Richmond",
          "id": 954,
          "address": "1070 Virginia Center Pkwy Ste 101",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.774467,
              -77.173375
          ],
          "Rest_Name": "Springfield Town Center",
          "id": 955,
          "address": "6500 Springfield Mall Ste 6741",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.024896,
              -77.147045
          ],
          "Rest_Name": "Montgomery Mall",
          "id": 956,
          "address": "7101 Democracy Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.557134,
              -77.486249
          ],
          "Rest_Name": "Carytown Place",
          "id": 957,
          "address": "10 N Nansemond St Ste D",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.581214,
              -79.73633
          ],
          "Rest_Name": "Britannia",
          "id": 958,
          "address": "6133 ERIN MILLS PKWY",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.075289,
              -77.137765
          ],
          "Rest_Name": "Wintergreen",
          "id": 959,
          "address": "865 Rockville Pike Ste B",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.944878,
              -78.868713
          ],
          "Rest_Name": "Delaware Commons",
          "id": 960,
          "address": "2236 Delaware Ave",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.870591,
              -77.153138
          ],
          "Rest_Name": "Arlington Boulevard",
          "id": 961,
          "address": "6299 Seven Corners Ctr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.202155,
              -89.792946
          ],
          "Rest_Name": "Wolfchase - Germantown Pkwy",
          "id": 962,
          "address": "2825 N Germantown Pkwy Ste 105",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.994726,
              -81.226326
          ],
          "Rest_Name": "Lexington SC",
          "id": 963,
          "address": "5594 Sunset Blvd Ste E",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.02576,
              -77.126984
          ],
          "Rest_Name": "Old Georgetown Square",
          "id": 964,
          "address": "10400 Old Georgetown Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.818259,
              -77.141866
          ],
          "Rest_Name": "Landmark",
          "id": 965,
          "address": "6242 Little River Tpke Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.651229,
              -86.825584
          ],
          "Rest_Name": "Gardendale",
          "id": 966,
          "address": "616 Gayle St Ste 112",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.190025,
              -77.192505
          ],
          "Rest_Name": "Carlisle",
          "id": 967,
          "address": "40 Noble Blvd Ste 32",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.049784,
              -77.115596
          ],
          "Rest_Name": "Pike & Rose",
          "id": 968,
          "address": "11802 Rockville Pike Ste E",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.722103,
              -78.652367
          ],
          "Rest_Name": "South Raleigh Hwy 401",
          "id": 969,
          "address": "4203 Fayetteville Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.769915,
              -77.139712
          ],
          "Rest_Name": "Kingstowne",
          "id": 970,
          "address": "5955 Kingstowne Towne Ctr Ste 160",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.592746,
              -78.774461
          ],
          "Rest_Name": "Fuquay Varina",
          "id": 971,
          "address": "1385 N Main St Ste 120",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.98015,
              -78.86915
          ],
          "Rest_Name": "Tonawanda",
          "id": 972,
          "address": "1759 Sheridan Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.5956,
              -86.647453
          ],
          "Rest_Name": "Trussville",
          "id": 973,
          "address": "3220 Morrow Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.898363,
              -77.11876
          ],
          "Rest_Name": "Lee Heights Shops",
          "id": 974,
          "address": "4520 Cherry Hill Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.847884,
              -77.12099
          ],
          "Rest_Name": "Bailey's Crossroads",
          "id": 975,
          "address": "3556B S Jefferson St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.549415,
              -77.449898
          ],
          "Rest_Name": "VCU",
          "id": 976,
          "address": "810 West Grace Street",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.907543,
              -78.774256
          ],
          "Rest_Name": "Cheektowaga",
          "id": 977,
          "address": "1717 Walden Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.879866,
              -77.112222
          ],
          "Rest_Name": "Ballston",
          "id": 978,
          "address": "4300 Wilson Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.117946,
              -80.87743
          ],
          "Rest_Name": "Two Notch",
          "id": 979,
          "address": "10136 Two Notch Rd Ste 107B",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.376725,
              -77.511039
          ],
          "Rest_Name": "Chesterfield Courthouse",
          "id": 980,
          "address": "9873 Iron Bridge Rd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.985654,
              -77.096138
          ],
          "Rest_Name": "Bethesda",
          "id": 981,
          "address": "7600 Old Georgetown Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.005282,
              -81.118209
          ],
          "Rest_Name": "West Columbia",
          "id": 982,
          "address": "2871 Sunset Blvd Ste B",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.813032,
              -77.110677
          ],
          "Rest_Name": "Foxchase",
          "id": 983,
          "address": "4531 Duke St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.096925,
              -78.973837
          ],
          "Rest_Name": "Niagara Falls",
          "id": 984,
          "address": "1785 Military Rd Ste 300",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.524551,
              -77.438942
          ],
          "Rest_Name": "Manchester Hull Street",
          "id": 985,
          "address": "505 Hull St",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.962249,
              -77.088636
          ],
          "Rest_Name": "Chevy Chase",
          "id": 986,
          "address": "4471 Willard Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.076738,
              -77.080042
          ],
          "Rest_Name": "Aspen Hill",
          "id": 987,
          "address": "13501 Connecticut Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.144495,
              -89.796228
          ],
          "Rest_Name": "Cordova",
          "id": 988,
          "address": "695 N Germantown Pkwy Ste 101",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.887459,
              -77.094345
          ],
          "Rest_Name": "Clarendon",
          "id": 989,
          "address": "3017 Clarendon Blvd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.861003,
              -77.092176
          ],
          "Rest_Name": "Pike 3400",
          "id": 990,
          "address": "1002 S Glebe Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.02792,
              -77.076746
          ],
          "Rest_Name": "Kensington",
          "id": 991,
          "address": "10526 Connecticut Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.153201,
              -77.066147
          ],
          "Rest_Name": "Olney",
          "id": 992,
          "address": "3333 Olney Sandy Springs Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.59348,
              -79.642438
          ],
          "Rest_Name": "Square One Food Court",
          "id": 993,
          "address": "100 City Centre Dr Suite 3",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.945028,
              -77.077721
          ],
          "Rest_Name": "Tenleytown",
          "id": 994,
          "address": "4301 Wisconsin Ave NW Unit B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.046465,
              -89.698565
          ],
          "Rest_Name": "Collierville",
          "id": 995,
          "address": "1090 W Poplar Ave Ste 102",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.920921,
              -77.072149
          ],
          "Rest_Name": "Glover Park DC",
          "id": 996,
          "address": "2338 Wisconsin Ave NW",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.491537,
              -90.720086
          ],
          "Rest_Name": "Dubuque",
          "id": 997,
          "address": "3450 Dodge St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.89534,
              -77.07053
          ],
          "Rest_Name": "Rosslyn",
          "id": 998,
          "address": "1735 N Lynn St Lbby 15",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.99972,
              -78.819438
          ],
          "Rest_Name": "Amherst Niagara Falls Blvd",
          "id": 999,
          "address": "1643 Niagara Falls Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.77063,
              -77.082208
          ],
          "Rest_Name": "Beacon Mall",
          "id": 1000,
          "address": "6770 Richmond Hwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.035843,
              -77.055322
          ],
          "Rest_Name": "Wheaton Mall",
          "id": 1001,
          "address": "11160 Viers Mill Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.905302,
              -77.06455
          ],
          "Rest_Name": "Georgetown #2",
          "id": 1002,
          "address": "3255 M St NW",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.998966,
              -81.042841
          ],
          "Rest_Name": "Vista",
          "id": 1003,
          "address": "619 Gervais St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.863442,
              -77.060614
          ],
          "Rest_Name": "Pentagon City",
          "id": 1004,
          "address": "1100 S Hayes St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.923709,
              -77.051902
          ],
          "Rest_Name": "Woodley Park",
          "id": 1005,
          "address": "2600 Connecticut Ave NW Ste 200",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.697311,
              -78.584972
          ],
          "Rest_Name": "Garner White Oak",
          "id": 1006,
          "address": "68 Eagle Wing Way",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.900173,
              -77.04574
          ],
          "Rest_Name": "GW",
          "id": 1007,
          "address": "2000 Pennsylvania Ave NW Lbby 15",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.83576,
              -77.051201
          ],
          "Rest_Name": "Potomac Yard",
          "id": 1008,
          "address": "3425 Richmond Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.854013,
              -77.049159
          ],
          "Rest_Name": "Crystal City",
          "id": 1009,
          "address": "2231 Crystal Dr Lbby 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.605229,
              -77.354784
          ],
          "Rest_Name": "Mechanicsville",
          "id": 1010,
          "address": "7314 Bell Creek Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.907536,
              -77.042711
          ],
          "Rest_Name": "Dupont South",
          "id": 1011,
          "address": "1300 Connecticut Ave NW Ste 103",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.018337,
              -80.965603
          ],
          "Rest_Name": "Columbia Trenholm",
          "id": 1012,
          "address": "4840 Forest Dr Ste 16",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.272316,
              -84.290189
          ],
          "Rest_Name": "Griffin",
          "id": 1013,
          "address": "1440 N Expressway",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.963977,
              -78.740571
          ],
          "Rest_Name": "Williamsville",
          "id": 1014,
          "address": "5712 Main St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.03712,
              -78.82392
          ],
          "Rest_Name": "North Amherst",
          "id": 1015,
          "address": "3030 NIAGARA BLVD",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.805109,
              -77.045909
          ],
          "Rest_Name": "Old Town Alexandria",
          "id": 1016,
          "address": "601 King St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.929686,
              -77.032503
          ],
          "Rest_Name": "Columbia Heights DC",
          "id": 1017,
          "address": "3113 14th St NW",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.996514,
              -77.025961
          ],
          "Rest_Name": "Silver Spring",
          "id": 1018,
          "address": "907 Ellsworth Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.903098,
              -77.033677
          ],
          "Rest_Name": "McPherson Square",
          "id": 1019,
          "address": "1010 Vermont Ave NW",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.910014,
              -77.032171
          ],
          "Rest_Name": "14th Street",
          "id": 1020,
          "address": "1508 14th St NW Ste 1",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.637412,
              -79.622315
          ],
          "Rest_Name": "Dixie and Eglinton",
          "id": 1021,
          "address": "5089 Dixie Road Building B Unit B1",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.89516,
              -77.026491
          ],
          "Rest_Name": "1001 Penn Ave NW",
          "id": 1022,
          "address": "1001 Pennsylvania Ave NW",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.921084,
              -77.021911
          ],
          "Rest_Name": "Howard University",
          "id": 1023,
          "address": "2301 Georgia Ave NW Unit A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.020954,
              -77.011951
          ],
          "Rest_Name": "Four Corners",
          "id": 1024,
          "address": "10135 Colesville Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.898906,
              -77.022229
          ],
          "Rest_Name": "Chinatown",
          "id": 1025,
          "address": "710 7th St NW",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.903056,
              -77.018838
          ],
          "Rest_Name": "CityVista",
          "id": 1026,
          "address": "1045 5th St NW",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.148979,
              -89.902893
          ],
          "Rest_Name": "Summer Avenue",
          "id": 1027,
          "address": "4726 Summer Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.613328,
              -79.558702
          ],
          "Rest_Name": "Sherway Gardens FC",
          "id": 1028,
          "address": "25 The West Mall Unit F012",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.897689,
              -77.006884
          ],
          "Rest_Name": "Union Station",
          "id": 1029,
          "address": "40 Massachusetts Ave NE",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.897731,
              -77.005632
          ],
          "Rest_Name": "Union Station Food Court",
          "id": 1030,
          "address": "50 Massachusetts Ave NE",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.784547,
              -77.016889
          ],
          "Rest_Name": "National Harbor",
          "id": 1031,
          "address": "158 National Plz",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.87547,
              -77.006141
          ],
          "Rest_Name": "Ballpark Square",
          "id": 1032,
          "address": "1255 1st Street SE",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.102096,
              -89.865546
          ],
          "Rest_Name": "Ridgeway Trace",
          "id": 1033,
          "address": "5865 Poplar Ave Ste 104",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.983156,
              -80.967654
          ],
          "Rest_Name": "Garners Ferry Rd.",
          "id": 1034,
          "address": "6070 Garners Ferry Rd Ste F",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.932191,
              -76.996401
          ],
          "Rest_Name": "Catholic",
          "id": 1035,
          "address": "625 Monroe St NE Ste A8",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.354564,
              -77.410938
          ],
          "Rest_Name": "Chester",
          "id": 1036,
          "address": "2423 W Hundred Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.920899,
              -76.993967
          ],
          "Rest_Name": "Rhode Island Row",
          "id": 1037,
          "address": "2300 Washington Place NE",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.301939,
              -88.708356
          ],
          "Rest_Name": "Tupelo",
          "id": 1038,
          "address": "897 Sawmill Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.82923,
              -76.998084
          ],
          "Rest_Name": "Hanover",
          "id": 1039,
          "address": "32 Wilson Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.883258,
              -76.995243
          ],
          "Rest_Name": "Barracks Row",
          "id": 1040,
          "address": "413 8TH STREET SE",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.985652,
              -78.69753
          ],
          "Rest_Name": "Transit Road",
          "id": 1041,
          "address": "8020 Transit Rd Unit 23",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.984886,
              -76.981586
          ],
          "Rest_Name": "Langley Park",
          "id": 1042,
          "address": "1461 University Blvd E",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.562066,
              -76.971595
          ],
          "Rest_Name": "Westminster II",
          "id": 1043,
          "address": "631 Baltimore Blvd Unit 102",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.021865,
              -76.976696
          ],
          "Rest_Name": "Hillandale Center",
          "id": 1044,
          "address": "10161 New Hampshire Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.804744,
              -76.990571
          ],
          "Rest_Name": "Oxon Hill",
          "id": 1045,
          "address": "6201 Livingston Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.84647,
              -76.980385
          ],
          "Rest_Name": "Shops at Park Village",
          "id": 1046,
          "address": "1571 Alabama Ave SE",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.350319,
              -77.054796
          ],
          "Rest_Name": "King George",
          "id": 1047,
          "address": "16449 Consumer Row Bldg 9",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.055511,
              -76.954912
          ],
          "Rest_Name": "White Oak",
          "id": 1048,
          "address": "12060 Cherry Hill Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.509777,
              -86.802721
          ],
          "Rest_Name": "UAB Birmingham",
          "id": 1049,
          "address": "300 20th St S",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.968586,
              -76.957622
          ],
          "Rest_Name": "Mall at Prince Georges",
          "id": 1050,
          "address": "3500 E West Hwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.244322,
              -88.348683
          ],
          "Rest_Name": "East Appleton",
          "id": 1051,
          "address": "W3198 County Road Kk Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.081924,
              -76.946068
          ],
          "Rest_Name": "Briggs Chaney",
          "id": 1052,
          "address": "13801 Outlet Dr",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.869485,
              -76.959413
          ],
          "Rest_Name": "Penn Branch",
          "id": 1053,
          "address": "3240 Pennsylvania Ave SE",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.920086,
              -76.952888
          ],
          "Rest_Name": "Dakota Crossing",
          "id": 1054,
          "address": "2430 Market St NE",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.398562,
              -76.933013
          ],
          "Rest_Name": "Eldersburg",
          "id": 1055,
          "address": "6400F Ridge Rd Ste 1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.246065,
              -77.014393
          ],
          "Rest_Name": "Mechanicsburg",
          "id": 1056,
          "address": "6455 Carlisle Pike Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.980485,
              -76.938948
          ],
          "Rest_Name": "College Park",
          "id": 1057,
          "address": "7332 Baltimore Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.82391,
              -90.679382
          ],
          "Rest_Name": "Jonesboro II",
          "id": 1058,
          "address": "1737 S Caraway Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.955757,
              -76.939945
          ],
          "Rest_Name": "Hyattsville",
          "id": 1059,
          "address": "5501 Baltimore Ave Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.839694,
              -76.948578
          ],
          "Rest_Name": "Iverson Mall",
          "id": 1060,
          "address": "3701 Branch Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.547027,
              -76.984461
          ],
          "Rest_Name": "La Plata",
          "id": 1061,
          "address": "105 Drury Dr Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.02264,
              -76.925743
          ],
          "Rest_Name": "North College Park",
          "id": 1062,
          "address": "10250 Baltimore Ave Ste G",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.134751,
              -89.993488
          ],
          "Rest_Name": "Memphis Midtown",
          "id": 1063,
          "address": "2046 Union Ave Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.254223,
              -77.390361
          ],
          "Rest_Name": "Colonial Heights",
          "id": 1064,
          "address": "1901 Southpark Blvd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.149709,
              -76.909905
          ],
          "Rest_Name": "Maple Lawn",
          "id": 1065,
          "address": "11811 W Market Pl Ste 3",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.998603,
              -76.912146
          ],
          "Rest_Name": "Berwyn Heights",
          "id": 1066,
          "address": "5506 Cherrywood Ln Ste G",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.939433,
              -76.90732
          ],
          "Rest_Name": "Landover Hills",
          "id": 1067,
          "address": "6320 Annapolis Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.823428,
              -76.918471
          ],
          "Rest_Name": "Camp Springs",
          "id": 1068,
          "address": "5204 Auth Road",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.639821,
              -79.420461
          ],
          "Rest_Name": "Liberty Village",
          "id": 1069,
          "address": "109 Atlantic Ave",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.672411,
              -79.469106
          ],
          "Rest_Name": "Stockyards",
          "id": 1070,
          "address": "1970 St. Clair Ave W Unit 109",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.491208,
              -82.080402
          ],
          "Rest_Name": "Augusta Exchange",
          "id": 1071,
          "address": "229 Robert C Daniel Jr Pkwy # A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.96501,
              -89.84286
          ],
          "Rest_Name": "Olive Branch",
          "id": 1072,
          "address": "8255 McGregor Crossing",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.418301,
              -86.675797
          ],
          "Rest_Name": "Cahaba Point",
          "id": 1073,
          "address": "5342 Highway 280",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.422581,
              -86.698268
          ],
          "Rest_Name": "Inverness",
          "id": 1074,
          "address": "4719 Highway 280",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.659748,
              -91.534894
          ],
          "Rest_Name": "Iowa City",
          "id": 1075,
          "address": "201 S Clinton St Ste 112",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.262639,
              -88.470015
          ],
          "Rest_Name": "Appleton",
          "id": 1076,
          "address": "111 N Mall Dr",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.438963,
              -86.788295
          ],
          "Rest_Name": "Vestavia Hills",
          "id": 1077,
          "address": "1031 Montgomery Hwy Ste 111",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.21473,
              -76.861714
          ],
          "Rest_Name": "Columbia Mall",
          "id": 1078,
          "address": "10300 Little Patuxent Pkwy Ste 1420",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.445756,
              -86.825986
          ],
          "Rest_Name": "Lakeshore",
          "id": 1079,
          "address": "273 Lakeshore Pkwy",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.846268,
              -76.885284
          ],
          "Rest_Name": "Forestville",
          "id": 1080,
          "address": "3443 Donnell Dr Ste 1",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.622794,
              -76.913416
          ],
          "Rest_Name": "Waldorf",
          "id": 1081,
          "address": "3250 Crain Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.0852,
              -76.862369
          ],
          "Rest_Name": "Laurel Lakes",
          "id": 1082,
          "address": "14354 Baltimore Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.957758,
              -76.86999
          ],
          "Rest_Name": "New Carrollton",
          "id": 1083,
          "address": "8490 Annapolis Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.649566,
              -79.391697
          ],
          "Rest_Name": "Queen and John",
          "id": 1084,
          "address": "319 Queen St W",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.767599,
              -76.887921
          ],
          "Rest_Name": "Woodyard Crossing",
          "id": 1085,
          "address": "8913 Woodyard Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.64499,
              -79.383388
          ],
          "Rest_Name": "123 Front Street",
          "id": 1086,
          "address": "123 Front St W Unit 103",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.238471,
              -76.935378
          ],
          "Rest_Name": "Camp Hill",
          "id": 1087,
          "address": "3216 Trindle Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.18359,
              -76.850249
          ],
          "Rest_Name": "Broken Land Pkwy",
          "id": 1088,
          "address": "9851 Broken Land Parkway",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.64927,
              -79.378423
          ],
          "Rest_Name": "4 King West",
          "id": 1089,
          "address": "103-4 King St W",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.65626,
              -79.388222
          ],
          "Rest_Name": "University Dundas",
          "id": 1090,
          "address": "R1-525 University Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.496414,
              -81.962333
          ],
          "Rest_Name": "North Augusta",
          "id": 1091,
          "address": "362 E Martintown Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.656861,
              -79.380962
          ],
          "Rest_Name": "Toronto Life Square",
          "id": 1092,
          "address": "323 Yonge St Unit 114",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.487026,
              -87.962984
          ],
          "Rest_Name": "East Green Bay",
          "id": 1093,
          "address": "2400 E Mason St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.670547,
              -79.383884
          ],
          "Rest_Name": "Bloor St E",
          "id": 1094,
          "address": "121 Bloor St E Suite 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.92138,
              -76.846378
          ],
          "Rest_Name": "Woodmore Towne Centre",
          "id": 1095,
          "address": "9301 Woodmore Center Dr Ste 514",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.277324,
              -76.825935
          ],
          "Rest_Name": "Ellicott City",
          "id": 1096,
          "address": "9120 Baltimore National Pike Lot A-1",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.869747,
              -76.847826
          ],
          "Rest_Name": "Steeplechase",
          "id": 1097,
          "address": "9141 Alaking Ct Ste 111",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.72602,
              -79.45182
          ],
          "Rest_Name": "Yorkdale Mall",
          "id": 1098,
          "address": "3401 Dufferin Street Unit FC-04",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.448267,
              -76.81519
          ],
          "Rest_Name": "Reisterstown Shopping Center",
          "id": 1099,
          "address": "11907 Reisterstown Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.954851,
              -76.828421
          ],
          "Rest_Name": "Vista Gardens",
          "id": 1100,
          "address": "10201 Martin Luther King Jr Hwy Unit 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.200752,
              -76.812958
          ],
          "Rest_Name": "Columbia",
          "id": 1101,
          "address": "6181 Old Dobbin Ln Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.230812,
              -76.893143
          ],
          "Rest_Name": "Cedar Cliff",
          "id": 1102,
          "address": "1120 Carlisle Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.068529,
              -78.967152
          ],
          "Rest_Name": "Fayetteville NC",
          "id": 1103,
          "address": "1918 Skibo Rd Ste 102",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.097935,
              -76.809009
          ],
          "Rest_Name": "Maryland City",
          "id": 1104,
          "address": "3353 Corridor Marketplace Ste 200",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.770474,
              -79.50024
          ],
          "Rest_Name": "York University",
          "id": 1105,
          "address": "85 The Pond Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.708197,
              -79.398366
          ],
          "Rest_Name": "Midtown Toronto",
          "id": 1106,
          "address": "2323 Yonge St Unit 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.486756,
              -88.067571
          ],
          "Rest_Name": "Green Bay - Oneida St.",
          "id": 1107,
          "address": "2388 S Oneida St Ste 300",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.125504,
              -78.881104
          ],
          "Rest_Name": "Fayetteville Ramsey St.",
          "id": 1108,
          "address": "4715 Ramsey St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.378965,
              -86.803812
          ],
          "Rest_Name": "Riverchase",
          "id": 1109,
          "address": "1759 Montgomery Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.676,
              -76.8276
          ],
          "Rest_Name": "Brandywine MD",
          "id": 1110,
          "address": "16110 Cadillac Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.706777,
              -79.361699
          ],
          "Rest_Name": "Leaside",
          "id": 1111,
          "address": "101 Laird Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.412696,
              -76.772272
          ],
          "Rest_Name": "Owings Mills",
          "id": 1112,
          "address": "10150 Reisterstown Rd Ste 40",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.825596,
              -79.538825
          ],
          "Rest_Name": "Vaughan Mills",
          "id": 1113,
          "address": "1 Bass Pro Mills Drive Suite FC6A",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.899966,
              -76.791667
          ],
          "Rest_Name": "Watkins",
          "id": 1114,
          "address": "2 Watkins Park Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.578833,
              -92.192716
          ],
          "Rest_Name": "Jefferson City",
          "id": 1115,
          "address": "1400 Missouri Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.963151,
              -89.990437
          ],
          "Rest_Name": "Southaven",
          "id": 1116,
          "address": "50 Goodman Rd W Ste A",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.329502,
              -76.862762
          ],
          "Rest_Name": "Linglestown",
          "id": 1117,
          "address": "2330 Linglestown Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.04438,
              -78.930733
          ],
          "Rest_Name": "Fayetteville Raeford Rd",
          "id": 1118,
          "address": "3101 Raeford Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.312449,
              -76.755355
          ],
          "Rest_Name": "Woodlawn",
          "id": 1119,
          "address": "7091 Security Blvd Ste 102",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.765935,
              -79.412398
          ],
          "Rest_Name": "North York",
          "id": 1120,
          "address": "4996 Yonge Street",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.850498,
              -79.53271
          ],
          "Rest_Name": "Vaughan North",
          "id": 1121,
          "address": "2901 Major Mackenzie Drive Building C Unit 3",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.355007,
              -86.848995
          ],
          "Rest_Name": "Stadium Trace",
          "id": 1122,
          "address": "1051 Amber Dr Ste 105",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.735191,
              -79.343997
          ],
          "Rest_Name": "Don Mills",
          "id": 1123,
          "address": "1072 Don Mills Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.388398,
              -76.736214
          ],
          "Rest_Name": "Pikesville",
          "id": 1124,
          "address": "1809 Reisterstown Rd Ste 134",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.276972,
              -76.8251
          ],
          "Rest_Name": "Union Deposit",
          "id": 1125,
          "address": "3531 Union Deposit Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.153273,
              -76.72457
          ],
          "Rest_Name": "Arundel Mills",
          "id": 1126,
          "address": "7049 Arundel Mills Cir Ste C",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.97774,
              -76.734879
          ],
          "Rest_Name": "Bowie Market Place",
          "id": 1127,
          "address": "15475 Annapolis Rd Ste 180",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.944513,
              -76.735131
          ],
          "Rest_Name": "Bowie Town Center",
          "id": 1128,
          "address": "15500 Excelsior Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.042839,
              -84.979688
          ],
          "Rest_Name": "Lagrange",
          "id": 1129,
          "address": "1509 Lafayette Pkwy",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.979821,
              -76.752051
          ],
          "Rest_Name": "West York",
          "id": 1130,
          "address": "890 Loucks Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.947395,
              -92.30007
          ],
          "Rest_Name": "Columbia East",
          "id": 1131,
          "address": "2540 Broadway Bluffs Dr Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.312466,
              -76.802003
          ],
          "Rest_Name": "Harrisburg",
          "id": 1132,
          "address": "5106 Jonestown Rd Ste I",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.108596,
              -83.253691
          ],
          "Rest_Name": "Milledgeville",
          "id": 1133,
          "address": "2399 N Columbia St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.424148,
              -77.633236
          ],
          "Rest_Name": "Roanoke Rapids NC",
          "id": 1134,
          "address": "304 Premier Blvd",
          "rating": 1.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.493234,
              -76.773689
          ],
          "Rest_Name": "Charlotte Hall",
          "id": 1135,
          "address": "30030 Three Notch Rd",
          "rating": 1.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.384596,
              -76.688925
          ],
          "Rest_Name": "Quarry Lake",
          "id": 1136,
          "address": "2588 Quarry Lake Dr Bldg E",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.202139,
              -76.683569
          ],
          "Rest_Name": "West Nursery",
          "id": 1137,
          "address": "1622 W Nursery Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.947978,
              -92.327411
          ],
          "Rest_Name": "Mizzou",
          "id": 1138,
          "address": "306 S 9th St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.038051,
              -76.68016
          ],
          "Rest_Name": "Waugh Chapel",
          "id": 1139,
          "address": "2503 Brandermill Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.179426,
              -76.671014
          ],
          "Rest_Name": "BWI Airport",
          "id": 1140,
          "address": "Post Security - AB Concourse Food Court",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.506173,
              -78.32157
          ],
          "Rest_Name": "Smithfield",
          "id": 1141,
          "address": "120 S Equity Dr Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.939665,
              -76.693203
          ],
          "Rest_Name": "South York",
          "id": 1142,
          "address": "1923 Springwood Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.769788,
              -76.67238
          ],
          "Rest_Name": "Shrewsbury PA",
          "id": 1143,
          "address": "98 Sofia Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.496668,
              -76.654429
          ],
          "Rest_Name": "Hunt Valley",
          "id": 1144,
          "address": "112 Shawan Rd Unit 2",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.775092,
              -79.257401
          ],
          "Rest_Name": "Scarborough Town Centre",
          "id": 1145,
          "address": "300 Borough Dr Unit 17/18",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.956726,
              -80.388801
          ],
          "Rest_Name": "Sumter",
          "id": 1146,
          "address": "1286 Broad St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.960867,
              -92.37042
          ],
          "Rest_Name": "Stadium Blvd",
          "id": 1147,
          "address": "2020 W Worley St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.337924,
              -76.635505
          ],
          "Rest_Name": "Greenspring Tower",
          "id": 1148,
          "address": "1040 W 41st St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.85256,
              -79.359102
          ],
          "Rest_Name": "Woodbine and 7",
          "id": 1149,
          "address": "8610 Woodbine Ave Bldg B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.983044,
              -76.668837
          ],
          "Rest_Name": "York",
          "id": 1150,
          "address": "2801 Concord Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.432745,
              -76.623175
          ],
          "Rest_Name": "Timonium 2",
          "id": 1151,
          "address": "1830 York Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.286624,
              -76.619508
          ],
          "Rest_Name": "West Pratt Street",
          "id": 1152,
          "address": "300 West Pratt St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.304333,
              -76.615981
          ],
          "Rest_Name": "North Charles Street",
          "id": 1153,
          "address": "1209 N Charles St Ste B",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.725282,
              -76.658776
          ],
          "Rest_Name": "Dunkirk",
          "id": 1154,
          "address": "2989 Plaza Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.32712,
              -76.615625
          ],
          "Rest_Name": "Charles Village",
          "id": 1155,
          "address": "3201 Saint Paul St Ste A",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.19509,
              -76.613549
          ],
          "Rest_Name": "Glen Burnie",
          "id": 1156,
          "address": "6654 Ritchie Hwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.372168,
              -76.61022
          ],
          "Rest_Name": "Roger's Forge",
          "id": 1157,
          "address": "6314 York Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.287129,
              -76.607407
          ],
          "Rest_Name": "East Pratt Street",
          "id": 1158,
          "address": "600 E Pratt St Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.98148,
              -76.884397
          ],
          "Rest_Name": "Lewisburg",
          "id": 1159,
          "address": "7431 Westbranch Hwy Ste 6",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.400403,
              -76.602846
          ],
          "Rest_Name": "Towson Commons",
          "id": 1160,
          "address": "401 York Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.27002,
              -76.68758
          ],
          "Rest_Name": "Hummelstown",
          "id": 1161,
          "address": "1198 Mae Street",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.2724,
              -76.60231
          ],
          "Rest_Name": "Federal Hill",
          "id": 1162,
          "address": "919 E Fort Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.837428,
              -76.832171
          ],
          "Rest_Name": "Selinsgrove",
          "id": 1163,
          "address": "2192 N Susquehanna Trl",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.02683,
              -91.626915
          ],
          "Rest_Name": "Lindale Mall",
          "id": 1164,
          "address": "4444 1st Ave. NE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.136266,
              -76.597528
          ],
          "Rest_Name": "Pasadena MD",
          "id": 1165,
          "address": "4 Mountain Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.340408,
              -76.591768
          ],
          "Rest_Name": "Northwood Commons",
          "id": 1166,
          "address": "1507 Havenwood Road",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.398285,
              -76.585683
          ],
          "Rest_Name": "Joppa",
          "id": 1167,
          "address": "801 Goucher Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.016354,
              -78.203918
          ],
          "Rest_Name": "Batavia",
          "id": 1168,
          "address": "4230 Veterans Memorial Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.281246,
              -76.579937
          ],
          "Rest_Name": "Can Company",
          "id": 1169,
          "address": "2400 Boston St Ste 126",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.248269,
              -76.960102
          ],
          "Rest_Name": "Williamsport",
          "id": 1170,
          "address": "1955 E 3rd St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.283716,
              -76.654587
          ],
          "Rest_Name": "Hershey",
          "id": 1171,
          "address": "210 W Chocolate Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.970033,
              -77.809089
          ],
          "Rest_Name": "Rocky Mount Hwy 301",
          "id": 1172,
          "address": "959 N Wesleyan Blvd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.18954,
              -79.830353
          ],
          "Rest_Name": "Florence SC",
          "id": 1173,
          "address": "2617 David H McLeod Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.287761,
              -76.548304
          ],
          "Rest_Name": "Hopkins Bayview",
          "id": 1174,
          "address": "501 Bayview Blvd",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.943155,
              -76.562083
          ],
          "Rest_Name": "Lee Airport",
          "id": 1175,
          "address": "3046 Solomons Island Rd Ste 200",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.373169,
              -76.542551
          ],
          "Rest_Name": "Parkville",
          "id": 1176,
          "address": "7711 Harford Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.077507,
              -76.544604
          ],
          "Rest_Name": "Severna Park",
          "id": 1177,
          "address": "575 Ritchie Hwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.987491,
              -76.547059
          ],
          "Rest_Name": "Annapolis Mall",
          "id": 1178,
          "address": "2002 Annapolis Mall Spc 185",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.544697,
              -76.59211
          ],
          "Rest_Name": "Prince Frederick",
          "id": 1179,
          "address": "144 W Dares Beach Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.731623,
              -77.951545
          ],
          "Rest_Name": "Wilson",
          "id": 1180,
          "address": "2304 Forest Hills Rd W",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.122486,
              -76.516403
          ],
          "Rest_Name": "East Pasadena MD",
          "id": 1181,
          "address": "4107 Mountain Rd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.270764,
              -76.505434
          ],
          "Rest_Name": "Dundalk Merritt Park",
          "id": 1182,
          "address": "7545 Holabird Ave",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.900818,
              -83.688555
          ],
          "Rest_Name": "Macon",
          "id": 1183,
          "address": "120 Tom Hill Sr Blvd Ste 100",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.363708,
              -89.559204
          ],
          "Rest_Name": "Oxford MS",
          "id": 1184,
          "address": "2151 Jackson Ave W",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.335366,
              -76.485039
          ],
          "Rest_Name": "Golden Ring",
          "id": 1185,
          "address": "8652 Pulaski Hwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.977812,
              -76.487798
          ],
          "Rest_Name": "Downtown Annapolis",
          "id": 1186,
          "address": "36 Market Space",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.367873,
              -76.451833
          ],
          "Rest_Name": "White Marsh MD",
          "id": 1187,
          "address": "5341 Campbell Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
            40.189531,
            -80.218710
          ],
          "Rest_Name": "Washington PA",
          "id": 1188,
          "address": "187 Old Mill Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.49382,
              -76.391267
          ],
          "Rest_Name": "Fallston",
          "id": 1189,
          "address": "2209 Bel Air Rd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.291997,
              -76.493615
          ],
          "Rest_Name": "Lex Park",
          "id": 1190,
          "address": "22720 Three Notch Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.561498,
              -76.373064
          ],
          "Rest_Name": "North Bel Air",
          "id": 1191,
          "address": "1538 Rock Spring Rd Ste 200",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.317947,
              -76.424328
          ],
          "Rest_Name": "Lebanon PA",
          "id": 1192,
          "address": "1451 Quentin Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.495593,
              -76.327773
          ],
          "Rest_Name": "Bel Air MD",
          "id": 1193,
          "address": "5 Bel Air S Pkwy",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.044809,
              -76.36346
          ],
          "Rest_Name": "West Lancaster PA",
          "id": 1194,
          "address": "130 Rohrerstown Rd Ste 10",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.210743,
              -87.553572
          ],
          "Rest_Name": "BAMA Campus",
          "id": 1195,
          "address": "1203 University Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.196767,
              -87.52704
          ],
          "Rest_Name": "Bama (University of Alabama)",
          "id": 1196,
          "address": "1800 McFarland Blvd E Ste 608",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.077067,
              -76.325595
          ],
          "Rest_Name": "Central Lancaster",
          "id": 1197,
          "address": "2081 Fruitville Pike",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.287482,
              -76.721078
          ],
          "Rest_Name": "Williamsburg II",
          "id": 1198,
          "address": "1413 Richmond Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.374761,
              -77.945619
          ],
          "Rest_Name": "Goldsboro",
          "id": 1199,
          "address": "501 N Berkeley Blvd Ste A",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.026734,
              -76.220056
          ],
          "Rest_Name": "East Lancaster PA",
          "id": 1200,
          "address": "2232 Lincoln Hwy E Ste 140",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.618576,
              -83.690349
          ],
          "Rest_Name": "Warner Robins",
          "id": 1201,
          "address": "133 Margie Dr Ste 500",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.394285,
              -76.527367
          ],
          "Rest_Name": "Gloucester",
          "id": 1202,
          "address": "7115 George Washington Memorial Hwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.616801,
              -85.404508
          ],
          "Rest_Name": "Tiger Town Auburn",
          "id": 1203,
          "address": "2125 Interstate Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.606818,
              -85.487317
          ],
          "Rest_Name": "Auburn Campus",
          "id": 1204,
          "address": "346 W Magnolia Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.165289,
              -76.160358
          ],
          "Rest_Name": "Ephrata",
          "id": 1205,
          "address": "823 E Main St Ste 100",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.087605,
              -77.62529
          ],
          "Rest_Name": "Henrietta",
          "id": 1206,
          "address": "640 Jefferson Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.541783,
              -84.950318
          ],
          "Rest_Name": "Columbus Park Crossing",
          "id": 1207,
          "address": "5295 Whittlesey Blvd Ste 600",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.123427,
              -77.617732
          ],
          "Rest_Name": "University of Rochester",
          "id": 1208,
          "address": "1360 Mount Hope Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.793927,
              -76.059386
          ],
          "Rest_Name": "Easton MD",
          "id": 1209,
          "address": "8911 Ocean Gtwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.202797,
              -77.651413
          ],
          "Rest_Name": "Greece",
          "id": 1210,
          "address": "1847 W Ridge Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.112524,
              -77.550491
          ],
          "Rest_Name": "Brighton NY",
          "id": 1211,
          "address": "2735 Monroe Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.102146,
              -77.540144
          ],
          "Rest_Name": "Pittsford Plaza NY",
          "id": 1212,
          "address": "3349 Monroe Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.480404,
              -84.943902
          ],
          "Rest_Name": "Columbus South",
          "id": 1213,
          "address": "3201 Macon Rd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.454201,
              -88.814088
          ],
          "Rest_Name": "Starkville",
          "id": 1214,
          "address": "101 MS HWY 12",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.019459,
              -77.438829
          ],
          "Rest_Name": "Victor Crossing",
          "id": 1215,
          "address": "401 Commerce Dr",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.112656,
              -76.495607
          ],
          "Rest_Name": "Patrick Henry Mall",
          "id": 1216,
          "address": "12300 Jefferson Ave Ste 200",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.198695,
              -77.577404
          ],
          "Rest_Name": "Irondequoit",
          "id": 1217,
          "address": "1495 E Ridge Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.067362,
              -76.49196
          ],
          "Rest_Name": "CNU",
          "id": 1218,
          "address": "12386 Warwick Blvd Ste A",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.069614,
              -77.441093
          ],
          "Rest_Name": "Perinton",
          "id": 1219,
          "address": "6720 Pittsford Palmyra Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.878228,
              -77.258982
          ],
          "Rest_Name": "Canandaigua",
          "id": 1220,
          "address": "282 Eastern Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.188798,
              -77.503958
          ],
          "Rest_Name": "Penfield, Empire Blvd",
          "id": 1221,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.551435,
              -77.405015
          ],
          "Rest_Name": "Greenville Memorial Dr",
          "id": 1222,
          "address": "4100 Humber Rd Ste 108",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.5843,
              -77.370953
          ],
          "Rest_Name": "Greenville",
          "id": 1223,
          "address": "610 Greenville Blvd SE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.357379,
              -75.983429
          ],
          "Rest_Name": "Reading",
          "id": 1224,
          "address": "2733 Papermill Rd Ste X5",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.213518,
              -77.449563
          ],
          "Rest_Name": "Webster Towne Center",
          "id": 1225,
          "address": "927 Holt Rd Ste 500",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.043568,
              -76.39315
          ],
          "Rest_Name": "Peninsula Town Center",
          "id": 1226,
          "address": "3510 Von Schilling Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.397385,
              -75.929153
          ],
          "Rest_Name": "Temple PA",
          "id": 1227,
          "address": "4300 5th Street Hwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.88124,
              -76.424366
          ],
          "Rest_Name": "Suffolk",
          "id": 1228,
          "address": "6216 College Dr Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.60052,
              -75.804095
          ],
          "Rest_Name": "Elkton",
          "id": 1229,
          "address": "722 E Pulaski Hwy Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.980646,
              -76.015136
          ],
          "Rest_Name": "Hazleton",
          "id": 1230,
          "address": "543 Susquehanna Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.824352,
              -76.412171
          ],
          "Rest_Name": "Chesapeake Square",
          "id": 1231,
          "address": "4116 Portsmouth Blvd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.459167,
              -86.391323
          ],
          "Rest_Name": "Prattville AL",
          "id": 1232,
          "address": "2566 Cobbs Ford Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.683617,
              -75.747669
          ],
          "Rest_Name": "Univ of Delaware",
          "id": 1233,
          "address": "136 E Main St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.445845,
              -75.734678
          ],
          "Rest_Name": "Middletown DE",
          "id": 1234,
          "address": "657 Middletown Warwick Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.824996,
              -76.338234
          ],
          "Rest_Name": "Portsmouth Frederick Blvd",
          "id": 1235,
          "address": "1805 Frederick Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.886684,
              -76.302246
          ],
          "Rest_Name": "ODU",
          "id": 1236,
          "address": "4712 Hampton Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.868151,
              -76.29813
          ],
          "Rest_Name": "Ghent",
          "id": 1237,
          "address": "1501 Colley Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.359183,
              -86.162277
          ],
          "Rest_Name": "Montgomery Eastchase",
          "id": 1238,
          "address": "2560 Berryhill Rd Ste C",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.915283,
              -76.271393
          ],
          "Rest_Name": "Wards Corner",
          "id": 1239,
          "address": "132 E Little Creek Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.628947,
              -75.66606
          ],
          "Rest_Name": "Bear DE",
          "id": 1240,
          "address": "1200 Pulaski Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.687652,
              -75.652467
          ],
          "Rest_Name": "Churchmans Road",
          "id": 1241,
          "address": "60 Geoffrey Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.678692,
              -75.651317
          ],
          "Rest_Name": "Christiana Mall",
          "id": 1242,
          "address": "132 Christiana Mall",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.732958,
              -75.639545
          ],
          "Rest_Name": "Kirkwood Highway",
          "id": 1243,
          "address": "3700 Kirkwood Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.872775,
              -91.190819
          ],
          "Rest_Name": "Onalaska",
          "id": 1244,
          "address": "9415 East 16 Frontage Rd ",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.662139,
              -75.608966
          ],
          "Rest_Name": "NEW CASTLE DELAWARE",
          "id": 1245,
          "address": "115A S Dupont Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.429953,
              -76.50853
          ],
          "Rest_Name": "Ithaca",
          "id": 1246,
          "address": "740 S Meadow St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.2548,
              -75.659758
          ],
          "Rest_Name": "Pottstown PA",
          "id": 1247,
          "address": "200 Shoemaker Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.032681,
              -75.630349
          ],
          "Rest_Name": "Exton",
          "id": 1248,
          "address": "241 N Pottstown Pike",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.860657,
              -76.21033
          ],
          "Rest_Name": "Military Hwy",
          "id": 1249,
          "address": "1087 N Military Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.766352,
              -76.250168
          ],
          "Rest_Name": "Chesapeake Battlefield",
          "id": 1250,
          "address": "100 Coastal Way",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.775386,
              -78.984428
          ],
          "Rest_Name": "Conway SC",
          "id": 1251,
          "address": "2296 E Highway 501",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.775948,
              -76.232699
          ],
          "Rest_Name": "Greenbrier Virginia Beach",
          "id": 1252,
          "address": "836 Eden Way N",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.969312,
              -75.587546
          ],
          "Rest_Name": "Turner Square",
          "id": 1253,
          "address": "101 Turner Ln",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.243665,
              -75.845769
          ],
          "Rest_Name": "Wilkes Barre",
          "id": 1254,
          "address": "280 Mundy St Ste 1",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.79086,
              -75.545712
          ],
          "Rest_Name": "Fairfax DE",
          "id": 1255,
          "address": "2209 Concord Pike",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.799879,
              -76.17865
          ],
          "Rest_Name": "Kemps River Commons",
          "id": 1256,
          "address": "5399 Kempsriver Dr",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.10693,
              -75.54102
          ],
          "Rest_Name": "S Dover Camden",
          "id": 1257,
          "address": "30 King Blvd.",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.186768,
              -75.536549
          ],
          "Rest_Name": "Dover DE",
          "id": 1258,
          "address": "1141 N Dupont Hwy Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.875339,
              -75.544217
          ],
          "Rest_Name": "Concordville",
          "id": 1259,
          "address": "1751 Wilmington Pike",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.844559,
              -76.129471
          ],
          "Rest_Name": "Virginia Beach Pembroke",
          "id": 1260,
          "address": "300 Constitution Dr Ste 103",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.349695,
              -75.60252
          ],
          "Rest_Name": "Salisbury University",
          "id": 1261,
          "address": "1016 S Salisbury Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.034801,
              -80.158297
          ],
          "Rest_Name": "Summerville Main St",
          "id": 1262,
          "address": "1209 N Main St Ste D",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.050671,
              -75.518312
          ],
          "Rest_Name": "Malvern PA",
          "id": 1263,
          "address": "50 National Ave Ste 1100",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.199081,
              -75.525085
          ],
          "Rest_Name": "Royersford",
          "id": 1264,
          "address": "70 Buckwalter Rd Ste 650",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.403186,
              -75.569717
          ],
          "Rest_Name": "North Salisbury",
          "id": 1265,
          "address": "2408B N Salisbury Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.705008,
              -78.920749
          ],
          "Rest_Name": "Myrtle Beach Coastal Grand",
          "id": 1266,
          "address": "1229 N Retail Ct",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.64424,
              -78.98683
          ],
          "Rest_Name": "Myrtle Beach South Strand",
          "id": 1267,
          "address": "1000 S Commons Dr Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.564208,
              -75.564003
          ],
          "Rest_Name": "Trexlertown",
          "id": 1268,
          "address": "750 N Krocks Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.41251,
              -81.768892
          ],
          "Rest_Name": "Statesboro",
          "id": 1269,
          "address": "170 Brampton Ave",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.819754,
              -76.068312
          ],
          "Rest_Name": "Lynnhaven",
          "id": 1270,
          "address": "2701 N Mall Dr Ste 108",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.775679,
              -76.088666
          ],
          "Rest_Name": "Landstown Commons",
          "id": 1271,
          "address": "3380 Princess Anne Rd Ste 115",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.842102,
              -78.661156
          ],
          "Rest_Name": "North Myrtle Beach",
          "id": 1272,
          "address": "1502 Highway 17 N Unit 1",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.914527,
              -75.432734
          ],
          "Rest_Name": "Granite Run",
          "id": 1273,
          "address": "1075 W Baltimore Pike Ste L",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.850994,
              -76.023622
          ],
          "Rest_Name": "Hilltop",
          "id": 1274,
          "address": "1724 Laskin Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.597143,
              -75.526976
          ],
          "Rest_Name": "Cedar Crest",
          "id": 1275,
          "address": "3114 W Tilghman St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.010147,
              -80.045853
          ],
          "Rest_Name": "Goose Creek",
          "id": 1276,
          "address": "220 Saint James Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.073973,
              -75.431954
          ],
          "Rest_Name": "Gateway Wayne",
          "id": 1277,
          "address": "239 E Swedesford Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.987042,
              -75.405412
          ],
          "Rest_Name": "Newtown Square",
          "id": 1278,
          "address": "3741 W Chester Pike",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.126572,
              -75.40663
          ],
          "Rest_Name": "Audubon",
          "id": 1279,
          "address": "717 S Trooper Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.088149,
              -75.392971
          ],
          "Rest_Name": "King of Prussia Food Court",
          "id": 1280,
          "address": "160 N Gulph Rd Ste 1049",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.629985,
              -75.480292
          ],
          "Rest_Name": "Lehigh Valley",
          "id": 1281,
          "address": "837 Lehigh Lifestyle Ctr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.044341,
              -75.379272
          ],
          "Rest_Name": "Wayne PA",
          "id": 1282,
          "address": "323 E Lancaster Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.755791,
              -76.007784
          ],
          "Rest_Name": "Red Mill R",
          "id": 1283,
          "address": "2201 Upton Dr Ste 908",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.088346,
              -75.382726
          ],
          "Rest_Name": "KOP Pad",
          "id": 1284,
          "address": "620 W DeKalb Pike ",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.755027,
              -77.460497
          ],
          "Rest_Name": "Richlands Hwy",
          "id": 1285,
          "address": "2730 US HWY 258",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.362623,
              -75.680485
          ],
          "Rest_Name": "Moosic",
          "id": 1286,
          "address": "5 Radcliffe Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.938301,
              -75.353662
          ],
          "Rest_Name": "Marple",
          "id": 1287,
          "address": "400 S State Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.786515,
              -77.402599
          ],
          "Rest_Name": "Gateway Plaza",
          "id": 1288,
          "address": "1345 Western Blvd Ste 140",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.936908,
              -80.0389
          ],
          "Rest_Name": "North Charleston",
          "id": 1289,
          "address": "7398 Rivers Ave Ste 102",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.916446,
              -75.333776
          ],
          "Rest_Name": "Springfield PA",
          "id": 1290,
          "address": "801 Baltimore Pike",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.487545,
              -75.695938
          ],
          "Rest_Name": "Clarks Summit PA",
          "id": 1291,
          "address": "1135 Northern Blvd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.956927,
              -76.542761
          ],
          "Rest_Name": "Auburn NY",
          "id": 1292,
          "address": "1 Plaza Dr",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.639636,
              -75.434827
          ],
          "Rest_Name": "Bethlehem PA",
          "id": 1293,
          "address": "1870 Airport Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.102893,
              -77.085557
          ],
          "Rest_Name": "New Bern",
          "id": 1294,
          "address": "2999 Dr Martin Luther King Jr Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.209743,
              -93.275599
          ],
          "Rest_Name": "Springfield National",
          "id": 1295,
          "address": "1211 E Saint Louis St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.124598,
              -75.973572
          ],
          "Rest_Name": "Johnson City NY",
          "id": 1296,
          "address": "621 Harry L Dr",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.092832,
              -75.952797
          ],
          "Rest_Name": "Binghamton",
          "id": 1297,
          "address": "4698 Vestal Pkwy E",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.899911,
              -75.30509
          ],
          "Rest_Name": "MacDade Blvd",
          "id": 1298,
          "address": "2603 Macdade Blvd",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.966892,
              -75.301307
          ],
          "Rest_Name": "Havertown",
          "id": 1299,
          "address": "116 W Township Line Rd Ste 206",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.445559,
              -75.359445
          ],
          "Rest_Name": "Rte 309 Quakertown",
          "id": 1300,
          "address": "79 N West End Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.009645,
              -75.293602
          ],
          "Rest_Name": "Ardmore Main Line",
          "id": 1301,
          "address": "133 W Lancaster Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.465942,
              -75.637274
          ],
          "Rest_Name": "Dickson City",
          "id": 1302,
          "address": "1245 Commerce Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.153027,
              -93.295163
          ],
          "Rest_Name": "Springfield-MO",
          "id": 1303,
          "address": "3356 S Campbell Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.116714,
              -75.285336
          ],
          "Rest_Name": "Germantown Pike",
          "id": 1304,
          "address": "550 W Germantown Pike",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.165873,
              -75.290726
          ],
          "Rest_Name": "Blue Bell",
          "id": 1305,
          "address": "950 DeKalb Pike",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.315556,
              -75.310181
          ],
          "Rest_Name": "Souderton",
          "id": 1306,
          "address": "715A Route 113",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.772324,
              -93.735849
          ],
          "Rest_Name": "Warrensburg",
          "id": 1307,
          "address": "728 N Maguire St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.958456,
              -75.258049
          ],
          "Rest_Name": "Upper Darby",
          "id": 1308,
          "address": "109 S 69th St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.873118,
              -80.020384
          ],
          "Rest_Name": "Centre Pointe",
          "id": 1309,
          "address": "4953 Centre Pointe Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.213665,
              -77.901899
          ],
          "Rest_Name": "Wilmington Oleander",
          "id": 1310,
          "address": "1525 Fordham Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.063345,
              -75.236689
          ],
          "Rest_Name": "Andorra SC",
          "id": 1311,
          "address": "701 E Cathedral Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.242672,
              -77.828374
          ],
          "Rest_Name": "Mayfaire",
          "id": 1312,
          "address": "941 International Dr Ste 1",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.240686,
              -75.241896
          ],
          "Rest_Name": "Airport Square",
          "id": 1313,
          "address": "30 Airport Sq",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.007464,
              -75.212216
          ],
          "Rest_Name": "Bala Cynwyd",
          "id": 1314,
          "address": "4030 City Avenue",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.954087,
              -75.200753
          ],
          "Rest_Name": "UPenn",
          "id": 1315,
          "address": "3925 Walnut St Ste 128",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.957221,
              -75.191371
          ],
          "Rest_Name": "Drexel University",
          "id": 1316,
          "address": "3400 Lancaster Ave Ste 10",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.650509,
              -75.29484
          ],
          "Rest_Name": "Freemansburg",
          "id": 1317,
          "address": "4743 Freemansburg Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.00873,
              -75.176496
          ],
          "Rest_Name": "Baker Centre",
          "id": 1318,
          "address": "2800 Fox St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.938824,
              -75.167058
          ],
          "Rest_Name": "Lincoln Square Philadelphia",
          "id": 1319,
          "address": "1000 S Broad St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.95209,
              -75.167774
          ],
          "Rest_Name": "Liberty Place",
          "id": 1320,
          "address": "1625 Chestnut St Ste F6",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.949496,
              -75.166664
          ],
          "Rest_Name": "Rittenhouse Square",
          "id": 1321,
          "address": "1512 Walnut Street",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.71468,
              -75.284512
          ],
          "Rest_Name": "Lower Nazareth",
          "id": 1322,
          "address": "3704 Easton Nazareth Hwy",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.948786,
              -75.160683
          ],
          "Rest_Name": "12th & Walnut",
          "id": 1323,
          "address": "1200 Walnut St Ste B",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.979579,
              -75.153661
          ],
          "Rest_Name": "Temple University",
          "id": 1324,
          "address": "1100 W Montgomery Ave Ste A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.14361,
              -75.168796
          ],
          "Rest_Name": "Fort Washington",
          "id": 1325,
          "address": "1673 Limekiln Pike",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.0735,
              -75.1569
          ],
          "Rest_Name": "Cheltenham Mall",
          "id": 1326,
          "address": "2401 W Cheltenham Ave Ste 220",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.923598,
              -75.14156
          ],
          "Rest_Name": "Columbus Blvd",
          "id": 1327,
          "address": "1851 S Columbus Blvd Ste 3",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.781932,
              -79.990821
          ],
          "Rest_Name": "Charleston West Ashley",
          "id": 1328,
          "address": "975 Savannah Hwy Ste 205",
          "rating": 1.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.721118,
              -75.115195
          ],
          "Rest_Name": "Glassboro",
          "id": 1329,
          "address": "789 Delsea Dr N",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.877994,
              -75.11351
          ],
          "Rest_Name": "Brooklawn NJ",
          "id": 1330,
          "address": "703 Browning Ln",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.785578,
              -79.935977
          ],
          "Rest_Name": "Charleston King Street",
          "id": 1331,
          "address": "374 King St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.223789,
              -75.139374
          ],
          "Rest_Name": "Warrington",
          "id": 1332,
          "address": "385 Easton Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.143644,
              -75.121712
          ],
          "Rest_Name": "Willow Grove",
          "id": 1333,
          "address": "2618 W Moreland Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.840318,
              -75.092033
          ],
          "Rest_Name": "Deptford",
          "id": 1334,
          "address": "2000 Clements Bridge Road",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.817082,
              -79.845065
          ],
          "Rest_Name": "Mt. Pleasant Wando Crossing",
          "id": 1335,
          "address": "1509 N Highway 17",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.123367,
              -75.115874
          ],
          "Rest_Name": "Abington",
          "id": 1336,
          "address": "1437 Old York Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.748098,
              -75.07583
          ],
          "Rest_Name": "Washington Township NJ",
          "id": 1337,
          "address": "415 Egg Harbor Rd Ste 30A",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.439545,
              -80.704788
          ],
          "Rest_Name": "Beaufort",
          "id": 1338,
          "address": "21 Robert Smalls Pkwy",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.323482,
              -75.130139
          ],
          "Rest_Name": "Doylestown",
          "id": 1339,
          "address": "569 N Main St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.866835,
              -75.080053
          ],
          "Rest_Name": "Bellmawr",
          "id": 1340,
          "address": "2 N Black Horse Pike",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.717848,
              -75.111212
          ],
          "Rest_Name": "Rehoboth",
          "id": 1341,
          "address": "36453 Seaside Outlet Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.197716,
              -75.083216
          ],
          "Rest_Name": "Warminster",
          "id": 1342,
          "address": "25 W Street Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.047823,
              -75.058301
          ],
          "Rest_Name": "Cottman Avenue",
          "id": 1343,
          "address": "2327 Cottman Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.171207,
              -75.071756
          ],
          "Rest_Name": "County Line Rd",
          "id": 1344,
          "address": "2090 County Line Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.803086,
              -75.037262
          ],
          "Rest_Name": "Clementon",
          "id": 1345,
          "address": "1410 Blackwood Clementon Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.92742,
              -75.031925
          ],
          "Rest_Name": "Cherry Hill NJ",
          "id": 1346,
          "address": "818 Haddonfield Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.074503,
              -75.03305
          ],
          "Rest_Name": "NE Shopping Center",
          "id": 1347,
          "address": "9173 Roosevelt Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.734516,
              -75.007887
          ],
          "Rest_Name": "Turnersville",
          "id": 1348,
          "address": "629 Cross Keys Rd Ste C",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.047711,
              -76.237321
          ],
          "Rest_Name": "Camillus",
          "id": 1349,
          "address": "3600 W Genesee St",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.913171,
              -75.009904
          ],
          "Rest_Name": "Ellisburg Circle",
          "id": 1350,
          "address": "3 Marlton Pike E",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.709328,
              -93.580778
          ],
          "Rest_Name": "Ankeny",
          "id": 1351,
          "address": "2125 SE Delaware Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.987369,
              -75.011253
          ],
          "Rest_Name": "Cinnaminson",
          "id": 1352,
          "address": "115 Route 130 S",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.335852,
              -75.101551
          ],
          "Rest_Name": "West Ocean City",
          "id": 1353,
          "address": "12909 Ocean Gtwy Unit 1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.853169,
              -74.982545
          ],
          "Rest_Name": "Voorhees",
          "id": 1354,
          "address": "700 Haddonfield Berlin Rd Ste 40C",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.106537,
              -75.002213
          ],
          "Rest_Name": "Roosevelt Blvd",
          "id": 1355,
          "address": "12000 Roosevelt Blvd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.150348,
              -75.001692
          ],
          "Rest_Name": "Feasterville",
          "id": 1356,
          "address": "120 E Street Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.431785,
              -75.055966
          ],
          "Rest_Name": "North Ocean City",
          "id": 1357,
          "address": "12601 Coastal Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.946398,
              -74.959013
          ],
          "Rest_Name": "East Gate",
          "id": 1358,
          "address": "1600 Nixon Dr",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.140266,
              -81.243508
          ],
          "Rest_Name": "Pooler GA",
          "id": 1359,
          "address": "6 Mill Creek Cir Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.137362,
              -74.964624
          ],
          "Rest_Name": "Neshaminy",
          "id": 1360,
          "address": "3580 Horizon Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.90622,
              -74.93662
          ],
          "Rest_Name": "Marlton Greentree",
          "id": 1361,
          "address": "882 Route 73 N Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.18404,
              -76.247989
          ],
          "Rest_Name": "Clay",
          "id": 1362,
          "address": "3852 State Route 31",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.041845,
              -76.135868
          ],
          "Rest_Name": "Syracuse University",
          "id": 1363,
          "address": "129 Marshall St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.103038,
              -74.949458
          ],
          "Rest_Name": "Bensalem",
          "id": 1364,
          "address": "2307 Street Rd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.628894,
              -93.697223
          ],
          "Rest_Name": "Merle Hay",
          "id": 1365,
          "address": "3737 Merle Hay Rd Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.87752,
              -74.919336
          ],
          "Rest_Name": "Marlton",
          "id": 1366,
          "address": "500 Route 73 S",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.247148,
              -80.832487
          ],
          "Rest_Name": "Bluffton",
          "id": 1367,
          "address": "1250 Fording Island Rd Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.969929,
              -74.914218
          ],
          "Rest_Name": "Mt. Laurel",
          "id": 1368,
          "address": "10 Centerton Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.235762,
              -74.941877
          ],
          "Rest_Name": "Newtown PA",
          "id": 1369,
          "address": "2940 S Eagle Rd",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.617023,
              -84.221769
          ],
          "Rest_Name": "Albany",
          "id": 1370,
          "address": "2630 Dawson Rd # 1",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.090446,
              -92.417587
          ],
          "Rest_Name": "Conway",
          "id": 1371,
          "address": "915 E Oak St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.597726,
              -93.750277
          ],
          "Rest_Name": "Valley West Mall",
          "id": 1372,
          "address": "1551 Valley West Dr Ste 224",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.079529,
              -81.284775
          ],
          "Rest_Name": "Savannah Quarters",
          "id": 1373,
          "address": "2120 Pooler Pkwy",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.042929,
              -76.068459
          ],
          "Rest_Name": "Dewitt",
          "id": 1374,
          "address": "3496 Erie Blvd East",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.01065,
              -74.86988
          ],
          "Rest_Name": "RIO GRANDE",
          "id": 1375,
          "address": "1705 NJ-47 South",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.562515,
              -93.799293
          ],
          "Rest_Name": "Jordan Creek",
          "id": 1376,
          "address": "490 S 68th St Ste 110",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.1793,
              -74.879
          ],
          "Rest_Name": "Oxford Valley",
          "id": 1377,
          "address": "2370 E Lincoln Hwy",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.017626,
              -93.610942
          ],
          "Rest_Name": "Ames",
          "id": 1378,
          "address": "435 S Duff Ave Ste 102",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.154937,
              -76.122084
          ],
          "Rest_Name": "Cicero NY",
          "id": 1379,
          "address": "7944 Brewerton Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.033741,
              -76.017953
          ],
          "Rest_Name": "Fayetteville East Genesee St.",
          "id": 1380,
          "address": "406 Towne Dr",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.054887,
              -74.844064
          ],
          "Rest_Name": "Burlington NJ",
          "id": 1381,
          "address": "1805 Mount Holly Rd Ste 100",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.79121,
              -92.22813
          ],
          "Rest_Name": "North Little Rock",
          "id": 1382,
          "address": "3917 McCain Blvd.",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.50063,
              -74.85544
          ],
          "Rest_Name": "Flemington",
          "id": 1383,
          "address": "287 US 202",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.61553,
              -93.86384
          ],
          "Rest_Name": "Waukee",
          "id": 1384,
          "address": "425 E. Hickman Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.025883,
              -94.248295
          ],
          "Rest_Name": "Blue Springs",
          "id": 1385,
          "address": "1115 NE Coronado Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.043869,
              -81.072026
          ],
          "Rest_Name": "Savannah Victory Station",
          "id": 1386,
          "address": "1801 E Victory Dr Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.00896,
              -94.27518
          ],
          "Rest_Name": "Blue Springs - Hwy 7",
          "id": 1387,
          "address": "802 SW US-40",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.0053,
              -81.11393
          ],
          "Rest_Name": "Savannah Abercorn",
          "id": 1388,
          "address": "318 Mall Blvd Ste 600C",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.75202,
              -92.341761
          ],
          "Rest_Name": "University & Markham",
          "id": 1389,
          "address": "100 S University Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.794012,
              -92.400984
          ],
          "Rest_Name": "Pleasant Ridge Town Center",
          "id": 1390,
          "address": "11525 Cantrell Rd Ste 1000",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.822484,
              -81.598801
          ],
          "Rest_Name": "Hinesville",
          "id": 1391,
          "address": "806 W Oglethorpe Hwy Unit A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.033696,
              -94.357917
          ],
          "Rest_Name": "Independence",
          "id": 1392,
          "address": "18880 E Valley View Pkwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.77466,
              -91.43798
          ],
          "Rest_Name": "Eau Claire",
          "id": 1393,
          "address": "4738 Golf Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.451197,
              -74.637708
          ],
          "Rest_Name": "Mays Landing",
          "id": 1394,
          "address": "2220 Wrangleboro Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.921327,
              -94.359722
          ],
          "Rest_Name": "Chipman",
          "id": 1395,
          "address": "900 NE Columbus St Unit A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.295026,
              -74.684338
          ],
          "Rest_Name": "Mercer Mall",
          "id": 1396,
          "address": "3371 US Highway 1 Unit 250",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.753684,
              -92.412967
          ],
          "Rest_Name": "Chenal & Markham",
          "id": 1397,
          "address": "12524 Chenal Pkwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.311031,
              -74.66144
          ],
          "Rest_Name": "Princeton",
          "id": 1398,
          "address": "3522 US Highway 1",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.912167,
              -94.396644
          ],
          "Rest_Name": "South Lee Summit",
          "id": 1399,
          "address": "1103 SW Oldham Pkwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.329056,
              -74.594978
          ],
          "Rest_Name": "Somers Point",
          "id": 1400,
          "address": "210 New Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.928658,
              -94.409007
          ],
          "Rest_Name": "Lee's Summit",
          "id": 1401,
          "address": "1716 NW Chipman Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.792949,
              -91.51212
          ],
          "Rest_Name": "UW - Eau Claire",
          "id": 1402,
          "address": "941 W Clairemont Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.006074,
              -92.464226
          ],
          "Rest_Name": "Ear of Corn",
          "id": 1403,
          "address": "1201 Broadway Ave S Ste 60",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.498091,
              -74.644712
          ],
          "Rest_Name": "Hillsborough",
          "id": 1404,
          "address": "649 US Highway 206 Unit 1A",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.779104,
              -74.695598
          ],
          "Rest_Name": "Chester NJ",
          "id": 1405,
          "address": "537 US Hwy 206",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.391376,
              -74.559689
          ],
          "Rest_Name": "Egg Harbor",
          "id": 1406,
          "address": "6813 Tilton Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.046592,
              -94.446975
          ],
          "Rest_Name": "Blue Ridge Crossing",
          "id": 1407,
          "address": "4151 Sterling Ave Ste B",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.059733,
              -74.752
          ],
          "Rest_Name": "Newton",
          "id": 1408,
          "address": "121 Water St",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.247708,
              -94.465917
          ],
          "Rest_Name": "Liberty",
          "id": 1409,
          "address": "8700 NE Flintlock Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.994551,
              -94.474417
          ],
          "Rest_Name": "Raytown",
          "id": 1410,
          "address": "9205 E State Route 350",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.582943,
              -74.614439
          ],
          "Rest_Name": "Bridgewater Commons",
          "id": 1411,
          "address": "640 Commons Way Ste 4280",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.062767,
              -92.500103
          ],
          "Rest_Name": "Rochester",
          "id": 1412,
          "address": "3780 Marketplace Dr NW Ste 100",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.874315,
              -74.650184
          ],
          "Rest_Name": "Ledgewood Commons",
          "id": 1413,
          "address": "461 Rte 10",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.27876,
              -74.530297
          ],
          "Rest_Name": "East Windsor",
          "id": 1414,
          "address": "319 US Highway 130",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.173627,
              -94.535573
          ],
          "Rest_Name": "North KC",
          "id": 1415,
          "address": "4323 N Chouteau Trfy Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.644172,
              -74.577208
          ],
          "Rest_Name": "Basking Ridge",
          "id": 1416,
          "address": "25 Mountainview Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.574,
              -74.5612
          ],
          "Rest_Name": "Chimney Rock",
          "id": 1417,
          "address": "318 Chimney Rock Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.814028,
              -94.510088
          ],
          "Rest_Name": "Belton",
          "id": 1418,
          "address": "1424 E North Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.901985,
              -94.525386
          ],
          "Rest_Name": "Truman's Marketplace",
          "id": 1419,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.720187,
              -74.563005
          ],
          "Rest_Name": "Bernardsville",
          "id": 1420,
          "address": "80 Morristown Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.195461,
              -94.579993
          ],
          "Rest_Name": "North Oak",
          "id": 1421,
          "address": "203 NE Englewood Rd # I",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.097367,
              -94.582447
          ],
          "Rest_Name": "Kansas City Live",
          "id": 1422,
          "address": "1370 Walnut St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.247634,
              -94.595075
          ],
          "Rest_Name": "Twin Creeks",
          "id": 1423,
          "address": "949 N 84th Ter",
          "rating": 1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.038948,
              -94.586877
          ],
          "Rest_Name": "Main Street KC",
          "id": 1424,
          "address": "4851 Main St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.992351,
              -94.595414
          ],
          "Rest_Name": "Waldo",
          "id": 1425,
          "address": "501 W 75th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.057066,
              -94.605593
          ],
          "Rest_Name": "Bell",
          "id": 1426,
          "address": "1713 W 39th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.906883,
              -74.553241
          ],
          "Rest_Name": "Rockaway Townsquare Mall",
          "id": 1427,
          "address": "301 Mt Hope Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.461415,
              -74.45718
          ],
          "Rest_Name": "North Brunswick",
          "id": 1428,
          "address": "524 Shoppes Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.944279,
              -94.607973
          ],
          "Rest_Name": "South KC",
          "id": 1429,
          "address": "10221 State Line Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.888928,
              -94.607339
          ],
          "Rest_Name": "Stateline",
          "id": 1430,
          "address": "13127 State Line Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.209913,
              -94.643014
          ],
          "Rest_Name": "Tremont",
          "id": 1431,
          "address": "6314 N Chatham Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.003581,
              -94.629855
          ],
          "Rest_Name": "Prairie Village",
          "id": 1432,
          "address": "6920 Mission Rd Ste C",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.496055,
              -74.443963
          ],
          "Rest_Name": "New Brunswick NJ Rutgers",
          "id": 1433,
          "address": "387 George St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.246394,
              -85.428363
          ],
          "Rest_Name": "Dothan",
          "id": 1434,
          "address": "3473 Ross Clark Drive",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.253091,
              -94.653916
          ],
          "Rest_Name": "Barry North",
          "id": 1435,
          "address": "8600 N Boardwalk Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.912594,
              -94.643196
          ],
          "Rest_Name": "Leawood",
          "id": 1436,
          "address": "5065 W 119th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.554506,
              -74.431474
          ],
          "Rest_Name": "South Plainfield",
          "id": 1437,
          "address": "6850 Hadley Road",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.023373,
              -94.66486
          ],
          "Rest_Name": "Johnson Drive",
          "id": 1438,
          "address": "6864 Johnson Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.963547,
              -94.665794
          ],
          "Rest_Name": "Glenwood",
          "id": 1439,
          "address": "6879 W 91st St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.844952,
              -74.467307
          ],
          "Rest_Name": "Morris Plains",
          "id": 1440,
          "address": "1711 State Route 10",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.43529,
              -74.390348
          ],
          "Rest_Name": "East Brunswick",
          "id": 1441,
          "address": "609 State Route 18",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.885062,
              -94.667307
          ],
          "Rest_Name": "135th & Metcalf",
          "id": 1442,
          "address": "7040 W 135th St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.642992,
              -74.411518
          ],
          "Rest_Name": "Route 22 Watchung",
          "id": 1443,
          "address": "1620 US Highway 22",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.015365,
              -94.688388
          ],
          "Rest_Name": "Merriam",
          "id": 1444,
          "address": "8800 Shawnee Mission Pkwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.854149,
              -94.683167
          ],
          "Rest_Name": "Stanley",
          "id": 1445,
          "address": "8301 W 151st St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.516132,
              -74.373944
          ],
          "Rest_Name": "Edison NJ",
          "id": 1446,
          "address": "901 US-1",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.760139,
              -74.416757
          ],
          "Rest_Name": "Madison NJ",
          "id": 1447,
          "address": "6 Main St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.706449,
              -74.40458
          ],
          "Rest_Name": "New Providence",
          "id": 1448,
          "address": "1260 Springfield Ave Ste 2",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.927964,
              -94.697999
          ],
          "Rest_Name": "Corporate Woods",
          "id": 1449,
          "address": "9900 College Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.540868,
              -74.365858
          ],
          "Rest_Name": "Metuchen",
          "id": 1450,
          "address": "349 Lake Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.014177,
              -94.722639
          ],
          "Rest_Name": "Shawnee",
          "id": 1451,
          "address": "11805 Shawnee Mission Parkway",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.953852,
              -94.7179
          ],
          "Rest_Name": "Oak Park Mall Food Court",
          "id": 1452,
          "address": "11513 W 95th St Ste Fcj",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.704048,
              -74.275577
          ],
          "Rest_Name": "Manahawkin",
          "id": 1453,
          "address": "385 Martin Truex Jr Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.957305,
              -94.726616
          ],
          "Rest_Name": "95th & Quivira",
          "id": 1454,
          "address": "12150 W 95th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.24438,
              -74.299337
          ],
          "Rest_Name": "Freehold",
          "id": 1455,
          "address": "323 W Main St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.547867,
              -74.334464
          ],
          "Rest_Name": "Menlo Park Mall 2",
          "id": 1456,
          "address": "55 Parsonage Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.65096,
              -74.349401
          ],
          "Rest_Name": "Westfield NJ",
          "id": 1457,
          "address": "117 E Broad St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.116807,
              -94.760043
          ],
          "Rest_Name": "78th & State",
          "id": 1458,
          "address": "7750 State Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.86189,
              -74.387713
          ],
          "Rest_Name": "Parsippany Troy Hills",
          "id": 1459,
          "address": "1131 US Highway 46",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.883055,
              -94.752265
          ],
          "Rest_Name": "Blackbob",
          "id": 1460,
          "address": "14317 W 135th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.803476,
              -74.362675
          ],
          "Rest_Name": "East Hanover NJ",
          "id": 1461,
          "address": "368 State Route 10",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.009255,
              -94.76982
          ],
          "Rest_Name": "Central Shawnee",
          "id": 1462,
          "address": "15830 Shawnee Mission Pkwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.913387,
              -94.76152
          ],
          "Rest_Name": "Northridge",
          "id": 1463,
          "address": "15100 W 119th St",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.970588,
              -94.772657
          ],
          "Rest_Name": "Lenexa",
          "id": 1464,
          "address": "16101 W 87th St Pkwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.718625,
              -74.329505
          ],
          "Rest_Name": "Springfield NJ",
          "id": 1465,
          "address": "697 Morris Tpke Ste 2",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.536275,
              -74.296361
          ],
          "Rest_Name": "WOODBRIDGE",
          "id": 1466,
          "address": "318 US Highway 9 N",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.687128,
              -74.31577
          ],
          "Rest_Name": "Union Springfield",
          "id": 1467,
          "address": "101 US Highway 22",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.632739,
              -74.305283
          ],
          "Rest_Name": "Clark NJ",
          "id": 1468,
          "address": "1255 Raritan Rd Unit 410",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.813656,
              -94.813872
          ],
          "Rest_Name": "St. Joe",
          "id": 1469,
          "address": "5107 N Belt Hwy Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.13435,
              -74.223073
          ],
          "Rest_Name": "Howell",
          "id": 1470,
          "address": "4733 US Highway 9",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.913261,
              -94.794208
          ],
          "Rest_Name": "119th & Ridgeview",
          "id": 1471,
          "address": "18016 W 119th St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.095363,
              -75.305808
          ],
          "Rest_Name": "New Hartford",
          "id": 1472,
          "address": "4815 Commercial Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.126117,
              -94.823465
          ],
          "Rest_Name": "Legends",
          "id": 1473,
          "address": "1813 Village West Pkwy Ste Q101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.847453,
              -74.186416
          ],
          "Rest_Name": "Forked River",
          "id": 1474,
          "address": "246 North Main Street",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.984303,
              -74.180515
          ],
          "Rest_Name": "Toms River NJ",
          "id": 1475,
          "address": "1258 Hooper Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.851197,
              -94.817512
          ],
          "Rest_Name": "Southgate",
          "id": 1476,
          "address": "20080 W 153rd St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.850213,
              -74.294027
          ],
          "Rest_Name": "West Caldwell",
          "id": 1477,
          "address": "749 Bloomfield Ave Ste 4",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.009372,
              -94.846074
          ],
          "Rest_Name": "Shawnee West Relo",
          "id": 1478,
          "address": "22410 W 66th St",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.883567,
              -94.834688
          ],
          "Rest_Name": "Olathe West",
          "id": 1479,
          "address": "197 N Parker St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.95419,
              -74.16339
          ],
          "Rest_Name": "Route 37 Toms River",
          "id": 1480,
          "address": "465 East Route 37",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.46167,
              -90.13499
          ],
          "Rest_Name": "Madison MS",
          "id": 1481,
          "address": "164 Grandview Blvd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.682556,
              -74.234111
          ],
          "Rest_Name": "Union NJ",
          "id": 1482,
          "address": "1101 Morris Ave",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.97848,
              -75.947266
          ],
          "Rest_Name": "Watertown_NY",
          "id": 1483,
          "address": "1290 Arsenal St Ste 7",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.803907,
              -74.248848
          ],
          "Rest_Name": "West Orange",
          "id": 1484,
          "address": "235 Prospect Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.059006,
              -74.142208
          ],
          "Rest_Name": "Brick Plaza",
          "id": 1485,
          "address": "74 Brick Plz",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.888695,
              -74.251974
          ],
          "Rest_Name": "Wayne Town Center",
          "id": 1486,
          "address": "70 Willowbrook Blvd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.20587,
              -82.33601
          ],
          "Rest_Name": "Waycross",
          "id": 1487,
          "address": "1751 Memorial Drive",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.415183,
              -74.15862
          ],
          "Rest_Name": "Holmdel",
          "id": 1488,
          "address": "2131 State Route 35 Ste 102",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.453492,
              -74.381107
          ],
          "Rest_Name": "Middletown_NY",
          "id": 1489,
          "address": "444 Rte 211 E",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.58188,
              -74.165865
          ],
          "Rest_Name": "Staten Island Mall",
          "id": 1490,
          "address": "2655 Richmond Ave Ste P05",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.399979,
              -90.141434
          ],
          "Rest_Name": "Ridgeland",
          "id": 1491,
          "address": "836 E County Line Rd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.783362,
              -74.192425
          ],
          "Rest_Name": "Bloomfield",
          "id": 1492,
          "address": "240 Bloomfield Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.084878,
              -94.47663
          ],
          "Rest_Name": "Joplin",
          "id": 1493,
          "address": "631 S Range Line Rd Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.734835,
              -74.170299
          ],
          "Rest_Name": "Newark NJ",
          "id": 1494,
          "address": "222 Market St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.337598,
              -90.067307
          ],
          "Rest_Name": "Flowood",
          "id": 1495,
          "address": "108 Market St Ste 100",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.759513,
              -74.160434
          ],
          "Rest_Name": "Kearny",
          "id": 1496,
          "address": "190 Passaic Ave Ste 6",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.139538,
              -74.060942
          ],
          "Rest_Name": "Brook 35",
          "id": 1497,
          "address": "2150 Route 35",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.568004,
              -74.111222
          ],
          "Rest_Name": "Hylan Blvd",
          "id": 1498,
          "address": "2602 Hylan Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.3139,
              -74.0662
          ],
          "Rest_Name": "Shrewsbury NJ",
          "id": 1499,
          "address": "490B Shrewsbury Plz",
          "rating": 4.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.350742,
              -74.069902
          ],
          "Rest_Name": "Red Bank NJ",
          "id": 1500,
          "address": "20 Water St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.334667,
              -94.18512
          ],
          "Rest_Name": "Bentonville",
          "id": 1501,
          "address": "2400 SE Walton Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.289936,
              -74.052551
          ],
          "Rest_Name": "Monmouth Mall",
          "id": 1502,
          "address": "186 Hwy 35",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.8303,
              -74.13768
          ],
          "Rest_Name": "Mad River",
          "id": 1503,
          "address": "380 State Rt 3",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.284407,
              -90.039506
          ],
          "Rest_Name": "Brandon",
          "id": 1504,
          "address": "5718 Highway 80 E",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.831172,
              -74.089105
          ],
          "Rest_Name": "East Rutherford",
          "id": 1505,
          "address": "40 State Rt 17",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.070674,
              -74.139408
          ],
          "Rest_Name": "Ramsey",
          "id": 1506,
          "address": "1255 State Rt 17 Ste 3",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.7312,
              -74.0645
          ],
          "Rest_Name": "Journal Square",
          "id": 1507,
          "address": "26 Journal Sq Ste 120",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.622081,
              -74.026611
          ],
          "Rest_Name": "Bay Ridge",
          "id": 1508,
          "address": "463 86th St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.112765,
              -94.145347
          ],
          "Rest_Name": "College & Millsap",
          "id": 1509,
          "address": "3379 N College Ave Ste 1",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.891262,
              -74.072044
          ],
          "Rest_Name": "LODI NJ",
          "id": 1510,
          "address": "170 Essex Street Ste 3",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.916331,
              -74.076981
          ],
          "Rest_Name": "Garden State Plaza",
          "id": 1511,
          "address": "1 Garden State Plz",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.716918,
              -74.035255
          ],
          "Rest_Name": "Exchange Place",
          "id": 1512,
          "address": "30 Montgomery St",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.861558,
              -74.062319
          ],
          "Rest_Name": "Teterboro",
          "id": 1513,
          "address": "9 Teterboro Landing Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.726784,
              -74.035263
          ],
          "Rest_Name": "Jersey City Newport Tower",
          "id": 1514,
          "address": "525 Washington Blvd Ste G",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.787561,
              -74.04581
          ],
          "Rest_Name": "Secaucus",
          "id": 1515,
          "address": "700 Plaza Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.957278,
              -74.07577
          ],
          "Rest_Name": "Paramus Rt 17",
          "id": 1516,
          "address": "545 Route 17 South",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.739605,
              -74.030094
          ],
          "Rest_Name": "Hoboken NJ",
          "id": 1517,
          "address": "229 Washington Street",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.919302,
              -74.064849
          ],
          "Rest_Name": "Paramus",
          "id": 1518,
          "address": "81 E State Rt 4 Ste 101",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.651156,
              -74.007204
          ],
          "Rest_Name": "Sunset Park",
          "id": 1519,
          "address": "4101 4th Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.710703,
              -74.016362
          ],
          "Rest_Name": "Battery Park City",
          "id": 1520,
          "address": "325 S End Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.603254,
              -73.996469
          ],
          "Rest_Name": "Bensonhurst",
          "id": 1521,
          "address": "2076 86th St",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.704225,
              -74.013027
          ],
          "Rest_Name": "Bowling Green",
          "id": 1522,
          "address": "2 Broadway Frnt 4",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.702015,
              -74.01215
          ],
          "Rest_Name": "New York Plaza",
          "id": 1523,
          "address": "1 New York Plz",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.714991,
              -74.011105
          ],
          "Rest_Name": "Tribeca",
          "id": 1524,
          "address": "275 Greenwich Street",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.58329,
              -73.986764
          ],
          "Rest_Name": "Cropsey Avenue",
          "id": 1525,
          "address": "2940 Cropsey Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.706723,
              -74.00697
          ],
          "Rest_Name": "Maiden Lane",
          "id": 1526,
          "address": "100 Maiden Ln",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.231716,
              -81.499477
          ],
          "Rest_Name": "Brunswick 2",
          "id": 1527,
          "address": "100 Shupe Way",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.709726,
              -74.006437
          ],
          "Rest_Name": "Fulton Street",
          "id": 1528,
          "address": "111 Fulton St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.619161,
              -73.990627
          ],
          "Rest_Name": "6502 18th Avenue",
          "id": 1529,
          "address": "6502 18th Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.714498,
              -74.006234
          ],
          "Rest_Name": "Civic Center",
          "id": 1530,
          "address": "281 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.803222,
              -74.021318
          ],
          "Rest_Name": "North Bergen",
          "id": 1531,
          "address": "2199 74th St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.728236,
              -74.005126
          ],
          "Rest_Name": "Varick",
          "id": 1532,
          "address": "200 Varick St Frnt B",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.718766,
              -74.002147
          ],
          "Rest_Name": "404 Broadway",
          "id": 1533,
          "address": "404 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.689251,
              -73.992896
          ],
          "Rest_Name": "Court Street",
          "id": 1534,
          "address": "140 Court St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.733578,
              -73.999878
          ],
          "Rest_Name": "Greenwich Village",
          "id": 1535,
          "address": "405 Avenue of the Americas",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.0484,
              -74.0663
          ],
          "Rest_Name": "Montvale",
          "id": 1536,
          "address": "18 Farm Vw",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.742012,
              -74.001121
          ],
          "Rest_Name": "Chelsea West",
          "id": 1537,
          "address": "149 8th Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.722602,
              -73.997529
          ],
          "Rest_Name": "Soho East",
          "id": 1538,
          "address": "71 Spring St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.694341,
              -73.991816
          ],
          "Rest_Name": "Montague Street",
          "id": 1539,
          "address": "185 Montague St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.06675,
              -94.166113
          ],
          "Rest_Name": "University of Arkansas",
          "id": 1540,
          "address": "550 W Dickson St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.726042,
              -73.996472
          ],
          "Rest_Name": "NOHO",
          "id": 1541,
          "address": "625 Broadway",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.908779,
              -74.032069
          ],
          "Rest_Name": "Hackensack Commons",
          "id": 1542,
          "address": "450 Hackensack Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.736472,
              -73.997166
          ],
          "Rest_Name": "13th & 6th",
          "id": 1543,
          "address": "504 Avenue of the Americas",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.731165,
              -73.993436
          ],
          "Rest_Name": "NYU North",
          "id": 1544,
          "address": "55 E 8th St Frnt 2",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.694401,
              -73.986034
          ],
          "Rest_Name": "Metrotech",
          "id": 1545,
          "address": "One Metrotech Center",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.741755,
              -73.993364
          ],
          "Rest_Name": "Wolf Twine",
          "id": 1546,
          "address": "680 Avenue of the Americas",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.689835,
              -73.983866
          ],
          "Rest_Name": "Fulton Street Mall",
          "id": 1547,
          "address": "474 Fulton St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.746042,
              -73.993967
          ],
          "Rest_Name": "283 Seventh Avenue",
          "id": 1548,
          "address": "283 7th Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.752378,
              -73.994305
          ],
          "Rest_Name": "Penn Plaza",
          "id": 1549,
          "address": "304 W 34th St Frnt 2",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.737346,
              -73.990045
          ],
          "Rest_Name": "Union Square North",
          "id": 1550,
          "address": "864 Broadway Frnt 1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.671143,
              -73.977664
          ],
          "Rest_Name": "7th Ave Park Slope",
          "id": 1551,
          "address": "185 7th Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.734232,
              -73.98884
          ],
          "Rest_Name": "Union Square South",
          "id": 1552,
          "address": "117 E 14th St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.317747,
              -74.125412
          ],
          "Rest_Name": "Woodbury Commons",
          "id": 1553,
          "address": "498 Red Apple Ct",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.75566,
              -73.991358
          ],
          "Rest_Name": "601 8th Avenue",
          "id": 1554,
          "address": "601 8th Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.002371,
              -74.042737
          ],
          "Rest_Name": "Hillsdale",
          "id": 1555,
          "address": "441 Hillsdale Ave",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.75958,
              -73.99167
          ],
          "Rest_Name": "Clinton",
          "id": 1556,
          "address": "620 9th Ave",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.744269,
              -73.987877
          ],
          "Rest_Name": "Madison Square Park",
          "id": 1557,
          "address": "234 5th Ave Frnt 1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.7531,
              -73.988874
          ],
          "Rest_Name": "Garment Center",
          "id": 1558,
          "address": "501 Fashion Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.739853,
              -73.985148
          ],
          "Rest_Name": "Gramercy Park",
          "id": 1559,
          "address": "125 E 23rd St Frnt 1",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.74852,
              -73.9852
          ],
          "Rest_Name": "Empire State Relo",
          "id": 1560,
          "address": "350 5th Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.677245,
              -73.972122
          ],
          "Rest_Name": "347 Flatbush",
          "id": 1561,
          "address": "347 Flatbush Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.769756,
              -73.988492
          ],
          "Rest_Name": "John Jay",
          "id": 1562,
          "address": "885 10th Ave",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.608353,
              -73.959572
          ],
          "Rest_Name": "Kings Hwy",
          "id": 1563,
          "address": "1325 Kings Hwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.732991,
              -73.980992
          ],
          "Rest_Name": "Stuyvesant Town",
          "id": 1564,
          "address": "286 1st Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.745453,
              -73.982831
          ],
          "Rest_Name": "Park Avenue South",
          "id": 1565,
          "address": "464 Park Ave S",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.763097,
              -73.985312
          ],
          "Rest_Name": "World Wide Plaza",
          "id": 1566,
          "address": "854 8th Ave Frnt 1",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.7527,
              -73.9833
          ],
          "Rest_Name": "28 W 40th Street",
          "id": 1567,
          "address": "28 W 40th St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.759369,
              -73.982608
          ],
          "Rest_Name": "1221 6th Ave",
          "id": 1568,
          "address": "129 W. 48th St.",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.753988,
              -73.981551
          ],
          "Rest_Name": "Bryant Park",
          "id": 1569,
          "address": "9 W 42nd St Frnt 1",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.692934,
              -73.970141
          ],
          "Rest_Name": "Myrtle Avenue",
          "id": 1570,
          "address": "394 Myrtle Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.751304,
              -73.980609
          ],
          "Rest_Name": "274 Madison Avenue",
          "id": 1571,
          "address": "274 Madison Avenue",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.76602,
              -73.982912
          ],
          "Rest_Name": "Columbus Circle",
          "id": 1572,
          "address": "235 W 56th Street",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.75609,
              -73.980737
          ],
          "Rest_Name": "25 West 45th Street",
          "id": 1573,
          "address": "25 W 45th St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.806988,
              -73.990198
          ],
          "Rest_Name": "Edgewater",
          "id": 1574,
          "address": "14 the Promenade",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.744106,
              -73.97594
          ],
          "Rest_Name": "Kips Bay",
          "id": 1575,
          "address": "604 2nd Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.646772,
              -73.958449
          ],
          "Rest_Name": "1004 Flatbush",
          "id": 1576,
          "address": "1004 Flatbush Ave",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.778906,
              -73.98116
          ],
          "Rest_Name": "Upper West Side",
          "id": 1577,
          "address": "269 Amsterdam Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.763667,
              -73.977938
          ],
          "Rest_Name": "56th & 6th",
          "id": 1578,
          "address": "1379 Avenue of the Americas",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.752158,
              -73.974026
          ],
          "Rest_Name": "Grand Central",
          "id": 1579,
          "address": "150 E 44th St Frnt 1",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.883755,
              -83.287562
          ],
          "Rest_Name": "North Valdosta",
          "id": 1580,
          "address": "3289 INNER PERIMTER RD.",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.757584,
              -73.974248
          ],
          "Rest_Name": "320 Park Ave",
          "id": 1581,
          "address": "320 Park Ave Frnt 5A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.786236,
              -73.977863
          ],
          "Rest_Name": "Broadway & 83rd",
          "id": 1582,
          "address": "2298 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.75728,
              -73.970612
          ],
          "Rest_Name": "E. 52nd Street",
          "id": 1583,
          "address": "150 E 52nd St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.632005,
              -73.947915
          ],
          "Rest_Name": "Nostrand Avenue",
          "id": 1584,
          "address": "2166 Nostrand Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.75895,
              -73.968762
          ],
          "Rest_Name": "55th and 3rd",
          "id": 1585,
          "address": "906 3rd Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.71636,
              -73.96001
          ],
          "Rest_Name": "Williamsburg NY",
          "id": 1586,
          "address": "130 N 4th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.680434,
              -73.952976
          ],
          "Rest_Name": "1190 Fulton",
          "id": 1587,
          "address": "1190 Fulton St",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.762666,
              -73.966089
          ],
          "Rest_Name": "Bloomingdales",
          "id": 1588,
          "address": "1020 3rd Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.795288,
              -73.971718
          ],
          "Rest_Name": "Broadway & 97th",
          "id": 1589,
          "address": "2575 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.766668,
              -73.962605
          ],
          "Rest_Name": "Hunter College",
          "id": 1590,
          "address": "1153 Third Avenue",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.794969,
              -73.965744
          ],
          "Rest_Name": "Columbus Square",
          "id": 1591,
          "address": "805 Columbus Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.80453,
              -73.966788
          ],
          "Rest_Name": "Columbia Univ",
          "id": 1592,
          "address": "2843 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.765974,
              -73.957107
          ],
          "Rest_Name": "69th and 1st",
          "id": 1593,
          "address": "1288 1st Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.69989,
              -73.941059
          ],
          "Rest_Name": "Bushwick",
          "id": 1594,
          "address": "774 Broadway",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.777727,
              -73.954542
          ],
          "Rest_Name": "Upper East Side",
          "id": 1595,
          "address": "1497 3rd Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.861216,
              -73.970513
          ],
          "Rest_Name": "Fort Lee",
          "id": 1596,
          "address": "134 Linwood Plaza",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.895324,
              -73.976161
          ],
          "Rest_Name": "Englewood NJ",
          "id": 1597,
          "address": "10 Nathaniel Pl",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.609819,
              -73.921613
          ],
          "Rest_Name": "Kings Plaza",
          "id": 1598,
          "address": "5102 Avenue U Ste 2223",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.669947,
              -73.931405
          ],
          "Rest_Name": "Utica Avenue",
          "id": 1599,
          "address": "250 Utica Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.74724,
              -73.941628
          ],
          "Rest_Name": "Long Island City",
          "id": 1600,
          "address": "26-16 Jackson Avenue",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.822211,
              -73.953161
          ],
          "Rest_Name": "West 138th Street",
          "id": 1601,
          "address": "3410 Broadway",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.626429,
              -73.916943
          ],
          "Rest_Name": "Ralph Avenue",
          "id": 1602,
          "address": "2179 Ralph Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.097514,
              -74.012496
          ],
          "Rest_Name": "Nanuet",
          "id": 1603,
          "address": "79 Rockland Plaza",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.807288,
              -73.945063
          ],
          "Rest_Name": "125th Street",
          "id": 1604,
          "address": "72 W 125th St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.798472,
              -73.940834
          ],
          "Rest_Name": "East Harlem",
          "id": 1605,
          "address": "165-167 East 116th Street",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.834343,
              -73.945099
          ],
          "Rest_Name": "Hamilton Hts",
          "id": 1606,
          "address": "3781 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.841512,
              -73.939878
          ],
          "Rest_Name": "Columbia Presbyterian",
          "id": 1607,
          "address": "4009 Broadway",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.840725,
              -83.322314
          ],
          "Rest_Name": "Valdosta GA",
          "id": 1608,
          "address": "1108 N Saint Augustine Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.849592,
              -73.935878
          ],
          "Rest_Name": "Washington Hts",
          "id": 1609,
          "address": "4240 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.759379,
              -73.918315
          ],
          "Rest_Name": "Steinway Street",
          "id": 1610,
          "address": "3173 Steinway St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.819096,
              -73.927409
          ],
          "Rest_Name": "Grand Concourse",
          "id": 1611,
          "address": "557 Grand Concourse",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.725698,
              -73.909447
          ],
          "Rest_Name": "Maspeth",
          "id": 1612,
          "address": "5901 Maurice Ave",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.969988,
              -73.955088
          ],
          "Rest_Name": "Closter",
          "id": 1613,
          "address": "83 Ver Valen St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.776119,
              -73.911298
          ],
          "Rest_Name": "31st & Ditmars",
          "id": 1614,
          "address": "2206 31st St",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.658474,
              -73.889958
          ],
          "Rest_Name": "East New York",
          "id": 1615,
          "address": "1940 Linden Blvd",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.825455,
              -73.919076
          ],
          "Rest_Name": "161st Street",
          "id": 1616,
          "address": "260 E 161st St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.816907,
              -73.915935
          ],
          "Rest_Name": "2902 Third Avenue",
          "id": 1617,
          "address": "2902 3rd Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.839717,
              -73.917593
          ],
          "Rest_Name": "E 170th & Jerome Avenue",
          "id": 1618,
          "address": "22 E 170th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.74662,
              -73.883492
          ],
          "Rest_Name": "82nd Street Queens",
          "id": 1619,
          "address": "40-37 82nd St ",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.097879,
              -73.957833
          ],
          "Rest_Name": "Palisades Center",
          "id": 1620,
          "address": "1000 Palisades Center Dr Spc A402",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.878496,
              -73.904408
          ],
          "Rest_Name": "Kingsbridge",
          "id": 1621,
          "address": "190 W 231st St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.755822,
              -73.880207
          ],
          "Rest_Name": "Jackson Heights",
          "id": 1622,
          "address": "8710 Northern Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.735686,
              -73.87485
          ],
          "Rest_Name": "Queens Place",
          "id": 1623,
          "address": "8801 Queens Blvd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.82373,
              -73.891269
          ],
          "Rest_Name": "Southern Blvd",
          "id": 1624,
          "address": "1030 Southern Blvd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.884361,
              -73.899818
          ],
          "Rest_Name": "Riverdale",
          "id": 1625,
          "address": "193 W 237th St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.734521,
              -73.870798
          ],
          "Rest_Name": "Queens Center",
          "id": 1626,
          "address": "9015 Queens Blvd Ste FC12",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.861594,
              -73.893211
          ],
          "Rest_Name": "East Fordham Road",
          "id": 1627,
          "address": "350 E Fordham Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.750253,
              -73.869885
          ],
          "Rest_Name": "Corona Queens",
          "id": 1628,
          "address": "3747 Junction Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.860223,
              -73.890728
          ],
          "Rest_Name": "Fordham",
          "id": 1629,
          "address": "4760 3rd Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.733128,
              -73.86303
          ],
          "Rest_Name": "Rego Center",
          "id": 1630,
          "address": "6135 Junction Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.967069,
              -95.236151
          ],
          "Rest_Name": "Mass Ave",
          "id": 1631,
          "address": "911 Massachusetts St Ste A",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.656944,
              -73.83996
          ],
          "Rest_Name": "Howard Beach",
          "id": 1632,
          "address": "16010 Crossbay Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.720314,
              -73.845939
          ],
          "Rest_Name": "Forest Hills Queens",
          "id": 1633,
          "address": "7030 Austin St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.8562,
              -73.867474
          ],
          "Rest_Name": "White Plains Road",
          "id": 1634,
          "address": "2190 White Plains Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.94318,
              -95.251918
          ],
          "Rest_Name": "Lawrence - 23rd Street",
          "id": 1635,
          "address": "1420 W 23rd St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.841407,
              -73.843824
          ],
          "Rest_Name": "Westchester Square",
          "id": 1636,
          "address": "35 Westchester Sq",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.760348,
              -73.827981
          ],
          "Rest_Name": "Flushing",
          "id": 1637,
          "address": "13661 Roosevelt Ave",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.849546,
              -73.84263
          ],
          "Rest_Name": "Hutch Metro Center",
          "id": 1638,
          "address": "1776 Eastchester Rd",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.823586,
              -73.837404
          ],
          "Rest_Name": "Throggs Neck S.C.",
          "id": 1639,
          "address": "815 Hutchinson River Pkwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.926886,
              -73.853534
          ],
          "Rest_Name": "Cross County S.C.",
          "id": 1640,
          "address": "5510 Xavier Dr Spc 5A10",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.376284,
              -73.963022
          ],
          "Rest_Name": "West Point-Highland Falls",
          "id": 1641,
          "address": "521A Main St",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.700725,
              -73.806434
          ],
          "Rest_Name": "Archer Avenue",
          "id": 1642,
          "address": "14740 Archer Ave",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.703535,
              -73.798799
          ],
          "Rest_Name": "Jamaica Avenue",
          "id": 1643,
          "address": "16002 Jamaica Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.001973,
              -73.857727
          ],
          "Rest_Name": "Dobbs Ferry",
          "id": 1644,
          "address": "12 Lawrence St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.972643,
              -95.289387
          ],
          "Rest_Name": "West Lawrence",
          "id": 1645,
          "address": "4000 W 6th St Ste I",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.868836,
              -73.826257
          ],
          "Rest_Name": "Bay Plaza",
          "id": 1646,
          "address": "2122 Bartow Avenue",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.892511,
              -73.818514
          ],
          "Rest_Name": "Pelham Manor",
          "id": 1647,
          "address": "798 Pelham Pkwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.738873,
              -92.892639
          ],
          "Rest_Name": "Hastings",
          "id": 1648,
          "address": "1769 N Frontage Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.993003,
              -73.820923
          ],
          "Rest_Name": "Midway Shopping Center",
          "id": 1649,
          "address": "913 Central Park Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.948604,
              -73.792398
          ],
          "Rest_Name": "New Rochelle",
          "id": 1650,
          "address": "77 Quaker Ridge Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.617896,
              -73.731298
          ],
          "Rest_Name": "Lawrence NY",
          "id": 1651,
          "address": "331 Rockaway Tpke",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.312668,
              -73.869829
          ],
          "Rest_Name": "Cortlandt Crossing",
          "id": 1652,
          "address": "3144 E. Main Street",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.042959,
              -73.797262
          ],
          "Rest_Name": "White Plains, Rte 119",
          "id": 1653,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.663271,
              -73.719247
          ],
          "Rest_Name": "Valley Stream",
          "id": 1654,
          "address": "2034 Green Acres Mall",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.765265,
              -73.725261
          ],
          "Rest_Name": "Little Neck",
          "id": 1655,
          "address": "25465 Nassau Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.785549,
              -73.727394
          ],
          "Rest_Name": "Great Neck",
          "id": 1656,
          "address": "44 Great Neck Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.959411,
              -92.720856
          ],
          "Rest_Name": "Hudson WI",
          "id": 1657,
          "address": "1021 Pearson Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.708132,
              -73.707054
          ],
          "Rest_Name": "Elmont",
          "id": 1658,
          "address": "620 Hempstead Tpke",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.033315,
              -73.764409
          ],
          "Rest_Name": "Downtown White Plains",
          "id": 1659,
          "address": "250 Main St Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.528046,
              -73.894316
          ],
          "Rest_Name": "Fishkill",
          "id": 1660,
          "address": "10 Westage Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.956648,
              -73.736832
          ],
          "Rest_Name": "Mamaroneck Centre",
          "id": 1661,
          "address": "805 Mamaroneck Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.758225,
              -73.689868
          ],
          "Rest_Name": "Lake Success",
          "id": 1662,
          "address": "2335 New Hyde Park Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.728053,
              -73.931267
          ],
          "Rest_Name": "Poughkeepsie Hudson Heritage",
          "id": 1663,
          "address": "15-55 Winslow Gate Rd ",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.634489,
              -73.639692
          ],
          "Rest_Name": "Oceanside LI",
          "id": 1664,
          "address": "3161 Long Beach Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.744779,
              -73.655976
          ],
          "Rest_Name": "Mineola",
          "id": 1665,
          "address": "528 Jericho Tpke Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.832866,
              -92.957598
          ],
          "Rest_Name": "80th St - Cottage Grove",
          "id": 1666,
          "address": "7180 E Point Douglas Rd S Ste 300",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.966453,
              -73.989844
          ],
          "Rest_Name": "Kingston",
          "id": 1667,
          "address": "1217 Ulster Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.003575,
              -73.683276
          ],
          "Rest_Name": "Rye Brook",
          "id": 1668,
          "address": "100 S Ridge St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.216179,
              -73.721603
          ],
          "Rest_Name": "Mt Kisco Route 117",
          "id": 1669,
          "address": "195 N Bedford Rd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.743426,
              -73.618029
          ],
          "Rest_Name": "Carle Place",
          "id": 1670,
          "address": "135 Old Country Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.739971,
              -73.613576
          ],
          "Rest_Name": "Roosevelt Field Mall",
          "id": 1671,
          "address": "630 Old Country Rd Unit 1047",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.731298,
              -93.13359
          ],
          "Rest_Name": "Rosemount",
          "id": 1672,
          "address": "3390 150th St W",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.944625,
              -92.90468
          ],
          "Rest_Name": "Lake Elmo",
          "id": 1673,
          "address": "9965 Hudson Pl Ste 650",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.717959,
              -73.592126
          ],
          "Rest_Name": "Uniondale",
          "id": 1674,
          "address": "1166 Hempstead Tpke",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.924735,
              -92.955012
          ],
          "Rest_Name": "Woodbury",
          "id": 1675,
          "address": "7455 Currell Blvd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.749361,
              -73.579178
          ],
          "Rest_Name": "Westbury",
          "id": 1676,
          "address": "474 Old Country Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.035646,
              -92.837415
          ],
          "Rest_Name": "Stillwater",
          "id": 1677,
          "address": "13315 60th St N",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.65564,
              -73.549797
          ],
          "Rest_Name": "Merrick",
          "id": 1678,
          "address": "1996 Merrick Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.86631,
              -93.05581
          ],
          "Rest_Name": "Inver Grove Heights",
          "id": 1679,
          "address": "5697 Blaine Ave E",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.724617,
              -73.550529
          ],
          "Rest_Name": "East Meadow",
          "id": 1680,
          "address": "2312 Hempstead Tpke",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.504443,
              -84.249691
          ],
          "Rest_Name": "Carriage Gate Tallahassee",
          "id": 1681,
          "address": "3425 Thomasville Rd Unit 1",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.731145,
              -93.219372
          ],
          "Rest_Name": "Apple Valley",
          "id": 1682,
          "address": "7638 150th St W",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.757303,
              -93.213697
          ],
          "Rest_Name": "Orchard Place",
          "id": 1683,
          "address": "15600 English Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.694106,
              -93.285727
          ],
          "Rest_Name": "Lakeville",
          "id": 1684,
          "address": "17599 Kenwood Trl Ste 100",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.774769,
              -73.526601
          ],
          "Rest_Name": "Hicksville",
          "id": 1685,
          "address": "215 N Broadway",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.04235,
              -73.578371
          ],
          "Rest_Name": "Riverside Commons",
          "id": 1686,
          "address": "1233 E Putnam Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.890162,
              -93.081144
          ],
          "Rest_Name": "Robert Street",
          "id": 1687,
          "address": "1857 Robert St S",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.694265,
              -73.508369
          ],
          "Rest_Name": "Wantagh",
          "id": 1688,
          "address": "1178 Wantagh Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.835976,
              -93.151959
          ],
          "Rest_Name": "Eagan",
          "id": 1689,
          "address": "3324 Promenade Ave Ste 107",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.790697,
              -93.211784
          ],
          "Rest_Name": "South Eagan",
          "id": 1690,
          "address": "2075 Cliff Road",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.725668,
              -73.49987
          ],
          "Rest_Name": "Levittown",
          "id": 1691,
          "address": "3629 Hempstead Tpke",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.809887,
              -73.500386
          ],
          "Rest_Name": "Syosset",
          "id": 1692,
          "address": "420 Jericho Tpke",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.74756,
              -93.288722
          ],
          "Rest_Name": "Burnhaven",
          "id": 1693,
          "address": "728 County Road 42 W",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.448801,
              -84.311136
          ],
          "Rest_Name": "Florida State University",
          "id": 1694,
          "address": "1801 W Tennessee St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.110501,
              -73.545624
          ],
          "Rest_Name": "Stamford High Ridge",
          "id": 1695,
          "address": "1207 High Ridge Road",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.945463,
              -93.095372
          ],
          "Rest_Name": "Lawson",
          "id": 1696,
          "address": "29 5th St W",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.43616,
              -84.258393
          ],
          "Rest_Name": "Governors Square Tallahassee",
          "id": 1697,
          "address": "1498 Apalachee Pkwy Unit 16",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.010412,
              -93.023837
          ],
          "Rest_Name": "Maplewood",
          "id": 1698,
          "address": "2303 White Bear Ave N Ste H",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.435213,
              -84.294207
          ],
          "Rest_Name": "Stadium Centre at FSU",
          "id": 1699,
          "address": "699 W Gaines St",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.035551,
              -93.019509
          ],
          "Rest_Name": "North Maplewood",
          "id": 1700,
          "address": "3095 White Bear Ave N",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.01694,
              -95.6514
          ],
          "Rest_Name": "Topeka - 29th St",
          "id": 1701,
          "address": "2001 SE 28th St.",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.940113,
              -93.136826
          ],
          "Rest_Name": "Grand Ave.",
          "id": 1702,
          "address": "867 Grand Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.853598,
              -93.242074
          ],
          "Rest_Name": "Mall of America MN",
          "id": 1703,
          "address": "322 S Avenue",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.0862,
              -95.66431
          ],
          "Rest_Name": "North Topeka",
          "id": 1704,
          "address": "2014 NW Topeka Blvd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.726763,
              -73.423033
          ],
          "Rest_Name": "Farmingdale",
          "id": 1705,
          "address": "901 Broadhollow Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.917524,
              -93.188556
          ],
          "Rest_Name": "Highland Crossing",
          "id": 1706,
          "address": "2082 Ford Pkwy",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.749682,
              -93.379868
          ],
          "Rest_Name": "Savage",
          "id": 1707,
          "address": "14008 Highway 13 S",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.166301,
              -93.951396
          ],
          "Rest_Name": "Mankato 2",
          "id": 1708,
          "address": "1851 Madison Ave Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.811215,
              -93.329092
          ],
          "Rest_Name": "Bloomington MN",
          "id": 1709,
          "address": "10629 France Ave S",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.883954,
              -93.248529
          ],
          "Rest_Name": "Richfield 2",
          "id": 1710,
          "address": "1830 E 66th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.814802,
              -73.41115
          ],
          "Rest_Name": "Huntington Station",
          "id": 1711,
          "address": "435 Walt Whitman Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.080578,
              -73.461234
          ],
          "Rest_Name": "I95 NB Darien",
          "id": 1712,
          "address": "I-95 Darien NB Service Plaza",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.051991,
              -93.063298
          ],
          "Rest_Name": "Vadnais Heights",
          "id": 1713,
          "address": "925 County Road E E Ste 150",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.359955,
              -94.363702
          ],
          "Rest_Name": "Fort Smith",
          "id": 1714,
          "address": "6205 Rogers Ave",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.962673,
              -74.187023
          ],
          "Rest_Name": "Amsterdam",
          "id": 1715,
          "address": "110 Veeders Ml",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.146167,
              -93.994052
          ],
          "Rest_Name": "Mankato",
          "id": 1716,
          "address": "1600 Warren St Ste 1",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.090926,
              -73.45563
          ],
          "Rest_Name": "Darien CT",
          "id": 1717,
          "address": "71 Post Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.86401,
              -93.288867
          ],
          "Rest_Name": "Richfield",
          "id": 1718,
          "address": "7644 Lyndale Ave S Ste 200",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.701764,
              -73.378473
          ],
          "Rest_Name": "Lindenhurst",
          "id": 1719,
          "address": "96 Sunrise Hwy",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.392428,
              -73.522496
          ],
          "Rest_Name": "Danbury, Mill Plain Road",
          "id": 1720,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.776314,
              -93.414129
          ],
          "Rest_Name": "Shakopee MN",
          "id": 1721,
          "address": "8094 Old Carriage Ct",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.879146,
              -93.320556
          ],
          "Rest_Name": "Edina",
          "id": 1722,
          "address": "6801 York Ave S",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.012778,
              -93.170237
          ],
          "Rest_Name": "Roseville",
          "id": 1723,
          "address": "860 Rosedale Shopping Ctr ",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.022001,
              -93.168834
          ],
          "Rest_Name": "Roseville - Snelling Ave",
          "id": 1724,
          "address": "2720 Lincoln Dr",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.380305,
              -73.479398
          ],
          "Rest_Name": "Danbury Fair Mall",
          "id": 1725,
          "address": "7 Backus Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.973462,
              -93.22691
          ],
          "Rest_Name": "Stadium Village",
          "id": 1726,
          "address": "800 Washington Ave SE",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.17797,
              -94.03398
          ],
          "Rest_Name": "North Mankato",
          "id": 1727,
          "address": "1725 Lor Ray Drive",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.058092,
              -93.143527
          ],
          "Rest_Name": "Shoreview",
          "id": 1728,
          "address": "1021 Red Fox Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.973058,
              -93.247025
          ],
          "Rest_Name": "7 Corners",
          "id": 1729,
          "address": "229 Cedar Ave S",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.758877,
              -73.327532
          ],
          "Rest_Name": "Deer Park ",
          "id": 1730,
          "address": "1831 Deer Park Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.029533,
              -95.761236
          ],
          "Rest_Name": "Wanamaker",
          "id": 1731,
          "address": "2040 SW Wanamaker Rd Ste 103",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.976898,
              -93.267942
          ],
          "Rest_Name": "200 South Sixth",
          "id": 1732,
          "address": "200 S. Sixth St Ste 100",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.988006,
              -93.256909
          ],
          "Rest_Name": "St. Anthony",
          "id": 1733,
          "address": "225 E Hennepin Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.973535,
              -93.27533
          ],
          "Rest_Name": "Nicollet Mall",
          "id": 1734,
          "address": "1040 Nicollet Mall",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.955352,
              -93.297214
          ],
          "Rest_Name": "Hennepin",
          "id": 1735,
          "address": "2600 Hennepin Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.978246,
              -93.271806
          ],
          "Rest_Name": "Skyway",
          "id": 1736,
          "address": "50 S 6th St Ste 240",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.764668,
              -73.306534
          ],
          "Rest_Name": "Tanger-Deer Park",
          "id": 1737,
          "address": "1090 the Arches Cir",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.839046,
              -73.315956
          ],
          "Rest_Name": "East Northport NY",
          "id": 1738,
          "address": "4000 Jericho Tpke",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.94747,
              -93.321226
          ],
          "Rest_Name": "Cedar Commons",
          "id": 1739,
          "address": "3040 Excelsior Blvd Ste 104",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.038909,
              -93.219786
          ],
          "Rest_Name": "Silver Lake",
          "id": 1740,
          "address": "2701 39th Ave NE Ste E128",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.781348,
              -93.508241
          ],
          "Rest_Name": "West Shakopee",
          "id": 1741,
          "address": "1120 Vierling Dr E",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.930205,
              -93.350442
          ],
          "Rest_Name": "St Louis Park Relo",
          "id": 1742,
          "address": "5580 Excelsior Blvd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.811233,
              -73.294142
          ],
          "Rest_Name": "Commack",
          "id": 1743,
          "address": "660 Commack Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.859244,
              -93.442869
          ],
          "Rest_Name": "Eden Prairie",
          "id": 1744,
          "address": "13250 Technology Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.41013,
              -73.412883
          ],
          "Rest_Name": "Danbury Newtown Road",
          "id": 1745,
          "address": "81 Newtown Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.894066,
              -93.419739
          ],
          "Rest_Name": "Shady Oak",
          "id": 1746,
          "address": "10995 Red Circle Dr",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.625168,
              -81.542989
          ],
          "Rest_Name": "Yulee",
          "id": 1747,
          "address": "463913 State Road 200 Ste 8",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.062711,
              -93.248297
          ],
          "Rest_Name": "694 & Central",
          "id": 1748,
          "address": "5200 Central Ave NE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.736775,
              -73.247641
          ],
          "Rest_Name": "Bay Shore",
          "id": 1749,
          "address": "1675 Sunrise Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.924186,
              -93.409958
          ],
          "Rest_Name": "Hopkins",
          "id": 1750,
          "address": "786 Mainstreet",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.278227,
              -92.998222
          ],
          "Rest_Name": "Forest Lake",
          "id": 1751,
          "address": "107 12th St SW Ste 1",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.550104,
              -73.415585
          ],
          "Rest_Name": "New Milford",
          "id": 1752,
          "address": "169 Danbury Rd Bldg 101",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.807293,
              -73.238103
          ],
          "Rest_Name": "Motor Parkway",
          "id": 1753,
          "address": "694 Motor Pkwy Ste 1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.773265,
              -73.894019
          ],
          "Rest_Name": "Niskayuna",
          "id": 1754,
          "address": "441 Balltown Rd Ste 3",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.984482,
              -93.381111
          ],
          "Rest_Name": "Golden Valley MN",
          "id": 1755,
          "address": "515 Winnetka Ave N",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.426197,
              -86.640595
          ],
          "Rest_Name": "Ft. Walton",
          "id": 1756,
          "address": "227 Carol Ave NW",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.390646,
              -86.424125
          ],
          "Rest_Name": "Destin Commons",
          "id": 1757,
          "address": "4281 Legendary Dr # J-114",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.682875,
              -73.838574
          ],
          "Rest_Name": "Stuyvesant Plaza",
          "id": 1758,
          "address": "1475 Western Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.866718,
              -73.933248
          ],
          "Rest_Name": "Glenville",
          "id": 1759,
          "address": "231 Saratoga Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.533027,
              -87.227573
          ],
          "Rest_Name": "9 Mile",
          "id": 1760,
          "address": "1674 E Nine Mile Rd Ste A",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.859373,
              -93.534467
          ],
          "Rest_Name": "Chanhassen",
          "id": 1761,
          "address": "560 W 79th St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.601755,
              -73.794693
          ],
          "Rest_Name": "Glenmont",
          "id": 1762,
          "address": "9 Moriah Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.059152,
              -93.320954
          ],
          "Rest_Name": "Brooklyn Center",
          "id": 1763,
          "address": "5711 Xerxes Ave N Ste K",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.967347,
              -93.437982
          ],
          "Rest_Name": "Ridgedale Center",
          "id": 1764,
          "address": "12509 Wayzata Blvd Ste 1305",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.127286,
              -93.25675
          ],
          "Rest_Name": "Northtown MN",
          "id": 1765,
          "address": "599 Northtown Dr NE Ste 500",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.828072,
              -73.201332
          ],
          "Rest_Name": "Nesconset Highway",
          "id": 1766,
          "address": "387 Smithtown Byp",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.918029,
              -93.502459
          ],
          "Rest_Name": "Minnetonka 7 & 101",
          "id": 1767,
          "address": "4717 County Road 101",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.160472,
              -93.233823
          ],
          "Rest_Name": "Blaine",
          "id": 1768,
          "address": "10450 Baltimore St NE Ste 100",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.834868,
              -93.601277
          ],
          "Rest_Name": "Chaska",
          "id": 1769,
          "address": "1112 Hazeltine Blvd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.055094,
              -93.364971
          ],
          "Rest_Name": "Crystal",
          "id": 1770,
          "address": "5608 W Broadway Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.716033,
              -73.810012
          ],
          "Rest_Name": "Wolf Road",
          "id": 1771,
          "address": "105 Wolf Road",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.654297,
              -73.773117
          ],
          "Rest_Name": "Albany New Scotland Ave",
          "id": 1772,
          "address": "17 New Scotland Ave Ste 112",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.157041,
              -73.240621
          ],
          "Rest_Name": "Fairfield CT",
          "id": 1773,
          "address": "340 Grasmere Ave Ste 1",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.195718,
              -93.236486
          ],
          "Rest_Name": "North Blaine",
          "id": 1774,
          "address": "12409 Ulysses St NE",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.971462,
              -93.496635
          ],
          "Rest_Name": "Wayzata MN",
          "id": 1775,
          "address": "1313 Wayzata Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.474259,
              -87.209737
          ],
          "Rest_Name": "Pensacola-Cordova Mall",
          "id": 1776,
          "address": "5100 N 9th Ave Ste E541",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.094135,
              -93.383211
          ],
          "Rest_Name": "Brooklyn Park",
          "id": 1777,
          "address": "7631 Jolly Ln",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.755726,
              -73.775974
          ],
          "Rest_Name": "Latham Route 7",
          "id": 1778,
          "address": "2 Wade Road",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.124552,
              -93.357518
          ],
          "Rest_Name": "610 & Zane",
          "id": 1779,
          "address": "5901 94th Ave N Ste 101",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.02058,
              -93.482526
          ],
          "Rest_Name": "Plymouth",
          "id": 1780,
          "address": "3425 Vicksburg Ln N Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.30217,
              -73.22381
          ],
          "Rest_Name": "Monroe CT",
          "id": 1781,
          "address": "143 Purdy Hill Road",
          "rating": 1.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.641537,
              -73.70131
          ],
          "Rest_Name": "East Greenbush",
          "id": 1782,
          "address": "279 Troy Rd Ste 5",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.481461,
              -81.637563
          ],
          "Rest_Name": "River City Pointe Place",
          "id": 1783,
          "address": "790 Skymarks Dr Ste 101",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.178449,
              -73.181922
          ],
          "Rest_Name": "Bridgeport Steelpointe",
          "id": 1784,
          "address": "275 E Main St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.675336,
              -88.143691
          ],
          "Rest_Name": "Mobile",
          "id": 1785,
          "address": "3871 Airport Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.1827,
              -93.3216
          ],
          "Rest_Name": "Coon Rapids - Northdale",
          "id": 1786,
          "address": "2170 Northdale Blvd NW",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.866515,
              -73.110711
          ],
          "Rest_Name": "Smith Haven Plaza",
          "id": 1787,
          "address": "2093 Smith Haven Plz",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.764454,
              -73.09185
          ],
          "Rest_Name": "Sayville",
          "id": 1788,
          "address": "5181 Sunrise Hwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.597425,
              -87.898844
          ],
          "Rest_Name": "Daphne",
          "id": 1789,
          "address": "914 Van Ave",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.095678,
              -93.44131
          ],
          "Rest_Name": "Arbor Lakes",
          "id": 1790,
          "address": "7750 Main St N",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.218221,
              -85.873395
          ],
          "Rest_Name": "Pier Park",
          "id": 1791,
          "address": "100 S Pier Park Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.681527,
              -88.224163
          ],
          "Rest_Name": "Westwood Plaza",
          "id": 1792,
          "address": "7765 Airport Blvd Ste D100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.831945,
              -73.088934
          ],
          "Rest_Name": "Lake Ronkonkoma",
          "id": 1793,
          "address": "601 Portion Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.860239,
              -73.780359
          ],
          "Rest_Name": "Clifton Park",
          "id": 1794,
          "address": "22 Clifton Country Rd Ste 150",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.189106,
              -85.653816
          ],
          "Rest_Name": "Panama City",
          "id": 1795,
          "address": "500 E 23rd St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.158322,
              -93.390783
          ],
          "Rest_Name": "Champlin",
          "id": 1796,
          "address": "11209 Aquila Dr N",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.198771,
              -93.354817
          ],
          "Rest_Name": "Coon Rapids",
          "id": 1797,
          "address": "3455 River Rapids Dr NW",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.479177,
              -73.210364
          ],
          "Rest_Name": "Southbury",
          "id": 1798,
          "address": "100 Main St N",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.51071,
              -93.00077
          ],
          "Rest_Name": "North Branch",
          "id": 1799,
          "address": "5330 St Croix Trail ",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.202238,
              -73.117218
          ],
          "Rest_Name": "Stratford",
          "id": 1800,
          "address": "100 E Main St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.7778,
              -73.031907
          ],
          "Rest_Name": "Patchogue",
          "id": 1801,
          "address": "367 N Service Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.133612,
              -93.476855
          ],
          "Rest_Name": "Maple Grove",
          "id": 1802,
          "address": "9881 Maple Grove Pkwy N",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.27582,
              -73.124023
          ],
          "Rest_Name": "Shelton",
          "id": 1803,
          "address": "701 Bridgeport Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.157884,
              -95.934753
          ],
          "Rest_Name": "Wolf Creek",
          "id": 1804,
          "address": "10403 S 15th St Ste 106",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.91881,
              -73.040855
          ],
          "Rest_Name": "Port Jefferson",
          "id": 1805,
          "address": "1064 Route 112",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.867859,
              -73.025935
          ],
          "Rest_Name": "Selden",
          "id": 1806,
          "address": "261 Middle Country Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.358084,
              -87.165792
          ],
          "Rest_Name": "Gulf Breeze",
          "id": 1807,
          "address": "725 Gulf Breeze Parkway",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.180902,
              -82.681702
          ],
          "Rest_Name": "Lake City",
          "id": 1808,
          "address": "3017 W US Highway 90",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.261535,
              -95.979917
          ],
          "Rest_Name": "Omaha - Midtown",
          "id": 1809,
          "address": "333 N Saddle Creek Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.238459,
              -73.035389
          ],
          "Rest_Name": "Connecticut Post Mall",
          "id": 1810,
          "address": "1319 Boston Post Rd Spc 4B",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.162401,
              -96.023306
          ],
          "Rest_Name": "Settler Creek",
          "id": 1811,
          "address": "9820 S 71st Plz Ste 117",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.103951,
              -73.74134
          ],
          "Rest_Name": "Wilton",
          "id": 1812,
          "address": "3057 Route 50 Ste 5",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.200739,
              -93.553948
          ],
          "Rest_Name": "Rogers",
          "id": 1813,
          "address": "13590 Northdale Blvd Ste 300",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.324183,
              -81.670311
          ],
          "Rest_Name": "Riverside Ave Jacksonville",
          "id": 1814,
          "address": "50 Riverside Ave Ste 705",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.258773,
              -96.023232
          ],
          "Rest_Name": "72nd Crossing",
          "id": 1815,
          "address": "201 S 72nd St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.425428,
              -75.693098
          ],
          "Rest_Name": "Rideau Centre",
          "id": 1816,
          "address": "201C-50 Rideau Rue",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.271229,
              -72.972761
          ],
          "Rest_Name": "West Haven, I95",
          "id": 1817,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.372448,
              -87.683802
          ],
          "Rest_Name": "Foley",
          "id": 1818,
          "address": "2862 S. McKenzie Street",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.319345,
              -81.55503
          ],
          "Rest_Name": "Regency Jacksonville",
          "id": 1819,
          "address": "9395 Atlantic Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.278023,
              -81.719544
          ],
          "Rest_Name": "Roosevelt Square",
          "id": 1820,
          "address": "4495 Roosevelt Blvd Ste 611",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.800107,
              -72.869079
          ],
          "Rest_Name": "Shirley",
          "id": 1821,
          "address": "800 Montauk Hwy",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.32216,
              -81.49311
          ],
          "Rest_Name": "Atlantic Blvd. & Kernan",
          "id": 1822,
          "address": "11919 Atlantic Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.266998,
              -96.073593
          ],
          "Rest_Name": "Westroads",
          "id": 1823,
          "address": "808 N 102nd St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.293697,
              -81.604056
          ],
          "Rest_Name": "Beach and University",
          "id": 1824,
          "address": "5801 Beach Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.817207,
              -73.092076
          ],
          "Rest_Name": "Torrington",
          "id": 1825,
          "address": "1313 E Main St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.30128,
              -93.555077
          ],
          "Rest_Name": "Elk River",
          "id": 1826,
          "address": "18201 Carson Ct NW",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.306412,
              -72.926701
          ],
          "Rest_Name": "Yale",
          "id": 1827,
          "address": "910 Chapel St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.262889,
              -81.62294
          ],
          "Rest_Name": "University&Phillips Highway",
          "id": 1828,
          "address": "3860 University Blvd W",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.233724,
              -96.120335
          ],
          "Rest_Name": "Baker Square",
          "id": 1829,
          "address": "13203 W Center Rd",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.19729,
              -96.13978
          ],
          "Rest_Name": "Stony Brook",
          "id": 1830,
          "address": "14415 STONY BROOK BLVD.",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.287145,
              -81.460377
          ],
          "Rest_Name": "Beach and Hodges",
          "id": 1831,
          "address": "13546 Beach Blvd Ste 1",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.369121,
              -72.918123
          ],
          "Rest_Name": "Hamden",
          "id": 1832,
          "address": "2165 Dixwell Ave Ste 2",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.196359,
              -81.829889
          ],
          "Rest_Name": "Oakleaf",
          "id": 1833,
          "address": "9671 Argyle Forest Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.254629,
              -81.532771
          ],
          "Rest_Name": "Markets at Town Center",
          "id": 1834,
          "address": "4865 Town Center Pkwy Ste 1",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.291723,
              -96.142879
          ],
          "Rest_Name": "West Maple",
          "id": 1835,
          "address": "3605 N 147th St Ste 111",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.19383,
              -81.738462
          ],
          "Rest_Name": "Orange Park",
          "id": 1836,
          "address": "8635 Blanding Blvd Ste 102",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.271266,
              -81.388854
          ],
          "Rest_Name": "Jacksonville Beach",
          "id": 1837,
          "address": "1758 3rd St S",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.220468,
              -81.5678
          ],
          "Rest_Name": "Baymeadows",
          "id": 1838,
          "address": "9172 Baymeadows Rd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.233649,
              -96.19198
          ],
          "Rest_Name": "Legacy West",
          "id": 1839,
          "address": "2717 S 177th St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.332589,
              -73.675548
          ],
          "Rest_Name": "Queensbury",
          "id": 1840,
          "address": "820 State Route 9",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.14733,
              -96.21173
          ],
          "Rest_Name": "Gretna",
          "id": 1841,
          "address": "19005 OAKMONT DRIVE",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.27763,
              -72.827453
          ],
          "Rest_Name": "Branford",
          "id": 1842,
          "address": "2 Commercial Pkwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.466536,
              -73.196212
          ],
          "Rest_Name": "Pittsfield",
          "id": 1843,
          "address": "555 Hubbard Ave",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.173643,
              -81.627794
          ],
          "Rest_Name": "Mandarin Riverplace",
          "id": 1844,
          "address": "11113 San Jose Blvd Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.142174,
              -81.767095
          ],
          "Rest_Name": "South Orange Park",
          "id": 1845,
          "address": "876 Blanding Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.26584,
              -96.237002
          ],
          "Rest_Name": "Elkhorn",
          "id": 1846,
          "address": "820 N 205th St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.698837,
              -72.894966
          ],
          "Rest_Name": "Bristol",
          "id": 1847,
          "address": "1444 Farmington Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.638481,
              -72.873199
          ],
          "Rest_Name": "Southington",
          "id": 1848,
          "address": "785 Queen St Ste 1",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.92577,
              -72.692128
          ],
          "Rest_Name": "Riverhead",
          "id": 1849,
          "address": "1501 Old Country Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.497032,
              -72.806633
          ],
          "Rest_Name": "Wallingford",
          "id": 1850,
          "address": "1094 N Colony Rd Ste 20",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.82352,
              -72.88427
          ],
          "Rest_Name": "Shoppes at Farmington Valley",
          "id": 1851,
          "address": "110 Albany Tpke",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.141352,
              -81.546654
          ],
          "Rest_Name": "Bartram Park",
          "id": 1852,
          "address": "13920 Old Saint Augustine Rd Ste 105",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.101669,
              -81.715524
          ],
          "Rest_Name": "Fleming Island",
          "id": 1853,
          "address": "1607 County Road 220",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.725602,
              -72.759226
          ],
          "Rest_Name": "Corbins Corner",
          "id": 1854,
          "address": "1457 New Britain Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.652349,
              -72.729538
          ],
          "Rest_Name": "Newington Gateway",
          "id": 1855,
          "address": "3575 Berlin Tpke Unit B",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.454879,
              -88.904974
          ],
          "Rest_Name": "D'Iberville",
          "id": 1856,
          "address": "3990 Promenade Pkwy",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.762015,
              -72.742908
          ],
          "Rest_Name": "West Hartford",
          "id": 1857,
          "address": "966 Farmington Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.554779,
              -72.679245
          ],
          "Rest_Name": "Middletown CT",
          "id": 1858,
          "address": "808 Washington St",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.18509,
              -96.576044
          ],
          "Rest_Name": "Manhattan KS",
          "id": 1859,
          "address": "606 N Manhattan Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.692542,
              -72.656257
          ],
          "Rest_Name": "Wethersfield",
          "id": 1860,
          "address": "1084 Silas Deane Hwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.292598,
              -95.846688
          ],
          "Rest_Name": "Owasso II",
          "id": 1861,
          "address": "11320 E 96TH STREET N",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.45096,
              -96.45261
          ],
          "Rest_Name": "Fremont - 23rd St",
          "id": 1862,
          "address": "3550 E Elk Ln",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.720292,
              -72.620484
          ],
          "Rest_Name": "Glastonbury",
          "id": 1863,
          "address": "140 Glastonbury Blvd Ste 4",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.06175,
              -95.78203
          ],
          "Rest_Name": "Broken Arrow",
          "id": 1864,
          "address": "750 E Kenosha St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.769142,
              -96.622677
          ],
          "Rest_Name": "70th & Pioneers",
          "id": 1865,
          "address": "7133 Pioneers Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.112768,
              -72.722707
          ],
          "Rest_Name": "Westfield MA",
          "id": 1866,
          "address": "465 East Main Street",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.813113,
              -96.64009
          ],
          "Rest_Name": "Lincoln O-Street",
          "id": 1867,
          "address": "6005 O St Ste A",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.739298,
              -96.679507
          ],
          "Rest_Name": "South Ridge",
          "id": 1868,
          "address": "2801 Pine Lake Rd Ste V",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.060394,
              -95.854485
          ],
          "Rest_Name": "E Woodland Hills",
          "id": 1869,
          "address": "10902 E 71st St",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.856003,
              -96.682602
          ],
          "Rest_Name": "27th & Superior",
          "id": 1870,
          "address": "4465 N 27th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.804871,
              -92.1597
          ],
          "Rest_Name": "Duluth - Miller Hill Mall",
          "id": 1871,
          "address": "1600 Miller Trunk Hwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.815686,
              -96.702361
          ],
          "Rest_Name": "Univ of Nebraska - Block 38",
          "id": 1872,
          "address": "1317 Q St Ste 100",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.990546,
              -72.584436
          ],
          "Rest_Name": "Enfield",
          "id": 1873,
          "address": "7 Hazard Ave Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.811828,
              -72.528248
          ],
          "Rest_Name": "Buckland Hills",
          "id": 1874,
          "address": "48 Hale Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.104015,
              -95.911355
          ],
          "Rest_Name": "Tulsa Promenade",
          "id": 1875,
          "address": "5730 E 41st St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.175981,
              -72.640542
          ],
          "Rest_Name": "Holyoke I91",
          "id": 1876,
          "address": "235 Whiting Farms Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.096473,
              -72.600274
          ],
          "Rest_Name": "West Springfield MA",
          "id": 1877,
          "address": "219 Memorial Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.013306,
              -95.887085
          ],
          "Rest_Name": "South Memorial",
          "id": 1878,
          "address": "10410 S Memorial Dr Ste A",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.075732,
              -95.922722
          ],
          "Rest_Name": "61st & Yale",
          "id": 1879,
          "address": "6060 S Yale Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.140823,
              -95.968597
          ],
          "Rest_Name": "Cherry Street 2",
          "id": 1880,
          "address": "1623 E 15th St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.457921,
              -94.087695
          ],
          "Rest_Name": "Texarkana",
          "id": 1881,
          "address": "2502 Richmond Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.44571,
              -96.343208
          ],
          "Rest_Name": "Sioux City",
          "id": 1882,
          "address": "5001 Sergeant Rd Ste 240",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.172811,
              -72.578186
          ],
          "Rest_Name": "Chicopee Crossing",
          "id": 1883,
          "address": "530 Memorial Dr Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.673671,
              -82.338755
          ],
          "Rest_Name": "North Gainesville",
          "id": 1884,
          "address": "2225 NW 13th St Suite 100",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.869556,
              -81.323824
          ],
          "Rest_Name": "St. Augustine",
          "id": 1885,
          "address": "1753 US Highway 1 S",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.064735,
              -72.51373
          ],
          "Rest_Name": "East Longmeadow",
          "id": 1886,
          "address": "42 Center Sq",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.652263,
              -82.341518
          ],
          "Rest_Name": "U of Florida",
          "id": 1887,
          "address": "1432 W University Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.558689,
              -94.156541
          ],
          "Rest_Name": "St. Cloud - 5th Ave.",
          "id": 1888,
          "address": "211 5th Ave S Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.619404,
              -82.381281
          ],
          "Rest_Name": "Archer Road",
          "id": 1889,
          "address": "3841 SW Archer Rd Unit A",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.55133,
              -94.206193
          ],
          "Rest_Name": "Gateway Village",
          "id": 1890,
          "address": "3959 2nd St S Ste 205",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.356834,
              -72.553741
          ],
          "Rest_Name": "UMASS",
          "id": 1891,
          "address": "334 Russell St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.596415,
              -94.19206
          ],
          "Rest_Name": "Sartell",
          "id": 1892,
          "address": "1708 Crossing Loop",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.610879,
              -72.973113
          ],
          "Rest_Name": "Rutland",
          "id": 1893,
          "address": "37 N Main St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.513345,
              -72.106799
          ],
          "Rest_Name": "Norwich",
          "id": 1894,
          "address": "30 Salem Tpke",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.369655,
              -72.070601
          ],
          "Rest_Name": "Groton",
          "id": 1895,
          "address": "258 Route 12 Unit A",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.437375,
              -90.084405
          ],
          "Rest_Name": "Covington - Hwy 190",
          "id": 1896,
          "address": "501 N Highway 190",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.2693,
              -89.7606
          ],
          "Rest_Name": "Slidell",
          "id": 1897,
          "address": "400 Town Center Pkwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.394524,
              -90.092283
          ],
          "Rest_Name": "Mandeville",
          "id": 1898,
          "address": "3810 Highway 22 Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.481974,
              -90.45791
          ],
          "Rest_Name": "Hammond",
          "id": 1899,
          "address": "1934 Hammond Square Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.550373,
              -93.709282
          ],
          "Rest_Name": "Bossier City",
          "id": 1900,
          "address": "2610 Airline Dr",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.69448,
              -73.486789
          ],
          "Rest_Name": "Plattsburgh",
          "id": 1901,
          "address": "200 Consumer Sq Ste 208",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.448613,
              -73.208136
          ],
          "Rest_Name": "South Burlington",
          "id": 1902,
          "address": "580 Shelburne Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.476264,
              -81.196113
          ],
          "Rest_Name": "Palm Coast",
          "id": 1903,
          "address": "5860 State Road 100 E",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.925167,
              -72.289786
          ],
          "Rest_Name": "Keene NH",
          "id": 1904,
          "address": "333 Winchester St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.441716,
              -93.718353
          ],
          "Rest_Name": "Shreveport",
          "id": 1905,
          "address": "7030 Youree Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.463611,
              -73.181116
          ],
          "Rest_Name": "Burlington Dorset Street",
          "id": 1906,
          "address": "205 Dorset St",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.73085,
              -97.206474
          ],
          "Rest_Name": "Greenwich Rd",
          "id": 1907,
          "address": "2608 N Greenwich Ct",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.209106,
              -82.067314
          ],
          "Rest_Name": "Silver Springs FL",
          "id": 1908,
          "address": "4916 E Silver Springs Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.738196,
              -97.245344
          ],
          "Rest_Name": "Rock Road",
          "id": 1909,
          "address": "3015 N Rock Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.678727,
              -97.245051
          ],
          "Rest_Name": "Wichita East",
          "id": 1910,
          "address": "535 S Rock Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.167095,
              -82.16836
          ],
          "Rest_Name": "Ocala 2",
          "id": 1911,
          "address": "2645 SW College Rd Unit 103",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.56455,
              -97.243501
          ],
          "Rest_Name": "Derby Marketplace",
          "id": 1912,
          "address": "1700 N Rock Rd Ste 300",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.693987,
              -97.299467
          ],
          "Rest_Name": "Hillside",
          "id": 1913,
          "address": "515 N Hillside St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.544465,
              -96.661136
          ],
          "Rest_Name": "Sioux Falls - Arrowhead Pkwy",
          "id": 1914,
          "address": "5300 E Arrowhead Pkwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.282154,
              -71.807807
          ],
          "Rest_Name": "Worcester",
          "id": 1915,
          "address": "49 Park Ave Ste 1",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.721804,
              -97.363634
          ],
          "Rest_Name": "21st & Amidon",
          "id": 1916,
          "address": "2121 N Amidon Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.358212,
              -94.245605
          ],
          "Rest_Name": "Baxter MN",
          "id": 1917,
          "address": "14203 Edgewood Dr",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.649538,
              -97.353198
          ],
          "Rest_Name": "Pawnee & Seneca",
          "id": 1918,
          "address": "2439 S Seneca St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.275389,
              -71.749783
          ],
          "Rest_Name": "Shrewsbury",
          "id": 1919,
          "address": "97 Boston Turnpike",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.003074,
              -90.162486
          ],
          "Rest_Name": "Veterans Blvd",
          "id": 1920,
          "address": "3600 Veterans Memorial Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.798713,
              -97.611874
          ],
          "Rest_Name": "Salina",
          "id": 1921,
          "address": "2375 S 9th St Ste C",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.263973,
              -81.105463
          ],
          "Rest_Name": "Ormond Beach",
          "id": 1922,
          "address": "1290 W Granada Blvd Ste 300",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.5147,
              -96.774948
          ],
          "Rest_Name": "Sioux Falls - 41st Street",
          "id": 1923,
          "address": "4035 W 41st St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.680185,
              -97.424519
          ],
          "Rest_Name": "Maple & Ridge",
          "id": 1924,
          "address": "7130 W Maple St Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.92614,
              -90.083069
          ],
          "Rest_Name": "Magazine - Garden District",
          "id": 1925,
          "address": "2801 Magazine St Ste 2",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.960115,
              -90.184442
          ],
          "Rest_Name": "Elmwood",
          "id": 1926,
          "address": "1208 S Clearview Pkwy Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.418031,
              -91.176726
          ],
          "Rest_Name": "LSU",
          "id": 1927,
          "address": "101 W State St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.663971,
              -71.495857
          ],
          "Rest_Name": "East Greenwich",
          "id": 1928,
          "address": "1200 Quaker Ln",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.724139,
              -97.463274
          ],
          "Rest_Name": "New Market",
          "id": 1929,
          "address": "2241 N Maize Rd Ste 111",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.21884,
              -81.097244
          ],
          "Rest_Name": "LPGA Daytona",
          "id": 1930,
          "address": "1425 Cornerstone Blvd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.533484,
              -71.743503
          ],
          "Rest_Name": "Leominster",
          "id": 1931,
          "address": "252 Mill St # A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.870808,
              -71.532227
          ],
          "Rest_Name": "Smithfield Relo",
          "id": 1932,
          "address": "473 Putnam Pike",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.712296,
              -71.489657
          ],
          "Rest_Name": "Warwick",
          "id": 1933,
          "address": "969 Bald Hill Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.60542,
              -71.45446
          ],
          "Rest_Name": "North Kingstown",
          "id": 1934,
          "address": "118 Gate Rd ",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.815099,
              -71.497821
          ],
          "Rest_Name": "Johnston",
          "id": 1935,
          "address": "1386 Atwood Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.180812,
              -81.080596
          ],
          "Rest_Name": "Daytona Beach",
          "id": 1936,
          "address": "2400 W International Speedway Blvd Ste 730",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.761894,
              -71.4589
          ],
          "Rest_Name": "Garden City Center",
          "id": 1937,
          "address": "87 Hillside Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.285611,
              -71.608542
          ],
          "Rest_Name": "Westborough",
          "id": 1938,
          "address": "1 Oak St Ste 2",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.731845,
              -71.435526
          ],
          "Rest_Name": "Airport Plaza",
          "id": 1939,
          "address": "1800 Post Road",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.937636,
              -71.476992
          ],
          "Rest_Name": "Lincoln RI",
          "id": 1940,
          "address": "622 George Washington Hwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.338745,
              -71.5914
          ],
          "Rest_Name": "Marlborough",
          "id": 1941,
          "address": "237 Boston Post Rd W",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.86465,
              -71.448916
          ],
          "Rest_Name": "North Providence",
          "id": 1942,
          "address": "1601 Mineral Spring Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.212445,
              -90.950268
          ],
          "Rest_Name": "Gonzales Outlets",
          "id": 1943,
          "address": "2410 Tanger Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.843487,
              -71.409187
          ],
          "Rest_Name": "Providence Branch Ave",
          "id": 1944,
          "address": "66 Branch Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.828265,
              -71.400524
          ],
          "Rest_Name": "Providence - Thayer St",
          "id": 1945,
          "address": "235 Thayer St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.150131,
              -71.487961
          ],
          "Rest_Name": "Milford Rte 109",
          "id": 1946,
          "address": "128 Medway Rd Ste 8",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.931606,
              -81.932597
          ],
          "Rest_Name": "Lady Lake",
          "id": 1947,
          "address": "508 U.S. 27/441",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.516583,
              -71.299258
          ],
          "Rest_Name": "Middletown RI",
          "id": 1948,
          "address": "11 E Main Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.111362,
              -81.02642
          ],
          "Rest_Name": "Port Orange Gateway Center",
          "id": 1949,
          "address": "1731 Dunlawton Ave Ste 103",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.12648,
              -97.053361
          ],
          "Rest_Name": "OSU-Stillwater",
          "id": 1950,
          "address": "415 E Hall of Fame Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.090073,
              -71.42185
          ],
          "Rest_Name": "Franklin Village",
          "id": 1951,
          "address": "5 Franklin Village Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.350233,
              -71.503162
          ],
          "Rest_Name": "Marlborough East",
          "id": 1952,
          "address": "651 Boston Post Rd E",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.8557,
              -71.35473
          ],
          "Rest_Name": "Rumford",
          "id": 1953,
          "address": "75 NEWPORT AVE",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.803022,
              -71.339008
          ],
          "Rest_Name": "Seekonk Rte 6",
          "id": 1954,
          "address": "35 Highland Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.835554,
              -82.341252
          ],
          "Rest_Name": "Inverness FL",
          "id": 1955,
          "address": "911 W Main St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.923685,
              -71.356128
          ],
          "Rest_Name": "Attleboro Rte 1",
          "id": 1956,
          "address": "228 Washington St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.15274,
              -71.40478
          ],
          "Rest_Name": "Medway",
          "id": 1957,
          "address": "67B Main Street",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.986665,
              -81.299716
          ],
          "Rest_Name": "Deland",
          "id": 1958,
          "address": "2335 S Woodland Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.300117,
              -71.393726
          ],
          "Rest_Name": "Shoppers World",
          "id": 1959,
          "address": "1 Worcester Rd Ste 5",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.302291,
              -71.359489
          ],
          "Rest_Name": "Natick",
          "id": 1960,
          "address": "219 N Main St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.014406,
              -80.941199
          ],
          "Rest_Name": "New Smyrna Beach",
          "id": 1961,
          "address": "1796 SR 44",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.673483,
              -71.165355
          ],
          "Rest_Name": "Fall River MA",
          "id": 1962,
          "address": "575 Newton St",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.782864,
              -71.505122
          ],
          "Rest_Name": "Nashua_Amherst Street",
          "id": 1963,
          "address": "356 Amherst St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.566596,
              -71.422779
          ],
          "Rest_Name": "Westford",
          "id": 1964,
          "address": "174 Littleton Rd Ste 1",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.821624,
              -81.783433
          ],
          "Rest_Name": "Leesburg FL 2",
          "id": 1965,
          "address": "10450 US Highway 441",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.19446,
              -71.28971
          ],
          "Rest_Name": "Medfield",
          "id": 1966,
          "address": "250 Main Street",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.015547,
              -71.231427
          ],
          "Rest_Name": "Mansfield Marketplace",
          "id": 1967,
          "address": "287 School St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.712315,
              -71.443691
          ],
          "Rest_Name": "Daniel Webster Highway",
          "id": 1968,
          "address": "225 Daniel Webster Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.824581,
              -81.640709
          ],
          "Rest_Name": "Mount Dora",
          "id": 1969,
          "address": "19025 US Highway 441",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.625047,
              -71.362388
          ],
          "Rest_Name": "Chelmsford",
          "id": 1970,
          "address": "65 Drum Hill Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.542182,
              -94.73356
          ],
          "Rest_Name": "Longview",
          "id": 1971,
          "address": "415 E Loop 281",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.164465,
              -71.199587
          ],
          "Rest_Name": "Norwood - Boston",
          "id": 1972,
          "address": "1415 Boston-Providence Highway",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.956402,
              -71.478195
          ],
          "Rest_Name": "Bedford_NH",
          "id": 1973,
          "address": "99 S River Rd Ste 4",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.375759,
              -71.252849
          ],
          "Rest_Name": "Waltham_Main Street",
          "id": 1974,
          "address": "1030 Main St Ste 5",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.308255,
              -71.215011
          ],
          "Rest_Name": "Needham Street",
          "id": 1975,
          "address": "300 Needham St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.486291,
              -71.262617
          ],
          "Rest_Name": "Bedford MA",
          "id": 1976,
          "address": "301 Great Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.963114,
              -71.441439
          ],
          "Rest_Name": "Manchester NH",
          "id": 1977,
          "address": "926 S Willow St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.41318,
              -71.233721
          ],
          "Rest_Name": "Waltham Lexington St",
          "id": 1978,
          "address": "1110 Lexington St. ",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.580564,
              -71.288135
          ],
          "Rest_Name": "Billerica",
          "id": 1979,
          "address": "199 Boston Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.351673,
              -71.207962
          ],
          "Rest_Name": "Newtonville",
          "id": 1980,
          "address": "847 Washington St",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.204483,
              -71.153575
          ],
          "Rest_Name": "Westwood University Ave.",
          "id": 1981,
          "address": "217 University Ave",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.800126,
              -81.333621
          ],
          "Rest_Name": "Sanford",
          "id": 1982,
          "address": "1805 Wp Ball Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.257647,
              -71.167728
          ],
          "Rest_Name": "Dedham",
          "id": 1983,
          "address": "176 Providence Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.639493,
              -70.991665
          ],
          "Rest_Name": "North Dartmouth",
          "id": 1984,
          "address": "349 State Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.905418,
              -71.049319
          ],
          "Rest_Name": "Raynham",
          "id": 1985,
          "address": "421 New State Hwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.3703,
              -71.1796
          ],
          "Rest_Name": "Arsenal Yards Relo",
          "id": 1986,
          "address": "69 Bond St",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.486162,
              -71.212724
          ],
          "Rest_Name": "Burlington Marketplace",
          "id": 1987,
          "address": "110 Mall Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.336475,
              -71.149571
          ],
          "Rest_Name": "Cleveland Circle MA",
          "id": 1988,
          "address": "1924 Beacon St",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.22982,
              -71.482074
          ],
          "Rest_Name": "Concord, Loudon Road",
          "id": 1989,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.755741,
              -81.35074
          ],
          "Rest_Name": "Lake Mary #2",
          "id": 1990,
          "address": "4315 W Lake Mary Blvd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.092976,
              -71.054373
          ],
          "Rest_Name": "Brockton Westgate Mall",
          "id": 1991,
          "address": "500 Westgate Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.389296,
              -71.143417
          ],
          "Rest_Name": "Fresh Pond",
          "id": 1992,
          "address": "227 Alewife Brook Pkwy Spc B5",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.761273,
              -81.284415
          ],
          "Rest_Name": "East Sanford",
          "id": 1993,
          "address": "3715 S Orlando Dr Ste 100",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.350695,
              -71.11467
          ],
          "Rest_Name": "BU Terriers",
          "id": 1994,
          "address": "876 Commonwealth Avenue",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.534012,
              -82.50673
          ],
          "Rest_Name": "Spring Hill",
          "id": 1995,
          "address": "13207 Cortez Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.395925,
              -71.122413
          ],
          "Rest_Name": "Davis Square",
          "id": 1996,
          "address": "274 Elm St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.344755,
              -71.10089
          ],
          "Rest_Name": "Fenway Park",
          "id": 1997,
          "address": "148 Brookline Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.165752,
              -71.045497
          ],
          "Rest_Name": "Randolph",
          "id": 1998,
          "address": "51 Warren St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.3649,
              -71.103212
          ],
          "Rest_Name": "Central Square_Cambridge",
          "id": 1999,
          "address": "598 Massachusetts Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.68563,
              -81.532751
          ],
          "Rest_Name": "West Apopka",
          "id": 2000,
          "address": "1215 W Orange Blossom Trl",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.516584,
              -71.137851
          ],
          "Rest_Name": "Woburn Mass",
          "id": 2001,
          "address": "112 Commerce Way",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.362548,
              -71.085697
          ],
          "Rest_Name": "Kendall Square MIT",
          "id": 2002,
          "address": "255 Main St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.770176,
              -71.222225
          ],
          "Rest_Name": "Salem NH",
          "id": 2003,
          "address": "12 South Village Drive",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.35067,
              -71.076071
          ],
          "Rest_Name": "553 Boylston Street",
          "id": 2004,
          "address": "553 Boylston St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.64106,
              -70.88566
          ],
          "Rest_Name": "Fairhaven",
          "id": 2005,
          "address": "12 PLAZA WAY",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.368607,
              -71.076583
          ],
          "Rest_Name": "Cambridgeside Galleria",
          "id": 2006,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.322726,
              -71.061248
          ],
          "Rest_Name": "South Bay Center",
          "id": 2007,
          "address": "27 District Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.686288,
              -81.414169
          ],
          "Rest_Name": "Longwood",
          "id": 2008,
          "address": "2700 W State Road 434",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.35173,
              -71.067018
          ],
          "Rest_Name": "City Place Theatre District",
          "id": 2009,
          "address": "8 Park Plz",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.406043,
              -71.082907
          ],
          "Rest_Name": "Medford",
          "id": 2010,
          "address": "616 Fellsway",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.673578,
              -81.469972
          ],
          "Rest_Name": "Apopka - Wekiva",
          "id": 2011,
          "address": "2201 E Semoran Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.353418,
              -71.058025
          ],
          "Rest_Name": "Boston Financial District",
          "id": 2012,
          "address": "101 Summer St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.357535,
              -71.058415
          ],
          "Rest_Name": "School Street",
          "id": 2013,
          "address": "283 Washington St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.272591,
              -71.02721
          ],
          "Rest_Name": "North Quincy",
          "id": 2014,
          "address": "60 Newport Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.351059,
              -71.045746
          ],
          "Rest_Name": "Boston Seaport",
          "id": 2015,
          "address": "51 Boston Wharf Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.697223,
              -81.263633
          ],
          "Rest_Name": "Winter Springs",
          "id": 2016,
          "address": "1177 E State Road 434",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.519634,
              -71.092382
          ],
          "Rest_Name": "Reading Walkers Brook",
          "id": 2017,
          "address": "46 Walkers Brook Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.661332,
              -81.396677
          ],
          "Rest_Name": "Altamonte",
          "id": 2018,
          "address": "400 W State Road 436",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.744014,
              -71.156593
          ],
          "Rest_Name": "Methuen The Loop",
          "id": 2019,
          "address": "90 Pleasant Valley St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.676092,
              -71.131702
          ],
          "Rest_Name": "North Andover",
          "id": 2020,
          "address": "93 Turnpike St",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.659711,
              -81.340747
          ],
          "Rest_Name": "Casselberry",
          "id": 2021,
          "address": "103 State Road 436",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.579395,
              -81.723073
          ],
          "Rest_Name": "Minneola FL",
          "id": 2022,
          "address": "1677 N Hancock Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.51541,
              -71.03368
          ],
          "Rest_Name": "Lynnfield, Market Street",
          "id": 2023,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.479603,
              -71.021167
          ],
          "Rest_Name": "Saugus",
          "id": 2024,
          "address": "444 Broadway",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.646918,
              -81.270118
          ],
          "Rest_Name": "Willa Springs",
          "id": 2025,
          "address": "5637 Red Bug Lake Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.656311,
              -81.203357
          ],
          "Rest_Name": "Oviedo",
          "id": 2026,
          "address": "327 E Mitchell Hammock Rd Ste 1021",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.629809,
              -81.318099
          ],
          "Rest_Name": "Casselberry South",
          "id": 2027,
          "address": "1387 State Road 436",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.178557,
              -70.908632
          ],
          "Rest_Name": "Derby Street",
          "id": 2028,
          "address": "92 Derby St Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.54576,
              -81.730085
          ],
          "Rest_Name": "Clermont",
          "id": 2029,
          "address": "1495 E Hwy 50",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.938956,
              -98.382827
          ],
          "Rest_Name": "Grand Island - State St",
          "id": 2030,
          "address": "3440 W. State St.",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.814072,
              -71.101594
          ],
          "Rest_Name": "Haverhill",
          "id": 2031,
          "address": "89 Plaistow Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.55143,
              -81.585396
          ],
          "Rest_Name": "Dillard & 50",
          "id": 2032,
          "address": "13599 W Colonial Dr Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.593625,
              -81.365317
          ],
          "Rest_Name": "Winter Park",
          "id": 2033,
          "address": "525 S Orlando Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.552487,
              -81.526959
          ],
          "Rest_Name": "Ocoee",
          "id": 2034,
          "address": "10149 W Colonial Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.147459,
              -70.845195
          ],
          "Rest_Name": "Hanover, Rte 53",
          "id": 2035,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.781759,
              -70.744821
          ],
          "Rest_Name": "Wareham",
          "id": 2036,
          "address": "1 Seth F Tobey Rd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.597189,
              -81.216485
          ],
          "Rest_Name": "U of Central Florida",
          "id": 2037,
          "address": "11680 University Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.520935,
              -81.586791
          ],
          "Rest_Name": "Winter Garden",
          "id": 2038,
          "address": "3317 Daniels Rd Ste 102",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.542196,
              -70.945071
          ],
          "Rest_Name": "Northshore Mall",
          "id": 2039,
          "address": "210 Andover St Ste E194A",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.55358,
              -81.346916
          ],
          "Rest_Name": "East Colonial",
          "id": 2040,
          "address": "2903 E Colonial Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.48195,
              -70.903741
          ],
          "Rest_Name": "Swampscott",
          "id": 2041,
          "address": "450 Paradise Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.568565,
              -81.216403
          ],
          "Rest_Name": "Union Park Orlando",
          "id": 2042,
          "address": "11291 E Colonial Dr Ste 101",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.512078,
              -81.459718
          ],
          "Rest_Name": "Metrowest",
          "id": 2043,
          "address": "2850 S Kirkman Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.161779,
              -92.053976
          ],
          "Rest_Name": "Lafayette Ambassador",
          "id": 2044,
          "address": "4231 Ambassador Caffery Pkwy Ste 101",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.493258,
              -81.509186
          ],
          "Rest_Name": "Windermere",
          "id": 2045,
          "address": "9100 Conroy Windermere Rd Ste 120",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.553564,
              -81.206557
          ],
          "Rest_Name": "Waterford Lakes",
          "id": 2046,
          "address": "464 N Alafaya Trl Ste 111",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.516773,
              -81.376134
          ],
          "Rest_Name": "South Orange",
          "id": 2047,
          "address": "2211 S Orange Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.953382,
              -70.711116
          ],
          "Rest_Name": "Plymouth MA",
          "id": 2048,
          "address": "51-9 Commerce Way Plaza",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.575817,
              -70.891249
          ],
          "Rest_Name": "Beverly_MA",
          "id": 2049,
          "address": "55 Dodge St",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.637526,
              -97.484247
          ],
          "Rest_Name": "Edmond",
          "id": 2050,
          "address": "1569 S Broadway",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.290794,
              -82.711856
          ],
          "Rest_Name": "Port Richey",
          "id": 2051,
          "address": "9203 US Highway 19",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.49295,
              -81.428624
          ],
          "Rest_Name": "Millenia Boulevard",
          "id": 2052,
          "address": "4724 Millenia Plaza Way",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.514189,
              -81.311058
          ],
          "Rest_Name": "Azalea Park",
          "id": 2053,
          "address": "2610 S Semoran Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.485062,
              -81.431721
          ],
          "Rest_Name": "Millenia Mall Orlando",
          "id": 2054,
          "address": "4200 Conroy Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.450619,
              -81.488967
          ],
          "Rest_Name": "Dr. Phillips Marketplace",
          "id": 2055,
          "address": "7800 Dr Phillips Blvd Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.820735,
              -70.906568
          ],
          "Rest_Name": "Newburyport Rte 113",
          "id": 2056,
          "address": "47 Storey Ave",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.584613,
              -80.802148
          ],
          "Rest_Name": "Titusville",
          "id": 2057,
          "address": "2560-300 S. Washington Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.44943,
              -81.401886
          ],
          "Rest_Name": "Florida Mall",
          "id": 2058,
          "address": "1700 Sand Lake Rd Ste D110",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.46762,
              -81.30863
          ],
          "Rest_Name": "Orlando Airport",
          "id": 2059,
          "address": "6607 S Semoran Blvd Ste 108",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.614872,
              -97.550088
          ],
          "Rest_Name": "Quail Springs",
          "id": 2060,
          "address": "14221 N Pennsylvania Ave Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.46045,
              -81.27149
          ],
          "Rest_Name": "Vista Palms",
          "id": 2061,
          "address": "7091 Narcoossee Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.996077,
              -70.9245
          ],
          "Rest_Name": "Stratham",
          "id": 2062,
          "address": "18 Portsmouth Ave",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.435619,
              -81.301496
          ],
          "Rest_Name": "Orlando Airport Term 2 FC",
          "id": 2063,
          "address": "9202 Jeff Fuqua Blvd Ste 1427",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.393644,
              -81.485626
          ],
          "Rest_Name": "Vineland Pointe",
          "id": 2064,
          "address": "11513 Regency Village Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.355704,
              -81.676349
          ],
          "Rest_Name": "Cagan Crossings",
          "id": 2065,
          "address": "610 Cagan View Rd Ste 103",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.269062,
              -82.187591
          ],
          "Rest_Name": "Zephyrhills",
          "id": 2066,
          "address": "7646 Gall Blvd",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.205174,
              -82.66659
          ],
          "Rest_Name": "Trinity",
          "id": 2067,
          "address": "3286 Word Way",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.531194,
              -97.57533
          ],
          "Rest_Name": "Northwest Expressway",
          "id": 2068,
          "address": "3315 NW Expressway Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.34644,
              -81.611278
          ],
          "Rest_Name": "West Kissimmee",
          "id": 2069,
          "address": "8124 W Irlo Bronson Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.381376,
              -81.404894
          ],
          "Rest_Name": "Southchase",
          "id": 2070,
          "address": "12648 S Orange Blossom Trl Ste 30",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.188057,
              -82.551875
          ],
          "Rest_Name": "Odessa FL",
          "id": 2071,
          "address": "16118 Preserve Marketplace Blvd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.346074,
              -81.486706
          ],
          "Rest_Name": "Osceola Parkway Kissimmee",
          "id": 2072,
          "address": "3275 Vineland Rd",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.320069,
              -97.498876
          ],
          "Rest_Name": "Moore",
          "id": 2073,
          "address": "705 SW 19th St Ste 200",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.211777,
              -97.44335
          ],
          "Rest_Name": "University of Oklahoma",
          "id": 2074,
          "address": "765 Asp Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.19322,
              -82.395242
          ],
          "Rest_Name": "Wesley Chapel",
          "id": 2075,
          "address": "25442 Sierra Center Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.332154,
              -81.5328
          ],
          "Rest_Name": "Celebration",
          "id": 2076,
          "address": "6210 W Irlo Bronson Memorial Hwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.364094,
              -81.365211
          ],
          "Rest_Name": "Buena Ventura Lakes",
          "id": 2077,
          "address": "13935 Landstar Blvd Ste 120",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.386963,
              -81.244816
          ],
          "Rest_Name": "Lake Nona",
          "id": 2078,
          "address": "12278 Narcoossee Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.345635,
              -81.420783
          ],
          "Rest_Name": "Kissimmee",
          "id": 2079,
          "address": "3254 N John Young Pkwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.238637,
              -97.481421
          ],
          "Rest_Name": "I-35 & Robinson",
          "id": 2080,
          "address": "1641 24th Ave NW",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.215338,
              -70.880136
          ],
          "Rest_Name": "Dover NH",
          "id": 2081,
          "address": "826 Central Ave # 15",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.30348,
              -81.45728
          ],
          "Rest_Name": "Vine&Bass Kissimmee",
          "id": 2082,
          "address": "4390 W VINE ST.",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.034657,
              -70.783223
          ],
          "Rest_Name": "Portsmouth Rte 1",
          "id": 2083,
          "address": "2422 Lafayette Road",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.09444,
              -70.798843
          ],
          "Rest_Name": "Newington",
          "id": 2084,
          "address": "45 Gosling Rd Unit 5C",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.25944,
              -81.59553
          ],
          "Rest_Name": "Reunion FL",
          "id": 2085,
          "address": "2116 Triglia Way",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.29719,
              -95.301791
          ],
          "Rest_Name": "Tyler",
          "id": 2086,
          "address": "4751 S Broadway Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.131275,
              -82.365765
          ],
          "Rest_Name": "New Tampa Highwoods Preserve",
          "id": 2087,
          "address": "18019 Highwoods Preserve Pkwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.073064,
              -82.738312
          ],
          "Rest_Name": "Coral Landing Palm Harbor",
          "id": 2088,
          "address": "33121 US Highway 19 N",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.224933,
              -81.645096
          ],
          "Rest_Name": "Davenport",
          "id": 2089,
          "address": "1091 Posner Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.251327,
              -95.307743
          ],
          "Rest_Name": "Cumberland Park",
          "id": 2090,
          "address": "8916 South Broadway Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.066482,
              -82.570505
          ],
          "Rest_Name": "Gunn Highway Citrus Park",
          "id": 2091,
          "address": "7002 Gunn Hwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.096009,
              -96.112571
          ],
          "Rest_Name": "Greenville Texas",
          "id": 2092,
          "address": "3118 Interstate Highway 30",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.063578,
              -82.503442
          ],
          "Rest_Name": "Carrollwood",
          "id": 2093,
          "address": "12827 N Dale Mabry Hwy",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.667267,
              -70.296814
          ],
          "Rest_Name": "Cape Cod Hyannis Relo",
          "id": 2094,
          "address": "793 Iyannough Rd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.623169,
              -94.652864
          ],
          "Rest_Name": "Nacogdoches",
          "id": 2095,
          "address": "2111 North St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.2526,
              -81.316273
          ],
          "Rest_Name": "St. Cloud FL",
          "id": 2096,
          "address": "4351 13th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.682577,
              -96.60891
          ],
          "Rest_Name": "Big Sherm",
          "id": 2097,
          "address": "875 Northcreek Dr.",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.043503,
              -82.590416
          ],
          "Rest_Name": "Westchase",
          "id": 2098,
          "address": "9466 W Linebaugh Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.054873,
              -82.429836
          ],
          "Rest_Name": "USF Fowler 2",
          "id": 2099,
          "address": "2576 E Fowler Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.014723,
              -82.734477
          ],
          "Rest_Name": "Countryside",
          "id": 2100,
          "address": "2537 Countryside Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.054882,
              -82.3993
          ],
          "Rest_Name": "Temple Terrace",
          "id": 2101,
          "address": "5114 E Fowler Ave Ste 101",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.021404,
              -71.115179
          ],
          "Rest_Name": "North Conway",
          "id": 2102,
          "address": "1549 White Mountain Hwy",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.023987,
              -82.504363
          ],
          "Rest_Name": "Dale Mabry & Waters",
          "id": 2103,
          "address": "8301 N Dale Mabry Hwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.095807,
              -81.974674
          ],
          "Rest_Name": "North Lakeland",
          "id": 2104,
          "address": "4204 US Hwy 98 N",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.961126,
              -82.727515
          ],
          "Rest_Name": "Clearwater Mall",
          "id": 2105,
          "address": "2662 Gulf To Bay Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.156896,
              -81.440713
          ],
          "Rest_Name": "Poinciana",
          "id": 2106,
          "address": "4450 Pleasant Hill Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.11834,
              -81.64031
          ],
          "Rest_Name": "Haines City",
          "id": 2107,
          "address": "35654 US 27 N",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.96499,
              -82.521147
          ],
          "Rest_Name": "International Plaza Food Court",
          "id": 2108,
          "address": "2223 N West Shore Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.960183,
              -82.505162
          ],
          "Rest_Name": "Dale Mabry & Walnut",
          "id": 2109,
          "address": "2011 N Dale Mabry Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.947477,
              -82.52393
          ],
          "Rest_Name": "Westshore",
          "id": 2110,
          "address": "309 N West Shore Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.916774,
              -82.731462
          ],
          "Rest_Name": "East Largo",
          "id": 2111,
          "address": "5360 East Bay Drive",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.9397,
              -82.483393
          ],
          "Rest_Name": "South Tampa",
          "id": 2112,
          "address": "533 S Howard Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.894106,
              -82.780083
          ],
          "Rest_Name": "Largo Ulmerton Road",
          "id": 2113,
          "address": "10160 Ulmerton Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.001635,
              -81.956539
          ],
          "Rest_Name": "South Lakeland",
          "id": 2114,
          "address": "3434 S Florida Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.239152,
              -80.728458
          ],
          "Rest_Name": "Viera",
          "id": 2115,
          "address": "2322 Harnett Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.938726,
              -82.323634
          ],
          "Rest_Name": "Brandon Town Center",
          "id": 2116,
          "address": "103 Brandon Town Center Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.932241,
              -82.324896
          ],
          "Rest_Name": "Brandon Mall Food Court",
          "id": 2117,
          "address": "459 Brandon Town Center Mall Ste Fc",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.938434,
              -82.257814
          ],
          "Rest_Name": "Valrico",
          "id": 2118,
          "address": "1812 East Highway 60",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.923045,
              -82.326399
          ],
          "Rest_Name": "Brandon Causeway",
          "id": 2119,
          "address": "11137 Causeway Blvd",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.89347,
              -82.505875
          ],
          "Rest_Name": "Dale Mabry & Gandy",
          "id": 2120,
          "address": "3696 W Gandy Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.723399,
              -99.083389
          ],
          "Rest_Name": "Kearney",
          "id": 2121,
          "address": "5001 2nd Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.003614,
              -81.717516
          ],
          "Rest_Name": "Winter Haven",
          "id": 2122,
          "address": "804 Cypress Gardens Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.841562,
              -82.79449
          ],
          "Rest_Name": "Seminole City Center",
          "id": 2123,
          "address": "11241 Park Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.893163,
              -82.272361
          ],
          "Rest_Name": "Brandon-Bloomingdale Avenue",
          "id": 2124,
          "address": "829 E Bloomingdale Ave",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.838767,
              -82.684634
          ],
          "Rest_Name": "Parkside Mall",
          "id": 2125,
          "address": "3700 Park Blvd N",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.189863,
              -80.671335
          ],
          "Rest_Name": "Melbourne North",
          "id": 2126,
          "address": "4999 N Wickham Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.176088,
              -96.513944
          ],
          "Rest_Name": "Princeton TX",
          "id": 2127,
          "address": "800 W Princeton Drive",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.96592,
              -96.33946
          ],
          "Rest_Name": "Royse City",
          "id": 2128,
          "address": "460 West Interstate 30",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.804778,
              -82.638811
          ],
          "Rest_Name": "North St Pete",
          "id": 2129,
          "address": "3540 4th St N",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.785704,
              -82.728901
          ],
          "Rest_Name": "Tyrone",
          "id": 2130,
          "address": "1418 66th St N",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.780159,
              -82.638729
          ],
          "Rest_Name": "4th Street",
          "id": 2131,
          "address": "780 4th St N",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.218062,
              -96.638339
          ],
          "Rest_Name": "380 Town Crossing",
          "id": 2132,
          "address": "2014 W University Dr Ste 300",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.172883,
              -96.641614
          ],
          "Rest_Name": "Eldorado",
          "id": 2133,
          "address": "2811 Craig Dr Ste 100",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.218,
              -96.6801
          ],
          "Rest_Name": "380 & Lake Forest",
          "id": 2134,
          "address": "5151 W University Dr",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.078431,
              -80.647537
          ],
          "Rest_Name": "Melbourne",
          "id": 2135,
          "address": "1563 W New Haven Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.508308,
              -70.434967
          ],
          "Rest_Name": "Saco",
          "id": 2136,
          "address": "507 Main St Ste 103",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.902614,
              -96.454727
          ],
          "Rest_Name": "Rockwall",
          "id": 2137,
          "address": "951 E Interstate 30 Ste 111",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.00797,
              -96.545144
          ],
          "Rest_Name": "Wylie",
          "id": 2138,
          "address": "499 S State Hwy ",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.086072,
              -96.617706
          ],
          "Rest_Name": "Lucas",
          "id": 2139,
          "address": "2650 W Lucas Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.128246,
              -96.662642
          ],
          "Rest_Name": "North Allen",
          "id": 2140,
          "address": "1985 Central Expy N Ste 130",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              28.036206,
              -80.667366
          ],
          "Rest_Name": "West Melbourne",
          "id": 2141,
          "address": "125 Palm Bay Rd NE",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.716716,
              -96.325676
          ],
          "Rest_Name": "Terrell",
          "id": 2142,
          "address": "1396 FM Rd 148",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.102906,
              -96.679133
          ],
          "Rest_Name": "Allen",
          "id": 2143,
          "address": "103 Central Expy N",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.01193,
              -96.609264
          ],
          "Rest_Name": "Murphy",
          "id": 2144,
          "address": "121 E FM 544 Ste 129",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.218717,
              -96.783434
          ],
          "Rest_Name": "380 & 423",
          "id": 2145,
          "address": "1525 US Highway 380 Ste 100",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.127704,
              -96.733941
          ],
          "Rest_Name": "Custer",
          "id": 2146,
          "address": "6111 Custer Rd Ste 100",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.157166,
              -96.767945
          ],
          "Rest_Name": "Coit & Main",
          "id": 2147,
          "address": "8847 Coit Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.056714,
              -96.693465
          ],
          "Rest_Name": "Spring Creek",
          "id": 2148,
          "address": "500 E Spring Creek Pkwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.908125,
              -96.572245
          ],
          "Rest_Name": "Rowlett",
          "id": 2149,
          "address": "3109 Lakeview Pkwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.955566,
              -96.616909
          ],
          "Rest_Name": "Firewheel",
          "id": 2150,
          "address": "4170 Lavon Dr Ste 130",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.835445,
              -70.438889
          ],
          "Rest_Name": "Windham",
          "id": 2151,
          "address": "799 Roosevelt Trl Unit 2",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.634989,
              -70.337837
          ],
          "Rest_Name": "South Portland",
          "id": 2152,
          "address": "359 Maine Mall Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.741413,
              -96.447354
          ],
          "Rest_Name": "Forney",
          "id": 2153,
          "address": "825 E US Highway 80",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.997719,
              -96.668754
          ],
          "Rest_Name": "190 & Renner",
          "id": 2154,
          "address": "3391 Renner Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.071013,
              -96.750779
          ],
          "Rest_Name": "Independence and Legacy",
          "id": 2155,
          "address": "6917 Independence Pkwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.015733,
              -96.709271
          ],
          "Rest_Name": "Collin Creek",
          "id": 2156,
          "address": "1009 N Central Expy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.677565,
              -70.329777
          ],
          "Rest_Name": "Westbrook",
          "id": 2157,
          "address": "11 Main St Ste 3",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.175984,
              -96.845531
          ],
          "Rest_Name": "Eldorado & Legacy",
          "id": 2158,
          "address": "4770 Eldorado Pkwy Ste 400",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.107362,
              -96.806229
          ],
          "Rest_Name": "Preston Ridge",
          "id": 2159,
          "address": "3401 Preston Rd Ste 2",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.10619,
              -96.825546
          ],
          "Rest_Name": "Warren and DNT",
          "id": 2160,
          "address": "6851 Warren Pkwy Ste 205",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.975074,
              -96.720999
          ],
          "Rest_Name": "Telecom Corridor",
          "id": 2161,
          "address": "283 W Campbell Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.945274,
              -96.698638
          ],
          "Rest_Name": "Plano & Beltline",
          "id": 2162,
          "address": "1420 E Belt Line Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.660587,
              -70.266695
          ],
          "Rest_Name": "Portland Marginal Way",
          "id": 2163,
          "address": "195 Kennebec St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.078407,
              -96.820292
          ],
          "Rest_Name": "Shops at Legacy",
          "id": 2164,
          "address": "5717 Legacy Dr Ste 150",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.002224,
              -96.767018
          ],
          "Rest_Name": "Coit & 190",
          "id": 2165,
          "address": "340 Coit Rd Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.027502,
              -96.792389
          ],
          "Rest_Name": "Park & Preston",
          "id": 2166,
          "address": "4901 W Park Blvd Ste 539",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.791536,
              -96.597088
          ],
          "Rest_Name": "Mesquite East",
          "id": 2167,
          "address": "125 US-80 E Ste 200",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.811189,
              -96.624434
          ],
          "Rest_Name": "Mesquite",
          "id": 2168,
          "address": "1715 N Town East Blvd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.999127,
              -96.796695
          ],
          "Rest_Name": "Preston & Frankford",
          "id": 2169,
          "address": "18152 Preston Rd Ste 1",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.532086,
              -82.501768
          ],
          "Rest_Name": "Ellenton",
          "id": 2170,
          "address": "6020 US Highway 301 N",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.02706,
              -96.833078
          ],
          "Rest_Name": "West Plano",
          "id": 2171,
          "address": "6202 W Park Blvd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.949697,
              -96.769462
          ],
          "Rest_Name": "Richardson",
          "id": 2172,
          "address": "14715 Coit Road",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.069047,
              -96.879297
          ],
          "Rest_Name": "The Colony",
          "id": 2173,
          "address": "5040 State Highway 121 Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.907832,
              -96.769701
          ],
          "Rest_Name": "Central Expressway",
          "id": 2174,
          "address": "11613 N Central Expy Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.831193,
              -96.705691
          ],
          "Rest_Name": "Casa Linda II",
          "id": 2175,
          "address": "9291 Garland Rd Ste 120",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.953658,
              -96.817913
          ],
          "Rest_Name": "North Dallas",
          "id": 2176,
          "address": "5290 Belt Line Rd Ste 102A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.8574,
              -96.7503
          ],
          "Rest_Name": "Abrams and NWH",
          "id": 2177,
          "address": "6471 E Northwest Hwy Ste 150",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.911355,
              -96.803034
          ],
          "Rest_Name": "Preston Forest",
          "id": 2178,
          "address": "11930 Preston Rd Ste 108",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.870621,
              -96.769003
          ],
          "Rest_Name": "Park Lane",
          "id": 2179,
          "address": "8018 Park Ln # 105",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.868969,
              -96.77351
          ],
          "Rest_Name": "NorthPark Center",
          "id": 2180,
          "address": "8687 N Central Expy Ste 2396",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.857473,
              -96.769627
          ],
          "Rest_Name": "Upper Greenville",
          "id": 2181,
          "address": "7700 N Central Expy",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.95367,
              -96.853017
          ],
          "Rest_Name": "Addison",
          "id": 2182,
          "address": "3771 Belt Line Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.463178,
              -82.578336
          ],
          "Rest_Name": "Bradenton",
          "id": 2183,
          "address": "1608 Cortez Rd W",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.020492,
              -96.913809
          ],
          "Rest_Name": "Carrollton TX",
          "id": 2184,
          "address": "1016 E Hebron Pkwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.92136,
              -96.838671
          ],
          "Rest_Name": "Farmer's Branch",
          "id": 2185,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.816402,
              -96.753159
          ],
          "Rest_Name": "Lakewood TX",
          "id": 2186,
          "address": "2201 Abrams Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.1303,
              -97.0392
          ],
          "Rest_Name": "Corinth",
          "id": 2187,
          "address": "8201 S Interstate 35 E",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.821055,
              -96.788102
          ],
          "Rest_Name": "Knox Street",
          "id": 2188,
          "address": "4502 McKinney Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.191765,
              -97.100294
          ],
          "Rest_Name": "Denton",
          "id": 2189,
          "address": "1800 S Loop 288 Ste 398",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.923707,
              -96.898415
          ],
          "Rest_Name": "I-35 & Valley View",
          "id": 2190,
          "address": "2427 Valley View Ln",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.000531,
              -96.962609
          ],
          "Rest_Name": "Vista Ridge",
          "id": 2191,
          "address": "2605 S Stemmons Fwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.817779,
              -96.812016
          ],
          "Rest_Name": "Oaklawn",
          "id": 2192,
          "address": "4330 Lemmon Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.4323,
              -82.424
          ],
          "Rest_Name": "Lakewood Ranch",
          "id": 2193,
          "address": "11715 State Road 70 E",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.798164,
              -96.801621
          ],
          "Rest_Name": "McKinney",
          "id": 2194,
          "address": "2705 McKinney Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.861138,
              -96.857219
          ],
          "Rest_Name": "Bachman Lake",
          "id": 2195,
          "address": "3701 W Northwest Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.046072,
              -97.010343
          ],
          "Rest_Name": "North Lewisville",
          "id": 2196,
          "address": "722 W Main St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.214758,
              -97.146201
          ],
          "Rest_Name": "UNT Fry St.",
          "id": 2197,
          "address": "1224 W Hickory St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.229381,
              -97.168369
          ],
          "Rest_Name": "Rayzor Ranch",
          "id": 2198,
          "address": "2735 W University Dr Ste 1051",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.787815,
              -96.809544
          ],
          "Rest_Name": "Victory Park",
          "id": 2199,
          "address": "2401 VICTORY PARK LANE SUITE 140",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.827182,
              -96.845921
          ],
          "Rest_Name": "West Love",
          "id": 2200,
          "address": "2427 W Mockingbird Lane Ste 150",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.117154,
              -70.225259
          ],
          "Rest_Name": "Auburn Center Street",
          "id": 2201,
          "address": "410 Center St Ste 1",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.969027,
              -96.993335
          ],
          "Rest_Name": "Coppell",
          "id": 2202,
          "address": "104 S Denton Tap Rd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.387703,
              -82.458142
          ],
          "Rest_Name": "University Town Center Sarasota",
          "id": 2203,
          "address": "97 N Cattlemen Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.069765,
              -97.08218
          ],
          "Rest_Name": "Flower Mound",
          "id": 2204,
          "address": "6100 Long Prairie Rd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.912385,
              -96.959372
          ],
          "Rest_Name": "Las Colinas",
          "id": 2205,
          "address": "7717 N MacArthur Blvd Ste 100",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.865764,
              -96.943632
          ],
          "Rest_Name": "Las Colinas South",
          "id": 2206,
          "address": "118 E John Carpenter Fwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.922099,
              -96.99438
          ],
          "Rest_Name": "LBJ and Beltline",
          "id": 2207,
          "address": "8445 N Belt Line Rd Ste 130",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.7606,
              -96.8592
          ],
          "Rest_Name": "Kessler Park",
          "id": 2208,
          "address": "2242 Fort Worth Ave Bldg A",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.011492,
              -97.070128
          ],
          "Rest_Name": "Flower Mound South TX",
          "id": 2209,
          "address": "1900 Long Prairie Rd Ste 108",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.961725,
              -97.04183
          ],
          "Rest_Name": "Grapevine Mills",
          "id": 2210,
          "address": "2225 E Grapevine Mills Cir",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.834232,
              -96.820473
          ],
          "Rest_Name": "South Fargo",
          "id": 2211,
          "address": "3061 25th St S",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.337573,
              -82.463371
          ],
          "Rest_Name": "Sarasota Towne Center",
          "id": 2212,
          "address": "5354 Fruitville Rd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.318122,
              -82.5294
          ],
          "Rest_Name": "Sarasota",
          "id": 2213,
          "address": "1707 S Tamiami Trl",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.844365,
              -96.99201
          ],
          "Rest_Name": "Irving Mall",
          "id": 2214,
          "address": "2800 N Belt Line Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.904292,
              -96.796588
          ],
          "Rest_Name": "NDSU",
          "id": 2215,
          "address": "1204 19th Ave N",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.855015,
              -96.861643
          ],
          "Rest_Name": "Fargo - Prairie Stone",
          "id": 2216,
          "address": "1680 45th St S",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.938909,
              -97.104071
          ],
          "Rest_Name": "Southlake",
          "id": 2217,
          "address": "3010 E Southlake Blvd # 600",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.604645,
              -96.82537
          ],
          "Rest_Name": "Desoto",
          "id": 2218,
          "address": "1001 NORTH INTERSTATE 35E SERVICE RD ",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.65049,
              -96.865799
          ],
          "Rest_Name": "Wheatland",
          "id": 2219,
          "address": "39779 Lyndon B Johnson Fwy Ste 110",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.638345,
              -80.447965
          ],
          "Rest_Name": "Vero Beach",
          "id": 2220,
          "address": "5855 20th St Unit 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.88015,
              -97.098193
          ],
          "Rest_Name": "Euless",
          "id": 2221,
          "address": "3010 State Highway 121 Ste 800",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.017902,
              -97.218136
          ],
          "Rest_Name": "Roanoke",
          "id": 2222,
          "address": "1347 N. HWY 377",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.824215,
              -97.060249
          ],
          "Rest_Name": "Centreport 2",
          "id": 2223,
          "address": "4200 Highway 360",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.532558,
              -96.82062
          ],
          "Rest_Name": "Red Oak",
          "id": 2224,
          "address": "100 Sharaf Ave Suite 104",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.649141,
              -96.942269
          ],
          "Rest_Name": "Duncanville",
          "id": 2225,
          "address": "300 S Clark Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.937631,
              -97.186809
          ],
          "Rest_Name": "West Southlake",
          "id": 2226,
          "address": "2310 W Southlake Blvd Ste 180",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.878088,
              -97.158434
          ],
          "Rest_Name": "Colleyville",
          "id": 2227,
          "address": "4609 Colleyville Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.025883,
              -97.284912
          ],
          "Rest_Name": "Champions Circle",
          "id": 2228,
          "address": "3460 Highway 114",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.115381,
              -94.169175
          ],
          "Rest_Name": "Beaumont",
          "id": 2229,
          "address": "3905 Dowlen Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.706017,
              -97.023371
          ],
          "Rest_Name": "Pioneer",
          "id": 2230,
          "address": "1220 Arkansas Ln Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.83619,
              -97.132829
          ],
          "Rest_Name": "Bedford TX",
          "id": 2231,
          "address": "2100 Airport Fwy Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.673702,
              -97.006221
          ],
          "Rest_Name": "Grand Prairie",
          "id": 2232,
          "address": "4095 S Carrier Pkwy",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.592369,
              -96.93788
          ],
          "Rest_Name": "Cedar Hill",
          "id": 2233,
          "address": "398 E FM 1382",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.769008,
              -97.096823
          ],
          "Rest_Name": "Arlington",
          "id": 2234,
          "address": "2151 N Collins St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.853457,
              -97.190259
          ],
          "Rest_Name": "North Richland Hills",
          "id": 2235,
          "address": "9127 Grapevine Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.944487,
              -93.994997
          ],
          "Rest_Name": "Port Arthur",
          "id": 2236,
          "address": "2780 Highway 365",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.931846,
              -97.270075
          ],
          "Rest_Name": "Golden Triangle",
          "id": 2237,
          "address": "5252 Golden Triangle Blvd Ste 100",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.424219,
              -96.838914
          ],
          "Rest_Name": "Hach",
          "id": 2238,
          "address": "1440 N Highway 77 Ste 10",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.82413,
              -97.199892
          ],
          "Rest_Name": "Northeast Mall",
          "id": 2239,
          "address": "1312 W Pipeline Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.722217,
              -97.115236
          ],
          "Rest_Name": "UTA",
          "id": 2240,
          "address": "1390 S Cooper St Ste 100",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.882703,
              -97.259994
          ],
          "Rest_Name": "Watauga",
          "id": 2241,
          "address": "7604 Denton Hwy Ste 200",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.841063,
              -97.238015
          ],
          "Rest_Name": "Rufe Snow",
          "id": 2242,
          "address": "5100 Rufe Snow Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.915137,
              -97.313821
          ],
          "Rest_Name": "Alliance",
          "id": 2243,
          "address": "2901 Heritage Trace Pkwy Ste 101",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.858738,
              -97.290558
          ],
          "Rest_Name": "Beach & Western Center",
          "id": 2244,
          "address": "6301 N Beach St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.664639,
              -97.133659
          ],
          "Rest_Name": "Arlington South",
          "id": 2245,
          "address": "5001 S Cooper St Ste 125",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.572617,
              -97.088982
          ],
          "Rest_Name": "Mansfield East",
          "id": 2246,
          "address": "3040 E Broad St",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.869843,
              -97.341721
          ],
          "Rest_Name": "Blue Mound",
          "id": 2247,
          "address": "7100 Blue Mound Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.96601,
              -97.41997
          ],
          "Rest_Name": "Avondale",
          "id": 2248,
          "address": "13040 Saginaw Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.676315,
              -97.196754
          ],
          "Rest_Name": "I20 and Little Rd",
          "id": 2249,
          "address": "4401 Little Rd Ste 590",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.595463,
              -97.148099
          ],
          "Rest_Name": "Mansfield TX",
          "id": 2250,
          "address": "1971 Highway 287 N Ste 125",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.882164,
              -97.390778
          ],
          "Rest_Name": "Saginaw TX",
          "id": 2251,
          "address": "1424 Old Decatur Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.417304,
              -80.381779
          ],
          "Rest_Name": "Fort Pierce",
          "id": 2252,
          "address": "5545 Okeechobee Rd",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.732805,
              -97.343147
          ],
          "Rest_Name": "Medical District Fort Worth",
          "id": 2253,
          "address": "1519 W Rosedale St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.037397,
              -82.216243
          ],
          "Rest_Name": "North Port",
          "id": 2254,
          "address": "17019 Tamiami Trl",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.810296,
              -97.432461
          ],
          "Rest_Name": "Lake Worth",
          "id": 2255,
          "address": "6547 Lake Worth Blvd Ste 200",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.006174,
              -82.133263
          ],
          "Rest_Name": "Port Charlotte",
          "id": 2256,
          "address": "1799 Tamiami Trl",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.706451,
              -97.389039
          ],
          "Rest_Name": "Trinity Commons",
          "id": 2257,
          "address": "3050 S Hulen St Ste C",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.755116,
              -97.429336
          ],
          "Rest_Name": "Hawks Creek",
          "id": 2258,
          "address": "6706 Westworth Blvd Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.726414,
              -97.42219
          ],
          "Rest_Name": "Camp Bowie",
          "id": 2259,
          "address": "6370 Camp Bowie Blvd Ste 140",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.310724,
              -80.40644
          ],
          "Rest_Name": "St. Lucie West",
          "id": 2260,
          "address": "1768 SW Saint Lucie West Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.684737,
              -97.414653
          ],
          "Rest_Name": "Bryant Irvin",
          "id": 2261,
          "address": "4484 Bryant Irvin Rd Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.566789,
              -97.318388
          ],
          "Rest_Name": "Burleson II",
          "id": 2262,
          "address": "12621 South Fwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.664707,
              -97.401311
          ],
          "Rest_Name": "Hulen Mall South",
          "id": 2263,
          "address": "5745 S Hulen St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.268017,
              -80.432634
          ],
          "Rest_Name": "Tradition Port St Lucie",
          "id": 2264,
          "address": "10830 SW Tradition Pkwy",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.681151,
              -97.463049
          ],
          "Rest_Name": "Benbrook TX",
          "id": 2265,
          "address": "8522 Highway 377 S",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.531471,
              -97.338764
          ],
          "Rest_Name": "Burleson South",
          "id": 2266,
          "address": "828 SW Wilshire Blvd.",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.605881,
              -97.408127
          ],
          "Rest_Name": "Chisholm Trail & McPherson",
          "id": 2267,
          "address": "5425 McPherson Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.710827,
              -95.566226
          ],
          "Rest_Name": "Huntsville",
          "id": 2268,
          "address": "213 Interstate 45 S",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.234775,
              -80.26764
          ],
          "Rest_Name": "Jensen Beach",
          "id": 2269,
          "address": "2421 NW Federal Hwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.177976,
              -80.236088
          ],
          "Rest_Name": "Stuart Center North",
          "id": 2270,
          "address": "2181 SE Federal Hwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.758526,
              -97.714444
          ],
          "Rest_Name": "Hudson Oaks TX",
          "id": 2271,
          "address": "2791 Fort Worth Hwy",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.871129,
              -98.534152
          ],
          "Rest_Name": "Wichita Falls",
          "id": 2272,
          "address": "3910 Wayne Ave Ste 100",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.729325,
              -97.787634
          ],
          "Rest_Name": "Weatherford",
          "id": 2273,
          "address": "106 E Interstate 20",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.134644,
              -95.228539
          ],
          "Rest_Name": "New Caney",
          "id": 2274,
          "address": "21360 US Highway 59",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.315239,
              -95.473444
          ],
          "Rest_Name": "Conroe TX",
          "id": 2275,
          "address": "1301 W Davis St Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.673058,
              -81.928224
          ],
          "Rest_Name": "Cape Coral Marketplace",
          "id": 2276,
          "address": "2060 NE Pine Island Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.0507,
              -95.1804
          ],
          "Rest_Name": "Kingwood",
          "id": 2277,
          "address": "4554 Kingwood Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.82029,
              -94.89904
          ],
          "Rest_Name": "Mont Belvieu",
          "id": 2278,
          "address": "10404 Interstate 10 E",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.997368,
              -95.163998
          ],
          "Rest_Name": "Atascocita",
          "id": 2279,
          "address": "7418 FM 1960 Rd E",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.387336,
              -95.679742
          ],
          "Rest_Name": "Montgomery TX",
          "id": 2280,
          "address": "20165 Eva St",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.20628,
              -95.463578
          ],
          "Rest_Name": "Woodlands North",
          "id": 2281,
          "address": "3085 College Park Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.610907,
              -81.940712
          ],
          "Rest_Name": "Cape Coral Del Prado",
          "id": 2282,
          "address": "2207 Del Prado Blvd S",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.118431,
              -95.379661
          ],
          "Rest_Name": "Rayford Crossing",
          "id": 2283,
          "address": "4053 Riley Fuzzel Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.433649,
              -97.784812
          ],
          "Rest_Name": "Granbury",
          "id": 2284,
          "address": "321 E US Highway 377",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.608354,
              -81.810544
          ],
          "Rest_Name": "Colonial & 75 Fort Myers",
          "id": 2285,
          "address": "8061 Dani Dr Ste 140",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.16439,
              -95.464889
          ],
          "Rest_Name": "Woodlands",
          "id": 2286,
          "address": "9595 Six Pines Dr Ste 1080",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.005845,
              -95.269299
          ],
          "Rest_Name": "Deerbrook",
          "id": 2287,
          "address": "10035 FM 1960 Bypass Rd W",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.934223,
              -80.130662
          ],
          "Rest_Name": "Jupiter",
          "id": 2288,
          "address": "6274 W Indiantown Rd Ste A",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.779732,
              -94.977228
          ],
          "Rest_Name": "Baytown TX",
          "id": 2289,
          "address": "4996 Garth Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.222221,
              -95.565796
          ],
          "Rest_Name": "Magnolia",
          "id": 2290,
          "address": "6538 FM 1488",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.127648,
              -95.45431
          ],
          "Rest_Name": "Sawdust",
          "id": 2291,
          "address": "760 Sawdust Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.928637,
              -95.203308
          ],
          "Rest_Name": "Summerwood",
          "id": 2292,
          "address": "14309 E Sam Houston Pkwy N Ste 800",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.558735,
              -81.871156
          ],
          "Rest_Name": "College & 41 Fort Myers",
          "id": 2293,
          "address": "12635 S Cleveland Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.099725,
              -95.442139
          ],
          "Rest_Name": "Springwoods",
          "id": 2294,
          "address": "1400 Lake Plaza Drive",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.066232,
              -95.437682
          ],
          "Rest_Name": "Louetta North",
          "id": 2295,
          "address": "1600 Louetta Rd Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.882528,
              -80.115425
          ],
          "Rest_Name": "Donald Ross Road",
          "id": 2296,
          "address": "5240 Donald Ross Rd Ste 130",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.132385,
              -95.552521
          ],
          "Rest_Name": "Creekside TX",
          "id": 2297,
          "address": "25640 Kuykendahl Rd Ste A",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.551796,
              -97.124571
          ],
          "Rest_Name": "Baylor University",
          "id": 2298,
          "address": "721 S 4th St Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.088633,
              -95.521604
          ],
          "Rest_Name": "Spring North",
          "id": 2299,
          "address": "6615 N Grand Pkwy W Ste 300",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.810015,
              -95.162522
          ],
          "Rest_Name": "Wallisville",
          "id": 2300,
          "address": "5866 E Sam Houston Pkwy N Ste F",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.849017,
              -80.08515
          ],
          "Rest_Name": "The Gardens Mall",
          "id": 2301,
          "address": "3101 Pga Blvd Ste L219",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.995027,
              -95.423951
          ],
          "Rest_Name": "I-45 & Richey",
          "id": 2302,
          "address": "15710 N FWY",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.838797,
              -80.108903
          ],
          "Rest_Name": "Palm Beach Gardens",
          "id": 2303,
          "address": "4655 Pga Blvd Bldg F",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.846418,
              -80.059898
          ],
          "Rest_Name": "Palm Beach Gardens East",
          "id": 2304,
          "address": "11608 US Highway 1",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.491847,
              -81.786788
          ],
          "Rest_Name": "Gulf Coast Fort Myers",
          "id": 2305,
          "address": "10010 University Plaza Drive",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.76982,
              -95.181946
          ],
          "Rest_Name": "Haden & I-10",
          "id": 2306,
          "address": "13630 East Fwy Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.531142,
              -97.187941
          ],
          "Rest_Name": "WACO",
          "id": 2307,
          "address": "1115 N Valley Mills Dr Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.80864,
              -80.090616
          ],
          "Rest_Name": "Northlake Blvd FL",
          "id": 2308,
          "address": "3539 Northlake Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.870438,
              -95.327431
          ],
          "Rest_Name": "Hwy 59 & Little York",
          "id": 2309,
          "address": "3943 Little York Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.034105,
              -95.553421
          ],
          "Rest_Name": "Gleannloch",
          "id": 2310,
          "address": "8675 Spring Cypress Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.91974,
              -95.413393
          ],
          "Rest_Name": "Greenspoint",
          "id": 2311,
          "address": "10701 North Fwy Ste D",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.089045,
              -95.634588
          ],
          "Rest_Name": "Tomball",
          "id": 2312,
          "address": "14067 FM 2920 Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.639298,
              -96.317468
          ],
          "Rest_Name": "College Station 2",
          "id": 2313,
          "address": "1505 University Dr E",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.664249,
              -95.115503
          ],
          "Rest_Name": "San Jacinto Houston",
          "id": 2314,
          "address": "8035 Spencer Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.99639,
              -95.576801
          ],
          "Rest_Name": "Louetta Road",
          "id": 2315,
          "address": "10905 Louetta Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.403693,
              -81.811402
          ],
          "Rest_Name": "Shoppes at Coconut Point",
          "id": 2316,
          "address": "22941 Lyden Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.964272,
              -95.543527
          ],
          "Rest_Name": "Willowbrook",
          "id": 2317,
          "address": "7600 Cypress Creek Pkwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.913272,
              -95.48338
          ],
          "Rest_Name": "249 & Antoine",
          "id": 2318,
          "address": "12625 State Highway 249",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.622351,
              -96.341343
          ],
          "Rest_Name": "College Station",
          "id": 2319,
          "address": "815 University Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.650433,
              -95.152626
          ],
          "Rest_Name": "Pasadena",
          "id": 2320,
          "address": "5759 Fairmont Pkwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.590791,
              -96.330037
          ],
          "Rest_Name": "Wellborn",
          "id": 2321,
          "address": "1437 Wellborn Road",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.665194,
              -95.188335
          ],
          "Rest_Name": "Pasadena West",
          "id": 2322,
          "address": "3635 Spencer Hwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.706506,
              -80.158953
          ],
          "Rest_Name": "Okeechobee and Skees",
          "id": 2323,
          "address": "7000 Okeechobee Blvd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.708223,
              -80.098579
          ],
          "Rest_Name": "West Palm Beach",
          "id": 2324,
          "address": "2380 Palm Beach Lakes Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.812034,
              -95.42234
          ],
          "Rest_Name": "North Loop TX",
          "id": 2325,
          "address": "1249 North Loop W",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.760162,
              -95.362591
          ],
          "Rest_Name": "Theater District",
          "id": 2326,
          "address": "909 Texas St Ste B",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.903812,
              -95.551505
          ],
          "Rest_Name": "Jersey Village",
          "id": 2327,
          "address": "9102 W Sam Houston Pkwy N Ste 400",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.663799,
              -80.202121
          ],
          "Rest_Name": "Wellington",
          "id": 2328,
          "address": "1000 S State Road 7 Ste 2",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.77466,
              -95.397932
          ],
          "Rest_Name": "Heights Blvd.",
          "id": 2329,
          "address": "207 Heights Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.923854,
              -95.601372
          ],
          "Rest_Name": "Copperfield Relo",
          "id": 2330,
          "address": "12210 FM 1960 Rd W",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.819122,
              -95.467393
          ],
          "Rest_Name": "290 and 34th",
          "id": 2331,
          "address": "11150 Northwest Fwy Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.853965,
              -95.51287
          ],
          "Rest_Name": "Northwest Marketplace",
          "id": 2332,
          "address": "13768 Northwest Fwy",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.503349,
              -95.042747
          ],
          "Rest_Name": "Southshore",
          "id": 2333,
          "address": "2555 E League City Pkwy Ste 110",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.746321,
              -95.376237
          ],
          "Rest_Name": "Midtown",
          "id": 2334,
          "address": "2625 Lousiana St Ste K",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.547696,
              -95.131309
          ],
          "Rest_Name": "Baybrook TX II",
          "id": 2335,
          "address": "304 W Bay Area Blvd Ste 600",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.972817,
              -95.691465
          ],
          "Rest_Name": "Spring Cypress",
          "id": 2336,
          "address": "17400 Spring Cypress Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.648232,
              -80.146114
          ],
          "Rest_Name": "Greenacres",
          "id": 2337,
          "address": "6714 Forest Hill Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.747052,
              -95.410295
          ],
          "Rest_Name": "River Oaks",
          "id": 2338,
          "address": "2027 S Shepherd Dr Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.993144,
              -95.751294
          ],
          "Rest_Name": "Fairfield Outlet",
          "id": 2339,
          "address": "28920 Highway 290 Ste H05",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.61651,
              -80.18447
          ],
          "Rest_Name": "Lake Worth and Polo Road",
          "id": 2340,
          "address": "8746 Lake Worth Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.709759,
              -95.401168
          ],
          "Rest_Name": "Med Center",
          "id": 2341,
          "address": "6600 Fannin St Spc A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.721907,
              -95.418865
          ],
          "Rest_Name": "Village Place",
          "id": 2342,
          "address": "5600 Kirby Dr Ste N1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.73229,
              -95.439062
          ],
          "Rest_Name": "Greenway Plaza",
          "id": 2343,
          "address": "3819 Richmond Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.470186,
              -95.093029
          ],
          "Rest_Name": "League City TX",
          "id": 2344,
          "address": "2760 Gulf Fwy S",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.739563,
              -95.462705
          ],
          "Rest_Name": "Houston Galleria",
          "id": 2345,
          "address": "5015 Westheimer Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.91157,
              -95.685449
          ],
          "Rest_Name": "Barker Cypress",
          "id": 2346,
          "address": "9202 Barker Cypress Rd Ste 145",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.750156,
              -95.477381
          ],
          "Rest_Name": "Tanglewood TX",
          "id": 2347,
          "address": "5706 San Felipe St Ste A100",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.87697,
              -95.645856
          ],
          "Rest_Name": "Copperfield South",
          "id": 2348,
          "address": "6887 Hwy 6 N",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.732002,
              -95.466316
          ],
          "Rest_Name": "Richmond & Sage",
          "id": 2349,
          "address": "5176 Richmond Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.691108,
              -95.41572
          ],
          "Rest_Name": "Reliant Park",
          "id": 2350,
          "address": "8505 Main St Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.780353,
              -95.540002
          ],
          "Rest_Name": "Memorial City Mall",
          "id": 2351,
          "address": "303 Memorial City Ste 303",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.587637,
              -80.147145
          ],
          "Rest_Name": "Lantana & Jog",
          "id": 2352,
          "address": "6177 S Jog Rd Ste D15",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.508403,
              -95.186997
          ],
          "Rest_Name": "Friendswood",
          "id": 2353,
          "address": "1705 S Friendswood Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.55817,
              -95.26172
          ],
          "Rest_Name": "Pearland East",
          "id": 2354,
          "address": "2680 Pearland Pkwy Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.779686,
              -95.562167
          ],
          "Rest_Name": "City Centre",
          "id": 2355,
          "address": "12888 Queensbury Ln Ste 142",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.254143,
              -81.800193
          ],
          "Rest_Name": "Naples Mercato",
          "id": 2356,
          "address": "9100 Strada Pl Ste 2110",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.736555,
              -95.514418
          ],
          "Rest_Name": "Mid-Westheimer",
          "id": 2357,
          "address": "8401 Westheimer Rd Ste 100",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.571444,
              -80.112039
          ],
          "Rest_Name": "South Lake Worth",
          "id": 2358,
          "address": "4400 Hypoluxo Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.829395,
              -68.753743
          ],
          "Rest_Name": "Bangor",
          "id": 2359,
          "address": "583 Stillwater Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.698307,
              -95.518431
          ],
          "Rest_Name": "HBU",
          "id": 2360,
          "address": "7457 Southwest Fwy Ste 300",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.526247,
              -80.185791
          ],
          "Rest_Name": "West Boynton Beach",
          "id": 2361,
          "address": "8790 Boynton Beach Blvd Bldg B1",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.768398,
              -95.624098
          ],
          "Rest_Name": "Energy Corridor",
          "id": 2362,
          "address": "1120 Eldridge Pkwy Ste 100",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.736102,
              -95.586072
          ],
          "Rest_Name": "Royal Oaks",
          "id": 2363,
          "address": "11805 Westheimer Rd Ste 330",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.542687,
              -80.090222
          ],
          "Rest_Name": "Boynton Beach",
          "id": 2364,
          "address": "1780 N Congress Ave Ste 100",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.55642,
              -95.386371
          ],
          "Rest_Name": "Pearland",
          "id": 2365,
          "address": "10645 Broadway St Ste 118",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.5061,
              -80.1228
          ],
          "Rest_Name": "South Boynton Beach",
          "id": 2366,
          "address": "11500 S Military Trl Ste 102",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.172115,
              -81.800797
          ],
          "Rest_Name": "Naples Plaza",
          "id": 2367,
          "address": "2085 Tamiami Trl N",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.787698,
              -95.718353
          ],
          "Rest_Name": "Katy",
          "id": 2368,
          "address": "1260 Fry Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.513494,
              -80.056953
          ],
          "Rest_Name": "East Boynton Beach",
          "id": 2369,
          "address": "1620 S Federal Hwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.814954,
              -95.771268
          ],
          "Rest_Name": "Morton Ranch",
          "id": 2370,
          "address": "2812 W Grand Parkway N ",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.644635,
              -95.577063
          ],
          "Rest_Name": "The Grid",
          "id": 2371,
          "address": "11249 W Airport Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.677716,
              -95.642902
          ],
          "Rest_Name": "Four Corners TX",
          "id": 2372,
          "address": "9319 Highway 6 S Ste D",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.761454,
              -95.75118
          ],
          "Rest_Name": "Katy South",
          "id": 2373,
          "address": "1427 S Mason Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.61984,
              -95.568
          ],
          "Rest_Name": "Stafford TX",
          "id": 2374,
          "address": "3549 S. Main Street",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.457981,
              -80.118699
          ],
          "Rest_Name": "West Delray Beach",
          "id": 2375,
          "address": "4789 W Atlantic Ave",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.78692,
              -95.792483
          ],
          "Rest_Name": "Katy West",
          "id": 2376,
          "address": "24600 Katy Fwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.439234,
              -80.078439
          ],
          "Rest_Name": "Delray Beach",
          "id": 2377,
          "address": "520 Linton Blvd Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.734063,
              -95.775252
          ],
          "Rest_Name": "Cinco Ranch",
          "id": 2378,
          "address": "23660 Westheimer Pkwy # 660A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.564055,
              -95.562294
          ],
          "Rest_Name": "Missouri City",
          "id": 2379,
          "address": "6245 Highway 6 Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.594058,
              -95.622797
          ],
          "Rest_Name": "Sugarland",
          "id": 2380,
          "address": "2280 Lone Star Dr",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.660821,
              -95.714133
          ],
          "Rest_Name": "Aliana",
          "id": 2381,
          "address": "19235 W Bellfort St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.400771,
              -80.12049
          ],
          "Rest_Name": "North Boca Raton",
          "id": 2382,
          "address": "5560 N Military Trl",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.365991,
              -80.202361
          ],
          "Rest_Name": "Westwinds West Boca",
          "id": 2383,
          "address": "9930 Glades Rd Ste F8",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.065696,
              -81.69842
          ],
          "Rest_Name": "South Naples",
          "id": 2384,
          "address": "6821 Collier Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.092538,
              -97.38551
          ],
          "Rest_Name": "Bird Creek Crossing",
          "id": 2385,
          "address": "3550 S General Bruce Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.698158,
              -95.814494
          ],
          "Rest_Name": "Cinco South",
          "id": 2386,
          "address": "25226 Farm to Market 1093",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.368752,
              -80.128054
          ],
          "Rest_Name": "Central Boca Raton",
          "id": 2387,
          "address": "2301 Glades Rd Ste 300",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.117267,
              -97.417944
          ],
          "Rest_Name": "Temple West",
          "id": 2388,
          "address": "7121 W Adams Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.349524,
              -80.157448
          ],
          "Rest_Name": "Garden Shops Boca Raton",
          "id": 2389,
          "address": "7028 W Palmetto Park Rd Ste 106",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.365568,
              -80.078995
          ],
          "Rest_Name": "East Boca Raton",
          "id": 2390,
          "address": "1666 N Federal Hwy Ste A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.563681,
              -95.684298
          ],
          "Rest_Name": "Greatwood",
          "id": 2391,
          "address": "19832 Southwest Fwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.318392,
              -80.148341
          ],
          "Rest_Name": "West Hillsboro Boulevard",
          "id": 2392,
          "address": "3311 W Hillsboro Blvd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.287408,
              -80.250916
          ],
          "Rest_Name": "North Coral Springs",
          "id": 2393,
          "address": "4600 N University Dr",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.283501,
              -80.202614
          ],
          "Rest_Name": "Turtle Run at Coral Springs",
          "id": 2394,
          "address": "4220 N State Road 7",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.307095,
              -80.093134
          ],
          "Rest_Name": "Deerfield Beach",
          "id": 2395,
          "address": "777 S Federal Hwy",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.546221,
              -95.750252
          ],
          "Rest_Name": "Rosenberg",
          "id": 2396,
          "address": "24004 Southwest Fwy Ste 102",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.252864,
              -80.252319
          ],
          "Rest_Name": "Coral Springs",
          "id": 2397,
          "address": "1775 N University Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.260632,
              -80.09892
          ],
          "Rest_Name": "Pompano",
          "id": 2398,
          "address": "2390 N Federal Hwy Ste 104",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.235515,
              -80.203871
          ],
          "Rest_Name": "Margate",
          "id": 2399,
          "address": "5671 W Atlantic Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.191573,
              -80.252616
          ],
          "Rest_Name": "Lauderhill",
          "id": 2400,
          "address": "5435 N University Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.204836,
              -80.147477
          ],
          "Rest_Name": "Cypress Creek",
          "id": 2401,
          "address": "6320 N Andrews Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.149969,
              -80.324577
          ],
          "Rest_Name": "Sawgrass Mills Food Court",
          "id": 2402,
          "address": "12801 W Sunrise Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.187778,
              -80.11561
          ],
          "Rest_Name": "Fort Lauderdale North",
          "id": 2403,
          "address": "4850 N Federal Hwy",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.147349,
              -80.256339
          ],
          "Rest_Name": "Sunrise and University",
          "id": 2404,
          "address": "1760 N University Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.072001,
              -97.665832
          ],
          "Rest_Name": "Harker Heights",
          "id": 2405,
          "address": "201 E Central Texas Expy Ste 1400",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.121556,
              -80.330717
          ],
          "Rest_Name": "Sawgrass Ikea",
          "id": 2406,
          "address": "129 NW 136th Ave # A190",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.150574,
              -80.11891
          ],
          "Rest_Name": "Bal Harbour Square Fort Lauderdale",
          "id": 2407,
          "address": "1736 N Federal Hwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.094295,
              -80.250364
          ],
          "Rest_Name": "Davie Tower Shops",
          "id": 2408,
          "address": "2110 S University Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.067606,
              -80.36306
          ],
          "Rest_Name": "Weston",
          "id": 2409,
          "address": "4477 Weston Rd # 79",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.130868,
              -97.798133
          ],
          "Rest_Name": "Fort Hood",
          "id": 2410,
          "address": "Fort Hood Shopping Center",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.091092,
              -80.180294
          ],
          "Rest_Name": "Riverbend Fort Lauderdale",
          "id": 2411,
          "address": "2610 W Broward Blvd Ste 104",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.100851,
              -80.126664
          ],
          "Rest_Name": "Fort Lauderdale South",
          "id": 2412,
          "address": "1515 SE 17th St Ste 101",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.046949,
              -80.251057
          ],
          "Rest_Name": "Davie South",
          "id": 2413,
          "address": "5796 South University Dr ",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.120257,
              -97.861477
          ],
          "Rest_Name": "Copperas Cove",
          "id": 2414,
          "address": "3018 E Business 190",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.006839,
              -80.356265
          ],
          "Rest_Name": "West Pembroke Pines",
          "id": 2415,
          "address": "15880 Pines Blvd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.048639,
              -80.159491
          ],
          "Rest_Name": "East Hollywood",
          "id": 2416,
          "address": "1780 Stirling Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.008504,
              -80.294944
          ],
          "Rest_Name": "Pembroke Pines",
          "id": 2417,
          "address": "11041 Pines Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.033066,
              -80.142053
          ],
          "Rest_Name": "Dania Beach",
          "id": 2418,
          "address": "1774 Sheridan St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.980247,
              -80.356945
          ],
          "Rest_Name": "Miramar",
          "id": 2419,
          "address": "3231 SW 160th Ave Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.941296,
              -80.306469
          ],
          "Rest_Name": "Miami Gardens",
          "id": 2420,
          "address": "18461 NW 67th Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.940701,
              -80.244756
          ],
          "Rest_Name": "Miami Gardens & NW 27th Avenue",
          "id": 2421,
          "address": "18299 NW 27th Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.957414,
              -80.146767
          ],
          "Rest_Name": "Aventura Mall Food Court",
          "id": 2422,
          "address": "19565 Biscayne Blvd Ste FH1",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.912891,
              -80.330937
          ],
          "Rest_Name": "Miami Lakes",
          "id": 2423,
          "address": "8000 NW 154th St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.950186,
              -80.145689
          ],
          "Rest_Name": "Aventura",
          "id": 2424,
          "address": "18815 Biscayne Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.894804,
              -80.350866
          ],
          "Rest_Name": "Hialeah Gardens",
          "id": 2425,
          "address": "7925 Hialeah Gardens Blvd Ste 1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.928778,
              -80.183109
          ],
          "Rest_Name": "North Miami Beach",
          "id": 2426,
          "address": "886 N Miami Beach Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.912522,
              -80.157698
          ],
          "Rest_Name": "North Miami FL",
          "id": 2427,
          "address": "14776 Biscayne Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.867037,
              -80.291961
          ],
          "Rest_Name": "Hialeah 2",
          "id": 2428,
          "address": "405 W 49th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.827346,
              -80.368546
          ],
          "Rest_Name": "Landmark North Doral",
          "id": 2429,
          "address": "5875 NW 105th Ct Ste 103",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.544465,
              -97.561399
          ],
          "Rest_Name": "Hutto TX",
          "id": 2430,
          "address": "326 Ed Schmidt Blvd Ste 100",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.649201,
              -97.677597
          ],
          "Rest_Name": "Republic Square",
          "id": 2431,
          "address": "900 N Austin Ave Ste 102",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.808885,
              -80.315344
          ],
          "Rest_Name": "Airport West",
          "id": 2432,
          "address": "7230 NW 36th St # 1",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.789777,
              -80.38703
          ],
          "Rest_Name": "Beacon Lakes",
          "id": 2433,
          "address": "1700 NW 117th Pl Unit 101",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.793463,
              -80.337645
          ],
          "Rest_Name": "Doral 87th Avenue",
          "id": 2434,
          "address": "2000 NW 87th Ave Ste 1",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.80706,
              -80.194655
          ],
          "Rest_Name": "Midtown Miami",
          "id": 2435,
          "address": "3201 N Miami Ave Ste 100",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.769252,
              -80.359821
          ],
          "Rest_Name": "Fountain Square",
          "id": 2436,
          "address": "10141 W Flagler St Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.781982,
              -80.286966
          ],
          "Rest_Name": "Blue Lagoon",
          "id": 2437,
          "address": "5600 Waterford District Dr Ste 11",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.556406,
              -97.687919
          ],
          "Rest_Name": "University Oaks",
          "id": 2438,
          "address": "201 University Oaks Blvd Ste 1350",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.773037,
              -80.264107
          ],
          "Rest_Name": "Lejeune Road Miami",
          "id": 2439,
          "address": "110 NW 42nd Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.46269,
              -97.59851
          ],
          "Rest_Name": "Pflugerville",
          "id": 2440,
          "address": "2424 FM 685",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.749193,
              -80.336108
          ],
          "Rest_Name": "Coral Way - Westchester",
          "id": 2441,
          "address": "2200 SW 87th Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.729034,
              -80.42804
          ],
          "Rest_Name": "West Bird Road",
          "id": 2442,
          "address": "14401 SW 42nd St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.765576,
              -80.193144
          ],
          "Rest_Name": "Brickell",
          "id": 2443,
          "address": "891 South Miami Avenue",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.749328,
              -80.254359
          ],
          "Rest_Name": "Coral Gables",
          "id": 2444,
          "address": "2235 SW 37th Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.483262,
              -97.681878
          ],
          "Rest_Name": "Round Rock",
          "id": 2445,
          "address": "150 Sundance Pkwy Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.509436,
              -97.712165
          ],
          "Rest_Name": "Smyers",
          "id": 2446,
          "address": "17100 N Rm 620 Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.684578,
              -80.415201
          ],
          "Rest_Name": "West Kendall",
          "id": 2447,
          "address": "13612 SW 88th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.686898,
              -80.36474
          ],
          "Rest_Name": "Kendall Mall",
          "id": 2448,
          "address": "10600 N Kendall Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.699534,
              -80.295008
          ],
          "Rest_Name": "South Miami",
          "id": 2449,
          "address": "6290 S Dixie Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.586461,
              -97.857104
          ],
          "Rest_Name": "Leander",
          "id": 2450,
          "address": "821 N US 183 ",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.413068,
              -97.68771
          ],
          "Rest_Name": "Scofield Farms",
          "id": 2451,
          "address": "1700 W Parmer Ln Ste 200",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.657071,
              -80.327515
          ],
          "Rest_Name": "Pinecrest",
          "id": 2452,
          "address": "12227 South Dixie Highway",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.524857,
              -97.819717
          ],
          "Rest_Name": "Cedar Park",
          "id": 2453,
          "address": "909 E Whitestone Blvd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.445721,
              -97.744081
          ],
          "Rest_Name": "Millwood",
          "id": 2454,
          "address": "6301 W Parmer Ln Ste 201",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.626448,
              -80.394877
          ],
          "Rest_Name": "Coral Reef 2",
          "id": 2455,
          "address": "15221 SW 127th Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.478321,
              -97.800583
          ],
          "Rest_Name": "Lakeline Mall",
          "id": 2456,
          "address": "11301 Lakeline Blvd Ste 230",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.389926,
              -97.733342
          ],
          "Rest_Name": "Arboretum",
          "id": 2457,
          "address": "10515 N Mopac Expy Ste B200",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.58961,
              -80.35868
          ],
          "Rest_Name": "Point Royale Cutler Bay",
          "id": 2458,
          "address": "19151 S Dixie Hwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.358578,
              -97.734726
          ],
          "Rest_Name": "Anderson Lane",
          "id": 2459,
          "address": "2700 W Anderson Ln Ste 204",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.30565,
              -97.707981
          ],
          "Rest_Name": "Mueller",
          "id": 2460,
          "address": "1201 Barbara Jordan Blvd Ste 1300",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.312467,
              -97.738615
          ],
          "Rest_Name": "45th & Lamar",
          "id": 2461,
          "address": "4400 N Lamar Blvd Ste 101",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.4115,
              -97.8502
          ],
          "Rest_Name": "Four Points",
          "id": 2462,
          "address": "7710 N FM 620 Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.285652,
              -97.741979
          ],
          "Rest_Name": "U of Texas",
          "id": 2463,
          "address": "2230 Guadalupe St # 32",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.263372,
              -97.731508
          ],
          "Rest_Name": "Plaza Saltillo",
          "id": 2464,
          "address": "1109 E 5th St Ste 130",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.269856,
              -97.742378
          ],
          "Rest_Name": "Congress",
          "id": 2465,
          "address": "801 Congress Ave. Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.24213,
              -97.727277
          ],
          "Rest_Name": "South Shore",
          "id": 2466,
          "address": "1920 E Riverside Dr Ste E-150",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.479033,
              -80.441929
          ],
          "Rest_Name": "Homestead",
          "id": 2467,
          "address": "2500 NE 10th Ct",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.233939,
              -97.739224
          ],
          "Rest_Name": "Oltorf",
          "id": 2468,
          "address": "1616 E Oltorf St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.274705,
              -97.799339
          ],
          "Rest_Name": "Westbank",
          "id": 2469,
          "address": "3300 Bee Caves Rd Ste 670",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.247644,
              -97.775468
          ],
          "Rest_Name": "South Lamar",
          "id": 2470,
          "address": "2320 S Lamar Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.201265,
              -97.768496
          ],
          "Rest_Name": "Stassney",
          "id": 2471,
          "address": "610 E Stassney Ln Ste B",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.405363,
              -99.765402
          ],
          "Rest_Name": "Abilene",
          "id": 2472,
          "address": "3800 S Clack St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.229169,
              -97.821024
          ],
          "Rest_Name": "Sunset Valley",
          "id": 2473,
          "address": "5400 Brodie Ln Ste 1230",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.158366,
              -97.791029
          ],
          "Rest_Name": "Southpark Meadows",
          "id": 2474,
          "address": "9600 S Interstate 35 Ste S350",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.200486,
              -97.864015
          ],
          "Rest_Name": "South Austin",
          "id": 2475,
          "address": "5000 W Slaughter Ln Bldg 3",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.20717,
              -97.97723
          ],
          "Rest_Name": "Belterra",
          "id": 2476,
          "address": "12400 Hwy 290",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              30.015155,
              -97.866743
          ],
          "Rest_Name": "Kyle",
          "id": 2477,
          "address": "5180 S FM 1626 Ste 120",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.885571,
              -97.940987
          ],
          "Rest_Name": "San Marcos",
          "id": 2478,
          "address": "401 N Lbj Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.188631,
              -101.866807
          ],
          "Rest_Name": "Amarillo",
          "id": 2479,
          "address": "2414 S Georgia St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.827032,
              -97.985293
          ],
          "Rest_Name": "San Marcos Outlets",
          "id": 2480,
          "address": "1020 Center Point Rd Ste 112",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.700977,
              -98.095527
          ],
          "Rest_Name": "New Braunfels",
          "id": 2481,
          "address": "156 State Highway 46 S Ste 110",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.57199,
              -98.23858
          ],
          "Rest_Name": "Cibolo",
          "id": 2482,
          "address": "872 Cibolo Valley Dr.",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.571994,
              -98.326307
          ],
          "Rest_Name": "Forum",
          "id": 2483,
          "address": "14540 Forum Pkwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.550166,
              -98.308005
          ],
          "Rest_Name": "Kitty Hawk",
          "id": 2484,
          "address": "540 Kitty Hawk Rd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.647749,
              -98.449806
          ],
          "Rest_Name": "TPC & 281",
          "id": 2485,
          "address": "22106 US Highway 281 N",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.596676,
              -98.419196
          ],
          "Rest_Name": "Bulverde & 1604",
          "id": 2486,
          "address": "17202 Bulverde Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.5124,
              -98.39628
          ],
          "Rest_Name": "Walzem & I-35",
          "id": 2487,
          "address": "8206 I-35 North",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.478526,
              -98.364761
          ],
          "Rest_Name": "Woodlake",
          "id": 2488,
          "address": "6302 FM 78",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.609469,
              -98.512211
          ],
          "Rest_Name": "Vineyard",
          "id": 2489,
          "address": "1201 N Loop 1604 W Ste 119",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.566236,
              -98.48475
          ],
          "Rest_Name": "281 & Bitters",
          "id": 2490,
          "address": "190 W Bitters Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.519679,
              -98.501378
          ],
          "Rest_Name": "San Pedro",
          "id": 2491,
          "address": "438 NW Loop 410 Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.498668,
              -98.480652
          ],
          "Rest_Name": "Quarry",
          "id": 2492,
          "address": "7322 Jones Maltsberger Rd Ste 206",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.462383,
              -98.465518
          ],
          "Rest_Name": "Alamo Heights",
          "id": 2493,
          "address": "3928 Broadway",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.550582,
              -98.583183
          ],
          "Rest_Name": "Huebner Oaks",
          "id": 2494,
          "address": "11745 W Interstate 10 Ste 401",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.588063,
              -101.869452
          ],
          "Rest_Name": "Texas Tech Red Raiders",
          "id": 2495,
          "address": "2411 Glenna Goodacre Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.423806,
              -98.484332
          ],
          "Rest_Name": "Rivercenter",
          "id": 2496,
          "address": "849 E Commerce St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.510427,
              -98.57829
          ],
          "Rest_Name": "Medical District",
          "id": 2497,
          "address": "4727 Medical Dr Ste 102",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.352259,
              -98.437233
          ],
          "Rest_Name": "Brooks City Base TX",
          "id": 2498,
          "address": "3155 SE Military Dr Ste 101",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.548579,
              -98.66504
          ],
          "Rest_Name": "Bandera Road",
          "id": 2499,
          "address": "11411 Bandera Rd Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.514289,
              -98.634497
          ],
          "Rest_Name": "Guilbeau Road",
          "id": 2500,
          "address": "7989 Bandera Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.518444,
              -101.903597
          ],
          "Rest_Name": "Kingsgate",
          "id": 2501,
          "address": "8201 Quaker Ave Unit 102",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.56741,
              -101.944974
          ],
          "Rest_Name": "West End Lubbock",
          "id": 2502,
          "address": "2912 W Loop 289 Ste 102",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.433612,
              -100.456283
          ],
          "Rest_Name": "Knickerbocker",
          "id": 2503,
          "address": "3515 S Jackson St",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.48539,
              -101.90457
          ],
          "Rest_Name": "Lubbock South",
          "id": 2504,
          "address": "11909 Quaker Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.523453,
              -101.956436
          ],
          "Rest_Name": "82nd & Milwaukee",
          "id": 2505,
          "address": "7715 Milwaukee Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.357387,
              -98.540459
          ],
          "Rest_Name": "SW Military",
          "id": 2506,
          "address": "2741 SW Military Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.493576,
              -98.706432
          ],
          "Rest_Name": "Alamo Ranch",
          "id": 2507,
          "address": "5623 W Loop 1604 N",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.42863,
              -100.51241
          ],
          "Rest_Name": "San Angelo",
          "id": 2508,
          "address": "5590 SHERWOOD WAY",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.436426,
              -98.649401
          ],
          "Rest_Name": "Westover Marketplace",
          "id": 2509,
          "address": "8227 State Highway 151 Ste 105",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.428031,
              -98.712241
          ],
          "Rest_Name": "Potranco III",
          "id": 2510,
          "address": "407 W Loop 1604 S Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              29.42115,
              -98.77483
          ],
          "Rest_Name": "Potranco West",
          "id": 2511,
          "address": "14218 Potranco Road",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.412018,
              -104.691498
          ],
          "Rest_Name": "U of N. Colorado",
          "id": 2512,
          "address": "807 17th St Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.71092,
              -97.368595
          ],
          "Rest_Name": "Corpus Christi",
          "id": 2513,
          "address": "4938 S Staples St Ste E1",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.941812,
              -104.612094
          ],
          "Rest_Name": "Falcon",
          "id": 2514,
          "address": "7565 Falcon Market Pl",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.604301,
              -104.707438
          ],
          "Rest_Name": "Southlands",
          "id": 2515,
          "address": "24100 E Commons Ave Ste 101",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.160736,
              -104.805715
          ],
          "Rest_Name": "Cheyenne",
          "id": 2516,
          "address": "1508 Dell Range Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.957012,
              -104.775632
          ],
          "Rest_Name": "Brighton - Prairie Center",
          "id": 2517,
          "address": "2046 Prairie Center Pkwy",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.611253,
              -104.752579
          ],
          "Rest_Name": "Smoky Hill",
          "id": 2518,
          "address": "20209 E Smoky Hill Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.764052,
              -104.774668
          ],
          "Rest_Name": "I 70 & Tower",
          "id": 2519,
          "address": "3455 N Salida St Unit D",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.434396,
              -103.195928
          ],
          "Rest_Name": "Clovis NM",
          "id": 2520,
          "address": "3501 N Prince St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.518709,
              -104.770353
          ],
          "Rest_Name": "Parker",
          "id": 2521,
          "address": "18701 E Mainstreet Ste 1",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.558086,
              -104.776917
          ],
          "Rest_Name": "Crown Point Relo",
          "id": 2522,
          "address": "18400 Cottonwood Dr Ste 101",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.640233,
              -104.792049
          ],
          "Rest_Name": "Buckley & Quincy",
          "id": 2523,
          "address": "4205 S Buckley Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.674373,
              -104.810645
          ],
          "Rest_Name": "Iliff & Chambers",
          "id": 2524,
          "address": "15280 E Iliff Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.92668,
              -104.71533
          ],
          "Rest_Name": "Dublin CO",
          "id": 2525,
          "address": "6530 TUTT BOULEVARD",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.59457,
              -104.806401
          ],
          "Rest_Name": "Arapahoe & Parker",
          "id": 2526,
          "address": "6710 S Cornerstar Way Ste A",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.73993,
              -104.827753
          ],
          "Rest_Name": "Fitzsimons",
          "id": 2527,
          "address": "13700 E Colfax Ave Unit A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.877086,
              -104.718765
          ],
          "Rest_Name": "Powers",
          "id": 2528,
          "address": "3026 New Center Pt",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.303272,
              -104.614993
          ],
          "Rest_Name": "Pueblo",
          "id": 2529,
          "address": "3230 N Elizabeth St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.777639,
              -104.846422
          ],
          "Rest_Name": "45th & Peoria",
          "id": 2530,
          "address": "4490 Peoria St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.595465,
              -104.84874
          ],
          "Rest_Name": "Centennial",
          "id": 2531,
          "address": "12073 E Arapahoe Rd Ste 160",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.694589,
              -104.866242
          ],
          "Rest_Name": "Havana",
          "id": 2532,
          "address": "1205 S Havana St Unit B1",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.845476,
              -104.756686
          ],
          "Rest_Name": "Citadel",
          "id": 2533,
          "address": "895 N Academy Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.674409,
              -104.869623
          ],
          "Rest_Name": "London Square Relo",
          "id": 2534,
          "address": "2300 S Parker Rd ",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.904338,
              -104.774162
          ],
          "Rest_Name": "FlyingDog",
          "id": 2535,
          "address": "5079 N Academy Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.98861,
              -104.796164
          ],
          "Rest_Name": "Interquest Pkwy",
          "id": 2536,
          "address": "1846 Democracy Pt",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.562747,
              -104.875857
          ],
          "Rest_Name": "Park Meadows Mall Food Court",
          "id": 2537,
          "address": "8515 Park Meadows Center Dr Ste 3001 # SPC 252",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.567257,
              -104.880515
          ],
          "Rest_Name": "Park Meadows Relo",
          "id": 2538,
          "address": "8255 S Chester St Ste 100",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.414491,
              -104.86401
          ],
          "Rest_Name": "Castle Rock Shoppes",
          "id": 2539,
          "address": "5642 Allen Way Ste 104",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.7579,
              -104.901543
          ],
          "Rest_Name": "Stapleton",
          "id": 2540,
          "address": "7400 E 29th Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.537046,
              -104.879352
          ],
          "Rest_Name": "Lone Tree",
          "id": 2541,
          "address": "9500 Heritage Hills Cir Ste 700",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.946376,
              -104.802166
          ],
          "Rest_Name": "Chapel Hills",
          "id": 2542,
          "address": "7818 N Academy Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.596871,
              -104.888084
          ],
          "Rest_Name": "Arapahoe Relo",
          "id": 2543,
          "address": "6570 S Yosemite St Ste D",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.62376,
              -104.896024
          ],
          "Rest_Name": "Belleview Promenade",
          "id": 2544,
          "address": "8000 E Belleview Ave Ste F20",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.652423,
              -104.903217
          ],
          "Rest_Name": "Tiffany",
          "id": 2545,
          "address": "7350 E Hampden Ave Unit B-2",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.41919,
              -104.87774
          ],
          "Rest_Name": "Castle Rock - Promenade Pkwy",
          "id": 2546,
          "address": "5954 Promenade Pkwy.",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.701821,
              -104.912519
          ],
          "Rest_Name": "Monaco",
          "id": 2547,
          "address": "820 S Monaco Pkwy Unit 1B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.803973,
              -104.93083
          ],
          "Rest_Name": "Commerce City",
          "id": 2548,
          "address": "5988 Dahlia St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.902762,
              -104.818155
          ],
          "Rest_Name": "CS Univ Village",
          "id": 2549,
          "address": "5102 N Nevada Ave Ste 170",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.728341,
              -104.940977
          ],
          "Rest_Name": "Colorado",
          "id": 2550,
          "address": "745 Colorado Blvd Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.833131,
              -104.823351
          ],
          "Rest_Name": "Tejon",
          "id": 2551,
          "address": "17 S Tejon St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.801722,
              -104.819078
          ],
          "Rest_Name": "Broadmoor",
          "id": 2552,
          "address": "2130 Southgate Rd Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.666446,
              -104.939882
          ],
          "Rest_Name": "U Hills",
          "id": 2553,
          "address": "2760 S Colorado Blvd Unit M",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.718549,
              -104.949577
          ],
          "Rest_Name": "Cherry Creek",
          "id": 2554,
          "address": "140 Steele St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.739977,
              -104.957214
          ],
          "Rest_Name": "Lowenstein Theatre",
          "id": 2555,
          "address": "2500 E Colfax Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.914773,
              -104.976009
          ],
          "Rest_Name": "Thornton",
          "id": 2556,
          "address": "951 E 120th Ave Unit A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.408322,
              -105.007667
          ],
          "Rest_Name": "Loveland Factory Shops",
          "id": 2557,
          "address": "1569 Fall River Dr Ste 105",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.897084,
              -104.852678
          ],
          "Rest_Name": "Garden of the Gods",
          "id": 2558,
          "address": "1370 Garden of the Gods Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.542614,
              -104.940953
          ],
          "Rest_Name": "Highlands Ranch",
          "id": 2559,
          "address": "9579 S University Blvd Lot 3A",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.871139,
              -104.976335
          ],
          "Rest_Name": "Lambertson Lakes",
          "id": 2560,
          "address": "815 Thornton Pkwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.958933,
              -104.992827
          ],
          "Rest_Name": "Orchard Town Center",
          "id": 2561,
          "address": "14452 Orchard Pkwy Unit 300",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.678281,
              -104.967812
          ],
          "Rest_Name": "Evans",
          "id": 2562,
          "address": "1644 E Evans Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.580227,
              -104.961312
          ],
          "Rest_Name": "Dry Creek",
          "id": 2563,
          "address": "7515 S University Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.523765,
              -105.03686
          ],
          "Rest_Name": "Ft Collins",
          "id": 2564,
          "address": "2120 E Harmony Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.711425,
              -104.983177
          ],
          "Rest_Name": "Alameda",
          "id": 2565,
          "address": "371 E Alameda Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.725503,
              -104.987019
          ],
          "Rest_Name": "6th & Broadway",
          "id": 2566,
          "address": "550 N Broadway Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.745398,
              -104.991628
          ],
          "Rest_Name": "California",
          "id": 2567,
          "address": "1600 California St Unit 7",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.750284,
              -104.999073
          ],
          "Rest_Name": "LODO",
          "id": 2568,
          "address": "1480 16th St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.65349,
              -104.992173
          ],
          "Rest_Name": "Hampden",
          "id": 2569,
          "address": "333 W Hampden Ave Ste 110",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.613454,
              -104.988389
          ],
          "Rest_Name": "Littleton",
          "id": 2570,
          "address": "5699 S Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.884269,
              -105.024708
          ],
          "Rest_Name": "104th & Federal",
          "id": 2571,
          "address": "10320 Federal Blvd Unit 100",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.828657,
              -105.025453
          ],
          "Rest_Name": "Westminster Plaza",
          "id": 2572,
          "address": "7303 Federal Blvd Unit 1",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.578284,
              -105.077412
          ],
          "Rest_Name": "CSU",
          "id": 2573,
          "address": "649 S College Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.762019,
              -105.034818
          ],
          "Rest_Name": "Lowell",
          "id": 2574,
          "address": "3600 W 32nd Ave Ste A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.537418,
              -105.013889
          ],
          "Rest_Name": "Wildcat",
          "id": 2575,
          "address": "2209 W Wildcat Reserve Pkwy Unit E1A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.912443,
              -105.051281
          ],
          "Rest_Name": "Sheridan",
          "id": 2576,
          "address": "5160 W 120th Ave. Unit B",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.407528,
              -105.097842
          ],
          "Rest_Name": "Taft",
          "id": 2577,
          "address": "1421 W Eisenhower Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.189192,
              -105.101798
          ],
          "Rest_Name": "17th & Main",
          "id": 2578,
          "address": "1708  Main Street",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.856161,
              -105.082426
          ],
          "Rest_Name": "88th & Wadsworth",
          "id": 2579,
          "address": "8797 Wadsworth Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.793934,
              -105.080282
          ],
          "Rest_Name": "55th & Wads RELO",
          "id": 2580,
          "address": "5324 Wadsworth Byp Unit G",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.98639,
              -105.097962
          ],
          "Rest_Name": "Lafayette Crossings",
          "id": 2581,
          "address": "548 W South Boulder Rd Unit D",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.152854,
              -105.111126
          ],
          "Rest_Name": "Longmont",
          "id": 2582,
          "address": "1100 Ken Pratt Blvd Ste B",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.740578,
              -105.07842
          ],
          "Rest_Name": "Colfax",
          "id": 2583,
          "address": "7355 W Colfax Ave Unit 103",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.710324,
              -105.08215
          ],
          "Rest_Name": "Alameda & Wadsworth",
          "id": 2584,
          "address": "355 S Wadsworth Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.660754,
              -105.080916
          ],
          "Rest_Name": "Wadsworth & Hampden",
          "id": 2585,
          "address": "3170 S Wadsworth Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.616581,
              -105.090435
          ],
          "Rest_Name": "Bow Mar",
          "id": 2586,
          "address": "8100 W Crestline Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.61172,
              -105.09678
          ],
          "Rest_Name": "Southwest Plaza FC",
          "id": 2587,
          "address": "8501 W Bowles Ave Ste 2020",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.928411,
              -105.143128
          ],
          "Rest_Name": "Flatiron Crossing",
          "id": 2588,
          "address": "1940 Coalton Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.811396,
              -105.139667
          ],
          "Rest_Name": "64th Ave. & Ward Rd.",
          "id": 2589,
          "address": "12550 W 64th Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.717486,
              -105.133629
          ],
          "Rest_Name": "Union Relo",
          "id": 2590,
          "address": "145 Union Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.763151,
              -105.140088
          ],
          "Rest_Name": "Youngfield",
          "id": 2591,
          "address": "3294 Youngfield St Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.966259,
              -105.163966
          ],
          "Rest_Name": "Louisville",
          "id": 2592,
          "address": "375 McCaslin Blvd Ste D",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.036278,
              -102.123368
          ],
          "Rest_Name": "Midland",
          "id": 2593,
          "address": "2820 West Loop 250 North ",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.580354,
              -105.135026
          ],
          "Rest_Name": "Ken Caryl",
          "id": 2594,
          "address": "12512 W Ken Caryl Ave Unit C",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.734038,
              -105.160103
          ],
          "Rest_Name": "Colorado Mills",
          "id": 2595,
          "address": "14255 W Colfax Ave Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.016357,
              -105.257952
          ],
          "Rest_Name": "29th Street",
          "id": 2596,
          "address": "1650 28th St Unit 1224",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.8899,
              -102.35041
          ],
          "Rest_Name": "Odessa",
          "id": 2597,
          "address": "3810 E 42nd St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.309589,
              -105.559519
          ],
          "Rest_Name": "Laramie",
          "id": 2598,
          "address": "2615 E Grand Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.630328,
              -106.071411
          ],
          "Rest_Name": "Silverthorne",
          "id": 2599,
          "address": "247 Rainbow Dr # C",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              27.573353,
              -99.474004
          ],
          "Rest_Name": "Laredo",
          "id": 2600,
          "address": "1211 Del Mar Blvd.; Suite #1",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.186609,
              -97.721518
          ],
          "Rest_Name": "Harlingen",
          "id": 2601,
          "address": "410 Dixieland Rd",
          "rating": 4.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              25.951962,
              -97.487429
          ],
          "Rest_Name": "Brownsville",
          "id": 2602,
          "address": "2425 Paredes Line Rd",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.268379,
              -98.218471
          ],
          "Rest_Name": "10th and Trenton",
          "id": 2603,
          "address": "7300 N 10th St Ste 30",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.202566,
              -98.20195
          ],
          "Rest_Name": "Pharr",
          "id": 2604,
          "address": "500 N Jackson Rd Ste N1",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              26.196019,
              -98.260688
          ],
          "Rest_Name": "The Palms",
          "id": 2605,
          "address": "3400 W Expressway 83",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.677816,
              -105.952881
          ],
          "Rest_Name": "Santa Fe",
          "id": 2606,
          "address": "1001 S Saint Francis Dr Ste 101",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.088667,
              -106.514546
          ],
          "Rest_Name": "Juan Tabo",
          "id": 2607,
          "address": "1000 Juan Tabo Blvd NE",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.174957,
              -106.574974
          ],
          "Rest_Name": "Paseo del Norte",
          "id": 2608,
          "address": "8120 San Pedro Dr NE",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.108872,
              -106.568504
          ],
          "Rest_Name": "ABQ Uptown",
          "id": 2609,
          "address": "6810 Menaul Blvd NE",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.130585,
              -106.586862
          ],
          "Rest_Name": "San Mateo & Montgomery",
          "id": 2610,
          "address": "4331 San Mateo Blvd NE",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.204546,
              -106.649959
          ],
          "Rest_Name": "Cottonwood",
          "id": 2611,
          "address": "3400 NM 528 NW Ste B",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.080594,
              -106.616873
          ],
          "Rest_Name": "UNM",
          "id": 2612,
          "address": "2608 Central Ave SE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.123209,
              -106.701283
          ],
          "Rest_Name": "Coors Blvd",
          "id": 2613,
          "address": "3600 Coors Blvd NW",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.090573,
              -108.452673
          ],
          "Rest_Name": "Clifton",
          "id": 2614,
          "address": "3225 I-70 Business Loop",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.083535,
              -108.588004
          ],
          "Rest_Name": "Grand Junction",
          "id": 2615,
          "address": "2504 Highway 6 and 50 Ste 300",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.767788,
              -108.147758
          ],
          "Rest_Name": "Farmington",
          "id": 2616,
          "address": "4906 E Main St Ste 100",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.784522,
              -108.55692
          ],
          "Rest_Name": "Billings Mid Town",
          "id": 2617,
          "address": "1601 Grand Ave Ste 100",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.749,
              -108.61692
          ],
          "Rest_Name": "Southwest Billings",
          "id": 2618,
          "address": "1124 Shiloh Crossing BLVD",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.7959,
              -106.231
          ],
          "Rest_Name": "Zaragoza & Edgemere",
          "id": 2619,
          "address": "3540 N Zaragoza Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.758768,
              -106.271846
          ],
          "Rest_Name": "Joe Battle & Zaragoza",
          "id": 2620,
          "address": "1875 N Zaragoza Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.688641,
              -106.265579
          ],
          "Rest_Name": "Eastlake TX",
          "id": 2621,
          "address": "12101 Eastlake Blvd Ste 401",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.729102,
              -106.30334
          ],
          "Rest_Name": "George Dieter",
          "id": 2622,
          "address": "1355 George Dieter Dr Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.924665,
              -106.439021
          ],
          "Rest_Name": "North El Paso",
          "id": 2623,
          "address": "10771 Gateway South Boulevard",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.770416,
              -106.371356
          ],
          "Rest_Name": "The Fountains",
          "id": 2624,
          "address": "8889 Gateway Blvd W Ste 2510",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.81715,
              -106.427572
          ],
          "Rest_Name": "Fort Bliss",
          "id": 2625,
          "address": "1617 Pleasonton Road",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.314883,
              -106.743602
          ],
          "Rest_Name": "Las Cruces - Telshor",
          "id": 2626,
          "address": "525 S Telshor Blvd Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.779081,
              -106.504235
          ],
          "Rest_Name": "UTEP",
          "id": 2627,
          "address": "2900 N Mesa St Ste H",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.834125,
              -106.546125
          ],
          "Rest_Name": "N Mesa Rd",
          "id": 2628,
          "address": "6801 N Mesa St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.886742,
              -106.579666
          ],
          "Rest_Name": "West Towne El Paso",
          "id": 2629,
          "address": "6450 N Desert Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.680196,
              -111.063014
          ],
          "Rest_Name": "Bozeman",
          "id": 2630,
          "address": "1919 W Main St Ste 1",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.728042,
              -111.551416
          ],
          "Rest_Name": "Park City - UT",
          "id": 2631,
          "address": "6699 N Landmark Dr Ste H150",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.251282,
              -111.658081
          ],
          "Rest_Name": "Cougar & University",
          "id": 2632,
          "address": "1284 N University Ave",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.122256,
              -111.641641
          ],
          "Rest_Name": "Spanish Fork",
          "id": 2633,
          "address": "841 E Highway 6",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.273552,
              -111.678505
          ],
          "Rest_Name": "University Mall",
          "id": 2634,
          "address": "715 E University Pkwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.718507,
              -111.835866
          ],
          "Rest_Name": "South Logan",
          "id": 2635,
          "address": "731 S Main St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.30577,
              -111.7384
          ],
          "Rest_Name": "Vineyard - Utah",
          "id": 2636,
          "address": "661 EAST 450 NORTH",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.358318,
              -111.764046
          ],
          "Rest_Name": "Pleasant Grove - UT",
          "id": 2637,
          "address": "470 S Pleasant Grove Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.725766,
              -111.86202
          ],
          "Rest_Name": "Sugarhouse",
          "id": 2638,
          "address": "1011 E 2100 S",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.625272,
              -111.852235
          ],
          "Rest_Name": "Fort Union",
          "id": 2639,
          "address": "6924 S Park Centre Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.921849,
              -111.885083
          ],
          "Rest_Name": "Centerville - UT",
          "id": 2640,
          "address": "370 W Parrish Ln",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.75999,
              -111.869894
          ],
          "Rest_Name": "400 South",
          "id": 2641,
          "address": "734 E 400 S",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.890877,
              -111.892677
          ],
          "Rest_Name": "West Bountiful",
          "id": 2642,
          "address": "135 N 500 W Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.98244,
              -111.91174
          ],
          "Rest_Name": "Clark Lane - Farmington",
          "id": 2643,
          "address": "138 NORTH 1100 WEST",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.72459,
              -111.88863
          ],
          "Rest_Name": "2100 South & State",
          "id": 2644,
          "address": "2140 S. State St.",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.659627,
              -111.887723
          ],
          "Rest_Name": "Murray",
          "id": 2645,
          "address": "5141 S State St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.562515,
              -111.890489
          ],
          "Rest_Name": "Sandy",
          "id": 2646,
          "address": "10387 S State St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.432263,
              -111.888108
          ],
          "Rest_Name": "Lehi",
          "id": 2647,
          "address": "3601 N Digital Dr Ste 202",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.242967,
              -111.970573
          ],
          "Rest_Name": "12th & Washington",
          "id": 2648,
          "address": "1269 Washington Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.088928,
              -111.98468
          ],
          "Rest_Name": "Layton",
          "id": 2649,
          "address": "1035 W Antelope Dr Unit 2",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.17832,
              -111.999459
          ],
          "Rest_Name": "Riverdale Road",
          "id": 2650,
          "address": "849 W Riverdale Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.370717,
              -111.916485
          ],
          "Rest_Name": "Saratoga Springs - UT",
          "id": 2651,
          "address": "889 N Redwood Rd # 100",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.61434,
              -111.98186
          ],
          "Rest_Name": "West Jordan",
          "id": 2652,
          "address": "3849 W. Campus Dr.",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.543699,
              -111.97579
          ],
          "Rest_Name": "South Jordan",
          "id": 2653,
          "address": "3561 W 11400 S",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.49385,
              -111.274441
          ],
          "Rest_Name": "Great Falls",
          "id": 2654,
          "address": "1900 10th Ave S",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.5086,
              -111.9996
          ],
          "Rest_Name": "Riverton",
          "id": 2655,
          "address": "4599 W Partridge Hill Ln Ste PD-D1",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.71269,
              -112.02533
          ],
          "Rest_Name": "West Valley",
          "id": 2656,
          "address": "2640 South 5600 West",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              41.088768,
              -112.06369
          ],
          "Rest_Name": "Syracuse - UT",
          "id": 2657,
          "address": "1975 W 1700 S",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.30998,
              -112.01403
          ],
          "Rest_Name": "Eagle Mountain",
          "id": 2658,
          "address": "4315 North Pony Express Parkway Suite 110",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.223442,
              -111.585215
          ],
          "Rest_Name": "Flagstaff North",
          "id": 2659,
          "address": "4409 N US Highway 89",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.189819,
              -111.661772
          ],
          "Rest_Name": "Flagstaff",
          "id": 2660,
          "address": "1111 S Plaza Way",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.239527,
              -111.323839
          ],
          "Rest_Name": "Payson II",
          "id": 2661,
          "address": "108 S Beeline Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.86838,
              -111.762053
          ],
          "Rest_Name": "Sedona",
          "id": 2662,
          "address": "361 Forest Rd Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.559827,
              -110.257965
          ],
          "Rest_Name": "Sierra Vista",
          "id": 2663,
          "address": "480 N Highway 90 Byp Ste A6",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.205924,
              -110.790107
          ],
          "Rest_Name": "22nd and Harrison",
          "id": 2664,
          "address": "9484 E 22nd St Ste 130",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.653579,
              -113.08464
          ],
          "Rest_Name": "Cedar City",
          "id": 2665,
          "address": "1351 S Providence Center Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.250253,
              -110.851532
          ],
          "Rest_Name": "Grant and Tanque Verde",
          "id": 2666,
          "address": "6501 E Grant Rd Ste 141",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.398646,
              -110.955784
          ],
          "Rest_Name": "Oro Valley",
          "id": 2667,
          "address": "10604 N Oracle Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.251655,
              -110.890117
          ],
          "Rest_Name": "Grant & Swan Relo",
          "id": 2668,
          "address": "4857 E Grant Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.222101,
              -110.877658
          ],
          "Rest_Name": "Broadway & Craycroft",
          "id": 2669,
          "address": "5353 E Broadway Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.262371,
              -110.9441
          ],
          "Rest_Name": "Campbell Village",
          "id": 2670,
          "address": "3055 N Campbell Ave Ste 183",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.236487,
              -110.94349
          ],
          "Rest_Name": "Speedway & Campbell II",
          "id": 2671,
          "address": "1903 E Speedway Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.286145,
              -110.975659
          ],
          "Rest_Name": "Tucson Mall",
          "id": 2672,
          "address": "235 W Wetmore Rd Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.343582,
              -111.013367
          ],
          "Rest_Name": "Foothills",
          "id": 2673,
          "address": "7555 N La Cholla Blvd Ste 155",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.231861,
              -110.957487
          ],
          "Rest_Name": "University Square",
          "id": 2674,
          "address": "905 E University Blvd Ste 149",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.183919,
              -110.945042
          ],
          "Rest_Name": "Bridges",
          "id": 2675,
          "address": "3510 S. Kino Parkway",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.379446,
              -111.600875
          ],
          "Rest_Name": "Signal Butte and Baseline",
          "id": 2676,
          "address": "1955 S Signal Butte Rd Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.354864,
              -111.091721
          ],
          "Rest_Name": "Cortaro and I-10",
          "id": 2677,
          "address": "5940 W Arizona Pavillions Dr ",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.163913,
              -110.989487
          ],
          "Rest_Name": "I-19 & Irvington",
          "id": 2678,
          "address": "1202 W Irvington Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.249444,
              -111.567122
          ],
          "Rest_Name": "Ironwood & Ocotillo",
          "id": 2679,
          "address": "170 W Ocotillo",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.467879,
              -111.683508
          ],
          "Rest_Name": "Las Sendas",
          "id": 2680,
          "address": "2849 N Power Rd Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.320894,
              -111.633465
          ],
          "Rest_Name": "Eastmark",
          "id": 2681,
          "address": "9259 E. Ray Road",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.390012,
              -111.683607
          ],
          "Rest_Name": "Superstition Springs Relo",
          "id": 2682,
          "address": "1411 S Power Rd Ste 103",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.176517,
              -111.581497
          ],
          "Rest_Name": "Hunt & Gary",
          "id": 2683,
          "address": "1371 W Hunt Hwy",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.256155,
              -111.638908
          ],
          "Rest_Name": "Ritten House",
          "id": 2684,
          "address": "21172 S Ellsworth Loop Rd Ste 114",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.325549,
              -111.68757
          ],
          "Rest_Name": "Power & 202",
          "id": 2685,
          "address": "4984 S Power Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.379601,
              -111.755802
          ],
          "Rest_Name": "Dana Park",
          "id": 2686,
          "address": "3440 E Baseline Rd Ste 104",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.622326,
              -111.873977
          ],
          "Rest_Name": "Thompson Peak",
          "id": 2687,
          "address": "15035 N Thompson Peak Pkwy Ste E101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.90995,
              -110.979962
          ],
          "Rest_Name": "Sahuarita",
          "id": 2688,
          "address": "18725 S Nogales Hwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.798202,
              -111.965651
          ],
          "Rest_Name": "Cave Creek",
          "id": 2689,
          "address": "5355 E Carefree Hwy Ste E",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.451182,
              -111.802989
          ],
          "Rest_Name": "Stapley and McKellips",
          "id": 2690,
          "address": "1229 E McKellips Rd Ste 102",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.307787,
              -111.742797
          ],
          "Rest_Name": "SanTan",
          "id": 2691,
          "address": "2224 E Williams Field Rd Ste 109",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.676233,
              -111.924005
          ],
          "Rest_Name": "Grayhawk",
          "id": 2692,
          "address": "20851 N Scottsdale Rd Ste 101",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.580879,
              -111.881926
          ],
          "Rest_Name": "92nd & Shea",
          "id": 2693,
          "address": "9301 E Shea Blvd Ste 102",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.281748,
              -111.75466
          ],
          "Rest_Name": "Val Vista & Melrose",
          "id": 2694,
          "address": "3785 S Val Vista Dr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.626346,
              -111.924781
          ],
          "Rest_Name": "Maloney",
          "id": 2695,
          "address": "15425 N Scottsdale Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.377942,
              -111.807652
          ],
          "Rest_Name": "Cooper & Baseline",
          "id": 2696,
          "address": "1546 N Cooper Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.578152,
              -112.362396
          ],
          "Rest_Name": "Prescott Valley",
          "id": 2697,
          "address": "5791 E State Route 69 Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.538759,
              -111.887645
          ],
          "Rest_Name": "Scottsdale Pavilions",
          "id": 2698,
          "address": "9010 E Talking Stick Way",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.329917,
              -111.790846
          ],
          "Rest_Name": "Gilbert Town Square",
          "id": 2699,
          "address": "1084 S Gilbert Rd Ste 104",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.586073,
              -111.925521
          ],
          "Rest_Name": "Scottsdale and Shea",
          "id": 2700,
          "address": "11015 N Scottsdale Rd Ste 100",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.674754,
              -111.975293
          ],
          "Rest_Name": "Desert Ridge",
          "id": 2701,
          "address": "21001 N Tatum Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.281865,
              -111.788915
          ],
          "Rest_Name": "Gilbert & 202",
          "id": 2702,
          "address": "3757 S Gilbert Rd Ste 110",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.638244,
              -111.977417
          ],
          "Rest_Name": "Tatum and Bell",
          "id": 2703,
          "address": "16635 N Tatum Blvd Ste 100",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.389609,
              -111.856892
          ],
          "Rest_Name": "Alma School Relo",
          "id": 2704,
          "address": "1335 S Alma School Rd Ste 105",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.503359,
              -111.925649
          ],
          "Rest_Name": "Downtown Scottsdale",
          "id": 2705,
          "address": "4513 N Scottsdale Rd Ste 108",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.503892,
              -111.928664
          ],
          "Rest_Name": "Scottsdale Fashion Square",
          "id": 2706,
          "address": "7014 E Camelback Rd Ste 580",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.599546,
              -111.981773
          ],
          "Rest_Name": "Paradise Valley",
          "id": 2707,
          "address": "4622 E Cactus Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.433358,
              -111.901823
          ],
          "Rest_Name": "Tempe Marketplace",
          "id": 2708,
          "address": "2000 E Rio Salado Pkwy Ste 1226",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.479339,
              -111.925741
          ],
          "Rest_Name": "Scottsdale and Thomas",
          "id": 2709,
          "address": "2805 N Scottsdale Rd Ste 105",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.640489,
              -112.01374
          ],
          "Rest_Name": "32nd & Bell",
          "id": 2710,
          "address": "3185 E Bell Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.305479,
              -111.860126
          ],
          "Rest_Name": "Alma School and Chandler",
          "id": 2711,
          "address": "1055 W Chandler Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.26397,
              -111.857267
          ],
          "Rest_Name": "Alma School & Queen Creek",
          "id": 2712,
          "address": "2895 S Alma School Rd Ste 1",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.349748,
              -111.901478
          ],
          "Rest_Name": "Discovery",
          "id": 2713,
          "address": "2020 E Elliot Rd Ste 102",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.417977,
              -111.940362
          ],
          "Rest_Name": "ASU",
          "id": 2714,
          "address": "1038 S Mill Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.377901,
              -111.929047
          ],
          "Rest_Name": "Rural & Baseline",
          "id": 2715,
          "address": "815 E Baseline Rd Ste 118",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.546605,
              -112.46938
          ],
          "Rest_Name": "Prescott",
          "id": 2716,
          "address": "351 N Montezuma St Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.305291,
              -111.89993
          ],
          "Rest_Name": "Chandler Boulevard Shops",
          "id": 2717,
          "address": "3395 W Chandler Blvd Ste 1",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.479711,
              -111.986121
          ],
          "Rest_Name": "44th & Thomas",
          "id": 2718,
          "address": "4423 E. Thomas Road",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.639914,
              -112.067442
          ],
          "Rest_Name": "7th St. & Bell Road",
          "id": 2719,
          "address": "425 E Bell Rd Ste 140",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.714577,
              -112.112513
          ],
          "Rest_Name": "Nortera",
          "id": 2720,
          "address": "2470 W Happy Valley Rd Ste 1181",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.494946,
              -112.01464
          ],
          "Rest_Name": "Arcadia Fiesta",
          "id": 2721,
          "address": "3125 E Indian School Rd Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.879117,
              -111.711079
          ],
          "Rest_Name": "Casa Grande",
          "id": 2722,
          "address": "1775 E Florence Blvd Ste 1",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.406387,
              -111.979652
          ],
          "Rest_Name": "48th St and Broadway",
          "id": 2723,
          "address": "4705 E Broadway Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.347271,
              -111.964492
          ],
          "Rest_Name": "Priest & Elliot Rd",
          "id": 2724,
          "address": "1655 W Elliot Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.510338,
              -112.046281
          ],
          "Rest_Name": "Camelback",
          "id": 2725,
          "address": "1660 E Camelback Rd Ste 185",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.14439,
              -113.48459
          ],
          "Rest_Name": "Washington City",
          "id": 2726,
          "address": "1560 Grapevine Xing",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.6667,
              -112.123219
          ],
          "Rest_Name": "Deer Valley Town Center",
          "id": 2727,
          "address": "3009 W Agua Fria Fwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.640453,
              -112.112343
          ],
          "Rest_Name": "I-17 & Bell",
          "id": 2728,
          "address": "2450 W Bell Rd Ste 5",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.317395,
              -111.968776
          ],
          "Rest_Name": "Chandler Pavilions",
          "id": 2729,
          "address": "890 N 54th St Ste 5",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.892196,
              -114.03998
          ],
          "Rest_Name": "Missoula",
          "id": 2730,
          "address": "2995 N Reserve St Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.509588,
              -112.071631
          ],
          "Rest_Name": "Uptown",
          "id": 2731,
          "address": "52 E Camelback Road",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.581565,
              -112.125103
          ],
          "Rest_Name": "Metro Center",
          "id": 2732,
          "address": "3039 W Peoria Ave Ste C105",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              31.366301,
              -110.936838
          ],
          "Rest_Name": "Nogales",
          "id": 2733,
          "address": "337 W Mariposa Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.524581,
              -112.098773
          ],
          "Rest_Name": "19th and Bethany",
          "id": 2734,
          "address": "1818 W Bethany Home Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.304753,
              -111.993608
          ],
          "Rest_Name": "Ahwatukee",
          "id": 2735,
          "address": "4111 E Chandler Blvd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.377228,
              -112.029866
          ],
          "Rest_Name": "South Mountain",
          "id": 2736,
          "address": "2415 E Baseline Rd Ste 111",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.588279,
              -114.481157
          ],
          "Rest_Name": "Twin Falls - ID",
          "id": 2737,
          "address": "118 Cheney Dr W",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.465588,
              -112.082387
          ],
          "Rest_Name": "Encanto",
          "id": 2738,
          "address": "1515 N 7th Ave Ste 120",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.448066,
              -112.074525
          ],
          "Rest_Name": "City Scape",
          "id": 2739,
          "address": "11 W Washington St Ste 140",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.111891,
              -113.554506
          ],
          "Rest_Name": "St. George",
          "id": 2740,
          "address": "231 Red Cliffs Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.665923,
              -112.203393
          ],
          "Rest_Name": "67th Ave and 101",
          "id": 2741,
          "address": "20004 N 67th Ave Ste 500",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.611255,
              -112.184625
          ],
          "Rest_Name": "59th & Thunderbird",
          "id": 2742,
          "address": "5880 W Thunderbird Rd Ste 1",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.64196,
              -112.225341
          ],
          "Rest_Name": "Arrowhead Towne Center",
          "id": 2743,
          "address": "7700 W Arrowhead Towne Ctr Ste 2067",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.378235,
              -112.097858
          ],
          "Rest_Name": "Baseline & 19th",
          "id": 2744,
          "address": "1838 W Baseline Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.55238,
              -112.185552
          ],
          "Rest_Name": "Northern Crossing",
          "id": 2745,
          "address": "5849 W Northern Ave Ste 500",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.636283,
              -112.234039
          ],
          "Rest_Name": "Arrowhead Fountains",
          "id": 2746,
          "address": "16680 N 83rd Ave Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.71146,
              -112.273076
          ],
          "Rest_Name": "Happy Valley",
          "id": 2747,
          "address": "9940 W Happy Valley Pkwy Ste 1040",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.122334,
              -113.619568
          ],
          "Rest_Name": "Sunset St. George",
          "id": 2748,
          "address": "1687 W Sunset Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.74207,
              -112.32396
          ],
          "Rest_Name": "Vistancia",
          "id": 2749,
          "address": "28576 N El Mirage Rd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.379305,
              -112.169013
          ],
          "Rest_Name": "Laveen",
          "id": 2750,
          "address": "5130 W Baseline Rd Ste 105",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.479745,
              -112.219873
          ],
          "Rest_Name": "Desert Sky",
          "id": 2751,
          "address": "7427 W Thomas Rd Ste 5",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.536961,
              -112.261373
          ],
          "Rest_Name": "Westgate Glendale Relo",
          "id": 2752,
          "address": "9405 W Glendale Ave Ste 100",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.073533,
              -112.042739
          ],
          "Rest_Name": "Maricopa",
          "id": 2753,
          "address": "21423 N John Wayne Pkwy Ste 105",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.464374,
              -112.273159
          ],
          "Rest_Name": "99th & McDowell",
          "id": 2754,
          "address": "9925 W McDowell Rd Ste 101",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.638254,
              -112.3591
          ],
          "Rest_Name": "Surprise",
          "id": 2755,
          "address": "13869 W Bell Rd Ste 107",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.610461,
              -112.420607
          ],
          "Rest_Name": "303 & Waddell",
          "id": 2756,
          "address": "16810 W Waddell Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.463832,
              -112.358647
          ],
          "Rest_Name": "Goodyear",
          "id": 2757,
          "address": "1560 N Litchfield Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.440222,
              -112.426792
          ],
          "Rest_Name": "Canyon Trails",
          "id": 2758,
          "address": "655 S Cotton Ln",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.438274,
              -112.55831
          ],
          "Rest_Name": "Sundance Town Center",
          "id": 2759,
          "address": "944 S Watson Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.231424,
              -114.331391
          ],
          "Rest_Name": "Kalispell",
          "id": 2760,
          "address": "2264 US Highway 93 N",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.804518,
              -114.110037
          ],
          "Rest_Name": "Falcon Ridge & I-15",
          "id": 2761,
          "address": "145 Falcon Ridge Pkwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.224069,
              -114.036963
          ],
          "Rest_Name": "Kingman",
          "id": 2762,
          "address": "3455 N Stockton Hill Rd Ste C",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.080964,
              -113.995152
          ],
          "Rest_Name": "Sunridge",
          "id": 2763,
          "address": "2791 32 Ave NE",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.578924,
              -116.174525
          ],
          "Rest_Name": "Southeast Boise",
          "id": 2764,
          "address": "702 E Boise Ave",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.612686,
              -116.203084
          ],
          "Rest_Name": "Downtown Boise",
          "id": 2765,
          "address": "305 S 6th St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.606699,
              -116.282264
          ],
          "Rest_Name": "Boise Towne Square",
          "id": 2766,
          "address": "205 N Milwaukee St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.67125,
              -116.283004
          ],
          "Rest_Name": "Garden City",
          "id": 2767,
          "address": "7610 W State Street Suite 110",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.624188,
              -116.353907
          ],
          "Rest_Name": "Meridian",
          "id": 2768,
          "address": "2126 N Eagle Rd Ste 100",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.66278,
              -116.41189
          ],
          "Rest_Name": "Linder Village Meridian",
          "id": 2769,
          "address": "1323 W Chinden Blvd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.472154,
              -114.34274
          ],
          "Rest_Name": "Lake Havasu",
          "id": 2770,
          "address": "55 Lake Havasu Ave S Ste M",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.041365,
              -114.979392
          ],
          "Rest_Name": "Boulder Hwy and Lake Mead",
          "id": 2771,
          "address": "246 E Lake Mead Pkwy",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.161908,
              -115.062329
          ],
          "Rest_Name": "Charleston Commons",
          "id": 2772,
          "address": "111 N Nellis Blvd Ste 150",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.063167,
              -115.043606
          ],
          "Rest_Name": "Sunset Station",
          "id": 2773,
          "address": "1311 W Sunset Rd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.240089,
              -115.116196
          ],
          "Rest_Name": "The Cannery",
          "id": 2774,
          "address": "2546 E Craig Rd Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.59511,
              -116.513142
          ],
          "Rest_Name": "North Nampa",
          "id": 2775,
          "address": "1260 N Happy Valley Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.195468,
              -115.119124
          ],
          "Rest_Name": "North Las Vegas",
          "id": 2776,
          "address": "2023 E Lake Mead Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.239537,
              -115.169354
          ],
          "Rest_Name": "Craig and MLK",
          "id": 2777,
          "address": "1920 W Craig Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.085349,
              -115.119382
          ],
          "Rest_Name": "McCarran",
          "id": 2778,
          "address": "5715 S Eastern Ave Ste 105",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.106855,
              -115.136432
          ],
          "Rest_Name": "Maryland Parkway",
          "id": 2779,
          "address": "4530 S Maryland Pkwy",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.163071,
              -115.158226
          ],
          "Rest_Name": "Las Vegas North Outlets",
          "id": 2780,
          "address": "875 S Grand Central Pkwy Ste 1660",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.2729,
              -115.2064
          ],
          "Rest_Name": "N Decatur and 215",
          "id": 2781,
          "address": "6211 N Decatur Blvd Ste 110",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.003391,
              -115.110466
          ],
          "Rest_Name": "St. Rose",
          "id": 2782,
          "address": "10251 S Eastern Ave Ste 100",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.02166,
              -115.117149
          ],
          "Rest_Name": "Eastern Beltway",
          "id": 2783,
          "address": "9210 S Eastern Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.115019,
              -115.155591
          ],
          "Rest_Name": "Hughes Center",
          "id": 2784,
          "address": "365 Hughes Center Dr Ste 140",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.127437,
              -115.171639
          ],
          "Rest_Name": "Fashion Show",
          "id": 2785,
          "address": "3200 Las Vegas Blvd S",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.119496,
              -115.169679
          ],
          "Rest_Name": "Harrah's",
          "id": 2786,
          "address": "3475 Las Vegas Blvd S",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.121244,
              -115.171349
          ],
          "Rest_Name": "Venetian Grand Canal",
          "id": 2787,
          "address": "3377 Las Vegas Blvd S",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.110122,
              -115.17108
          ],
          "Rest_Name": "Miracle Mile Shops",
          "id": 2788,
          "address": "3663 Las Vegas Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.985846,
              -115.145473
          ],
          "Rest_Name": "St. Rose & Amigo",
          "id": 2789,
          "address": "3588 Saint Rose Pkwy",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.056051,
              -115.171578
          ],
          "Rest_Name": "Belz",
          "id": 2790,
          "address": "7370 Las Vegas Blvd S",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.143889,
              -115.207897
          ],
          "Rest_Name": "Sahara Pavilion",
          "id": 2791,
          "address": "2540 S Decatur Blvd Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.634542,
              -116.645189
          ],
          "Rest_Name": "Caldwell",
          "id": 2792,
          "address": "5005 Cleveland Blvd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.039671,
              -115.190601
          ],
          "Rest_Name": "Blue Diamond",
          "id": 2793,
          "address": "3815 Blue Diamond Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.195524,
              -115.248116
          ],
          "Rest_Name": "Las Vegas Tech Center",
          "id": 2794,
          "address": "7175 W Lake Mead Blvd Ste 105",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.291122,
              -115.287369
          ],
          "Rest_Name": "Montecito",
          "id": 2795,
          "address": "7170 N Durango Dr Ste 150",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.99909,
              -115.19737
          ],
          "Rest_Name": "Cactus & Schirlls",
          "id": 2796,
          "address": "4332 W Cactus Ave",
          "rating": 2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.06366,
              -115.252113
          ],
          "Rest_Name": "The Arroyo",
          "id": 2797,
          "address": "7340 Arroyo Crossing Pkwy Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.192942,
              -115.305604
          ],
          "Rest_Name": "Trails Village",
          "id": 2798,
          "address": "1910 Village Center Cir Ste 9",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.144771,
              -115.297077
          ],
          "Rest_Name": "Ft. Apache",
          "id": 2799,
          "address": "9240 W Sahara Ave Ste 150",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.071384,
              -115.280078
          ],
          "Rest_Name": "Sunset and Durango",
          "id": 2800,
          "address": "8620 W Sunset Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.098833,
              -115.295744
          ],
          "Rest_Name": "Tropicana Beltway",
          "id": 2801,
          "address": "4916 S Fort Apache Rd Ste 102",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.02518,
              -116.940824
          ],
          "Rest_Name": "Ontario - OR",
          "id": 2802,
          "address": "1657 E Idaho Ave",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.713668,
              -117.177715
          ],
          "Rest_Name": "Pullman",
          "id": 2803,
          "address": "485 SE Brelsford Drive",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.21175,
              -115.98987
          ],
          "Rest_Name": "Pahrump",
          "id": 2804,
          "address": "540 S Hwy 160 ",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.659107,
              -117.197129
          ],
          "Rest_Name": "Spokane Valley",
          "id": 2805,
          "address": "209 N Sullivan Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.699723,
              -114.601064
          ],
          "Rest_Name": "Yuma",
          "id": 2806,
          "address": "1525 S Yuma Palms Pkwy Ste 3",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.680845,
              -117.284494
          ],
          "Rest_Name": "Trent & Argonne",
          "id": 2807,
          "address": "8909 E Trent Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.671593,
              -114.624343
          ],
          "Rest_Name": "Big Curve",
          "id": 2808,
          "address": "3080 S 4th Avenue Suite A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.665878,
              -117.410728
          ],
          "Rest_Name": "Spokane",
          "id": 2809,
          "address": "930 N Division St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.745933,
              -117.4044
          ],
          "Rest_Name": "North Spokane",
          "id": 2810,
          "address": "9602 N Newport Hwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.71563,
              -117.436882
          ],
          "Rest_Name": "Francis & Ash  - Spokane",
          "id": 2811,
          "address": "1706 W Francis Ave",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.64389,
              -117.54964
          ],
          "Rest_Name": "Airway Heights",
          "id": 2812,
          "address": "9926 West Sunset Highway",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.032633,
              -118.380667
          ],
          "Rest_Name": "Walla Walla",
          "id": 2813,
          "address": "1715 SE Meadowbrook Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.810821,
              -115.569267
          ],
          "Rest_Name": "El Centro",
          "id": 2814,
          "address": "1953 N Imperial Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.742747,
              -116.216864
          ],
          "Rest_Name": "Indio",
          "id": 2815,
          "address": "42213 Jackson St Ste 106",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.125591,
              -116.407289
          ],
          "Rest_Name": "Yucca Valley",
          "id": 2816,
          "address": "57592 29 Palms Hwy",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.762912,
              -116.301207
          ],
          "Rest_Name": "I10 & Washington",
          "id": 2817,
          "address": "39575 Washington St Ste 101",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.708264,
              -116.283442
          ],
          "Rest_Name": "La Quinta",
          "id": 2818,
          "address": "79174 Highway 111 Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.801507,
              -116.387342
          ],
          "Rest_Name": "Monterey & Dinah Shore",
          "id": 2819,
          "address": "34100 Monterey Ave Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.728137,
              -116.403037
          ],
          "Rest_Name": "Palm Desert",
          "id": 2820,
          "address": "72333 Highway 111 Ste A",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.80082,
              -116.521662
          ],
          "Rest_Name": "Palm Springs",
          "id": 2821,
          "address": "2465 E Palm Canyon Dr Ste 1110",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.852814,
              -117.082788
          ],
          "Rest_Name": "Barstow",
          "id": 2822,
          "address": "2590 High Point Pkwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.178402,
              -119.188688
          ],
          "Rest_Name": "Southridge",
          "id": 2823,
          "address": "3631 Plaza Way",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.275712,
              -119.186159
          ],
          "Rest_Name": "Pasco",
          "id": 2824,
          "address": "5326 N 68 RD.",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.22126,
              -119.223138
          ],
          "Rest_Name": "Kennewick",
          "id": 2825,
          "address": "1102 N Columbia Center Blvd Ste C",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.923284,
              -116.805493
          ],
          "Rest_Name": "Cabazon",
          "id": 2826,
          "address": "48557 Morongo Trl",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.258518,
              -119.309033
          ],
          "Rest_Name": "Queensgate",
          "id": 2827,
          "address": "2673 Queensgate Dr",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.103696,
              -119.250021
          ],
          "Rest_Name": "Moses Lake",
          "id": 2828,
          "address": "2345 S Maiers Rd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.923274,
              -116.945548
          ],
          "Rest_Name": "Banning",
          "id": 2829,
          "address": "300 S Highland Springs Ave Ste 11A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.609492,
              -117.670123
          ],
          "Rest_Name": "Ridgecrest",
          "id": 2830,
          "address": "820 S China Lake Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.470684,
              -117.245748
          ],
          "Rest_Name": "Jess Ranch",
          "id": 2831,
          "address": "19179 Bear Valley Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.028285,
              -117.116914
          ],
          "Rest_Name": "Yucaipa",
          "id": 2832,
          "address": "31495 Yucaipa Blvd Unit 1",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.747169,
              -116.997502
          ],
          "Rest_Name": "Hemet",
          "id": 2833,
          "address": "2465 W Florida Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.061156,
              -117.182877
          ],
          "Rest_Name": "Redlands",
          "id": 2834,
          "address": "635 Orange St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.506706,
              -117.393807
          ],
          "Rest_Name": "395 and Palmdale II",
          "id": 2835,
          "address": "12304 Palmdale Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.073997,
              -117.205073
          ],
          "Rest_Name": "Mountain Grove",
          "id": 2836,
          "address": "27511 San Bernardino Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.428019,
              -117.378393
          ],
          "Rest_Name": "Hesperia",
          "id": 2837,
          "address": "9770 Mariposa Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.699936,
              -117.084833
          ],
          "Rest_Name": "French Valley",
          "id": 2838,
          "address": "35938 Winchester Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.069278,
              -117.261854
          ],
          "Rest_Name": "San Bernardino",
          "id": 2839,
          "address": "1092 E Hospitality Ln Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.93696,
              -117.26182
          ],
          "Rest_Name": "Moreno Valley 2",
          "id": 2840,
          "address": "12625 Frederick St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.134814,
              -117.369287
          ],
          "Rest_Name": "North Rialto",
          "id": 2841,
          "address": "186 E Easton St Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.807429,
              -117.22663
          ],
          "Rest_Name": "Perris",
          "id": 2842,
          "address": "1870 N Perris Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.684585,
              -117.174999
          ],
          "Rest_Name": "Menifee",
          "id": 2843,
          "address": "30024 Haun Rd Ste 130",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.88801,
              -117.279581
          ],
          "Rest_Name": "Veterans Plaza",
          "id": 2844,
          "address": "22430 Van Buren Blvd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.077251,
              -117.370727
          ],
          "Rest_Name": "Rialto",
          "id": 2845,
          "address": "1314 S Riverside Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.481156,
              -117.096984
          ],
          "Rest_Name": "Red Hawk",
          "id": 2846,
          "address": "32195 Temecula Pkwy Ste 105",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.890269,
              -117.324317
          ],
          "Rest_Name": "Van Buren & Trautwein",
          "id": 2847,
          "address": "8956 Trautwein Rd Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.524085,
              -117.158949
          ],
          "Rest_Name": "Temecula",
          "id": 2848,
          "address": "26440 Ynez Rd Ste B",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.138627,
              -117.441473
          ],
          "Rest_Name": "Sierra Lakes",
          "id": 2849,
          "address": "16595 Sierra Lakes Pkwy Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.568334,
              -117.204661
          ],
          "Rest_Name": "Murrieta",
          "id": 2850,
          "address": "40940 California Oaks Rd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.062702,
              -117.435064
          ],
          "Rest_Name": "South Fontana",
          "id": 2851,
          "address": "10515 Sierra Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.955624,
              -117.389371
          ],
          "Rest_Name": "Riverside Plaza",
          "id": 2852,
          "address": "3525 Riverside Plaza Dr Ste 204",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.597815,
              -117.240529
          ],
          "Rest_Name": "Wildomar",
          "id": 2853,
          "address": "24032 Clinton Keith Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.130275,
              -117.066471
          ],
          "Rest_Name": "East Escondido",
          "id": 2854,
          "address": "1201 E Valley Pkwy Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.692074,
              -117.335568
          ],
          "Rest_Name": "Lake Elsinore",
          "id": 2855,
          "address": "18492 Dexter Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.110328,
              -117.535498
          ],
          "Rest_Name": "Victoria Gardens",
          "id": 2856,
          "address": "7879 Monticello Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.90712,
              -117.458253
          ],
          "Rest_Name": "Tyler Street",
          "id": 2857,
          "address": "3510 Tyler St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.070588,
              -117.067433
          ],
          "Rest_Name": "North County Mall",
          "id": 2858,
          "address": "200 E Via Rancho Pkwy Ste 455",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.794781,
              -116.934935
          ],
          "Rest_Name": "El Cajon East",
          "id": 2859,
          "address": "225 Jamacha Rd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.112927,
              -117.101017
          ],
          "Rest_Name": "Escondido Promenade",
          "id": 2860,
          "address": "1282 Auto Park Way Ste A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.073768,
              -117.5517
          ],
          "Rest_Name": "The Mills",
          "id": 2861,
          "address": "One Mills Cir",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.106191,
              -117.568843
          ],
          "Rest_Name": "Rancho Cucamonga",
          "id": 2862,
          "address": "10811 Foothill Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.077662,
              -117.557563
          ],
          "Rest_Name": "Ontario Mills",
          "id": 2863,
          "address": "11334 4th St Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.955802,
              -117.037873
          ],
          "Rest_Name": "Poway",
          "id": 2864,
          "address": "13495 Poway Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.840813,
              -116.981522
          ],
          "Rest_Name": "Santee",
          "id": 2865,
          "address": "9828 Mission Gorge Rd Ste C",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.979751,
              -117.082604
          ],
          "Rest_Name": "Carmel Mountain",
          "id": 2866,
          "address": "11134 Rancho Carmel Dr Ste 104",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.976619,
              -117.552862
          ],
          "Rest_Name": "Eastvale",
          "id": 2867,
          "address": "12411 Limonite Ave Ste 600",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.019872,
              -117.110737
          ],
          "Rest_Name": "4S Ranch",
          "id": 2868,
          "address": "10550 Craftsman Way Ste 180",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.132404,
              -117.636583
          ],
          "Rest_Name": "Colonies Crossroads",
          "id": 2869,
          "address": "1053 E 19th St Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.136349,
              -117.176569
          ],
          "Rest_Name": "San Marcos Creekside",
          "id": 2870,
          "address": "575 Grand Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.82365,
              -117.516599
          ],
          "Rest_Name": "Corona Crossings",
          "id": 2871,
          "address": "2560 Tuscany St Ste 102",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.902633,
              -117.561865
          ],
          "Rest_Name": "Norco",
          "id": 2872,
          "address": "1409 Hamner Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.773612,
              -117.02693
          ],
          "Rest_Name": "La Mesa",
          "id": 2873,
          "address": "8005 Fletcher Pkwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.647206,
              -116.96553
          ],
          "Rest_Name": "Eastlake",
          "id": 2874,
          "address": "2290 Otay Lakes Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.847626,
              -117.543809
          ],
          "Rest_Name": "Corona",
          "id": 2875,
          "address": "1160 E Ontario Ave Ste 101",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.033736,
              -117.628874
          ],
          "Rest_Name": "West Ontario",
          "id": 2876,
          "address": "2200 S Grove Ave Ste 104",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.200581,
              -117.244873
          ],
          "Rest_Name": "Vista Village",
          "id": 2877,
          "address": "30 Main St Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.108384,
              -117.669856
          ],
          "Rest_Name": "Upland",
          "id": 2878,
          "address": "1092 N Mountain Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.121708,
              -117.68875
          ],
          "Rest_Name": "Sycamore Hills",
          "id": 2879,
          "address": "2057 W Baseline Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.245196,
              -117.290349
          ],
          "Rest_Name": "Mission Marketplace",
          "id": 2880,
          "address": "495 College Blvd Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.645508,
              -117.002167
          ],
          "Rest_Name": "Bonita Plaza",
          "id": 2881,
          "address": "720 Otay Lakes Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.087439,
              -117.689171
          ],
          "Rest_Name": "Montclair Mall",
          "id": 2882,
          "address": "9177 Central Ave Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.761239,
              -117.066656
          ],
          "Rest_Name": "Campus Plaza",
          "id": 2883,
          "address": "6083 El Cajon Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.889744,
              -117.132437
          ],
          "Rest_Name": "Miramar Air Station USMC",
          "id": 2884,
          "address": "2750 Bauer Road",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.913856,
              -117.146019
          ],
          "Rest_Name": "Mira Mesa",
          "id": 2885,
          "address": "8250 Mira Mesa Blvd Ste G",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.205753,
              -117.288354
          ],
          "Rest_Name": "Del Oro Marketplace",
          "id": 2886,
          "address": "4111 Oceanside Blvd Ste 103",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.082009,
              -117.235829
          ],
          "Rest_Name": "La Costa Town Square",
          "id": 2887,
          "address": "3416 Via Mercato Ste 110",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.031921,
              -117.690287
          ],
          "Rest_Name": "Chino",
          "id": 2888,
          "address": "12188 Central Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.128087,
              -117.270439
          ],
          "Rest_Name": "Carlsbad",
          "id": 2889,
          "address": "2505 Palomar Airport Rd Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.079826,
              -117.719604
          ],
          "Rest_Name": "Claremont Promenade",
          "id": 2890,
          "address": "885 S Indian Hill Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.04945,
              -117.260551
          ],
          "Rest_Name": "Encinitas Relo",
          "id": 2891,
          "address": "242 N El Camino Real",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.11209,
              -117.762289
          ],
          "Rest_Name": "La Verne Ctr",
          "id": 2892,
          "address": "2298 Foothill Blvd Ste C",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.181828,
              -117.331323
          ],
          "Rest_Name": "Oceanside",
          "id": 2893,
          "address": "2611 Vista Way Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.831741,
              -117.165205
          ],
          "Rest_Name": "Kearny Mesa",
          "id": 2894,
          "address": "7081 Clairemont Mesa Blvd Ste 103",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.981653,
              -117.250566
          ],
          "Rest_Name": "Flower Hill",
          "id": 2895,
          "address": "2710 Via De La Valle Ste 290",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.937558,
              -117.230092
          ],
          "Rest_Name": "Carmel Valley",
          "id": 2896,
          "address": "3881 Valley Centre Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.000611,
              -117.731591
          ],
          "Rest_Name": "Chino Hills",
          "id": 2897,
          "address": "13920 City Center Dr Ste 4005",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.768551,
              -117.14841
          ],
          "Rest_Name": "Mission Valley",
          "id": 2898,
          "address": "1025 Camino De La Reina Ste 2",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.82193,
              -117.179085
          ],
          "Rest_Name": "Genesee Plaza",
          "id": 2899,
          "address": "5650 Balboa Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.631549,
              -117.08836
          ],
          "Rest_Name": "Chula Vista H Street",
          "id": 2900,
          "address": "569 H St",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.033712,
              -117.756774
          ],
          "Rest_Name": "Pomona",
          "id": 2901,
          "address": "785 Rio Rancho Rd Ste 300",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.660442,
              -117.105087
          ],
          "Rest_Name": "National City",
          "id": 2902,
          "address": "404 Mile of Cars Way",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.748625,
              -117.157755
          ],
          "Rest_Name": "Hillcrest",
          "id": 2903,
          "address": "734 University Ave Ste C",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.639077,
              -117.594022
          ],
          "Rest_Name": "Rancho Santa Margarita",
          "id": 2904,
          "address": "22245 El Paseo Ste C",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.866533,
              -117.231662
          ],
          "Rest_Name": "LaJolla Village Square",
          "id": 2905,
          "address": "8657 Villa La Jolla Dr Ste 209",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.715146,
              -117.164272
          ],
          "Rest_Name": "Downtown San Diego",
          "id": 2906,
          "address": "101 W Broadway Ste 150",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.116352,
              -117.835618
          ],
          "Rest_Name": "Glendora",
          "id": 2907,
          "address": "1365 E Gladstone St Ste 700",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.583743,
              -117.115371
          ],
          "Rest_Name": "Imperial Beach",
          "id": 2908,
          "address": "801 Palm Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.576296,
              -118.046214
          ],
          "Rest_Name": "East Palmdale",
          "id": 2909,
          "address": "38207 47th Street East",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.751262,
              -117.205617
          ],
          "Rest_Name": "Grosvenor Square",
          "id": 2910,
          "address": "3680 Rosecrans St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.020989,
              -117.810495
          ],
          "Rest_Name": "Diamond Bar",
          "id": 2911,
          "address": "235 S Diamond Bar Blvd Ste B",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.682677,
              -117.178774
          ],
          "Rest_Name": "Coronado Island",
          "id": 2912,
          "address": "1360 Orange Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.534118,
              -119.717792
          ],
          "Rest_Name": "Sparks Marina",
          "id": 2913,
          "address": "1560 E Lincoln Way Ste 120",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.868043,
              -117.748238
          ],
          "Rest_Name": "Anaheim Hills",
          "id": 2914,
          "address": "8182 E Santa Ana Canyon Rd Ste 195",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              32.799725,
              -117.242512
          ],
          "Rest_Name": "Pacific Beach",
          "id": 2915,
          "address": "1504 Garnet Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.677253,
              -117.666574
          ],
          "Rest_Name": "Foothill Ranch Relo",
          "id": 2916,
          "address": "26592 Towne Centre Dr Ste 120",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.027892,
              -117.833865
          ],
          "Rest_Name": "Walnut",
          "id": 2917,
          "address": "21710 Valley Blvd Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.128164,
              -117.891544
          ],
          "Rest_Name": "Azusa",
          "id": 2918,
          "address": "860 E Alosta Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.637972,
              -117.680585
          ],
          "Rest_Name": "Lake Forest",
          "id": 2919,
          "address": "22379 El Toro Rd",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.592266,
              -117.660309
          ],
          "Rest_Name": "Mission Viejo",
          "id": 2920,
          "address": "25523 Marguerite Pkwy Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.07317,
              -117.882161
          ],
          "Rest_Name": "The Curve",
          "id": 2921,
          "address": "143 N Barranca St Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.437687,
              -120.323562
          ],
          "Rest_Name": "Wenatchee",
          "id": 2922,
          "address": "1121 N Mission St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.675176,
              -118.14733
          ],
          "Rest_Name": "Lancaster South East",
          "id": 2923,
          "address": "855 W. Avenue K. Suite 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.860571,
              -117.792008
          ],
          "Rest_Name": "East Anaheim",
          "id": 2924,
          "address": "5631 E La Palma Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.133283,
              -117.926712
          ],
          "Rest_Name": "West Azusa",
          "id": 2925,
          "address": "1250 W Foothill Blvd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.439133,
              -119.759254
          ],
          "Rest_Name": "South Meadows",
          "id": 2926,
          "address": "537 S Meadows Pkwy Ste 100",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.443379,
              -117.613782
          ],
          "Rest_Name": "San Clemente",
          "id": 2927,
          "address": "806 Avenida Pico Ste D",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.558168,
              -117.668539
          ],
          "Rest_Name": "Shops at Mission Viejo",
          "id": 2928,
          "address": "VC10 Shops at Mission Viejo",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.55954,
              -117.671356
          ],
          "Rest_Name": "Crown Valley",
          "id": 2929,
          "address": "27750 Crown Valley Pkwy",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.618822,
              -117.703464
          ],
          "Rest_Name": "The Arbor",
          "id": 2930,
          "address": "23645 El Toro Road",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.698406,
              -117.740873
          ],
          "Rest_Name": "Woodbury Town Center",
          "id": 2931,
          "address": "6424 Irvine Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.602263,
              -118.150175
          ],
          "Rest_Name": "Palmdale",
          "id": 2932,
          "address": "1125 W Rancho Vista Blvd Ste B",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.477472,
              -119.79302
          ],
          "Rest_Name": "Reno",
          "id": 2933,
          "address": "6395 S McCarran Blvd Unit A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.72293,
              -117.77245
          ],
          "Rest_Name": "Northpark Plaza Relo",
          "id": 2934,
          "address": "3911 Irvine Blvd",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.071113,
              -117.932782
          ],
          "Rest_Name": "West Covina",
          "id": 2935,
          "address": "427 Plaza Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.650854,
              -117.74293
          ],
          "Rest_Name": "Irvine Spectrum",
          "id": 2936,
          "address": "731 Spectrum Center Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.56814,
              -117.708595
          ],
          "Rest_Name": "Laguna Niguel",
          "id": 2937,
          "address": "27221 La Paz Rd Ste H",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.787203,
              -117.816559
          ],
          "Rest_Name": "El Modena",
          "id": 2938,
          "address": "3440 E Chapman Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.887511,
              -117.862948
          ],
          "Rest_Name": "Placentia",
          "id": 2939,
          "address": "1474 N Kraemer Blvd Ste A3",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.825811,
              -117.836411
          ],
          "Rest_Name": "The Village",
          "id": 2940,
          "address": "2202 N Tustin St Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.66893,
              -117.764748
          ],
          "Rest_Name": "Sand Canyon",
          "id": 2941,
          "address": "6500 Irvine Center Dr",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.528891,
              -119.827699
          ],
          "Rest_Name": "Keystone Commons",
          "id": 2942,
          "address": "925 W 5th St Ste 100",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.576777,
              -117.725076
          ],
          "Rest_Name": "Aliso Viejo",
          "id": 2943,
          "address": "26661 Aliso Creek Rd Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.911769,
              -117.882136
          ],
          "Rest_Name": "Brea",
          "id": 2944,
          "address": "477 S Associated Rd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.915372,
              -117.887265
          ],
          "Rest_Name": "Brea Mall",
          "id": 2945,
          "address": "1065 Brea Mall Ste 2160",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.991125,
              -117.924697
          ],
          "Rest_Name": "Puente Hills Mall",
          "id": 2946,
          "address": "17501 Colima Rd Ste C",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.745132,
              -117.814616
          ],
          "Rest_Name": "Tustin",
          "id": 2947,
          "address": "13348 Newport Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.493047,
              -117.69776
          ],
          "Rest_Name": "Dana Hills",
          "id": 2948,
          "address": "32391 Golden Lantern Ste A # A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.069359,
              -117.972809
          ],
          "Rest_Name": "Baldwin Park",
          "id": 2949,
          "address": "13916 Garvey Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.874546,
              -117.890095
          ],
          "Rest_Name": "Cal State Fullerton",
          "id": 2950,
          "address": "501 N State College Blvd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.154164,
              -119.76657
          ],
          "Rest_Name": "Carson City",
          "id": 2951,
          "address": "1457 S Carson St Ste 102",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.712597,
              -117.817131
          ],
          "Rest_Name": "Tustin Legacy",
          "id": 2952,
          "address": "15040 Kensington Park Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.789543,
              -117.85272
          ],
          "Rest_Name": "Old Towne Orange",
          "id": 2953,
          "address": "112 E Maple Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.02197,
              -117.959587
          ],
          "Rest_Name": "Hacienda Valley",
          "id": 2954,
          "address": "15495 Valley Blvd",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.139865,
              -118.012466
          ],
          "Rest_Name": "Huntington Oaks",
          "id": 2955,
          "address": "608 W Huntington Dr",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.532391,
              -119.86559
          ],
          "Rest_Name": "Mae Anne & N McCarran",
          "id": 2956,
          "address": "5144 Mae Anne Ave Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.685416,
              -117.811168
          ],
          "Rest_Name": "The Crossroads",
          "id": 2957,
          "address": "3966 Barranca Pkwy",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.83961,
              -117.888693
          ],
          "Rest_Name": "Anaheim Town Square",
          "id": 2958,
          "address": "2002 E Lincoln Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.773379,
              -117.866934
          ],
          "Rest_Name": "MainPlace Mall",
          "id": 2959,
          "address": "2773 N Main St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.584648,
              -120.541914
          ],
          "Rest_Name": "Nob Hill - Yakima",
          "id": 2960,
          "address": "2416 W Nob Hill Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.803882,
              -117.886198
          ],
          "Rest_Name": "Stadium Crossings",
          "id": 2961,
          "address": "2085 E Katella Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.130966,
              -118.466885
          ],
          "Rest_Name": "Tehachapi 2",
          "id": 2962,
          "address": "340 TUCKER ROAD",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.859707,
              -117.923852
          ],
          "Rest_Name": "Harbor and Orangethorpe",
          "id": 2963,
          "address": "101 E Orangethorpe Ave Ste A",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.135728,
              -118.049735
          ],
          "Rest_Name": "Santa Anita",
          "id": 2964,
          "address": "400 S Baldwin Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.760371,
              -117.885993
          ],
          "Rest_Name": "Santa Ana College",
          "id": 2965,
          "address": "1313 W 17th St Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.916839,
              -117.958823
          ],
          "Rest_Name": "La Habra",
          "id": 2966,
          "address": "1202 S Idaho St Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.649611,
              -117.83931
          ],
          "Rest_Name": "UC Irvine",
          "id": 2967,
          "address": "4255 Campus Dr Ste A116",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.151701,
              -118.080225
          ],
          "Rest_Name": "East Pas",
          "id": 2968,
          "address": "3409 E Foothill Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.944445,
              -117.994841
          ],
          "Rest_Name": "Whittier",
          "id": 2969,
          "address": "15528 Whittier Blvd Ste A",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.698363,
              -117.885057
          ],
          "Rest_Name": "Bristol Center",
          "id": 2970,
          "address": "3705 S Bristol St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.818736,
              -117.941747
          ],
          "Rest_Name": "Anaheim",
          "id": 2971,
          "address": "927 S Euclid St Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.638916,
              -117.859999
          ],
          "Rest_Name": "The Bluffs",
          "id": 2972,
          "address": "1322 Bison Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.104598,
              -118.073861
          ],
          "Rest_Name": "Temple City",
          "id": 2973,
          "address": "5809 Rosemead Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.667885,
              -117.882953
          ],
          "Rest_Name": "Bristol St",
          "id": 2974,
          "address": "260 Bristol St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.738685,
              -117.919629
          ],
          "Rest_Name": "Santa Ana",
          "id": 2975,
          "address": "629 S Harbor Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.074594,
              -118.072174
          ],
          "Rest_Name": "Rosemead Place",
          "id": 2976,
          "address": "3616 Rosemead Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.146268,
              -118.115557
          ],
          "Rest_Name": "PCC II",
          "id": 2977,
          "address": "1687 E Colorado Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.598516,
              -117.871516
          ],
          "Rest_Name": "Corona Del Mar",
          "id": 2978,
          "address": "3050 E Coast Hwy",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.9781,
              -118.04924
          ],
          "Rest_Name": "West Whittier",
          "id": 2979,
          "address": "11840 Whittier Blvd.",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.788829,
              -117.963191
          ],
          "Rest_Name": "Garden Grove Promenade",
          "id": 2980,
          "address": "9737 Chapman Ave Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.141626,
              -118.132084
          ],
          "Rest_Name": "South Lake",
          "id": 2981,
          "address": "246 S Lake Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.941085,
              -118.046197
          ],
          "Rest_Name": "Santa Fe Springs",
          "id": 2982,
          "address": "10120 Carmenita Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.658126,
              -117.918601
          ],
          "Rest_Name": "Harbor Center",
          "id": 2983,
          "address": "2300 Harbor Blvd Ste L1",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.629583,
              -117.90936
          ],
          "Rest_Name": "Costa Mesa",
          "id": 2984,
          "address": "468 E 17th St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.001219,
              -118.08428
          ],
          "Rest_Name": "Pico Rivera",
          "id": 2985,
          "address": "8888 Whittier Blvd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.713977,
              -117.953572
          ],
          "Rest_Name": "Brookhurst St",
          "id": 2986,
          "address": "17150 Brookhurst St Ste E",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.858361,
              -118.030546
          ],
          "Rest_Name": "Centerpointe",
          "id": 2987,
          "address": "5976 Orangethorpe Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.687593,
              -117.954502
          ],
          "Rest_Name": "Fountain Valley",
          "id": 2988,
          "address": "18951 Brookhurst St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.018922,
              -118.105875
          ],
          "Rest_Name": "Montebello",
          "id": 2989,
          "address": "545 N Montebello Blvd Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.115575,
              -118.15078
          ],
          "Rest_Name": "South Pasadena",
          "id": 2990,
          "address": "901 Fair Oaks Ave Unit B",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.803658,
              -118.01245
          ],
          "Rest_Name": "Cypress Center",
          "id": 2991,
          "address": "6859 Katella Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.917263,
              -118.065426
          ],
          "Rest_Name": "Norwalk CA",
          "id": 2992,
          "address": "12539 Imperial Hwy Ste 103",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.092527,
              -118.145827
          ],
          "Rest_Name": "Alhambra II",
          "id": 2993,
          "address": "2121 W Main St Ste 210",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.615295,
              -117.930623
          ],
          "Rest_Name": "Newport Peninsula",
          "id": 2994,
          "address": "3101 Newport Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.203616,
              -118.197853
          ],
          "Rest_Name": "La Canada Town Center",
          "id": 2995,
          "address": "895 Foothill Blvd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.726841,
              -117.989692
          ],
          "Rest_Name": "Beach Blvd.",
          "id": 2996,
          "address": "16241 Beach Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.759406,
              -118.007543
          ],
          "Rest_Name": "Westminster",
          "id": 2997,
          "address": "6777 Westminster Blvd Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.872793,
              -118.064185
          ],
          "Rest_Name": "Cerritos",
          "id": 2998,
          "address": "12560 Artesia Blvd",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.658079,
              -117.970641
          ],
          "Rest_Name": "Magnolia and Atlanta",
          "id": 2999,
          "address": "20962 Magnolia St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.038421,
              -118.144575
          ],
          "Rest_Name": "ELA City College",
          "id": 3000,
          "address": "2180 S Atlantic Blvd Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.680656,
              -118.004241
          ],
          "Rest_Name": "Seacliff Village",
          "id": 3001,
          "address": "7101 Yorktown Ave Ste 109",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.00544,
              -118.152899
          ],
          "Rest_Name": "Citadel Outlets",
          "id": 3002,
          "address": "150 Citadel Dr",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.138336,
              -118.214607
          ],
          "Rest_Name": "Eagle Rock",
          "id": 3003,
          "address": "5047 Eagle Rock Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.8873,
              -118.1022
          ],
          "Rest_Name": "Cerritos College Square",
          "id": 3004,
          "address": "10826 Alondra Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.860461,
              -118.092518
          ],
          "Rest_Name": "Los Cerritos",
          "id": 3005,
          "address": "459 Los Cerritos Mall",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.937825,
              -118.129544
          ],
          "Rest_Name": "Downey",
          "id": 3006,
          "address": "8500 Firestone Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.922263,
              -118.131536
          ],
          "Rest_Name": "Promenade at Downey",
          "id": 3007,
          "address": "8890 Apollo Way Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.782981,
              -118.072325
          ],
          "Rest_Name": "Rossmoor",
          "id": 3008,
          "address": "12359 Seal Beach Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.06404,
              -118.202104
          ],
          "Rest_Name": "LAC & USC Med Center",
          "id": 3009,
          "address": "2200 Trojan Way Ste 152",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.147481,
              -118.246395
          ],
          "Rest_Name": "Glendale CA",
          "id": 3010,
          "address": "132 N Glendale Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.948013,
              -119.958085
          ],
          "Rest_Name": "SLT",
          "id": 3011,
          "address": "3640-A Lake Tahoe Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.0807,
              -119.043549
          ],
          "Rest_Name": "Porterville",
          "id": 3012,
          "address": "1174 W Henderson Ave Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.147928,
              -118.255293
          ],
          "Rest_Name": "Brand Blvd.",
          "id": 3013,
          "address": "155 N. Brand Blvd.",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.145115,
              -118.259277
          ],
          "Rest_Name": "Glendale Galleria",
          "id": 3014,
          "address": "1135 Glendale Galleria",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.952508,
              -118.185617
          ],
          "Rest_Name": "Azalea South Gate",
          "id": 3015,
          "address": "4709 Firestone Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.85416,
              -118.141947
          ],
          "Rest_Name": "Lakewood Mall",
          "id": 3016,
          "address": "5310 Lakewood Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.889544,
              -118.159775
          ],
          "Rest_Name": "Paramount",
          "id": 3017,
          "address": "15754 Paramount Blvd Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.759944,
              -118.114883
          ],
          "Rest_Name": "Marina Pacifica",
          "id": 3018,
          "address": "6324 E Pacific Coast Hwy Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.053669,
              -118.251486
          ],
          "Rest_Name": "Bunker Hill",
          "id": 3019,
          "address": "255 S Grand Ave Ste 102",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.077065,
              -118.264695
          ],
          "Rest_Name": "Echo Park",
          "id": 3020,
          "address": "2134 W Sunset Blvd Ste F",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.181214,
              -118.311737
          ],
          "Rest_Name": "Burbank",
          "id": 3021,
          "address": "135 E Palm Ave Spc A2",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.790174,
              -118.138672
          ],
          "Rest_Name": "Long Beach",
          "id": 3022,
          "address": "1800 Ximeno Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.047573,
              -118.256658
          ],
          "Rest_Name": "Financial District LA",
          "id": 3023,
          "address": "601 W 7th St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.054046,
              -118.262461
          ],
          "Rest_Name": "City West",
          "id": 3024,
          "address": "1122 W 6th St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.041789,
              -118.258498
          ],
          "Rest_Name": "South Park",
          "id": 3025,
          "address": "301 W Olympic Blvd Ste A",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.974481,
              -118.233506
          ],
          "Rest_Name": "Huntington Park",
          "id": 3026,
          "address": "2110 E Florence Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.06215,
              -118.291372
          ],
          "Rest_Name": "Koreatown",
          "id": 3027,
          "address": "3183 Wilshire Blvd Ste 195",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.811967,
              -118.181334
          ],
          "Rest_Name": "Signal Hill",
          "id": 3028,
          "address": "959 E. Spring Street",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.032096,
              -118.284247
          ],
          "Rest_Name": "Hoover & Adams USC",
          "id": 3029,
          "address": "2595 S Hoover St Ste B",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.876523,
              -118.217833
          ],
          "Rest_Name": "Compton",
          "id": 3030,
          "address": "1759 S Alameda St Ste 104",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.016993,
              -118.282559
          ],
          "Rest_Name": "USC",
          "id": 3031,
          "address": "3748 S Figueroa St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.15169,
              -118.343344
          ],
          "Rest_Name": "Toluca Lake",
          "id": 3032,
          "address": "4012 W Riverside Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.097858,
              -118.326368
          ],
          "Rest_Name": "Sunset & Vine Relo",
          "id": 3033,
          "address": "6290 W Sunset Blvd Ste C",
          "rating": 2.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.394452,
              -118.462748
          ],
          "Rest_Name": "Golden Valley Ranch",
          "id": 3034,
          "address": "19085 Golden Valley Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.076439,
              -118.323939
          ],
          "Rest_Name": "Larchmont Village",
          "id": 3035,
          "address": "301 N Larchmont Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.769909,
              -118.193141
          ],
          "Rest_Name": "Downtown Long Beach",
          "id": 3036,
          "address": "245 Pine Ave Ste 190",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.166181,
              -118.375136
          ],
          "Rest_Name": "NoHo LA",
          "id": 3037,
          "address": "5240 Lankershim Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.083651,
              -118.344347
          ],
          "Rest_Name": "Melrose Place",
          "id": 3038,
          "address": "7101 Melrose Ave",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.097856,
              -118.356916
          ],
          "Rest_Name": "Sunset Boulevard",
          "id": 3039,
          "address": "7660 W Sunset Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.048562,
              -118.3367
          ],
          "Rest_Name": "Midtown Crossing",
          "id": 3040,
          "address": "4550 W Pico Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.283559,
              -118.441346
          ],
          "Rest_Name": "San Fernando",
          "id": 3041,
          "address": "1100 Truman St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.062163,
              -118.3502
          ],
          "Rest_Name": "Miracle Mile",
          "id": 3042,
          "address": "5550 Wilshire Blvd Ste 101B",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.073633,
              -118.360931
          ],
          "Rest_Name": "Farmers Market",
          "id": 3043,
          "address": "110 S Fairfax Ave Ste A12",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.013287,
              -118.336632
          ],
          "Rest_Name": "Baldwin Hills",
          "id": 3044,
          "address": "3939 Crenshaw Blvd Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.846577,
              -118.263448
          ],
          "Rest_Name": "Southbay Pavilion",
          "id": 3045,
          "address": "20420 Avalon Blvd Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.143657,
              -118.397758
          ],
          "Rest_Name": "Studio City",
          "id": 3046,
          "address": "12175 Ventura Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.187098,
              -118.417114
          ],
          "Rest_Name": "Victory Plaza",
          "id": 3047,
          "address": "13075 Victory Blvd Spc 102",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.075866,
              -118.373895
          ],
          "Rest_Name": "West Hollywood",
          "id": 3048,
          "address": "8420 Beverly Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.427711,
              -118.537346
          ],
          "Rest_Name": "Bouquet Canyon",
          "id": 3049,
          "address": "26543 Bouquet Canyon Rd",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.258844,
              -118.467198
          ],
          "Rest_Name": "Mission Hills",
          "id": 3050,
          "address": "10384 Sepulveda Blvd Ste 2E",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.215632,
              -118.448389
          ],
          "Rest_Name": "The Plant",
          "id": 3051,
          "address": "7940 Van Nuys Blvd # 2A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.049185,
              -118.375836
          ],
          "Rest_Name": "La Cienega Blvd",
          "id": 3052,
          "address": "1550 S La Cienega Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.945614,
              -118.331624
          ],
          "Rest_Name": "Hollywood Park",
          "id": 3053,
          "address": "3421 W Century Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.930758,
              -118.325539
          ],
          "Rest_Name": "Crenshaw Imperial Plaza",
          "id": 3054,
          "address": "2944 W Imperial Hwy",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.873496,
              -118.308833
          ],
          "Rest_Name": "Gardena Marketplace",
          "id": 3055,
          "address": "1755 W Artesia Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.15663,
              -118.437435
          ],
          "Rest_Name": "Fashion Square Mall",
          "id": 3056,
          "address": "14006 Riverside Dr Ste 249B",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.063337,
              -118.398891
          ],
          "Rest_Name": "Beverly Hills",
          "id": 3057,
          "address": "244 S Beverly Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.172335,
              -118.4483
          ],
          "Rest_Name": "Valley Glen",
          "id": 3058,
          "address": "5600 Van Nuys Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.417927,
              -118.561428
          ],
          "Rest_Name": "Valencia Towne Center",
          "id": 3059,
          "address": "24405 Town Center Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.392482,
              -118.967716
          ],
          "Rest_Name": "Mt Vernon Bakersfield",
          "id": 3060,
          "address": "2630 Mount Vernon Ave Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.442702,
              -118.573836
          ],
          "Rest_Name": "Valencia",
          "id": 3061,
          "address": "28102 Newhall Ranch Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.2917,
              -119.31384
          ],
          "Rest_Name": "Visalia",
          "id": 3062,
          "address": "4219 S Mooney Blvd Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.978436,
              -118.370774
          ],
          "Rest_Name": "Ladera Heights",
          "id": 3063,
          "address": "6719 La Tijera Blvd Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.023344,
              -118.394364
          ],
          "Rest_Name": "Culver City DT",
          "id": 3064,
          "address": "9512 Culver Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.151531,
              -118.453641
          ],
          "Rest_Name": "Sherman Oaks",
          "id": 3065,
          "address": "14708 Ventura Blvd Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.257778,
              -118.502046
          ],
          "Rest_Name": "Granada Hills",
          "id": 3066,
          "address": "16959 Devonshire St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.058179,
              -118.419647
          ],
          "Rest_Name": "Century City",
          "id": 3067,
          "address": "10250 Santa Monica Blvd Ste FC-2",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.988136,
              -118.393006
          ],
          "Rest_Name": "Fox Hills",
          "id": 3068,
          "address": "6150 W Slauson Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.985652,
              -118.393062
          ],
          "Rest_Name": "Culver City Terrace",
          "id": 3069,
          "address": "6000 Sepulveda Blvd Ste 3236",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.223617,
              -118.501788
          ],
          "Rest_Name": "North Hills",
          "id": 3070,
          "address": "8420 Balboa Blvd Ste 110",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.007163,
              -118.412697
          ],
          "Rest_Name": "Culver Crossroads",
          "id": 3071,
          "address": "4114 Sepulveda Blvd Ste F",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.90122,
              -118.373185
          ],
          "Rest_Name": "Hawthorne",
          "id": 3072,
          "address": "5330 W Rosecrans Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.226341,
              -119.328949
          ],
          "Rest_Name": "Tulare",
          "id": 3073,
          "address": "1108 E Prosperity Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.801825,
              -118.328423
          ],
          "Rest_Name": "Torrance Crossroads",
          "id": 3074,
          "address": "24631 Crenshaw Blvd Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.061111,
              -118.446169
          ],
          "Rest_Name": "UCLA Bruins",
          "id": 3075,
          "address": "1077 Broxton Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.751009,
              -118.307294
          ],
          "Rest_Name": "San Pedro Heights",
          "id": 3076,
          "address": "940 N Western Ave Ste E",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.831467,
              -118.349755
          ],
          "Rest_Name": "Del Amo Dining Terrace",
          "id": 3077,
          "address": "3525 W Carson St Ste VC02",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.834921,
              -118.352794
          ],
          "Rest_Name": "Del Amo",
          "id": 3078,
          "address": "21300 Hawthorne Blvd Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.022651,
              -118.439204
          ],
          "Rest_Name": "Mar Vista",
          "id": 3079,
          "address": "11711 National Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.236922,
              -118.535728
          ],
          "Rest_Name": "CSUN",
          "id": 3080,
          "address": "9160 Reseda Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.91969,
              -118.39649
          ],
          "Rest_Name": "El Segundo",
          "id": 3081,
          "address": "307 N Pacific Coast Hwy",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.053542,
              -118.464888
          ],
          "Rest_Name": "Brentwood LA",
          "id": 3082,
          "address": "11690 San Vicente Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.33245,
              -119.39177
          ],
          "Rest_Name": "Plaza & 198",
          "id": 3083,
          "address": "248 PLAZA DRIVE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.979147,
              -118.438326
          ],
          "Rest_Name": "Marina Del Rey",
          "id": 3084,
          "address": "4718 Admiralty Way",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.275514,
              -118.572534
          ],
          "Rest_Name": "The Vineyards",
          "id": 3085,
          "address": "20111 Rinaldi St Bldg S6",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.234682,
              -118.555099
          ],
          "Rest_Name": "Nordhoff Plaza",
          "id": 3086,
          "address": "19332 Nordhoff St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.8654,
              -118.393478
          ],
          "Rest_Name": "Hermosa Beach",
          "id": 3087,
          "address": "1439 Pacific Coast Hwy",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.170359,
              -118.540585
          ],
          "Rest_Name": "Tarzana",
          "id": 3088,
          "address": "18700 Ventura Blvd Ste 130",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.337714,
              -119.035162
          ],
          "Rest_Name": "Valley Plaza Food Court",
          "id": 3089,
          "address": "2701 Ming Ave Spc 200",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.03211,
              -118.482879
          ],
          "Rest_Name": "Santa Monica Mid City",
          "id": 3090,
          "address": "2025 Wilshire Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              33.776098,
              -118.375914
          ],
          "Rest_Name": "Rolling Hills Estates",
          "id": 3091,
          "address": "4C Peninsula Ctr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.354548,
              -119.057057
          ],
          "Rest_Name": "Bakersfield",
          "id": 3092,
          "address": "4950 Stockdale Hwy",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.237531,
              -118.589083
          ],
          "Rest_Name": "Chatsworth",
          "id": 3093,
          "address": "9175 De Soto Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.017789,
              -118.498916
          ],
          "Rest_Name": "Santa Monica",
          "id": 3094,
          "address": "1218 3rd Street Promenade",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.401167,
              -119.091835
          ],
          "Rest_Name": "Bakersfield NW",
          "id": 3095,
          "address": "4580 Coffee Rd Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.046424,
              -118.524288
          ],
          "Rest_Name": "Pacific Palisades",
          "id": 3096,
          "address": "15207 W Sunset Blvd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.187781,
              -118.597917
          ],
          "Rest_Name": "Warner Center",
          "id": 3097,
          "address": "6443 Canoga Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.189967,
              -118.602638
          ],
          "Rest_Name": "Topanga",
          "id": 3098,
          "address": "6600 Topanga Canyon Blvd Unit FC09",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.74798,
              -119.248504
          ],
          "Rest_Name": "Delano",
          "id": 3099,
          "address": "503 WOOLLOMES AVENUE",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.986516,
              -118.946312
          ],
          "Rest_Name": "Tejon Ranch",
          "id": 3100,
          "address": "5602 Dennis McCarthy Dr Ste B",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.16985,
              -118.605267
          ],
          "Rest_Name": "Woodland Hills",
          "id": 3101,
          "address": "5430 Topanga Canyon Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.306519,
              -119.092532
          ],
          "Rest_Name": "Gosford Village",
          "id": 3102,
          "address": "5321 Gosford Rd",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.352108,
              -119.119109
          ],
          "Rest_Name": "River Walk Bakersfield",
          "id": 3103,
          "address": "10510 Stockdale Hwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.837044,
              -119.682797
          ],
          "Rest_Name": "Clovis NE",
          "id": 3104,
          "address": "1840 Herndon Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.296012,
              -119.111053
          ],
          "Rest_Name": "Panama & Old River",
          "id": 3105,
          "address": "9723 Panama Ln Ste A",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.324745,
              -119.128107
          ],
          "Rest_Name": "Buena Vista & White",
          "id": 3106,
          "address": "3345 Buena Vista Rd Ste 650",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.808266,
              -119.693632
          ],
          "Rest_Name": "Clovis",
          "id": 3107,
          "address": "1210 Shaw Ave Ste 101",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.576644,
              -119.631736
          ],
          "Rest_Name": "Selma",
          "id": 3108,
          "address": "3120 Floral Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.808204,
              -119.753605
          ],
          "Rest_Name": "Fresno State",
          "id": 3109,
          "address": "4978 N Cedar Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.284132,
              -118.775359
          ],
          "Rest_Name": "Simi Valley",
          "id": 3110,
          "address": "1263 Simi Town Center Way Ste H2",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.102446,
              -121.298058
          ],
          "Rest_Name": "North Bend",
          "id": 3111,
          "address": "20524 Robal Ln",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.852114,
              -119.788978
          ],
          "Rest_Name": "Riverpark Plaza",
          "id": 3112,
          "address": "80 E Nees Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.055159,
              -121.3034
          ],
          "Rest_Name": "Bend",
          "id": 3113,
          "address": "222 NE Emerson Ave Ste 100",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.036082,
              -118.684706
          ],
          "Rest_Name": "Malibu Village",
          "id": 3114,
          "address": "3822 Cross Creek Rd # 3834",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.781636,
              -119.790074
          ],
          "Rest_Name": "Manchester Mall",
          "id": 3115,
          "address": "3520 N Blackstone Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.811035,
              -119.808014
          ],
          "Rest_Name": "Fig Garden",
          "id": 3116,
          "address": "5128 N Palm Ave",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.3289,
              -119.6731
          ],
          "Rest_Name": "Hanford",
          "id": 3117,
          "address": "366 N 12th Ave Ste 113",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.153706,
              -118.796529
          ],
          "Rest_Name": "Westlake Village",
          "id": 3118,
          "address": "5754 Lindero Canyon Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.836732,
              -119.862392
          ],
          "Rest_Name": "Herndon & Brawley",
          "id": 3119,
          "address": "6702 N Brawley Ave",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.2789,
              -118.866647
          ],
          "Rest_Name": "Moorpark",
          "id": 3120,
          "address": "806 E Los Angeles Ave.",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.808313,
              -119.872126
          ],
          "Rest_Name": "West Shaw",
          "id": 3121,
          "address": "4424 W Shaw Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.182059,
              -118.884967
          ],
          "Rest_Name": "Thousand Oaks",
          "id": 3122,
          "address": "162 W Hillcrest Dr Ste 300",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.191179,
              -118.937736
          ],
          "Rest_Name": "Newbury Park",
          "id": 3123,
          "address": "935 Broadbeck Dr Ste C",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.973888,
              -120.082878
          ],
          "Rest_Name": "Madera",
          "id": 3124,
          "address": "2180 W Cleveland Ave Ste 104",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.217281,
              -119.065521
          ],
          "Rest_Name": "Camarillo Outlets",
          "id": 3125,
          "address": "660 Ventura Blvd Unit 500",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.234003,
              -119.178705
          ],
          "Rest_Name": "Oxnard",
          "id": 3126,
          "address": "369 W Esplanade Dr",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.264401,
              -119.212597
          ],
          "Rest_Name": "Paradise Center",
          "id": 3127,
          "address": "1145 S Victoria Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.713223,
              -120.840127
          ],
          "Rest_Name": "Placerville",
          "id": 3128,
          "address": "3987 Missouri Flat Rd Ste 390",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.266791,
              -119.246065
          ],
          "Rest_Name": "Ventura",
          "id": 3129,
          "address": "488 S Mills Rd Ste 104",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.188358,
              -119.222115
          ],
          "Rest_Name": "Seabridge",
          "id": 3130,
          "address": "1291 S Victoria Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.319654,
              -120.486286
          ],
          "Rest_Name": "Merced II",
          "id": 3131,
          "address": "3110 R St Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.941045,
              -121.09611
          ],
          "Rest_Name": "Auburn",
          "id": 3132,
          "address": "2845 Bell Rd Ste A",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.606125,
              -122.036285
          ],
          "Rest_Name": "Sammamish",
          "id": 3133,
          "address": "22704 SE 4th St Ste 210",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.65217,
              -121.067986
          ],
          "Rest_Name": "El Dorado Hills",
          "id": 3134,
          "address": "4520 Post St Ste 294",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.54394,
              -122.049473
          ],
          "Rest_Name": "Issaquah",
          "id": 3135,
          "address": "775 NW Gilman Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.644985,
              -121.123267
          ],
          "Rest_Name": "Folsom 2",
          "id": 3136,
          "address": "2379 Iron Point Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.666691,
              -122.102234
          ],
          "Rest_Name": "Redmond",
          "id": 3137,
          "address": "17875 Redmond Way Ste 170",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.743916,
              -121.171776
          ],
          "Rest_Name": "Folsom Lake",
          "id": 3138,
          "address": "6981 Douglas Blvd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.62195,
              -122.13232
          ],
          "Rest_Name": "Crossroads II",
          "id": 3139,
          "address": "1328 156th Ave NE",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.669502,
              -121.155663
          ],
          "Rest_Name": "Willowcreek",
          "id": 3140,
          "address": "1001 E Bidwell St Ste 106",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.804619,
              -121.206535
          ],
          "Rest_Name": "Rocklin Commons",
          "id": 3141,
          "address": "5194 Commons Dr Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.021763,
              -122.108812
          ],
          "Rest_Name": "Lake Stevens",
          "id": 3142,
          "address": "2812 90th Ave. NE ",
          "rating": 2.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.167697,
              -122.187984
          ],
          "Rest_Name": "Bonney Lake",
          "id": 3143,
          "address": "20416 98th St E",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.574662,
              -122.168647
          ],
          "Rest_Name": "Factoria",
          "id": 3144,
          "address": "4000 Factoria Blvd SE",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.754871,
              -122.155743
          ],
          "Rest_Name": "Woodinville",
          "id": 3145,
          "address": "13780 NE 175th St Ste 104",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.613816,
              -122.185565
          ],
          "Rest_Name": "East Bellevue",
          "id": 3146,
          "address": "376 116th Ave NE",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.711533,
              -122.181874
          ],
          "Rest_Name": "Totem Lake",
          "id": 3147,
          "address": "12530 Totem Lake Blvd NE Ste 136",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.74307,
              -121.22751
          ],
          "Rest_Name": "Granite Bay",
          "id": 3148,
          "address": "3988 Douglas Blvd Ste 140",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.613607,
              -122.199857
          ],
          "Rest_Name": "Bellevue",
          "id": 3149,
          "address": "10503 NE 4th St Ste 200",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.477325,
              -122.21635
          ],
          "Rest_Name": "Renton",
          "id": 3150,
          "address": "439 Rainier Ave S Ste B",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.661606,
              -121.226037
          ],
          "Rest_Name": "Fair Oaks Madison",
          "id": 3151,
          "address": "5223 Hazel Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.385218,
              -122.235006
          ],
          "Rest_Name": "Kent",
          "id": 3152,
          "address": "512 Ramsay Way Ste 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.520648,
              -120.880841
          ],
          "Rest_Name": "Turlock",
          "id": 3153,
          "address": "3090 Countryside Dr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.805906,
              -122.206571
          ],
          "Rest_Name": "Canyon Park",
          "id": 3154,
          "address": "21135 Bothell Everett HWY",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.770149,
              -121.264757
          ],
          "Rest_Name": "Creekside",
          "id": 3155,
          "address": "1136 Galleria Blvd Ste 160",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.78955,
              -121.280949
          ],
          "Rest_Name": "Highland Plaza",
          "id": 3156,
          "address": "781 Pleasant Grove Blvd Ste 140",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.711362,
              -120.958054
          ],
          "Rest_Name": "Riverbank",
          "id": 3157,
          "address": "2207 Claribel Rd Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.458545,
              -122.256103
          ],
          "Rest_Name": "Southcenter Mall",
          "id": 3158,
          "address": "2800 Southcenter Mall",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.151763,
              -122.191354
          ],
          "Rest_Name": "Smokey Point",
          "id": 3159,
          "address": "17103 28th Dr NE Ste 101",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.449379,
              -122.260185
          ],
          "Rest_Name": "Southcenter Square",
          "id": 3160,
          "address": "17250 Southcenter Pkwy Ste 108",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.7608,
              -121.2874
          ],
          "Rest_Name": "Freedom Point",
          "id": 3161,
          "address": "4200 Thrive Dr Ste 140",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.909104,
              -122.225675
          ],
          "Rest_Name": "Everett",
          "id": 3162,
          "address": "515 SE Everett Mall Way",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.10464,
              -122.29409
          ],
          "Rest_Name": "South Hill",
          "id": 3163,
          "address": "10115 167th St Ct E",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.146748,
              -122.292509
          ],
          "Rest_Name": "Puyallup",
          "id": 3164,
          "address": "12011 Meridian E",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.672049,
              -121.272114
          ],
          "Rest_Name": "Sunrise",
          "id": 3165,
          "address": "5851 Sunrise Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.579848,
              -120.941099
          ],
          "Rest_Name": "Ceres",
          "id": 3166,
          "address": "2870 E Service Rd",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.439451,
              -119.74914
          ],
          "Rest_Name": "La Cumbre",
          "id": 3167,
          "address": "3851 State St Ste A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.617043,
              -121.26897
          ],
          "Rest_Name": "Rancho Cordova Rivergate",
          "id": 3168,
          "address": "2350 Sunrise Blvd Ste 3",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.316376,
              -122.31382
          ],
          "Rest_Name": "Federal Way",
          "id": 3169,
          "address": "31827 Pacific Hwy S Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.671339,
              -120.994148
          ],
          "Rest_Name": "Briggsmore Center",
          "id": 3170,
          "address": "1801 Mchenry Ave Ste B",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.829413,
              -122.273781
          ],
          "Rest_Name": "Alderwood Mall",
          "id": 3171,
          "address": "3000 184th St SW Ste 850",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.661911,
              -122.298729
          ],
          "Rest_Name": "U Village",
          "id": 3172,
          "address": "2626 NE 46th St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.721844,
              -122.294185
          ],
          "Rest_Name": "Lake City Way",
          "id": 3173,
          "address": "12725 Lake City Way NE",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.820549,
              -122.288954
          ],
          "Rest_Name": "Lynnwood",
          "id": 3174,
          "address": "4120 196th St SW Ste 150",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.594345,
              -121.28845
          ],
          "Rest_Name": "Rancho Cordova",
          "id": 3175,
          "address": "2878 Zinfandel Dr Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.658806,
              -122.313398
          ],
          "Rest_Name": "The Ave.",
          "id": 3176,
          "address": "4229 University Way NE",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.7918,
              -121.356055
          ],
          "Rest_Name": "Blue Oaks Plaza",
          "id": 3177,
          "address": "1980 Blue Oaks Blvd Ste 110",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.613342,
              -122.320946
          ],
          "Rest_Name": "Capitol Hill",
          "id": 3178,
          "address": "1415 Broadway",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.70628,
              -122.326405
          ],
          "Rest_Name": "Northgate",
          "id": 3179,
          "address": "401 NE Northgate Way Spc 1119",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.610279,
              -122.337519
          ],
          "Rest_Name": "DT Seattle",
          "id": 3180,
          "address": "1501 4th Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.620364,
              -122.338261
          ],
          "Rest_Name": "South Lake Union",
          "id": 3181,
          "address": "212 Westlake Ave N",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.057266,
              -120.832894
          ],
          "Rest_Name": "Los Banos",
          "id": 3182,
          "address": "1420 E Pacheco Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.499779,
              -122.411024
          ],
          "Rest_Name": "Gresham",
          "id": 3183,
          "address": "2065 NE Burnside Rd",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.497477,
              -121.574288
          ],
          "Rest_Name": "Oroville",
          "id": 3184,
          "address": "325 Oroville Dam Blvd ",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.660209,
              -121.34706
          ],
          "Rest_Name": "Foothill Farms",
          "id": 3185,
          "address": "5040 Auburn Blvd Ste A",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.687718,
              -121.052528
          ],
          "Rest_Name": "Modesto",
          "id": 3186,
          "address": "3401 Dale Rd Ste 650",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.743233,
              -122.346304
          ],
          "Rest_Name": "Shoreline",
          "id": 3187,
          "address": "15725 Westminster Way N",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.52922,
              -122.42716
          ],
          "Rest_Name": "Wood Village",
          "id": 3188,
          "address": "920 NE Wood Village Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.635607,
              -122.377037
          ],
          "Rest_Name": "Interbay",
          "id": 3189,
          "address": "1827 15th Ave W Ste A22",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.56013,
              -122.386426
          ],
          "Rest_Name": "The Junction",
          "id": 3190,
          "address": "4730 California Ave SW Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.119021,
              -122.434299
          ],
          "Rest_Name": "Spanaway",
          "id": 3191,
          "address": "15211 Pacific Ave S",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.429193,
              -119.869361
          ],
          "Rest_Name": "Goleta",
          "id": 3192,
          "address": "270 Storke Rd Ste F",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.610032,
              -121.385745
          ],
          "Rest_Name": "Country Club",
          "id": 3193,
          "address": "3328 El Camino Ave Ste 300",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.594658,
              -121.385841
          ],
          "Rest_Name": "Arden Park",
          "id": 3194,
          "address": "3302 Arden Way",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.452735,
              -122.335691
          ],
          "Rest_Name": "Burlington Blvd.",
          "id": 3195,
          "address": "1753 S Burlington Blvd Ste 106",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.4269,
              -122.4882
          ],
          "Rest_Name": "Happy Valley Crossroads",
          "id": 3196,
          "address": "17015 SE Sunnyside Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.614025,
              -122.503591
          ],
          "Rest_Name": "Mill Plain",
          "id": 3197,
          "address": "915 SE 164th Ave Ste 100",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.218098,
              -122.467119
          ],
          "Rest_Name": "Tacoma Mall RELO",
          "id": 3198,
          "address": "4301 S Steele St",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.575663,
              -121.4034
          ],
          "Rest_Name": "Loehmann's Plaza",
          "id": 3199,
          "address": "2517 Fair Oaks Blvd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.100763,
              -121.26707
          ],
          "Rest_Name": "Reynolds Ranch",
          "id": 3200,
          "address": "2601 Reynolds Ranch Pkwy Ste 140",
          "rating": 4.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.597374,
              -121.415885
          ],
          "Rest_Name": "Howe & Arden",
          "id": 3201,
          "address": "1729 Howe Ave",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.672456,
              -122.525802
          ],
          "Rest_Name": "Fourth Plain - Vancouver",
          "id": 3202,
          "address": "14321 NE Fourth Plain Blvd",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.796344,
              -121.193043
          ],
          "Rest_Name": "Manteca",
          "id": 3203,
          "address": "1442 Hulsey Way",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.76955,
              -122.549798
          ],
          "Rest_Name": "Battleground - WA",
          "id": 3204,
          "address": "1201 SW Scotton Way",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.116729,
              -121.302526
          ],
          "Rest_Name": "Lodi",
          "id": 3205,
          "address": "2310 Tienda Dr Ste 200",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.557678,
              -121.436589
          ],
          "Rest_Name": "East Sacramento",
          "id": 3206,
          "address": "5738 Folsom Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.621403,
              -122.553009
          ],
          "Rest_Name": "205 Commerce",
          "id": 3207,
          "address": "11600 SE Mill Plain Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.163102,
              -122.52042
          ],
          "Rest_Name": "Lakewood Town Center",
          "id": 3208,
          "address": "10427 Gravelly Lake Dr SW Ste A",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.575423,
              -122.564101
          ],
          "Rest_Name": "Portland Cascade Station",
          "id": 3209,
          "address": "9687 NE Cascades Pkwy",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.423214,
              -121.415578
          ],
          "Rest_Name": "Laguna Pavilion",
          "id": 3210,
          "address": "7440 Laguna Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.220126,
              -122.537587
          ],
          "Rest_Name": "University Place",
          "id": 3211,
          "address": "4020 Bridgeport Way W",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.021278,
              -121.297019
          ],
          "Rest_Name": "Hammer & West",
          "id": 3212,
          "address": "1205 E Hammer Ln",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.407021,
              -121.417074
          ],
          "Rest_Name": "The Ridge",
          "id": 3213,
          "address": "7450 Elk Grove Blvd",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.435528,
              -122.578969
          ],
          "Rest_Name": "Clackamas Town Center",
          "id": 3214,
          "address": "12130 SE 82nd Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.142598,
              -121.629673
          ],
          "Rest_Name": "Yuba City",
          "id": 3215,
          "address": "1005 Gray Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.653767,
              -122.586601
          ],
          "Rest_Name": "Vancouver WA",
          "id": 3216,
          "address": "4311 NE Thurston Way Ste 200",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.464747,
              -121.13749
          ],
          "Rest_Name": "Patterson",
          "id": 3217,
          "address": "975 Sperry Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.574048,
              -121.482742
          ],
          "Rest_Name": "Capitol Mall",
          "id": 3218,
          "address": "1831 Capitol Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.638697,
              -121.504965
          ],
          "Rest_Name": "Natomas",
          "id": 3219,
          "address": "3830 Truxel Rd Ste 300",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.994695,
              -121.31342
          ],
          "Rest_Name": "Weberstown",
          "id": 3220,
          "address": "4940 Pacific Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.727558,
              -121.805007
          ],
          "Rest_Name": "Chico Mall",
          "id": 3221,
          "address": "1960 E 20th St Ste 100",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.561828,
              -121.491902
          ],
          "Rest_Name": "Tower District",
          "id": 3222,
          "address": "1601 Broadway Ste 300",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.408353,
              -121.449199
          ],
          "Rest_Name": "ELK GROVE 2",
          "id": 3223,
          "address": "4730 Elk Grove Blvd Ste 110",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.58117,
              -121.500204
          ],
          "Rest_Name": "DOCO",
          "id": 3224,
          "address": "615 David J Stern Walk Ste 130",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.655529,
              -121.523951
          ],
          "Rest_Name": "Del Paso Marketplace",
          "id": 3225,
          "address": "2800 Del Paso Rd Ste 100",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.64268,
              -121.52122
          ],
          "Rest_Name": "Arena & I-5",
          "id": 3226,
          "address": "2650 Arena Blvd",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.302453,
              -122.578267
          ],
          "Rest_Name": "Gig Harbor",
          "id": 3227,
          "address": "4823 Point Fosdick Dr",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.537853,
              -121.492431
          ],
          "Rest_Name": "Land Park",
          "id": 3228,
          "address": "4001 Freeport Blvd Ste 102",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.496484,
              -122.626761
          ],
          "Rest_Name": "SE 36th & Powell",
          "id": 3229,
          "address": "3582 SE Powell Blvd",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.734389,
              -121.835142
          ],
          "Rest_Name": "Chico",
          "id": 3230,
          "address": "620 Mangrove Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.761531,
              -121.842141
          ],
          "Rest_Name": "Chico North",
          "id": 3231,
          "address": "962 East Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.4656,
              -121.4912
          ],
          "Rest_Name": "Sacramento Delta Shores",
          "id": 3232,
          "address": "8124 Delta Shores Cir S",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.05393,
              -121.3747
          ],
          "Rest_Name": "Park West Stockton",
          "id": 3233,
          "address": "10710 Trinity Pkwy Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.811335,
              -121.299219
          ],
          "Rest_Name": "Lathrop",
          "id": 3234,
          "address": "16542 Golden Valley Parkway",
          "rating": 2.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.516343,
              -122.655548
          ],
          "Rest_Name": "SE Belmont",
          "id": 3235,
          "address": "910 SE 10th Ave",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.534062,
              -122.658272
          ],
          "Rest_Name": "Lloyd District",
          "id": 3236,
          "address": "704 NE Weidler St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.723376,
              -122.661621
          ],
          "Rest_Name": "Salmon Creek",
          "id": 3237,
          "address": "14003 NE 10th Ave # 201",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.590036,
              -121.549661
          ],
          "Rest_Name": "West Sacramento",
          "id": 3238,
          "address": "775 Ikea Ct Ste 100",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.550914,
              -121.538349
          ],
          "Rest_Name": "Southport Town Center",
          "id": 3239,
          "address": "2155 Town Center Plz Ste E110",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.677636,
              -122.666302
          ],
          "Rest_Name": "Hazel Dell",
          "id": 3240,
          "address": "7715 NE 5th Ave Ste 109",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.784943,
              -122.489619
          ],
          "Rest_Name": "Bellis Fair",
          "id": 3241,
          "address": "4 Bellis Fair Pkwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.517295,
              -122.67568
          ],
          "Rest_Name": "Director Building",
          "id": 3242,
          "address": "240 SW Yamhill St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.530416,
              -122.683916
          ],
          "Rest_Name": "Pearl District",
          "id": 3243,
          "address": "1201 NW Lovejoy St",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.509521,
              -122.68443
          ],
          "Rest_Name": "Portland State",
          "id": 3244,
          "address": "1948 SW Broadway",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.606689,
              -122.628571
          ],
          "Rest_Name": "Bremerton",
          "id": 3245,
          "address": "4350 Wheaton Way",
          "rating": 2.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.523363,
              -122.690951
          ],
          "Rest_Name": "West Burnside",
          "id": 3246,
          "address": "1933 W Burnside St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.75708,
              -122.65918
          ],
          "Rest_Name": "Poulsbo - WA",
          "id": 3247,
          "address": "21401 Olhava Way ",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.420441,
              -122.739151
          ],
          "Rest_Name": "Kruse Way",
          "id": 3248,
          "address": "8 Centerpointe Dr Ste B",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.383011,
              -122.748199
          ],
          "Rest_Name": "Nyberg Woods",
          "id": 3249,
          "address": "7003 SW Nyberg St",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.663348,
              -122.696448
          ],
          "Rest_Name": "Silverdale",
          "id": 3250,
          "address": "11065 Pacific Crest Pl NW Ste B101",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.303293,
              -122.766396
          ],
          "Rest_Name": "Wilsonville",
          "id": 3251,
          "address": "29991 SW Town Center Loop W Ste A",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.487028,
              -122.773661
          ],
          "Rest_Name": "Raleigh Hills",
          "id": 3252,
          "address": "9375 SW Beaverton Hillsdale Hwy",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.454356,
              -122.77552
          ],
          "Rest_Name": "Washington Square",
          "id": 3253,
          "address": "9120 SW Hall Blvd Ste D",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.057931,
              -122.765109
          ],
          "Rest_Name": "Lacey",
          "id": 3254,
          "address": "1177 Marvin Rd NE",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.402986,
              -122.794573
          ],
          "Rest_Name": "Tigard Town Square",
          "id": 3255,
          "address": "16200 SW Pacific Hwy Ste J1",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.495198,
              -122.808846
          ],
          "Rest_Name": "Beaverton",
          "id": 3256,
          "address": "3380 SW Cedar Hills Blvd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.527546,
              -122.815506
          ],
          "Rest_Name": "Cedar Mill",
          "id": 3257,
          "address": "13535 NW Cornell Rd Ste C",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.438467,
              -122.828509
          ],
          "Rest_Name": "Murray Scholls Center",
          "id": 3258,
          "address": "14795 SW Murray Scholls Dr Ste 112",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.614792,
              -120.683402
          ],
          "Rest_Name": "Paso Robles II",
          "id": 3259,
          "address": "98 Niblick Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.996587,
              -122.819006
          ],
          "Rest_Name": "Summerwalk Village",
          "id": 3260,
          "address": "5116 Yelm Hwy SE Ste A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.365505,
              -122.835672
          ],
          "Rest_Name": "Sherwood",
          "id": 3261,
          "address": "21332 SW Langer Farms Pkwy Ste 118",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.7567,
              -121.4626
          ],
          "Rest_Name": "Tracy",
          "id": 3262,
          "address": "2512 Naglee Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.039741,
              -122.835382
          ],
          "Rest_Name": "South Lacey",
          "id": 3263,
          "address": "4002 Pacific Ave  SE",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              34.92348,
              -120.428223
          ],
          "Rest_Name": "Santa Maria Relo",
          "id": 3264,
          "address": "467 E Betteravia Rd",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.483778,
              -120.665005
          ],
          "Rest_Name": "Morro Rd & Hwy 101 - Atascadero",
          "id": 3265,
          "address": "6435 Morro Rd",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.534839,
              -122.87732
          ],
          "Rest_Name": "Hillsboro",
          "id": 3266,
          "address": "2048 NW Stucki Ave.",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.544036,
              -121.741606
          ],
          "Rest_Name": "E Street",
          "id": 3267,
          "address": "227 E St",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.112739,
              -122.672312
          ],
          "Rest_Name": "Langley",
          "id": 3268,
          "address": "19898 Langley Bypass Unit 110",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.151874,
              -122.885217
          ],
          "Rest_Name": "Woodburn",
          "id": 3269,
          "address": "105 Arney Rd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.145998,
              -122.928763
          ],
          "Rest_Name": "Longview WA",
          "id": 3270,
          "address": "923 Ocean Beach Hwy",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.532982,
              -122.935108
          ],
          "Rest_Name": "West Hillsboro",
          "id": 3271,
          "address": "4530 NE Cornell Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              47.041925,
              -122.933975
          ],
          "Rest_Name": "Olympia",
          "id": 3272,
          "address": "625 Black Lake Blvd SW",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.52131,
              -122.90521
          ],
          "Rest_Name": "Lebanon - OR",
          "id": 3273,
          "address": "2590 S. SANTIAM HWY.",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.306648,
              -122.947426
          ],
          "Rest_Name": "Newberg",
          "id": 3274,
          "address": "3105 E Portland Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.12023,
              -120.601663
          ],
          "Rest_Name": "Arroyo Grande",
          "id": 3275,
          "address": "1229 E Grand Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.585387,
              -122.353979
          ],
          "Rest_Name": "Redding",
          "id": 3276,
          "address": "961 Dana Dr Ste 160",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.256799,
              -122.746899
          ],
          "Rest_Name": "PoCo",
          "id": 3277,
          "address": "200-2325 Ottawa St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              35.262626,
              -120.677654
          ],
          "Rest_Name": "Madonna Plaza",
          "id": 3278,
          "address": "297 Madonna Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.047726,
              -122.785064
          ],
          "Rest_Name": "South Surrey",
          "id": 3279,
          "address": "15775 Croydon Dr",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.941878,
              -122.982656
          ],
          "Rest_Name": "Lancaster Mall Salem",
          "id": 3280,
          "address": "890 Lancaster Dr NE",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.011113,
              -122.996269
          ],
          "Rest_Name": "Keizer Station",
          "id": 3281,
          "address": "6198 Ulali Dr NE",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.16786,
              -122.80103
          ],
          "Rest_Name": "Fleetwood VIllage",
          "id": 3282,
          "address": "1-9082 152 Rue",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.188636,
              -122.802242
          ],
          "Rest_Name": "Surrey Guildford Mall",
          "id": 3283,
          "address": "10355 152 St Unit 1005",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.94587,
              -121.69581
          ],
          "Rest_Name": "East Brentwood",
          "id": 3284,
          "address": "7580 BRENTWOOD BLVD",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.182304,
              -122.845027
          ],
          "Rest_Name": "King George Hub",
          "id": 3285,
          "address": "60-9900 King George Boul",
          "rating": 2.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.899967,
              -123.039413
          ],
          "Rest_Name": "South Salem",
          "id": 3286,
          "address": "3883 Commercial St SE",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.949786,
              -123.051033
          ],
          "Rest_Name": "West Salem",
          "id": 3287,
          "address": "1060 Wallace Rd NW",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.961262,
              -121.748868
          ],
          "Rest_Name": "Brentwood Lone Tree Plaza",
          "id": 3288,
          "address": "5401 Lone Tree Way Ste 100",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.843323,
              -121.390773
          ],
          "Rest_Name": "Hollister",
          "id": 3289,
          "address": "1250 E Park St",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.628979,
              -123.080796
          ],
          "Rest_Name": "Albany OR",
          "id": 3290,
          "address": "1961 SE 14th Ave.",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.073415,
              -123.043112
          ],
          "Rest_Name": "Gateway Mall",
          "id": 3291,
          "address": "2860 Gateway St Ste MT200",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.309172,
              -122.851621
          ],
          "Rest_Name": "South Medford",
          "id": 3292,
          "address": "1429 Center Dr Unit 101",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.067703,
              -123.072391
          ],
          "Rest_Name": "Coburg Station",
          "id": 3293,
          "address": "460 Coburg Rd Ste 302",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.04473,
              -123.073896
          ],
          "Rest_Name": "University of Oregon",
          "id": 3294,
          "address": "1395 University St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.005509,
              -121.839451
          ],
          "Rest_Name": "Delta Gateway",
          "id": 3295,
          "address": "4418 Century Blvd",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.699108,
              -121.741628
          ],
          "Rest_Name": "Livermore",
          "id": 3296,
          "address": "4691 1st St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.228898,
              -123.170845
          ],
          "Rest_Name": "McMinnville",
          "id": 3297,
          "address": "2696 NE HIGHWAY 99W",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.366921,
              -121.962349
          ],
          "Rest_Name": "Vacaville Nut Tree",
          "id": 3298,
          "address": "1620 E Monte Vista Ave Ste 101",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              42.346038,
              -122.883924
          ],
          "Rest_Name": "North Medford RELO",
          "id": 3299,
          "address": "60 ROSSANLEY DRIVE",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.22611,
              -123.002441
          ],
          "Rest_Name": "Burnaby Metrotown",
          "id": 3300,
          "address": "4700 Kingsway Suite 2200",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.002972,
              -121.551949
          ],
          "Rest_Name": "Gilroy Ca",
          "id": 3301,
          "address": "6955 Camino Arroyo Ste 70",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.151203,
              -121.656045
          ],
          "Rest_Name": "Morgan Hill",
          "id": 3302,
          "address": "775 Cochrane Rd Ste 140",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.698464,
              -121.84492
          ],
          "Rest_Name": "Livermore Outlets",
          "id": 3303,
          "address": "3238 Livermore Outlets Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.320259,
              -123.072858
          ],
          "Rest_Name": "North Van",
          "id": 3304,
          "address": "1301 Lonsdale Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.588538,
              -123.251087
          ],
          "Rest_Name": "5th & Circle - Corvallis",
          "id": 3305,
          "address": "550 NE Circle Blvd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.177462,
              -123.121396
          ],
          "Rest_Name": "Richmond Central",
          "id": 3306,
          "address": "1845-4720 McClelland Rd",
          "rating": 2.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.950082,
              -121.957979
          ],
          "Rest_Name": "Clayton",
          "id": 3307,
          "address": "5434 Ygnacio Valley Rd Ste 220",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.262922,
              -123.116025
          ],
          "Rest_Name": "Vancouver Fairview",
          "id": 3308,
          "address": "532 Broadway W Unit 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.261589,
              -122.058443
          ],
          "Rest_Name": "Gateway Courtyard",
          "id": 3309,
          "address": "1586 Gateway Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.279247,
              -123.116938
          ],
          "Rest_Name": "East Robson",
          "id": 3310,
          "address": "398 Robson St Unit Sl",
          "rating": 2.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              44.569048,
              -123.279146
          ],
          "Rest_Name": "Corvallis OSU",
          "id": 3311,
          "address": "2501 NW Monroe Ave",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.281535,
              -123.121256
          ],
          "Rest_Name": "Vancouver Robson Square",
          "id": 3312,
          "address": "818 Howe St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.705657,
              -121.890836
          ],
          "Rest_Name": "Dublin Hacienda",
          "id": 3313,
          "address": "5240 Dublin Blvd Ste S2J",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              49.327764,
              -123.139538
          ],
          "Rest_Name": "West Vancouver",
          "id": 3314,
          "address": "879 Park Royal N",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.658794,
              -121.898122
          ],
          "Rest_Name": "Pleasanton",
          "id": 3315,
          "address": "6654 Koll Center Pkwy Ste 330",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.706379,
              -121.929067
          ],
          "Rest_Name": "Dublin",
          "id": 3316,
          "address": "7020 Amador Plaza Rd",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.331238,
              -121.812137
          ],
          "Rest_Name": "Evergreen",
          "id": 3317,
          "address": "2990 E Capitol Expy Ste 40",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.762315,
              -121.960954
          ],
          "Rest_Name": "Bishop Ranch",
          "id": 3318,
          "address": "132 Sunset Dr",
          "rating": 2.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.976754,
              -122.035452
          ],
          "Rest_Name": "Todos Santos",
          "id": 3319,
          "address": "2002 Salvio St Ste A",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.937408,
              -122.025467
          ],
          "Rest_Name": "Concord",
          "id": 3320,
          "address": "785 Oak Grove Rd Ste E1",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.248665,
              -121.802916
          ],
          "Rest_Name": "San Jose Village Oaks",
          "id": 3321,
          "address": "5670 Cottle Rd Ste 30",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.775162,
              -121.978021
          ],
          "Rest_Name": "San Ramon",
          "id": 3322,
          "address": "2421 San Ramon Valley Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.371156,
              -121.844861
          ],
          "Rest_Name": "McKee and N. Capitol Ave.",
          "id": 3323,
          "address": "361 N Capitol Ave Ste 30",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.823223,
              -122.004014
          ],
          "Rest_Name": "Danville",
          "id": 3324,
          "address": "33 Railroad Ave",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.978775,
              -122.066619
          ],
          "Rest_Name": "Pleasant Hill Center",
          "id": 3325,
          "address": "552 Contra Costa Blvd Ste 110",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.945873,
              -122.061647
          ],
          "Rest_Name": "Pleasant Hill",
          "id": 3326,
          "address": "60 Crescent Dr Ste G",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.716984,
              -121.653925
          ],
          "Rest_Name": "Salinas",
          "id": 3327,
          "address": "1748 N Main St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.413534,
              -121.896827
          ],
          "Rest_Name": "Great Mall of America Bay Area",
          "id": 3328,
          "address": "246 Great Mall Dr Ste 246",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.895627,
              -122.061109
          ],
          "Rest_Name": "Walnut Creek",
          "id": 3329,
          "address": "1158 Locust St # D-2A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.314568,
              -121.871725
          ],
          "Rest_Name": "San Jose Sun Garden",
          "id": 3330,
          "address": "1566 Monterey Hwy Ste 10",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.383101,
              -121.896718
          ],
          "Rest_Name": "Brokaw North",
          "id": 3331,
          "address": "1095 E Brokaw Rd Ste 70",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.511047,
              -121.940733
          ],
          "Rest_Name": "Fremont Warm Springs",
          "id": 3332,
          "address": "44029 Osgood Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.994675,
              -122.105783
          ],
          "Rest_Name": "Martinez",
          "id": 3333,
          "address": "1061 Arnold Dr",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.334998,
              -121.892604
          ],
          "Rest_Name": "San Jose One South Market",
          "id": 3334,
          "address": "1 S Market St Ste 40",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.249866,
              -121.876427
          ],
          "Rest_Name": "Almaden",
          "id": 3335,
          "address": "1110 Blossom Hill Rd Ste 30",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.554387,
              -121.981729
          ],
          "Rest_Name": "Fremont",
          "id": 3336,
          "address": "2760 Mowry Ave",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.33185,
              -121.908596
          ],
          "Rest_Name": "The Alameda",
          "id": 3337,
          "address": "975 the Alameda Ste 10",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.915479,
              -121.768337
          ],
          "Rest_Name": "Watsonville",
          "id": 3338,
          "address": "1075 Main St",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.350606,
              -121.922255
          ],
          "Rest_Name": "Coleman Landings",
          "id": 3339,
          "address": "540 Newhall Dr Ste 10",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.504362,
              -121.975593
          ],
          "Rest_Name": "Pacific Commons",
          "id": 3340,
          "address": "5565 Auto Mall Pkwy",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.394826,
              -121.946348
          ],
          "Rest_Name": "Rivermark",
          "id": 3341,
          "address": "3952 Rivermark Plz",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.41622,
              -121.954959
          ],
          "Rest_Name": "At First",
          "id": 3342,
          "address": "4180 N 1st St Unit 60",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.893216,
              -122.118088
          ],
          "Rest_Name": "Lafayette SF",
          "id": 3343,
          "address": "3518 Mt Diablo Blvd Ste F",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.32621,
              -121.94394
          ],
          "Rest_Name": "Valley Fair Mall",
          "id": 3344,
          "address": "2855 Stevens Creek Blvd Suite 2471",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.290927,
              -121.932023
          ],
          "Rest_Name": "Campbell",
          "id": 3345,
          "address": "1815 S Bascom Ave",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.13697,
              -122.215023
          ],
          "Rest_Name": "Vallejo",
          "id": 3346,
          "address": "1195 Admiral Callaghan Ln",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.352049,
              -121.960205
          ],
          "Rest_Name": "Santa Clara",
          "id": 3347,
          "address": "2002 El Camino Real",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.321714,
              -121.950513
          ],
          "Rest_Name": "Winchester Blvd",
          "id": 3348,
          "address": "369 S Winchester Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.262214,
              -121.931864
          ],
          "Rest_Name": "Camden Park",
          "id": 3349,
          "address": "2007 Camden Ave Ste 50",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.695358,
              -122.076805
          ],
          "Rest_Name": "Castro Valley",
          "id": 3350,
          "address": "3369 Castro Valley Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.379291,
              -121.97449
          ],
          "Rest_Name": "Santa Clara Square",
          "id": 3351,
          "address": "3249 Coronado Pl",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.285897,
              -122.274356
          ],
          "Rest_Name": "South Napa",
          "id": 3352,
          "address": "304 Soscol Ave Ste F",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.67885,
              -122.08481
          ],
          "Rest_Name": "Hayward",
          "id": 3353,
          "address": "22263 FOOTHILL BOULEVARD",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              43.232666,
              -123.372444
          ],
          "Rest_Name": "Garden Valley Blvd. & Stewart Parkway",
          "id": 3354,
          "address": "2060 NW Stewart Pkwy",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.551144,
              -122.051486
          ],
          "Rest_Name": "Newark",
          "id": 3355,
          "address": "34883 Newark Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.602471,
              -122.069124
          ],
          "Rest_Name": "Union City",
          "id": 3356,
          "address": "30920 Dyer St",
          "rating": 3.5,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.321226,
              -122.306089
          ],
          "Rest_Name": "Napa",
          "id": 3357,
          "address": "1715 Trancas St Ste D",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.235261,
              -121.963763
          ],
          "Rest_Name": "Los Gatos",
          "id": 3358,
          "address": "640 Blossom Hill Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.292933,
              -121.991171
          ],
          "Rest_Name": "Westgate Mall",
          "id": 3359,
          "address": "1645 Saratoga Ave Ste 10",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.723357,
              -122.15397
          ],
          "Rest_Name": "San Leandro",
          "id": 3360,
          "address": "1505 E 14th St",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.367819,
              -122.036189
          ],
          "Rest_Name": "Cherry Orchard",
          "id": 3361,
          "address": "324 W El Camino Real # B-3",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.337322,
              -122.036457
          ],
          "Rest_Name": "Cupertino Homestead Square",
          "id": 3362,
          "address": "20688 Homestead Rd",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.317286,
              -122.032759
          ],
          "Rest_Name": "Cupertino",
          "id": 3363,
          "address": "10385 S De Anza Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.665904,
              -121.809654
          ],
          "Rest_Name": "Marina",
          "id": 3364,
          "address": "140 General Stillwell Dr Ste 106",
          "rating": 2.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.737989,
              -122.19751
          ],
          "Rest_Name": "Oakland Hegenberger",
          "id": 3365,
          "address": "8460 Edgewater Dr Ste C",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.421855,
              -122.096558
          ],
          "Rest_Name": "Mountain View",
          "id": 3366,
          "address": "2400 Charleston Rd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.995827,
              -122.286073
          ],
          "Rest_Name": "Pinole",
          "id": 3367,
          "address": "2696 Pinole Valley Rd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.390677,
              -122.094413
          ],
          "Rest_Name": "El Monte Center",
          "id": 3368,
          "address": "1039 El Monte Ave Ste A",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.868367,
              -122.258959
          ],
          "Rest_Name": "Telegraph Ave.",
          "id": 3369,
          "address": "2311 Telegraph Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.810767,
              -122.244734
          ],
          "Rest_Name": "Oakland Lakeshore",
          "id": 3370,
          "address": "3271 Lakeshore Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.977912,
              -121.96625
          ],
          "Rest_Name": "Capitola",
          "id": 3371,
          "address": "1955 41st Ave Ste A5",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.868863,
              -122.26751
          ],
          "Rest_Name": "Berkeley Shattuck",
          "id": 3372,
          "address": "2225 Shattuck Ave",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.622639,
              -121.843659
          ],
          "Rest_Name": "Sand City II",
          "id": 3373,
          "address": "2140 California Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.819104,
              -122.262346
          ],
          "Rest_Name": "Oakland Broadway & 30th",
          "id": 3374,
          "address": "3017 Broadway",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.756135,
              -122.250143
          ],
          "Rest_Name": "Alameda Island",
          "id": 3375,
          "address": "2314 S Shore Ctr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.423923,
              -122.14322
          ],
          "Rest_Name": "Palo Alto",
          "id": 3376,
          "address": "2675 El Camino Real",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.803665,
              -122.271377
          ],
          "Rest_Name": "1300 Broadway - Oakland",
          "id": 3377,
          "address": "1302 Broadway ",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.89921,
              -122.302302
          ],
          "Rest_Name": "El Cerrito",
          "id": 3378,
          "address": "9901 San Pablo Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.879525,
              -122.296172
          ],
          "Rest_Name": "Gilman",
          "id": 3379,
          "address": "1050 Gilman St",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.786139,
              -122.281214
          ],
          "Rest_Name": "Alameda Landing",
          "id": 3380,
          "address": "2610 5th St Ste A",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.444352,
              -122.170843
          ],
          "Rest_Name": "Stanford Center",
          "id": 3381,
          "address": "180 El Camino Real",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.982583,
              -122.029336
          ],
          "Rest_Name": "Santa Cruz III",
          "id": 3382,
          "address": "550 River St Ste A",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.596934,
              -121.894483
          ],
          "Rest_Name": "Monterey",
          "id": 3383,
          "address": "560 Munras Ave Ste 20",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              36.583417,
              -121.896834
          ],
          "Rest_Name": "Del Monte",
          "id": 3384,
          "address": "500 Del Monte Ctr Ste 30",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.485816,
              -122.228957
          ],
          "Rest_Name": "Redwood City",
          "id": 3385,
          "address": "861 Middlefield Rd",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.521541,
              -122.25271
          ],
          "Rest_Name": "Redwood Shores",
          "id": 3386,
          "address": "224 Redwood Shores Pkwy",
          "rating": 4.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.502188,
              -122.246332
          ],
          "Rest_Name": "San Carlos",
          "id": 3387,
          "address": "1135 Industrial Rd Ste C",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.562558,
              -122.280633
          ],
          "Rest_Name": "Bridgepointe",
          "id": 3388,
          "address": "2019 Chess Dr",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.544689,
              -122.291481
          ],
          "Rest_Name": "Bay Meadows",
          "id": 3389,
          "address": "1050 Park Pl",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.794388,
              -122.397535
          ],
          "Rest_Name": "50 California",
          "id": 3390,
          "address": "50 California St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.788882,
              -122.396157
          ],
          "Rest_Name": "SF 400 Howard Street",
          "id": 3391,
          "address": "400 Howard St Ste 110",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.7904,
              -122.399472
          ],
          "Rest_Name": "525 Market Street",
          "id": 3392,
          "address": "525 Market Street",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.78968,
              -122.404021
          ],
          "Rest_Name": "Sutter and Kearney",
          "id": 3393,
          "address": "211 Sutter St",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.784232,
              -122.403689
          ],
          "Rest_Name": "Metreon",
          "id": 3394,
          "address": "121 4th St # 135",
          "rating": null,
          "type": "Chipotle"
      },
      {
          "geocode": [
              46.16214,
              -123.89793
          ],
          "Rest_Name": "Warrenton OR II",
          "id": 3395,
          "address": "103A US-101",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.78183,
              -122.445633
          ],
          "Rest_Name": "SF Geary & Masonic",
          "id": 3396,
          "address": "2675 Geary Boulevard",
          "rating": 3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.59665,
              -122.38617
          ],
          "Rest_Name": "Millbrae",
          "id": 3397,
          "address": "135 S El Camino Real",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.96967,
              -122.516735
          ],
          "Rest_Name": "San Rafael Montecito Plaza",
          "id": 3398,
          "address": "345 3rd St",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.091581,
              -122.556136
          ],
          "Rest_Name": "Novato RELO",
          "id": 3399,
          "address": "128 Vintage Way Ste W-4",
          "rating": 3.2,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.637204,
              -122.417247
          ],
          "Rest_Name": "Tanforan Food Court",
          "id": 3400,
          "address": "1150 El Camino Real Ste 197",
          "rating": 3.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.004794,
              -122.544694
          ],
          "Rest_Name": "San Rafael",
          "id": 3401,
          "address": "5800 Northgate Dr Ste 154",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.243616,
              -122.62638
          ],
          "Rest_Name": "Petaluma",
          "id": 3402,
          "address": "401 Kenilworth Dr Ste 710",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.727635,
              -122.476593
          ],
          "Rest_Name": "Stonestown Galleria",
          "id": 3403,
          "address": "3251 20th Ave",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.671137,
              -122.464453
          ],
          "Rest_Name": "Colma",
          "id": 3404,
          "address": "990 Serramonte Blvd Ste D",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.732506,
              -122.489986
          ],
          "Rest_Name": "Lakeshore Plaza",
          "id": 3405,
          "address": "1523 Sloat Blvd",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              37.701474,
              -122.482415
          ],
          "Rest_Name": "Daly City",
          "id": 3406,
          "address": "213 Westlake Ctr",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.417932,
              -122.712458
          ],
          "Rest_Name": "Santa Rosa Marketplace",
          "id": 3407,
          "address": "2182 Santa Rosa Ave",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.458375,
              -122.730127
          ],
          "Rest_Name": "Santa Rosa Coddingtown",
          "id": 3408,
          "address": "336A Coddingtown Ctr",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
          "geocode": [
              38.34733,
              -122.710127
          ],
          "Rest_Name": "Rohnert Park",
          "id": 3409,
          "address": "6325 Commerce Blvd",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              39.151643,
              -123.199436
          ],
          "Rest_Name": "Ukiah",
          "id": 3410,
          "address": "596 E Perkins St",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              40.785067,
              -124.182365
          ],
          "Rest_Name": "Eureka",
          "id": 3411,
          "address": "2626 Broadway Ste 100",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.655866,
              -0.397444
          ],
          "Rest_Name": "Watford ",
          "id": 3412,
          "address": "60 High Street",
          "rating": 4.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.23524,
              -0.57533
          ],
          "Rest_Name": "Guildford",
          "id": 3413,
          "address": "White Lion Walk",
          "rating": 4.4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.4471,
              -0.32868
          ],
          "Rest_Name": "Twickenham",
          "id": 3414,
          "address": "15 London Road",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.492277,
              -0.259431
          ],
          "Rest_Name": "Chiswick ",
          "id": 3415,
          "address": "227 229 Chiswick High Road",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.5088,
              -0.2245
          ],
          "Rest_Name": "LAB - 45026",
          "id": 3416,
          "address": "Unit 1109 Ariel Way",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.50752,
              -0.22218
          ],
          "Rest_Name": "White City Westfield",
          "id": 3417,
          "address": "Unit SU2067",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.5503,
              -0.191076
          ],
          "Rest_Name": "West End Lane ",
          "id": 3418,
          "address": "208 West End Lane",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.46362,
              -0.21584
          ],
          "Rest_Name": "Putney High Street",
          "id": 3419,
          "address": "88-90 Putney High St",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.520735,
              -0.157206
          ],
          "Rest_Name": "Baker Street UK",
          "id": 3420,
          "address": "101-103 Baker Street",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.514703,
              -0.129489
          ],
          "Rest_Name": "Charing Cross",
          "id": 3421,
          "address": "114-116 Charing Cross Road",
          "rating": 3.8,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.459292,
              -0.166621
          ],
          "Rest_Name": "Northcote ",
          "id": 3422,
          "address": "44 Northcote Road",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.510797,
              -0.127131
          ],
          "Rest_Name": "St. Martins Lane London",
          "id": 3423,
          "address": "92-93 St. Martins Lane",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.535448,
              -0.103621
          ],
          "Rest_Name": "Upper Street Islington",
          "id": 3424,
          "address": "334 Upper Street",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.517072,
              -0.087992
          ],
          "Rest_Name": "London Wall",
          "id": 3425,
          "address": "49 London Wall",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.511191,
              -0.087069
          ],
          "Rest_Name": "King William St",
          "id": 3426,
          "address": "70/72 King William Street",
          "rating": 3.7,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.4976,
              -0.0723
          ],
          "Rest_Name": "Bermondsey DK",
          "id": 3427,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.45823,
              -0.07327
          ],
          "Rest_Name": "Lordship Lane",
          "id": 3428,
          "address": "49-51 Lordship Lane",
          "rating": 3.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.50293,
              -0.01857
          ],
          "Rest_Name": "Canary Wharf Jubilee",
          "id": 3429,
          "address": "Unit 20 Jubilee Place",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              51.50386,
              0.00422
          ],
          "Rest_Name": "North Greenwich",
          "id": 3430,
          "address": "Unit 12.0.6 ",
          "rating": 3.3,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.890766,
              2.23938
          ],
          "Rest_Name": "La Defense Quatre Temps",
          "id": 3431,
          "address": "15 Parvis de la Defense",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.892566,
              2.297698
          ],
          "Rest_Name": "So Ouest",
          "id": 3432,
          "address": "31 Rue d'Alsace",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.848499,
              2.281333
          ],
          "Rest_Name": "Beaugrenelle",
          "id": 3433,
          "address": "2 Rue Linois",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.872007,
              2.340092
          ],
          "Rest_Name": "Boulevard Montmartre",
          "id": 3434,
          "address": "18-20 Boulevard Montmartre",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              48.851384,
              2.343986
          ],
          "Rest_Name": "Saint-Germain",
          "id": 3435,
          "address": "96 Boulevard Saint-Germain",
          "rating": 3.9,
          "type": "Chipotle"
      },
      {
          "geocode": [
              45.760139,
              4.856053
          ],
          "Rest_Name": "Lyon La Part Dieu ",
          "id": 3436,
          "address": "17 Rue Dr Bouchut",
          "rating": 4.1,
          "type": "Chipotle"
      },
      {
          "geocode": [
              50.109025,
              8.652219
          ],
          "Rest_Name": "Frankfurt Skyline Plaza",
          "id": 3437,
          "address": "Shopping Center Skyline Plaza Europa Allee 6",
          "rating": 4,
          "type": "Chipotle"
      },
      {
          "geocode": [
              50.1146,
              8.6815
          ],
          "Rest_Name": "MYZEIL FRANKFURT",
          "id": 3438,
          "address": "Shopping Center MyZeil Foodtopia Zeil 106",
          "rating": 3.6,
          "type": "Chipotle"
      },
      {
        "geocode": [
            36.28343,
            -115.13424
        ],
        "Rest_Name": "North Fifth Street",
        "address": "6780 N. 5TH STREET",
        "type": "Chipotle",
        "id": 3439,
        "rating": 3.6,
        "place_id": "ChIJRxWU5QrIxokRThAHqh9FioE",
        "user_ratings_total": 666
    },
    {
        "geocode": [
            35.999003,
            -115.198303
        ],
        "Rest_Name": "Cactus & Schirlls",
        "address": "4332 W Cactus Ave",
        "type": "Chipotle",
        "id": 3440,
        "rating": 3.5,
        "place_id": "ChIJy2D12rLTD4gRvbivEQZMt04",
        "user_ratings_total": 171
    },
    {
        "geocode": [
            37.140878,
            -113.493466
        ],
        "Rest_Name": "Washington City",
        "address": "1560 Grapevine Xing",
        "type": "Chipotle",
        "id": 3441,
        "rating": 3.7,
        "place_id": "ChIJI9H2b2JbyoARVs_TCPwZtRU",
        "user_ratings_total": 54
    },
    {
        "geocode": [
            33.923606,
            -116.805283
        ],
        "Rest_Name": "Cabazon",
        "address": "48557 Morongo Trl",
        "type": "Chipotle",
        "id": 3442,
        "rating": 4.1,
        "place_id": "ChIJD0Ji8a9B24ARf-RbZyTzoIA",
        "user_ratings_total": 497
    },
    {
        "geocode": [
            33.686334,
            -116.268568
        ],
        "Rest_Name": "Jefferson & Avenue 50",
        "address": "49810 Jefferson St",
        "type": "Chipotle",
        "id": 3443,
        "rating": 3.8,
        "place_id": "ChIJmXTQe_fSD4gRml2ylOC67-Y",
        "user_ratings_total": 233
    },
    {
        "geocode": [
            34.027409,
            -117.11866
        ],
        "Rest_Name": "Yucaipa",
        "address": "31495 Yucaipa Blvd Unit 1",
        "type": "Chipotle",
        "id": 3444,
        "rating": 3.1,
        "place_id": "ChIJqd_W7jJX24ARNQmJdqePTvo",
        "user_ratings_total": 174
    },
    {
        "geocode": [
            33.959079,
            -117.618489
        ],
        "Rest_Name": "Chino Preserve",
        "address": "8417 Pine Ave",
        "type": "Chipotle",
        "id": 3445,
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
    },
    {
        "geocode": [
            33.598149,
            -117.172588
        ],
        "Rest_Name": "Clinton Keith",
        "address": "27970 Clinton Keith Rd Ste 100",
        "type": "Chipotle",
        "id": 3446,
        "rating": 3,
        "place_id": "ChIJP19vfOR924ARRzfTSRE3if8",
        "user_ratings_total": 1
    },
    {
        "geocode": [
            33.741786,
            -112.323198
        ],
        "Rest_Name": "Vistancia",
        "address": "28576 N El Mirage Rd",
        "type": "Chipotle",
        "id": 3447,
        "rating": 2.6,
        "place_id": "ChIJhXPThIFfK4cRgMO3keV2mmQ",
        "user_ratings_total": 44
    },
    {
        "geocode": [
            33.658074,
            -117.970629
        ],
        "Rest_Name": "Magnolia and Atlanta",
        "address": "20962 Magnolia St",
        "type": "Chipotle",
        "id": 3448,
        "rating": 3.3,
        "place_id": "ChIJo1urGO4G9YgRfTe-O1aEnL4",
        "user_ratings_total": 432
    },
    {
        "geocode": [
            37.275171,
            -120.432291
        ],
        "Rest_Name": "Campus & Hwy 99",
        "address": "3120 Campus Parkway",
        "type": "Chipotle",
        "id": 3449,
        "rating": 3.6,
        "place_id": "ChIJv42QcwG2FIgRqPA--Cvj9fk",
        "user_ratings_total": 715
    },
    {
        "geocode": [
            34.924765,
            -120.425807
        ],
        "Rest_Name": "Santa Maria Relo",
        "address": "467 E Betteravia Rd",
        "type": "Chipotle",
        "id": 3450,
        "rating": 3,
        "place_id": "ChIJi8WWtMFt7IARn7PCC71SELc",
        "user_ratings_total": 170
    },
    {
        "geocode": [
            37.580028,
            -120.941808
        ],
        "Rest_Name": "Ceres",
        "address": "2870 E Service Rd",
        "type": "Chipotle",
        "id": 3451,
        "rating": 3.4,
        "place_id": "ChIJ8WF8mNirkYARQSPLPXnSYdQ",
        "user_ratings_total": 58
    },
    {
        "geocode": [
            42.570581,
            -114.43857
        ],
        "Rest_Name": "Twin Falls - ID",
        "address": "118 Cheney Dr W",
        "type": "Chipotle",
        "id": 3452,
        "rating": 4,
        "place_id": "ChIJm4kolDWirFQRwW6OOBaRWxw",
        "user_ratings_total": 117
    },
    {
        "geocode": [
            43.662327,
            -116.410149
        ],
        "Rest_Name": "Linder Village Meridian",
        "address": "1323 W Chinden Blvd",
        "type": "Chipotle",
        "id": 3453,
        "rating": 3.1,
        "place_id": "ChIJYROaT2JVrlQRa_pth7oyNBA",
        "user_ratings_total": 127
    },
    {
        "geocode": [
            32.314987,
            -106.738051
        ],
        "Rest_Name": "Las Cruces - Telshor",
        "address": "525 S Telshor Blvd Ste A",
        "type": "Chipotle",
        "id": 3454,
        "rating": 4,
        "place_id": "ChIJAQDQVlw93oYRlnFUqMo2dA0",
        "user_ratings_total": 861
    },
    {
        "geocode": [
            38.942349,
            -104.611922
        ],
        "Rest_Name": "Falcon",
        "address": "7565 Falcon Market Pl",
        "type": "Chipotle",
        "id": 3455,
        "rating": 3.1,
        "place_id": "ChIJ88RsPKY3E4cRM7Fouyi9Pf8",
        "user_ratings_total": 50
    },
    {
        "geocode": [
            45.409149,
            -122.627394
        ],
        "Rest_Name": "Concord & Hwy 99E",
        "address": "15717 SE McLoughlin Blvd",
        "type": "Chipotle",
        "id": 3456,
        "rating": 3.5,
        "place_id": "ChIJL8aLMGN1lVQReUSCVR4M06k",
        "user_ratings_total": 35
    },
    {
        "geocode": [
            45.809223,
            -108.473413
        ],
        "Rest_Name": "The Heights",
        "address": "548 Main St",
        "type": "Chipotle",
        "id": 3457,
        "rating": 4.2,
        "place_id": "ChIJBxHSf68cE4gRx9qXXhWODg4",
        "user_ratings_total": 1250
    },
    {
        "geocode": [
            46.585105,
            -120.542479
        ],
        "Rest_Name": "Nob Hill - Yakima",
        "address": "2416 W Nob Hill Blvd",
        "type": "Chipotle",
        "id": 3458,
        "rating": 3.5,
        "place_id": "ChIJFcKKIP99l1QR-M8WAtPmnIU",
        "user_ratings_total": 107
    },
    {
        "geocode": [
            32.47764,
            -99.69767
        ],
        "Rest_Name": "I-20 and Ambler",
        "address": "1631 Hwy 351",
        "type": "Chipotle",
        "id": 3459,
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
    },
    {
        "geocode": [
            49.249111,
            -122.892759
        ],
        "Rest_Name": "Lougheed",
        "address": "3778 Grand Promenade",
        "type": "Chipotle",
        "id": 3460,
        "rating": 2.9,
        "place_id": "ChIJZVmeP1R5hlQRUQXKBx1E8hE",
        "user_ratings_total": 157
    },
    {
        "geocode": [
            32.757393,
            -97.713875
        ],
        "Rest_Name": "Hudson Oaks TX",
        "address": "2791 Fort Worth Hwy",
        "type": "Chipotle",
        "id": 3461,
        "rating": 2.5,
        "place_id": "ChIJ-_CLTT4BToYReP2vQ3eNo-Y",
        "user_ratings_total": 84
    },
    {
        "geocode": [
            33.149947,
            -96.767806
        ],
        "Rest_Name": "Coit & Main",
        "address": "8847 Coit Rd",
        "type": "Chipotle",
        "id": 3462,
        "rating": 2.4,
        "place_id": "ChIJayxgZVc9TIYRSaw9WifHEvI",
        "user_ratings_total": 217
    },
    {
        "geocode": [
            32.92136,
            -96.838671
        ],
        "Rest_Name": "Farmer Branch",
        "address": "12747 Midway Rd",
        "type": "Chipotle",
        "id": 3463,
        "rating": 3.7,
        "place_id": "ChIJZ0zNmzMnTIYRrZ2e6V8f5Zk",
        "user_ratings_total": 827
    },
    {
        "geocode": [
            32.827614,
            -96.845272
        ],
        "Rest_Name": "West Love",
        "address": "2427 W Mockingbird Ln Ste 150",
        "type": "Chipotle",
        "id": 3464,
        "rating": 3.9,
        "place_id": "ChIJq-A68aydToYRoj-iqM1hmLw",
        "user_ratings_total": 759
    },
    {
        "geocode": [
            41.450293,
            -96.452051
        ],
        "Rest_Name": "Fremont - 23rd St",
        "address": "3550 E Elk Ln",
        "type": "Chipotle",
        "id": 3465,
        "rating": null,
        "place_id": "ChIJ8WDKKfhgkYcRuQ9_OPW4ctg",
        "user_ratings_total": null
    },
    {
        "geocode": [
            29.548813,
            -98.309425
        ],
        "Rest_Name": "Kitty Hawk",
        "address": "540 Kitty Hawk Rd",
        "type": "Chipotle",
        "id": 3466,
        "rating": 3,
        "place_id": "ChIJtYL3hBeNXIYRWQIR3EW6u-Q",
        "user_ratings_total": 86
    },
    {
        "geocode": [
            41.291158,
            -96.204336
        ],
        "Rest_Name": "Antler View",
        "address": "18404 Evans St",
        "type": "Chipotle",
        "id": 3467,
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
    },
    {
        "geocode": [
            35.99617,
            -95.81478
        ],
        "Rest_Name": "Broken Arrow South",
        "address": "5405 S ASPEN AVE",
        "type": "Chipotle",
        "id": 3468,
        "rating": 2.7,
        "place_id": "ChIJXdIiFROPtocRH2jYDrXFfW0",
        "user_ratings_total": 12
    },
    {
        "geocode": [
            38.901985,
            -94.525386
        ],
        "Rest_Name": "Truman Marketplace",
        "address": "12348 S US Highway 71",
        "type": "Chipotle",
        "id": 3469,
        "rating": 3.9,
        "place_id": "ChIJTXCdeXPdwIcRAM6MDUxZ1QQ",
        "user_ratings_total": 1499
    },
    {
        "geocode": [
            46.87713,
            -96.72818
        ],
        "Rest_Name": "Moorhead",
        "address": "3262 US-10 E",
        "type": "Chipotle",
        "id": 3470,
        "rating": 2.9,
        "place_id": "ChIJw3S7gSzZyVIR4pCM0hTnS0I",
        "user_ratings_total": 16
    },
    {
        "geocode": [
            29.699547,
            -95.687523
        ],
        "Rest_Name": "Mission Bend",
        "address": "7111 FM 1464 Rd",
        "type": "Chipotle",
        "id": 3471,
        "rating": 2.9,
        "place_id": "ChIJs4QH7X_fQIYRdU-I5YwvoJ8",
        "user_ratings_total": 19
    },
    {
        "geocode": [
            29.986227,
            -95.500244
        ],
        "Rest_Name": "North Oaks TX",
        "address": "4561 FM 1960 Rd W Ste A",
        "type": "Chipotle",
        "id": 3472,
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
    },
    {
        "geocode": [
            29.73499,
            -95.62429
        ],
        "Rest_Name": "Eldridge",
        "address": "2522 Eldridge Parkway",
        "type": "Chipotle",
        "id": 3473,
        "rating": 3.4,
        "place_id": "ChIJI7B5M9ndQIYRTjkyugGZ95g",
        "user_ratings_total": 22
    },
    {
        "geocode": [
            29.619025,
            -95.56598
        ],
        "Rest_Name": "Stafford TX",
        "address": "3549 S Main St",
        "type": "Chipotle",
        "id": 3474,
        "rating": 4,
        "place_id": "ChIJ8yyCH9PnQIYRs2QHXDoo5Hg",
        "user_ratings_total": 26
    },
    {
        "geocode": [
            44.177839,
            -94.034032
        ],
        "Rest_Name": "North Mankato",
        "address": "1725 Lor Ray Dr",
        "type": "Chipotle",
        "id": 3475,
        "rating": 3.2,
        "place_id": "ChIJ1XJ0Gwk39IcRkO7QSsbgTXA",
        "user_ratings_total": 65
    },
    {
        "geocode": [
            44.721978,
            -93.179276
        ],
        "Rest_Name": "Orchard Place",
        "address": "15600 English Ave",
        "type": "Chipotle",
        "id": 3476,
        "rating": 3.7,
        "place_id": "ChIJHxlrrXzPD4gRHqoHvjq0Dnc",
        "user_ratings_total": 736
    },
    {
        "geocode": [
            38.672957,
            -90.360404
        ],
        "Rest_Name": "U City",
        "address": "8650 Olive Blvd",
        "type": "Chipotle",
        "id": 3477,
        "rating": 3.3,
        "place_id": "ChIJHaMYRrA134cRkUT34D_24XQ",
        "user_ratings_total": 58
    },
    {
        "geocode": [
            35.271997,
            -89.666053
        ],
        "Rest_Name": "Arlington TN",
        "address": "5350 Airline Rd",
        "type": "Chipotle",
        "id": 3478,
        "rating": 5,
        "place_id": "ChIJCZXPI9sLf4gRXrs6OQIUPrA",
        "user_ratings_total": 2
    },
    {
        "geocode": [
            41.454609,
            -88.270565
        ],
        "Rest_Name": "Minooka",
        "address": "462 W Mondamin St",
        "type": "Chipotle",
        "id": 3479,
        "rating": 2.8,
        "place_id": "ChIJ5dD4kNOPDogR9ByyXet2ASw",
        "user_ratings_total": 5
    },
    {
        "geocode": [
            42.051427,
            -87.998346
        ],
        "Rest_Name": "Rolling Meadows R",
        "address": "1458 Golf Rd",
        "type": "Chipotle",
        "id": 3480,
        "rating": 3.2,
        "place_id": "ChIJiYPi4MGxD4gRPF22dlKPYG0",
        "user_ratings_total": 32
    },
    {
        "geocode": [
            29.926037,
            -90.083069
        ],
        "Rest_Name": "Magazine - Garden District",
        "address": "2801 Magazine St Ste 2",
        "type": "Chipotle",
        "id": 3481,
        "rating": 3.4,
        "place_id": "ChIJqWRGvSOlIIYRmbnVxh9JA8o",
        "user_ratings_total": 421
    },
    {
        "geocode": [
            42.565678,
            -87.92112
        ],
        "Rest_Name": "Kenosha",
        "address": "9370 76th St Ste B",
        "type": "Chipotle",
        "id": 3482,
        "rating": 3.8,
        "place_id": "ChIJPVrDboRhBYgRzzZJx6MT2iQ",
        "user_ratings_total": 1116
    },
    {
        "geocode": [
            41.87299,
            -87.67748
        ],
        "Rest_Name": "IMD Damen Ogden",
        "address": "725 S Seeley St",
        "type": "Chipotle",
        "id": 3483,
        "rating": 3.7,
        "place_id": "ChIJX83M3LMtDogRzd7om8ELbqo",
        "user_ratings_total": 595
    },
    {
        "geocode": [
            34.836763,
            -87.631528
        ],
        "Rest_Name": "Florence AL",
        "address": "311 Cox Creek Pkwy",
        "type": "Chipotle",
        "id": 3484,
        "rating": 2.9,
        "place_id": "ChIJg5fWno9JfYgRvJEmkRWXYQg",
        "user_ratings_total": 42
    },
    {
        "geocode": [
            40.452126,
            -86.915367
        ],
        "Rest_Name": "West Lafayette",
        "address": "2400 N Salisbury St",
        "type": "Chipotle",
        "id": 3485,
        "rating": 3.6,
        "place_id": "ChIJ0debCpf9EogRgF0Ie9otyuk",
        "user_ratings_total": 314
    },
    {
        "geocode": [
            40.368625,
            -86.85869
        ],
        "Rest_Name": "Lafayette Concord",
        "address": "3235 Builder Dr",
        "type": "Chipotle",
        "id": 3486,
        "rating": 3.3,
        "place_id": "ChIJm0x6RCgdE4gRQ84nXkreGlQ",
        "user_ratings_total": 32
    },
    {
        "geocode": [
            36.035003,
            -86.651283
        ],
        "Rest_Name": "Century Farms",
        "address": "4216 Century Farms Ter Ste 101",
        "type": "Chipotle",
        "id": 3487,
        "rating": 3.6,
        "place_id": "ChIJYbPhbQW1D4gRzQjciEWrB-E",
        "user_ratings_total": 590
    },
    {
        "geocode": [
            38.303747,
            -85.83976
        ],
        "Rest_Name": "New Albany - IN",
        "address": "2133 State St",
        "type": "Chipotle",
        "id": 3488,
        "rating": 2.8,
        "place_id": "ChIJ6ypOGZttaYgRG1D2-uj-w1g",
        "user_ratings_total": 191
    },
    {
        "geocode": [
            38.3238,
            -85.744528
        ],
        "Rest_Name": "Jeffersonville Town Center",
        "address": "1620 Veterans Pkwy",
        "type": "Chipotle",
        "id": 3489,
        "rating": 3,
        "place_id": "ChIJJ3LJw11xaYgRjLKR2WxmcHQ",
        "user_ratings_total": 29
    },
    {
        "geocode": [
            41.367182,
            -85.076641
        ],
        "Rest_Name": "Auburn IN",
        "address": "1150 W 7th St",
        "type": "Chipotle",
        "id": 3490,
        "rating": 3.3,
        "place_id": "ChIJC28mJcobFogRx09NResVRiY",
        "user_ratings_total": 36
    },
    {
        "geocode": [
            30.732689,
            -86.567567
        ],
        "Rest_Name": "Crestview Commons",
        "address": "2680 S Ferdon Blvd",
        "type": "Chipotle",
        "id": 3491,
        "rating": 2.5,
        "place_id": "ChIJi11lWcBzkYgRPyH_1WOnESo",
        "user_ratings_total": 44
    },
    {
        "geocode": [
            40.729731,
            -84.070235
        ],
        "Rest_Name": "Harding Highway",
        "address": "1925 Bellefontaine Rd",
        "type": "Chipotle",
        "id": 3492,
        "rating": null,
        "place_id": "ChIJz07X59ntPogRNsxjz8L8mo0",
        "user_ratings_total": null
    },
    {
        "geocode": [
            33.836857,
            -84.575853
        ],
        "Rest_Name": "Mableton",
        "address": "4835 Floyd Rd SW",
        "type": "Chipotle",
        "id": 3493,
        "rating": 5,
        "place_id": "ChIJrxs9udcZ9YgRVyQ05hTn6Tg",
        "user_ratings_total": 3
    },
    {
        "geocode": [
            33.699639,
            -84.578525
        ],
        "Rest_Name": "Sandtown",
        "address": "5823 Campbellton Rd SW",
        "type": "Chipotle",
        "id": 3494,
        "rating": 2.6,
        "place_id": "ChIJMZrKzQ4f9YgRNsyMVhV3gvo",
        "user_ratings_total": 46
    },
    {
        "geocode": [
            33.895346,
            -84.142392
        ],
        "Rest_Name": "Lilburn",
        "address": "4805 Lawrenceville Hwy NW Ste 500",
        "type": "Chipotle",
        "id": 3495,
        "rating": 2.5,
        "place_id": "ChIJg-zTxMul9YgRdscQb7rRH-E",
        "user_ratings_total": 38
    },
    {
        "geocode": [
            39.33341,
            -83.014683
        ],
        "Rest_Name": "Chillicothe Western Ave",
        "address": "6 Saint Andrews Blvd",
        "type": "Chipotle",
        "id": 3496,
        "rating": 3.6,
        "place_id": "ChIJ4wfQWx0PR4gRtlRO6EmcRRg",
        "user_ratings_total": 10
    },
    {
        "geocode": [
            39.9522,
            -82.683382
        ],
        "Rest_Name": "Etna Township",
        "address": "98 Etna Crest Blvd",
        "type": "Chipotle",
        "id": 3497,
        "rating": 3.4,
        "place_id": "ChIJ8QHSMjBvOIgROKTVmNAxHhY",
        "user_ratings_total": 303
    },
    {
        "geocode": [
            41.371521,
            -81.868767
        ],
        "Rest_Name": "Berea",
        "address": "399 W Bagley Rd",
        "type": "Chipotle",
        "id": 3498,
        "rating": 2.9,
        "place_id": "ChIJ74wCOcGVMIgRI_pznjY4BnQ",
        "user_ratings_total": 41
    },
    {
        "geocode": [
            41.4586,
            -81.69149
        ],
        "Rest_Name": "Steelyard R",
        "address": "3530 Steelyard Dr",
        "type": "Chipotle",
        "id": 3499,
        "rating": 1.7,
        "place_id": "ChIJ8-WqQNP7MIgRnuiWpGMsArE",
        "user_ratings_total": 44
    },
    {
        "geocode": [
            35.093396,
            -81.664512
        ],
        "Rest_Name": "Gaffney SC",
        "address": "1602 W Floyd Baker Blvd",
        "type": "Chipotle",
        "id": 3500,
        "rating": 4,
        "place_id": "ChIJH-zr3QIFV4gRCYNQ3x14LU8",
        "user_ratings_total": 8
    },
    {
        "geocode": [
            40.405159,
            -80.549438
        ],
        "Rest_Name": "Weirton",
        "address": "261 Saint Thomas Dr",
        "type": "Chipotle",
        "id": 3501,
        "rating": 2,
        "place_id": "ChIJR00mfwAxNIgRrE6lV9iEnYs",
        "user_ratings_total": 107
    },
    {
        "geocode": [
            40.68048,
            -80.31031
        ],
        "Rest_Name": "Monaca",
        "address": "89 Wagner Road",
        "type": "Chipotle",
        "id": 3502,
        "rating": 2.1,
        "place_id": "ChIJtQeGinFvNIgRTnOIRHFfePA",
        "user_ratings_total": 82
    },
    {
        "geocode": [
            35.815496,
            -80.876032
        ],
        "Rest_Name": "statesville",
        "address": "210 Turnersburg Hwy # C",
        "type": "Chipotle",
        "id": 3503,
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
    },
    {
        "geocode": [
            31.205556,
            -82.335419
        ],
        "Rest_Name": "Waycross",
        "address": "1751 Memorial Dr",
        "type": "Chipotle",
        "id": 3504,
        "rating": 3.9,
        "place_id": "ChIJXeFTdYjx74gRd8QgR5is-cQ",
        "user_ratings_total": 16
    },
    {
        "geocode": [
            39.904935,
            -79.753075
        ],
        "Rest_Name": "Uniontown",
        "address": "65 Matthew Dr",
        "type": "Chipotle",
        "id": 3505,
        "rating": 2.5,
        "place_id": "ChIJQepZ_FYVNYgR6XJeu_4pXrQ",
        "user_ratings_total": 66
    },
    {
        "geocode": [
            36.04594,
            -80.259051
        ],
        "Rest_Name": "Peters Creek Pkwy",
        "address": "1051 Southpark Blvd",
        "type": "Chipotle",
        "id": 3506,
        "rating": 2.5,
        "place_id": "ChIJ0dpZ2BGvU4gRFJpRaPVmpD8",
        "user_ratings_total": 143
    },
    {
        "geocode": [
            37.230442,
            -79.975861
        ],
        "Rest_Name": "Tanglewood",
        "address": "4464 Electric Rd Ste A",
        "type": "Chipotle",
        "id": 3507,
        "rating": 2.7,
        "place_id": "ChIJbRSDugUNTYgRiQMynp-7-O0",
        "user_ratings_total": 120
    },
    {
        "geocode": [
            31.231881,
            -81.499838
        ],
        "Rest_Name": "Brunswick 2",
        "address": "100 Shupe Way",
        "type": "Chipotle",
        "id": 3508,
        "rating": 3.8,
        "place_id": "ChIJCchHyTDBMIgR82_z7E_co_4",
        "user_ratings_total": 580
    },
    {
        "geocode": [
            42.947148,
            -78.868615
        ],
        "Rest_Name": "Delaware Commons",
        "address": "2236 Delaware Ave",
        "type": "Chipotle",
        "id": 3509,
        "rating": 2.4,
        "place_id": "ChIJQZhu1ntt04kRiWGMD1VxrrQ",
        "user_ratings_total": 104
    },
    {
        "geocode": [
            28.30759,
            -81.66655
        ],
        "Rest_Name": "North Davenport",
        "address": "49539 HWY 27",
        "type": "Chipotle",
        "id": 3510,
        "rating": 3,
        "place_id": "ChIJoRJ4XDtz3YgRy7Xc6pn35tQ",
        "user_ratings_total": 132
    },
    {
        "geocode": [
            28.810686,
            -81.348467
        ],
        "Rest_Name": "West Sanford",
        "address": "277 High Water Ln",
        "type": "Chipotle",
        "id": 3511,
        "rating": 3.7,
        "place_id": "ChIJ8W3FixAN54gR6Pc3lsShLoE",
        "user_ratings_total": 3
    },
    {
        "geocode": [
            33.791048,
            -78.998688
        ],
        "Rest_Name": "Conway SC",
        "address": "2296 US-501 East",
        "type": "Chipotle",
        "id": 3512,
        "rating": 3.3,
        "place_id": "ChIJ6akVKG9tAIkRgSl2UNtnT10",
        "user_ratings_total": 171
    },
    {
        "geocode": [
            43.188798,
            -77.503958
        ],
        "Rest_Name": "Penfield Empire Blvd",
        "address": "1838 Empire Blvd",
        "type": "Chipotle",
        "id": 3513,
        "rating": 3.1,
        "place_id": "ChIJx5aqugDJ1okRvA3jjC16oSQ",
        "user_ratings_total": 139
    },
    {
        "geocode": [
            27.495454,
            -81.486207
        ],
        "Rest_Name": "Sebring",
        "address": "1310 US Highway 27 N",
        "type": "Chipotle",
        "id": 3514,
        "rating": 2.6,
        "place_id": "ChIJxawWVgBX3IgRWTo3LLPL_4I",
        "user_ratings_total": 90
    },
    {
        "geocode": [
            38.343099,
            -77.494231
        ],
        "Rest_Name": "Carter Corner",
        "address": "28 S Gateway Dr",
        "type": "Chipotle",
        "id": 3515,
        "rating": 3.6,
        "place_id": "ChIJKaaZSEfqtokRIUXrRNySR1A",
        "user_ratings_total": 487
    },
    {
        "geocode": [
            37.57344,
            -77.46459
        ],
        "Rest_Name": "Arthur Ashe Boulevard",
        "address": "3044 N ARTHUR ASHE BLVD",
        "type": "Chipotle",
        "id": 3516,
        "rating": 2.3,
        "place_id": "ChIJmZ5zqWUXsYkRDLOXwSv7PB8",
        "user_ratings_total": 3
    },
    {
        "geocode": [
            39.456718,
            -76.632712
        ],
        "Rest_Name": "Cockeysville",
        "address": "9701 York Rd",
        "type": "Chipotle",
        "id": 3517,
        "rating": 5,
        "place_id": "ChIJ69wtiPkNyIkRDoLZ3RdntzQ",
        "user_ratings_total": 1
    },
    {
        "geocode": [
            34.290718,
            -77.799477
        ],
        "Rest_Name": "Porters Neck",
        "address": "7964 Market St",
        "type": "Chipotle",
        "id": 3518,
        "rating": 5,
        "place_id": "ChIJVWBE0daNqYkRIkLynVPkm9A",
        "user_ratings_total": 1
    },
    {
        "geocode": [
            37.39425,
            -76.527059
        ],
        "Rest_Name": "Gloucester",
        "address": "7115 George Washington Memorial Hwy",
        "type": "Chipotle",
        "id": 3519,
        "rating": 3.3,
        "place_id": "ChIJe4kmteydsIkRj03JLb2YFSg",
        "user_ratings_total": 150
    },
    {
        "geocode": [
            39.606742,
            -75.951305
        ],
        "Rest_Name": "North East",
        "address": "2570 Pulaski Highway",
        "type": "Chipotle",
        "id": 3520,
        "rating": 2,
        "place_id": "ChIJZxwgGCC7x4kRJjFzyFUf0JM",
        "user_ratings_total": 42
    },
    {
        "geocode": [
            40.299012,
            -75.845668
        ],
        "Rest_Name": "Exeter PA",
        "address": "5411 Perkiomen Ave",
        "type": "Chipotle",
        "id": 3521,
        "rating": 2.5,
        "place_id": "ChIJIeadac97xokR3qgBGU4f-P4",
        "user_ratings_total": 63
    },
    {
        "geocode": [
            39.99404,
            -75.75972
        ],
        "Rest_Name": "Thorndale",
        "address": "3203 E Lincoln Hwy East",
        "type": "Chipotle",
        "id": 3522,
        "rating": 3,
        "place_id": "ChIJo1FycQBZxokR1kOUuv5C3P8",
        "user_ratings_total": 42
    },
    {
        "geocode": [
            25.979075,
            -80.314588
        ],
        "Rest_Name": "East Miramar",
        "address": "12581 Miramar Pkwy",
        "type": "Chipotle",
        "id": 3523,
        "rating": 2,
        "place_id": "ChIJYdvknsGl2YgRJ2pX8vPF0GI",
        "user_ratings_total": 47
    },
    {
        "geocode": [
            26.009558,
            -80.257388
        ],
        "Rest_Name": "East Pembroke Pines",
        "address": "8559 Pines Blvd",
        "type": "Chipotle",
        "id": 3524,
        "rating": 3.6,
        "place_id": "ChIJ8Y_OmZSp2YgRgZdYBdNDMJE",
        "user_ratings_total": 5
    },
    {
        "geocode": [
            39.802986,
            -75.037399
        ],
        "Rest_Name": "Clementon",
        "address": "1410 Blackwood Clementon Rd",
        "type": "Chipotle",
        "id": 3525,
        "rating": 3.2,
        "place_id": "ChIJR-N9JFnTxokRwRk2x6k9k-Y",
        "user_ratings_total": 188
    },
    {
        "geocode": [
            39.430375,
            -75.040797
        ],
        "Rest_Name": "Delsea Drive",
        "address": "3880 S Delsea Dr",
        "type": "Chipotle",
        "id": 3526,
        "rating": 2.5,
        "place_id": "ChIJQ90-cgAzx4kRujIU6Bo28t4",
        "user_ratings_total": 41
    },
    {
        "geocode": [
            41.064542,
            -74.74591
        ],
        "Rest_Name": "Newton",
        "address": "121 Water St",
        "type": "Chipotle",
        "id": 3527,
        "rating": 2.9,
        "place_id": "ChIJnwkYmIlvw4kR4_r28Ij-Rzk",
        "user_ratings_total": 197
    },
    {
        "geocode": [
            40.43815,
            -74.50431
        ],
        "Rest_Name": "Main Street North Brunswick",
        "address": "229 Grand Ave",
        "type": "Chipotle",
        "id": 3528,
        "rating": 2.2,
        "place_id": "ChIJ0Uk5fnjDw4kRA9j_c0o0s8s",
        "user_ratings_total": 19
    },
    {
        "geocode": [
            40.833659,
            -73.860448
        ],
        "Rest_Name": "Parkchester",
        "address": "31 Hugh J Grant Cir",
        "type": "Chipotle",
        "id": 3529,
        "rating": 2.1,
        "place_id": "ChIJDQckPAD1wokR0spwb8L2hNI",
        "user_ratings_total": 26
    },
    {
        "geocode": [
            41.042959,
            -73.797262
        ],
        "Rest_Name": "White Plains Rte 119",
        "address": "405 Tarrytown Rd",
        "type": "Chipotle",
        "id": 3530,
        "rating": 3.5,
        "place_id": "ChIJp5Wnaf6UwokRs4qHPyFx4-Y",
        "user_ratings_total": 573
    },
    {
        "geocode": [
            41.392428,
            -73.522496
        ],
        "Rest_Name": "Danbury Mill Plain Road",
        "address": "115 Mill Plain Rd",
        "type": "Chipotle",
        "id": 3531,
        "rating": 3.1,
        "place_id": "ChIJI5E5uPlU3YkRYt8-YJk64LQ",
        "user_ratings_total": 461
    },
    {
        "geocode": [
            41.110556,
            -73.546043
        ],
        "Rest_Name": "Stamford High Ridge",
        "address": "1207 High Ridge Rd",
        "type": "Chipotle",
        "id": 3532,
        "rating": 2.2,
        "place_id": "ChIJ3fFqN_qhwokRRjTaj1VPYlA",
        "user_ratings_total": 307
    },
    {
        "geocode": [
            41.238459,
            -73.035398
        ],
        "Rest_Name": "Connecticut Post Mall",
        "address": "1319 Boston Post Rd Spc 4B",
        "type": "Chipotle",
        "id": 3533,
        "rating": 3.2,
        "place_id": "ChIJkwZcx1Z06IkRtFgCmus22Ro",
        "user_ratings_total": 608
    },
    {
        "geocode": [
            41.271229,
            -72.972761
        ],
        "Rest_Name": "West Haven I95",
        "address": "354 Saw Mill Rd",
        "type": "Chipotle",
        "id": 3534,
        "rating": 3.4,
        "place_id": "ChIJEbdwHR926IkRRE-NueueX3k",
        "user_ratings_total": 564
    },
    {
        "geocode": [
            43.22982,
            -71.482074
        ],
        "Rest_Name": "Concord Loudon Road",
        "address": "265 Loudon Rd Ste 2",
        "type": "Chipotle",
        "id": 3535,
        "rating": 3.5,
        "place_id": "ChIJqX6Oac5r4okROBzwZ5ToaWM",
        "user_ratings_total": 1117
    },
    {
        "geocode": [
            42.51541,
            -71.03368
        ],
        "Rest_Name": "Lynnfield Market Street",
        "address": "425 Market Street",
        "type": "Chipotle",
        "id": 3536,
        "rating": 2.2,
        "place_id": "ChIJqzBwkekN44kRMD3SwgNAgLQ",
        "user_ratings_total": 127
    },
    {
        "geocode": [
            42.147459,
            -70.845195
        ],
        "Rest_Name": "Hanover Rte 53",
        "address": "1773 Washington St",
        "type": "Chipotle",
        "id": 3537,
        "rating": 2.2,
        "place_id": "ChIJgWcMLyKf5IkRbmsquZT4vRs",
        "user_ratings_total": 239
    },
    {
        "geocode": [
            51.54617,
            -0.47992
        ],
        "Rest_Name": "Uxbridge ",
        "address": "48-49 High St Uxbridge UB8 1UT",
        "type": "Chipotle",
        "id": 3538,
        "rating": 4.8,
        "place_id": "ChIJ9XyofjBvdkgRP_x0hIER8uY",
        "user_ratings_total": 350
    },
    {
        "geocode": [
            51.520076,
            -0.134271
        ],
        "Rest_Name": "Tottenham Court Road",
        "address": "64 Tottenham Court Road",
        "type": "Chipotle",
        "id": 3539,
        "rating": null,
        "place_id": "ChIJs_M3xEobdkgRFmUIHe_-RuY",
        "user_ratings_total": null
    },
    {
        "geocode": [
            51.458997,
            -0.074763
        ],
        "Rest_Name": "Lordship Lane",
        "address": "49-51 Lordship Lane",
        "type": "Chipotle",
        "id": 3540,
        "rating": 3.1,
        "place_id": "ChIJoeamQ-IDdkgR9LSOp4OhfmQ",
        "user_ratings_total": 48
    },
    {
        "geocode": [
            42.330852,
            -83.045705
        ],
        "Rest_Name": "Downtown Detroit",
        "address": "630 Woodward Ave Ste 620",
        "type": "Chipotle",
        "id": 3541,
        "rating": 4.6,
        "place_id": "N/A",
        "user_ratings_total": 10
    },
  {
        "geocode": [
            40.418114,
            -80.045905
        ],
        "Rest_Name": "Green Tree",
        "address": "978 Greentree Rd",
        "type": "Chipotle",
        "id": 3542,
        "rating": 3.7,
        "place_id": "N/A",
        "user_ratings_total": 3
    },
    {
        "geocode": [
            39.646360,
            -86.327820
        ],
        "Rest_Name": "Camby IN",
        "address": "8052 UPLAND BEND",
        "type": "Chipotle",
        "id": 3543,
        "rating": 2.4,
        "place_id": "N/A",
        "user_ratings_total": 7
    },
            {
        "geocode": [
            33.465706,
            -111.907200
        ],
        "Rest_Name": "Hayden & McDowell",
        "address": "8099 E McDowell Rd",
        "type": "Chipotle",
        "id": 3544,
        "rating": 4.5,
        "place_id": "N/A",
        "user_ratings_total": 6
    },
                {
        "geocode": [
            41.568040,
            -87.559070
        ],
        "Rest_Name": "Lansing IL",
        "address": "2302 Thornton Lansing Road",
        "type": "Chipotle",
        "id": 3545,
        "rating": 2.6,
        "place_id": "N/A",
        "user_ratings_total": 1
    },
            {
        "geocode": [
            40.836632,
            -115.790181
        ],
        "Rest_Name": "Elko",
        "address": "2405 Mountain City Hwy",
        "type": "Chipotle",
        "id": 3546,
        "rating": 2.3,
        "place_id": "N/A",
        "user_ratings_total": 23
    },
            {
        "geocode": [
            39.162853,
            -76.654238
        ],
        "Rest_Name": "Cromwell Business Park",
        "address": "920 Cromwell Park Dr Ste 101",
        "type": "Chipotle",
        "id": 3547,
        "rating": 5,
        "place_id": "N/A",
        "user_ratings_total": 1
    },
            {
        "geocode": [
            38.004550,
            -84.573521
        ],
        "Rest_Name": "Palomar",
        "address": "3900 Fountainblue Ln Ste 130",
        "type": "Chipotle",
        "id": 3548,
        "rating": 3,
        "place_id": "N/A",
        "user_ratings_total": 2
    },
            {
        "geocode": [
            41.470666,
            -81.900868
        ],
        "Rest_Name": "Westlake",
        "address": "25350 Detroit Rd",
        "type": "Chipotle",
        "id": 3549,
        "rating": 5,
        "place_id": "N/A",
        "user_ratings_total": 1
    },
            {
        "geocode": [
            42.027910,
            -87.893530
        ],
        "Rest_Name": "Des Plaines",
        "address": "1361 Lee Street",
        "type": "Chipotle",
        "id": 3550,
        "rating": 4,
        "place_id": "N/A",
        "user_ratings_total": 7
    },
            {
        "geocode": [
            44.014180,
            -88.581060
        ],
        "Rest_Name": "Oshkosh",
        "address": "440 S. Koeller",
        "type": "Chipotle",
        "id": 3551,
        "rating": 2.8,
        "place_id": "N/A",
        "user_ratings_total": 5
    },
       
  {
    "Rest_Name": "Sunset Strip",
    "address": "8570 Sunset Blvd",
    "id": 15000,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJ-dqmV7y-woARXjAU8graBCo",
    "user_ratings_total": 139.0,
    "geocode": [
      34.092666,
      -118.3781657
    ]
  },
  {
    "Rest_Name": "8th + Hill",
    "address": "410 West 8th St",
    "id": 15001,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJy1w-FtosDogRKGBXuEUhF1g",
    "user_ratings_total": 404.0,
    "geocode": [
      34.044769,
      -118.2565277
    ]
  },
  {
    "Rest_Name": "Montana Ave",
    "address": "1517 Montana Ave",
    "id": 15002,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJKZ8JcY-lwoARwnjXVUKt9yQ",
    "user_ratings_total": 101.0,
    "geocode": [
      34.0334082,
      -118.4937476
    ]
  },
  {
    "Rest_Name": "Studio City",
    "address": "13003 Ventura Blvd",
    "id": 15003,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJG_dKoeC9woAR4fum1d48lkI",
    "user_ratings_total": 125.0,
    "geocode": [
      34.1458323,
      -118.4163463
    ]
  },
  {
    "Rest_Name": "Westwood",
    "address": "10922 Kinross Ave",
    "id": 15004,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJyeOHzUy9woARy5NsockLVGw",
    "user_ratings_total": 33.0,
    "geocode": [
      34.0602128,
      -118.4462451
    ]
  },
  {
    "Rest_Name": "Folsom + Main",
    "address": "150 Folsom St",
    "id": 15005,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJTaXBtB2BhYAREktdiKNbZ0I",
    "user_ratings_total": 68.0,
    "geocode": [
      37.7899235,
      -122.3915284
    ]
  },
  {
    "Rest_Name": "SB Tesla- Page Mill",
    "address": "1501 Page Mill Rd",
    "id": 15006,
    "type": "Sweetgreen",
    "rating": null,
    "place_id": null,
    "user_ratings_total": null,
    "geocode": [
      37.4123125,
      -122.147938
    ]
  },
  {
    "Rest_Name": "Marina",
    "address": "2040 Chestnut St.",
    "id": 15007,
    "type": "Sweetgreen",
    "rating": 3.0,
    "place_id": "ChIJdzfMWlzTD4gRt1qaDfCVZ2Q",
    "user_ratings_total": 40.0,
    "geocode": [
      37.8008603,
      -122.4371593
    ]
  },
  {
    "Rest_Name": "5th + Grand",
    "address": "601 W 5th St",
    "id": 15008,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJY8serWvTD4gRus8KbV7xzc8",
    "user_ratings_total": 245.0,
    "geocode": [
      34.0507938,
      -118.2538957
    ]
  },
  {
    "Rest_Name": "West 3rd",
    "address": "8055 W 3rd St",
    "id": 15009,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJaUPLFTnGwoARG3_9ow6DZkk",
    "user_ratings_total": 341.0,
    "geocode": [
      34.072295,
      -118.365089
    ]
  },
  {
    "Rest_Name": "Palo Alto",
    "address": "581 Ramona St",
    "id": 15010,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJKWjVKTq7j4ARuUYobtoUXfQ",
    "user_ratings_total": 542.0,
    "geocode": [
      37.4447329,
      -122.1610914
    ]
  },
  {
    "Rest_Name": "9th + Broadway",
    "address": "831 Broadway",
    "id": 15011,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJyf4gntGBj4ARZJ_4h6y-0tE",
    "user_ratings_total": 113.0,
    "geocode": [
      37.8007946,
      -122.2737628
    ]
  },
  {
    "Rest_Name": "Toluca Lake",
    "address": "4320 Riverside Dr",
    "id": 15012,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJzU1DL7e_woARPArZTv2_duk",
    "user_ratings_total": 120.0,
    "geocode": [
      34.1519466,
      -118.3473424
    ]
  },
  {
    "Rest_Name": "La Jolla",
    "address": "4575 La Jolla Village Drive",
    "id": 15018,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJX3QwaikH3IARIhC4mdYdBGU",
    "user_ratings_total": 40.0,
    "geocode": [
      32.8724004,
      -117.2089342
    ]
  },
  {
    "Rest_Name": "Spectrum",
    "address": "714 Spectrum Center Drive",
    "id": 15020,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJzQOICFvn3IARSsMQvdsy538",
    "user_ratings_total": 46.0,
    "geocode": [
      33.6501116,
      -117.7418902
    ]
  },
  {
    "Rest_Name": "Greenwich",
    "address": "102 Greenwich Ave",
    "id": 15022,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJoWqFQ_WZwokRj6_CTgzW7ys",
    "user_ratings_total": 99.0,
    "geocode": [
      41.02916,
      -73.6267153
    ]
  },
  {
    "Rest_Name": "Darien",
    "address": "126 Heights Road",
    "id": 15023,
    "type": "Sweetgreen",
    "rating": 2.4,
    "place_id": "ChIJB0i0lhqhwokRDP6PUxihJjc",
    "user_ratings_total": 32.0,
    "geocode": [
      41.0694565,
      -73.4957919
    ]
  },
  {
    "Rest_Name": "2nd + Colorado",
    "address": "200 West 2nd St.",
    "id": 15024,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJa2Z1tRN5bIcRXO_VVIUcipk",
    "user_ratings_total": 310.0,
    "geocode": [
      30.264954,
      -97.7456859
    ]
  },
  {
    "Rest_Name": "Hollywood",
    "address": "6115 West Sunset Blvd",
    "id": 15025,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJGdzc3ze_woARZAAgQTgHqz8",
    "user_ratings_total": 289.0,
    "geocode": [
      34.0982714,
      -118.3226741
    ]
  },
  {
    "Rest_Name": "16th + Pearl",
    "address": "1601 Pearl St",
    "id": 15029,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJy1w-FtosDogRKGBXuEUhF1g",
    "user_ratings_total": 404.0,
    "geocode": [
      40.0191411,
      -105.2745506
    ]
  },
  {
    "Rest_Name": "Highlands Ranch",
    "address": "3620 E Highlands Ranch Pkwy",
    "id": 15030,
    "type": "Sweetgreen",
    "rating": 4.6,
    "place_id": "ChIJwZ-bI-aDbIcRcHevmaJeMYE",
    "user_ratings_total": 67.0,
    "geocode": [
      39.5428864,
      -104.9469621
    ]
  },
  {
    "Rest_Name": "Park Meadows",
    "address": "8423 Park Meadows Center Drive  #E160",
    "id": 15031,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": null,
    "user_ratings_total": 28.0,
    "geocode": [
      39.5624248,
      -104.8782086
    ]
  },
  {
    "Rest_Name": "Coconut Grove",
    "address": "3015 Grand Ave. Space #140",
    "id": 15033,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": null,
    "user_ratings_total": 105.0,
    "geocode": [
      25.728972,
      -80.241977
    ]
  },
  {
    "Rest_Name": "The Falls",
    "address": "8870 SW 136th Street",
    "id": 15036,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJn4CGd-LH2YgRFtldPeZhxvQ",
    "user_ratings_total": 56.0,
    "geocode": [
      25.6456773,
      -80.3371219
    ]
  },
  {
    "Rest_Name": "Tampa Heights",
    "address": "301 W Palm Ave",
    "id": 15037,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ1c5QaHzFwogR0-lm7ZpZMRk",
    "user_ratings_total": 84.0,
    "geocode": [
      27.9624286,
      -82.4638043
    ]
  },
  {
    "Rest_Name": "River Market",
    "address": "2374 N Federal Hwy",
    "id": 15038,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ74_-pV3TD4gRJZnF7aSwSOs",
    "user_ratings_total": 188.0,
    "geocode": [
      26.1550022,
      -80.1169592
    ]
  },
  {
    "Rest_Name": "West Palm",
    "address": "701 S Rosemary Ave",
    "id": 15039,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJQzxTAC7X2IgRgOzzmaMSv00",
    "user_ratings_total": 93.0,
    "geocode": [
      26.7073975,
      -80.0574215
    ]
  },
  {
    "Rest_Name": "Hyde Park Village",
    "address": "722 South Village Circle",
    "id": 15040,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJF4ZN0_YpDogR8CZyWQGB4iA",
    "user_ratings_total": 207.0,
    "geocode": [
      27.9359451,
      -82.4751699
    ]
  },
  {
    "Rest_Name": "Sunset Harbour",
    "address": "1929 Purdy Ave",
    "id": 15041,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJ5QQAjYC12YgROYHzjMvYeC4",
    "user_ratings_total": 87.0,
    "geocode": [
      25.7952157,
      -80.1442322
    ]
  },
  {
    "Rest_Name": "Wynwood",
    "address": "2621 Northwest 2nd Avenue",
    "id": 15042,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJ3R9q-oK32YgRtocZ1BnXZPo",
    "user_ratings_total": 160.0,
    "geocode": [
      25.801825,
      -80.198745
    ]
  },
  {
    "Rest_Name": "Palm Beach Gardens",
    "address": "11701 Lake Victoria Gardens Avenue",
    "id": 15043,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJQ-1ukPYr34gRHwJHdFFZUa0",
    "user_ratings_total": 50.0,
    "geocode": [
      26.8495787,
      -80.0948511
    ]
  },
  {
    "Rest_Name": "Silver Spring",
    "address": "8517 Georgia Ave",
    "id": 15044,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJ9z8NzrvIt4kR9X6QPGJqns0",
    "user_ratings_total": 267.0,
    "geocode": [
      38.9951538,
      -77.0268273
    ]
  },
  {
    "Rest_Name": "West Midtown",
    "address": "1050 Howell Mill Road NW",
    "id": 15045,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJr8ji9vsF9YgR36hJZ8J4heI",
    "user_ratings_total": 53.0,
    "geocode": [
      33.7834152,
      -84.4121429
    ]
  },
  {
    "Rest_Name": "Lenox Square",
    "address": "3393 Peachtree Rd NE #3065B",
    "id": 15046,
    "type": "Sweetgreen",
    "rating": 3.3,
    "place_id": null,
    "user_ratings_total": 103.0,
    "geocode": [
      33.846335,
      -84.3635778
    ]
  },
  {
    "Rest_Name": "Ponce City Market",
    "address": "650 North Ave NE, Suite 102B",
    "id": 15047,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJZ4BBTk4F9YgRD6mfjv6Pz40",
    "user_ratings_total": 209.0,
    "geocode": [
      33.7715446,
      -84.3672662
    ]
  },
  {
    "Rest_Name": "Perimeter",
    "address": "123 Perimeter Center W Suite 400",
    "id": 15048,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ7yQrPfEJ9YgR9Nekw2d06Lc",
    "user_ratings_total": 184.0,
    "geocode": [
      33.9278953,
      -84.3441195
    ]
  },
  {
    "Rest_Name": "14th + Peachtree",
    "address": "1201 Peachtree St. NE, Suite 150",
    "id": 15049,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJGSxsqnwF9YgRrKnQcwPC9Jk",
    "user_ratings_total": 102.0,
    "geocode": [
      33.787648,
      -84.383102
    ]
  },
  {
    "Rest_Name": "Downtown Indianapolis",
    "address": "157 East New York Street",
    "id": 15051,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJ7zKaAGlRa4gRhdd5UVdq5Aw",
    "user_ratings_total": 97.0,
    "geocode": [
      39.771248,
      -86.1549493
    ]
  },
  {
    "Rest_Name": "Fishers",
    "address": "9711 East 116th Street",
    "id": 15052,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJ95uDUtK1FIgR_XrYCZpZIhQ",
    "user_ratings_total": 63.0,
    "geocode": [
      39.9565221,
      -86.0006834
    ]
  },
  {
    "Rest_Name": "86th + Keystone",
    "address": "8702 Keystone Crossing",
    "id": 15053,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJBVExhaVTa4gRnVUBKEur7KA",
    "user_ratings_total": 76.0,
    "geocode": [
      39.9146201,
      -86.11124
    ]
  },
  {
    "Rest_Name": "Bloomington",
    "address": "210 East Kirkwood Avenue",
    "id": 15054,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJ7ZYIM4FnbIgRihNVtQJT9Nc",
    "user_ratings_total": 30.0,
    "geocode": [
      39.1663178,
      -86.5316788
    ]
  },
  {
    "Rest_Name": "River North",
    "address": "623 N State St",
    "id": 15055,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJeRWme60sDogRKMsPP2a2bsI",
    "user_ratings_total": 573.0,
    "geocode": [
      41.893189,
      -87.628114
    ]
  },
  {
    "Rest_Name": "Millennium Park",
    "address": "150 N Michigan Ave",
    "id": 15056,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJS1BWjKUsDogRTzKzvjcNxP4",
    "user_ratings_total": 273.0,
    "geocode": [
      41.8847816,
      -87.6249607
    ]
  },
  {
    "Rest_Name": "Willis Tower",
    "address": "233 S Wacker Dr",
    "id": 15057,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJDV63SqYtDogRcGuSJ05vu8w",
    "user_ratings_total": 65.0,
    "geocode": [
      41.8788792,
      -87.6358363
    ]
  },
  {
    "Rest_Name": "Andersonville",
    "address": "5400 N Clark St.",
    "id": 15058,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJxb-laB3RD4gR1IglpSkfsm0",
    "user_ratings_total": 138.0,
    "geocode": [
      41.9799644,
      -87.6685351
    ]
  },
  {
    "Rest_Name": "Streeterville",
    "address": "227 East Ontario Street",
    "id": 15059,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJwW2TRC4tDogRRRY8bsYnGGA",
    "user_ratings_total": 78.0,
    "geocode": [
      41.8931646,
      -87.6216438
    ]
  },
  {
    "Rest_Name": "Logan Square",
    "address": "2500 N Milwaukee Ave",
    "id": 15060,
    "type": "Sweetgreen",
    "rating": 3.3,
    "place_id": "ChIJWWIx-K7ND4gRu-sAkvgxcv8",
    "user_ratings_total": 55.0,
    "geocode": [
      41.926122,
      -87.7030106
    ]
  },
  {
    "Rest_Name": "Deer Park",
    "address": "20530 North Rand Road",
    "id": 15061,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJ1UwHMiKjD4gRw4iV99f8uz8",
    "user_ratings_total": 38.0,
    "geocode": [
      42.1613988,
      -88.0546076
    ]
  },
  {
    "Rest_Name": "Schaumburg",
    "address": "1320 N. Meacham Rd",
    "id": 15063,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJNfTPNqWvD4gRCvHRvUbIU-0",
    "user_ratings_total": 162.0,
    "geocode": [
      42.0532116,
      -88.0456692
    ]
  },
  {
    "Rest_Name": "Naperville",
    "address": "223 South Main Street",
    "id": 15064,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJD7gvJhRXDogRsysZoQUIZVM",
    "user_ratings_total": 88.0,
    "geocode": [
      41.7723493,
      -88.1499147
    ]
  },
  {
    "Rest_Name": "Dearborn + Madison",
    "address": "1 N Dearborn St",
    "id": 15065,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJqxXDL7ssDogRx61gcE30hF8",
    "user_ratings_total": 264.0,
    "geocode": [
      41.8823576,
      -87.6291667
    ]
  },
  {
    "Rest_Name": "North + Clybourn",
    "address": "900 W North Ave",
    "id": 15066,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJY8serWvTD4gRus8KbV7xzc8",
    "user_ratings_total": 245.0,
    "geocode": [
      41.9115507,
      -87.6508297
    ]
  },
  {
    "Rest_Name": "Wicker Park",
    "address": "1471 N Milwaukee Ave",
    "id": 15067,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ74_-pV3TD4gRJZnF7aSwSOs",
    "user_ratings_total": 188.0,
    "geocode": [
      41.9087763,
      -87.6744363
    ]
  },
  {
    "Rest_Name": "Lincoln Common",
    "address": "2361 N Lincoln Ave",
    "id": 15069,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJ4YpTUyrTD4gRumRaKs15Yhg",
    "user_ratings_total": 44.0,
    "geocode": [
      41.9251088,
      -87.6478192
    ]
  },
  {
    "Rest_Name": "Northbrook",
    "address": "984 Willow Road",
    "id": 15070,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJ3TVbfU3HD4gRJC1ysbxRcdQ",
    "user_ratings_total": 26.0,
    "geocode": [
      42.1073071,
      -87.802819
    ]
  },
  {
    "Rest_Name": "Fulton Market",
    "address": "1000 Randolph St",
    "id": 15071,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJy1w-FtosDogRKGBXuEUhF1g",
    "user_ratings_total": 404.0,
    "geocode": [
      41.8846323,
      -87.6523399
    ]
  },
  {
    "Rest_Name": "Oakbrook",
    "address": "1817 Oakbrook Center",
    "id": 15072,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJfV637kpNDogRr-oIIh6i_Nw",
    "user_ratings_total": 209.0,
    "geocode": [
      41.8537482,
      -87.9541523
    ]
  },
  {
    "Rest_Name": "Deerfield",
    "address": "775 Waukegan",
    "id": 15073,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJmaH-KbDBD4gRvcaUdDetT-w",
    "user_ratings_total": 102.0,
    "geocode": [
      42.1668499,
      -87.8452603
    ]
  },
  {
    "Rest_Name": "Evanston",
    "address": "1601 Sherman Avenue",
    "id": 15074,
    "type": "Sweetgreen",
    "rating": 3.2,
    "place_id": "ChIJrwDkD7vRD4gRzMX-1XZVKeU",
    "user_ratings_total": 83.0,
    "geocode": [
      42.0472072,
      -87.6816327
    ]
  },
  {
    "Rest_Name": "Oak Park",
    "address": "1143 Lake Street",
    "id": 15075,
    "type": "Sweetgreen",
    "rating": 3.1,
    "place_id": "ChIJ4cf2Yqo1DogR5SNHxYpLtsE",
    "user_ratings_total": 90.0,
    "geocode": [
      41.8883796,
      -87.8043423
    ]
  },
  {
    "Rest_Name": "Bethesda",
    "address": "4831 Bethesda Ave",
    "id": 15076,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJpwsTC3zJt4kRRauE7C2TDzo",
    "user_ratings_total": 189.0,
    "geocode": [
      38.9811015,
      -77.0969659
    ]
  },
  {
    "Rest_Name": "College Park",
    "address": "8300 Baltimore Ave",
    "id": 15077,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJe0UHhabGt4kRNzQyP8Q6vlY",
    "user_ratings_total": 504.0,
    "geocode": [
      38.992541,
      -76.933663
    ]
  },
  {
    "Rest_Name": "Towson",
    "address": "1 E. Joppa Road",
    "id": 15078,
    "type": "Sweetgreen",
    "rating": 2.9,
    "place_id": "ChIJzYdpCjIPyIkRPNXFpnd1MPc",
    "user_ratings_total": 69.0,
    "geocode": [
      39.4015618,
      -76.6017709
    ]
  },
  {
    "Rest_Name": "Pike + Rose",
    "address": "11875 Grand Park Ave",
    "id": 15080,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJKR-cbHvNt4kRAkP5-V43TfQ",
    "user_ratings_total": 130.0,
    "geocode": [
      39.0505418,
      -77.117171
    ]
  },
  {
    "Rest_Name": "Mt Vernon",
    "address": "601 Massachusetts Avenue Northwest",
    "id": 15083,
    "type": "Sweetgreen",
    "rating": 3.3,
    "place_id": "ChIJey7R6R23t4kRU4ct-YPcGwA",
    "user_ratings_total": 33.0,
    "geocode": [
      38.901813,
      -77.020041
    ]
  },
  {
    "Rest_Name": "Prudential Center",
    "address": "800 Boylston St",
    "id": 15084,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJzdiyTA5644kRkfw0k3B67hY",
    "user_ratings_total": 169.0,
    "geocode": [
      42.3473046,
      -71.0819042
    ]
  },
  {
    "Rest_Name": "Downtown Crossing",
    "address": "13 School St",
    "id": 15085,
    "type": "Sweetgreen",
    "rating": 4.5,
    "place_id": "ChIJMTSfh4Rw44kRQQMRrzqtOZc",
    "user_ratings_total": 327.0,
    "geocode": [
      42.357691,
      -71.058809
    ]
  },
  {
    "Rest_Name": "Harvard Square",
    "address": "39 JFK St",
    "id": 15086,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJ343usUJ344kRZLXNRIR0y3g",
    "user_ratings_total": 426.0,
    "geocode": [
      42.3727512,
      -71.120277
    ]
  },
  {
    "Rest_Name": "Wellesley",
    "address": "180 Linden St",
    "id": 15087,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJAQAQO7SG44kRqk8vqxyPCEs",
    "user_ratings_total": 166.0,
    "geocode": [
      42.3001171,
      -71.2924786
    ]
  },
  {
    "Rest_Name": "Ink Block",
    "address": "354 Harrison Ave",
    "id": 15088,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJHzXaRnB644kRgGtbmn4xjIw",
    "user_ratings_total": 203.0,
    "geocode": [
      42.3450576,
      -71.0633556
    ]
  },
  {
    "Rest_Name": "Arsenal Yards",
    "address": "100 Bond Square",
    "id": 15089,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJDV63SqYtDogRcGuSJ05vu8w",
    "user_ratings_total": 65.0,
    "geocode": [
      42.362147,
      -71.158391
    ]
  },
  {
    "Rest_Name": "Back Bay",
    "address": "659 Boylston St",
    "id": 15090,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJa6jJFwx644kRCqw6ohQtC6c",
    "user_ratings_total": 656.0,
    "geocode": [
      42.3500262,
      -71.0786707
    ]
  },
  {
    "Rest_Name": "Lynnfield",
    "address": "675 Market St",
    "id": 15091,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJq1UXvz4N44kROWVWBfsbPCQ",
    "user_ratings_total": 334.0,
    "geocode": [
      42.516789,
      -71.035619
    ]
  },
  {
    "Rest_Name": "Assembly Row",
    "address": "345 Assembly Row #812",
    "id": 15092,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": null,
    "user_ratings_total": 124.0,
    "geocode": [
      42.3929423,
      -71.0808783
    ]
  },
  {
    "Rest_Name": "Peabody",
    "address": "210 Andover Street, Unit N115A",
    "id": 15093,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJqyi3UgcT44kRTiRQskdRDIA",
    "user_ratings_total": 21.0,
    "geocode": [
      42.5414196,
      -70.9445901
    ]
  },
  {
    "Rest_Name": "Summer Street",
    "address": "87 Summer St",
    "id": 15094,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJ4YpTUyrTD4gRumRaKs15Yhg",
    "user_ratings_total": 44.0,
    "geocode": [
      42.353817,
      -71.058639
    ]
  },
  {
    "Rest_Name": "Kendall Square",
    "address": "201 Galileo Galilei Way",
    "id": 15095,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJX65ns7hx44kR8UOmDF_q61U",
    "user_ratings_total": 80.0,
    "geocode": [
      42.3649281,
      -71.0888711
    ]
  },
  {
    "Rest_Name": "Longwood Medical",
    "address": "360 Longwood Avenue",
    "id": 15096,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJb2_EGHp544kR2dz7qnFcO5o",
    "user_ratings_total": 35.0,
    "geocode": [
      42.3390223,
      -71.1078085
    ]
  },
  {
    "Rest_Name": "Troy",
    "address": "730 East Big Beaver Road",
    "id": 15098,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJs8k8aI3FJIgRe3_dgWafzN0",
    "user_ratings_total": 81.0,
    "geocode": [
      42.5625077,
      -83.1328379
    ]
  },
  {
    "Rest_Name": "Ann Arbor",
    "address": "311 S. State St.",
    "id": 15099,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJbWRMzDWvPIgR79FijUcSwDk",
    "user_ratings_total": 71.0,
    "geocode": [
      42.278969,
      -83.740576
    ]
  },
  {
    "Rest_Name": "Birmingham",
    "address": "167 North Old Woodward Avenue",
    "id": 15100,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJY8serWvTD4gRus8KbV7xzc8",
    "user_ratings_total": 245.0,
    "geocode": [
      42.54697,
      -83.2149826
    ]
  },
  {
    "Rest_Name": "Arbor Hills",
    "address": "3070 Washtenaw Avenue",
    "id": 15101,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJV2q-ybivPIgRke8sqvGBMxk",
    "user_ratings_total": 57.0,
    "geocode": [
      42.2568435,
      -83.6978046
    ]
  },
  {
    "Rest_Name": "University of Minnesota",
    "address": "615 Washington Avenue Southeast",
    "id": 15102,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJJ8VWgI8ts1IR7pfC_oGh5FU",
    "user_ratings_total": 41.0,
    "geocode": [
      44.974379,
      -93.2295986
    ]
  },
  {
    "Rest_Name": "Grand Ave",
    "address": "735 Grand Ave",
    "id": 15103,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJn0jyBu4r9ocR2hcGbQ-EZAk",
    "user_ratings_total": 86.0,
    "geocode": [
      44.9402708,
      -93.1311116
    ]
  },
  {
    "Rest_Name": "Galleria Edina",
    "address": "3545 Galleria",
    "id": 15104,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJxaV-zfcn9ocRB3KfaO4-95A",
    "user_ratings_total": 105.0,
    "geocode": [
      44.8772613,
      -93.326556
    ]
  },
  {
    "Rest_Name": "North Loop",
    "address": "212 N 3rd Ave",
    "id": 15105,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJT3PABKczs1IRQBZMQuaO5w4",
    "user_ratings_total": 111.0,
    "geocode": [
      44.9854013,
      -93.2720038
    ]
  },
  {
    "Rest_Name": "Jersey City",
    "address": "90 Hudson St.",
    "id": 15106,
    "type": "Sweetgreen",
    "rating": 3.1,
    "place_id": "ChIJs7-nAG5RwokRM-3t9RGLZOY",
    "user_ratings_total": 308.0,
    "geocode": [
      40.7152016,
      -74.033868
    ]
  },
  {
    "Rest_Name": "Columbia U",
    "address": "2937 Broadway",
    "id": 15112,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJ3W_Lrz72wokRy3BEhF4s20U",
    "user_ratings_total": 451.0,
    "geocode": [
      40.8073834,
      -73.964812
    ]
  },
  {
    "Rest_Name": "Bleecker + 6th",
    "address": "226 Bleecker St",
    "id": 15113,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJ6TuWSJJZwokRIvcVZcp-Q7U",
    "user_ratings_total": 328.0,
    "geocode": [
      40.7299908,
      -74.0024662
    ]
  },
  {
    "Rest_Name": "Spring + Hudson",
    "address": "315 Hudson Street",
    "id": 15118,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJy1w-FtosDogRKGBXuEUhF1g",
    "user_ratings_total": 404.0,
    "geocode": [
      40.7260344,
      -74.0086852
    ]
  },
  {
    "Rest_Name": "85th + 3rd",
    "address": "1500 3rd Ave",
    "id": 15119,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJF4ZN0_YpDogR8CZyWQGB4iA",
    "user_ratings_total": 207.0,
    "geocode": [
      40.7780316,
      -73.9549226
    ]
  },
  {
    "Rest_Name": "Union Square",
    "address": "8 E 18th St",
    "id": 15120,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJv7h7W6JZwokR0PWGNOqQ8XM",
    "user_ratings_total": 393.0,
    "geocode": [
      40.7380613,
      -73.9912233
    ]
  },
  {
    "Rest_Name": "Meatpacking",
    "address": "32 Gansevoort St",
    "id": 15121,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJz3mf-r9ZwokRm8hYnrceGC0",
    "user_ratings_total": 392.0,
    "geocode": [
      40.7391918,
      -74.0058739
    ]
  },
  {
    "Rest_Name": "61st + Lex",
    "address": "137 E  61st St",
    "id": 15122,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ74_-pV3TD4gRJZnF7aSwSOs",
    "user_ratings_total": 188.0,
    "geocode": [
      40.7636906,
      -73.9675058
    ]
  },
  {
    "Rest_Name": "12th + University",
    "address": "101 University Pl",
    "id": 15123,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJy1w-FtosDogRKGBXuEUhF1g",
    "user_ratings_total": 404.0,
    "geocode": [
      40.7339247,
      -73.9923918
    ]
  },
  {
    "Rest_Name": "Chambers St",
    "address": "125 Chambers St",
    "id": 15124,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJ5SYtXCNbwokRNa8EaDbaDMA",
    "user_ratings_total": 232.0,
    "geocode": [
      40.7155448,
      -74.0088733
    ]
  },
  {
    "Rest_Name": "Bowery",
    "address": "347 Bowery Ave",
    "id": 15125,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJQ1RsG1pZwokROTq5AbUEfCY",
    "user_ratings_total": 123.0,
    "geocode": [
      40.7263613,
      -73.9914483
    ]
  },
  {
    "Rest_Name": "Rice Village",
    "address": "2551 Amherst St",
    "id": 15141,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJTSi1rJLBQIYR5znBpUJzpPI",
    "user_ratings_total": 222.0,
    "geocode": [
      29.715623,
      -95.4175087
    ]
  },
  {
    "Rest_Name": "Montrose",
    "address": "1303 Westheimer",
    "id": 15143,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJdWDB1aHBQIYRELBbwYSSSMA",
    "user_ratings_total": 361.0,
    "geocode": [
      29.7434544,
      -95.3951208
    ]
  },
  {
    "Rest_Name": "Main + Walker",
    "address": "820 Main St.",
    "id": 15144,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJxb-laB3RD4gR1IglpSkfsm0",
    "user_ratings_total": 138.0,
    "geocode": [
      29.758285,
      -95.3647591
    ]
  },
  {
    "Rest_Name": "West Village",
    "address": "3636 McKinney Avenue, Suite 100",
    "id": 15145,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJV-jyEZqfToYRdBfR3RQsAY4",
    "user_ratings_total": 207.0,
    "geocode": [
      32.8073188,
      -96.7966329
    ]
  },
  {
    "Rest_Name": "Fort Worth",
    "address": "1605 S University Drive",
    "id": 15146,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJr7OhtDhzToYRheFCpQz86M8",
    "user_ratings_total": 61.0,
    "geocode": [
      32.7279155,
      -97.3606907
    ]
  },
  {
    "Rest_Name": "Mueller",
    "address": "1900 Aldrich Street Suite 140",
    "id": 15147,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJf2NgWNO1RIYR7jgKfUqn_WY",
    "user_ratings_total": 56.0,
    "geocode": [
      30.2986524,
      -97.7048787
    ]
  },
  {
    "Rest_Name": "Uptown Park",
    "address": "1141 Uptown Park Blvd",
    "id": 15148,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJJXzRqa_BQIYRcZF4N7mymnU",
    "user_ratings_total": 24.0,
    "geocode": [
      29.7551252,
      -95.4572367
    ]
  },
  {
    "Rest_Name": "The Domain",
    "address": "11501 Rock Rose Avenue, Suite 124",
    "id": 15149,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": null,
    "user_ratings_total": 16.0,
    "geocode": [
      30.4007577,
      -97.7227295
    ]
  },
  {
    "Rest_Name": "South Congress",
    "address": "1007 South Congress Ave.",
    "id": 15150,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJ_dHJMb61RIYRqBEXojsIGOM",
    "user_ratings_total": 251.0,
    "geocode": [
      30.253956,
      -97.7477392
    ]
  },
  {
    "Rest_Name": "The Drag",
    "address": "2234 Guadalupe St",
    "id": 15151,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJV-YgNv61RIYRmfQQLDWPkJ4",
    "user_ratings_total": 93.0,
    "geocode": [
      30.2857515,
      -97.7421159
    ]
  },
  {
    "Rest_Name": "The Heights",
    "address": "600 N Shepherd Dr",
    "id": 15152,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJj53geMLHQIYRaWuek7nX9sU",
    "user_ratings_total": 159.0,
    "geocode": [
      29.7824121,
      -95.4080107
    ]
  },
  {
    "Rest_Name": "Lakewood",
    "address": "6400 Gaston Avenue",
    "id": 15153,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJcRiSAwufToYRKkq2LzUUo4g",
    "user_ratings_total": 67.0,
    "geocode": [
      32.8136598,
      -96.752533
    ]
  },
  {
    "Rest_Name": "67th + Columbus",
    "address": "159 Columbus Ave",
    "id": 15159,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJ7QLgUC5ZwokRq1NLGZe4pJE",
    "user_ratings_total": 89.0,
    "geocode": [
      40.7743665,
      -73.9807694
    ]
  },
  {
    "Rest_Name": "Culver City: The Lab",
    "address": "8840 Washington Blvd",
    "id": 15180,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJP7fjU4G5woARoOqmyqfO700",
    "user_ratings_total": 200.0,
    "geocode": [
      34.0271811,
      -118.3875259
    ]
  },
  {
    "Rest_Name": "DUMBO",
    "address": "50 Washington St",
    "id": 15181,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJUX8oaDFawokRv7SH0n6SJQM",
    "user_ratings_total": 529.0,
    "geocode": [
      40.7029349,
      -73.9897522
    ]
  },
  {
    "Rest_Name": "Totem Lake",
    "address": "11900 Northeast Village Plaza, Suite 164",
    "id": 15183,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJW-dogmcNkFQR_3LubwUURuo",
    "user_ratings_total": 57.0,
    "geocode": [
      47.7126696,
      -122.1812823
    ]
  },
  {
    "Rest_Name": "South Lake Union",
    "address": "801 Lenora Street",
    "id": 15184,
    "type": "Sweetgreen",
    "rating": null,
    "place_id": null,
    "user_ratings_total": null,
    "geocode": [
      47.6165074,
      -122.3376193
    ]
  },
  {
    "Rest_Name": "West End",
    "address": "2238 M St NW",
    "id": 15185,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ74_-pV3TD4gRJZnF7aSwSOs",
    "user_ratings_total": 188.0,
    "geocode": [
      38.905184,
      -77.0493305
    ]
  },
  {
    "Rest_Name": "Farragut Square",
    "address": "888 17th St NW",
    "id": 15186,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJZVEg-7u3t4kREiOsY5fgdCI",
    "user_ratings_total": 147.0,
    "geocode": [
      38.901095,
      -77.0398145
    ]
  },
  {
    "Rest_Name": "Logan Circle",
    "address": "1461 P St NW",
    "id": 15187,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJ-8G13MG3t4kRSubiJXosIJ4",
    "user_ratings_total": 231.0,
    "geocode": [
      38.9098374,
      -77.0339645
    ]
  },
  {
    "Rest_Name": "14th + W",
    "address": "1325 W St NW",
    "id": 15191,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJlex5Dee3t4kR8RY663krtbs",
    "user_ratings_total": 235.0,
    "geocode": [
      38.9194299,
      -77.0311521
    ]
  },
  {
    "Rest_Name": "Georgetown",
    "address": "1044 Wisconsin Ave NW",
    "id": 15192,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJvbAUpUi2t4kRa1unHwv-8Yo",
    "user_ratings_total": 247.0,
    "geocode": [
      38.9038278,
      -77.0630018
    ]
  },
  {
    "Rest_Name": "Penn Quarter",
    "address": "624 E. Street, NW",
    "id": 15193,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJySDe1Xq3t4kR4epip116orA",
    "user_ratings_total": 69.0,
    "geocode": [
      38.896012,
      -77.0214127
    ]
  },
  {
    "Rest_Name": "Madison",
    "address": "652 State Street",
    "id": 15218,
    "type": "Sweetgreen",
    "rating": 3.2,
    "place_id": "ChIJCfHMiVBTBogR0nR70wac7xM",
    "user_ratings_total": 18.0,
    "geocode": [
      43.075054,
      -89.396524
    ]
  },
  {
    "Rest_Name": "Third Ward",
    "address": "300 E. Buffalo St",
    "id": 15219,
    "type": "Sweetgreen",
    "rating": 3.1,
    "place_id": "ChIJVQ7PhZIZBYgRfgd1l9gmgoM",
    "user_ratings_total": 91.0,
    "geocode": [
      43.034062,
      -87.9070224
    ]
  },
  {
    "Rest_Name": "Brookfield",
    "address": "20330 West Lord Street, Suite G117",
    "id": 15220,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJ90DfJR0HBYgRXFgGSLazGn4",
    "user_ratings_total": 50.0,
    "geocode": [
      43.0342689,
      -88.1644672
    ]
  },
  {
    "Rest_Name": "Mountain View",
    "address": "440 Castro St",
    "id": 15221,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJw4RuczO3j4ARC7RByZ5K9nI",
    "user_ratings_total": 525.0,
    "geocode": [
      37.3910306,
      -122.0813237
    ]
  },
  {
    "Rest_Name": "Berkeley",
    "address": "1890 Shattuck Ave",
    "id": 15222,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJe57aDZ9-hYARgrcTiaA5JOI",
    "user_ratings_total": 689.0,
    "geocode": [
      37.8739935,
      -122.2688226
    ]
  },
  {
    "Rest_Name": "Brentwood",
    "address": "11677 San Vicente Blvd",
    "id": 15223,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJ3-Lbb6C8woAR3k-VIpFbQ8A",
    "user_ratings_total": 139.0,
    "geocode": [
      34.0542471,
      -118.4651397
    ]
  },
  {
    "Rest_Name": "Sunnyvale",
    "address": "300 W El Camino Real",
    "id": 15224,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJd_ydnWG2j4ARPjGR0_2wJWU",
    "user_ratings_total": 535.0,
    "geocode": [
      37.3683036,
      -122.0355091
    ]
  },
  {
    "Rest_Name": "Pasadena",
    "address": "600 E Colorado Blvd.",
    "id": 15225,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJF7DmQnLDwoARrD9FjDh907g",
    "user_ratings_total": 163.0,
    "geocode": [
      34.1455348,
      -118.1379256
    ]
  },
  {
    "Rest_Name": "Del Mar",
    "address": "12853 El Camino Real, Suite 111",
    "id": 15226,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJWXIywokJ3IARvNp6ZADbLuQ",
    "user_ratings_total": 22.0,
    "geocode": [
      32.9507443,
      -117.2329339
    ]
  },
  {
    "Rest_Name": "Dupont",
    "address": "1512 Connecticut Ave NW",
    "id": 15227,
    "type": "Sweetgreen",
    "rating": 4.5,
    "place_id": "ChIJn8bHOsa3t4kR0dcMdz1mR88",
    "user_ratings_total": 278.0,
    "geocode": [
      38.9104437,
      -77.0442565
    ]
  },
  {
    "Rest_Name": "University Hills",
    "address": "2770 S. Colorado Boulevard, Suite L-110",
    "id": 15228,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ9akJSUN_bIcRlomISexy03k",
    "user_ratings_total": 30.0,
    "geocode": [
      39.665927,
      -104.9402543
    ]
  },
  {
    "Rest_Name": "Tennyson",
    "address": "3985 N Tennyson Street, Suite 110",
    "id": 15229,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJxb-laB3RD4gR1IglpSkfsm0",
    "user_ratings_total": 138.0,
    "geocode": [
      39.7727206,
      -105.0443717
    ]
  },
  {
    "Rest_Name": "Cherry Creek",
    "address": "275 Saint Paul St",
    "id": 15230,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJzYWla2l_bIcRMXSgJYOVfvs",
    "user_ratings_total": 268.0,
    "geocode": [
      39.7206012,
      -104.9510077
    ]
  },
  {
    "Rest_Name": "LoDo",
    "address": "1750 Wewatta St",
    "id": 15231,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJa2Z1tRN5bIcRXO_VVIUcipk",
    "user_ratings_total": 310.0,
    "geocode": [
      39.7542552,
      -105.0008633
    ]
  },
  {
    "Rest_Name": "Coral Gables",
    "address": "122 Giralda Ave",
    "id": 15232,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJ9WL8z0e32YgRlRd8ibDsYHo",
    "user_ratings_total": 158.0,
    "geocode": [
      25.7514969,
      -80.2574621
    ]
  },
  {
    "Rest_Name": "Esplanade at Aventura",
    "address": "19505 Biscayne Boulevard, Suite #2160",
    "id": 15233,
    "type": "Sweetgreen",
    "rating": null,
    "place_id": null,
    "user_ratings_total": null,
    "geocode": [
      25.955848,
      -80.1436708
    ]
  },
  {
    "Rest_Name": "Emory Village",
    "address": "1556 North Decatur Road",
    "id": 15234,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJP6E9z98H9YgR89TUlV3PxvI",
    "user_ratings_total": 16.0,
    "geocode": [
      33.7882385,
      -84.3266559
    ]
  },
  {
    "Rest_Name": "Harbor East",
    "address": "1306 Fleet St",
    "id": 15235,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJO7NyaH4DyIkRDYYqjXVwpDQ",
    "user_ratings_total": 399.0,
    "geocode": [
      39.2843456,
      -76.5985956
    ]
  },
  {
    "Rest_Name": "Gaithersburg",
    "address": "299 Copley Place",
    "id": 15236,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJARfRRdMttokRCoxqFotkr4Y",
    "user_ratings_total": 50.0,
    "geocode": [
      39.1153618,
      -77.2066753
    ]
  },
  {
    "Rest_Name": "Shrewsbury",
    "address": "575 Broad Street",
    "id": 15237,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJ61s82p4xwokRU8olwQBo02o",
    "user_ratings_total": 71.0,
    "geocode": [
      40.3326111,
      -74.0627208
    ]
  },
  {
    "Rest_Name": "Montclair",
    "address": "460 Bloomfield Avenue",
    "id": 15238,
    "type": "Sweetgreen",
    "rating": 3.5,
    "place_id": "ChIJOdTf3v9VwokRxQ-uvCIU-SA",
    "user_ratings_total": 58.0,
    "geocode": [
      40.8134808,
      -74.2175886
    ]
  },
  {
    "Rest_Name": "Marlton",
    "address": "500 Route 73 South, Suite A28",
    "id": 15239,
    "type": "Sweetgreen",
    "rating": 3.3,
    "place_id": "ChIJb9EnuMAzwYkRPI83YtP34o8",
    "user_ratings_total": 27.0,
    "geocode": [
      39.8788271,
      -74.919718
    ]
  },
  {
    "Rest_Name": "Shops at Riverside",
    "address": "380-390 Hackensack Ave, Space 169",
    "id": 15240,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJo_Gl-ZL7wokRFUM7ZLQkSJ4",
    "user_ratings_total": 145.0,
    "geocode": [
      40.9058382,
      -74.0300446
    ]
  },
  {
    "Rest_Name": "Astor Place",
    "address": "10 Astor Pl",
    "id": 15241,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJczOdUZpZwokRI5iPGG64VsM",
    "user_ratings_total": 246.0,
    "geocode": [
      40.7297006,
      -73.9922318
    ]
  },
  {
    "Rest_Name": "55th + Park",
    "address": "60 E 55th St",
    "id": 15242,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJY8serWvTD4gRus8KbV7xzc8",
    "user_ratings_total": 245.0,
    "geocode": [
      40.7605465,
      -73.9727699
    ]
  },
  {
    "Rest_Name": "Penn Plaza",
    "address": "7 Pennsylvania Plaza",
    "id": 15243,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJ082aafdZwokR-QrAmtZfvgg",
    "user_ratings_total": 116.0,
    "geocode": [
      40.7489362,
      -73.992482
    ]
  },
  {
    "Rest_Name": "Downtown Brooklyn",
    "address": "100 Willoughby St.",
    "id": 15244,
    "type": "Sweetgreen",
    "rating": 2.4,
    "place_id": "ChIJA6bAoH1bwokRBQUbUdrjOiM",
    "user_ratings_total": 158.0,
    "geocode": [
      40.6920217,
      -73.9847946
    ]
  },
  {
    "Rest_Name": "Southlake",
    "address": "1111 East Southlake Boulevard",
    "id": 15245,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJNYKZpm_VTYYRzsoUQ2w5e6s",
    "user_ratings_total": 43.0,
    "geocode": [
      32.9406805,
      -97.1344312
    ]
  },
  {
    "Rest_Name": "Quarry Village",
    "address": "340 East Basse Road",
    "id": 15246,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJ3ZYzLXj1XIYRnF2BFediATs",
    "user_ratings_total": 81.0,
    "geocode": [
      29.4964003,
      -98.4785446
    ]
  },
  {
    "Rest_Name": "The Woodlands",
    "address": "9595 Six Pines Dr #1065",
    "id": 15247,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": null,
    "user_ratings_total": 126.0,
    "geocode": [
      30.164414,
      -95.464544
    ]
  },
  {
    "Rest_Name": "Cypress",
    "address": "9945 Barker Cypress Rd.",
    "id": 15248,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJh3ZmpBDXQIYRWsrsyIeuPz4",
    "user_ratings_total": 91.0,
    "geocode": [
      29.9223808,
      -95.6872981
    ]
  },
  {
    "Rest_Name": "Addison",
    "address": "5280 Belt Line Road",
    "id": 15249,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJw9RqwSwhTIYRFWlvUFfMLfs",
    "user_ratings_total": 51.0,
    "geocode": [
      32.953616,
      -96.8187376
    ]
  },
  {
    "Rest_Name": "38th + Broadway",
    "address": "1384 Broadway",
    "id": 15250,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJqfjlvKtZwokRjV2vOk_hyEE",
    "user_ratings_total": 220.0,
    "geocode": [
      40.752552,
      -73.987007
    ]
  },
  {
    "Rest_Name": "16th + Market",
    "address": "1601 Market St",
    "id": 15251,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJha9QwTHGxokRFVf1tLZeD7A",
    "user_ratings_total": 180.0,
    "geocode": [
      39.9530971,
      -75.1673521
    ]
  },
  {
    "Rest_Name": "KOP Mall",
    "address": "350 Mall Blvd Ste 3035",
    "id": 15252,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJgZqNrUqUxokR2c6-M5QFEo0",
    "user_ratings_total": 204.0,
    "geocode": [
      40.0883,
      -75.391531
    ]
  },
  {
    "Rest_Name": "Ardmore",
    "address": "68 Coulter Ave",
    "id": 15253,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJH3JeU4TAxokReM9aVw79_2o",
    "user_ratings_total": 224.0,
    "geocode": [
      40.00771,
      -75.2878798
    ]
  },
  {
    "Rest_Name": "Rittenhouse",
    "address": "1821 Chestnut St",
    "id": 15254,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJWdtPxDDGxokRKSZqA7cfEao",
    "user_ratings_total": 436.0,
    "geocode": [
      39.9520366,
      -75.171114
    ]
  },
  {
    "Rest_Name": "UPenn",
    "address": "3925 Walnut St",
    "id": 15255,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJCZkSAFjGxokRUYdwbKZ34RU",
    "user_ratings_total": 217.0,
    "geocode": [
      39.954141,
      -75.2017708
    ]
  },
  {
    "Rest_Name": "10th + Walnut",
    "address": "924 Walnut St",
    "id": 15256,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJP9jweCjGxokRcn9EjXcMAF8",
    "user_ratings_total": 351.0,
    "geocode": [
      39.9484173,
      -75.1569385
    ]
  },
  {
    "Rest_Name": "Wayne",
    "address": "223 E Lancaster Ave",
    "id": 15257,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJ2TAY-WKVxokRf3RlZ2kh8zY",
    "user_ratings_total": 67.0,
    "geocode": [
      40.044181,
      -75.3831126
    ]
  },
  {
    "Rest_Name": "Woodbury",
    "address": "7969 Jericho Turnpike",
    "id": 15258,
    "type": "Sweetgreen",
    "rating": 3.3,
    "place_id": "ChIJzfkurxiDwokRSLPb3MoIn5k",
    "user_ratings_total": 42.0,
    "geocode": [
      40.81563877,
      -73.47601475
    ]
  },
  {
    "Rest_Name": "Cranston",
    "address": "111 Hillside Rd",
    "id": 15259,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJo-sOI55P5IkRY11lyOqYGec",
    "user_ratings_total": 81.0,
    "geocode": [
      41.760195,
      -71.4590442
    ]
  },
  {
    "Rest_Name": "Clarendon",
    "address": "3100 Clarendon Blvd",
    "id": 15260,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJVWAqFoS2t4kRf9WsB2T-YT8",
    "user_ratings_total": 172.0,
    "geocode": [
      38.886345,
      -77.095489
    ]
  },
  {
    "Rest_Name": "Rosslyn",
    "address": "1800 N Lynn St",
    "id": 15261,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJidu0Slq2t4kRcHUJflRNa50",
    "user_ratings_total": 95.0,
    "geocode": [
      38.896549,
      -77.071262
    ]
  },
  {
    "Rest_Name": "One Loudoun",
    "address": "20347 Exchange St",
    "id": 15262,
    "type": "Sweetgreen",
    "rating": 3.2,
    "place_id": "ChIJX4cMwsk5tokR2nT7Xidq-lE",
    "user_ratings_total": 62.0,
    "geocode": [
      39.0531092,
      -77.4544958
    ]
  },
  {
    "Rest_Name": "Reston",
    "address": "1824 Library St",
    "id": 15263,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJZQEU1h5ItokRn58KR_tkqTs",
    "user_ratings_total": 275.0,
    "geocode": [
      38.958307,
      -77.359057
    ]
  },
  {
    "Rest_Name": "Pentagon City",
    "address": "575 12th Rd South, Suite B2",
    "id": 15264,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJW40zqyC3t4kRguqeNS1vP60",
    "user_ratings_total": 303.0,
    "geocode": [
      38.8625225,
      -77.0560884
    ]
  },
  {
    "Rest_Name": "Old Town",
    "address": "823 King St",
    "id": 15265,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJDwXgmfiwt4kRffXLe6XUtHg",
    "user_ratings_total": 621.0,
    "geocode": [
      38.8055659,
      -77.0489783
    ]
  },
  {
    "Rest_Name": "Ballston",
    "address": "4075 Wilson Blvd #C",
    "id": 15266,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": null,
    "user_ratings_total": 302.0,
    "geocode": [
      38.8802456,
      -77.1086156
    ]
  },
  {
    "Rest_Name": "Mosaic",
    "address": "2905 District Ave, Ste 185",
    "id": 15267,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJod9UtGRLtokRwXVIP783MVY",
    "user_ratings_total": 237.0,
    "geocode": [
      38.8733047,
      -77.2290159
    ]
  },
  {
    "Rest_Name": "Tysons Galleria",
    "address": "2001 International Dr",
    "id": 15268,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJ9f_bQ-xKtokRT1RQV02cqls",
    "user_ratings_total": 172.0,
    "geocode": [
      38.9242362,
      -77.2250154
    ]
  },
  {
    "Rest_Name": "Crystal City",
    "address": "2200 Crystal Dr, Ste E",
    "id": 15269,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJ8Q-VOi-3t4kRGc25NWIY2RM",
    "user_ratings_total": 181.0,
    "geocode": [
      38.8544038,
      -77.0496917
    ]
  },
  {
    "Rest_Name": "Encino",
    "address": "16403 Ventura Blvd., Suite D",
    "id": 15270,
    "type": "Sweetgreen",
    "rating": 3.3,
    "place_id": "ChIJsVOkxIuZwoARLwsvP0U_N3Y",
    "user_ratings_total": 44.0,
    "geocode": [
      34.1574614,
      -118.4904009
    ]
  },
  {
    "Rest_Name": "Manhattan Beach",
    "address": "3200 N Sepulveda Blvd, Space #E18",
    "id": 15271,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": null,
    "user_ratings_total": 94.0,
    "geocode": [
      33.8995437,
      -118.394016
    ]
  },
  {
    "Rest_Name": "Greenbrae",
    "address": "284 Bon Air Center",
    "id": 15272,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJi-a4QEibhYARKXebEynXwts",
    "user_ratings_total": 41.0,
    "geocode": [
      37.9455557,
      -122.5238174
    ]
  },
  {
    "Rest_Name": "Walnut Creek",
    "address": "1556 Mt Diablo Blvd",
    "id": 15273,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ_-IxtG1hhYARVX0T2kL63Ok",
    "user_ratings_total": 61.0,
    "geocode": [
      37.8978156,
      -122.0612394
    ]
  },
  {
    "Rest_Name": "Rancho Cucamonga",
    "address": "7811 Monet Ave",
    "id": 15274,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJo6nEJaNLw4ARLFVRt4Orjv8",
    "user_ratings_total": 47.0,
    "geocode": [
      34.1115443,
      -117.5341977
    ]
  },
  {
    "Rest_Name": "4S Commons",
    "address": "10562 Craftsman Way #192",
    "id": 15275,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": null,
    "user_ratings_total": 22.0,
    "geocode": [
      33.0195592,
      -117.11055
    ]
  },
  {
    "Rest_Name": "Westlake Village",
    "address": "100 Promenade Way, Unit A",
    "id": 15276,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJjUXFfUIl6IARVNdv_PaOSDc",
    "user_ratings_total": 62.0,
    "geocode": [
      34.1592762,
      -118.8203519
    ]
  },
  {
    "Rest_Name": "La Brea",
    "address": "180 S La Brea",
    "id": 15277,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJVVWFVti4woARB1lbo3_bNmc",
    "user_ratings_total": 195.0,
    "geocode": [
      34.0713323,
      -118.3436832
    ]
  },
  {
    "Rest_Name": "Century City",
    "address": "10250 Santa Monica Blvd",
    "id": 15278,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJAwAAjIy7woARxsARaK_VvFQ",
    "user_ratings_total": 127.0,
    "geocode": [
      34.0590031,
      -118.418866
    ]
  },
  {
    "Rest_Name": "Silver Lake",
    "address": "3925 Sunset Blvd",
    "id": 15279,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJGctuIfjHwoARyhjQACcJ4Fg",
    "user_ratings_total": 212.0,
    "geocode": [
      34.0925725,
      -118.2800926
    ]
  },
  {
    "Rest_Name": "San Mateo",
    "address": "1 East 3rd Ave",
    "id": 15280,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJI2THxnGej4ARZ8lUgarD0UU",
    "user_ratings_total": 376.0,
    "geocode": [
      37.5630642,
      -122.3259146
    ]
  },
  {
    "Rest_Name": "Soma",
    "address": "171 2nd St",
    "id": 15281,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJeRWme60sDogRKMsPP2a2bsI",
    "user_ratings_total": 573.0,
    "geocode": [
      37.7872658,
      -122.3985428
    ]
  },
  {
    "Rest_Name": "Beverly + Wilshire",
    "address": "251 N Beverly Dr",
    "id": 15282,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJM961C5m9woARbr3W9o2j-2s",
    "user_ratings_total": 41.0,
    "geocode": [
      34.0680965,
      -118.4003664
    ]
  },
  {
    "Rest_Name": "Training Room",
    "address": "430 South Capitol Street SE",
    "id": 15283,
    "type": "Sweetgreen",
    "rating": null,
    "place_id": "ChIJiarI6Cq4t4kR8sHuqviZDk4",
    "user_ratings_total": null,
    "geocode": [
      38.8840494,
      -77.0086303
    ]
  },
  {
    "Rest_Name": "Hyde Park",
    "address": "1500 East 53rd St.",
    "id": 15284,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJF4ZN0_YpDogR8CZyWQGB4iA",
    "user_ratings_total": 207.0,
    "geocode": [
      41.7998079,
      -87.59104392
    ]
  },
  {
    "Rest_Name": "Gold Coast",
    "address": "2 W Division St",
    "id": 15285,
    "type": "Sweetgreen",
    "rating": 3.0,
    "place_id": "ChIJdzfMWlzTD4gRt1qaDfCVZ2Q",
    "user_ratings_total": 40.0,
    "geocode": [
      41.9040733,
      -87.62889467
    ]
  },
  {
    "Rest_Name": "Chestnut Hill",
    "address": "210 Boylston St",
    "id": 15286,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJj8wA2pZ444kRlwZ1t9R8fmk",
    "user_ratings_total": 304.0,
    "geocode": [
      42.3196738,
      -71.1771344
    ]
  },
  {
    "Rest_Name": "Legacy Place",
    "address": "244 Legacy Pl",
    "id": 15287,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJW1rIh-V_44kRqajFj6WN_p4",
    "user_ratings_total": 281.0,
    "geocode": [
      42.2320161,
      -71.1772387
    ]
  },
  {
    "Rest_Name": "Burlington Mall",
    "address": "85 Middlesex Turnpike",
    "id": 15288,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJR4SWF1yf44kRuuk7j60Bpw8",
    "user_ratings_total": 228.0,
    "geocode": [
      42.4843196,
      -71.2168111
    ]
  },
  {
    "Rest_Name": "Seaport Square",
    "address": "47 Northern Ave",
    "id": 15289,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJNwzr4n5w44kRTQLYt6f6NTA",
    "user_ratings_total": 140.0,
    "geocode": [
      42.3531477,
      -71.0465706
    ]
  },
  {
    "Rest_Name": "60 State",
    "address": "60 State St",
    "id": 15290,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJPV8P0oVw44kRrsrB-5wblfc",
    "user_ratings_total": 177.0,
    "geocode": [
      42.35961031,
      -71.05632305
    ]
  },
  {
    "Rest_Name": "Beacon Hill",
    "address": "300 Cambridge Street",
    "id": 15291,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJAVbkVuFx44kRbehbFNn2XzM",
    "user_ratings_total": 29.0,
    "geocode": [
      42.36149934,
      -71.06882292
    ]
  },
  {
    "Rest_Name": "Fenway",
    "address": "132 Brookline Ave",
    "id": 15292,
    "type": "Sweetgreen",
    "rating": 4.4,
    "place_id": "ChIJk_cXKvR544kRv3E4WY1NRNo",
    "user_ratings_total": 301.0,
    "geocode": [
      42.34549904,
      -71.10039201
    ]
  },
  {
    "Rest_Name": "Post Office Square",
    "address": "225 Franklin St",
    "id": 15293,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJa2h9NoFw44kRtkeudgjVNjE",
    "user_ratings_total": 65.0,
    "geocode": [
      42.35657487,
      -71.05386822
    ]
  },
  {
    "Rest_Name": "Garden City",
    "address": "191 7th St",
    "id": 15294,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJoQnGZqN9wokRB6L8bWq-y3U",
    "user_ratings_total": 80.0,
    "geocode": [
      40.7255197,
      -73.6345804
    ]
  },
  {
    "Rest_Name": "75th + Amsterdam",
    "address": "311 Amsterdam Ave",
    "id": 15295,
    "type": "Sweetgreen",
    "rating": 4.1,
    "place_id": "ChIJ74_-pV3TD4gRJZnF7aSwSOs",
    "user_ratings_total": 188.0,
    "geocode": [
      40.7802668,
      -73.9802091
    ]
  },
  {
    "Rest_Name": "71st + 1st",
    "address": "1321 1st Ave",
    "id": 15296,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJwW2TRC4tDogRRRY8bsYnGGA",
    "user_ratings_total": 78.0,
    "geocode": [
      40.767093,
      -73.9568348
    ]
  },
  {
    "Rest_Name": "91st + Broadway",
    "address": "2460 Broadway",
    "id": 15297,
    "type": "Sweetgreen",
    "rating": 3.6,
    "place_id": "ChIJXRro8IJYwokRC0WZS8rrUhw",
    "user_ratings_total": 370.0,
    "geocode": [
      40.79144,
      -73.97346
    ]
  },
  {
    "Rest_Name": "44th + 3rd",
    "address": "155 E 44th St.",
    "id": 15298,
    "type": "Sweetgreen",
    "rating": 3.8,
    "place_id": "ChIJiz2jlzBZwokRncA26QdtDwo",
    "user_ratings_total": 61.0,
    "geocode": [
      40.7524739,
      -73.9739429
    ]
  },
  {
    "Rest_Name": "Capitol Hill",
    "address": "221 Pennsylvania Ave SE",
    "id": 15299,
    "type": "Sweetgreen",
    "rating": 4.5,
    "place_id": "ChIJ2ewnbrG3t4kRsUOhGHHlO40",
    "user_ratings_total": 487.0,
    "geocode": [
      38.886959,
      -77.002752
    ]
  },
  {
    "Rest_Name": "Court + Atlantic",
    "address": "127 Court St",
    "id": 15300,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJh4RH-sxbwokRTGcRcVQZkk0",
    "user_ratings_total": 320.0,
    "geocode": [
      40.6899104,
      -73.9920039
    ]
  },
  {
    "Rest_Name": "32nd + Park",
    "address": "2 Park Ave",
    "id": 15301,
    "type": "Sweetgreen",
    "rating": 2.6,
    "place_id": "ChIJ52UEOtBZwokRjOxLoNcHbYA",
    "user_ratings_total": 164.0,
    "geocode": [
      40.7462517,
      -73.98266
    ]
  },
  {
    "Rest_Name": "56th + Broadway",
    "address": "1740 Broadway",
    "id": 15302,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJZ0o0BCVZwokRLxrj7edLWM8",
    "user_ratings_total": 168.0,
    "geocode": [
      40.7651145,
      -73.9814962
    ]
  },
  {
    "Rest_Name": "52nd + Lex",
    "address": "599 Lexington Ave",
    "id": 15303,
    "type": "Sweetgreen",
    "rating": 3.2,
    "place_id": "ChIJJTbJmShZwokRFOyR0AwVtWA",
    "user_ratings_total": 57.0,
    "geocode": [
      40.757962,
      -73.9708356
    ]
  },
  {
    "Rest_Name": "Chappaqua",
    "address": "480 N Bedford Rd Suite #D-5",
    "id": 15304,
    "type": "Sweetgreen",
    "rating": 3.2,
    "place_id": null,
    "user_ratings_total": 42.0,
    "geocode": [
      41.18840042,
      -73.75855791
    ]
  },
  {
    "Rest_Name": "Huntington",
    "address": "160 Walt Whitman Rd Space 1021C",
    "id": 15305,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJkUCmh-sp6IkROkEUHbWqaV0",
    "user_ratings_total": 15.0,
    "geocode": [
      40.8209628,
      -73.41025725
    ]
  },
  {
    "Rest_Name": "Vernon Hills",
    "address": "696 White Plains Rd",
    "id": 15306,
    "type": "Sweetgreen",
    "rating": 3.0,
    "place_id": "ChIJZ7aROYmTwokRc95v_J8fw6o",
    "user_ratings_total": 141.0,
    "geocode": [
      40.97055246,
      -73.80597227
    ]
  },
  {
    "Rest_Name": "Long Island City",
    "address": "25-35 44th Drive",
    "id": 15307,
    "type": "Sweetgreen",
    "rating": 2.7,
    "place_id": "ChIJZfxRn-ZZwokRcbHPm0l19n0",
    "user_ratings_total": 234.0,
    "geocode": [
      40.7474421,
      -73.9425356
    ]
  },
  {
    "Rest_Name": "Bryant Park",
    "address": "1114 6th Ave, New York, NY 10036",
    "id": 15308,
    "type": "Sweetgreen",
    "rating": 3.7,
    "place_id": "ChIJNaraBgBZwokR8gUieY3X5ys",
    "user_ratings_total": 295.0,
    "geocode": [
      40.75527382,
      -73.9831017
    ]
  },
  {
    "Rest_Name": "Grand Central",
    "address": "230 Park Ave",
    "id": 15309,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJ3Zb9y09ZwokRb8Oecb9bZPA",
    "user_ratings_total": 38.0,
    "geocode": [
      40.75512676,
      -73.97672375
    ]
  },
  {
    "Rest_Name": "Hudson Yards",
    "address": "10 Hudson Yards",
    "id": 15310,
    "type": "Sweetgreen",
    "rating": 3.4,
    "place_id": "ChIJYZVcaZNZwokRuApYl7QMEYc",
    "user_ratings_total": 268.0,
    "geocode": [
      40.75317779,
      -74.00084121
    ]
  },
  {
    "Rest_Name": "Murray Hill",
    "address": "606 1st Ave.",
    "id": 15311,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJFR4I7aRZwokROPq_sLEdPn4",
    "user_ratings_total": 177.0,
    "geocode": [
      40.74468154,
      -73.9729059
    ]
  },
  {
    "Rest_Name": "Nolita",
    "address": "100 Kenmare St",
    "id": 15312,
    "type": "Sweetgreen",
    "rating": 4.3,
    "place_id": "ChIJfbwZ54hZwokRwnXArxBfP4A",
    "user_ratings_total": 564.0,
    "geocode": [
      40.72165601,
      -73.99715342
    ]
  },
  {
    "Rest_Name": "NoMad",
    "address": "1164 Broadway",
    "id": 15313,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJFVcaC6ZZwokREjt0f0rv4Xg",
    "user_ratings_total": 931.0,
    "geocode": [
      40.74537219,
      -73.9886378
    ]
  },
  {
    "Rest_Name": "Rockefeller Center",
    "address": "Rockefeller Plaza",
    "id": 15314,
    "type": "Sweetgreen",
    "rating": 2.8,
    "place_id": "ChIJs1SpeDJZwokR5KJQb9zRB1A",
    "user_ratings_total": 90.0,
    "geocode": [
      40.7592468,
      -73.97893225
    ]
  },
  {
    "Rest_Name": "Tribeca",
    "address": "125 Chambers St",
    "id": 15315,
    "type": "Sweetgreen",
    "rating": 4.0,
    "place_id": "ChIJ5SYtXCNbwokRNa8EaDbaDMA",
    "user_ratings_total": 232.0,
    "geocode": [
      40.71603244,
      -74.00900776
    ]
  },
  {
    "Rest_Name": "Wall Street",
    "address": "67 Wall St",
    "id": 15316,
    "type": "Sweetgreen",
    "rating": 4.2,
    "place_id": "ChIJ2zcGahZawokR7UhfvrCS3LQ",
    "user_ratings_total": 620.0,
    "geocode": [
      40.70574226,
      -74.00812194
    ]
  },
  {
    "Rest_Name": "Williamsburg",
    "address": "162 N 4th St",
    "id": 15317,
    "type": "Sweetgreen",
    "rating": 3.9,
    "place_id": "ChIJ32ZRGF5ZwokR9Bud7H3e4QM",
    "user_ratings_total": 669.0,
    "geocode": [
      40.71636454,
      -73.95926623
    ]
  },
  {
    "Rest_Name": "World Trade Center",
    "address": "3 World ztrade Center Space 13000",
    "id": 15318,
    "type": "Sweetgreen",
    "rating": 3.1,
    "place_id": "ChIJPel3BMlbwokReeh6l2Jw_20",
    "user_ratings_total": 77.0,
    "geocode": [
      40.71190038,
      -74.01179158
    ]
  }
      ,
      {
        "geocode": [
          61.1430667,
          -149.8474723
        ],
        "Rest_Name": "Abbott",
        "address": "1900 E 85th Ct",
        "id": "20000",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          61.1931932,
          -149.8702453
        ],
        "Rest_Name": "Benson",
        "address": "702 E Benson Blvd",
        "id": "20001",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJZccwwreXyFYRc5KKGrPY_Bo",
        "user_ratings_total": 687
      },
      {
        "geocode": [
          61.22709,
          -149.73981
        ],
        "Rest_Name": "Muldoon",
        "address": "1102 N Muldoon Rd",
        "id": "20002",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ4SXe7VS9BYgRZGjEOtUrFqk",
        "user_ratings_total": 381
      },
      {
        "geocode": [
          61.5781,
          -149.41159
        ],
        "Rest_Name": "Wasilla",
        "address": "1515 E Parks Hwy",
        "id": "20003",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJuS_VohneyFYR0KieKnif3tQ",
        "user_ratings_total": 665
      },
      {
        "geocode": [
          31.33673197,
          -85.71282667
        ],
        "Rest_Name": "Fort Rucker\r\n",
        "address": "9214 Hutton Plz",
        "id": "20004",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJXUoP7qllkogR8Zb6ejmZ2iE",
        "user_ratings_total": 13
      },
      {
        "geocode": [
          32.37522622,
          -86.35462376
        ],
        "Rest_Name": "Maxwell AFB",
        "address": "355 S Kelly StMain PX Food Court Bldg 1090",
        "id": "20005",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          35.81671,
          -90.66997
        ],
        "Rest_Name": "Parkwood",
        "address": "2935 Parkwood Rd",
        "id": "20006",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          36.1230416,
          -94.1490572
        ],
        "Rest_Name": "Joyce",
        "address": "637 E Joyce Blvd",
        "id": "20007",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          36.3117911,
          -94.1784952
        ],
        "Rest_Name": "Promenade",
        "address": "2005 Promenade Blvd",
        "id": "20008",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          33.513437,
          -112.13071
        ],
        "Rest_Name": "Grand Canyon University",
        "address": "3300 W Camelback Rd. Thunder Alley Bldg",
        "id": "20009",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          33.414947,
          -111.932874
        ],
        "Rest_Name": "ASU Tempe",
        "address": "1290 S Normal Ave Memorial Union Building",
        "id": "20010",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJezLLbMMIK4cRThp6K35v5q4",
        "user_ratings_total": 292
      },
      {
        "geocode": [
          32.18361424,
          -110.8767871
        ],
        "Rest_Name": "Davis Monthan",
        "address": "5405 Granite St #2527",
        "id": "20011",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          35.17805803,
          -111.6554371
        ],
        "Rest_Name": "Northern Arizona",
        "address": "306 E Pine Knoll Dr",
        "id": "20012",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJa3oianqPLYcRi7nqhLuzcrk",
        "user_ratings_total": 59
      },
      {
        "geocode": [
          33.6092878,
          -112.1607606
        ],
        "Rest_Name": "ASU West Campus",
        "address": "4701 West Thunderbird Rd",
        "id": "20013",
        "type": "Qdoba",
        "rating": 2.6,
        "place_id": "ChIJ7dZJf81pK4cRfskNDdV9p1Y",
        "user_ratings_total": 12
      },
      {
        "geocode": [
          33.30742184,
          -111.676599
        ],
        "Rest_Name": "ASU Polytechnical",
        "address": "7001 E. Williams Field Rd.",
        "id": "20014",
        "type": "Qdoba",
        "rating": 2.9,
        "place_id": "ChIJ5Tu7iK6tK4cR2FyVZMbQSLs",
        "user_ratings_total": 17
      },
      {
        "geocode": [
          33.48331085,
          -112.0893566
        ],
        "Rest_Name": "PHOENIX COLLEGE",
        "address": "1202 W Thomas RdHannelly Student Center",
        "id": "20015",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          33.2894,
          -117.30573
        ],
        "Rest_Name": "Camp Pendleton",
        "address": "Building 20845 Suite 110",
        "id": "20016",
        "type": "Qdoba",
        "rating": 3.2,
        "place_id": "ChIJX0ZhHv9v3IARVZGWd6c7zdE",
        "user_ratings_total": 25
      },
      {
        "geocode": [
          32.7708356,
          -117.1497382
        ],
        "Rest_Name": "Mission Valley",
        "address": "1620C Camino De La Reina",
        "id": "20017",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJC6QwWLdV2YARSdFA-rBcD3Y",
        "user_ratings_total": 232
      },
      {
        "geocode": [
          32.73239,
          -117.204452
        ],
        "Rest_Name": "San Diego Airport",
        "address": "3225 North Harbor Dr Terminal TW2",
        "id": "20018",
        "type": "Qdoba",
        "rating": 2.3,
        "place_id": "ChIJJ9vNzcCr3oARaDkUednXHJg",
        "user_ratings_total": 88
      },
      {
        "geocode": [
          34.050362,
          -117.820442
        ],
        "Rest_Name": "Cal Poly",
        "address": "3801 W Temple Ave Bldg 35 Bronco Student Ctr",
        "id": "20019",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJxbrhE6guw4ARE-6R0XGhyj8",
        "user_ratings_total": 72
      },
      {
        "geocode": [
          33.792832,
          -117.850268
        ],
        "Rest_Name": "Chapman University",
        "address": "One University Dr",
        "id": "20020",
        "type": "Qdoba",
        "rating": 3.2,
        "place_id": "ChIJXVuHRuDZ3IARk6BNIUpFJZQ",
        "user_ratings_total": 46
      },
      {
        "geocode": [
          33.678112,
          -117.862515
        ],
        "Rest_Name": "John Wayne Airport",
        "address": "18601 Airport Way Terminal B Food Court",
        "id": "20021",
        "type": "Qdoba",
        "rating": 2.8,
        "place_id": "ChIJq6ofD5Lf3IARD-4seyZeCnU",
        "user_ratings_total": 37
      },
      {
        "geocode": [
          37.9174263,
          -122.0375139
        ],
        "Rest_Name": "Walnut Creek",
        "address": "1815 - B Ygnacio Valley Road",
        "id": "20022",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJZaM8O-NhhYARy6rIfMO8WPI",
        "user_ratings_total": 21
      },
      {
        "geocode": [
          33.97027904,
          -118.4165896
        ],
        "Rest_Name": "Loyola Marymount",
        "address": "1 Loyola Marymount University",
        "id": "20023",
        "type": "Qdoba",
        "rating": 3.2,
        "place_id": "ChIJVz37HE2xwoARvU_7iQhkkEI",
        "user_ratings_total": 18
      },
      {
        "geocode": [
          34.23477235,
          -118.5549268
        ],
        "Rest_Name": "CSU Northridge",
        "address": "1811 Nordhoff St",
        "id": "20024",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ0fnhnAqbwoARMlJiHFp4HEE",
        "user_ratings_total": 465
      },
      {
        "geocode": [
          33.13056825,
          -117.1592864
        ],
        "Rest_Name": "CSU San Marcos",
        "address": "333 S Twin Oaks Valley Rd",
        "id": "20025",
        "type": "Qdoba",
        "rating": 3,
        "place_id": "ChIJ1eLy1Zv124ARjYeOK3CL-mw",
        "user_ratings_total": 2
      },
      {
        "geocode": [
          38.26942685,
          -121.9503735
        ],
        "Rest_Name": "Travis AFB",
        "address": "461 SkyMaster CircleBuilding 648",
        "id": "20026",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJN39QFDw_hYARt7Osg9afKsk",
        "user_ratings_total": 25
      },
      {
        "geocode": [
          34.24037938,
          116.062798
        ],
        "Rest_Name": "MCAGCC",
        "address": "Building 1024",
        "id": "20027",
        "type": "Qdoba",
        "rating": null,
        "place_id": "ChIJP0V8jxnJ2oARBXuBLKdrEtc",
        "user_ratings_total": null
      },
      {
        "geocode": [
          38.4564797,
          -107.8655603
        ],
        "Rest_Name": "Montrose",
        "address": "2620 Woodgate Rd",
        "id": "20028",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJW26TXnFQP4cRN5SuJZQmjsk",
        "user_ratings_total": 308
      },
      {
        "geocode": [
          39.15215,
          -108.7368
        ],
        "Rest_Name": "Fruita",
        "address": "401 Jurassic Ave",
        "id": "20029",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJSZu5FnH_RocR-8NpxufKXAk",
        "user_ratings_total": 196
      },
      {
        "geocode": [
          39.07901,
          -108.58266
        ],
        "Rest_Name": "Rimrock",
        "address": "2536 Rimrock Dr",
        "id": "20030",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJa3oianqPLYcRi7nqhLuzcrk",
        "user_ratings_total": 59
      },
      {
        "geocode": [
          39.09038,
          -108.55208
        ],
        "Rest_Name": "12th & Patterson",
        "address": "2510 N 12th St",
        "id": "20031",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          39.0887703,
          -108.4591799
        ],
        "Rest_Name": "Clifton",
        "address": "592 32nd Rd",
        "id": "20032",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ2RavOhEfR4cRvSNOoBcy0d0",
        "user_ratings_total": 575
      },
      {
        "geocode": [
          39.5511211,
          -107.32673
        ],
        "Rest_Name": "Glenwood",
        "address": "510 Laurel St",
        "id": "20033",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ9QF_HZoJQYcRpcTV8vEzCOQ",
        "user_ratings_total": 812
      },
      {
        "geocode": [
          39.63033,
          -106.41867
        ],
        "Rest_Name": "Frontage",
        "address": "2161 N Frontage Rd W",
        "id": "20034",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ_SNGvKJwaocRX80SRoMvfAs",
        "user_ratings_total": 370
      },
      {
        "geocode": [
          38.7653445,
          -104.783526
        ],
        "Rest_Name": "25 & S Academy",
        "address": "4451 Venetucci Blvd",
        "id": "20035",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJiUBjnL1DE4cR6Q8HWo9xbkU",
        "user_ratings_total": 1009
      },
      {
        "geocode": [
          38.8044832,
          -104.8191765
        ],
        "Rest_Name": "Southgate & Nevada",
        "address": "1916 Southgate Rd",
        "id": "20036",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.8971665,
          -104.8337062
        ],
        "Rest_Name": "Garden of the Gods",
        "address": "536 W Garden of the Gods Rd",
        "id": "20037",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJEdNq2M9PE4cRJjPrNpyb-WQ",
        "user_ratings_total": 633
      },
      {
        "geocode": [
          38.8912446,
          -104.7592496
        ],
        "Rest_Name": "Austin Bluffs & Academy",
        "address": "3670 Austin Bluffs Pkwy",
        "id": "20038",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJr6c4kUhPE4cRTpG90PhpAxc",
        "user_ratings_total": 513
      },
      {
        "geocode": [
          38.8854358,
          -104.7171025
        ],
        "Rest_Name": "Bloomington & Carefree",
        "address": "3709 Bloomington St",
        "id": "20039",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJr6mkZ8JmbIgRnDKxAWikkPw",
        "user_ratings_total": 323
      },
      {
        "geocode": [
          38.9660608,
          -104.7986465
        ],
        "Rest_Name": "Shops at Briargate",
        "address": "1605 Briargate Pkwy",
        "id": "20040",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJc83qADNME4cRWjqPvup-Nfc",
        "user_ratings_total": 444
      },
      {
        "geocode": [
          39.0663607,
          -104.849133
        ],
        "Rest_Name": "NEC Jackson Ck",
        "address": "16010 Old Forest Point",
        "id": "20041",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          40.460806,
          -106.8226109
        ],
        "Rest_Name": "Steamboat",
        "address": "1755 Central Park Dr",
        "id": "20042",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJl4m7AjVpQocRUrZwUiODZjw",
        "user_ratings_total": 380
      },
      {
        "geocode": [
          39.54364,
          -105.2896
        ],
        "Rest_Name": "Conifer",
        "address": "25597 Conifer Rd",
        "id": "20043",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ9XtpzMl2a4cRFtDrNO82Rng",
        "user_ratings_total": 414
      },
      {
        "geocode": [
          39.698119,
          -105.3297208
        ],
        "Rest_Name": "Hotel Way",
        "address": "29057 Hotel Way",
        "id": "20044",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.4130031,
          -104.8736018
        ],
        "Rest_Name": "Outlets at Castle Rock",
        "address": "955 New Memphis Ct",
        "id": "20045",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJqxdDMJSZbIcRsKAfKZsuSmo",
        "user_ratings_total": 589
      },
      {
        "geocode": [
          39.5634951,
          -105.1075013
        ],
        "Rest_Name": "C-470/Kipling",
        "address": "9956 W Remington Pl",
        "id": "20046",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJrUh6SqF-a4cROkIcHD3PiYg",
        "user_ratings_total": 410
      },
      {
        "geocode": [
          39.411782,
          -104.862504
        ],
        "Rest_Name": "Founder's Pkwy & Allen",
        "address": "78 E Allen St",
        "id": "20047",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          39.5478276,
          -105.0000231
        ],
        "Rest_Name": "Dorchester",
        "address": "9338 S Dorchester St",
        "id": "20048",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          39.60681,
          -105.09033
        ],
        "Rest_Name": "Chanson Plaza",
        "address": "8246 W Bowles Ave",
        "id": "20049",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJT6o7NwN_a4cRrVCskVlmatA",
        "user_ratings_total": 465
      },
      {
        "geocode": [
          39.58638,
          -105.0258
        ],
        "Rest_Name": "Aspen Grove Mall",
        "address": "7301 S Santa Fe Dr",
        "id": "20050",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJWSphduSBbIcR_Yzklz4wOb4",
        "user_ratings_total": 280
      },
      {
        "geocode": [
          39.5445769,
          -104.9423256
        ],
        "Rest_Name": "Highlands Ranch & Colorado",
        "address": "9364 S Colorado Blvd",
        "id": "20051",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJFaVqqIGDbIcRA5f7u0d9cV0",
        "user_ratings_total": 301
      },
      {
        "geocode": [
          39.5352225,
          -104.8807629
        ],
        "Rest_Name": "Lincoln & I-25",
        "address": "9994 Commons St",
        "id": "20052",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.59468,
          -104.96074
        ],
        "Rest_Name": "University & Arapahoe",
        "address": "6703 S University Blvd",
        "id": "20053",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJCxOqw0KBbIcRx6weQHe4d_g",
        "user_ratings_total": 230
      },
      {
        "geocode": [
          39.5115349,
          -104.7665318
        ],
        "Rest_Name": "Twenty Mile Rd. & Pavilion Dr.",
        "address": "11215 S Parker Rd",
        "id": "20054",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJqSB2kuKRbIcRHZFSKcXiAkE",
        "user_ratings_total": 459
      },
      {
        "geocode": [
          39.56417,
          -104.8854
        ],
        "Rest_Name": "Yosemite & County Line",
        "address": "8441 S Yosemite St",
        "id": "20055",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJOTiuQrGFbIcRyz9n9mRh-3A",
        "user_ratings_total": 457
      },
      {
        "geocode": [
          39.5944719,
          -104.7990551
        ],
        "Rest_Name": "Foxfield",
        "address": "16270 E Arapahoe Rd",
        "id": "20056",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJB7Mx2imPbIcRgdBsfT-lPbM",
        "user_ratings_total": 565
      },
      {
        "geocode": [
          39.5946331,
          -104.8608115
        ],
        "Rest_Name": "Lima",
        "address": "10909 E Arapahoe Pl",
        "id": "20057",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJY4PotwmGbIcRa5XMJBfbtcI",
        "user_ratings_total": 386
      },
      {
        "geocode": [
          39.595361,
          -104.9023805
        ],
        "Rest_Name": "Arapahoe & Quebec",
        "address": "7465 E Arapahoe Rd",
        "id": "20058",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ_7fc4ISGbIcRPd8gz7KS69o",
        "user_ratings_total": 373
      },
      {
        "geocode": [
          39.6009648,
          -104.7099081
        ],
        "Rest_Name": "Southlands Shopping Mall",
        "address": "23923 E Prospect Ave",
        "id": "20059",
        "type": "Qdoba",
        "rating": 3.2,
        "place_id": "ChIJHeY8C2OMbIcRHPg8aeSpvUA",
        "user_ratings_total": 514
      },
      {
        "geocode": [
          39.695605,
          -104.789253
        ],
        "Rest_Name": "Buckley and Mississippi",
        "address": "1180 S Buckley Rd",
        "id": "20060",
        "type": "Qdoba",
        "rating": 3.3,
        "place_id": "ChIJvxo5PoD7DogR_wAklOgIMck",
        "user_ratings_total": 21
      },
      {
        "geocode": [
          39.736059,
          -105.1619891
        ],
        "Rest_Name": "Colfax and Denver West Blvd",
        "address": "14231 W Colfax Ave",
        "id": "20061",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ843F4Ax8bIcRerlHDYjYJ64",
        "user_ratings_total": 418
      },
      {
        "geocode": [
          39.73205,
          -105.15805
        ],
        "Rest_Name": "Foodcourt of Colorado Mills Mall",
        "address": "14500 W Colfax Ave",
        "id": "20062",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJZwIN3GiEa4cRdQ3cggv-3yk",
        "user_ratings_total": 381
      },
      {
        "geocode": [
          39.7094062,
          -105.1353522
        ],
        "Rest_Name": "Alameda & Union",
        "address": "12027 W Alameda Pkwy",
        "id": "20063",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.81289,
          -105.16246
        ],
        "Rest_Name": "64th & Indiana",
        "address": "14575 West 64th Ave",
        "id": "20064",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJIVdZXnKPa4cRwx0tCveRMA8",
        "user_ratings_total": 399
      },
      {
        "geocode": [
          40.0153594,
          -105.2589612
        ],
        "Rest_Name": "28th St. & Arapahoe",
        "address": "1625 28th St",
        "id": "20065",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJVZypJDlNGIgR8GrBQyFY2z0",
        "user_ratings_total": 1210
      },
      {
        "geocode": [
          39.78898,
          -105.11
        ],
        "Rest_Name": "50th and Kipling",
        "address": "5095 Kipling St",
        "id": "20066",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJndDuPRGGa4cR9vBtXbWCjI8",
        "user_ratings_total": 567
      },
      {
        "geocode": [
          39.7405663,
          -105.0820602
        ],
        "Rest_Name": "Colfax and Wadsworth",
        "address": "7611 W Colfax Ave",
        "id": "20067",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.6224512,
          -104.987489
        ],
        "Rest_Name": "Broadway & Belleview",
        "address": "5188 S Broadway",
        "id": "20068",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.6547911,
          -104.9965771
        ],
        "Rest_Name": "Santa Fe & Englewood Pkwy",
        "address": "800 Englewood Pkwy",
        "id": "20069",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.7399128,
          -104.9170746
        ],
        "Rest_Name": "Colfax & Krameria",
        "address": "6110 E Colfax Ave",
        "id": "20070",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ843F4Ax8bIcRerlHDYjYJ64",
        "user_ratings_total": 418
      },
      {
        "geocode": [
          39.7893805,
          -104.9400496
        ],
        "Rest_Name": "40th & Colorado",
        "address": "4000 N Colorado Blvd",
        "id": "20071",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJRUIKjNd7bIcRN06ZP_bmuW8",
        "user_ratings_total": 695
      },
      {
        "geocode": [
          39.7863,
          -104.88214
        ],
        "Rest_Name": "Northfield Commons",
        "address": "9135 E Northfield Blvd",
        "id": "20072",
        "type": "Qdoba",
        "rating": 3.1,
        "place_id": "ChIJdTtwcVl7bIcRYSsbt_cGWYo",
        "user_ratings_total": 102
      },
      {
        "geocode": [
          39.7428649,
          -104.9692324
        ],
        "Rest_Name": "17th & Humboldt",
        "address": "1400 E 17th Ave",
        "id": "20073",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJF6CudTN5bIcR8waaBOF-gt4",
        "user_ratings_total": 597
      },
      {
        "geocode": [
          39.72476,
          -104.98309
        ],
        "Rest_Name": "5th & Grant",
        "address": "550 Grant St",
        "id": "20074",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.749619,
          -104.9991239
        ],
        "Rest_Name": "15th & Market",
        "address": "1533 Market St",
        "id": "20075",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.74647,
          -105.001893
        ],
        "Rest_Name": "CU Denver",
        "address": "1201 Larimer St Academic Bldg One",
        "id": "20076",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJKbwn2ch4bIcRPFSsVCi-A_8",
        "user_ratings_total": 105
      },
      {
        "geocode": [
          39.6955488,
          -104.9401322
        ],
        "Rest_Name": "Colorado & Mississippi",
        "address": "1156 S Colorado Blvd",
        "id": "20077",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJxcvYQnh-bIcR_j2TqxN5Vaw",
        "user_ratings_total": 633
      },
      {
        "geocode": [
          39.69332,
          -104.86503
        ],
        "Rest_Name": "Mississippi & Havana",
        "address": "10550 E Garden Drive",
        "id": "20078",
        "type": "Qdoba",
        "rating": 2,
        "place_id": "ChIJcUDmvfR7gIgRPquD97wQrg0",
        "user_ratings_total": 21
      },
      {
        "geocode": [
          39.7736869,
          -104.8087555
        ],
        "Rest_Name": "Chambers & I-70",
        "address": "15470 Andrews Dr",
        "id": "20079",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJu-QHt49kbIcRKWcz3aYSiMQ",
        "user_ratings_total": 1176
      },
      {
        "geocode": [
          39.6755312,
          -104.8477368
        ],
        "Rest_Name": "Iliff & Peoria",
        "address": "2295 S Peoria",
        "id": "20080",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.6535691,
          -104.9053294
        ],
        "Rest_Name": "Hampden & I-25",
        "address": "7155 E Hampden Ave",
        "id": "20081",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJvYCcdm6HbIcRlD-qih7jjbA",
        "user_ratings_total": 570
      },
      {
        "geocode": [
          39.8781198,
          -104.9832264
        ],
        "Rest_Name": "100th & Grant",
        "address": "10018 Grant St",
        "id": "20082",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.627025,
          -104.8954056
        ],
        "Rest_Name": "Ulster St.",
        "address": "4955 S Ulster St",
        "id": "20083",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          39.86696,
          -105.05391
        ],
        "Rest_Name": "92nd & Sheridan",
        "address": "9379 Sheridan Blvd",
        "id": "20084",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJJX1C7sCJa4cRdbKgZziv2v0",
        "user_ratings_total": 669
      },
      {
        "geocode": [
          39.96473,
          -105.16401
        ],
        "Rest_Name": "Hwy 36 & McCaslin Blvd.",
        "address": "459 S McCaslin Blvd",
        "id": "20085",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJndfjdC_za4cRtygsy2-kYv0",
        "user_ratings_total": 400
      },
      {
        "geocode": [
          39.930189,
          -105.126768
        ],
        "Rest_Name": "Flatirons Mall",
        "address": "635-B Flatiron Marketplace Dr",
        "id": "20086",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJNUfB94SMa4cRhcc8dOJtvGY",
        "user_ratings_total": 423
      },
      {
        "geocode": [
          40.1436962,
          -105.1319486
        ],
        "Rest_Name": "Clover Basin",
        "address": "2301 Clover Basin Dr",
        "id": "20087",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJWWOSeiH6a4cR1twTPQ1m0kA",
        "user_ratings_total": 606
      },
      {
        "geocode": [
          39.88687,
          -105.07548
        ],
        "Rest_Name": "Hwy 36 & Church Ranch",
        "address": "10431 Town Center Dr",
        "id": "20088",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJA6OxnCqKa4cRo7y98WIKrRs",
        "user_ratings_total": 433
      },
      {
        "geocode": [
          39.9147296,
          -105.0452849
        ],
        "Rest_Name": "120th & Vrain",
        "address": "4550 W 121st Ave",
        "id": "20089",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ7_vj5HOKa4cRfv_SgJLRwTc",
        "user_ratings_total": 522
      },
      {
        "geocode": [
          39.84254,
          -105.08338
        ],
        "Rest_Name": "80th & Wadsworth",
        "address": "8031 Wadsworth Blvd",
        "id": "20090",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJf-_p8v6Ia4cRqrIxbZc2clU",
        "user_ratings_total": 407
      },
      {
        "geocode": [
          39.91544,
          -104.99852
        ],
        "Rest_Name": "120th & Huron",
        "address": "1005 W 120th Ave",
        "id": "20091",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ2Tpcoch1bIcR6Z3OvVcci1k",
        "user_ratings_total": 613
      },
      {
        "geocode": [
          40.16295,
          -104.9771
        ],
        "Rest_Name": "I-25",
        "address": "11169 E I25 Frontage Rd",
        "id": "20092",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.9133887,
          -104.9425768
        ],
        "Rest_Name": "120th & Colorado",
        "address": "3804 E 120th Ave",
        "id": "20093",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ6whnRQZ0bIcRbDYlf2-93S0",
        "user_ratings_total": 543
      },
      {
        "geocode": [
          40.3962147,
          -104.6980645
        ],
        "Rest_Name": "11th Ave.",
        "address": "2527 11th Ave",
        "id": "20094",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          40.3948554,
          -104.7541905
        ],
        "Rest_Name": "Centerplace",
        "address": "4626 Centerplace Dr",
        "id": "20095",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJefEugrSmbocRxSnjSZZjaxc",
        "user_ratings_total": 596
      },
      {
        "geocode": [
          40.584313,
          -105.055016
        ],
        "Rest_Name": "Magnolia",
        "address": "1281 E Magnolia St",
        "id": "20096",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJW1BbUMJKaYcRuFKuhdML-uA",
        "user_ratings_total": 324
      },
      {
        "geocode": [
          40.5500384,
          -105.0381272
        ],
        "Rest_Name": "Timberline",
        "address": "2638 S Timberline Rd",
        "id": "20097",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJLart4klLaYcRvc1ZRuDREo4",
        "user_ratings_total": 348
      },
      {
        "geocode": [
          40.52571,
          -105.02625
        ],
        "Rest_Name": "Council Tree",
        "address": "2720 Council Tree Ave",
        "id": "20098",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJP4wGk1GzbocRqihFnyNyGKg",
        "user_ratings_total": 396
      },
      {
        "geocode": [
          40.5748818,
          -105.0962499
        ],
        "Rest_Name": "Elizabeth",
        "address": "1104 W Elizabeth St",
        "id": "20099",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.43567,
          -104.98729
        ],
        "Rest_Name": "Crossroads Blvd.",
        "address": "6100 E Crossroads Blvd",
        "id": "20100",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.5239207,
          -105.0735055
        ],
        "Rest_Name": "Fort Collins",
        "address": "250 E Harmony Rd",
        "id": "20101",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJua3xWoFMaYcRTZl-tMqt7B8",
        "user_ratings_total": 402
      },
      {
        "geocode": [
          40.5538014,
          -105.0965184
        ],
        "Rest_Name": "Shields",
        "address": "2513 S Shields St",
        "id": "20102",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJw1RzHbpLaYcRdYIbFC3ylMs",
        "user_ratings_total": 598
      },
      {
        "geocode": [
          40.40601,
          -104.98384
        ],
        "Rest_Name": "Johnstown",
        "address": "4791 Thompson Pkwy",
        "id": "20103",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJWzjLeQCtbocRopzbLEpNaAY",
        "user_ratings_total": 853
      },
      {
        "geocode": [
          40.4237945,
          -105.0769992
        ],
        "Rest_Name": "Loveland",
        "address": "233 E 29th St",
        "id": "20104",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJp4BRz_ZSaYcRtnjK6WNwFfY",
        "user_ratings_total": 846
      },
      {
        "geocode": [
          40.38251,
          -105.0972
        ],
        "Rest_Name": "10th St.",
        "address": "1420 SW 10th St",
        "id": "20105",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          39.9823968,
          -104.8240754
        ],
        "Rest_Name": "Brighton Pavilions",
        "address": "275 Pavilions Pl",
        "id": "20106",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJcYZ4bkBtbIcRNu0XWtBtbA4",
        "user_ratings_total": 652
      },
      {
        "geocode": [
          41.15837,
          -73.41806
        ],
        "Rest_Name": "Norwalk",
        "address": "652 Main Ave",
        "id": "20107",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJI0a3d-Ic6IkRM8z9_QMw7ag",
        "user_ratings_total": 298
      },
      {
        "geocode": [
          38.8962753,
          -77.0265758
        ],
        "Rest_Name": "11th St.",
        "address": "555 11th St NW",
        "id": "20108",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJd7Oez5C3t4kRRdqajCOtDhY",
        "user_ratings_total": 691
      },
      {
        "geocode": [
          39.1891,
          -75.53949
        ],
        "Rest_Name": "Dover",
        "address": "1211 N Dupont Hwy",
        "id": "20109",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJyZHIii97x4kRZRNBnzaPg3s",
        "user_ratings_total": 544
      },
      {
        "geocode": [
          39.6714562,
          -75.6492815
        ],
        "Rest_Name": "Christiana",
        "address": "3230 Fashion Center Blvd",
        "id": "20110",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJAQAAqz4Bx4kRZMxok5hQEZ8",
        "user_ratings_total": 687
      },
      {
        "geocode": [
          39.68331,
          -75.75098
        ],
        "Rest_Name": "U of Delaware Main Street",
        "address": "58 E Main St",
        "id": "20111",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJtcu3UTCqx4kRoltdmImb05g",
        "user_ratings_total": 264
      },
      {
        "geocode": [
          39.833764,
          -75.5414434
        ],
        "Rest_Name": "Wilmington",
        "address": "5609 Concord Pike",
        "id": "20112",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJmfRkWjr7xokRkjSkp0moEBQ",
        "user_ratings_total": 275
      },
      {
        "geocode": [
          27.8942432,
          -82.506829
        ],
        "Rest_Name": "Dale Mabry",
        "address": "3801 W Gandy Blvd",
        "id": "20113",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJk6CFI8XcwogRkyaakn37ZYE",
        "user_ratings_total": 668
      },
      {
        "geocode": [
          28.05368,
          -82.40153
        ],
        "Rest_Name": "USF",
        "address": "5001 E Fowler Ave",
        "id": "20114",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJa158ic_HwogRHUiRS4M9h60",
        "user_ratings_total": 827
      },
      {
        "geocode": [
          27.9250798,
          -82.337416
        ],
        "Rest_Name": "Falkenburg Commons",
        "address": "2524 S Falkenburg Rd",
        "id": "20115",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJjQ-Xk5nPwogRWx21iEZMr14",
        "user_ratings_total": 67
      },
      {
        "geocode": [
          27.793696,
          -82.335417
        ],
        "Rest_Name": "Hwy 301",
        "address": "13165 US 301",
        "id": "20116",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ1WisOtDWwogRmHaZUFBKGNk",
        "user_ratings_total": 973
      },
      {
        "geocode": [
          28.552166,
          -81.5415158
        ],
        "Rest_Name": "Ocoee",
        "address": "10959 W Colonial Dr",
        "id": "20117",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ9ZOS6SeD54gRly60LW4WjIc",
        "user_ratings_total": 363
      },
      {
        "geocode": [
          28.65693,
          -81.42018
        ],
        "Rest_Name": "SR 434",
        "address": "380 S State Rd 434",
        "id": "20118",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJA4eBMTZx54gRQN4Pm-myJ4Q",
        "user_ratings_total": 446
      },
      {
        "geocode": [
          28.385561,
          -81.506316
        ],
        "Rest_Name": "Lake Buena Vista",
        "address": "12376 Apopka Vineland Rd",
        "id": "20119",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJTzqqhxiA3YgRMO9EDcjv9dA",
        "user_ratings_total": 967
      },
      {
        "geocode": [
          28.7574188,
          -81.2884646
        ],
        "Rest_Name": "Lake Mary",
        "address": "202 W Lake Mary Blvd",
        "id": "20120",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJufnKDdFs54gRbRKvzQymavM",
        "user_ratings_total": 988
      },
      {
        "geocode": [
          28.443563,
          -81.300696
        ],
        "Rest_Name": "Orlando Airport",
        "address": "One Airport Blvd",
        "id": "20121",
        "type": "Qdoba",
        "rating": 3.4,
        "place_id": "ChIJ2dDHmgFj54gRFlj3ojK-UO4",
        "user_ratings_total": 554
      },
      {
        "geocode": [
          26.6475357,
          -81.9860782
        ],
        "Rest_Name": "Cape Coral",
        "address": "537 SW Pine Island Rd",
        "id": "20122",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA8eD5zZH24gRasQ9YoaE3ds",
        "user_ratings_total": 268
      },
      {
        "geocode": [
          26.5839127,
          -81.8711437
        ],
        "Rest_Name": "Page Field Commons",
        "address": "5037 S Cleveland",
        "id": "20123",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          26.0072554,
          -80.3612019
        ],
        "Rest_Name": "Pembroke Pines",
        "address": "16040 Pines Blvd P102",
        "id": "20124",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJp6I6KT2n2YgR5d3X6f7GmMY",
        "user_ratings_total": 83
      },
      {
        "geocode": [
          28.12818794,
          -82.36899998
        ],
        "Rest_Name": "Highwoods Preserve",
        "address": "17509 Preserve Walk Ln",
        "id": "20125",
        "type": "Qdoba",
        "rating": null,
        "place_id": "ChIJ_dicR3y3wogRQIxG26w423o",
        "user_ratings_total": null
      },
      {
        "geocode": [
          27.92530257,
          -82.33746964
        ],
        "Rest_Name": "Falkenburg Commons",
        "address": "2524 S Falkenburg Rd",
        "id": "20126",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJjQ-Xk5nPwogRWx21iEZMr14",
        "user_ratings_total": 67
      },
      {
        "geocode": [
          27.98017929,
          -82.53474656
        ],
        "Rest_Name": "TPA",
        "address": "4100 George J Bean PkwySpace T-3-1737",
        "id": "20127",
        "type": "Qdoba",
        "rating": 3,
        "place_id": "ChIJDTcjNlrDwogRDjxOtzsqW_c",
        "user_ratings_total": 52
      },
      {
        "geocode": [
          26.07898274,
          -80.24025675
        ],
        "Rest_Name": "Nova Southeastern University",
        "address": "3301 College Ave",
        "id": "20128",
        "type": "Qdoba",
        "rating": 5,
        "place_id": "ChIJ233N4vep2YgRUw8ILXU0Prk",
        "user_ratings_total": 1
      },
      {
        "geocode": [
          29.18995799,
          -81.04837996
        ],
        "Rest_Name": "Embry Riddle",
        "address": "600 S Clyde Morris Blvd",
        "id": "20129",
        "type": "Qdoba",
        "rating": 2.2,
        "place_id": "ChIJAUVGrEnZ5ogR7utBPtu2Oos",
        "user_ratings_total": 23
      },
      {
        "geocode": [
          30.27199909,
          -81.50918246
        ],
        "Rest_Name": "University of North Florida",
        "address": "1 UNF DriveJohn A Delaney Student Union",
        "id": "20130",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJh3wzFklL5IgRKCSdXuZMyo4",
        "user_ratings_total": 7
      },
      {
        "geocode": [
          28.6023596,
          -81.20021109
        ],
        "Rest_Name": "University of Central Florida (UCF)",
        "address": "12715 Pegasus Dr.Ste #137",
        "id": "20131",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          33.640791,
          -84.443431
        ],
        "Rest_Name": "Atlanta Airport II",
        "address": "12700 Spine Rd SW Atlanta Intrnl Airport Spc AF8",
        "id": "20132",
        "type": "Qdoba",
        "rating": 3.4,
        "place_id": "ChIJdw8oUjr99IgRdwnGExAtI90",
        "user_ratings_total": 458
      },
      {
        "geocode": [
          33.64152,
          -84.44325
        ],
        "Rest_Name": "Atlanta Airport",
        "address": "6000 N Terminal Pkwy Concourse E",
        "id": "20133",
        "type": "Qdoba",
        "rating": 3.4,
        "place_id": "ChIJdw8oUjr99IgRdwnGExAtI90",
        "user_ratings_total": 458
      },
      {
        "geocode": [
          33.9721354,
          -84.5513269
        ],
        "Rest_Name": "Marietta",
        "address": "827 Church St.",
        "id": "20134",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ_zoxKkgV9YgR0XANXUGUhpU",
        "user_ratings_total": 242
      },
      {
        "geocode": [
          33.90818,
          -84.36346
        ],
        "Rest_Name": "Glenridge",
        "address": "5610 Glenridge Dr NE",
        "id": "20135",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ-1uZVs8O9YgRd6MMrVY28l4",
        "user_ratings_total": 532
      },
      {
        "geocode": [
          34.08761,
          -84.26641
        ],
        "Rest_Name": "Windward",
        "address": "5530 Windward Pkwy",
        "id": "20136",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJ775UqR-e9YgR-5K_ROwiaKQ",
        "user_ratings_total": 694
      },
      {
        "geocode": [
          33.77902074,
          -84.3876327
        ],
        "Rest_Name": "Midtown",
        "address": "880 West Peachtree St NWSuite 2",
        "id": "20137",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ73Jk7jAF9YgRk_wsoOUgw5E",
        "user_ratings_total": 200
      },
      {
        "geocode": [
          32.37731690418228,
          -84.92278407
        ],
        "Rest_Name": "Ft Benning",
        "address": "103 Ingersol St",
        "id": "20138",
        "type": "Qdoba",
        "rating": 2.8,
        "place_id": "ChIJ2-xOvKgt84gRT6AyNbO5TiA",
        "user_ratings_total": 33
      },
      {
        "geocode": [
          31.86602473,
          -81.60861262
        ],
        "Rest_Name": "Ft Stewart",
        "address": "345 Lindquist Rd",
        "id": "20139",
        "type": "Qdoba",
        "rating": 2.7,
        "place_id": "ChIJZxWQy3rl-ogRUM8hhhroW4o",
        "user_ratings_total": 32
      },
      {
        "geocode": [
          33.3413529,
          -84.53560358
        ],
        "Rest_Name": "Peachtree City",
        "address": "1205 Highway 74 SSuite 100",
        "id": "20140",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          32.59812936,
          -83.58215722
        ],
        "Rest_Name": "Robins AFB",
        "address": "982 Macon St",
        "id": "20141",
        "type": "Qdoba",
        "rating": 2.1,
        "place_id": "ChIJg2WEwe9f8YgRgRUQYXyfWvc",
        "user_ratings_total": 14
      },
      {
        "geocode": [
          32.37587651,
          -84.92323836
        ],
        "Rest_Name": "Ft. Benning Food Court",
        "address": "9220 Marne RdMain Exchange",
        "id": "20142",
        "type": "Qdoba",
        "rating": 2.8,
        "place_id": "ChIJ2-xOvKgt84gRT6AyNbO5TiA",
        "user_ratings_total": 33
      },
      {
        "geocode": [
          13.4998369,
          144.7975913
        ],
        "Rest_Name": "Qdoba Online Ordering Not Supported Demo",
        "address": "331 North Marine Drive",
        "id": "20143",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          41.5582139,
          -90.5474732
        ],
        "Rest_Name": "Davenport",
        "address": "2104 E Kimberly Rd",
        "id": "20144",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ14C02Fs14ocRVDQ-reZSNtw",
        "user_ratings_total": 182
      },
      {
        "geocode": [
          41.58596,
          -93.64648
        ],
        "Rest_Name": "Ingersoll",
        "address": "2105 Ingersoll Ave",
        "id": "20145",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJHy78JieZ7ocRha06IIdu8-U",
        "user_ratings_total": 564
      },
      {
        "geocode": [
          41.22016,
          -95.83433
        ],
        "Rest_Name": "Denmark Dr.",
        "address": "3825 Denmark Drive",
        "id": "20146",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJOb4rvhiEk4cRX3dMO40wSgs",
        "user_ratings_total": 462
      },
      {
        "geocode": [
          42.445384,
          -96.339988
        ],
        "Rest_Name": "Seargeant Rd.",
        "address": "5110 Sergeant Rd",
        "id": "20147",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJBTxsvjOaEYgRaSrZ7jFbwdU",
        "user_ratings_total": 292
      },
      {
        "geocode": [
          42.5147279,
          -96.4173006
        ],
        "Rest_Name": "23rd & Hamilton",
        "address": "2303 Hamilton Blvd",
        "id": "20148",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          43.610565,
          -116.592243
        ],
        "Rest_Name": "Marketplace",
        "address": "16383 N Market Place Blvd",
        "id": "20149",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          43.5907946,
          -116.3560748
        ],
        "Rest_Name": "Overland",
        "address": "3068 E Overland Rd",
        "id": "20150",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          43.60497,
          -116.19287
        ],
        "Rest_Name": "590 S. Broadway",
        "address": "590 S Broadway Ave",
        "id": "20151",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          47.74656,
          -116.78986
        ],
        "Rest_Name": "Prairie Shopping Center",
        "address": "245 W Prairie Shopping Center",
        "id": "20152",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          47.6937695,
          -116.7870766
        ],
        "Rest_Name": "Coeur d' Alene",
        "address": "204 W Ironwood Dr",
        "id": "20153",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ2UaRxOnGYVMREplXHtWXAnc",
        "user_ratings_total": 476
      },
      {
        "geocode": [
          46.73396,
          -117.02879
        ],
        "Rest_Name": "Moscow",
        "address": "1970 W Pullman Rd",
        "id": "20154",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJq6I4qieIn1QRYUXWR4wMg4M",
        "user_ratings_total": 353
      },
      {
        "geocode": [
          38.5278254,
          -90.0353694
        ],
        "Rest_Name": "Frank Scott Pkwy and RT 15",
        "address": "5880 Belleville Crossing St",
        "id": "20155",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ8YbKHFOo2IcRnSnzcPFZSQ8",
        "user_ratings_total": 657
      },
      {
        "geocode": [
          38.5733258,
          -89.9275497
        ],
        "Rest_Name": "Green Mount and I-64",
        "address": "3210 Green Mount Crossing Dr",
        "id": "20156",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.9362912,
          -91.3653329
        ],
        "Rest_Name": "32nd St.",
        "address": "415 N 32nd St",
        "id": "20157",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          40.7467212,
          -89.611467
        ],
        "Rest_Name": "Glen",
        "address": "1216 W Glen Ave",
        "id": "20158",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ3c0mPppbCogRutIPEcLPym8",
        "user_ratings_total": 499
      },
      {
        "geocode": [
          40.6816632,
          -89.5876774
        ],
        "Rest_Name": "Camp",
        "address": "1040 W Camp St",
        "id": "20159",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJkXdHQqtZCogR4sCt6fzW17g",
        "user_ratings_total": 565
      },
      {
        "geocode": [
          39.7636225,
          -89.7005317
        ],
        "Rest_Name": "Drawbridge",
        "address": "2320 Wabash Ave",
        "id": "20160",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJM6Y2RJ04dYgRfRAbkoxWjpQ",
        "user_ratings_total": 458
      },
      {
        "geocode": [
          38.7903765,
          -89.9506451
        ],
        "Rest_Name": "Edwardsville",
        "address": "2100 Troy Rd",
        "id": "20161",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJS3ZMAqX5dYgR19754-dObJA",
        "user_ratings_total": 579
      },
      {
        "geocode": [
          41.88847,
          -88.10734
        ],
        "Rest_Name": "N. Main/S. Schmale Rd. and E. Geneva Rd.",
        "address": "2202 Main St",
        "id": "20162",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          38.67704,
          -90.01537
        ],
        "Rest_Name": "Collinsville",
        "address": "1100 Collinsville Crossing BLV",
        "id": "20163",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJTQjCN2pV34cR3M6LZ9Y0Ixg",
        "user_ratings_total": 633
      },
      {
        "geocode": [
          42.0250664,
          -88.1449794
        ],
        "Rest_Name": "Schaumburg",
        "address": "2602 W Schaumburg Rd",
        "id": "20164",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJI1siIEuoD4gRuFAp748-W8w",
        "user_ratings_total": 476
      },
      {
        "geocode": [
          42.138679,
          -87.99533
        ],
        "Rest_Name": "Arlington Heights",
        "address": "1025 W Dundee Rd",
        "id": "20165",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJTailMF-7D4gRkl1s_rMwBP4",
        "user_ratings_total": 481
      },
      {
        "geocode": [
          41.87696,
          -87.63345
        ],
        "Rest_Name": "Corner of W. Van Buren St. & South Wells St.",
        "address": "175 W Jackson St",
        "id": "20166",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJBUZJK7wsDogRON4091qn_m4",
        "user_ratings_total": 327
      },
      {
        "geocode": [
          41.8833548,
          -87.6328691
        ],
        "Rest_Name": "N. LaSalle Street & W. Washington St.",
        "address": "100 N Lasalle St",
        "id": "20167",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          41.9323791,
          -87.6534869
        ],
        "Rest_Name": "W. Diversey Pkwy & N. Sheffield Ave.",
        "address": "957 W Diversey Pkwy",
        "id": "20168",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          37.9747178,
          -87.6422587
        ],
        "Rest_Name": "Pearl Drive",
        "address": "5501 Pearl Dr",
        "id": "20169",
        "type": "Qdoba",
        "rating": 4.7,
        "place_id": "ChIJsf7ZvkzTcYgR0mTL1Q65_Qo",
        "user_ratings_total": 21
      },
      {
        "geocode": [
          37.9852557,
          -87.4746124
        ],
        "Rest_Name": "Burkhardt",
        "address": "922 N Burkhardt Rd",
        "id": "20170",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJC6BZvXErbogRmHEXcpSmwSc",
        "user_ratings_total": 629
      },
      {
        "geocode": [
          39.4240558,
          -87.4146642
        ],
        "Rest_Name": "Terre Haute",
        "address": "3510 Riverside Plaza",
        "id": "20171",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJ71Fjdft6bYgRG5ZUwq6ouyE",
        "user_ratings_total": 548
      },
      {
        "geocode": [
          41.5243297,
          -87.4705157
        ],
        "Rest_Name": "S. Indianapolis Blvd.",
        "address": "10439 S Indianapolis Blvd",
        "id": "20172",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJqRRdSyXhEYgRGLzq8jV48Mw",
        "user_ratings_total": 279
      },
      {
        "geocode": [
          40.41687,
          -86.85133
        ],
        "Rest_Name": "Lafayette",
        "address": "3515 South St",
        "id": "20173",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJpzZ8njEdE4gRW0HHaNxXE0A",
        "user_ratings_total": 303
      },
      {
        "geocode": [
          41.469922,
          -87.333981
        ],
        "Rest_Name": "Merrillville",
        "address": "333 E 81st Ave",
        "id": "20174",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJGbPJUt3uEYgR4jll17wkuF0",
        "user_ratings_total": 585
      },
      {
        "geocode": [
          41.46815,
          -87.02784
        ],
        "Rest_Name": "LaPorte",
        "address": "2710 Laporte Ave",
        "id": "20175",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJBTxsvjOaEYgRaSrZ7jFbwdU",
        "user_ratings_total": 292
      },
      {
        "geocode": [
          39.16569,
          -86.52708
        ],
        "Rest_Name": "Indiana University",
        "address": "116 S Indiana Ave",
        "id": "20176",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJr6mkZ8JmbIgRnDKxAWikkPw",
        "user_ratings_total": 323
      },
      {
        "geocode": [
          39.71735,
          -86.3567962
        ],
        "Rest_Name": "Plainfield/ Main St.",
        "address": "2663 E Main St",
        "id": "20177",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ3_8poEqkbIgREX_1xAZrGm4",
        "user_ratings_total": 715
      },
      {
        "geocode": [
          39.76458,
          -86.32808
        ],
        "Rest_Name": "Rockville",
        "address": "10934 E Us Highway 36",
        "id": "20178",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJKxAnzKiobIgRobwNX3vvxho",
        "user_ratings_total": 813
      },
      {
        "geocode": [
          39.8551673,
          -86.3935339
        ],
        "Rest_Name": "Brownsburg",
        "address": "890 N Green St",
        "id": "20179",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJj603YsOtbIgRdnaMAJ1xJIE",
        "user_ratings_total": 513
      },
      {
        "geocode": [
          39.60547,
          -86.15982
        ],
        "Rest_Name": "Smith Valley",
        "address": "704 State Road 135",
        "id": "20180",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ9eDRxetda4gRaLoP7BM5Nbk",
        "user_ratings_total": 690
      },
      {
        "geocode": [
          39.70475,
          -86.15098
        ],
        "Rest_Name": "Southern Plaza/ East St.",
        "address": "4200 S East St",
        "id": "20181",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJBX-K57Zba4gRZ2sNTbAQrTY",
        "user_ratings_total": 697
      },
      {
        "geocode": [
          41.7078205,
          -86.180699
        ],
        "Rest_Name": "Mishawaka",
        "address": "5310 N Main St",
        "id": "20182",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJwQHjyHnTFogR4zAHwuX9d2Y",
        "user_ratings_total": 633
      },
      {
        "geocode": [
          41.72395,
          -85.974
        ],
        "Rest_Name": "Elkhart",
        "address": "405 E County Rd 6",
        "id": "20183",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJd6_4avzcFogRInyH39Flzo4",
        "user_ratings_total": 997
      },
      {
        "geocode": [
          39.9495189,
          -86.3507059
        ],
        "Rest_Name": "Whitestown",
        "address": "6196 Whitestown Pkwy",
        "id": "20184",
        "type": "Qdoba",
        "rating": 3.3,
        "place_id": "ChIJT4-uD89TE4gRrCaDlNGZwgU",
        "user_ratings_total": 364
      },
      {
        "geocode": [
          39.91226,
          -86.26086
        ],
        "Rest_Name": "Traders Point",
        "address": "5630 W 86th St",
        "id": "20185",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJnUjGx6iqbIgR67xVbRWFafc",
        "user_ratings_total": 584
      },
      {
        "geocode": [
          39.9122669,
          -86.2213228
        ],
        "Rest_Name": "Pyramid Place",
        "address": "3528 W 86th St",
        "id": "20186",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJOUvtKy9Va4gReXe5vRQP7jY",
        "user_ratings_total": 381
      },
      {
        "geocode": [
          39.7797445,
          -86.1727321
        ],
        "Rest_Name": "IUPUI",
        "address": "907 Indiana Ave",
        "id": "20187",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJe2ayo8xQa4gRiCuAFDB3fig",
        "user_ratings_total": 1106
      },
      {
        "geocode": [
          39.7674007,
          -86.1578678
        ],
        "Rest_Name": "King Cole",
        "address": "9 N Meridian St",
        "id": "20188",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJfdPYN7xQa4gRscC8ufotzto",
        "user_ratings_total": 642
      },
      {
        "geocode": [
          39.8887246,
          -86.1212129
        ],
        "Rest_Name": "Norgate",
        "address": "7411 N Keystone Ave",
        "id": "20189",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJh19Z-e5Sa4gR4dTZyRVpQPM",
        "user_ratings_total": 593
      },
      {
        "geocode": [
          39.99842,
          -86.13052
        ],
        "Rest_Name": "ClayTerrace",
        "address": "14490 Clay Terrace Blvd",
        "id": "20190",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ-9778NWtFIgRaGXashwsYY8",
        "user_ratings_total": 361
      },
      {
        "geocode": [
          39.66737,
          -86.08191
        ],
        "Rest_Name": "Southport",
        "address": "6905 S Emerson Ave",
        "id": "20191",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJfaukblhDa4gRzCPA5NUgfZ4",
        "user_ratings_total": 804
      },
      {
        "geocode": [
          39.905518,
          -86.0517256
        ],
        "Rest_Name": "Castleton",
        "address": "6714 E 82nd St",
        "id": "20192",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJfUiwAgVNa4gRqaKh-cnloHw",
        "user_ratings_total": 548
      },
      {
        "geocode": [
          39.2170013,
          -85.8833615
        ],
        "Rest_Name": "Columbus/ 17th St.",
        "address": "1665 N National Rd",
        "id": "20193",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJTyKioPCga4gRfNbpYPCMZuU",
        "user_ratings_total": 529
      },
      {
        "geocode": [
          39.77541,
          -85.99343
        ],
        "Rest_Name": "East Washington",
        "address": "9702 E Washington St",
        "id": "20194",
        "type": "Qdoba",
        "rating": 3.4,
        "place_id": "ChIJwzKzXMJIa4gRX7Xj_8DndvI",
        "user_ratings_total": 1486
      },
      {
        "geocode": [
          38.3072774,
          -85.8406797
        ],
        "Rest_Name": "Wesley Commons",
        "address": "2216 State St",
        "id": "20195",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          38.3513122,
          -85.7942799
        ],
        "Rest_Name": "Charlestown",
        "address": "4302 Charlestown Rd",
        "id": "20196",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJq55a5DduaYgRyW-4rCY4_Gk",
        "user_ratings_total": 671
      },
      {
        "geocode": [
          40.0359804,
          -85.9942659
        ],
        "Rest_Name": "Noblesville",
        "address": "17047 Mercantile Blvd",
        "id": "20197",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJbw0oXSC3FIgRM3Cd-prwuuc",
        "user_ratings_total": 442
      },
      {
        "geocode": [
          39.9277156,
          -86.0217732
        ],
        "Rest_Name": "Exit 3- Fishers Geist",
        "address": "8260 E 96th St",
        "id": "20198",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJsRnux0SzFIgRaHqPE4QTKVw",
        "user_ratings_total": 564
      },
      {
        "geocode": [
          39.9560484,
          -86.0109035
        ],
        "Rest_Name": "Exit 5- Fishers",
        "address": "8971 E 116th St",
        "id": "20199",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJCZnhBX-0FIgRmV7a3lQldks",
        "user_ratings_total": 550
      },
      {
        "geocode": [
          38.329538,
          -85.7594951
        ],
        "Rest_Name": "Veterans",
        "address": "1305 Veterans Pkwy",
        "id": "20200",
        "type": "Qdoba",
        "rating": 2.8,
        "place_id": "ChIJ2-xOvKgt84gRT6AyNbO5TiA",
        "user_ratings_total": 33
      },
      {
        "geocode": [
          38.33361,
          -85.70969
        ],
        "Rest_Name": "Jeffersonville",
        "address": "3523 E 10th St",
        "id": "20201",
        "type": "Qdoba",
        "rating": 3,
        "place_id": "ChIJN9R1OhFxaYgR1mbNyFVAzxM",
        "user_ratings_total": 182
      },
      {
        "geocode": [
          39.99292,
          -85.92979
        ],
        "Rest_Name": "Hamilton Town Center",
        "address": "13230 Harrell Pkwy",
        "id": "20202",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJc0Xtlf_JFIgRZMYwV5vMuJg",
        "user_ratings_total": 418
      },
      {
        "geocode": [
          39.8144896,
          -85.7691116
        ],
        "Rest_Name": "N. State St.",
        "address": "2037 N State St",
        "id": "20203",
        "type": "Qdoba",
        "rating": null,
        "place_id": "EicyMDM3IE4gU3RhdGUgU3QsIENoaWNhZ28sIElMIDYwNjEwLCBVU0EiMRIvChQKEgk1iVPeT9MPiBHXcUTs8Wq_ExD1DyoUChIJ_a9Aea0sDogRQQmA4zBu2C0",
        "user_ratings_total": null
      },
      {
        "geocode": [
          41.176034,
          -85.092881
        ],
        "Rest_Name": "Dupont Corner",
        "address": "10070 Diebold Road",
        "id": "20204",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJfScawc_9FYgR_Z4J509zGJs",
        "user_ratings_total": 67
      },
      {
        "geocode": [
          41.1178,
          -85.13082
        ],
        "Rest_Name": "Coliseum",
        "address": "700 E Coliseum Blvd",
        "id": "20205",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJKdxz1BTjFYgRSv9f5I6909U",
        "user_ratings_total": 782
      },
      {
        "geocode": [
          40.21941,
          -85.37843
        ],
        "Rest_Name": "Muncie Mall",
        "address": "720 E Mcgalliard",
        "id": "20206",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJtQlZ-SAWFYgRaowc0obJvvs",
        "user_ratings_total": 326
      },
      {
        "geocode": [
          40.0669014,
          -85.6520701
        ],
        "Rest_Name": "Scatterfield Rd.",
        "address": "4682 S Scatterfield Rd",
        "id": "20207",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ55_IbdfZFIgReF7-f8vTipw",
        "user_ratings_total": 606
      },
      {
        "geocode": [
          38.8813893,
          -94.6673943
        ],
        "Rest_Name": "Overland Park",
        "address": "13641 Metcalf Ave",
        "id": "20208",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJV3OfT8LBwIcRuIMcm2M9ITE",
        "user_ratings_total": 551
      },
      {
        "geocode": [
          37.6930678,
          -97.244013
        ],
        "Rest_Name": "North Rock",
        "address": "430 N Rock Rd",
        "id": "20209",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJT-0-A8D8uocR08R5xzEug_8",
        "user_ratings_total": 815
      },
      {
        "geocode": [
          39.3259616,
          -101.7271581
        ],
        "Rest_Name": "Goodland",
        "address": "2710 Commerce Rd",
        "id": "20210",
        "type": "Qdoba",
        "rating": 2.7,
        "place_id": "ChIJgducc91LC4cRqVjYlHzgKeQ",
        "user_ratings_total": 57
      },
      {
        "geocode": [
          39.361766,
          -101.053673
        ],
        "Rest_Name": "Oasis Travel Center",
        "address": "700 W Horton Ave",
        "id": "20211",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.89388,
          -99.31725
        ],
        "Rest_Name": "Hays",
        "address": "3310 Vine St",
        "id": "20212",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ9TqRLQ95oYcRsU96Wr-Xp4M",
        "user_ratings_total": 903
      },
      {
        "geocode": [
          39.049015,
          -95.762255
        ],
        "Rest_Name": "Wanamaker",
        "address": "1025 Sw Wanamaker Rd",
        "id": "20213",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJF3BWDwb9vocRybcRLfShbnM",
        "user_ratings_total": 1087
      },
      {
        "geocode": [
          39.1823,
          -96.55991
        ],
        "Rest_Name": "Manhattan Marketplace",
        "address": "320 N 3rd St",
        "id": "20214",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          39.1881504,
          -96.5825251
        ],
        "Rest_Name": "KSU Student Union",
        "address": "918 N 17th St",
        "id": "20215",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJv1DNsYjNvYcRlmW-ukRg8ZA",
        "user_ratings_total": 13
      },
      {
        "geocode": [
          39.0223682,
          -96.8170201
        ],
        "Rest_Name": "Junction City",
        "address": "820 E Chestnut St",
        "id": "20216",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJUb3bcUFJvIcRgCZlCYmLAmU",
        "user_ratings_total": 661
      },
      {
        "geocode": [
          37.67508,
          -97.38986
        ],
        "Rest_Name": "Kellogg",
        "address": "583 S West St",
        "id": "20217",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJB_0s3L5UDogRSsCCCR16ETA",
        "user_ratings_total": 706
      },
      {
        "geocode": [
          38.0721406,
          -97.9038363
        ],
        "Rest_Name": "Hutchinson",
        "address": "1518 E 17th Ave",
        "id": "20218",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJm6XIXEe2pIcRL-V3-pVg6cE",
        "user_ratings_total": 822
      },
      {
        "geocode": [
          38.8014529,
          -97.6135185
        ],
        "Rest_Name": "Salina",
        "address": "2300 Planet Ave",
        "id": "20219",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJrapzm5_IvIcRNQX-orppLtk",
        "user_ratings_total": 471
      },
      {
        "geocode": [
          36.62004,
          -88.31447
        ],
        "Rest_Name": "Murray",
        "address": "618 N 12th St",
        "id": "20220",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ3WTmDphSeogRM4p_YZq4AQs",
        "user_ratings_total": 284
      },
      {
        "geocode": [
          37.0783007,
          -88.6838873
        ],
        "Rest_Name": "Paducah",
        "address": "5015 Hinkleville Rd",
        "id": "20221",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJAQDQVwgbeogRD9ytTHevTUc",
        "user_ratings_total": 334
      },
      {
        "geocode": [
          36.94501,
          -86.42409
        ],
        "Rest_Name": "Bowling Green",
        "address": "1971 Cave Mill Rd",
        "id": "20222",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ3zKn1IDoZYgRahsYYEXgXDk",
        "user_ratings_total": 366
      },
      {
        "geocode": [
          37.72731,
          -85.88438
        ],
        "Rest_Name": "Elizabethtown",
        "address": "1570 N Dixie Hwy",
        "id": "20223",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJJUyTCYboaIgRYqig1zRfSg4",
        "user_ratings_total": 926
      },
      {
        "geocode": [
          37.7369529,
          -84.312246
        ],
        "Rest_Name": "Richmond",
        "address": "429 Leighway Dr",
        "id": "20224",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ4fSJZ8r9QogRhIdg47eKn1g",
        "user_ratings_total": 701
      },
      {
        "geocode": [
          38.15109,
          -85.83735
        ],
        "Rest_Name": "Dixie Highway",
        "address": "6814 Dixie Hwy",
        "id": "20225",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJx9wo9VoRaYgRT-kjw7iEYMc",
        "user_ratings_total": 1345
      },
      {
        "geocode": [
          38.0887723,
          -85.6672172
        ],
        "Rest_Name": "Preston/Standiford Plaza",
        "address": "11910 Standiford Plaza Dr",
        "id": "20226",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJA1f3gr8IaYgRAKocQ4S_pQk",
        "user_ratings_total": 871
      },
      {
        "geocode": [
          38.138352,
          -85.6663237
        ],
        "Rest_Name": "Jefferson Commons",
        "address": "4921 Outer Loop",
        "id": "20227",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJSRznNUIJaYgRtgydACpFS-Q",
        "user_ratings_total": 899
      },
      {
        "geocode": [
          38.153564,
          -85.5878594
        ],
        "Rest_Name": "Bardstown Rd in Fern Creek",
        "address": "6403 Bardstown Rd",
        "id": "20228",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJuSm07LOgaYgRSru7azL1_eg",
        "user_ratings_total": 517
      },
      {
        "geocode": [
          38.2207385,
          -85.7614256
        ],
        "Rest_Name": "University of Louisville",
        "address": "1832 S 3rd St",
        "id": "20229",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ0Ton2aRyaYgRSohqBEByqmM",
        "user_ratings_total": 695
      },
      {
        "geocode": [
          37.980386,
          -84.5276868
        ],
        "Rest_Name": "E. Tiverton",
        "address": "101 E Tiverton Way",
        "id": "20230",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.0047,
          -84.57241
        ],
        "Rest_Name": "Palomar",
        "address": "3735 Palomar Centre Dr",
        "id": "20231",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJtX3TUiJdQogRbGB9r9z6_OY",
        "user_ratings_total": 433
      },
      {
        "geocode": [
          38.212681,
          -85.7206468
        ],
        "Rest_Name": "Poplar Level",
        "address": "3021 Poplar Level Rd",
        "id": "20232",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJg0x_VOEMaYgRv0JpSH2B5II",
        "user_ratings_total": 870
      },
      {
        "geocode": [
          38.2119294,
          -85.5969722
        ],
        "Rest_Name": "Stonybrook",
        "address": "2730 S Hurstbourne Pkwy",
        "id": "20233",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ89s3OXOgaYgRDpFdybVwmC4",
        "user_ratings_total": 864
      },
      {
        "geocode": [
          38.0385092,
          -84.4998708
        ],
        "Rest_Name": "UK Campus",
        "address": "265 Avenue of Champions",
        "id": "20234",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJFatzZcBEQogR66ic7oj1Q2w",
        "user_ratings_total": 385
      },
      {
        "geocode": [
          38.02358,
          -84.42384
        ],
        "Rest_Name": "Sir Barton",
        "address": "2337 Sir Barton Dr",
        "id": "20235",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.1683299,
          -84.8994395
        ],
        "Rest_Name": "127 S",
        "address": "1303 US Highway 127 S",
        "id": "20236",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ7YhzL1R0QogRsr1BsjzRHUA",
        "user_ratings_total": 563
      },
      {
        "geocode": [
          38.201276,
          -84.854625
        ],
        "Rest_Name": "Kentucky State",
        "address": "400 East Main Street Hill Student Center",
        "id": "20237",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJG0RLZypzQogR9z54U-xmiuY",
        "user_ratings_total": 5
      },
      {
        "geocode": [
          38.252915,
          -85.757183
        ],
        "Rest_Name": "S. 4th St.",
        "address": "312 S 4th St",
        "id": "20238",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJverN2qRyaYgRzJ-vyr9mgGg",
        "user_ratings_total": 505
      },
      {
        "geocode": [
          38.2316632,
          -85.7091926
        ],
        "Rest_Name": "Bardstown Rd and Eastern Pkwy",
        "address": "1500 Bardstown Rd",
        "id": "20239",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ1Sn0PskMaYgR4znQjBLmdr4",
        "user_ratings_total": 923
      },
      {
        "geocode": [
          38.2506,
          -85.63139
        ],
        "Rest_Name": "Shelbyville Road Plaza",
        "address": "4600 Shelbyville Rd",
        "id": "20240",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJj62Sbzt1aYgRikEAKilU2oo",
        "user_ratings_total": 497
      },
      {
        "geocode": [
          38.2331276,
          -85.6411126
        ],
        "Rest_Name": "Breckenridge Lane",
        "address": "970 Breckenridge Ln",
        "id": "20241",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ45rF89QKaYgRFf9k7qU_s80",
        "user_ratings_total": 808
      },
      {
        "geocode": [
          38.26959,
          -85.61411
        ],
        "Rest_Name": "Wesport Village",
        "address": "1321 Herr Lane",
        "id": "20242",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJhdqUZXB1aYgRyiNHy0RywTU",
        "user_ratings_total": 490
      },
      {
        "geocode": [
          38.3075164,
          -85.5772696
        ],
        "Rest_Name": "The Paddock Shops",
        "address": "4059 Summit Plaza Dr",
        "id": "20243",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJl2FoD3KeaYgRS5JjNMwcwM0",
        "user_ratings_total": 538
      },
      {
        "geocode": [
          38.2155847,
          -85.538087
        ],
        "Rest_Name": "Blankenbaker Pkwy.",
        "address": "1831 Blankenbaker Pkwy",
        "id": "20244",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ2QDkJ-qhaYgRTo6OHbxalj8",
        "user_ratings_total": 517
      },
      {
        "geocode": [
          38.2422212,
          -85.513464
        ],
        "Rest_Name": "Middletown",
        "address": "13006 Shelbyville Rd",
        "id": "20245",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJSV6JIbKYaYgRJM9vkL24bxE",
        "user_ratings_total": 454
      },
      {
        "geocode": [
          38.9971,
          -84.65434
        ],
        "Rest_Name": "Mall Road",
        "address": "7683 Mall Rd",
        "id": "20246",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJbyxVhFHGQYgRtxZUYjZ_pEA",
        "user_ratings_total": 621
      },
      {
        "geocode": [
          38.22657,
          -84.53513
        ],
        "Rest_Name": "Magnolia Dr. & Connector Rd.",
        "address": "106 Magnolia Crossing",
        "id": "20247",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ9TlCfyY_QogRAxGWf60mXpw",
        "user_ratings_total": 542
      },
      {
        "geocode": [
          30.5155207,
          -90.4785495
        ],
        "Rest_Name": "Hammond",
        "address": "1720 W Thomas",
        "id": "20248",
        "type": "Qdoba",
        "rating": 4.6,
        "place_id": "ChIJaVgS904jJ4YRpsPpFaVyMpA",
        "user_ratings_total": 74
      },
      {
        "geocode": [
          30.43151,
          -91.11283
        ],
        "Rest_Name": "Corporate",
        "address": "7415 Corporate Blvd",
        "id": "20249",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJI1siIEuoD4gRuFAp748-W8w",
        "user_ratings_total": 476
      },
      {
        "geocode": [
          42.19945,
          -71.77834
        ],
        "Rest_Name": "Blackstone Valley Crossing",
        "address": "70 Worcester Providence Tpk",
        "id": "20250",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJDxMTEXEF5IkRA1lMsCdW3HU",
        "user_ratings_total": 501
      },
      {
        "geocode": [
          41.67191,
          -71.15764
        ],
        "Rest_Name": "South Coast Marketplace",
        "address": "544 William S Canning Blvd",
        "id": "20251",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJ_9FAFzD_5IkReXdha7sgYgA",
        "user_ratings_total": 436
      },
      {
        "geocode": [
          42.2875458,
          -71.6720986
        ],
        "Rest_Name": "Northbourough Crossing",
        "address": "4104 Shops Way",
        "id": "20252",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJHRXTZPQJ5IkREhNFgkkSLrA",
        "user_ratings_total": 340
      },
      {
        "geocode": [
          42.014,
          -71.22966
        ],
        "Rest_Name": "Mansfield Crossing",
        "address": "280 School St",
        "id": "20253",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJMS51Gjpi5IkRVm7XWHpg-4k",
        "user_ratings_total": 309
      },
      {
        "geocode": [
          42.33814,
          -71.58872
        ],
        "Rest_Name": "Marlborough",
        "address": "180 Boston Post Rd W",
        "id": "20254",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ_deQ4MT044kRsyECOw6yyFQ",
        "user_ratings_total": 410
      },
      {
        "geocode": [
          42.3929091,
          -71.5956692
        ],
        "Rest_Name": "Highland Commons",
        "address": "42 Highland Common E",
        "id": "20255",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJqRRdSyXhEYgRGLzq8jV48Mw",
        "user_ratings_total": 279
      },
      {
        "geocode": [
          42.30014,
          -71.29085
        ],
        "Rest_Name": "Linden Square",
        "address": "185 Linden St",
        "id": "20256",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          42.22059,
          -71.03058
        ],
        "Rest_Name": "Braintree",
        "address": "30 Forbes Rd",
        "id": "20257",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJyVcF9xZ944kRuaRlNytAEJ0",
        "user_ratings_total": 562
      },
      {
        "geocode": [
          41.774462,
          -70.742794
        ],
        "Rest_Name": "Wareham Crossing",
        "address": "2421 Cranberry Hwy",
        "id": "20258",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJZTnIOGrB5IkRWmOQQmy7W0I",
        "user_ratings_total": 424
      },
      {
        "geocode": [
          42.45267,
          -71.23269
        ],
        "Rest_Name": "Bedford St./ Lexington",
        "address": "46 Bedford St",
        "id": "20259",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJJZIpRtGd44kR63WlKhiLOzY",
        "user_ratings_total": 387
      },
      {
        "geocode": [
          42.3648721,
          -71.0618368
        ],
        "Rest_Name": "Causeway",
        "address": "101 Causeway St",
        "id": "20260",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          42.40416,
          -71.08141
        ],
        "Rest_Name": "Medford",
        "address": "523 Fellsway",
        "id": "20261",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ8Yg7Oztx44kRO4pPSt9ONUU",
        "user_ratings_total": 525
      },
      {
        "geocode": [
          42.50432,
          -71.13194
        ],
        "Rest_Name": "Mishawum/ Woburn",
        "address": "300 Mishawum Rd",
        "id": "20262",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJKfAw28R044kR00A0C330nyU",
        "user_ratings_total": 789
      },
      {
        "geocode": [
          42.4847259,
          -70.9007142
        ],
        "Rest_Name": "Paradise Rd./ Swampscott",
        "address": "1016 Paradise Rd",
        "id": "20263",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJCyUFLq8U44kRiACFHQOK6dg",
        "user_ratings_total": 243
      },
      {
        "geocode": [
          42.54736,
          -70.94721
        ],
        "Rest_Name": "Andover St./ Peabody",
        "address": "229 Andover St",
        "id": "20264",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ2dHNxc4T44kRt-6TvuBHO9g",
        "user_ratings_total": 521
      },
      {
        "geocode": [
          49.8304615,
          -97.1064552
        ],
        "Rest_Name": "St Vital Square",
        "address": "831 Dakota Street",
        "id": "20265",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          49.82344,
          -97.20162
        ],
        "Rest_Name": "Kenaston",
        "address": "1659 Kenaston Blvd",
        "id": "20266",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJT_X02BZ16lIRoPBx9k_Gyic",
        "user_ratings_total": 870
      },
      {
        "geocode": [
          49.89691,
          -97.05919
        ],
        "Rest_Name": "Regent",
        "address": "20-1545 Regent Ave W",
        "id": "20267",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJu3MBhAhx6lIRCd60nWGTJl4",
        "user_ratings_total": 787
      },
      {
        "geocode": [
          49.89364,
          -97.19502
        ],
        "Rest_Name": "Ellice Ave",
        "address": "1340 Ellice Ave",
        "id": "20268",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ26gHj55z6lIR5yZRl0s6ve4",
        "user_ratings_total": 1127
      },
      {
        "geocode": [
          49.952696,
          -97.147551
        ],
        "Rest_Name": "Garden City",
        "address": "2315 McPhillips St",
        "id": "20269",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJn4N3WwJx6lIRLh_7DCuyE94",
        "user_ratings_total": 187
      },
      {
        "geocode": [
          49.83565,
          -99.96293
        ],
        "Rest_Name": "Brandon MB",
        "address": "2-1830 Park Ave",
        "id": "20270",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJk4G0laqQ51IR0j2Dvc4Q2KI",
        "user_ratings_total": 583
      },
      {
        "geocode": [
          39.1004,
          -76.5643
        ],
        "Rest_Name": "Severna Park",
        "address": "147 Ritchie Hwy",
        "id": "20271",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJy4-sCun7t4kRgR_m_sFO2t4",
        "user_ratings_total": 406
      },
      {
        "geocode": [
          39.33371,
          -76.44604
        ],
        "Rest_Name": "Middle River",
        "address": "1470 Martin Blvd",
        "id": "20272",
        "type": "Qdoba",
        "rating": 3.3,
        "place_id": "ChIJq_ghc339x4kRQRfpW71YmwM",
        "user_ratings_total": 183
      },
      {
        "geocode": [
          39.03315,
          -76.68568
        ],
        "Rest_Name": "Waugh Chapel Towne Center",
        "address": "1404 S Main Chapel Way",
        "id": "20273",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJz_zaIJvvt4kRUazSFqecjVU",
        "user_ratings_total": 423
      },
      {
        "geocode": [
          39.3690145,
          -76.4519126
        ],
        "Rest_Name": "Campbell",
        "address": "5282 Campbell Blvd",
        "id": "20274",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJAxlpo6Hix4kRBSNL8XU1ecQ",
        "user_ratings_total": 620
      },
      {
        "geocode": [
          39.1955655,
          -76.612561
        ],
        "Rest_Name": "Ritchie",
        "address": "6653 Ritchie Hwy",
        "id": "20275",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ74okpWj9t4kRhLp1Sb7H67U",
        "user_ratings_total": 775
      },
      {
        "geocode": [
          39.27765,
          -76.56675
        ],
        "Rest_Name": "Broom Street Factory",
        "address": "3500 Boston St",
        "id": "20276",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          39.52477,
          -76.35696
        ],
        "Rest_Name": "Harford Mall",
        "address": "696 Belair Rd",
        "id": "20277",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJzdEErN7dx4kRzkq2s28qQsY",
        "user_ratings_total": 506
      },
      {
        "geocode": [
          39.2894283,
          -76.6221145
        ],
        "Rest_Name": "Westside",
        "address": "426 W Baltimore St. Suite 110",
        "id": "20278",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJMZhzchUFyIkR-0VnXSf03Lc",
        "user_ratings_total": 11
      },
      {
        "geocode": [
          39.3954337,
          -76.5757113
        ],
        "Rest_Name": "Joppa",
        "address": "1255 E Joppa Rd",
        "id": "20279",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJg9TZERoPyIkRwqwRlmxYEuU",
        "user_ratings_total": 701
      },
      {
        "geocode": [
          39.3713759,
          -76.6105153
        ],
        "Rest_Name": "Rogers Forge",
        "address": "6304 1/2 York Rd",
        "id": "20280",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJuzvwq4kPyIkRlXto70jsDaw",
        "user_ratings_total": 458
      },
      {
        "geocode": [
          39.19918,
          -76.8196
        ],
        "Rest_Name": "Dobbin",
        "address": "6476 Dobbin Center Way",
        "id": "20281",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ0XO8l3ngt4kRmd0sA4KCPgE",
        "user_ratings_total": 1010
      },
      {
        "geocode": [
          39.27746,
          -76.82467
        ],
        "Rest_Name": "Ellicott City",
        "address": "9050 Baltimore National Pkwy",
        "id": "20282",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJEwzo6X0fyIkROKn6UAL4nA0",
        "user_ratings_total": 800
      },
      {
        "geocode": [
          39.0567475,
          -76.9655212
        ],
        "Rest_Name": "Tech",
        "address": "12265 Tech Rd",
        "id": "20283",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.42099,
          -76.77918
        ],
        "Rest_Name": "Owings Mills Sq Shopping Ctr",
        "address": "10450 Owings Mills Blvd",
        "id": "20284",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJpfkdowcXyIkRX1cisLkfYYI",
        "user_ratings_total": 783
      },
      {
        "geocode": [
          39.0585027,
          -77.1221123
        ],
        "Rest_Name": "Rollins",
        "address": "128 Rollins Ave",
        "id": "20285",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJubQL2zTMt4kRdRzHgS3pl9Y",
        "user_ratings_total": 1040
      },
      {
        "geocode": [
          39.1217253,
          -77.1779251
        ],
        "Rest_Name": "Rock Grove",
        "address": "16260 Frederick Rd",
        "id": "20286",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJTailMF-7D4gRkl1s_rMwBP4",
        "user_ratings_total": 481
      },
      {
        "geocode": [
          39.40313,
          -76.94863
        ],
        "Rest_Name": "Liberty Rd. near MD 32",
        "address": "1325 Liberty Rd",
        "id": "20287",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJK9tcIMwkyIkRJ8TXZH8h3do",
        "user_ratings_total": 359
      },
      {
        "geocode": [
          39.3854909,
          -77.4052771
        ],
        "Rest_Name": "Gateway Shoppes",
        "address": "5597 Spectrum Dr.",
        "id": "20288",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          42.083122,
          -86.484854
        ],
        "Rest_Name": "Niles Ave",
        "address": "2909 Niles Ave",
        "id": "20289",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ70tUt2zEEIgRQIic2jXDvFU",
        "user_ratings_total": 709
      },
      {
        "geocode": [
          42.2614859,
          -85.1921865
        ],
        "Rest_Name": "Battle Creek",
        "address": "5335 Beckley Rd",
        "id": "20290",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJW0uuAdftF4gRrqFI6ZUVQm0",
        "user_ratings_total": 1151
      },
      {
        "geocode": [
          42.2177359,
          -85.5898194
        ],
        "Rest_Name": "Portage",
        "address": "6800 S Westnedge Ave",
        "id": "20291",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJsT3hAhGeF4gR8lWVwyDazJU",
        "user_ratings_total": 860
      },
      {
        "geocode": [
          42.2596814,
          -85.5900863
        ],
        "Rest_Name": "Whites Rd",
        "address": "3150 S Westnedge Ave",
        "id": "20292",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJC6gQfZCdF4gRUTvK5hUXPzA",
        "user_ratings_total": 747
      },
      {
        "geocode": [
          42.2698066,
          -84.427421
        ],
        "Rest_Name": "Boardman Rd",
        "address": "1101 Boardman Rd",
        "id": "20293",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          42.2712154,
          -85.6478023
        ],
        "Rest_Name": "Stadium & Drake",
        "address": "2061 S Drake Rd",
        "id": "20294",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJjZUzD_52F4gR2BIhmODtPPE",
        "user_ratings_total": 775
      },
      {
        "geocode": [
          42.32789,
          -85.51423
        ],
        "Rest_Name": "Gull",
        "address": "5901 Gull Rd",
        "id": "20295",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJD4RwVJiEF4gRJOhIcZ8Wl9I",
        "user_ratings_total": 719
      },
      {
        "geocode": [
          42.2959151,
          -85.6440089
        ],
        "Rest_Name": "Kalamazoo",
        "address": "4403 W Main St",
        "id": "20296",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJyalh-3V3F4gRvWDIpPsp_C8",
        "user_ratings_total": 886
      },
      {
        "geocode": [
          42.2871365,
          -83.8391721
        ],
        "Rest_Name": "Zeeb Rd",
        "address": "80 S Zeeb Rd",
        "id": "20297",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJwy1PgVSxPIgRGcS6n_2-m-I",
        "user_ratings_total": 116
      },
      {
        "geocode": [
          42.253893,
          -83.7518551
        ],
        "Rest_Name": "Main",
        "address": "2252 S Main St",
        "id": "20298",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJB_0s3L5UDogRSsCCCR16ETA",
        "user_ratings_total": 706
      },
      {
        "geocode": [
          42.2751271,
          -83.741547
        ],
        "Rest_Name": "University of Michigan",
        "address": "530 S State St. Student Union",
        "id": "20299",
        "type": "Qdoba",
        "rating": 2,
        "place_id": "ChIJE3sZtu2vPIgRstKowKfp-qY",
        "user_ratings_total": 11
      },
      {
        "geocode": [
          42.2571362,
          -83.6961492
        ],
        "Rest_Name": "Washtenaw",
        "address": "3279 Washtenaw Ave",
        "id": "20300",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ01vfCyGvPIgRlh18oZ_khvk",
        "user_ratings_total": 882
      },
      {
        "geocode": [
          41.942842,
          -83.403293
        ],
        "Rest_Name": "Monroe",
        "address": "1570 N Telegraph Rd",
        "id": "20301",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJWQSMe2xvO4gRmBlbZCjxqgI",
        "user_ratings_total": 764
      },
      {
        "geocode": [
          42.7405283,
          -84.6265336
        ],
        "Rest_Name": "Saginaw",
        "address": "5415 W Saginaw Hwy",
        "id": "20302",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ21O3Wl6VIogRKl5g83us5aA",
        "user_ratings_total": 1113
      },
      {
        "geocode": [
          42.736539,
          -84.509274
        ],
        "Rest_Name": "Clippert",
        "address": "301 N Clippert St",
        "id": "20303",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJc8nmeOPpIogRT5bKKBDLEx4",
        "user_ratings_total": 1244
      },
      {
        "geocode": [
          42.7313914,
          -84.4686871
        ],
        "Rest_Name": "Grand River",
        "address": "1127 E Grand River",
        "id": "20304",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJqSVd6yfoIogRmTgBSoO4Z0k",
        "user_ratings_total": 364
      },
      {
        "geocode": [
          42.71976,
          -84.41962
        ],
        "Rest_Name": "Okemos",
        "address": "1941 W Grand River Ave",
        "id": "20305",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJAYpvBF3dIogRRjY1Ebx7Z-I",
        "user_ratings_total": 349
      },
      {
        "geocode": [
          42.84585,
          -85.622155
        ],
        "Rest_Name": "Kalamazoo & Marketplace Dr.",
        "address": "1673 Marketplace Dr Se",
        "id": "20306",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJC6gQfZCdF4gRUTvK5hUXPzA",
        "user_ratings_total": 747
      },
      {
        "geocode": [
          42.8595337,
          -85.7225508
        ],
        "Rest_Name": "Metro Health Village",
        "address": "2380 Health Dr SW",
        "id": "20307",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJQwyLVnK3GYgR-SL5U5L8ymU",
        "user_ratings_total": 327
      },
      {
        "geocode": [
          42.82562,
          -86.09281
        ],
        "Rest_Name": "West Shore & Riley",
        "address": "3155 West Shore Dr",
        "id": "20308",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJi-znPp2SGYgRfdHXNf2TInU",
        "user_ratings_total": 477
      },
      {
        "geocode": [
          42.9135523,
          -85.5307531
        ],
        "Rest_Name": "28th & Kraft",
        "address": "5595 28th St Se",
        "id": "20309",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJt0VCJeZNGIgR9NCc5qChAUA",
        "user_ratings_total": 843
      },
      {
        "geocode": [
          42.8829525,
          -85.7594005
        ],
        "Rest_Name": "Rivertown Pkwy",
        "address": "3871 Rivertown Pkwy",
        "id": "20310",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJy0e75qGwGYgRyd8baPA1Y54",
        "user_ratings_total": 996
      },
      {
        "geocode": [
          42.9120932,
          -85.5878594
        ],
        "Rest_Name": "28th and Shaffer",
        "address": "3214 28th St SE",
        "id": "20311",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJVZypJDlNGIgR8GrBQyFY2z0",
        "user_ratings_total": 1210
      },
      {
        "geocode": [
          42.2234432,
          -83.4843628
        ],
        "Rest_Name": "Belleville",
        "address": "10889 Belleville Rd",
        "id": "20312",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ0aXY-pdQO4gRZA7VyG3Mfac",
        "user_ratings_total": 871
      },
      {
        "geocode": [
          43.05605,
          -85.57915
        ],
        "Rest_Name": "Northland",
        "address": "5150 Northland Drive NE",
        "id": "20313",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJD5K6KxlVGIgRLfy6jz6T2Xk",
        "user_ratings_total": 823
      },
      {
        "geocode": [
          43.688868,
          -85.484613
        ],
        "Rest_Name": "Big Rapids",
        "address": "836 S State St",
        "id": "20314",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJbWwsoCbTGIgRvG8wFy0NPi0",
        "user_ratings_total": 540
      },
      {
        "geocode": [
          43.003,
          -85.59116
        ],
        "Rest_Name": "Beltline and Knapp St.",
        "address": "2211 E Beltline Ave Ne",
        "id": "20315",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJS9CQNUVTGIgRcEQByUhwmIQ",
        "user_ratings_total": 669
      },
      {
        "geocode": [
          43.0217861,
          -85.6896664
        ],
        "Rest_Name": "Alpine & I-96",
        "address": "3225 Alpine Ave Nw",
        "id": "20316",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJEcw4s1apGYgRgQnPI47s0L8",
        "user_ratings_total": 1141
      },
      {
        "geocode": [
          42.9709529,
          -85.6673834
        ],
        "Rest_Name": "Michigan Street",
        "address": "25 Michigan St NE",
        "id": "20317",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJKQ-rb8mtGYgRVEmhbmQ_UdI",
        "user_ratings_total": 423
      },
      {
        "geocode": [
          43.586818,
          -84.7667594
        ],
        "Rest_Name": "Mission",
        "address": "1529 S Mission St",
        "id": "20318",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          43.155,
          -86.20505
        ],
        "Rest_Name": "Sternberg & Harvey St.",
        "address": "5350 S Harvey St",
        "id": "20319",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          43.66029,
          -84.2464
        ],
        "Rest_Name": "Eastman",
        "address": "6910 Eastman Rd",
        "id": "20320",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          44.28054,
          -85.4062
        ],
        "Rest_Name": "Cadillac",
        "address": "2204 N Mitchell St",
        "id": "20321",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJYxqqYkUIH4gR3Xq5s20qUMg",
        "user_ratings_total": 947
      },
      {
        "geocode": [
          42.9974225,
          -84.1581144
        ],
        "Rest_Name": "Owosso",
        "address": "910 E Main St",
        "id": "20322",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ0W-HuxAHI4gRxSBPjCyzzYc",
        "user_ratings_total": 676
      },
      {
        "geocode": [
          45.0278968,
          -84.6856891
        ],
        "Rest_Name": "Gaylord",
        "address": "832 W Main St",
        "id": "20323",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJzYnk7YpFNU0Rp3LvtuUSdJ0",
        "user_ratings_total": 1140
      },
      {
        "geocode": [
          44.7338437,
          -85.6007313
        ],
        "Rest_Name": "Airport",
        "address": "1280 W South Airport Rd",
        "id": "20324",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJk21joCzNH4gR67Vk2e7W8qo",
        "user_ratings_total": 603
      },
      {
        "geocode": [
          44.73332,
          -85.64744
        ],
        "Rest_Name": "Traverse City",
        "address": "3500 Marketplace Cir",
        "id": "20325",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ0e0ZA1UyHogRcWQb-mitHSs",
        "user_ratings_total": 657
      },
      {
        "geocode": [
          43.4825599,
          -83.9758524
        ],
        "Rest_Name": "Bay",
        "address": "5165 Bay Rd",
        "id": "20326",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          42.9915609,
          -83.7378228
        ],
        "Rest_Name": "Miller Rd & I-75",
        "address": "2415 E Austins Pkwy",
        "id": "20327",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJq6q_Am14I4gR82RifUi9Q_w",
        "user_ratings_total": 1307
      },
      {
        "geocode": [
          42.92013,
          -83.62149
        ],
        "Rest_Name": "Corner of Holly and Saginaw",
        "address": "12488 S Saginaw St",
        "id": "20328",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          42.7130053,
          -83.4109785
        ],
        "Rest_Name": "Inside Clarkston Oaks Shopping Center",
        "address": "6461 Dixie Hwy",
        "id": "20329",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          43.0314108,
          -82.4553511
        ],
        "Rest_Name": "On 24th Ave Across from Birchwood Mall",
        "address": "4214 24th Ave",
        "id": "20330",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJTailMF-7D4gRkl1s_rMwBP4",
        "user_ratings_total": 481
      },
      {
        "geocode": [
          42.5373931,
          -83.7839306
        ],
        "Rest_Name": "Brighton",
        "address": "8709 W Grand River Ave",
        "id": "20331",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJYc45nmZPI4gR_xX_BuxZ7rY",
        "user_ratings_total": 155
      },
      {
        "geocode": [
          42.5885981,
          -83.8779124
        ],
        "Rest_Name": "Livingston",
        "address": "3825 E Grand River",
        "id": "20332",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          42.48385,
          -83.47616
        ],
        "Rest_Name": "Novi",
        "address": "26401 Novi Rd",
        "id": "20333",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJL1d_Qx2vJIgR0jV9_GdboqY",
        "user_ratings_total": 761
      },
      {
        "geocode": [
          42.4374307,
          -83.4370692
        ],
        "Rest_Name": "8th & Haggerty",
        "address": "20561 Haggerty Rd",
        "id": "20334",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJI0bij-OtJIgRuTQzL76IAY8",
        "user_ratings_total": 541
      },
      {
        "geocode": [
          42.4094474,
          -83.4140215
        ],
        "Rest_Name": "Livonia",
        "address": "16971 Newburgh Rd",
        "id": "20335",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJhZcph3qyJIgRho5_4frp5Q4",
        "user_ratings_total": 555
      },
      {
        "geocode": [
          42.323402,
          -83.449552
        ],
        "Rest_Name": "Canton",
        "address": "41420 Ford Rd",
        "id": "20336",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJPzddwVhSO4gR3zoNDumkM1c",
        "user_ratings_total": 1127
      },
      {
        "geocode": [
          42.33783,
          -83.40116
        ],
        "Rest_Name": "Warren Rd and Central City Pkwy",
        "address": "36575 Warren Rd",
        "id": "20337",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJU3B0gmtNO4gRiDNM9ucwAuA",
        "user_ratings_total": 566
      },
      {
        "geocode": [
          42.4992704,
          -83.3762118
        ],
        "Rest_Name": "Farmington Hills",
        "address": "33224 W 12 Mile Rd",
        "id": "20338",
        "type": "Qdoba",
        "rating": 3.2,
        "place_id": "ChIJV5S0DYuwJIgRGdf3lK8VHb0",
        "user_ratings_total": 462
      },
      {
        "geocode": [
          42.513266,
          -83.2848376
        ],
        "Rest_Name": "Telegraph and 13 mile",
        "address": "30758 Telegraph Rd",
        "id": "20339",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJJdFrAim4JIgRcUdLd20zCT0",
        "user_ratings_total": 566
      },
      {
        "geocode": [
          42.214197,
          -83.353865
        ],
        "Rest_Name": "DTW McNamara",
        "address": "2565 World Gateway Pl Airport-Dtw Mcnamara Terminal",
        "id": "20340",
        "type": "Qdoba",
        "rating": 2.6,
        "place_id": "ChIJBdRvp0NPO4gRJ8nlK4w0UMM",
        "user_ratings_total": 237
      },
      {
        "geocode": [
          42.4751158,
          -83.2414766
        ],
        "Rest_Name": "Evergreen Rd and 10 mile",
        "address": "25243 Evergreen Rd",
        "id": "20341",
        "type": "Qdoba",
        "rating": 3.2,
        "place_id": "ChIJaSKhFy7IJIgRVMy1kk0EG2g",
        "user_ratings_total": 796
      },
      {
        "geocode": [
          42.6998926,
          -83.307574
        ],
        "Rest_Name": "Near the Corner of Baldwin Rd. and Alberta St.",
        "address": "3945 Baldwin Rd",
        "id": "20342",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJzy2Oh7qVJIgR3CfUq41dE2k",
        "user_ratings_total": 686
      },
      {
        "geocode": [
          42.5159875,
          -83.1833847
        ],
        "Rest_Name": "13 & Woodward",
        "address": "30754 Woodward Ave",
        "id": "20343",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJjbT2nBfGJIgRNQoteiIfJz0",
        "user_ratings_total": 592
      },
      {
        "geocode": [
          42.28078,
          -83.20844
        ],
        "Rest_Name": "Fairlane Dr.",
        "address": "3452 Fairlane Dr",
        "id": "20344",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          42.7109137,
          -83.0358229
        ],
        "Rest_Name": "Van Dyke",
        "address": "56653 Van Dyke Ave",
        "id": "20345",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJZXuhsormJIgRE17AopHgqqc",
        "user_ratings_total": 464
      },
      {
        "geocode": [
          42.6357015,
          -83.1325437
        ],
        "Rest_Name": "Auburn Rd. and Rochester Rd.",
        "address": "3014 Rochester Rd S",
        "id": "20346",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJh9X121zCJIgRIUcQQq6K5C0",
        "user_ratings_total": 649
      },
      {
        "geocode": [
          42.670346,
          -83.006531
        ],
        "Rest_Name": "Napi Shoppes",
        "address": "12387 23 Mile Rd",
        "id": "20347",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          42.675286,
          -82.8347366
        ],
        "Rest_Name": "New Baltimore/Chesterfield",
        "address": "27363 23 Mile Road",
        "id": "20348",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJaY76ViYfJYgRijDhsI5WUBg",
        "user_ratings_total": 555
      },
      {
        "geocode": [
          42.6282468,
          -82.9760711
        ],
        "Rest_Name": "Hall Road",
        "address": "15099 Hall Rd",
        "id": "20349",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ_SwFFWveJIgRqXE0osM6X7Y",
        "user_ratings_total": 642
      },
      {
        "geocode": [
          42.56311,
          -83.14128
        ],
        "Rest_Name": "Big Beaver",
        "address": "301 E Big Beaver Rd",
        "id": "20350",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJGZ1nlWbEJIgR_PRxr2DZLaE",
        "user_ratings_total": 633
      },
      {
        "geocode": [
          42.5319814,
          -82.9132182
        ],
        "Rest_Name": "Gratiot Ave.",
        "address": "31982 Gratiot Ave",
        "id": "20351",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJB_SWvIbYJIgRxfkZSvVQ0cU",
        "user_ratings_total": 1211
      },
      {
        "geocode": [
          42.50704,
          -83.04885
        ],
        "Rest_Name": "Mound",
        "address": "5779 E 12 Mile Rd",
        "id": "20352",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJKzuIbpraJIgR_DeYPRKE3eE",
        "user_ratings_total": 841
      },
      {
        "geocode": [
          42.1383221,
          -83.2265917
        ],
        "Rest_Name": "Woodhaven",
        "address": "23378 Allen Rd",
        "id": "20353",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ18ZfqHs5O4gRls_89xBpfOI",
        "user_ratings_total": 176
      },
      {
        "geocode": [
          42.19846,
          -83.24903
        ],
        "Rest_Name": "Taylor - Eureka Rd & Rancho Rd",
        "address": "22040 Eureka Rd",
        "id": "20354",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJQ21lUv43O4gRIJYiHdzd2-g",
        "user_ratings_total": 838
      },
      {
        "geocode": [
          44.035569,
          -91.618192
        ],
        "Rest_Name": "Frontenac",
        "address": "950 Frontenac Drive",
        "id": "20355",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJselZYl9t-YcRCUUzyJQo_bY",
        "user_ratings_total": 267
      },
      {
        "geocode": [
          44.02337,
          -92.46562
        ],
        "Rest_Name": "Kahler Hotel",
        "address": "180 Center Street W",
        "id": "20356",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJn2bmPC9f94cRdjJ-GwxaKvM",
        "user_ratings_total": 194
      },
      {
        "geocode": [
          43.684751,
          -92.9863345
        ],
        "Rest_Name": "Austin",
        "address": "1011 18th Avenue NW",
        "id": "20357",
        "type": "Qdoba",
        "rating": 1,
        "place_id": "ChIJAZdN01jJRIYRMBK0x7u5xU8",
        "user_ratings_total": 6
      },
      {
        "geocode": [
          43.6814254,
          -93.3582315
        ],
        "Rest_Name": "Albert Lea",
        "address": "2522 Bridge Ave",
        "id": "20358",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJO4Zr5h418YcR4O_uW53vUxI",
        "user_ratings_total": 330
      },
      {
        "geocode": [
          44.83481,
          -93.15876
        ],
        "Rest_Name": "Promenade Place",
        "address": "1298 Promenade Pl",
        "id": "20359",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          44.94253,
          -92.93318
        ],
        "Rest_Name": "Radio Drive",
        "address": "345 Radio Dr",
        "id": "20360",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJm1OL3CHY94cRzMwCZL1lopg",
        "user_ratings_total": 715
      },
      {
        "geocode": [
          44.854804,
          -93.241696
        ],
        "Rest_Name": "Mall of America",
        "address": "60 E Broadway Space C364",
        "id": "20361",
        "type": "Qdoba",
        "rating": 3.3,
        "place_id": "ChIJB6clCGwv9ocRe-XpHzWNMgE",
        "user_ratings_total": 199
      },
      {
        "geocode": [
          44.9160768,
          -93.1016074
        ],
        "Rest_Name": "Roberts St",
        "address": "1709 S Roberts St",
        "id": "20362",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJBUZJK7wsDogRON4091qn_m4",
        "user_ratings_total": 327
      },
      {
        "geocode": [
          44.7795685,
          -93.4690266
        ],
        "Rest_Name": "Dean Lakes",
        "address": "4057 Dean Lakes Blvd",
        "id": "20363",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ5SLjt3IX9ocR-87mO24mjWk",
        "user_ratings_total": 910
      },
      {
        "geocode": [
          44.85926,
          -93.4249
        ],
        "Rest_Name": "Leona",
        "address": "11611 Leona Rd",
        "id": "20364",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJu9zD5qMY9ocR_9VvlDdl2yU",
        "user_ratings_total": 792
      },
      {
        "geocode": [
          44.8681682,
          -94.3763475
        ],
        "Rest_Name": "Hutchinson",
        "address": "1380 Hwy 15 S",
        "id": "20365",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJc-RQbriB9YcRLOt55Zm0rZ0",
        "user_ratings_total": 567
      },
      {
        "geocode": [
          45.10298,
          -95.03677
        ],
        "Rest_Name": "Willmar",
        "address": "600 19th Ave SE",
        "id": "20366",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJER-GPjhZtVIRyiYEu_J05rs",
        "user_ratings_total": 587
      },
      {
        "geocode": [
          44.8390431,
          -93.7969691
        ],
        "Rest_Name": "Waconia",
        "address": "10720 W 10th St",
        "id": "20367",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJTYGq4Pr99YcRAdAq6UdWm-w",
        "user_ratings_total": 210
      },
      {
        "geocode": [
          44.829261,
          -93.6016168
        ],
        "Rest_Name": "Pioneer",
        "address": "194 Pioneer Trail",
        "id": "20368",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJB_0s3L5UDogRSsCCCR16ETA",
        "user_ratings_total": 706
      },
      {
        "geocode": [
          45.03371,
          -93.4103
        ],
        "Rest_Name": "Nathan Lane",
        "address": "4345 Nathan Ln",
        "id": "20369",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          44.97442,
          -93.27478
        ],
        "Rest_Name": "Nicollet Mall",
        "address": "50 South 10th St",
        "id": "20370",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJV3GJj5Yys1IRLsh2yT4-2Gs",
        "user_ratings_total": 208
      },
      {
        "geocode": [
          44.9802,
          -93.23576
        ],
        "Rest_Name": "Dinkytown",
        "address": "324 14th Ave SE",
        "id": "20371",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJC86CgBEts1IRUFaaWFKVSrw",
        "user_ratings_total": 488
      },
      {
        "geocode": [
          45.5525095,
          -94.2092637
        ],
        "Rest_Name": "St. Cloud",
        "address": "4110 Division St",
        "id": "20372",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ0T7FkXZftFIRYf2fJdRGeuw",
        "user_ratings_total": 362
      },
      {
        "geocode": [
          46.81763,
          -92.0775
        ],
        "Rest_Name": "Duluth",
        "address": "1020 Woodland Ave",
        "id": "20373",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ84Fn9ietr1IRTe2XWdXpNOs",
        "user_ratings_total": 809
      },
      {
        "geocode": [
          45.86117,
          -95.3842
        ],
        "Rest_Name": "Viking Plaza",
        "address": "3019 Hwy 29 S",
        "id": "20374",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJxdRzo9EPylIRw2eJEGtfEvE",
        "user_ratings_total": 709
      },
      {
        "geocode": [
          47.46264,
          -94.86816
        ],
        "Rest_Name": "Paul Bunyan",
        "address": "1000 Paul Bunyan Dr S",
        "id": "20375",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          46.8497469,
          -96.7668275
        ],
        "Rest_Name": "Holiday",
        "address": "910 Holiday Dr",
        "id": "20376",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJN-EQdrfOyFIR3ajsU1zthAw",
        "user_ratings_total": 489
      },
      {
        "geocode": [
          37.30039,
          -89.568381
        ],
        "Rest_Name": "William",
        "address": "3019 William St",
        "id": "20377",
        "type": "Qdoba",
        "rating": null,
        "place_id": "ChIJxQZDW9M0DogRWmbRLNEZjqE",
        "user_ratings_total": null
      },
      {
        "geocode": [
          37.79056,
          -90.43372
        ],
        "Rest_Name": "Karsch Blvd",
        "address": "708 W Karsch Blvd",
        "id": "20378",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ6yxP4EBF2IcR__4Bfehk1w8",
        "user_ratings_total": 501
      },
      {
        "geocode": [
          36.648175,
          -93.217014
        ],
        "Rest_Name": "Branson Landing",
        "address": "482 Branson Landing Blvd",
        "id": "20379",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJCemE64EDz4cRAVjvTXYmrMk",
        "user_ratings_total": 560
      },
      {
        "geocode": [
          37.13953,
          -93.32023
        ],
        "Rest_Name": "Kansas Expressway",
        "address": "4127 S Kansas Expressway",
        "id": "20380",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ_bGCPj9kz4cR5BNZ6B2R29c",
        "user_ratings_total": 624
      },
      {
        "geocode": [
          37.1588481,
          -93.2827665
        ],
        "Rest_Name": "Battlefield",
        "address": "900 E Battlefield Rd",
        "id": "20381",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJfeP4Yplkz4cRyl8_Vwz7wjc",
        "user_ratings_total": 323
      },
      {
        "geocode": [
          37.181306,
          -93.2431014
        ],
        "Rest_Name": "Sunshine",
        "address": "2631 E Sunshine St",
        "id": "20382",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJt-IEd7x8z4cRmP_Q9HoVJ-M",
        "user_ratings_total": 784
      },
      {
        "geocode": [
          37.20666,
          -93.28695
        ],
        "Rest_Name": "Kimbrough",
        "address": "401 S Kimbrough Ave",
        "id": "20383",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ1RYPGx1jz4cR3tFobblFKyc",
        "user_ratings_total": 740
      },
      {
        "geocode": [
          37.0837502,
          -94.474445
        ],
        "Rest_Name": "Joplin",
        "address": "3102 E 7th St",
        "id": "20384",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJHwDIrIB8yIcRnaiu05QXIFM",
        "user_ratings_total": 457
      },
      {
        "geocode": [
          38.438475,
          -90.3852461
        ],
        "Rest_Name": "Arnold Commons",
        "address": "905 Arnold Commons Dr",
        "id": "20385",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJUakbNjLE2IcRLwIZSwGWhNM",
        "user_ratings_total": 722
      },
      {
        "geocode": [
          38.5026194,
          -90.4449564
        ],
        "Rest_Name": "Gravois Bluffs",
        "address": "180 Gravois Bluffs Cir",
        "id": "20386",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJOQybKefP2IcR0_egpY-5nvw",
        "user_ratings_total": 502
      },
      {
        "geocode": [
          38.6052302,
          -90.5849877
        ],
        "Rest_Name": "Chesterfield - Fountain Plaza",
        "address": "15829 Fountain Plaza Dr",
        "id": "20387",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJI6MX_Z7V2IcRrvsaOFk30mk",
        "user_ratings_total": 213
      },
      {
        "geocode": [
          38.50689,
          -90.33005
        ],
        "Rest_Name": "South County Mall",
        "address": "90 S County Center Wy",
        "id": "20388",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJHd6W7GfI2IcR97AJ_UeVLiE",
        "user_ratings_total": 535
      },
      {
        "geocode": [
          38.5943027,
          -90.4973467
        ],
        "Rest_Name": "Manchester Rd.",
        "address": "14169 Manchester Rd",
        "id": "20389",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJI1siIEuoD4gRuFAp748-W8w",
        "user_ratings_total": 476
      },
      {
        "geocode": [
          38.5747655,
          -90.4070603
        ],
        "Rest_Name": "Kirkwood",
        "address": "481 S Kirkwood Rd",
        "id": "20390",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ9bqd6i7M2IcRsb0zRiFxM80",
        "user_ratings_total": 293
      },
      {
        "geocode": [
          38.5939414,
          -90.2990462
        ],
        "Rest_Name": "Chippewa near Hampton Ave.",
        "address": "6417 Chippewa St",
        "id": "20391",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJo-PNDYq12IcRkqnzc7jBS5o",
        "user_ratings_total": 669
      },
      {
        "geocode": [
          38.6024968,
          -90.242023
        ],
        "Rest_Name": "South Grand and Arsenal",
        "address": "3100 S Grand Blvd",
        "id": "20392",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJL-HImGe02IcREcBax10mO7Y",
        "user_ratings_total": 709
      },
      {
        "geocode": [
          38.8123317,
          -94.492672
        ],
        "Rest_Name": "Hwy 58",
        "address": "1931 W Foxwood Dr",
        "id": "20393",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          38.622632,
          -90.5176233
        ],
        "Rest_Name": "Town and Country Plaza",
        "address": "1082 Town&Country Crossing Dr",
        "id": "20394",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.6679456,
          -90.5859747
        ],
        "Rest_Name": "Chesterfield Airport Rd.",
        "address": "16890 Chesterfield Airport Rd",
        "id": "20395",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ23GUx74q34cRFEkRwR7lE2c",
        "user_ratings_total": 348
      },
      {
        "geocode": [
          39.2131307,
          -94.5351516
        ],
        "Rest_Name": "Antioch Rd",
        "address": "5255 N Antioch Rd",
        "id": "20396",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.6210428,
          -90.3335896
        ],
        "Rest_Name": "Maplewood Commons",
        "address": "2001 Maplewood Commons Dr",
        "id": "20397",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJRQ9lyvzK2IcRmTG4Nh3lyQI",
        "user_ratings_total": 793
      },
      {
        "geocode": [
          38.634617,
          -90.232684
        ],
        "Rest_Name": "St Louis University",
        "address": "20 N Grand Blvd Busch Student Center",
        "id": "20398",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ9bU8qce02IcRYZLZgRuJVTI",
        "user_ratings_total": 815
      },
      {
        "geocode": [
          38.6399888,
          -90.244807
        ],
        "Rest_Name": "Sarah and Lindell",
        "address": "4049 Lindell Blvd",
        "id": "20399",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ9bU8qce02IcRYZLZgRuJVTI",
        "user_ratings_total": 815
      },
      {
        "geocode": [
          38.6348295,
          -90.3164429
        ],
        "Rest_Name": "Clayton",
        "address": "6701 Clayton Rd",
        "id": "20400",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ59rGBL_K2IcRybTGeA-PxtE",
        "user_ratings_total": 666
      },
      {
        "geocode": [
          38.7664694,
          -90.7670254
        ],
        "Rest_Name": "Dardenne Town Square",
        "address": "7863 Highway N",
        "id": "20401",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJZVs6sDDO3ocR0kCKKCvexYw",
        "user_ratings_total": 563
      },
      {
        "geocode": [
          38.79467,
          -90.69825
        ],
        "Rest_Name": "Hwy K and Mexico Rd.",
        "address": "1290 State Hwy K",
        "id": "20402",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJrdMVleHQ3ocRN3SixoOcQnw",
        "user_ratings_total": 724
      },
      {
        "geocode": [
          38.7488615,
          -90.6360667
        ],
        "Rest_Name": "Mid Rivers Mall",
        "address": "6005 Mid Rivers Mall Drive",
        "id": "20403",
        "type": "Qdoba",
        "rating": 3.3,
        "place_id": "ChIJter61AXX3ocRH06HXPm6c0k",
        "user_ratings_total": 102
      },
      {
        "geocode": [
          38.77181,
          -90.49487
        ],
        "Rest_Name": "Bass Pro Shopping Center",
        "address": "1315 Bass Pro Dr",
        "id": "20404",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJv1BnPPsu34cRYT0dxYEf-cQ",
        "user_ratings_total": 971
      },
      {
        "geocode": [
          38.8076195,
          -90.2993949
        ],
        "Rest_Name": "Lindbergh & New Halls Ferry Rd",
        "address": "14039 New Halls Ferry Rd",
        "id": "20405",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJ7X-8QR1I34cRBlE8pudrRPw",
        "user_ratings_total": 971
      },
      {
        "geocode": [
          38.7502646,
          -90.424688
        ],
        "Rest_Name": "Rock Road",
        "address": "12121 St. Charles Rock Rd",
        "id": "20406",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJIxtIGJEx34cRhno1huWJf2c",
        "user_ratings_total": 606
      },
      {
        "geocode": [
          38.6996047,
          -90.4208764
        ],
        "Rest_Name": "Page Ave",
        "address": "11500 Page Service Rd",
        "id": "20407",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJCy9YqFAy34cRDZNmM1x_1LM",
        "user_ratings_total": 562
      },
      {
        "geocode": [
          38.669636,
          -90.443207
        ],
        "Rest_Name": "Olive and New Ballas",
        "address": "766 N New Ballas Rd",
        "id": "20408",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJSVwJqKMy34cRrkFNSLc4sBo",
        "user_ratings_total": 670
      },
      {
        "geocode": [
          30.4015259,
          -89.0272489
        ],
        "Rest_Name": "Sweetwater",
        "address": "1109 Cowan Rd Ste A",
        "id": "20409",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          31.32532,
          -89.34455
        ],
        "Rest_Name": "Hardy",
        "address": "3705 Hardy St",
        "id": "20410",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJ234LcxrfnIgRnDBK5WXor9M",
        "user_ratings_total": 583
      },
      {
        "geocode": [
          45.67059,
          -111.07629
        ],
        "Rest_Name": "Bozeman",
        "address": "855 S 29th Ave",
        "id": "20411",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJaU9asJhFRVMRyqS5DISZeYk",
        "user_ratings_total": 646
      },
      {
        "geocode": [
          45.7546487,
          -108.5756
        ],
        "Rest_Name": "King Ave",
        "address": "2350 King Ave",
        "id": "20412",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJBUZJK7wsDogRON4091qn_m4",
        "user_ratings_total": 327
      },
      {
        "geocode": [
          46.61549,
          -112.00539
        ],
        "Rest_Name": "Skyway Drive",
        "address": "2080 Cromwell Dixon Ln",
        "id": "20413",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJM966nvWsB4gRa1EPu3c__KQ",
        "user_ratings_total": 245
      },
      {
        "geocode": [
          47.5119674,
          -111.3158545
        ],
        "Rest_Name": "West Bank Landing",
        "address": "415 3rd Street NW",
        "id": "20414",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          48.2200376,
          -114.329808
        ],
        "Rest_Name": "Kalispell",
        "address": "15 Commons Way",
        "id": "20415",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJOezfVXRaZlMRdB6FxO1SgRM",
        "user_ratings_total": 488
      },
      {
        "geocode": [
          35.26348,
          -81.13505
        ],
        "Rest_Name": "Cox",
        "address": "401 Cox Rd",
        "id": "20416",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJu9VbSb-_VogRIY9uGVRalcI",
        "user_ratings_total": 635
      },
      {
        "geocode": [
          35.02307,
          -80.84859
        ],
        "Rest_Name": "Lancaster Hwy",
        "address": "16631 Lancaster Hwy",
        "id": "20417",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJG3fn_t2CVogR9PCmPuJr57M",
        "user_ratings_total": 376
      },
      {
        "geocode": [
          35.14403,
          -80.9325
        ],
        "Rest_Name": "S.Tryon",
        "address": "8152 South Tryon St",
        "id": "20418",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ9Rf0VnuZVogR67bW01f3fxI",
        "user_ratings_total": 358
      },
      {
        "geocode": [
          35.171873,
          -80.849032
        ],
        "Rest_Name": "Woodlawn",
        "address": "1600 E Woodlawn Rd",
        "id": "20419",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJT42KbVSeVogRNqZqdieDd4w",
        "user_ratings_total": 237
      },
      {
        "geocode": [
          35.22804,
          -80.84213
        ],
        "Rest_Name": "N. Tryon",
        "address": "127 N Tryon St",
        "id": "20420",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJkWHgaCWgVogRdo8JqNlBjBQ",
        "user_ratings_total": 356
      },
      {
        "geocode": [
          35.08279,
          -80.72857
        ],
        "Rest_Name": "Siskey",
        "address": "3321 Siskey Pkwy",
        "id": "20421",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJK4hh6IYmVIgRqGvlHsmub80",
        "user_ratings_total": 240
      },
      {
        "geocode": [
          35.128028,
          -80.702461
        ],
        "Rest_Name": "Matthews",
        "address": "2233 Matthews Township Pkwy",
        "id": "20422",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJeZGFo_MjVIgRwy_WMrCJ9N8",
        "user_ratings_total": 436
      },
      {
        "geocode": [
          35.304002,
          -80.7500971
        ],
        "Rest_Name": "Harris",
        "address": "230 E WT Harris Blvd",
        "id": "20423",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJJ6-6riYcVIgRcpicyDyZtcY",
        "user_ratings_total": 573
      },
      {
        "geocode": [
          36.1497933,
          -81.1900511
        ],
        "Rest_Name": "Wilkesboro",
        "address": "1510 Winkler Mill Rd Ext",
        "id": "20424",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJY8wZVC8NUYgRLwkvdH6yFqo",
        "user_ratings_total": 449
      },
      {
        "geocode": [
          36.0862241,
          -79.8329236
        ],
        "Rest_Name": "Pembroke",
        "address": "700 Pembroke Rd",
        "id": "20425",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJp6I6KT2n2YgR5d3X6f7GmMY",
        "user_ratings_total": 83
      },
      {
        "geocode": [
          36.074121,
          -79.77467
        ],
        "Rest_Name": "NC A&T University",
        "address": "1601 E Market St Williams Hall",
        "id": "20426",
        "type": "Qdoba",
        "rating": 3,
        "place_id": "ChIJ24oqeQ4ZU4gR6CIwrPNCCAw",
        "user_ratings_total": 4
      },
      {
        "geocode": [
          36.105549,
          -79.502242
        ],
        "Rest_Name": "Elon University",
        "address": "100 Dalton Mcmichael Dr Daniel Commons",
        "id": "20427",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJrfwvkacpU4gRheIA6rK-NTg",
        "user_ratings_total": 36
      },
      {
        "geocode": [
          35.74442,
          -78.87238
        ],
        "Rest_Name": "Apex",
        "address": "1005 Beaver Creek Commons Dr",
        "id": "20428",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJX6CLntKSrIkR1ZnHGOx1dK0",
        "user_ratings_total": 482
      },
      {
        "geocode": [
          35.82466,
          -78.70731
        ],
        "Rest_Name": "Edwards Mill",
        "address": "3101 Edwards Mill Rd",
        "id": "20429",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJh7tBb4b2rIkRKVwRsH6H-tU",
        "user_ratings_total": 415
      },
      {
        "geocode": [
          35.79796,
          -78.51113
        ],
        "Rest_Name": "Hinton Oaks",
        "address": "210 Hinton Oaks Blvd",
        "id": "20430",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJyb6iz4JcrIkRk9B2foNDT8Y",
        "user_ratings_total": 595
      },
      {
        "geocode": [
          35.61714951,
          -82.56547687
        ],
        "Rest_Name": "UNC Asheville",
        "address": "1 University Heights",
        "id": "20431",
        "type": "Qdoba",
        "rating": 2.3,
        "place_id": "ChIJVTCawzKLWYgRVC48kw-T16I",
        "user_ratings_total": 3
      },
      {
        "geocode": [
          35.50107519,
          -80.8418341
        ],
        "Rest_Name": "Davidson College",
        "address": "204 Baker DrStowe Tennis Hut",
        "id": "20432",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          35.1184857,
          -79.01317392
        ],
        "Rest_Name": "Canopy Lane - Fort Bragg",
        "address": "1017 Canopy Lane",
        "id": "20433",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJz6GZcdBrq4kRUpy0pnHXzBk",
        "user_ratings_total": 4
      },
      {
        "geocode": [
          35.15735448,
          -78.97785365
        ],
        "Rest_Name": "Butner",
        "address": "85050 Butner Rd",
        "id": "20434",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJX6CLntKSrIkR1ZnHGOx1dK0",
        "user_ratings_total": 482
      },
      {
        "geocode": [
          46.85268,
          -96.86301
        ],
        "Rest_Name": "45th Street",
        "address": "1801 45th St SW",
        "id": "20435",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJqRRdSyXhEYgRGLzq8jV48Mw",
        "user_ratings_total": 279
      },
      {
        "geocode": [
          47.888964,
          -97.079367
        ],
        "Rest_Name": "Grand Forks",
        "address": "3551 32nd Ave S",
        "id": "20436",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJF-Mbp_GAxlIRY9rTRhw4gC4",
        "user_ratings_total": 614
      },
      {
        "geocode": [
          48.258011,
          -101.2970043
        ],
        "Rest_Name": "Minot North",
        "address": "2045 N Broadway",
        "id": "20437",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJx1nfQ-8p31IRbeDPjZzHzbE",
        "user_ratings_total": 377
      },
      {
        "geocode": [
          48.216175,
          -101.2967052
        ],
        "Rest_Name": "Minot South",
        "address": "1700 S Broadway",
        "id": "20438",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJVd_7vEMq31IRQ8WO5JVocxI",
        "user_ratings_total": 502
      },
      {
        "geocode": [
          46.8025987,
          -100.7881223
        ],
        "Rest_Name": "3rd Street",
        "address": "312 S 3rd St",
        "id": "20439",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          46.9013788,
          -102.7907306
        ],
        "Rest_Name": "Dickinson",
        "address": "1701 3rd Ave W",
        "id": "20440",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJfdis4lg9JlMRJ-wLq2h9T4Q",
        "user_ratings_total": 335
      },
      {
        "geocode": [
          48.167625,
          -103.620494
        ],
        "Rest_Name": "Williston",
        "address": "120 26th St East",
        "id": "20441",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJveBBVLhcIVMRa0xBQy56DoY",
        "user_ratings_total": 467
      },
      {
        "geocode": [
          41.8741835,
          -103.6750995
        ],
        "Rest_Name": "Scottsbluff",
        "address": "837 West 27th St.",
        "id": "20442",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJjyRkSsZRZYcRkTNYfg_I0Zw",
        "user_ratings_total": 44
      },
      {
        "geocode": [
          41.11436,
          -100.7628131
        ],
        "Rest_Name": "Halligan",
        "address": "109 Halligan Dr",
        "id": "20443",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          41.1733,
          -96.02322
        ],
        "Rest_Name": "72nd & Giles",
        "address": "8540 S 71st Plaza",
        "id": "20444",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJj68DfqiLk4cRbIPLezH35hE",
        "user_ratings_total": 533
      },
      {
        "geocode": [
          41.2606091,
          -95.9811726
        ],
        "Rest_Name": "Saddle Creek & Dodge",
        "address": "222 N Saddle Creek Rd",
        "id": "20445",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ21cHh3aOk4cRuLOmBwYCxrY",
        "user_ratings_total": 658
      },
      {
        "geocode": [
          41.2486879,
          -96.0223992
        ],
        "Rest_Name": "72nd & Pacific",
        "address": "1110 S 71st St",
        "id": "20446",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ38CptZwPBYgRjoSC3EceZwM",
        "user_ratings_total": 774
      },
      {
        "geocode": [
          41.21302,
          -96.10643
        ],
        "Rest_Name": "L Street and Market Place",
        "address": "12414 L St",
        "id": "20447",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          41.2792799,
          -96.099848
        ],
        "Rest_Name": "120th & Blondo",
        "address": "2101 N 120th St",
        "id": "20448",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJLSnzrYLyk4cRL0DiuKR4iLI",
        "user_ratings_total": 439
      },
      {
        "geocode": [
          41.2357586,
          -96.1396649
        ],
        "Rest_Name": "144th & Center",
        "address": "14410 W Center Rd",
        "id": "20449",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJGcDDys3zk4cR_iahnpXJrT8",
        "user_ratings_total": 497
      },
      {
        "geocode": [
          41.2909899,
          -96.2125507
        ],
        "Rest_Name": "Elkhorn",
        "address": "18924 Evans St",
        "id": "20450",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJZQZzRy_vk4cRj5OYGZtWAUs",
        "user_ratings_total": 73
      },
      {
        "geocode": [
          40.81485,
          -96.63645
        ],
        "Rest_Name": "Gateway Mall",
        "address": "6100 O St",
        "id": "20451",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ9dDYx068locRHQ0mXK7g4Hk",
        "user_ratings_total": 377
      },
      {
        "geocode": [
          40.7259481,
          -96.6796003
        ],
        "Rest_Name": "Yankee Hill",
        "address": "2900 Copper Ridge Drive",
        "id": "20452",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.81491,
          -96.70454
        ],
        "Rest_Name": "12th St. & P",
        "address": "211 N 12th St",
        "id": "20453",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.92837,
          -98.3842
        ],
        "Rest_Name": "Diers",
        "address": "1010 N Diers Ave",
        "id": "20454",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJAUZTX9qGmYcR9TT6GFkeF2I",
        "user_ratings_total": 530
      },
      {
        "geocode": [
          40.7001403,
          -99.0848646
        ],
        "Rest_Name": "2nd Avenue",
        "address": "2414 2nd Ave",
        "id": "20455",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          42.82431,
          -71.50619
        ],
        "Rest_Name": "Industrial Dr Merrimack Outlet",
        "address": "17C Premium Outlets Blvd",
        "id": "20456",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJATrrOD-044kRhMaD47c1wLk",
        "user_ratings_total": 540
      },
      {
        "geocode": [
          40.9475065,
          -74.0728339
        ],
        "Rest_Name": "Route 17 S",
        "address": "393 Route 17 S",
        "id": "20457",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          40.94359,
          -74.12977
        ],
        "Rest_Name": "Fair Lawn Promenade",
        "address": "26-31 NJ-208",
        "id": "20458",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJrVOHrpz8wokReBJ9hTWaFKw",
        "user_ratings_total": 509
      },
      {
        "geocode": [
          40.7414127,
          -74.0299258
        ],
        "Rest_Name": "Hoboken",
        "address": "400 Washington St",
        "id": "20459",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJv9i1AuBZwokRx6kIjZkXp2c",
        "user_ratings_total": 686
      },
      {
        "geocode": [
          40.72719,
          -74.03793
        ],
        "Rest_Name": "Newport Centre Mall",
        "address": "30 Mall Dr West",
        "id": "20460",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJU8XlxlNXwokRucRHw0N_HWg",
        "user_ratings_total": 254
      },
      {
        "geocode": [
          40.7118408,
          -74.0927728
        ],
        "Rest_Name": "Jersey City - University Place",
        "address": "26 University Place Blvd",
        "id": "20461",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJX1IaE5tRwokR_X9WenzpeiI",
        "user_ratings_total": 12
      },
      {
        "geocode": [
          40.88861,
          -74.26003
        ],
        "Rest_Name": "Willowbrook",
        "address": "1220 Willowbrook Mall",
        "id": "20462",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJT-h4oKEBw4kRm2OMkcyjpoI",
        "user_ratings_total": 283
      },
      {
        "geocode": [
          40.740106,
          -74.171183
        ],
        "Rest_Name": "Halsey",
        "address": "64 Halsey St",
        "id": "20463",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJteBNG4BTwokR6GTl8lIcIpQ",
        "user_ratings_total": 685
      },
      {
        "geocode": [
          40.6607963,
          -74.1083276
        ],
        "Rest_Name": "Bayonne",
        "address": "905 Bayonne Crossing Way",
        "id": "20464",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ8Y07COZRwokRBbw_gdJPnrg",
        "user_ratings_total": 535
      },
      {
        "geocode": [
          40.8660045,
          -74.5062391
        ],
        "Rest_Name": "Denville",
        "address": "3056 New Jersey 10",
        "id": "20465",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJrzqlvqmgw4kRPWvYjNHjW9E",
        "user_ratings_total": 273
      },
      {
        "geocode": [
          40.6909947,
          -74.2898673
        ],
        "Rest_Name": "Union",
        "address": "2268 US Highway 22 E",
        "id": "20466",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJb86M14bLNIgRogPBsMpE4_4",
        "user_ratings_total": 924
      },
      {
        "geocode": [
          40.90746,
          -74.55874
        ],
        "Rest_Name": "Rockaway",
        "address": "395 Mount Hope Ave",
        "id": "20467",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJLw6br_gKw4kR8tZZRiCM1SU",
        "user_ratings_total": 420
      },
      {
        "geocode": [
          39.91504,
          -75.00982
        ],
        "Rest_Name": "Cherry Hill",
        "address": "1630 Kings Hwy",
        "id": "20468",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ9e32h3PLxokRKfMFIoUKCA4",
        "user_ratings_total": 768
      },
      {
        "geocode": [
          40.79615,
          -74.48116
        ],
        "Rest_Name": "Park Place",
        "address": "40 W Park Place",
        "id": "20469",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.65143,
          -74.34796
        ],
        "Rest_Name": "Elm",
        "address": "70 Elm St",
        "id": "20470",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.563062,
          -74.298783
        ],
        "Rest_Name": "Woodbridge",
        "address": "770 US Route 1 N",
        "id": "20471",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJoftQvjK0w4kR-f09jVd9tEw",
        "user_ratings_total": 1069
      },
      {
        "geocode": [
          40.5258,
          -74.43818
        ],
        "Rest_Name": "Rutgers University",
        "address": "55 Rockafeller Rd",
        "id": "20472",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJT1ekGZfHw4kRQAcIPWXP2U8",
        "user_ratings_total": 228
      },
      {
        "geocode": [
          40.5856549,
          -74.6186134
        ],
        "Rest_Name": "Bridgewater",
        "address": "400 Commons Way",
        "id": "20473",
        "type": "Qdoba",
        "rating": 3,
        "place_id": "ChIJcX5rL1WVw4kRJV_vrfZlDB8",
        "user_ratings_total": 27
      },
      {
        "geocode": [
          40.507274,
          -74.394179
        ],
        "Rest_Name": "US Hwy 1",
        "address": "561 Route 1 South",
        "id": "20474",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJGzsQ2G_Iw4kRGOltQlQLP0U",
        "user_ratings_total": 599
      },
      {
        "geocode": [
          40.315166,
          -74.661733
        ],
        "Rest_Name": "Princeton Marketfair",
        "address": "3535 US Route 1",
        "id": "20475",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJl6q8aT7hw4kReGHa6nbdi9c",
        "user_ratings_total": 466
      },
      {
        "geocode": [
          40.2873057,
          -74.2964231
        ],
        "Rest_Name": "Manalapan",
        "address": "33 US Highway 9",
        "id": "20476",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJDyFzj7bTw4kRxOOgtprRUts",
        "user_ratings_total": 39
      },
      {
        "geocode": [
          36.03994,
          -115.04741
        ],
        "Rest_Name": "Stephanie",
        "address": "175 N Stephanie St",
        "id": "20477",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJCfSeF6jRyIARgeCv7EjnPB8",
        "user_ratings_total": 407
      },
      {
        "geocode": [
          36.0621369,
          -115.2433335
        ],
        "Rest_Name": "Rainbow",
        "address": "6965 S Rainbow Blvd",
        "id": "20478",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJBbNoU8nHyIAR4DHFqac9z_s",
        "user_ratings_total": 263
      },
      {
        "geocode": [
          39.1088198,
          -119.7767581
        ],
        "Rest_Name": "Topsy",
        "address": "933 Topsy Lane",
        "id": "20479",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJBUZJK7wsDogRON4091qn_m4",
        "user_ratings_total": 327
      },
      {
        "geocode": [
          39.4212297,
          -119.7552315
        ],
        "Rest_Name": "Damonte Ranch",
        "address": "197 Damonte Ranch Pkwy",
        "id": "20480",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJVQsIR-UUmYARTpdVo1AHUoc",
        "user_ratings_total": 302
      },
      {
        "geocode": [
          39.4696233,
          -119.7860058
        ],
        "Rest_Name": "Virginia",
        "address": "6135 S Virginia St",
        "id": "20481",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.57607,
          -119.74083
        ],
        "Rest_Name": "Disc Drive",
        "address": "151 Disc Drive",
        "id": "20482",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.53123,
          -119.86394
        ],
        "Rest_Name": "McCarran",
        "address": "10310 N McCarran Blvd",
        "id": "20483",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJn7K9BpZGmYARZtpfJWGibto",
        "user_ratings_total": 384
      },
      {
        "geocode": [
          39.6129223,
          -119.8501949
        ],
        "Rest_Name": "Lemmon",
        "address": "135 Lemmon Dr.",
        "id": "20484",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJf2sCAO1ImYARieDUDsqjAcc",
        "user_ratings_total": 689
      },
      {
        "geocode": [
          40.8073774,
          -73.241404
        ],
        "Rest_Name": "Hauppauge",
        "address": "670 Motor Pkwy",
        "id": "20485",
        "type": "Qdoba",
        "rating": 4.6,
        "place_id": "ChIJR1G4gNUx6IkRU2ktjVoOoDc",
        "user_ratings_total": 221
      },
      {
        "geocode": [
          40.7867165,
          -73.5029393
        ],
        "Rest_Name": "Plainview",
        "address": "327 South Oyster Bay Rd",
        "id": "20486",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ55uF4WeBwokRq7peXklnwQE",
        "user_ratings_total": 291
      },
      {
        "geocode": [
          40.7256653,
          -73.2388697
        ],
        "Rest_Name": "Bay Shore",
        "address": "331 E Main St",
        "id": "20487",
        "type": "Qdoba",
        "rating": 4.6,
        "place_id": "ChIJGxMSgIAz6IkRz0GUrgqwdvk",
        "user_ratings_total": 272
      },
      {
        "geocode": [
          40.729821,
          -73.424789
        ],
        "Rest_Name": "Farmingdale",
        "address": "1024 Broadhollow Rd",
        "id": "20488",
        "type": "Qdoba",
        "rating": 4.6,
        "place_id": "ChIJF7gH3J0r6IkRnOnfNtmkesA",
        "user_ratings_total": 403
      },
      {
        "geocode": [
          40.7248754,
          -73.5562381
        ],
        "Rest_Name": "East Meadow",
        "address": "2160 Hempstead Tpke",
        "id": "20489",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJq6p6TAZ-wokRSHsLQt1RDdk",
        "user_ratings_total": 1138
      },
      {
        "geocode": [
          40.6770558,
          -73.4552422
        ],
        "Rest_Name": "Massapequa",
        "address": "4870 Sunrise Hwy",
        "id": "20490",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJuboDt7rV6YkR1oJAHcbSZx4",
        "user_ratings_total": 236
      },
      {
        "geocode": [
          40.86299,
          -73.9011826
        ],
        "Rest_Name": "Fordham",
        "address": "1 W Fordham Rd",
        "id": "20491",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJH8TjYn7zwokRBusx_LqBuyE",
        "user_ratings_total": 22
      },
      {
        "geocode": [
          40.739449,
          -73.7864578
        ],
        "Rest_Name": "Fresh Meadows",
        "address": "61-40 188th St",
        "id": "20492",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJXwMLA7FhwokRdpWxkv_psqk",
        "user_ratings_total": 1551
      },
      {
        "geocode": [
          40.66302,
          -73.79259
        ],
        "Rest_Name": "JFK Airport Plaza",
        "address": "JFK Travel Plaza 147th Ave &",
        "id": "20493",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJ-RrM3FVmwokRjmsOttZuE58",
        "user_ratings_total": 361
      },
      {
        "geocode": [
          40.6986989,
          -73.9184075
        ],
        "Rest_Name": "Myrtle Ave",
        "address": "1422 Myrtle Ave",
        "id": "20494",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJKbfX7HBdwokRziVROHnJtLk",
        "user_ratings_total": 893
      },
      {
        "geocode": [
          40.5644249,
          -74.114203
        ],
        "Rest_Name": "Hylan",
        "address": "2750A Hylan Blvd",
        "id": "20495",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJc8y0mQdJwokRoMgKqel7BUU",
        "user_ratings_total": 29
      },
      {
        "geocode": [
          40.5909256,
          -74.1644609
        ],
        "Rest_Name": "Richmond Ave.",
        "address": "2335 Richmond Ave",
        "id": "20496",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.54198,
          -74.20767
        ],
        "Rest_Name": "Staten Island - Woodrow",
        "address": "1275 Woodrow Rd",
        "id": "20497",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ7wFzidBKwokRW1Nu424O304",
        "user_ratings_total": 272
      },
      {
        "geocode": [
          40.51697,
          -74.23416
        ],
        "Rest_Name": "Tottenville Square",
        "address": "7001 Amboy Rd",
        "id": "20498",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ-zPTIlm1w4kRk9873jeFp-E",
        "user_ratings_total": 224
      },
      {
        "geocode": [
          41.3743007,
          -83.6501008
        ],
        "Rest_Name": "Bowling Green",
        "address": "129 S Main St",
        "id": "20499",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJsxngersLPIgRou8q3ZN807o",
        "user_ratings_total": 380
      },
      {
        "geocode": [
          39.6293451,
          -84.1960503
        ],
        "Rest_Name": "Yankee Station",
        "address": "1524 Miamisburg Centerville Rd",
        "id": "20500",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ65lwf8GJQIgRwI0rKkqh3AQ",
        "user_ratings_total": 238
      },
      {
        "geocode": [
          39.3085717,
          -84.3150128
        ],
        "Rest_Name": "Deerfield",
        "address": "5030 Deerfield Blvd",
        "id": "20501",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJbTkztgFXQIgRfrWIfoBNBg0",
        "user_ratings_total": 350
      },
      {
        "geocode": [
          39.131768,
          -84.50953
        ],
        "Rest_Name": "UC",
        "address": "2701 Bearcat Way Tangeman University Center",
        "id": "20502",
        "type": "Qdoba",
        "rating": 2.6,
        "place_id": "ChIJ-0y699izQYgRMaCwVxs9dcs",
        "user_ratings_total": 17
      },
      {
        "geocode": [
          39.1471508,
          -84.448292
        ],
        "Rest_Name": "Edmondson",
        "address": "2721 Edmonson Rd",
        "id": "20503",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.2356895,
          -84.3769165
        ],
        "Rest_Name": "Kenwood",
        "address": "9749 Kenwood Rd",
        "id": "20504",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJp5iS6aJTQIgRNCbluLCqpqI",
        "user_ratings_total": 663
      },
      {
        "geocode": [
          39.3366511,
          -82.071271
        ],
        "Rest_Name": "Athens",
        "address": "859 E State St",
        "id": "20505",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA5A4PmdzSIgRbq0sDZ7dMgE",
        "user_ratings_total": 390
      },
      {
        "geocode": [
          39.4154021,
          -81.4437996
        ],
        "Rest_Name": "Pike Street",
        "address": "100 Pike St",
        "id": "20506",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJa8Z3VZ9Qa4gRt5BjmeDTUyM",
        "user_ratings_total": 368
      },
      {
        "geocode": [
          39.9888925,
          -83.0518785
        ],
        "Rest_Name": "West 5th Ave",
        "address": "1689 W 5th Ave",
        "id": "20507",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJFdBe-0WOOIgRqyOBg3rK3KA",
        "user_ratings_total": 689
      },
      {
        "geocode": [
          40.0022758,
          -83.0082443
        ],
        "Rest_Name": "OSU",
        "address": "1956 N High St",
        "id": "20508",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJz6-Z7LuOOIgR9MYmuJtV3Rk",
        "user_ratings_total": 342
      },
      {
        "geocode": [
          40.0949722,
          -82.8250568
        ],
        "Rest_Name": "New Albany",
        "address": "5471 New Albany Rd W",
        "id": "20509",
        "type": "Qdoba",
        "rating": 4.7,
        "place_id": "ChIJMbppIjhfOIgRhfucfCTF1Uo",
        "user_ratings_total": 42
      },
      {
        "geocode": [
          41.5193502,
          -81.4969942
        ],
        "Rest_Name": "Lyndhurst",
        "address": "5076 Mayfield Rd.",
        "id": "20510",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJdfTRlykDMYgRIBakqOusFMQ",
        "user_ratings_total": 38
      },
      {
        "geocode": [
          41.075287,
          -81.514023
        ],
        "Rest_Name": "University of Akron",
        "address": "Univ of Akron-Student Union 303 Carroll St",
        "id": "20511",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJa8Doe70pMYgRfBEpD5NNdSg",
        "user_ratings_total": 17
      },
      {
        "geocode": [
          40.1456295,
          -82.9927494
        ],
        "Rest_Name": "Polaris",
        "address": "1055 Polaris Pkwy",
        "id": "20512",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJg_PHfXj0OIgRKCP0KQEwsqo",
        "user_ratings_total": 1023
      },
      {
        "geocode": [
          36.0603863,
          -95.8185285
        ],
        "Rest_Name": "Broken Arrow",
        "address": "2401 W Kenosha St",
        "id": "20513",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJXe6bZXSMtocRBwlNgTKmizo",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          36.02972,
          -95.92328
        ],
        "Rest_Name": "Yale Village",
        "address": "9168 S Yale Ave",
        "id": "20514",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ-XBDcIqRtocRrg_ZWuK5rBk",
        "user_ratings_total": 287
      },
      {
        "geocode": [
          36.059441,
          -95.957349
        ],
        "Rest_Name": "Lewis",
        "address": "7153 S Lewis Ave",
        "id": "20515",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          36.04617,
          -96.00369
        ],
        "Rest_Name": "W 81st Tulsa",
        "address": "8115 S Olympia Ave.",
        "id": "20516",
        "type": "Qdoba",
        "rating": 2.9,
        "place_id": "ChIJ62uP2D-UtocR0cwQG0B8dck",
        "user_ratings_total": 411
      },
      {
        "geocode": [
          35.3784416,
          -96.9293503
        ],
        "Rest_Name": "Shawnee",
        "address": "4621 N Kickapoo St",
        "id": "20517",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJj4j54gTIs4cRLwvqAoYOvuE",
        "user_ratings_total": 699
      },
      {
        "geocode": [
          34.1877757,
          -97.1514036
        ],
        "Rest_Name": "Ardmore",
        "address": "1865 12 Ave NW",
        "id": "20518",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJ60hIBn8pTYYRfUk-B9h_C20",
        "user_ratings_total": 112
      },
      {
        "geocode": [
          36.1208338,
          -97.0648227
        ],
        "Rest_Name": "Knoblock",
        "address": "139 S Knoblock St",
        "id": "20519",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJHTOqvTgKsYcRmyF6wercrzw",
        "user_ratings_total": 495
      },
      {
        "geocode": [
          35.4356494,
          -97.4005058
        ],
        "Rest_Name": "SE 29th Street",
        "address": "7191 Se 29th St",
        "id": "20520",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJGbPJUt3uEYgR4jll17wkuF0",
        "user_ratings_total": 585
      },
      {
        "geocode": [
          35.1914236,
          -97.4238977
        ],
        "Rest_Name": "Classen Crossing",
        "address": "2620 Classen Blvd",
        "id": "20521",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJc6b5JZFCsocRSlIj4Zz1H84",
        "user_ratings_total": 521
      },
      {
        "geocode": [
          35.6810476,
          -97.4192335
        ],
        "Rest_Name": "Edmond E. Covell/I-35",
        "address": "2600 Exchange Drive",
        "id": "20522",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          35.2324201,
          -97.479418
        ],
        "Rest_Name": "Robinson",
        "address": "2450 W Robinson St",
        "id": "20523",
        "type": "Qdoba",
        "rating": null,
        "place_id": "EioyNDUwIFcgUm9iaW5zb24gU3QsIE9ybGFuZG8sIEZMIDMyODM1LCBVU0EiMRIvChQKEgm7YlIXqHnniBHDs-0Pnm8L8hCSEyoUChIJ6c37rCt654gRhujasgNrlUw",
        "user_ratings_total": null
      },
      {
        "geocode": [
          35.65189,
          -97.46123
        ],
        "Rest_Name": "Bryant",
        "address": "301 S Bryant St",
        "id": "20524",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJKzkeJfpgBYgR5Dhtw2QHsJ0",
        "user_ratings_total": 823
      },
      {
        "geocode": [
          35.68151,
          -97.50136
        ],
        "Rest_Name": "Covell and Kelly",
        "address": "1300 W Covell Rd",
        "id": "20525",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJQZHVdSAesocRh6jFjE98bNU",
        "user_ratings_total": 445
      },
      {
        "geocode": [
          35.31918,
          -97.4974
        ],
        "Rest_Name": "SW 19th Street",
        "address": "660 SW 19th St",
        "id": "20526",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ2ae3gjlrsocRoOOJTMFnt6U",
        "user_ratings_total": 686
      },
      {
        "geocode": [
          35.6102257,
          -97.5467365
        ],
        "Rest_Name": "Pennsylvania",
        "address": "13720 N Pennsylvania Ave",
        "id": "20527",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJSzFefrhTwYkRub5oJCOP3AY",
        "user_ratings_total": 67
      },
      {
        "geocode": [
          35.6536553,
          -97.5715308
        ],
        "Rest_Name": "178th Street OKC",
        "address": "3033 NW 178th St.",
        "id": "20528",
        "type": "Qdoba",
        "rating": 4.7,
        "place_id": "ChIJv3SVjhUdsocR1yNxeOc-YTE",
        "user_ratings_total": 33
      },
      {
        "geocode": [
          35.5566355,
          -97.6367147
        ],
        "Rest_Name": "NW Expressway",
        "address": "7002 Northwest Expressway",
        "id": "20529",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ27PMR-kEsocRwqU70SKpJuE",
        "user_ratings_total": 947
      },
      {
        "geocode": [
          35.52686,
          -98.69366
        ],
        "Rest_Name": "Weatherford Center",
        "address": "114 N Washington Ave",
        "id": "20530",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          35.477516,
          -97.749782
        ],
        "Rest_Name": "Yukon",
        "address": "12444 NW 10th St",
        "id": "20531",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ8TmBSFMJsocR03WDN4VlAvQ",
        "user_ratings_total": 143
      },
      {
        "geocode": [
          35.4616797,
          -97.6275412
        ],
        "Rest_Name": "Westgate Marketplace",
        "address": "6432 Sw 3rd St",
        "id": "20532",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          35.3637214,
          -97.5496045
        ],
        "Rest_Name": "SW 104th Street",
        "address": "2145 Sw 104th St",
        "id": "20533",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJp6I6KT2n2YgR5d3X6f7GmMY",
        "user_ratings_total": 83
      },
      {
        "geocode": [
          34.62713,
          -98.4931
        ],
        "Rest_Name": "Lawton",
        "address": "1772 Nw 82nd St",
        "id": "20534",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJJQCUpt3hrIcRx9YcE_ByyxM",
        "user_ratings_total": 633
      },
      {
        "geocode": [
          44.2320328,
          -76.484757
        ],
        "Rest_Name": "Kingston",
        "address": "169 Princess Street",
        "id": "20535",
        "type": "Qdoba",
        "rating": 4.8,
        "place_id": "ChIJeRx7aT6r0kwRB7b-ixOChQs",
        "user_ratings_total": 262
      },
      {
        "geocode": [
          46.5481839,
          -84.3199846
        ],
        "Rest_Name": "Great Northern",
        "address": "747 Great Northern Rd",
        "id": "20536",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJ_bbIPUE3Nk0RLxl3nmn67OY",
        "user_ratings_total": 368
      },
      {
        "geocode": [
          42.2696628,
          -82.9987573
        ],
        "Rest_Name": "Windsor",
        "address": "650 Division Road",
        "id": "20537",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJrUSnflctO4gRNlHWn2BsVwQ",
        "user_ratings_total": 353
      },
      {
        "geocode": [
          42.98998,
          -81.29627
        ],
        "Rest_Name": "Beaverbrook",
        "address": "1371 Beaverbrook Ave",
        "id": "20538",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJW3UIfzLwLogRVBn1zW_z9Ew",
        "user_ratings_total": 663
      },
      {
        "geocode": [
          43.00568,
          -81.17464
        ],
        "Rest_Name": "Dundas Street",
        "address": "1920 Dundas St",
        "id": "20539",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJpxqogUjtLogRMSPZEsT29OQ",
        "user_ratings_total": 843
      },
      {
        "geocode": [
          43.4188,
          -80.45295
        ],
        "Rest_Name": "Fairway Plaza",
        "address": "10 Manitou Dr",
        "id": "20540",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          42.94293,
          -81.22644
        ],
        "Rest_Name": "Wellington",
        "address": "841 Wellington Rd S",
        "id": "20541",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJITaApbnzLogRpmhJs-ZdVk8",
        "user_ratings_total": 971
      },
      {
        "geocode": [
          45.5366107,
          -122.8773308
        ],
        "Rest_Name": "Allie",
        "address": "2288 Nw Allie Ave",
        "id": "20542",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJrfOKqGgPlVQRhp33gMbTJGQ",
        "user_ratings_total": 492
      },
      {
        "geocode": [
          45.5345393,
          -122.6569033
        ],
        "Rest_Name": "Weidler",
        "address": "825 Ne Weidler St",
        "id": "20543",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJdWmWV0unlVQRip5gnnyQyww",
        "user_ratings_total": 432
      },
      {
        "geocode": [
          45.48617,
          -122.79617
        ],
        "Rest_Name": "Griffith",
        "address": "4655 Sw Griffith Dr",
        "id": "20544",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJoxOBjh4MlVQRFW4SE5PdIv8",
        "user_ratings_total": 510
      },
      {
        "geocode": [
          45.3950801,
          -122.750821
        ],
        "Rest_Name": "Hazelfern",
        "address": "7132 Sw Hazelfern Rd",
        "id": "20545",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          44.0453084,
          -123.0795856
        ],
        "Rest_Name": "13th Avenue",
        "address": "840 E 13th Ave",
        "id": "20546",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJlVFe438ZBYgRqG3dXv2_4pU",
        "user_ratings_total": 471
      },
      {
        "geocode": [
          44.56813,
          -123.27539
        ],
        "Rest_Name": "Corvallis",
        "address": "2001 NW Monroe Ave",
        "id": "20547",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJuQ07ur1AwFQRhW234eBSoUk",
        "user_ratings_total": 357
      },
      {
        "geocode": [
          44.89008,
          -123.03488
        ],
        "Rest_Name": "Salem",
        "address": "4405 Commercial St Se",
        "id": "20548",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJu8PuEkIHwFQRaP7XUNU6ZXs",
        "user_ratings_total": 824
      },
      {
        "geocode": [
          45.302441,
          -122.772511
        ],
        "Rest_Name": "Boones Ferry",
        "address": "30040 SW Boones Ferry Rd",
        "id": "20549",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJy-hWne5ulVQRsFbCfyZ6Oyk",
        "user_ratings_total": 333
      },
      {
        "geocode": [
          39.9145451,
          -75.3438824
        ],
        "Rest_Name": "Springfield",
        "address": "1054 Baltimore Pike",
        "id": "20550",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJL1ZMUdjpxokRUgWNPMK-tbI",
        "user_ratings_total": 732
      },
      {
        "geocode": [
          39.949632,
          -75.167326
        ],
        "Rest_Name": "Walnut",
        "address": "1528 Walnut St",
        "id": "20551",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          39.9531587,
          -75.2031567
        ],
        "Rest_Name": "40th",
        "address": "230 S 40th St",
        "id": "20552",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          39.9596,
          -75.35503
        ],
        "Rest_Name": "Lawrence Park",
        "address": "1991 Sproul Rd",
        "id": "20553",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJoQEPqRPqxokRTRoyf_rHp2E",
        "user_ratings_total": 239
      },
      {
        "geocode": [
          39.97854,
          -75.15839
        ],
        "Rest_Name": "Temple",
        "address": "1600 N Broad St",
        "id": "20554",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJgVfLsF-hxokR7DCXvA3Fkt0",
        "user_ratings_total": 650
      },
      {
        "geocode": [
          39.96944,
          -75.57438
        ],
        "Rest_Name": "Chester Pike",
        "address": "1107 West Chester Pike",
        "id": "20555",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJt7PlhH_xxokR6nrxOvlenYg",
        "user_ratings_total": 279
      },
      {
        "geocode": [
          40.0246394,
          -75.3252757
        ],
        "Rest_Name": "Main Line Retail",
        "address": "1112 W Lancaster Ave",
        "id": "20556",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.0025551,
          -75.2251523
        ],
        "Rest_Name": "City",
        "address": "33 E City Line Ave",
        "id": "20557",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJi83_713HxokRzyUCG1rL_rA",
        "user_ratings_total": 759
      },
      {
        "geocode": [
          40.095064,
          -75.288628
        ],
        "Rest_Name": "Ridge Pike",
        "address": "200 W Ridge Pike",
        "id": "20558",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJYX_p4ym8xokRE0BbyEDCqMk",
        "user_ratings_total": 446
      },
      {
        "geocode": [
          40.16433,
          -75.48115
        ],
        "Rest_Name": "Collegeville",
        "address": "500 Broad St",
        "id": "20559",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ46MPfv2ZxokRsxuY10KDlYM",
        "user_ratings_total": 310
      },
      {
        "geocode": [
          40.09,
          -75.12518
        ],
        "Rest_Name": "Old York",
        "address": "261 Old York Rd",
        "id": "20560",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJG5InSFnXBYgR-Bt1xwZtJ4k",
        "user_ratings_total": 561
      },
      {
        "geocode": [
          40.15833,
          -75.14243
        ],
        "Rest_Name": "Welsh",
        "address": "100 Welsh Rd",
        "id": "20561",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          40.1368332,
          -74.9616798
        ],
        "Rest_Name": "Bensalem",
        "address": "690 Rockhill Dr",
        "id": "20562",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJSzFefrhTwYkRub5oJCOP3AY",
        "user_ratings_total": 67
      },
      {
        "geocode": [
          40.2177843,
          -74.9265858
        ],
        "Rest_Name": "Summit Square",
        "address": "2 Summit Square Shopping Center",
        "id": "20563",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          40.069242,
          -76.337203
        ],
        "Rest_Name": "Park City Mall",
        "address": "142 Park City Center",
        "id": "20564",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJP4XhyoojxokRlQ0a0C0y-pM",
        "user_ratings_total": 575
      },
      {
        "geocode": [
          39.98448,
          -76.72614
        ],
        "Rest_Name": "York",
        "address": "303 Arsenal Rd",
        "id": "20565",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ-QWWP4SOyIkRRErqKzR6COA",
        "user_ratings_total": 585
      },
      {
        "geocode": [
          40.80875,
          -77.90514
        ],
        "Rest_Name": "North Atherton",
        "address": "1908 N Atherton St",
        "id": "20566",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJjWjvquCnzokRvLh9r6HYdao",
        "user_ratings_total": 367
      },
      {
        "geocode": [
          40.4264939,
          -79.9656955
        ],
        "Rest_Name": "Carson",
        "address": "2777 E Carson St",
        "id": "20567",
        "type": "Qdoba",
        "rating": 3.2,
        "place_id": "ChIJXVuHRuDZ3IARk6BNIUpFJZQ",
        "user_ratings_total": 46
      },
      {
        "geocode": [
          40.64562,
          -75.49251
        ],
        "Rest_Name": "MacArthur",
        "address": "2669A Mac Arther Rd",
        "id": "20568",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          41.943342,
          -78.673941
        ],
        "Rest_Name": "Pitt Bradford",
        "address": "300 Campus Drive",
        "id": "20569",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJy8E6uQ1_zYkRovScnRxcX9s",
        "user_ratings_total": 9
      },
      {
        "geocode": [
          41.248775,
          -76.973423
        ],
        "Rest_Name": "Williamsport",
        "address": "22 Liberty Lane",
        "id": "20570",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ4ZFy5zGvz4kROfZTjH0dQaA",
        "user_ratings_total": 491
      },
      {
        "geocode": [
          44.5018263,
          -103.8599667
        ],
        "Rest_Name": "Spearfish",
        "address": "130 Ryan Rd",
        "id": "20571",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJn6GkN7qZMlMROyTfI2E4B68",
        "user_ratings_total": 375
      },
      {
        "geocode": [
          43.5469253,
          -96.6961864
        ],
        "Rest_Name": "12th & Lowell",
        "address": "2400 E 10th St",
        "id": "20572",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          43.5152365,
          -96.7490229
        ],
        "Rest_Name": "41st 7 Hawthorne",
        "address": "1900 W 41st St",
        "id": "20573",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          43.5008018,
          -96.7716086
        ],
        "Rest_Name": "57th & Louise",
        "address": "4901 S Louise Ave",
        "id": "20574",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJKW0pNpPJjocR-TCmKlOKd8U",
        "user_ratings_total": 717
      },
      {
        "geocode": [
          44.3108684,
          -96.7655464
        ],
        "Rest_Name": "Brookings",
        "address": "2320 6th St",
        "id": "20575",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJHZMBGWKiiYcRO8__yZBOsj0",
        "user_ratings_total": 561
      },
      {
        "geocode": [
          42.782569,
          -96.927334
        ],
        "Rest_Name": "University of South Dakota",
        "address": "414 E Clark St Muenster University Center",
        "id": "20576",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJAwB6LolPjocR8M661Ga6zkE",
        "user_ratings_total": 33
      },
      {
        "geocode": [
          44.8889221,
          -97.0686248
        ],
        "Rest_Name": "Watertown",
        "address": "951 29th St Se",
        "id": "20577",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJXUqFsvvWzFIRy_OqFEU8R08",
        "user_ratings_total": 477
      },
      {
        "geocode": [
          45.45866,
          -98.43499
        ],
        "Rest_Name": "6th Avenue",
        "address": "3828 6th Ave SE",
        "id": "20578",
        "type": "Qdoba",
        "rating": 4.5,
        "place_id": "ChIJKbfX7HBdwokRziVROHnJtLk",
        "user_ratings_total": 893
      },
      {
        "geocode": [
          44.09628,
          -103.18232
        ],
        "Rest_Name": "Eglin",
        "address": "1745 Eglin St",
        "id": "20579",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          44.07986,
          -103.25253
        ],
        "Rest_Name": "Rapid City",
        "address": "741 Mountain View Rd",
        "id": "20580",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJp5ru_7JcfYcRXZdQCScIcqs",
        "user_ratings_total": 504
      },
      {
        "geocode": [
          35.930632,
          -84.0313024
        ],
        "Rest_Name": "Kingston Pike",
        "address": "7339 Kingston Pike",
        "id": "20582",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJiQn8dLokXIgRSiOBz3vHDF4",
        "user_ratings_total": 520
      },
      {
        "geocode": [
          33.54624,
          -101.9555
        ],
        "Rest_Name": "Canyon West",
        "address": "5017 Milwaukee Ave",
        "id": "20583",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJGfjW0Axz_oYR9jwk5VqBs8s",
        "user_ratings_total": 626
      },
      {
        "geocode": [
          33.480589,
          -101.88784
        ],
        "Rest_Name": "Indiana Ave",
        "address": "12406 Indiana Ave",
        "id": "20584",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJe2ayo8xQa4gRiCuAFDB3fig",
        "user_ratings_total": 1106
      },
      {
        "geocode": [
          32.977515,
          -96.767323
        ],
        "Rest_Name": "Richardson",
        "address": "1930 Coit Rd",
        "id": "20585",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ_5a8qPMhTIYR9-IZ2NtZx-8",
        "user_ratings_total": 613
      },
      {
        "geocode": [
          33.027787,
          -96.840519
        ],
        "Rest_Name": "Plano",
        "address": "6505 W Park Blvd",
        "id": "20586",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ4xw2QIckTIYRoJcaLhSAxC4",
        "user_ratings_total": 390
      },
      {
        "geocode": [
          33.2216456,
          -96.9253013
        ],
        "Rest_Name": "Union Park",
        "address": "710 Union Place",
        "id": "20587",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          32.86444,
          -96.83846
        ],
        "Rest_Name": "Midway",
        "address": "4235 W Northwest Hwy",
        "id": "20588",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          32.905199,
          -97.039119
        ],
        "Rest_Name": "DFW Airport",
        "address": "2040 N International Pkwy Terminal A",
        "id": "20589",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJQw1VwbqCToYRLvvhUZ1j_UM",
        "user_ratings_total": 1036
      },
      {
        "geocode": [
          32.68084,
          -97.09443
        ],
        "Rest_Name": "Arlington",
        "address": "4050 S. Collins",
        "id": "20590",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJ8WY7INBjToYRxX61gCI3Ln4",
        "user_ratings_total": 282
      },
      {
        "geocode": [
          32.60682,
          -97.11624
        ],
        "Rest_Name": "Mansfield",
        "address": "1401 E Debbie Lane",
        "id": "20591",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJt6GhnIphToYR44YtWUjkkr8",
        "user_ratings_total": 604
      },
      {
        "geocode": [
          32.9345486,
          -97.2172483
        ],
        "Rest_Name": "Keller",
        "address": "1521 Keller Parkway",
        "id": "20592",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ4ZAU947XTYYRdbby4LWsKUA",
        "user_ratings_total": 90
      },
      {
        "geocode": [
          32.9023683,
          -97.3214897
        ],
        "Rest_Name": "Presidio",
        "address": "8924 Tehama Ridge Pkwy",
        "id": "20593",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJj0BxgP_ZTYYRqZ9EXzAjWMA",
        "user_ratings_total": 532
      },
      {
        "geocode": [
          32.8813453,
          -97.3928973
        ],
        "Rest_Name": "Bailey Boswell",
        "address": "4540 W. Bailey Boswell",
        "id": "20594",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJQ0aW1WPfTYYRvO2hPc4mt5w",
        "user_ratings_total": 317
      },
      {
        "geocode": [
          32.51925,
          -97.34881
        ],
        "Rest_Name": "Wilshire",
        "address": "1505 SW Wilshire Blvd",
        "id": "20595",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          32.7298427,
          -97.4139612
        ],
        "Rest_Name": "Camp Bowie",
        "address": "6002 Camp Bowie Blvd",
        "id": "20596",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJZ8fK0B5zToYR-TKy0SW_qIQ",
        "user_ratings_total": 460
      },
      {
        "geocode": [
          29.450807,
          -98.448138
        ],
        "Rest_Name": "Fort Sam Houston",
        "address": "2503 Funston Rd",
        "id": "20597",
        "type": "Qdoba",
        "rating": 2.5,
        "place_id": "ChIJ4aah-Tb1XIYRZAX5oeRiMhs",
        "user_ratings_total": 37
      },
      {
        "geocode": [
          30.06725,
          -99.11504
        ],
        "Rest_Name": "Kerrville",
        "address": "2105 Sidney Baker St",
        "id": "20598",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJH4xgDorjW4YRWbb0KTaCDGU",
        "user_ratings_total": 77
      },
      {
        "geocode": [
          33.8747,
          -98.53912
        ],
        "Rest_Name": "Lawrence Road",
        "address": "3201 Lawrence Rd",
        "id": "20599",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJb9c5Na4hU4YRUg3ap_B1eWw",
        "user_ratings_total": 638
      },
      {
        "geocode": [
          38.85262,
          -77.13178
        ],
        "Rest_Name": "Crossroads",
        "address": "5872 Crossroads Ctr Way",
        "id": "20600",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJqc9w0Yuzt4kRJNB-BKOeG0c",
        "user_ratings_total": 411
      },
      {
        "geocode": [
          38.8460739,
          -77.3035536
        ],
        "Rest_Name": "Fairfax",
        "address": "10338 Main St",
        "id": "20601",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ2c-HhpFOtokRyY6WAGw44SA",
        "user_ratings_total": 236
      },
      {
        "geocode": [
          38.957316,
          -77.4007865
        ],
        "Rest_Name": "Worldgate",
        "address": "13031 Worldgate Dr",
        "id": "20602",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJh7PNrPJHtokRoqD-peZeApw",
        "user_ratings_total": 269
      },
      {
        "geocode": [
          38.65188,
          -77.29619
        ],
        "Rest_Name": "Prince William",
        "address": "2824 Prince William Pkwy",
        "id": "20603",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJZ7iPGXJWtokRVIIQP0rVX_M",
        "user_ratings_total": 418
      },
      {
        "geocode": [
          38.747182,
          -77.450986
        ],
        "Rest_Name": "Liberia",
        "address": "9674 Liberia Ave",
        "id": "20604",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.7962203,
          -77.6095772
        ],
        "Rest_Name": "Atlas Walk",
        "address": "7376 Atlas Walk Way",
        "id": "20605",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJgc9qLE1mtokR0vSVxC1n5dg",
        "user_ratings_total": 202
      },
      {
        "geocode": [
          37.23119,
          -80.42175
        ],
        "Rest_Name": "VA Tech",
        "address": "117A Lavery Hall",
        "id": "20606",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJr0LOfG2VTYgRRiv2A2Hfo9k",
        "user_ratings_total": 140
      },
      {
        "geocode": [
          37.0414284,
          -76.3865678
        ],
        "Rest_Name": "Hampton",
        "address": "1123 W Mercury Blvd",
        "id": "20607",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJbWGQbp-DuokR9TwtVf7Anrg",
        "user_ratings_total": 32
      },
      {
        "geocode": [
          36.94721,
          -76.31763
        ],
        "Rest_Name": "Norfolk",
        "address": "1731 Gilbert St",
        "id": "20608",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJParELjWauokRDLu0bl8S6nc",
        "user_ratings_total": 200
      },
      {
        "geocode": [
          36.860484,
          -76.2083725
        ],
        "Rest_Name": "Military Highway",
        "address": "1140 N Military Hwy",
        "id": "20609",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ3-rxiESWuokRhFYsXRCXkQc",
        "user_ratings_total": 730
      },
      {
        "geocode": [
          36.887514,
          -76.308689
        ],
        "Rest_Name": "Old Dominion University",
        "address": "4607 Hampton Blvd #1110 Monarch Hall",
        "id": "20610",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          37.52664,
          -77.35297
        ],
        "Rest_Name": "Laburnum",
        "address": "4501 S Laburnum Ave",
        "id": "20611",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJGTZjhmoasYkR1PFBLyPk4zM",
        "user_ratings_total": 667
      },
      {
        "geocode": [
          38.30848,
          -77.5108
        ],
        "Rest_Name": "Fredericksburg Central Park",
        "address": "1865 Carl D Silver Pkwy",
        "id": "20612",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJaRCSjM3BtokRPJxXaGim_jc",
        "user_ratings_total": 390
      },
      {
        "geocode": [
          37.55047,
          -77.45038
        ],
        "Rest_Name": "Broad",
        "address": "935 W Broad St",
        "id": "20613",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJIViIgUgRsYkRxbWY8a9eVzI",
        "user_ratings_total": 297
      },
      {
        "geocode": [
          37.6507883,
          -77.6057722
        ],
        "Rest_Name": "Short Pump",
        "address": "11282 W Broad St",
        "id": "20614",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJha36c4hqsYkR0N-zbBZsWYY",
        "user_ratings_total": 572
      },
      {
        "geocode": [
          37.4996409,
          -77.5280593
        ],
        "Rest_Name": "Stonebridge",
        "address": "121 Stonebridge Plaza Av",
        "id": "20615",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJJTmd-JESsYkRBef7bTt1zeg",
        "user_ratings_total": 420
      },
      {
        "geocode": [
          38.432661,
          -78.854691
        ],
        "Rest_Name": "Harrisonburg Crossing",
        "address": "223 Burgess Rd",
        "id": "20616",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJMVKMHpiStIkRTY3jKJkO93I",
        "user_ratings_total": 483
      },
      {
        "geocode": [
          38.06761,
          -78.48852
        ],
        "Rest_Name": "Charlottesville",
        "address": "3918 Lenox Ave",
        "id": "20617",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ1YCiW-iHs4kRvUZ3f-BerbY",
        "user_ratings_total": 333
      },
      {
        "geocode": [
          37.506666,
          -77.6652561
        ],
        "Rest_Name": "Winterfield Crossing",
        "address": "14279 Winterview Pkwy",
        "id": "20618",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJM1JEXF5vsYkRzKTiD7fIV7E",
        "user_ratings_total": 145
      },
      {
        "geocode": [
          37.4032838,
          -77.6740804
        ],
        "Rest_Name": "Hancock Village",
        "address": "7201 Hancock Village Dr",
        "id": "20619",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJSww-6bh2sYkR2OlfXEwV6co",
        "user_ratings_total": 357
      },
      {
        "geocode": [
          48.093375,
          -122.188503
        ],
        "Rest_Name": "Seattle Premium Outlets",
        "address": "10600 Quil Ceda Blvd.",
        "id": "20620",
        "type": "Qdoba",
        "rating": 3.5,
        "place_id": "ChIJN7xZBeRThVQRwjPM-3z797s",
        "user_ratings_total": 134
      },
      {
        "geocode": [
          47.8209,
          -122.31704
        ],
        "Rest_Name": "Hwy. 99",
        "address": "6208 196th St SW",
        "id": "20621",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJ6QyquzYFkFQR-AAMSm38H3g",
        "user_ratings_total": 473
      },
      {
        "geocode": [
          48.00097,
          -122.10534
        ],
        "Rest_Name": "Lake Stevens",
        "address": "513 State Rte 9 Ne",
        "id": "20622",
        "type": "Qdoba",
        "rating": 3.3,
        "place_id": "ChIJ_5hMXnyrmlQRG1OT8LbpMKA",
        "user_ratings_total": 449
      },
      {
        "geocode": [
          47.84984,
          -122.21938
        ],
        "Rest_Name": "Mill Creek",
        "address": "16314 Bothell-Everett Hwy",
        "id": "20623",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ5YyJX94FkFQRTZ4Vun3cNss",
        "user_ratings_total": 634
      },
      {
        "geocode": [
          47.3595837,
          -122.6020738
        ],
        "Rest_Name": "Borgen",
        "address": "4726 Borgen Blvd",
        "id": "20624",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJrzCNEmRNkFQRGzyT_-sNi84",
        "user_ratings_total": 612
      },
      {
        "geocode": [
          47.75675,
          -122.15432
        ],
        "Rest_Name": "Woodinville",
        "address": "17744 Garden Way NE",
        "id": "20625",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJZ2GqLygMkFQRhcqIZpeg_yo",
        "user_ratings_total": 359
      },
      {
        "geocode": [
          47.586797,
          -122.236414
        ],
        "Rest_Name": "27th Street",
        "address": "7650 Se 27th St",
        "id": "20626",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJ9aMWvoIRBYgRgHaTBQUyzZ8",
        "user_ratings_total": 101
      },
      {
        "geocode": [
          47.67503,
          -122.12836
        ],
        "Rest_Name": "Redmond Way",
        "address": "15946 Redmond Way",
        "id": "20627",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ8cJGo61ykFQRqjESfsoF6XI",
        "user_ratings_total": 448
      },
      {
        "geocode": [
          47.6320313,
          -122.1383212
        ],
        "Rest_Name": "24th St",
        "address": "15116 Ne 24th St",
        "id": "20628",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJX95qzwltkFQR8YgeImuFQzk",
        "user_ratings_total": 559
      },
      {
        "geocode": [
          47.4438608,
          -122.3022254
        ],
        "Rest_Name": "SeaTac Airport",
        "address": "17801 International Blvd",
        "id": "20629",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJTUIbPKpckFQRySUxS3YriZ4",
        "user_ratings_total": 780
      },
      {
        "geocode": [
          47.1071675,
          -122.592545
        ],
        "Rest_Name": "Base Access AAFES Exchange",
        "address": "41st Division DR",
        "id": "20630",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          47.4597557,
          -122.2531113
        ],
        "Rest_Name": "Tukwila",
        "address": "100 Andover Park W",
        "id": "20631",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJsf6ksNVckFQRlLFaMWBZAvU",
        "user_ratings_total": 693
      },
      {
        "geocode": [
          47.5480263,
          -122.0407643
        ],
        "Rest_Name": "Lake Samammish Pkwy Blvd.",
        "address": "6140 E Lake Sammamish Pkwy Se",
        "id": "20632",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJHYhC55lvkFQR5Cz2A0IDqes",
        "user_ratings_total": 695
      },
      {
        "geocode": [
          47.2962308,
          -122.2426956
        ],
        "Rest_Name": "Auburn",
        "address": "1016 Outlet Collection Way",
        "id": "20633",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ-4DAAwxYkFQRX_XPIh4KfaI",
        "user_ratings_total": 908
      },
      {
        "geocode": [
          47.11471,
          -122.29111
        ],
        "Rest_Name": "156th Street",
        "address": "10306 156th St E",
        "id": "20634",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJc8y0mQdJwokRoMgKqel7BUU",
        "user_ratings_total": 29
      },
      {
        "geocode": [
          45.62177,
          -122.47545
        ],
        "Rest_Name": "192nd Avenue",
        "address": "155 NE 192nd Ave",
        "id": "20635",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ04o2wiK7lVQR_ts2hbHHGAQ",
        "user_ratings_total": 296
      },
      {
        "geocode": [
          47.66776,
          -117.41079
        ],
        "Rest_Name": "Burgans Block",
        "address": "1120 N Division St",
        "id": "20636",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          47.6470818,
          -117.4129123
        ],
        "Rest_Name": "South Hill",
        "address": "901 S Grand Blvd",
        "id": "20637",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJL-HImGe02IcREcBax10mO7Y",
        "user_ratings_total": 709
      },
      {
        "geocode": [
          47.6714557,
          -117.2407192
        ],
        "Rest_Name": "The Valley",
        "address": "1527 N Pines Rd",
        "id": "20638",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          42.52381,
          -88.98645
        ],
        "Rest_Name": "Beloit",
        "address": "2777 Milwaukee Rd",
        "id": "20639",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJH5skJvunCIgRATlBXoPhN8M",
        "user_ratings_total": 505
      },
      {
        "geocode": [
          42.72038,
          -88.99547
        ],
        "Rest_Name": "Janesville",
        "address": "2201 Humes Rd",
        "id": "20640",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJz2_9qpUQBogRteXVjGIpcOY",
        "user_ratings_total": 519
      },
      {
        "geocode": [
          43.019202,
          -89.4200153
        ],
        "Rest_Name": "Fitchburg",
        "address": "5401 Caddis Bend Rd",
        "id": "20641",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJKQwKuGGtB4gRVh0RiOy3cVw",
        "user_ratings_total": 536
      },
      {
        "geocode": [
          43.0614301,
          -89.4965487
        ],
        "Rest_Name": "Mineral Point",
        "address": "6650 Mineral Point Rd",
        "id": "20642",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJLzUjQPSuB4gRuhF5ZgpFz3Q",
        "user_ratings_total": 445
      },
      {
        "geocode": [
          42.6270493,
          -88.6249267
        ],
        "Rest_Name": "Delavan",
        "address": "1312 Geneva St.",
        "id": "20643",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJKzS7nxHxBYgRNXVegGHeowQ",
        "user_ratings_total": 40
      },
      {
        "geocode": [
          43.0736004,
          -89.435467
        ],
        "Rest_Name": "University Avenue",
        "address": "2741 University Ave",
        "id": "20644",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJM966nvWsB4gRa1EPu3c__KQ",
        "user_ratings_total": 245
      },
      {
        "geocode": [
          43.06784,
          -89.4012
        ],
        "Rest_Name": "N. Park",
        "address": "2 N Park St",
        "id": "20645",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          43.04799,
          -89.32584
        ],
        "Rest_Name": "Monona",
        "address": "101 W Broadway Ave",
        "id": "20646",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJcSPE5yhSBogR_eh4auEYRJ8",
        "user_ratings_total": 573
      },
      {
        "geocode": [
          43.0751176,
          -89.3946624
        ],
        "Rest_Name": "State Street",
        "address": "548 State St",
        "id": "20647",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJISrY6zVTBogRiNQsauTTDUA",
        "user_ratings_total": 274
      },
      {
        "geocode": [
          42.8351,
          -88.75002
        ],
        "Rest_Name": "Whitewater",
        "address": "1114 W Main St",
        "id": "20648",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJg9khg1_gBYgRPwYMeZKdg-4",
        "user_ratings_total": 302
      },
      {
        "geocode": [
          43.13034,
          -89.30353
        ],
        "Rest_Name": "East Towne",
        "address": "4718 E Towne Blvd",
        "id": "20649",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJidB2yfRWBogRREoJN0-ivrQ",
        "user_ratings_total": 763
      },
      {
        "geocode": [
          42.5935312,
          -88.4152789
        ],
        "Rest_Name": "Lake Geneva",
        "address": "351 Peller Rd",
        "id": "20650",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJTVY2CcSLBYgRCU9aqTuQawk",
        "user_ratings_total": 581
      },
      {
        "geocode": [
          43.08326,
          -88.76222
        ],
        "Rest_Name": "Johnson Creek",
        "address": "485 Wright Rd",
        "id": "20651",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJG5InSFnXBYgR-Bt1xwZtJ4k",
        "user_ratings_total": 561
      },
      {
        "geocode": [
          42.853806,
          -88.325356
        ],
        "Rest_Name": "Mukwonago",
        "address": "885 S Rochester",
        "id": "20652",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ4SXe7VS9BYgRZGjEOtUrFqk",
        "user_ratings_total": 381
      },
      {
        "geocode": [
          42.57593,
          -87.88644
        ],
        "Rest_Name": "Kenosha",
        "address": "6430 Green Bay Rd",
        "id": "20653",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJKzkeJfpgBYgR5Dhtw2QHsJ0",
        "user_ratings_total": 823
      },
      {
        "geocode": [
          42.7250973,
          -87.949836
        ],
        "Rest_Name": "I-94 and Hwy 20 - Sturtevant",
        "address": "13330 Washington Ave",
        "id": "20654",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          42.6969626,
          -87.8536732
        ],
        "Rest_Name": "Racine \u2013 Across from Regency Mall",
        "address": "6035 W Durand Ave",
        "id": "20655",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          42.88582,
          -87.91166
        ],
        "Rest_Name": "Oak Creek",
        "address": "8750 S Howell Ave",
        "id": "20656",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJce1hu18UBYgRP0JTfAislFg",
        "user_ratings_total": 675
      },
      {
        "geocode": [
          42.9312624,
          -87.9495423
        ],
        "Rest_Name": "27th and College",
        "address": "6245 S. 27th St.",
        "id": "20657",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJ9aMWvoIRBYgRgHaTBQUyzZ8",
        "user_ratings_total": 101
      },
      {
        "geocode": [
          42.9525628,
          -88.0089416
        ],
        "Rest_Name": "Greenfield",
        "address": "5075 S 76th St",
        "id": "20658",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJsYCHt9kRBYgRVzSNWuems7c",
        "user_ratings_total": 615
      },
      {
        "geocode": [
          42.9588723,
          -87.920415
        ],
        "Rest_Name": "Layton",
        "address": "639 W Layton Ave",
        "id": "20659",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJi-F1HNkWBYgRvWh2g-pvnCA",
        "user_ratings_total": 780
      },
      {
        "geocode": [
          42.9764757,
          -88.1091158
        ],
        "Rest_Name": "New Berlin",
        "address": "15620 W National Ave",
        "id": "20660",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJgfX2lsIIBYgRlin4U386NTk",
        "user_ratings_total": 418
      },
      {
        "geocode": [
          42.992365,
          -88.0475664
        ],
        "Rest_Name": "West Allis",
        "address": "2831 S 108th St",
        "id": "20661",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJ38CptZwPBYgRjoSC3EceZwM",
        "user_ratings_total": 774
      },
      {
        "geocode": [
          43.0183856,
          -87.9672195
        ],
        "Rest_Name": "West Milwaukee",
        "address": "1240 Miller Park Way",
        "id": "20662",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJAzO4LWEaBYgRpC6ihHIg328",
        "user_ratings_total": 657
      },
      {
        "geocode": [
          43.0404152,
          -87.9332111
        ],
        "Rest_Name": "Marquette",
        "address": "803 N 16th St",
        "id": "20663",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJlVFe438ZBYgRqG3dXv2_4pU",
        "user_ratings_total": 471
      },
      {
        "geocode": [
          43.045307,
          -87.9107469
        ],
        "Rest_Name": "Water",
        "address": "1150 N Water St",
        "id": "20664",
        "type": "Qdoba",
        "rating": 3.8,
        "place_id": "ChIJFUveZwwZBYgRWkzjamEl1SE",
        "user_ratings_total": 374
      },
      {
        "geocode": [
          43.0355662,
          -88.0462575
        ],
        "Rest_Name": "Mayfair Road",
        "address": "418 N Mayfair Rd",
        "id": "20665",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJt0atnwYFBYgR4aeGFV5m3-U",
        "user_ratings_total": 666
      },
      {
        "geocode": [
          43.0531677,
          -87.8939641
        ],
        "Rest_Name": "E Brady St",
        "address": "1348 E Brady St",
        "id": "20666",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJUXvcBeIYBYgRj08hLCmvz3U",
        "user_ratings_total": 353
      },
      {
        "geocode": [
          43.0503678,
          -88.0078656
        ],
        "Rest_Name": "Wauwatosa Village",
        "address": "1417 N Wauwatosa Ave",
        "id": "20667",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJfUDb8y0bBYgRV6uyrFojt8k",
        "user_ratings_total": 503
      },
      {
        "geocode": [
          43.0516582,
          -88.3665272
        ],
        "Rest_Name": "Delafield",
        "address": "3250 Golf Rd",
        "id": "20668",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJoaLwKAexBYgRtwWvSC02p5Q",
        "user_ratings_total": 345
      },
      {
        "geocode": [
          43.0772298,
          -88.470021
        ],
        "Rest_Name": "Oconomowoc",
        "address": "1680 Old School House Rd",
        "id": "20669",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJ8Xci_A-2BYgRRihaGxm-NCo",
        "user_ratings_total": 641
      },
      {
        "geocode": [
          43.57396,
          -89.77724
        ],
        "Rest_Name": "Wisconsin Dells",
        "address": "1241 Kalahari Dr",
        "id": "20670",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJVezzxB1EB4gRbiRLPpFVOdg",
        "user_ratings_total": 565
      },
      {
        "geocode": [
          43.041749,
          -88.2577154
        ],
        "Rest_Name": "Silvernail Road",
        "address": "1890 Meadow Ln",
        "id": "20671",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJow5KWyavBYgRZnBeQc8aUOo",
        "user_ratings_total": 414
      },
      {
        "geocode": [
          43.01533,
          -88.23309
        ],
        "Rest_Name": "Waukesha",
        "address": "325 E North St",
        "id": "20672",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJAxJeyaSoBYgRidYUImSvFsM",
        "user_ratings_total": 622
      },
      {
        "geocode": [
          43.8120206,
          -91.2529604
        ],
        "Rest_Name": "La Crosse",
        "address": "127 S 3rd St",
        "id": "20673",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ02StMqZV-YcRuv6rjINPSFU",
        "user_ratings_total": 652
      },
      {
        "geocode": [
          44.3784366,
          -89.8166594
        ],
        "Rest_Name": "Wisconsin Rapids",
        "address": "1821 8th St S",
        "id": "20674",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJIeLKmVaJAIgReHcwpjkYn_M",
        "user_ratings_total": 163
      },
      {
        "geocode": [
          44.5227417,
          -89.5218562
        ],
        "Rest_Name": "Stevens Point",
        "address": "5347 US-10",
        "id": "20675",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJg_g8e0VQAIgR3coB4MbXLRc",
        "user_ratings_total": 774
      },
      {
        "geocode": [
          44.07396,
          -87.70729
        ],
        "Rest_Name": "Manitowoc",
        "address": "4550 Calumet Ave",
        "id": "20676",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ-yJ87gc4A4gROmSKfoe7f9I",
        "user_ratings_total": 302
      },
      {
        "geocode": [
          44.4869433,
          -87.9604409
        ],
        "Rest_Name": "Green Bay East",
        "address": "2450 E Mason St",
        "id": "20677",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ4yEnqk_kAogRA5c_9oT74NU",
        "user_ratings_total": 472
      },
      {
        "geocode": [
          44.95811,
          -89.66383
        ],
        "Rest_Name": "Wausau",
        "address": "2205 Stewart Ave",
        "id": "20678",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJu6VdF6ohAIgRTYsiFZVFS-I",
        "user_ratings_total": 580
      },
      {
        "geocode": [
          44.9052152,
          -89.5739541
        ],
        "Rest_Name": "Weston",
        "address": "4020 Schofield Ave",
        "id": "20679",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJiSZuFjElAIgRURNVkYflJoE",
        "user_ratings_total": 259
      },
      {
        "geocode": [
          44.5905469,
          -88.080725
        ],
        "Rest_Name": "Howard",
        "address": "2485 Lineville Rd",
        "id": "20680",
        "type": "Qdoba",
        "rating": 2.9,
        "place_id": "ChIJzXSCa6vxAogRRVIEA2PCG-M",
        "user_ratings_total": 160
      },
      {
        "geocode": [
          44.48405,
          -88.06956
        ],
        "Rest_Name": "Green Bay",
        "address": "2476 S Oneida St",
        "id": "20681",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJwaNZhLj7AogRNiDMDLoeqd0",
        "user_ratings_total": 764
      },
      {
        "geocode": [
          44.2725081,
          -88.4712418
        ],
        "Rest_Name": "Appleton",
        "address": "4343 W Wisconsin Ave",
        "id": "20682",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJXQKOTtK3A4gRUfLYe1N62B0",
        "user_ratings_total": 565
      },
      {
        "geocode": [
          44.2434178,
          -88.349496
        ],
        "Rest_Name": "Appleton East",
        "address": "3813 E Calumet St",
        "id": "20683",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJwVuHUYawA4gRdyt0wuLWxXQ",
        "user_ratings_total": 610
      },
      {
        "geocode": [
          44.1757782,
          -88.491353
        ],
        "Rest_Name": "Neenah",
        "address": "1125 Winneconne Ave",
        "id": "20684",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJSXY8V1vHA4gRrlkabr_Ed5w",
        "user_ratings_total": 585
      },
      {
        "geocode": [
          44.0057545,
          -88.581414
        ],
        "Rest_Name": "Oshkosh",
        "address": "1990 Menard Dr",
        "id": "20685",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ_0A5vM7uA4gRrDuYXwIKi1A",
        "user_ratings_total": 737
      },
      {
        "geocode": [
          43.7838814,
          -88.4791132
        ],
        "Rest_Name": "Fond Du Lac",
        "address": "187 N Pioneer Rd",
        "id": "20686",
        "type": "Qdoba",
        "rating": 3.4,
        "place_id": "ChIJvRF90FT1A4gR7e1rwgcE350",
        "user_ratings_total": 471
      },
      {
        "geocode": [
          43.7169394,
          -87.7553055
        ],
        "Rest_Name": "Sheboygan",
        "address": "3551 Washington Ave",
        "id": "20687",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJPVAXn-mmBIgRxrfeFEGq4L0",
        "user_ratings_total": 597
      },
      {
        "geocode": [
          43.39843,
          -88.18838
        ],
        "Rest_Name": "West Bend",
        "address": "920 W Paradise Dr",
        "id": "20688",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJMWijGjZfBIgRDHhtorLaLiE",
        "user_ratings_total": 791
      },
      {
        "geocode": [
          43.3181355,
          -88.3503461
        ],
        "Rest_Name": "Hartford",
        "address": "1574 E Sumner St.",
        "id": "20689",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJb3FLcFZFBIgRqs1aOC5XQy4",
        "user_ratings_total": 32
      },
      {
        "geocode": [
          43.3207712,
          -87.9246324
        ],
        "Rest_Name": "Grafton",
        "address": "2506 Washington St",
        "id": "20690",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJTzTdgK3oBIgRn78siHW78mg",
        "user_ratings_total": 679
      },
      {
        "geocode": [
          43.176265,
          -87.914576
        ],
        "Rest_Name": "Fox Point",
        "address": "8789 N Port Washington Rd",
        "id": "20691",
        "type": "Qdoba",
        "rating": 3.9,
        "place_id": "ChIJx7fFw7_hBIgRYvS3pAGH-m0",
        "user_ratings_total": 422
      },
      {
        "geocode": [
          43.1189166,
          -87.9153983
        ],
        "Rest_Name": "Bayshore Mall",
        "address": "510 W Silver Spring Drive",
        "id": "20692",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJJcn6RGAeBYgR_PpH7-xIsNg",
        "user_ratings_total": 485
      },
      {
        "geocode": [
          43.0748027,
          -87.8880521
        ],
        "Rest_Name": "Oakland",
        "address": "3101 Oakland Ave",
        "id": "20693",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJZaM8O-NhhYARy6rIfMO8WPI",
        "user_ratings_total": 21
      },
      {
        "geocode": [
          43.0355113,
          -88.112044
        ],
        "Rest_Name": "Brookfield",
        "address": "16005 W Bluemound Rd",
        "id": "20694",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJGzaTpF0GBYgR8TI8-fV0WLE",
        "user_ratings_total": 483
      },
      {
        "geocode": [
          43.1906138,
          -88.1352411
        ],
        "Rest_Name": "Menomonee Falls",
        "address": "N95 W18181 Appleton Ave",
        "id": "20695",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJsdQN6RICBYgR7ai-8Syu1tE",
        "user_ratings_total": 843
      },
      {
        "geocode": [
          43.0885006,
          -88.065792
        ],
        "Rest_Name": "Capitol Drive",
        "address": "12345 W Capitol Dr",
        "id": "20696",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJ7Y8Q2o0DBYgRVm0607H8TXk",
        "user_ratings_total": 1159
      },
      {
        "geocode": [
          38.4228966,
          -82.4212234
        ],
        "Rest_Name": "Marshall",
        "address": "2037 5th Ave",
        "id": "20697",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          38.3290605,
          -81.7143165
        ],
        "Rest_Name": "R H L Blvd",
        "address": "98 Rhl Blvd",
        "id": "20698",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJqyvwyZIyT4gRI834p9xzFPQ",
        "user_ratings_total": 500
      },
      {
        "geocode": [
          37.8016886,
          -81.1782709
        ],
        "Rest_Name": "Galleria Plaza",
        "address": "100 Galleria Plaza",
        "id": "20699",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          39.2993148,
          -81.5501621
        ],
        "Rest_Name": "Vienna",
        "address": "116 Grand Central Mall",
        "id": "20700",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJucgkqLpLSIgR8dFuGx40Eoo",
        "user_ratings_total": 193
      },
      {
        "geocode": [
          39.2757301,
          -80.2833004
        ],
        "Rest_Name": "Clarksburg",
        "address": "252 Emily Dr",
        "id": "20701",
        "type": "Qdoba",
        "rating": 4.4,
        "place_id": "ChIJi1wiPXBpSogRfB0qlcdtjkY",
        "user_ratings_total": 202
      },
      {
        "geocode": [
          39.6510039,
          -79.9674864
        ],
        "Rest_Name": "Patteson",
        "address": "386 Patteson Dr",
        "id": "20702",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJoydLt6F7NYgRzp_u4udukuk",
        "user_ratings_total": 529
      },
      {
        "geocode": [
          41.1451206,
          -104.7615817
        ],
        "Rest_Name": "Pershing",
        "address": "3927 E Pershing Blvd",
        "id": "20703",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJA_ADNLosDogR8V24sUYvcnQ",
        "user_ratings_total": 765
      },
      {
        "geocode": [
          41.16103,
          -104.7926
        ],
        "Rest_Name": "Dell Range",
        "address": "1958 Dell Range Blvd",
        "id": "20704",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJg6F0gak7b4cRG9erSLtLBzo",
        "user_ratings_total": 560
      },
      {
        "geocode": [
          41.3140796,
          -105.5937674
        ],
        "Rest_Name": "Laramie",
        "address": "252 N 3rd St",
        "id": "20705",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJM6MCch2QaIcRWS4eVgbx0aA",
        "user_ratings_total": 719
      },
      {
        "geocode": [
          42.8201685,
          -106.3700106
        ],
        "Rest_Name": "CasperWest",
        "address": "4009 CY Ave",
        "id": "20706",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          44.7746186,
          -106.9430935
        ],
        "Rest_Name": "Sheridan Qdoba",
        "address": "2112 Coffeen Ave",
        "id": "20707",
        "type": "Qdoba",
        "rating": 4.3,
        "place_id": "ChIJpZsmuBLwNVMRO6JOSBRb9BI",
        "user_ratings_total": 613
      },
      {
        "geocode": [
          42.84803,
          -106.25719
        ],
        "Rest_Name": "CasperEast",
        "address": "5030 E 2nd St",
        "id": "20708",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJL5BlA_y7YIcRFmY_MwzVSFw",
        "user_ratings_total": 592
      },
      {
        "geocode": [
          44.27021,
          -105.49322
        ],
        "Rest_Name": "Gillette",
        "address": "2711 S Douglas Hwy",
        "id": "20709",
        "type": "Qdoba",
        "rating": 4.1,
        "place_id": "ChIJi5op1NGVNFMR1YQ89s5DjIo",
        "user_ratings_total": 535
      },
      {
        "geocode": [
          33.99697844,
          -81.02720922
        ],
        "Rest_Name": "University of South Carolina",
        "address": "1400 Greene St",
        "id": "20710",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          34.02260946,
          -80.94371607
        ],
        "Rest_Name": "Ft Jackson",
        "address": "4110 Moseby St",
        "id": "20711",
        "type": "Qdoba",
        "rating": 3.6,
        "place_id": "ChIJkaEgcsev-IgRLBw_bdB1Oy0",
        "user_ratings_total": 12
      },
      {
        "geocode": [
          34.8911414,
          -82.21713016
        ],
        "Rest_Name": "GSP International",
        "address": "2000 GSP DrGrand Hall",
        "id": "20712",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          33.49913323,
          -80.85425392
        ],
        "Rest_Name": "Claflin University",
        "address": "400 Magnolia StreetStudent Center",
        "id": "20713",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          38.7246824,
          -77.15017041
        ],
        "Rest_Name": "Fort Belvoir",
        "address": "8651 John J Kingman Rd",
        "id": "20714",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJ5XPHtN2tt4kReZj2KPvHaWc",
        "user_ratings_total": 12
      },
      {
        "geocode": [
          37.15833218,
          -76.58073247
        ],
        "Rest_Name": "Fort Eustis",
        "address": "1386 Washington Blvd",
        "id": "20715",
        "type": "Qdoba",
        "rating": 3,
        "place_id": "ChIJo44m2op9sIkR66kbZjUEot4",
        "user_ratings_total": 4
      },
      {
        "geocode": [
          37.25351633,
          -77.32716626
        ],
        "Rest_Name": "Fort Lee",
        "address": "300 A AveBldg 1605",
        "id": "20716",
        "type": "Qdoba",
        "rating": 4.2,
        "place_id": "ChIJ8yetOQXTD4gRHp_6Nm83kg0",
        "user_ratings_total": 478
      },
      {
        "geocode": [
          37.07490914,
          -76.36118024
        ],
        "Rest_Name": "Langley AFB",
        "address": "61 Spaatz Dr#290",
        "id": "20717",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          47.54819123,
          -122.0407056
        ],
        "Rest_Name": "Lake Samammish Blvd.",
        "address": "6140 E Lake Sammamish Pkwy SeUnit G19",
        "id": "20718",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          47.66159375,
          -122.3152627
        ],
        "Rest_Name": "45th St.",
        "address": "1200 Ne 45th St",
        "id": "20719",
        "type": "Qdoba",
        "rating": 4,
        "place_id": "ChIJp4y6zooUkFQROeY7Vtt5_2Q",
        "user_ratings_total": 490
      },
      {
        "geocode": [
          47.66707539,
          -117.3989508
        ],
        "Rest_Name": "Gonzaga University",
        "address": "1117 N Dakota",
        "id": "20720",
        "type": "Qdoba",
        "rating": null,
        "place_id": "ChIJOTSEQPsZnlQRd4fUtRoz4jY",
        "user_ratings_total": null
      },
      {
        "geocode": [
          47.75331907,
          -117.4165175
        ],
        "Rest_Name": "Whitworth University",
        "address": "300 W Hawthorne Rd",
        "id": "20721",
        "type": "Qdoba",
        "rating": null,
        "place_id": "ChIJi-Ud4fAdnlQRppG2xkoHfPU",
        "user_ratings_total": null
      },
      {
        "geocode": [
          30.33729543,
          -97.68855489
        ],
        "Rest_Name": "AUS 28 Kitchen",
        "address": "1300 E Anderson Lane",
        "id": "20722",
        "type": "Qdoba",
        "rating": 1,
        "place_id": "ChIJAZdN01jJRIYRMBK0x7u5xU8",
        "user_ratings_total": 6
      },
      {
        "geocode": [
          30.184573,
          -97.79287999
        ],
        "Rest_Name": "AUS 14 (Food Truck)",
        "address": "7814 S 1st StAUS 14",
        "id": "20723",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          33.22702060951867,
          -97.12909833
        ],
        "Rest_Name": "Texas Woman's University",
        "address": "303 Administration DR",
        "id": "20724",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJG3zImlHLTYYRYY_RF4q4i3g",
        "user_ratings_total": 3
      },
      {
        "geocode": [
          31.13758279,
          -97.76491098
        ],
        "Rest_Name": "Ft Hood TJ Mills",
        "address": "761st Tank Battalion AveBldg 118",
        "id": "20725",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          32.90360554,
          -96.46497552
        ],
        "Rest_Name": "Rockwall",
        "address": "2224 Ridge Road",
        "id": "20726",
        "type": "Qdoba",
        "rating": null,
        "place_id": null,
        "user_ratings_total": null
      },
      {
        "geocode": [
          29.38251487,
          -98.61505812
        ],
        "Rest_Name": "Lackland AFB",
        "address": "1385 Reese",
        "id": "20727",
        "type": "Qdoba",
        "rating": 3.7,
        "place_id": "ChIJC-iOjPBbXIYRYjomGnxIuOc",
        "user_ratings_total": 33
      }
    
    ]
export default markers;